   import { Component, OnInit } from '@angular/core';
import { Sidebar, EstimateModel } from 'src/app/shared';
import { EstimateService } from 'src/app/shared/services/estimate/estimate.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { WorkOrderService } from 'src/app/shared/services/work-order/work-order.service';
import { ItemService } from 'src/app/shared/services/item/item.service';
@Component({
  selector: 'app-estimate',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.scss']
})
export class EstimateComponent implements OnInit {

  public loading: boolean = false;
  public estimates: any[] = [];
  public EstimateModel: EstimateModel[] = [];
  public amount: number = 0;
  public customer_id: string = null;
  public zip_url: string = null;

  public Search = this.fb.group({
    estimate_number: ['',Validators.required],
  })

  constructor(
    public _sidebar: Sidebar,
    public EstimateService: EstimateService,
    public SettingService:SettingService,
    public activatedRoute:ActivatedRoute,
    public fb: FormBuilder,
    public toastr: ToastrService,
    public router: Router,
    public WorkOrderService: WorkOrderService,
    public ItemService: ItemService
    )
    {
      this.customer_id = this.activatedRoute.snapshot.paramMap.get('customer_id');
      if (this.customer_id != null) {
        this.getEstimateListByCustomer(this.customer_id);
      }
      else {
        this.getEstimateList();
      }
    }

  ngOnInit() {
    window.scroll(0, 0);
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    let transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;

    return transformedData;
  }

  getEstimateList() {
    this.loading = true;

    this.EstimateService.GetEstimateList().subscribe(
      result => {
        if (result['success']) {
          this.loading = false;

          this.estimates = result['data'];
          let data = [];
          for (let index of this.estimates) {
            let EstimateData = new EstimateModel()
            if (index.estimate_customers == null || index.estimate_customers == '') {
              EstimateData.name = 'NA';
            } 
            else {
              EstimateData.name = index.estimate_customers[0].name;
            }
            if (index.estimate_items == null || index.estimate_items == '') {
              EstimateData.rate = 'NA';
            } 
            else {
              for (let i of index.estimate_items) {
                this.amount = this.amount + (+i.rate);
                EstimateData.rate = index.estimate_items[0].rate;
              }
              EstimateData.rate = this.amount;
              this.amount = 0;
            }
            EstimateData.estimate_id = index.estimate_id;
            EstimateData.due_date = index.due_date;
            EstimateData.total = index.gross_amount;
            EstimateData.estimate_number = index.estimate_number;
            EstimateData.net_amount = index.net_amount;
            EstimateData.gross_amount = index.gross_amount;
            EstimateData.advance_paid = index.advance_paid;
            data.push(EstimateData);
          }
          this.EstimateModel = data;
        }
        else {
          this.loading = false;
        }
      }, (err:HttpErrorResponse) => {
        this.loading = false;

        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  getEstimateListByCustomer(customer_id) {
    this.loading = true;

    this.EstimateService.GetEstimateListByCustomer(customer_id).subscribe(
      result => {
        if (result['success']) {
          this.loading = false;

          this.estimates = result['data'];
          let data = [];
          for (let index of this.estimates) {
            let EstimateData = new EstimateModel()
            if (index.estimate_customers == null || index.estimate_customers == '') {
              EstimateData.name = 'NA';
            } 
            else {
              EstimateData.name = index.estimate_customers[0].name;
            }
            if (index.estimate_items == null || index.estimate_items == '') {
              EstimateData.rate = 'NA';
            } 
            else {
              for (let i of index.estimate_items) {
                this.amount = this.amount + (+i.rate);
                EstimateData.rate = index.estimate_items[0].rate;
              }
              EstimateData.rate = this.amount;
              this.amount = 0;
            }
            EstimateData.estimate_id = index.estimate_id;
            EstimateData.due_date = index.due_date;
            EstimateData.total = index.total;
            EstimateData.estimate_number = index.estimate_number;
            EstimateData.net_amount = index.net_amount;
            EstimateData.gross_amount = index.gross_amount;
            EstimateData.advance_paid = index.advance_paid;
            data.push(EstimateData);
          }
          this.EstimateModel = data;
          
        }
        else {
          this.loading = false;
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  SearchEstimate(value){
    if(this.Search.invalid){
      this.toastr.error('Please provide the estimate number to search for!')
      return;
    }
    this.EstimateService.EstimateSearch(this.Search.value).subscribe(
      result=>{
        if (result['success']) {
          this.loading = false;

          this.estimates = result['data'];
          let data = [];
          for (let index of this.estimates) {
            let EstimateData = new EstimateModel()
            if (index.estimate_customers == null || index.estimate_customers == '') {
              EstimateData.name = 'NA';
            } 
            else {
              EstimateData.name = index.estimate_customers[0].name;
            }
            if (index.estimate_items == null || index.estimate_items == '') {
              EstimateData.rate = 'NA';
            } 
            else {
              for (let i of index.estimate_items) {
                this.amount = this.amount + (+i.rate);
                EstimateData.rate = index.estimate_items[0].rate;
              }
              EstimateData.rate = this.amount;
              this.amount = 0;
            }
            EstimateData.estimate_id = index.estimate_id;
            EstimateData.due_date = index.due_date;
            EstimateData.total = index.gross_amount;
            EstimateData.estimate_number = index.estimate_number;
            EstimateData.net_amount = index.net_amount;
            EstimateData.gross_amount = index.gross_amount;
            EstimateData.advance_paid = index.advance_paid;
            data.push(EstimateData);
          }
          this.EstimateModel = data;
        }else{
          this.loading = false;

          this.EstimateModel = null;
        }

        
      }
    )

  }
  Clear(){
    this.Search.get('estimate_number').setValue('');
    this.Search.updateValueAndValidity();
    this.getEstimateList();
      
  }
  ClearInput(){
    if(this.Search.value.estimate_number == '' || this.Search.value.estimate_number == null){
      this.getEstimateList();
    }else{
      this.SearchEstimate('Input');
    }
   
  }

  NavigateToAddEstimate(){
    this.WorkOrderService.ApproveInvoice(null);
    this.router.navigate(['/estimate-new/1']);
  }

  // exportCSV() {
  //   // this.loading = true;
  //   this.ItemService.exportCSV(data)
  //     .subscribe((result) => {
  //       if (result['success']) {
  //         this.zip_url = "http://128.199.25.224/invoice-app-server/public/product/service.zip";
  //         const link = document.createElement('a');
  //         link.href =  this.zip_url ;
  //         link.download = this.zip_url ;
  //         link.click();
  //       }
  //     }
  //     , (err:HttpErrorResponse) => {
  //       this.loading = false;
  //       if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
  //         this.SettingService.LogOut();
  //       }
  //     }
  //   )
  // }
}
