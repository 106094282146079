import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-my-schedule-status',
  templateUrl: './my-schedule-status.component.html',
  styleUrls: ['./my-schedule-status.component.scss']
})
export class MyScheduleStatusComponent implements OnInit {

  @Input() data: any =  null;
  constructor() {}

  ngOnInit() {

  }

}
