import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class InvoiceAllList {
  public invoice_id: string = null;
  public customer_id: string = null;
  public item_id: string = null;
  public items: any[] = [];
  public customer_name: string = null;
  public item_name: string = null;
  public rate: string = null;
  public status: any = null;
  public line_item: string = null;
  public discount: string = null;
  public discount_type: string = null;
  public sales_tax: any = null;
  public due_date: any = null;
  public advance: string = null;
  public advance_type: string = null;
  public note: string = null;
  public private_note: string = null;
  public amount: any = null;
  public total: any = null;
  public invoice_number: any = null;
  public payment_type: string = null;
  public created_at: string = null;
  public updated_at: string = null;
  public markup: any = 0;
  public quantity: any = 0;
  public item_number: any = 0;
  public expense_number: any = 0;
  public recurring_type: any =null;
  public invoice_recurring_details_id: string = null;
  public select_interval: boolean =false;
  public day: any =null;
  public month: any =null;
  public quarter: any =null;
  public is_new_invoice: any =null;
  public advance_paid: boolean =false;
  public net_amount: any = 0;
  public gross_amount: any = 0;
  public organization_id: string = null;
}