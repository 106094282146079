import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  public _token:string=null;

  constructor(
    public _Auth:AuthService

  ) { }

  public GetTokenByHeader() {
    let _userData = this._Auth.User();
    this._token = _userData['data']['token'];    
    let _headers:HttpHeaders = new HttpHeaders({

      'Authorization': 'Bearer ' + this._token,
    });

    return _headers;
  }

}
