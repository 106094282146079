import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Sidebar, CustomerModel } from 'src/app/shared';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ItemService } from 'src/app/shared/services/item/item.service';
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
  public loading: boolean = false;
  public CustomerList: any = null;
  public CustomerName: any[] = [];
  public CustomerModel: CustomerModel[] = [];
  public saveBtnLoader = false;
  public zip_url: string = null;
  public submitted: boolean = false;
  modelRef: BsModalRef;
  @ViewChild('uploadCustomerConfirm', {static: true}) uploadCustomerConfirm : ModalDirective;
  @ViewChild('openCustomerUpload', {static: false}) openCustomerUpload : TemplateRef<any>;

  public Search = this.fb.group({
    search: [''],
  })

  constructor(
    public _sidebar: Sidebar,
    public CustomerService: CustomerService,
    public fb: FormBuilder,
    public SettingService:SettingService,
    public toastr: ToastrService,
    public modelService: BsModalService,
    public ItemService: ItemService
  ) {
    this.getCustomers();
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  getCustomers() {
    this.loading = true;

    this.CustomerService.GetCustomerList()
      .subscribe((result) => {
        this.loading = false;
        this.CustomerList = result['data']['Customer'];
        let data = [];
        for (let index of this.CustomerList) {
          for (let i in index.customers) {
            let _CustomerData = new CustomerModel();

            _CustomerData.title = index.title;
            _CustomerData.customer_id = index.customers[i].id;
            _CustomerData.name = index.customers[i].name;

            data.push(_CustomerData);
          }
        }
        this.CustomerModel = data;
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  SearchCustomer() {
    if (this.Search.invalid) {
      this.toastr.error('Please provide invoice number');
      return;
    }
      this.CustomerService.SearchedCustomer(this.Search.value)
        .subscribe((result) => {
          this.loading = false;
          this.CustomerList = result['data']['Customer'];
          let data = [];
          for (let index of this.CustomerList) {
            for (let i in index.customers) {
              let _CustomerData = new CustomerModel();

              _CustomerData.title = index.title;
              _CustomerData.customer_id = index.customers[i].id;
              _CustomerData.name = index.customers[i].name;
              data.push(_CustomerData);
            }
          }
          this.CustomerModel = data;

        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
  }

  Clear() {
    this.Search.get('search').setValue('');
    this.Search.updateValueAndValidity();
    this.getCustomers();

  }
  ClearInput() {
    if (this.Search.value.search == '' || this.Search.value.search == null) {
      this.getCustomers();
    } else {
      this.SearchCustomer();
    }

  }
  public file: File = null;
  // Upload tax
  FilesSelected(event) {
    if (event.rejectedFiles.length > 0) {
      this.toastr.error("The file must be CSV type");
      return;
    }
    this.file = event.addedFiles[0];
    let reader: FileReader = new FileReader();
    reader.readAsText(this.file);

    reader.onload = (e) => {
      let csv: any = reader.result;
      let allTextLines = csv.split(/\r?\n/);      
    };
  }
  onRemove() {
    this.file = null;
  }
  public config = {
    backdrop : true,
    keyboard : false,
    ignoreBackdropClick: true
  };
  openModal(template: TemplateRef < any > ) {
    this.modelRef = this.modelService.show(template, this.config)
  }
  validCustomerData: any[] = [];
  inValidCustomerData: any[] = [];

  uploadedCsvData(){
    if(this.file == null){
      this.toastr.info('Please Upload a file first');
      return
    }
    this.CustomerService.validateCustomerCsv(this.file).subscribe((response) => {
      if(response['success']){
        this.modelRef.hide();
        this.uploadCustomerConfirm.show();
        this.toastr.success('CSV Data Validated');        
        this.validCustomerData = response['valid'];
        this.inValidCustomerData = response['invalid'];        
      }else{
        this.file = null;
        this.toastr.error(response['msg'])
      }
    })
  }

  uploadValidatedCustomer(){
    if(this.validCustomerData.length == 0){
      this.toastr.info('Please upload a valid CSV File');
      this.uploadCustomerConfirm.hide();
      this.file = null;
      return
    }
    this.CustomerService.uploadValidCustomer(this.file).subscribe((response) => {
      if(response['success']){
        this.toastr.success(response['msg']);
        this.uploadCustomerConfirm.hide();
        this.file = null;
        this.getCustomers();
      }else{
        this.file = null;
        this.toastr.warning('Something went wrong!','Warning');
      }
    })
   
  }
  cancelUpload(){
    this.uploadCustomerConfirm.hide();
    this.file = null;
  }

  public downloadCSVform = this.fb.group({
    start_date: ["", Validators.required],
    end_date: [""]
  });

  get csvForm() {
    return this.downloadCSVform.controls;
  }

  exportCSV(template: TemplateRef<any>) {
    this.submitted = false;
    this.modelRef = this.modelService.show(template);
    this.downloadCSVform.get('start_date').setValue('');
  }
  
  exportCustomerCSV() {
    this.submitted  = true;
    if(this.downloadCSVform.invalid){
      return;
    }
    let data = {
      end_date: this.downloadCSVform.get('start_date').value[1],
      start_date: this.downloadCSVform.get('start_date').value[0]
    }
    // this.loading = true;
    this.CustomerService.exportCSV(data)
      .subscribe((result) => {
        if (result['success']) {
          this.zip_url = "http://128.199.25.224/invoice-app-server/public/customer/customer.zip";
          const link = document.createElement('a');
          link.href =  this.zip_url ;
          link.download = this.zip_url ;
          link.click();
          this.modelRef.hide();
        }
      }
      , (err:HttpErrorResponse) => {
        this.loading = false;
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }
}
