import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-public-widget-view',
  templateUrl: './public-widget-view.component.html',
  styleUrls: ['./public-widget-view.component.scss']
})
export class PublicWidgetViewComponent implements OnInit {
  public orgId: any = null;
  url:any;
  constructor(public activatedRoute: ActivatedRoute) {
    this.orgId = this.activatedRoute.snapshot.params.organization_id;
  
    
  }

  ngOnInit() {
    this.url = `https://api.invoicehood.com/book-a-visit/${this.orgId}`
  }
}
