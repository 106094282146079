import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Sidebar } from "src/app/shared";
import { AuthService } from "src/app/shared/services/auth/auth.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  public user_name: string = null;

  constructor(
    public sidebar: Sidebar, 
    private _Auth: AuthService,
    private _router: Router,
  ) {
    this._router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          if (event.urlAfterRedirects !== '/') {
            let user = this._Auth.User();
            
            
            this.user_name = user && user.user ? user.user.name : '';
          };
        }
      }
    );
  }
  ngOnInit() {
    window.scroll(0, 0);
  }
}
