import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class InvoiceSignatureModel {
  public type: string = null;
  public invoice_id: string = null;
  public is_signed: any;
  public is_signed_receiver: any;
  public signature: any = null;
  public receiver_signature: any = null;
  public service_agreement_id: any =null;
}