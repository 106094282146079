import { Component, OnInit } from '@angular/core';
import { LoginModel, AccountModel, PaymentSettings } from 'src/app/shared';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs'
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public isOn: boolean = true;
  public loading: boolean = false;
  public accepted: boolean = false;

  public loginData: LoginModel = new LoginModel();

  public formError: string = null;
  public organization_id: string = null;
  public errors: any = {};
  public AccountData:AccountModel=new AccountModel();
  public PaymentData: PaymentSettings = new PaymentSettings();

  // @ViewChildren('autofocus') af;

  constructor(
    protected _Auth: AuthService,
    public _router: Router,
    public toastr: ToastrService,
    public SettingService:SettingService
  ) { 
   }

  ngOnInit() {
    window.scroll(0, 0);
    this.focus();
  }

  keyDownFunction(event) {    
    if(event.keyCode == 13) {
      this.login();
    }
  }

  focus() {
    let firstField: any = document.querySelector('input[name]');
    firstField.focus();
  }

  getOrganizationDetails(org_id){
    this.SettingService.GetAccountDetails(org_id).subscribe(
      result=>{ 
        this.AccountData.organization_id=result['data']['organization_id'];
        this.AccountData.email=result['data']['email'];
        if(this.AccountData.email==null || this.AccountData.email==''){
          this._router.navigate(['/account-details']);
          this.toastr.warning('Please provide the Account Details');
        } else {
          this._router.navigate(['/home']);
        }
        localStorage.setItem("account_details", JSON.stringify(result['data']['email']));
      }
    )
  }

  login() {
    this.loading = true;
    this.accepted = false;
    let validation = this.loginData.validate();    
    this.errors = validation.messages;
    if(!validation.success){      
      this.loading = false;
      return;
    }

    if (validation.success) {
      this._Auth.Login(this.loginData).subscribe(
        result => {
          if (result['success']) {
            this.loading = false;
            this.accepted = true;
            this.formError = null;
            localStorage.setItem("user_data", JSON.stringify(result));
            let userData=JSON.parse(localStorage.getItem("user_data"));
            this.organization_id = userData.user.organization_id;
            this.getOrganizationDetails(this.organization_id);
            this.getPaymentData(this.organization_id);
          }
          else {
            this.loading = false;
            this.accepted = false;
            this.formError = result['msg'];
          }
        },(err:HttpErrorResponse)=>{
          this.loading = false
        }
      );
    }
  }

  getPaymentData(organization_id){
    this.SettingService.GetPaymentDetails(organization_id).subscribe(
      result => {
        if(result['success']){
          if (result['data']) {
            this.PaymentData = result['data'];
            localStorage.setItem("payment_data", JSON.stringify(result['data'].key));
          }
          if (result['bank_details']) {
            this.PaymentData.bank_details = result['bank_details']['bank_details'];
            this.PaymentData.checked = result['bank_details']['checked'];
            this.PaymentData.bank_detail_id = result['bank_details']['bank_detail_id'];
          }
        }
        
      }
    )
  }

  // getPaymentKey(){
  //   this.SettingService.GetPaymentDetails(null)
  //     .subscribe((result) => {
  //     localStorage.setItem("payment_data", JSON.stringify(result['data'].key));
  //   })
  // }

  Loading() {
    let interval = timer(5000);
    this.loading = true;
    this.accepted = false;

    interval.subscribe(() => {
      this.loading = false;
      this.accepted = true;
    });
  }
}
