import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import {  InvoiceAllList, InvoiceModel, CustomerModel } from 'src/app/shared';
import { ItemService } from 'src/app/shared/services/item/item.service';
import {  CustomerService } from 'src/app/shared/services/customer/customer.service';
import {  environment } from 'src/environments/environment';
import {   ToastrService } from 'ngx-toastr';
import { ExpenseService } from 'src/app/shared/services/expense/expense.service';
import {  SettingService } from 'src/app/shared/services/setting/setting.service';
import {  HttpErrorResponse } from '@angular/common/http';
import { ServiceModuleService} from 'src/app/shared/services/service/service-module.service';
import {  AddOnService } from 'src/app/shared/services/add-on/add-on.service';
import {  WorkOrderService } from 'src/app/shared/services/work-order/work-order.service';
import { BsModalRef, BsModalService, ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { AddProductAndServiceComponent } from 'src/app/layout/modal/add-product-and-service/add-product-and-service.component';
import { Subscription } from 'rxjs';
import { TaxService } from 'src/app/shared/services/tax/tax.service';
import { AddChecklistService } from 'src/app/shared/services/checklist/add-checklist.service';
import { SaveProgressWorkComponent } from 'src/app/layout/modal/save-progress-work/save-progress-work/save-progress-work.component';
@Component( {
  selector: 'app-new-invoice',
  templateUrl: './new-invoice.component.html',
  styleUrls: [ './new-invoice.component.scss' ],
  styles: [
    `
    :host >>> .bs-datepicker-head {
      background-color: #6758f3 !important;
    }
    `
  ]
} )
export class NewInvoiceComponent implements OnInit
{
  public loading: boolean = true;
  public discount: number = 20;
  public percent: string = '%';
  public dollar: string = '';
  public math = Math;
  public loading1: boolean = false;
  public accepted1: boolean = false;
  public step: any = 1;
  private _step: any = null;
  public enabled_modules: any[] = [];
  public invoice_recurring_enabled: boolean = false;
  public checklist_enabled: boolean = false;
  public submitted: boolean = false;
  public submitted2: boolean = false;
  public discount_amount: number = 0;
  public env = environment;

  public checklistItem: any[] = []
  public itemArr: any[] = [];
  public expenseArr: any[] = [];
  public itemIdArr: any[] = [];
  public Customers: any[] = [];
  public List: any[] = [];
  public itemData: any = null;
  public invoice_id: string = null;
  public rate: any = 0;
  public invoice_number: any = null;
  public amount: number = 0;
  public total_amount: any = 0;
  public item_count: number = 0;
  public model_item_count: number = 0;
  public transformedData: any = null;
  public additional_image_name: any;
  public additionalFileExtension: any;
  public multiple_image_extension_validation: boolean = true;
  public multiple_image_size_validation: boolean = true;
  public image_extension: boolean = true;
  public image_size: any;
  public fileSizeMessage: any;
  public multiple_image_size: any;
  public filesSizeMessage: any;
  public multiplefileExtensionMessage: any;
  public current_tax: any = null;
  public addBtnLoader = false;
  public addBtnDisable = false;
  public minDate_for_start_date: any = null;
  public alternative_number: any[] = [];
  public discount_value: boolean = false;
  public advance_value: boolean = false;
  public salesTax_amount: number = 0;

  modalRef: BsModalRef;
  imagList: Array<any> = [];
  expenseData: Array<any> = [];
  invoice_expenseData: Array<any> = [];
  invoice_images: Array<any> = [];
  images: Array<any> = [];
  work_order_images: Array<any> = [];
  public work_order_id: string = null;
  // @ViewChild('uploadItem', {static: false}) uploadItem : ModalDirective;

  public InvoiceModel: InvoiceModel = new InvoiceModel();
  public CustomerModel: CustomerModel = new CustomerModel();
  public _formdata = new FormData();

  public NewInvoice = this.fb.group( {
    customer_id: [ '', Validators.required ], //Validators.required
    line_item: [ ''], //Validators.required , Validators.required 
    expenses: [ '' ],
    quantity: [ 1, [ Validators.required, Validators.pattern( '^[0-9]*$' ) ] ],
    unit_price: [ 0, [ Validators.required, Validators.pattern( /^[0-9]+(\.[0-9]{1,2})?$/ ) ] ],
    discount: [ '', Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ],
    discount_type: [ '' ],
    advance_type: [ '' ],
    sales_tax: [ '',
      Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' )
    ],
    due_date: [ '', Validators.required ],
    advance: [ '',
      Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' )
    ],
    note: [ '' ],
    select_interval: [ false ],
    recurring_type: [ '' ],
    month: [ '' ],
    quarter: [ '' ],
    weekly_day: [ '' ],
    biweekly_day: [ '' ],
    day: [ '' ],
    private_note: [ '' ],
    checklist_id: ['']
  } );

  public files: File[] = [];
  public submitted3: boolean = false;
  public selected: boolean = false;
  public add_item: boolean = false;
  public add_remove_item: boolean = false;
  public isCheckedPercent: boolean = false;
  public isCheckedDollar: boolean = false;
  public isCheckedDiscountPercent: boolean = false;
  public isCheckedDiscountDollar: boolean = false;
  public subscriptions: Subscription[] = [];
  public weekTrigger: boolean = true;
  public monthTrigger: boolean = true;
  public isWeekChecked: boolean = false;
  public isMonthChecked: boolean = false;
  public recurring_check: boolean = false;
  public recurring_value: null;
  public customer_id: string = null;
  public item_id: string = null;
  public customerList: any[] = [];

  // recurring_list (demo)
  type_of_recurrings: string[] = [
    'Weekly',
    'Biweekly',
    'Monthly',
    'Quarterly',
    'Yearly'
  ];
  // week_day_list (demo)
  week_days: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday', 
    'Thursday',
    'Friday',
    'Saturday',
  ];

  // month_day_list (demo)
  days: number[] = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28 ];
  // Quarterly
  quarterly: any[] = [
    '1st', '2nd', '3rd'
  ]
  // yearly_day_list (demo)
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  public services: any[] = [ {
    name: 'Service 1',
    description: 'This service1 description ',
    service_id: '1',
    rate: '300',
  },
  {
    name: 'Service 2',
    description: 'This service2 description ',
    service_id: '2',
    rate: '500',
  },
  {
    name: 'Service 2',
    description: 'This service2 description ',
    service_id: '3',
    rate: '800',
  },
  ];

  public productServices: any[] = [];
  public current_selected_item: any = null;
  public quantity_exceed: boolean = false;
  public subsciption: Subscription;
  public is_draft_save: boolean =false;

  @ViewChild('invoiceDraft', { static: true }) invoiceDraft: ModalDirective;

  constructor (
    public _router: Router,
    public _activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    public _invoiceService: InvoiceService,
    public ItemService: ItemService,
    public CustomerService: CustomerService,
    public toastr: ToastrService,
    public ExpenseServive: ExpenseService,
    public SettingService: SettingService,
    public serviceService: ServiceModuleService,
    public AddOnService: AddOnService,
    public WorkOrderService: WorkOrderService,
    private modalService: BsModalService,
    public AddChecklistService: AddChecklistService,
    private taxService: TaxService
  ) {
    this.CustomerList();
    this.step = this._activatedRoute.snapshot.paramMap.get( 'step' );
    if ( this.step == '2' )
    {
      this.step = ( +this.step )
      this.Step( this.step );
    }
    this.customer_id = this._activatedRoute.snapshot.paramMap.get( 'customer_id' );
    if ( this.customer_id )
    {
      this.Customer( this.customer_id )
    }

    this.item_id = this._activatedRoute.snapshot.paramMap.get( 'id' );
    if ( this.item_id )
    {
      this.getProductFromProductPage();
    }

    this.invoice_id = this._activatedRoute.snapshot.paramMap.get( 'invoice_id' );
    if ( this.invoice_id != null )
    {
      this.invoice_number = this._activatedRoute.snapshot.paramMap.get( 'invoice_index' );

    }
    this.getExpenseList();
    if ( this.invoice_id == null )
    {
      this.GetInvoiceList();
      this.getWorkOrderDetails();

    }
    this.productServiceList();
    // this.getActivatedAddOnList();
    if ( JSON.parse( localStorage.getItem( 'activated_modules' ) ) )
    {
      this.enabled_modules = JSON.parse( localStorage.getItem( 'activated_modules' ) );
      for ( let index of this.enabled_modules )
      {
        if ( index[ 'module_id' ] == '606f1baf2281b' && index[ 'enabled' ] )
        {
          this.invoice_recurring_enabled = true;
        }
        if(index['module_id']=='50bf5d16f34b' && index['enabled'] == 1){
          this.checklist_enabled = true;
        }
      }
    }
    
    this.minDate_for_start_date = new Date();
    this.minDate_for_start_date.setDate( this.minDate_for_start_date.getDate() );
    // this.getLocationList();
    this.getCheckListItem();
    this.getDraftDetailsData();
  }

  ngOnInit () {
    window.scroll( 0, 0 );
    this._step = this._activatedRoute.snapshot.paramMap.get( 'step' );
    this.Step( this._step );
    if ( this.invoice_id != null )
    {
      this.getInvoiceImages( this.invoice_id );

    }
    // this.ItemById();
    // this.Unselect();
    this.serviceService.setServiceSuccess = null;
    this.serviceService.setMoreServiceSuccess = null;
    this.add_item = false;
    this.AddInvoiceDraft();
  }
  ngAfterViewInit(){
    this.subsciption = this._invoiceService.isDraftSave.subscribe((response) => {
      if(response) {
        this.is_draft_save = response;
        if(this.is_draft_save && !this.invoice_id) {
          this.invoiceDraft.show();
        }
      }
    })

    if(this.InvoiceModel.is_draft_save == true && !this.invoice_id) {
      this.invoiceDraft.show();
    }
    const serviceAndProductResponse = this.serviceService.serviceSuccess.subscribe((response) => {
      if(response){
        this.productServiceList()
        this.modalService.hide(1);
      let removeModal =  document.querySelector('.modal-open');
      removeModal.classList.remove('modal-open')
      }
    });
    const moreServiceAndProductResponse = this.serviceService.servicMoreSuccess.subscribe((response) => {
      if(response){
        this.productServiceList()
      }
    })
    this.subscriptions.push(serviceAndProductResponse, moreServiceAndProductResponse)
    this.getProductServiceModalData();
    this.getServiceModalData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subsciption.unsubscribe();
  }

  discountErrorMessage(){
    
    if (this.NewInvoice.value.discount_type == '$'){
      if (this.NewInvoice.value.discount >= Number(this.amount)){
        this.discount_value = true;
      }else{
        this.discount_value = false;
      }
    }else if (this.NewInvoice.value.discount_type == '%'){
      if (this.amount < 0){
        this.discount_value = true;
      }else{
        this.discount_value = false;
      }
    }
  }

  advanceErrorMessage(){

    if (this.NewInvoice.value.advance_type == '$'){
      if (this.NewInvoice.value.advance >= Number(this.amount)){
        this.advance_value = true;
      }else{
        this.advance_value = false;
      }
    }else if (this.NewInvoice.value.advance_type == '%'){
      if (this.NewInvoice.value.advance>=100){
        this.advance_value = true;
      }else{
        this.advance_value = false;
      }
    }
  }

  checkToggle ( type: string )
  {
    if ( type === "week" )
    {
      this.isWeekChecked = !this.isWeekChecked;
      this.isMonthChecked = false;

      this.isWeekChecked ? this.f.week_day.enable() : this.f.week_day.disable();
      this.f.months.disable();
    } else if ( type === "month" )
    {
      this.isMonthChecked = !this.isMonthChecked;
      this.isWeekChecked = false;

      this.isMonthChecked ? this.f.months.enable() : this.f.months.disable();
      this.f.week_day.disable();
    }
  }

  recurring_toggle ()
  {
    this.recurring_check = !this.recurring_check;

    this.NewInvoice.get( 'select_interval' ).setValue( this.recurring_check );
    this.NewInvoice.get( 'recurring_type' ).setValue( '' );
    this.NewInvoice.get( 'quarter' ).setValue( '' );
    this.NewInvoice.get( 'month' ).setValue( '' );
    this.NewInvoice.get( 'day' ).setValue( '' );
    this.NewInvoice.updateValueAndValidity();
    if ( this.recurring_check )
    {
      this.NewInvoice.get( 'select_interval' ).setValidators( Validators.required );
      this.NewInvoice.get( 'recurring_type' ).setValidators( Validators.required );
      this.NewInvoice.updateValueAndValidity();
    } else
    {
      this.NewInvoice.get( 'select_interval' ).setValidators( null );
      this.NewInvoice.get( 'recurring_type' ).setValidators( null );
      this.NewInvoice.get( 'quarter' ).setValidators( null );
      this.NewInvoice.get( 'month' ).setValidators( null );
      this.NewInvoice.get( 'day' ).setValidators( null );
      this.NewInvoice.updateValueAndValidity();
    }


  }

  digitFormatter ( index: number )
  {
    let starting: any = `${ index }`;
    let transformFormat = '00000'
    this.transformedData = transformFormat.substring( 0, transformFormat.length - starting.length ) + starting;
    this._invoiceService.setInvoiceNumber = this.transformedData;
    return this.transformedData;
  }

  Unselect ( event )
  {
    if ( this.NewInvoice.value.advance_type != null )
    {

      this.NewInvoice.get( 'advance_type' ).reset();
      this.NewInvoice.get( 'advance_type' ).updateValueAndValidity();
    }
  }

  public isExpenseNull: boolean = false;

  expenseList() {
    if(this.isExpenseNull) {
      this.toastr.warning("Please add expense first");
    }
  }

  getExpenseList ()
  {
    this.loading = true;
    this.ExpenseServive.GetExpenseList()
      .subscribe( ( result ) =>
      {
        if ( result[ 'success' ] )
        {
          this.loading = false;
          this.expenseData = result[ 'data' ];
          if(this.expenseData) {
            this.isCustomerNull = false;
          }else {
            this.isCustomerNull = true;
          }
        } else
        {
          this.loading = false;
        }

        if ( this.invoice_expenseData.length > 0 )
        {
          for ( let i of this.invoice_expenseData )
          {
            for ( let [ index, obj ] of this.expenseData.entries() )
            {
              if ( i.expenses_id == obj.expenses_id )
              {
                this.expenseData.splice( index, 1 );
              } else
              {
                continue;
              }
            }
          }
        }
      }, ( err: HttpErrorResponse ) =>
      {
        if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
        {
          this.SettingService.LogOut();
        }
      } )

  }

  Expense ( event )
  {
    if ( event.target.value )
    {
      for ( let [ index, obj ] of this.expenseData.entries() )
      {
        if ( event.target.value == obj.expenses_id )
        {
          this.expenseData.splice( index, 1 );
        }
      }
      this.ExpenseServive.GetExpenseById( event.target.value ).subscribe(
        result =>
        {
          this.expenseArr.push( {
            expense_type: result[ 'data' ].type,
            expense_id: result[ 'data' ].expenses_id,
            expense_amount: result[ 'data' ].total,
            expense_number: result[ 'data' ].expense_number,
            expense_merchant: result[ 'data' ].merchant
          } )
          this.InvoiceModel.expense.push( result[ 'data' ].expenses_id );
        }, ( err: HttpErrorResponse ) =>
      {
        if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
        {
          this.SettingService.LogOut();
        }
      }
      )
    }
  }

  DeleteExpense ( expense_id, index )
  {
    this.ExpenseServive.GetExpenseById( expense_id ).subscribe(
      result =>
      {
        this.expenseData.push( result[ 'data' ] );
      }, ( err: HttpErrorResponse ) =>
    {
      if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
      {
        this.SettingService.LogOut();
      }
    }
    )
    this.expenseArr.splice( index, 1 );
    this.InvoiceModel.expense.splice( index, 1 );
    if ( this.expenseArr.length == 0 )
    {
      this.NewInvoice.get( 'expenses' ).setValidators( [ Validators.required ] );
      this.NewInvoice.get( 'expenses' ).updateValueAndValidity();
    }
    // for(let i of this.expenseData){

    //   if(i.expenses_id!=expense_id){
    //     this.ExpenseServive.GetExpenseById(expense_id).subscribe(
    //       result=>{
    //         this.expenseData.push(result['data']);
    //       }
    //     )
    //   }
    // }


  }

  Customer( event )
  {
    this.loading = true;
    if ( event )
    {
      this.CustomerService.GetCustomerById( event )
        .subscribe( ( result ) =>
        {

          this.CustomerModel.customer_id = result[ 'data' ].customer_id;
          this.CustomerModel.name = result[ 'data' ].name;
          this.CustomerModel.mobile = result[ 'data' ].mobile;
          this.CustomerModel.alternative_number = result[ 'data' ].alternative_number;
          this.alternative_number = this.CustomerModel.alternative_number;
          this.CustomerModel.email = result[ 'data' ].email;
          this.CustomerModel.address = result['data']['default_address'].address;
          this.CustomerModel.locality = result['data']['default_address'].locality;
          this.CustomerModel.zip = result['data']['default_address'].zip;
          this.loading = false;
          if ( !this.NewInvoice.value.customer_id )
          {
            this.NewInvoice.get( 'customer_id' ).setValue( this.CustomerModel.customer_id );
            this.NewInvoice.get( 'customer_id' ).updateValueAndValidity();
          }
        }, ( err: HttpErrorResponse ) =>
        {
          if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
          {
            this.SettingService.LogOut();
            this.loading = false;
          }
        }
        )
    }
  }

  GetInvoiceList ()
  {
    this._invoiceService.InvoiceList().subscribe(
      ( result ) =>
      {
        this.invoice_number = result[ 'data' ].length + 1;
        this.digitFormatter( this.invoice_number );
      }, ( err: HttpErrorResponse ) =>
    {
      if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
      {
        this.SettingService.LogOut();
      }
    }
    )
  }

  public isCustomerNull: boolean = false;

  GetCustomerList ()
  {
    if(this.isCustomerNull) {
      this.toastr.warning("Please add customer first");
    }
  }

  CustomerList ()
  {
    this._invoiceService.GetCustomerList().subscribe(
      result =>
      {
        if(result['data'].length) {
          this.isCustomerNull = false;
        }else {
          this.isCustomerNull = true;
        }
        this.customerList = result['data'];
        this.getInvoiceDetails( this.invoice_id );

      }, ( err: HttpErrorResponse ) =>
    {
      if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
      {
        this.SettingService.LogOut();
      }
    }
    )
  }

  GetCustomerListOnSearch ( event )
  {
    let data = {
      search: event.target.value
    }
    if ( data.search != '' )
    {
      this.CustomerService.GetCustomerListOnSearch( data ).subscribe(
        result =>
        {
          this.Customers = result[ 'data' ];

        }
      )
    } else
    {
      this.Customers = null;
    }


  }

  ItemById ()
  {


    this.ItemService.GetItemById( this.NewInvoice.value.line_item ).subscribe(
      result =>
      {
        if ( this.NewInvoice.value.quantity > ( +result[ 'data' ].quantity ) )
        {
          this.quantity_exceed = true;
          this.toastr.warning( result[ 'data' ].quantity + ' is present in our stock number of quantity!' )
        } else
        {
          this.quantity_exceed = false;
        }
      }, ( err: HttpErrorResponse ) =>
    {
      if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
      {
        this.SettingService.LogOut();
      }
    }
    )
  }

  get f ()
  {
    return this.NewInvoice.controls;
  }

  dollarActive ()
  {
    this.dollar = '$';
    this.percent = '';
  }

  percentActive ()
  {
    this.dollar = '';
    this.percent = '%';
  }

  isItemAdded: boolean = false;
  Step( step: number ){
    if ( step == 2 ){
      // window.scroll(0,0);
      this.submitted3 = false;
      if (this.discount_value == true) {
        return;
      }
      if ( ( this.NewInvoice.controls.customer_id.invalid ) ||
        ( this.NewInvoice.controls.quantity.invalid ) ||
        ( this.NewInvoice.controls.discount.invalid ) ||
        ( this.NewInvoice.controls.discount_type.invalid ) ||
        ( this.NewInvoice.controls.sales_tax.invalid )
        ){
        this.submitted = true;
        if(this.InvoiceModel.items.length == 0){
          this.add_item = true;
        }
        return;
      }
      if(this.InvoiceModel.items.length == 0){
        this.add_item = true;
        return;
      }

      if ( this.InvoiceModel.items.length > 0 ) {
        this.add_item = false;
      }
      let flag =0;
      if(this.NewInvoice.value.line_item){
        if(this.InvoiceModel.items.length>0)
        for(let value of  this.InvoiceModel.items){
          if(value.item_id == this.NewInvoice.value.line_item){

            flag =1;
            break;
            
          }else{
           flag =0;
          }
        }
        if(flag==0)
        {
          this.add_remove_item = true;
          return;
  
  
        }else{
          this.add_remove_item = false;
  
        }
      }
    }


    if ( ( this.NewInvoice.controls.discount_type.invalid ) )
    {
      return false;
    }
    this.step = step;
  }

  Route ()
  {
    const initialState: ModalOptions = {
      initialState: {
        type: "Invoice",
        path: "invoice-list",
        ignoreBackdropClick: true
      }
    };
    if(!this.invoice_id && 
      (this.NewInvoice.value.customer_id 
      || this.NewInvoice.value.discount
      || this.NewInvoice.value.discount_type
      || this.NewInvoice.value.sales_tax
      || (this.InvoiceModel.items.length>0))) 
      {
        this.modalRef = this.modalService.show(SaveProgressWorkComponent, initialState);
        this._invoiceService.setInvoiceDraftImages = this.files;
      } else {
        if ( this.step === 2 )
        {
          this.step = 1;
        } else
        {
          this._router.navigate( [ '/invoices' ] );
        }
      }
  }
  addTax() {
    const initialState: ModalOptions = {
      initialState: {
        type: "Invoice",
        path: "tax-setting",
        ignoreBackdropClick: true
      }
    };
    if(!this.invoice_id) {
      this.modalRef = this.modalService.show(SaveProgressWorkComponent, initialState);
    } else {
      this._router.navigate( [ '/tax-settings' ] );
    }
  }

  addChecklist() {
    const initialState: ModalOptions = {
      initialState: {
        type: "Invoice",
        path: "checklist",
        ignoreBackdropClick: true
      }
    };
    if(!this.invoice_id) {
      this.modalRef = this.modalService.show(SaveProgressWorkComponent, initialState);
    } else {
      this._router.navigate( [ '/checklist-add' ] );
    }
  }

  addCustomer() {
    const initialState: ModalOptions = {
      initialState: {
        type: "Invoice",
        path: "customer",
        ignoreBackdropClick: true
      }
    };
    if(!this.invoice_id) {
      this.modalRef = this.modalService.show(SaveProgressWorkComponent, initialState);
    } else {
      this._router.navigate( [ '/customer-new' ] );
    }
  }

  addExpense() {
    const initialState: ModalOptions = {
      initialState: {
        type: "Invoice",
        path: "expense",
        ignoreBackdropClick: true
      }
    };
    if(!this.invoice_id) {
      this.modalRef = this.modalService.show(SaveProgressWorkComponent, initialState);
    } else {
      this._router.navigate( [ '/expense-new' ] );
    }
  }

  Calculate ()
  {
    if ( this.amount == 0 )
    {
      this.InvoiceModel.total = 0;
    }
    
    if ( this.NewInvoice.value.discount_type != null &&
      this.NewInvoice.value.discount_type != '' &&
      this.NewInvoice.value.discount != null &&
      this.NewInvoice.value.discount != '' )
    {
      if ( this.NewInvoice.value.discount_type == '%' )
      {
        if(this.NewInvoice.value.discount>=100){
          this.discount_value = true;
          return;

        }else{
          this.discount_value = false;

        }
        this.discount_amount = 0;
        this.discount_amount = ( this.amount * this.NewInvoice.value.discount ) / 100;
        this.discount_amount = this.amount - this.discount_amount;
        this._invoiceService.setGrossAmount = this.discount_amount;
      } else if ( this.NewInvoice.value.discount_type == '$' )
      {
        if (this.NewInvoice.value.discount >= this.amount) {
          this.discount_value = true;
          return;

        } else {
          this.discount_value = false;
        }

        this.discount_amount = 0;
        this.discount_amount = this.amount - this.NewInvoice.value.discount;
        this._invoiceService.setGrossAmount = this.discount_amount;
      }
    }


    if ( this.NewInvoice.value.sales_tax != null || this.NewInvoice.value.sales_tax != '' )
    {
      if ( this.discount_amount == 0 )
      {
        this.total_amount = this.amount * ( 1 + ( this.NewInvoice.value.sales_tax / 100 ) );

        this.total_amount = ( Math.round( this.total_amount * 10000 ) / 10000 ).toFixed( 2 );
        this.salesTax_amount = this.total_amount;
        this._invoiceService.setGrossAmount = this.salesTax_amount;
        this._invoiceService.setTotalAmount = this.total_amount;
      } else
      {

        this.total_amount = this.discount_amount * ( 1 + ( this.NewInvoice.value.sales_tax / 100 ) );
        this.total_amount = ( Math.round( this.total_amount * 10000 ) / 10000 ).toFixed( 2 );
        this.salesTax_amount = this.total_amount;
        this._invoiceService.setGrossAmount = this.salesTax_amount;
        this._invoiceService.setTotalAmount = this.total_amount;
      }
    }

    if ( this.invoice_id === null )
    {
      if ( ( this.NewInvoice.value.advance != null && this.NewInvoice.value.advance != '' ) &&
        ( this.NewInvoice.value.advance_type != null && this.NewInvoice.value.advance_type != '' ) )
      {
        if ( this.NewInvoice.value.advance_type == "%" )
        {
          if(this.NewInvoice.value.advance>=100){
            this.advance_value = true;
  
          }else{
            this.advance_value = false;
  
          }

          this.total_amount = this.total_amount * ( 1 - this.NewInvoice.value.advance / 100 );
          this.total_amount = ( Math.round( this.total_amount * 10000 ) / 10000 ).toFixed( 2 )
          this._invoiceService.setTotalAmount = this.total_amount;
        } else
        {
          if (this.NewInvoice.value.advance > Number(this.total_amount)){
            this.advance_value = true;
  
          } else {
            this.advance_value = false;
  
          }

          this.total_amount = this.total_amount - this.NewInvoice.value.advance;
          this.total_amount = ( Math.round( this.total_amount * 10000 ) / 10000 ).toFixed( 2 );
          this._invoiceService.setTotalAmount = this.total_amount;
        }
      }

    } else if ( this.invoice_id !== null )
    {
      if ( ( this.InvoiceModel.advance != null && this.InvoiceModel.advance != '' ) &&
        ( this.InvoiceModel.advance_type != null && this.InvoiceModel.advance_type != '' ) )
      {
        if ( this.InvoiceModel.advance_type == "%" )
        {
          if(this.NewInvoice.value.advance>=100){
            this.advance_value = true;
  
          }else{
            this.advance_value = false;
  
          }
          this.total_amount = this.total_amount * ( 1 - this.InvoiceModel.advance / 100 );
          this.total_amount = ( Math.round( this.total_amount * 10000 ) / 10000 ).toFixed( 2 )
          this._invoiceService.setTotalAmount = this.total_amount;
        } else
        {
           if (this.NewInvoice.value.advance > Number(this.total_amount)){
            this.advance_value = true;
  
          } else {
            this.advance_value = false;
  
          }
          this.total_amount = this.total_amount - this.InvoiceModel.advance;
          this.total_amount = ( Math.round( this.total_amount * 10000 ) / 10000 ).toFixed( 2 )
          this._invoiceService.setTotalAmount = this.total_amount;
        }
      }
    }

    if ( this.discount_amount == 0 && this.total_amount == 0 )
    {
      this.total_amount = this.amount;
      this._invoiceService.setTotalAmount = this.total_amount;
    } else if ( this.discount_amount > 0 && this.total_amount == 0 )
    {
      this.total_amount = this.discount_amount;
      this._invoiceService.setTotalAmount = this.total_amount;
    } else if ( this.total_amount > 0 )
    {
      this.total_amount = this.total_amount;
      this._invoiceService.setTotalAmount = this.total_amount;
    }
  }
  //Change Unit Price

  ChangeUnitPrice ( event )
  {

  }

  // Add item
  AddItem(){
    this.add_item = false;
    this.add_remove_item = false;
    if ( ( this.NewInvoice.controls.unit_price.invalid ) || ( this.NewInvoice.controls.quantity.invalid ) )
    {
      this.submitted3 = true;
      return;
    }
    if ( this.quantity_exceed == true )
    {
      this.ItemById();
      return;
    }
    this.rate = this.NewInvoice.value.unit_price;
    let addItemField: HTMLInputElement = document.querySelector( '[name="bindValue"]' );
    let addQuantityField: HTMLInputElement = document.querySelector( '[name="quantity"]' );

    if ( this.NewInvoice.value.line_item !== '' )
    {

      if ( this.current_selected_item.group == 'Product' )
      {
        this.ItemService.GetItemById( this.NewInvoice.value.line_item )
          .subscribe( ( result ) =>
          {
            for ( let i in this.itemArr )
            {

              if ( this.itemArr[ i ].item_id == result[ 'data' ].name )
              {
                this.itemArr[ i ].qty = this.NewInvoice.value.quantity;
                this.itemArr[ i ].group = 'Product';
                this.itemArr[ i ].unit_price = this.rate;
                if ( result[ 'data' ].tax_rate )
                {
                  this.itemArr[ i ].tax = result[ 'data' ].item_taxrate.rate + '%';
                  this.itemArr[ i ].rate = ( this.NewInvoice.value.quantity * ( +this.rate + ( +this.rate * ( result[ 'data' ].item_taxrate.rate / 100 ) ) ) ).toFixed( 2 );
                } else
                {
                  this.itemArr[ i ].tax = 'NA';
                  this.itemArr[ i ].rate = this.NewInvoice.value.quantity * ( +this.rate );

                }
                this.item_count = 1;
                break;
              }

            }

            if ( result[ 'data' ].tax_rate )
            {
              if ( this.item_count == 0 )
              {
                this.itemArr.push( {
                  item_id: result[ 'data' ].name,
                  group: 'Product',
                  qty: this.NewInvoice.value.quantity,
                  unit_price: this.rate,
                  rate: ( this.NewInvoice.value.quantity * ( +this.rate + ( +this.rate * ( result[ 'data' ].item_taxrate.rate / 100 ) ) ) ).toFixed( 2 ),
                  tax: result[ 'data' ].item_taxrate.rate + '%'
                } );
              }
            } else
            {
              if ( this.item_count == 0 )
              {
                this.itemArr.push( {
                  item_id: result[ 'data' ].name,
                  group: 'Product',
                  qty: this.NewInvoice.value.quantity,
                  unit_price: this.rate,
                  rate: this.NewInvoice.value.quantity * ( +this.rate ),
                  tax: 'NA'
                } );
              }
            }

            this.item_count = 0;
            this.itemIdArr.push( result[ 'data' ].item_id );

            this.amount = 0;


            if ( this.amount == 0 )
            {
              for ( let i of this.itemArr )
              {
                this.amount = this.amount + ( +i.rate );
                this._invoiceService.setAmount = this.amount;
              }
            }

            if ( this.invoice_id == null )
            {
              if ( this.NewInvoice.value.unit_price > 0 )
              {
                this.NewInvoice.controls.unit_price.setValue( 0 );
                this.NewInvoice.updateValueAndValidity();
              }
              if ( this.NewInvoice.value.quantity > 0 )
              {
                // this.amount = this.amount + ((+result['data'].rate) * this.NewInvoice.value.quantity);
              } else if ( this.NewInvoice.value.quantity == '' || this.NewInvoice.value.quantity == null )
              {
                this.NewInvoice.controls.quantity.setValue( 1 );
                // this.amount = this.amount + ((+result['data'].rate) * this.NewInvoice.value.quantity);
              }

              this.Calculate();
              this.NewInvoice.controls.quantity.setValue( 1 );
              this.NewInvoice.updateValueAndValidity();
            } else
            {
              if ( this.NewInvoice.value.unit_price > 0 )
              {
                this.NewInvoice.controls.unit_price.setValue( 0 );
                this.NewInvoice.updateValueAndValidity();
              }

              if ( this.NewInvoice.value.quantity > 0 )
              {
                // this.amount = this.amount + ((+result['data'].rate) * this.NewInvoice.value.quantity);
              } else if ( this.NewInvoice.value.quantity == '' || this.NewInvoice.value.quantity == null )
              {
                this.NewInvoice.controls.quantity.setValue( 1 );
                // this.amount = this.amount + ((+result['data'].rate) * this.NewInvoice.value.quantity);
              }


              this.Calculate();
              this.InvoiceModel.amount = this.amount;
              this.NewInvoice.controls.quantity.setValue( 1 );
              this.NewInvoice.updateValueAndValidity();

            }
          }, ( err: HttpErrorResponse ) =>
          {
            if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
            {
              this.SettingService.LogOut();
            }
          } );
      } else if ( this.current_selected_item.group == 'Service' )
      {
        this.serviceService.GetServiceById( this.NewInvoice.value.line_item )
          .subscribe( ( result ) =>
          {
            for ( let i in this.itemArr )
            {

              if ( this.itemArr[ i ].item_id == result[ 'data' ].name )
              {
                this.itemArr[ i ].qty = this.NewInvoice.value.quantity;
                this.itemArr[ i ].group = 'Service';
                this.itemArr[ i ].unit_price = this.rate;
                if ( result[ 'data' ].tax_rate )
                {
                  this.itemArr[ i ].tax = result[ 'data' ].service_taxrate.rate + '%';
                  this.itemArr[ i ].rate = ( this.NewInvoice.value.quantity * ( +this.rate + ( +this.rate * ( result[ 'data' ].service_taxrate.rate / 100 ) ) ) ).toFixed( 2 );

                } else
                {
                  this.itemArr[ i ].rate = this.NewInvoice.value.quantity * ( +this.rate );
                  this.itemArr[ i ].tax = 'NA';

                }
                this.item_count = 1;
                break;
              }

            }

            if ( result[ 'data' ].tax_rate )
            {
              if ( this.item_count == 0 )
              {
                this.itemArr.push( {
                  item_id: result[ 'data' ].name,
                  group: 'Service',
                  qty: this.NewInvoice.value.quantity,
                  unit_price: this.rate,
                  rate: ( this.NewInvoice.value.quantity * ( +this.rate + ( +this.rate * ( result[ 'data' ].service_taxrate.rate / 100 ) ) ) ).toFixed( 2 ),
                  tax: result[ 'data' ].service_taxrate.rate + '%'
                } );
              }
            } else
            {
              if ( this.item_count == 0 )
              {
                this.itemArr.push( {
                  item_id: result[ 'data' ].name,
                  group: 'Service',
                  qty: this.NewInvoice.value.quantity,
                  unit_price: this.rate,
                  rate: this.NewInvoice.value.quantity * ( +this.rate ),
                  tax: 'NA'
                } );
              }
            }
            this.item_count = 0;
            this.itemIdArr.push( result[ 'data' ].item_id );

            this.amount = 0;


            if ( this.amount == 0 )
            {
              for ( let i of this.itemArr )
              {
                this.amount = this.amount + ( +i.rate );
                this._invoiceService.setAmount = this.amount;
              }
            }

            if ( this.invoice_id == null )
            {
              if ( this.NewInvoice.value.unit_price > 0 )
              {
                this.NewInvoice.controls.unit_price.setValue( 0 );
                this.NewInvoice.updateValueAndValidity();
              }
              if ( this.NewInvoice.value.quantity > 0 )
              {
                // this.amount = this.amount + ((+result['data'].rate) * this.NewInvoice.value.quantity);
              } else if ( this.NewInvoice.value.quantity == '' || this.NewInvoice.value.quantity == null )
              {
                this.NewInvoice.controls.quantity.setValue( 1 );
                // this.amount = this.amount + ((+result['data'].rate) * this.NewInvoice.value.quantity);
              }

              this.Calculate();
              this.NewInvoice.controls.quantity.setValue( 1 );
              this.NewInvoice.updateValueAndValidity();
            } else
            {
              if ( this.NewInvoice.value.unit_price > 0 )
              {
                this.NewInvoice.controls.unit_price.setValue( 0 );
                this.NewInvoice.updateValueAndValidity();
              }

              if ( this.NewInvoice.value.quantity > 0 )
              {
                // this.amount = this.amount + ((+result['data'].rate) * this.NewInvoice.value.quantity);
              } else if ( this.NewInvoice.value.quantity == '' || this.NewInvoice.value.quantity == null )
              {
                this.NewInvoice.controls.quantity.setValue( 1 );
                // this.amount = this.amount + ((+result['data'].rate) * this.NewInvoice.value.quantity);
              }


              this.Calculate();
              this.InvoiceModel.amount = this.amount;
              this.NewInvoice.controls.quantity.setValue( 1 );
              this.NewInvoice.updateValueAndValidity();

            }
          }, ( err: HttpErrorResponse ) =>
          {
            if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
            {
              this.SettingService.LogOut();
            }
          } );
      }

      for ( let i in this.InvoiceModel.items )
      {

        if ( this.InvoiceModel.items[ i ].item_id == this.NewInvoice.value.line_item )
        {
          this.InvoiceModel.items[ i ].qty = this.NewInvoice.value.quantity;
          this.InvoiceModel.items[ i ].group = this.current_selected_item.group;
          this.InvoiceModel.items[ i ].unit_price = this.rate;
          this.InvoiceModel.items[ i ].tax = this.current_tax;
          this.model_item_count = 1;
          break;
        }
      }

      if ( this.model_item_count == 0 )
      {
        this.InvoiceModel.items.push( {
          item_id: this.NewInvoice.value.line_item,
          group: this.current_selected_item.group,
          qty: addQuantityField.value || '1',
          unit_price: this.rate,
          tax: this.current_tax
        } );
      }

      this.model_item_count = 0;
    } else
    {
      return;
    }
   
    this.NewInvoice.get('line_item').setValue(null);
    this.NewInvoice.get('line_item').updateValueAndValidity();

    setTimeout(()=>{
      this._invoiceService.service_response_data.next(null);
      this._invoiceService.product_response_data.next(null);

    },0)

    if(this.itemArr.length > 0) {
      this.isItemAdded = false;
    }
  }

  DeleteItem ( index: number )
  {

    let spliced_item = this.itemArr.splice( index, 1 );

    let id = this.itemIdArr.splice( index, 1 );
    let invoice_amount = this.InvoiceModel.items.splice( index, 1 );

    if ( this.InvoiceModel.items.length == 0 )
    {
      this.NewInvoice.get( 'line_item' ).setValue( '' );

      this.NewInvoice.get( 'line_item' ).setValidators( [ Validators.required ] );
      this.NewInvoice.get( 'line_item' ).updateValueAndValidity();
    }

    if ( spliced_item[ 0 ].group == 'Product' )
    {
      for ( let i of invoice_amount )
      {
        this.ItemService.GetItemById( i.item_id ).subscribe(
          result =>
          {
            if ( this.invoice_id == null )
            {
              if ( i.tax )
              {
                this.amount = this.amount - ( ( +i.unit_price + ( +i.unit_price * ( +i.tax / 100 ) ) ) * i.qty );
                this._invoiceService.setGrossAmount = this.amount;
                this.InvoiceModel.gross_amount = this.amount;
              } else
              {
                this.amount = this.amount - ( +i.unit_price * i.qty );
                this._invoiceService.setGrossAmount = this.amount;
                this.InvoiceModel.gross_amount = this.amount;
              }
              // this.DiscountCalculate();
              // if (this.NewInvoice.value.sales_tax != null || this.NewInvoice.value.sales_tax != '') {
              //   this.SalesTax(null, this.NewInvoice.value.sales_tax);
              // }

              this.Calculate();
            } else
            {
              if ( i.tax )
              {
                this.amount = this.amount - ( ( +i.unit_price + ( +i.unit_price * ( +i.tax / 100 ) ) ) * i.qty );
                this.InvoiceModel.gross_amount = this.amount;
                this._invoiceService.setGrossAmount = this.amount;
              } else
              {
                this.amount = this.amount - ( +i.unit_price * i.qty );
                this.InvoiceModel.gross_amount = this.amount;
                this._invoiceService.setGrossAmount = this.amount;
              }
              // this.amount = this.amount - (+i.unit_price * i.qty);
              this.InvoiceModel.amount = this.amount;
              // this.DiscountCalculate();

              this.Calculate();
            }
          }, ( err: HttpErrorResponse ) =>
        {
          if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
          {
            this.SettingService.LogOut();
          }
        }
        )
      }
    } else if ( spliced_item[ 0 ].group == 'Service' )
    {
      for ( let i of invoice_amount )
      {
        this.serviceService.GetServiceById( i.item_id ).subscribe(
          result =>
          {
            if ( this.invoice_id == null )
            {
              if ( i.tax )
              {
                this.amount = this.amount - ( ( +i.unit_price + ( +i.unit_price * ( +i.tax / 100 ) ) ) * i.qty );
                this._invoiceService.setAmount = this.amount;
              } else
              {
                this.amount = this.amount - ( +i.unit_price * i.qty );
                this._invoiceService.setAmount = this.amount;
              }

              this.Calculate();
            } else
            {
              // this.amount = this.amount - (+i.unit_price * i.qty);
              if ( i.tax )
              {
                this.amount = this.amount - ( ( +i.unit_price + ( +i.unit_price * ( +i.tax / 100 ) ) ) * i.qty );
                this._invoiceService.setAmount = this.amount;
              } else
              {
                this.amount = this.amount - ( +i.unit_price * i.qty );
                this._invoiceService.setAmount = this.amount;
              }
              this.InvoiceModel.amount = this.amount;
              // this.DiscountCalculate();

              this.Calculate();
            }
          }, ( err: HttpErrorResponse ) =>
        {
          if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
          {
            this.SettingService.LogOut();
          }
        }
        )

      }
    }

  }

  DiscountCalculate ()
  {
    this.Calculate();
  }

  AdvanceCalculate ()
  {
    this.Calculate();
  }


  ViewProductOrSeviceData ( evt ){

    if(!this.NewInvoice.value.line_item || !evt){
      this.add_remove_item = false
      this.NewInvoice.get('unit_price').setValue(0);
      this.NewInvoice.get('quantity').setValue(1);
      this.NewInvoice.updateValueAndValidity();
      this.addBtnDisable = true;
    }
    if(evt){
      this.addBtnLoader = true;
      this.addBtnDisable = true;
      this.current_selected_item = evt;

      let value = evt[ 'id' ];
      if ( evt[ 'group' ] == 'Product' )
      {
        this._invoiceService.ViewListData( value ).subscribe(
          ( result: any ) =>
          {
            this.itemData = result[ 'data' ];
            this.rate = result[ 'data' ][ 'rate' ];
            if ( result[ 'data' ].tax_rate )
            {
              this.current_tax = result[ 'data' ].item_taxrate.rate;
            }
            else
            {
              this.current_tax = null;
            }
            this.addBtnLoader = false;
            this.addBtnDisable = false;
  
          }, ( err: HttpErrorResponse ) =>
        {
          this.addBtnLoader = false;
          this.addBtnDisable = false;

          if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
          {
            this.SettingService.LogOut();
          }
        }
        )
      } else if ( evt[ 'group' ] == 'Service' )
      {
        this.serviceService.GetServiceById( value ).subscribe(
          ( result: any ) =>
          {
            this.itemData = result[ 'data' ];
            this.rate = +result[ 'data' ].rate;
            
            if ( result[ 'data' ].tax_rate)
            {
              this.current_tax = result[ 'data' ].service_taxrate.rate;
            } else
            {
              this.current_tax = null;
            }
            this.addBtnLoader = false;
            this.addBtnDisable = false;
          }, ( err: HttpErrorResponse ) =>
        {
          this.addBtnLoader = false;
          this.addBtnDisable = false;
  
          if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
          {
            this.SettingService.LogOut();
          }
        }
        )
      }

    }


  }

  getInvoiceDetails ( invoice_id ){
    // this.loading = true;

    if ( invoice_id != null )
    {
      this._invoiceService.InvoiceView( invoice_id ).subscribe(
        result =>
        {
          this.loading = false;
          this.InvoiceModel.customer_id = result[ 'data' ].customer_id;
          if ( result[ 'data' ][ 'invoice_customers' ] )
          {
            this.CustomerModel.customer_id = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'customer_id' ];
            this.CustomerModel.name = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'name' ];

            this.CustomerModel.address = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'address' ];
            this.CustomerModel.email = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'email' ];
            this.CustomerModel.zip = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'zip' ];
            this.CustomerModel.locality = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'locality' ];
            this.CustomerModel.mobile = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'mobile' ];
            this.CustomerModel.alternative_number = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'alternative_number' ];
            this.alternative_number = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'alternative_number' ];
          }
          this.InvoiceModel.invoice_recurring_details_id = result[ 'data' ].invoice_agreement_recurring_details_id || null;
          if ( result[ 'data' ][ 'invoice_invoiceitems' ] != null || result[ 'data' ][ 'invoice_invoiceitems' ].length > 0 )
          {
            this.InvoiceModel.line_item = result[ 'data' ][ 'invoice_invoiceitems' ][ 0 ].item_id;
            this.NewInvoice.get( 'line_item' ).clearValidators();
            this.NewInvoice.get( 'line_item' ).updateValueAndValidity();

            for ( let index of result[ 'data' ][ 'invoice_invoiceitems' ] )
            {
              if ( index.group == 'Product' )
              {
                if ( index.invoiceitems_items_with_trashed.tax_rate )
                {
                  this.InvoiceModel.items.push( {
                    item_id: index.invoiceitems_items_with_trashed.item_id,
                    qty: index.quantity,
                    group: index.group,
                    unit_price: index.rate,
                    tax: index.invoiceitems_items_with_trashed.tax_rate_data.rate,
                    previous_quantity: index.quantity
                  } );
                  this.itemArr.push( {
                    item_id: index.invoiceitems_items_with_trashed.name,
                    qty: index.quantity,
                    rate: ( index.quantity * ( +index.rate + ( +index.rate * ( index.invoiceitems_items_with_trashed.tax_rate_data.rate / 100 ) ) ) ).toFixed( 2 ),
                    unit_price: index.rate,
                    tax: index.invoiceitems_items_with_trashed.tax_rate_data.rate + '%',
                    previous_quantity: index.quantity,
                    group: index.group,

                  } );
                } else
                {
                  this.InvoiceModel.items.push( {
                    item_id: index.invoiceitems_items_with_trashed.item_id,
                    qty: index.quantity,
                    group: index.group,
                    unit_price: index.rate,
                    previous_quantity: index.quantity
                  } );
                  this.itemArr.push( {
                    item_id: index.invoiceitems_items_with_trashed.name,
                    qty: index.quantity,
                    rate: index.quantity * ( +index.rate ),
                    unit_price: index.rate,
                    previous_quantity: index.quantity,
                    group: index.group,
                    tax: 'NA'
                  } );
                }
              } else if ( index.group == 'Service' )
              {
                if ( index.invoiceitems_service_with_trashed.tax_rate )
                {
                  this.InvoiceModel.items.push( {
                    item_id: index.invoiceitems_service_with_trashed.service_id,
                    qty: index.quantity,
                    unit_price: index.rate,
                    tax: index.invoiceitems_service_with_trashed.tax_rate_data.rate,
                    group: index.group,
                  } );
                  this.itemArr.push( {
                    item_id: index.invoiceitems_service_with_trashed.name,
                    qty: index.quantity,
                    rate: ( index.quantity * ( +index.rate + ( +index.rate * ( index.invoiceitems_service_with_trashed.tax_rate_data.rate / 100 ) ) ) ).toFixed( 2 ),
                    unit_price: index.rate,
                    group: index.group,
                    tax: index.invoiceitems_service_with_trashed.tax_rate_data.rate + '%',


                  } );
                } else
                {
                  this.InvoiceModel.items.push( {
                    item_id: index.invoiceitems_service_with_trashed.service_id,
                    qty: index.quantity,
                    unit_price: index.rate,
                    group: index.group,
                  } );
                  this.itemArr.push( {
                    item_id: index.invoiceitems_service_with_trashed.name,
                    qty: index.quantity,
                    rate: index.quantity * ( +index.rate ),
                    unit_price: index.rate,
                    group: index.group,
                    tax: 'NA'
                  } );
                }

              }

            }
          }
          if ( result[ 'data' ][ 'invoice_invoiceexpenses' ] != null && result[ 'data' ][ 'invoice_invoiceexpenses' ].length > 0 )
          {
            this.InvoiceModel.expenses = result[ 'data' ][ 'invoice_invoiceexpenses' ][ 0 ].expenses_id;
            this.NewInvoice.get( 'expenses' ).clearValidators();
            this.NewInvoice.get( 'expenses' ).updateValueAndValidity();

            for ( let index of result[ 'data' ][ 'invoice_invoiceexpenses' ] )
            {
              this.InvoiceModel.expense.push(
                index[ 'expense_id' ]
              );
              this.expenseArr.push( {
                expense_type: index.invoiceexpenses_expenses.type,
                expense_id: index.invoiceexpenses_expenses.expenses_id,
                expense_amount: index.invoiceexpenses_expenses.total,
                expense_number: index.invoiceexpenses_expenses.expense_number,
                expense_merchant: index.invoiceexpenses_expenses.merchant
              } );
              this.invoice_expenseData.push( index.invoiceexpenses_expenses );
            }
          }
          this.InvoiceModel.discount = result[ 'data' ].discount;
          this.InvoiceModel.discount_type = result[ 'data' ].discount_type;
          if ( this.InvoiceModel.discount_type != null && this.InvoiceModel.discount_type != '' )
          {
            if ( this.InvoiceModel.discount_type == '%' )
            {

              this.isCheckedDiscountPercent = true;
            } else if ( this.InvoiceModel.discount_type == '$' )
            {

              this.isCheckedDiscountDollar = true;
            }
          }
          this.InvoiceModel.sales_tax = result[ 'data' ].sales_tax;
          this.InvoiceModel.advance = result[ 'data' ].advance;
          this.InvoiceModel.advance_type = result[ 'data' ].advance_type;
          this.InvoiceModel.gross_amount = result[ 'data' ].gross_amount;
          this.InvoiceModel.net_amount = result[ 'data' ].net_amount;
          this.InvoiceModel.advance_paid = result[ 'data' ].advance_paid;
          if ( this.InvoiceModel.advance_type != null && this.InvoiceModel.advance_type != '' )
          {

            if ( this.InvoiceModel.advance_type == '%' )
            {


              this.isCheckedPercent = true;
            } else if ( this.InvoiceModel.advance_type == '$' )
            {


              this.isCheckedDollar = true;
            }
          }
          this.InvoiceModel.due_date = result[ 'data' ].due_date;
          this.InvoiceModel.amount = result[ 'data' ].amount;
          this.InvoiceModel.total = result[ 'data' ].total;
          this.amount = result[ 'data' ].amount;
          this.total_amount = result[ 'data' ].total;
          this.InvoiceModel.note = result[ 'data' ].note;
          this.InvoiceModel.private_note = result[ 'data' ].private_note;
          this.InvoiceModel.checklist_id = result[ 'data' ].checklist_id;
          this.InvoiceModel.invoice_number = result[ 'data' ].invoice_number;
          this.InvoiceModel.select_interval = result[ 'data' ].select_interval;
          if ( result[ 'data' ][ 'invoice_recurrings' ] != null && result[ 'data' ][ 'invoice_recurrings' ] != '' )
          {
            this.selected = true;

            this.InvoiceModel.day = result[ 'data' ][ 'invoice_recurrings' ].day;
            this.InvoiceModel.month = result[ 'data' ][ 'invoice_recurrings' ].month;
            this.InvoiceModel.quarter = result[ 'data' ][ 'invoice_recurrings' ].quarter;

            this.InvoiceModel.recurring_type = result[ 'data' ][ 'invoice_recurrings' ][ 'recurring_type' ];
          }
          this.recurring_check = result[ 'data' ].select_interval;
          this.recurring_value = this.InvoiceModel.recurring_type;
          if ( this.recurring_value == 'Weekly' )
          {
            setTimeout( () =>
            {
              this.NewInvoice.get( 'weekly_day' ).setValue( this.InvoiceModel.day );
              this.NewInvoice.get( 'weekly_day' ).updateValueAndValidity();

            }, 0 );
          } else if ( this.recurring_value == 'Biweekly' )
          {
            setTimeout( () =>
            {
              this.NewInvoice.get( 'biweekly_day' ).setValue( this.InvoiceModel.day );
              this.NewInvoice.get( 'biweekly_day' ).updateValueAndValidity();

            }, 0 );
          }




        }, ( err: HttpErrorResponse ) =>
      {
        if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
        {
          this.SettingService.LogOut();
        }
      }
      )
    } else
    {
      this.loading = false;
    }
  }

  Discount ( event )
  {
    
    if ( event.target.value == null || event.target.value == '' )
    {
      this.discount_value = false;
      this.NewInvoice.get( 'discount' ).setValidators( [ Validators.required, Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ] );
      this.NewInvoice.get( 'discount' ).setValue( '' );
      this.NewInvoice.updateValueAndValidity();
      this.discount_amount = 0;
    } else
    {
      this.NewInvoice.get( 'discount_type' ).setValidators( Validators.required );
      this.NewInvoice.get( 'discount_type' ).updateValueAndValidity();
      if ( ( this.NewInvoice.value.discount != null ||
        this.NewInvoice.value.discount != '' ) &&
        ( this.NewInvoice.value.discount_type != null ||
          this.NewInvoice.value.discount_type != '' ) )
      {
        // this.DiscountCalculate();
        this.Calculate();

      }
    }

    if ( this.NewInvoice.value.discount == null || this.NewInvoice.value.discount == '' )
    {
      if ( this.NewInvoice.value.discount_type == null || this.NewInvoice.value.discount_type == '' )
      {
        this.NewInvoice.get( 'discount' ).clearValidators();
        this.NewInvoice.get( 'discount_type' ).clearValidators();
        this.NewInvoice.get( 'discount' ).updateValueAndValidity();
        this.NewInvoice.get( 'discount_type' ).updateValueAndValidity();
      }
    }

    if ( this.NewInvoice.value.discount_type != null || this.NewInvoice.value.discount_type != '' )
    {
      this.Calculate();

    }
  }

  Advance ( evt: Event )
  {
    if ( evt.target[ 'value' ] == null || evt.target[ 'value' ] == '' )
    {
      this.advance_value = false;
      this.NewInvoice.get( 'advance' ).setValidators( [ Validators.required, Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ] );
      this.NewInvoice.get( 'advance' ).setValue( '' );
      this.NewInvoice.updateValueAndValidity();
    } else
    {

      this.NewInvoice.get( 'advance_type' ).setValidators( Validators.required );
      this.NewInvoice.get( 'advance_type' ).updateValueAndValidity();
      if ( ( this.NewInvoice.value.advance != null ||
        this.NewInvoice.value.advance != '' ) &&
        ( this.NewInvoice.value.advance_type != null ||
          this.NewInvoice.value.advance_type != '' ) )
      {
        this.Calculate();
      }
    }

    if ( this.NewInvoice.value.advance == null || this.NewInvoice.value.advance == '' )
    {
      if ( this.NewInvoice.value.advance_type == null || this.NewInvoice.value.advance_type == '' )
      {
        this.NewInvoice.get( 'advance' ).clearValidators();
        this.NewInvoice.get( 'advance_type' ).clearValidators();
        this.NewInvoice.get( 'advance' ).updateValueAndValidity();
        this.NewInvoice.get( 'advance_type' ).updateValueAndValidity();
      }
    }
  }

  DiscountInPercentage ( event )
  {
    this.percentActive();
    if ( ( event.target.value != null || event.target.value != '' ) && event.target.name == 'discount_type' )
    {
      if ( this.NewInvoice.value.discount == null )
      {
        this.NewInvoice.get( 'discount' ).setValidators( [ Validators.required, Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ] );
        this.NewInvoice.get( 'discount' ).setValue( '' );
        this.NewInvoice.updateValueAndValidity();
      }

      if ( this.NewInvoice.value.discount != null && this.NewInvoice.value.discount != '' )
      {
        this.Calculate();
      }
    }
    
  }

  DiscountInDollar ( event )
  {
    this.dollarActive();
    if ( ( event.target.value != null || event.target.value != '' ) && event.target.name == 'discount_type' )
    {
      if ( this.NewInvoice.value.discount == null )
      {
        this.NewInvoice.get( 'discount' ).setValidators( [ Validators.required, Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ] );
        this.NewInvoice.get( 'discount' ).setValue( '' );
        this.NewInvoice.updateValueAndValidity();
      }
      if ( this.NewInvoice.value.discount != null && this.NewInvoice.value.discount != '' )
      {
        this.Calculate();
      }
    }
  }

  unCheckPercent ( evt: Event )
  {
    this.isCheckedPercent = !this.isCheckedPercent;
    this.isCheckedDollar = false;
    if ( this.isCheckedPercent == false && ( this.NewInvoice.value.advance == null || this.NewInvoice.value.advance == '' ) )
    {
      this.NewInvoice.get( 'advance_type' ).setValue( '' );
      this.NewInvoice.get( 'advance_type' ).clearValidators();
      this.NewInvoice.get( 'advance' ).clearValidators();

      this.NewInvoice.get( 'advance_type' ).updateValueAndValidity();
      this.NewInvoice.get( 'advance' ).updateValueAndValidity();
    } else if ( this.isCheckedPercent == false && ( this.NewInvoice.value.advance != null || this.NewInvoice.value.advance != '' ) )
    {
      this.NewInvoice.get( 'advance_type' ).setValue( '' );
      this.NewInvoice.get( 'advance_type' ).updateValueAndValidity();

      this.NewInvoice.get( 'advance_type' ).setValidators( Validators.required );
      this.NewInvoice.get( 'advance' ).setValidators( [ Validators.required, Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ] );

      this.NewInvoice.get( 'advance_type' ).updateValueAndValidity();
      this.NewInvoice.get( 'advance' ).updateValueAndValidity();
    } else
    {
      this.NewInvoice.get( 'advance_type' ).setValidators( Validators.required );
      this.NewInvoice.get( 'advance' ).setValidators( [ Validators.required, Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ] );

      this.NewInvoice.get( 'advance_type' ).updateValueAndValidity();
      this.NewInvoice.get( 'advance' ).updateValueAndValidity();
    }

    this.Calculate();

  }

  AdvanceInPercentage ( event )
  {
    this.percentActive();
    if ( ( event.target.value != null || event.target.value != '' ) &&
      event.target.name == 'advance_type' )
    {
      if ( this.NewInvoice.value.advance == null )
      {
        this.NewInvoice.get( 'advance' ).setValidators( [ Validators.required, Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ] );
        this.NewInvoice.get( 'advance' ).setValue( '' );
        this.NewInvoice.updateValueAndValidity();
      }
      this.Calculate();
    }
  }

  unCheckDollar ( evt: Event )
  {
    this.isCheckedDollar = !this.isCheckedDollar;
    this.isCheckedPercent = false;
    if ( this.isCheckedDollar == false && ( this.NewInvoice.value.advance == null || this.NewInvoice.value.advance == '' ) )
    {
      this.NewInvoice.get( 'advance_type' ).setValue( '' );
      this.NewInvoice.get( 'advance_type' ).clearValidators();
      this.NewInvoice.get( 'advance' ).clearValidators();

      this.NewInvoice.get( 'advance_type' ).updateValueAndValidity();
      this.NewInvoice.get( 'advance' ).updateValueAndValidity();
    } else if ( this.isCheckedDollar == false && ( this.NewInvoice.value.advance != null || this.NewInvoice.value.advance != '' ) )
    {
      this.NewInvoice.get( 'advance_type' ).setValue( '' );
      this.NewInvoice.get( 'advance_type' ).updateValueAndValidity();

      this.NewInvoice.get( 'advance_type' ).setValidators( Validators.required );
      this.NewInvoice.get( 'advance' ).setValidators( [ Validators.required, Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ] );

      this.NewInvoice.get( 'advance_type' ).updateValueAndValidity();
      this.NewInvoice.get( 'advance' ).updateValueAndValidity();
    } else
    {
      this.NewInvoice.get( 'advance_type' ).setValidators( Validators.required );
      this.NewInvoice.get( 'advance' ).setValidators( [ Validators.required, Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ] );

      this.NewInvoice.get( 'advance_type' ).updateValueAndValidity();
      this.NewInvoice.get( 'advance' ).updateValueAndValidity();
    }

    this.Calculate();

  }

  AdvanceInDollar ( event )
  {
    this.dollarActive();
    if ( ( event.target.value != null || event.target.value != '' ) &&
      event.target.name == 'advance_type' )
    {
      if ( this.NewInvoice.value.advance == null )
      {
        this.NewInvoice.get( 'advance' ).setValidators( [ Validators.required, Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ] );
        this.NewInvoice.get( 'advance' ).setValue( '' );
        this.NewInvoice.updateValueAndValidity();
      }
      this.Calculate();
    }
  }

  ValueReset ( event )
  {
    if ( event.target.name == 'discount' )
    {
      if ( this.NewInvoice.value.discount_type == null )
      {
        this.NewInvoice.get( 'discount_type' ).setValue( '' );
      }
    }

    if ( event.target.name == 'advance' )
    {
      if ( this.NewInvoice.value.advance_type == null )
      {
        this.NewInvoice.get( 'advance_type' ).setValue( '' );
      }
    }
  }

  UncheckDiscoutPercent ( evt: Event )
  {
    this.isCheckedDiscountPercent = !this.isCheckedDiscountPercent;
    this.isCheckedDiscountDollar = false;
    if ( this.isCheckedDiscountPercent == false && ( this.NewInvoice.value.discount == null || this.NewInvoice.value.discount == '' ) )
    {
      this.NewInvoice.get( 'discount_type' ).setValue( '' );
      this.NewInvoice.get( 'discount_type' ).clearValidators();
      this.NewInvoice.get( 'discount' ).clearValidators();

      this.NewInvoice.get( 'discount_type' ).updateValueAndValidity();
      this.NewInvoice.get( 'discount' ).updateValueAndValidity();
    } else if ( this.isCheckedDiscountPercent == false && ( this.NewInvoice.value.discount != null || this.NewInvoice.value.discount != '' ) )
    {
      this.NewInvoice.get( 'discount_type' ).setValue( '' );
      this.NewInvoice.get( 'discount_type' ).updateValueAndValidity();

      this.NewInvoice.get( 'discount_type' ).setValidators( Validators.required );
      this.NewInvoice.get( 'discount' ).setValidators( [ Validators.required, Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ] );

      this.NewInvoice.get( 'discount_type' ).updateValueAndValidity();
      this.NewInvoice.get( 'discount' ).updateValueAndValidity();
    } else
    {
      this.NewInvoice.get( 'discount_type' ).setValidators( Validators.required );
      this.NewInvoice.get( 'discount' ).setValidators( [ Validators.required, Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ] );

      this.NewInvoice.get( 'discount_type' ).updateValueAndValidity();
      this.NewInvoice.get( 'discount' ).updateValueAndValidity();
    }

    this.Calculate();

  }

  UncheckDiscoutDollar ( evt: Event )
  {
    this.isCheckedDiscountDollar = !this.isCheckedDiscountDollar;
    this.isCheckedDiscountPercent = false;
    if ( this.isCheckedDiscountDollar == false && ( this.NewInvoice.value.discount == null || this.NewInvoice.value.discount == '' ) )
    {
      this.NewInvoice.get( 'discount_type' ).setValue( '' );
      this.NewInvoice.get( 'discount_type' ).clearValidators();
      this.NewInvoice.get( 'discount' ).clearValidators();

      this.NewInvoice.get( 'discount_type' ).updateValueAndValidity();
      this.NewInvoice.get( 'discount' ).updateValueAndValidity();
    } else if ( this.isCheckedDiscountDollar == false && ( this.NewInvoice.value.discount != null || this.NewInvoice.value.discount != '' ) )
    {
      this.NewInvoice.get( 'discount_type' ).setValue( '' );
      this.NewInvoice.get( 'discount_type' ).updateValueAndValidity();

      this.NewInvoice.get( 'discount_type' ).setValidators( Validators.required );
      this.NewInvoice.get( 'discount' ).setValidators( [ Validators.required, Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ] );

      this.NewInvoice.get( 'discount_type' ).updateValueAndValidity();
      this.NewInvoice.get( 'discount' ).updateValueAndValidity();
    } else
    {
      this.NewInvoice.get( 'discount_type' ).setValidators( Validators.required );
      this.NewInvoice.get( 'discount' ).setValidators( [ Validators.required, Validators.pattern( '^[0-9]+(.[0-9]{0,2})?$' ) ] );

      this.NewInvoice.get( 'discount_type' ).updateValueAndValidity();
      this.NewInvoice.get( 'discount' ).updateValueAndValidity();
    }

    this.Calculate();

  }

  SalesTax ( event, tax )
  {
    this.Calculate();
  }

  AddInvoiceDraft() {
    this.InvoiceModel.customer_id = this.NewInvoice.value.customer_id;
    this.InvoiceModel.discount = this.NewInvoice.value.discount;
    this.InvoiceModel.items = this.InvoiceModel.items;
    this.InvoiceModel.sales_tax = this.NewInvoice.value.sales_tax;
    this.InvoiceModel.discount_type = this.NewInvoice.value.discount_type;
    this.InvoiceModel.advance = this.NewInvoice.value.advance;
    this.InvoiceModel.due_date = this.NewInvoice.value.due_date;
    this.InvoiceModel.quantity = this.NewInvoice.value.quantity;
    this.InvoiceModel.advance_type = this.NewInvoice.value.advance_type;
    this.InvoiceModel.private_note = this.NewInvoice.value.private_note;
    this.InvoiceModel.note = this.NewInvoice.value.note;
    this.InvoiceModel.amount = this.amount;
    this.InvoiceModel.checklist_id = this.NewInvoice.value.checklist_id;
    this.InvoiceModel.total = this.total_amount;
    this.InvoiceModel.select_interval = this.NewInvoice.value.select_interval;
    this.InvoiceModel.invoice_number = this.transformedData;
    this.InvoiceModel.recurring_type = this.NewInvoice.value.recurring_type;
    if(this.discount_amount==0 && this.salesTax_amount==0){
      this.InvoiceModel.gross_amount = this.amount; 
      this._invoiceService.setGrossAmount = this.amount;
    }else if(this.discount_amount>0 && this.salesTax_amount==0){
      this.InvoiceModel.gross_amount = this.discount_amount; 
      this._invoiceService.setGrossAmount = this.discount_amount;
    }else if(this.salesTax_amount>0){
      this.InvoiceModel.gross_amount = this.salesTax_amount;
      this._invoiceService.setGrossAmount = this.salesTax_amount;
    }
    if ( this.recurring_value == 'Weekly' )
    {
      this.InvoiceModel.day = this.NewInvoice.value.weekly_day;
    } else if ( this.recurring_value == 'Biweekly' )
    {
      this.InvoiceModel.day = this.NewInvoice.value.biweekly_day;

    } else
    {
      this.InvoiceModel.day = this.NewInvoice.value.day;
    }
    this.InvoiceModel.month = this.NewInvoice.value.month;
    this.InvoiceModel.quarter = this.NewInvoice.value.quarter;
    this._invoiceService.setInvoiceDraft = this.InvoiceModel;
  }

  AddInvoice () {    
    this.submitted2 = true;
    if(this.advance_value == true){
      return;
    }
    if(this.discount_amount==0 && this.salesTax_amount==0){
      this.InvoiceModel.gross_amount = this.amount; 
    }else if(this.discount_amount>0 && this.salesTax_amount==0){
      this.InvoiceModel.gross_amount = this.discount_amount; 
    }else if(this.salesTax_amount>0){
      this.InvoiceModel.gross_amount = this.salesTax_amount;
    }
    if ( ( this.NewInvoice.controls.due_date.invalid ) ||
      ( this.NewInvoice.controls.advance.invalid ) ||
      ( this.NewInvoice.controls.advance_type.invalid ) ||
      ( this.NewInvoice.controls.note.invalid ) ||
      ( this.NewInvoice.controls.select_interval.invalid ) ||
      ( this.NewInvoice.controls.recurring_type.invalid ) ||
      ( this.NewInvoice.controls.day.invalid ) ||
      ( this.NewInvoice.controls.weekly_day.invalid ) ||
      ( this.NewInvoice.controls.biweekly_day.invalid ) ||
      ( this.NewInvoice.controls.month.invalid ) ||
      ( this.NewInvoice.controls.quarter.invalid )
    )
    {
      this.submitted = true;
      return;
    }

    if ( this.invoice_id == null )
    {
      if ( this.InvoiceModel.work_order_update == false )
      {
        this.InvoiceModel.customer_id = this.NewInvoice.value.customer_id;
        this.InvoiceModel.items = this.InvoiceModel.items;
        this.InvoiceModel.discount = this.NewInvoice.value.discount;
        this.InvoiceModel.discount_type = this.NewInvoice.value.discount_type;
        this.InvoiceModel.sales_tax = this.NewInvoice.value.sales_tax;
        this.InvoiceModel.due_date = this.NewInvoice.value.due_date;
        this.InvoiceModel.advance = this.NewInvoice.value.advance;
        this.InvoiceModel.advance_type = this.NewInvoice.value.advance_type;
        this.InvoiceModel.quantity = this.NewInvoice.value.quantity;
        this.InvoiceModel.note = this.NewInvoice.value.note;
        this.InvoiceModel.private_note = this.NewInvoice.value.private_note;
        this.InvoiceModel.checklist_id = this.NewInvoice.value.checklist_id;
        this.InvoiceModel.amount = this.amount;
        this.InvoiceModel.is_draft_save = false;
      }
      this.InvoiceModel.total = this.total_amount;
      this.InvoiceModel.invoice_number = this.transformedData;
      this.InvoiceModel.select_interval = this.NewInvoice.value.select_interval;
      this.InvoiceModel.recurring_type = this.NewInvoice.value.recurring_type;
      if ( this.recurring_value == 'Weekly' )
      {
        this.InvoiceModel.day = this.NewInvoice.value.weekly_day;

      } else if ( this.recurring_value == 'Biweekly' )
      {
        this.InvoiceModel.day = this.NewInvoice.value.biweekly_day;

      } else
      {
        this.InvoiceModel.day = this.NewInvoice.value.day;
      }
      this.InvoiceModel.month = this.NewInvoice.value.month;
      this.InvoiceModel.quarter = this.NewInvoice.value.quarter;
      if ( this.multiple_image_size_validation && this.multiple_image_extension_validation && this.image_extension )
      {
        this.loading = true;
        this.UpdateProductInventory( 'create' );
        this._invoiceService.Create( this.InvoiceModel )
          .subscribe( ( result ) =>{
            if ( result[ 'success' ] ){
              this.loading = false;
              window.scroll(0,0);
              this.InvoiceModel.is_draft_save = false;
              this._invoiceService.setIsDraftSave = this.InvoiceModel.is_draft_save;
              let data = result[ 'data' ];
              this._invoiceService.setInvoiceType(result[ 'is_new_invoice' ]);
              if ( this.imagList.length > 0 )
              {
                this.UploadImages( data.invoice_id );
              }else
              {
                this._router.navigate( [ `/invoice-pay/${ data.invoice_id }/${ this.invoice_number }` ] );
              }
            }
          }, ( err: HttpErrorResponse ) =>
          {
            if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
            {
              this.SettingService.LogOut();
            }
          } );
      } else
      {
        return false;
      }
    } else
    {
      this.InvoiceModel.total = this.total_amount;
      this.InvoiceModel.expenses = this.NewInvoice.value.expenses;
      this.InvoiceModel.due_date = new Date( this.NewInvoice.value.due_date );

      if ( this.recurring_value == 'Weekly' )
      {
        this.InvoiceModel.day = this.NewInvoice.value.weekly_day;
      } else if ( this.recurring_value == 'Biweekly' )
      {
        this.InvoiceModel.day = this.NewInvoice.value.biweekly_day;
      } else
      {
        this.InvoiceModel.day = this.NewInvoice.value.day;
      }

      this.UpdateProductInventory( 'update' );

      if ( this.multiple_image_size_validation && this.multiple_image_extension_validation && this.image_extension )
      {
        this.loading = true;

        this._invoiceService.EditInvoice( this.InvoiceModel, this.invoice_id )
          .subscribe( ( result ) => {
            if ( result[ 'success' ] ){
              this._invoiceService.setInvoiceType(result[ 'is_new_invoice' ]);
              window.scroll(0,0);
              let data = result[ 'data' ];
              if ( this.imagList.length > 0 )
              {
                this.UploadImages( this.invoice_id );
                this.loading = true;
              }else
              {
                this.loading1 = false;
                this.accepted1 = false;
                this._router.navigate( [ `/invoice-pay/${ this.invoice_id }/${ this.invoice_number }` ] );
              }
            }
          }, ( err: HttpErrorResponse ) =>
          {
            if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
            {
              this.SettingService.LogOut();
            }
          } )
      } else
      {
        return false;
      }
    }
  }

  // Selecting multiple files

  FilesSelected ( event )
  {
    this.files.push( ...event.addedFiles );
    
    this._invoiceService.setInvoiceDraftImages = this.files;
    if ( this.files && this.files.length > 0 )
    {
      for ( var i = 0; i < this.files.length; i++ )
      {
        this.imagList[ i ] = this.files[ i ];

        this.additional_image_name = this.imagList[ i ].name;

        this.additionalFileExtension = this.additional_image_name.split( '.' ).pop();

        this.multiple_image_size = ( this.imagList[ i ].size / 1000 );

        if ( this.additionalFileExtension == 'jpeg' ||
          this.additionalFileExtension == 'JPEG' ||
          this.additionalFileExtension == 'png' ||
          this.additionalFileExtension == 'PNG' ||
          this.additionalFileExtension == 'jpg' ||
          this.additionalFileExtension == 'JPG' )
        {
          if ( this.multiple_image_size <= 4096 )
          {
            this.filesSizeMessage = "";
            this.multiplefileExtensionMessage = "";
            this.multiple_image_extension_validation = true;
            this.multiple_image_size_validation = true;
          } else
          {
            this.additional_image_name = this.imagList[ i ].name;
            this.filesSizeMessage = "Image can be maximum upto 4MB";
            this.multiple_image_size_validation = false;
            break;
          }
        } else
        {
          this.additional_image_name = this.imagList[ i ].name;
          this.multiplefileExtensionMessage = "Only .jpg or .jpeg or .png images are allowed"
          this.multiple_image_extension_validation = false;
          break;
        }
      }


    }
  }

  // Uploading multipl files
  onSelect ( event )
  {
    this.files.push( ...event.addedFiles );
  }

  onRemove ( event )
  {
    this.files.splice( this.files.indexOf( event ), 1 );

    if ( this.files && this.files.length > 0 )
    {
      for ( var i = 0; i < this.files.length; i++ )
      {
        let image_name = this.files[ i ].name;

        this.additionalFileExtension = image_name.split( '.' ).pop();

        this.multiple_image_size = ( this.files[ i ].size / 1000 );
        if ( this.additionalFileExtension == 'jpeg' ||
          this.additionalFileExtension == 'JPEG' ||
          this.additionalFileExtension == 'png' ||
          this.additionalFileExtension == 'PNG' ||
          this.additionalFileExtension == 'jpg' ||
          this.additionalFileExtension == 'JPG' )
        {
          if ( this.multiple_image_size <= 4096 )
          {
            this.multiple_image_extension_validation = true;
            this.multiple_image_size_validation = true;
            this.image_extension = true;

          } else
          {
            this.multiple_image_size_validation = false;
            this.additional_image_name = this.files[ i ].name;
            this.filesSizeMessage = "Image can be maximum upto 4MB";
            this.multiplefileExtensionMessage = "";
            this.multiple_image_extension_validation = true;
            this.image_extension = true;
            break;
          }
        } else
        {
          this.image_extension = false;

          this.additional_image_name = this.files[ i ].name;
          this.multiplefileExtensionMessage = "Only .jpg or .jpeg or .png images are allowed";
          this.filesSizeMessage = "";
          this.multiple_image_size_validation = true;


          break;
        }
      }

    } else
    {

      this.multiple_image_size_validation = true;
      this.multiple_image_extension_validation = true;
      this.image_extension = true;
      this.filesSizeMessage = "";
      this.multiplefileExtensionMessage = "";
    }

  }

  // Image Validation function

  FilesValidation () { }

  UploadImages ( invoice_id ){
    this.loading = true;
    for ( var i = 0; i < this.files.length; i++ ){
      this._formdata.append( "logo[]", <string><unknown>this.files[ i ] );
    }

    this._formdata.append( "invoice_id", invoice_id );

    this._invoiceService.SaveFiles( this._formdata ).subscribe(
      (result) =>
      {
        if ( result[ 'success' ] )
        {
          this.loading = false;
          this._router.navigate( [ `/invoice-pay/${ invoice_id }/${ this.invoice_number }` ] );
        }
      }
    )
  }

  //Retrieving the images

  getInvoiceImages ( invoice_id )
  {
    this._invoiceService.GetFilesList( invoice_id ).subscribe(
      result =>
      {
        for ( let index of result[ 'data' ] )
        {
          this.invoice_images.push( index );
        }
      }
    )
  }

  //Delete the file

  onDeleteConfirm ( invoice_image_id, index: number )
  {
    this._invoiceService.DeleteFile( invoice_image_id ).subscribe(
      result =>
      {
        if ( result[ 'success' ] )
        {
          this.toastr.success( 'File Deleted' );
          this.invoice_images.splice( index, 1 );

        } else
        {
          this.toastr.error( 'File Not Deleted' );
        }
      }, ( err: HttpErrorResponse ) =>
    {
      if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
      {
        this.SettingService.LogOut();
      }
    }
    )
  }

  //Recurring Invoice 
  OnChangeRecurringtype ( event )
  {
    this.selected = false;
    this.NewInvoice.get( 'day' ).setValue( '' );
    this.NewInvoice.updateValueAndValidity();
    this.recurring_value = event.target.value;
    this.NewInvoice.get( 'quarter' ).setValue( '' );
    this.NewInvoice.get( 'month' ).setValue( '' );
    this.NewInvoice.get( 'day' ).setValue( '' );
    this.NewInvoice.updateValueAndValidity();

    if ( this.recurring_value == 'Weekly' )
    {
      this.NewInvoice.get( 'weekly_day' ).setValidators( Validators.required );
      this.NewInvoice.get( 'day' ).setValidators( null );
      this.NewInvoice.get( 'quarter' ).setValidators( null );
      this.NewInvoice.get( 'month' ).setValidators( null );
      this.NewInvoice.get( 'weekly_day' ).updateValueAndValidity();
      this.NewInvoice.get( 'day' ).updateValueAndValidity();
      this.NewInvoice.get( 'month' ).updateValueAndValidity();
      this.NewInvoice.get( 'quarter' ).updateValueAndValidity();

    } else if ( this.recurring_value == 'Biweekly' )
    {
      this.NewInvoice.get( 'biweekly_day' ).setValidators( Validators.required );
      this.NewInvoice.get( 'day' ).setValidators( null );
      this.NewInvoice.get( 'quarter' ).setValidators( null );
      this.NewInvoice.get( 'month' ).setValidators( null );
      this.NewInvoice.get( 'day' ).updateValueAndValidity();
      this.NewInvoice.get( 'month' ).updateValueAndValidity();
      this.NewInvoice.get( 'quarter' ).updateValueAndValidity();
      this.NewInvoice.get( 'biweekly_day' ).updateValueAndValidity();

    } else if ( this.recurring_value == "Monthly" )
    {
      this.NewInvoice.get( 'day' ).setValidators( Validators.required );
      this.NewInvoice.get( 'quarter' ).setValidators( null );
      this.NewInvoice.get( 'month' ).setValidators( null );
      this.NewInvoice.get( 'day' ).updateValueAndValidity();
      this.NewInvoice.get( 'month' ).updateValueAndValidity();
      this.NewInvoice.get( 'quarter' ).updateValueAndValidity();
      this.NewInvoice.get( 'biweekly_day' ).updateValueAndValidity();
      this.NewInvoice.get( 'weekly_day' ).updateValueAndValidity();

    } else if ( this.recurring_value == "Quarterly" )
    {
      this.NewInvoice.get( 'quarter' ).setValidators( Validators.required );
      this.NewInvoice.get( 'day' ).setValidators( Validators.required );
      this.NewInvoice.get( 'month' ).setValidators( null );
      this.NewInvoice.get( 'day' ).updateValueAndValidity();
      this.NewInvoice.get( 'month' ).updateValueAndValidity();
      this.NewInvoice.get( 'quarter' ).updateValueAndValidity();
      this.NewInvoice.get( 'biweekly_day' ).updateValueAndValidity();
      this.NewInvoice.get( 'weekly_day' ).updateValueAndValidity();

    } else if ( this.recurring_value == "Yearly" )
    {
      this.NewInvoice.get( 'month' ).setValidators( Validators.required );
      this.NewInvoice.get( 'day' ).setValidators( Validators.required );
      this.NewInvoice.get( 'quarter' ).setValidators( null );
      this.NewInvoice.get( 'day' ).updateValueAndValidity();
      this.NewInvoice.get( 'month' ).updateValueAndValidity();
      this.NewInvoice.get( 'quarter' ).updateValueAndValidity();
      this.NewInvoice.get( 'biweekly_day' ).updateValueAndValidity();
      this.NewInvoice.get( 'weekly_day' ).updateValueAndValidity();
    }
  }

  OnChangingRecurringValue ( event, type ){
    // this.agreemnet.get('day').setValue('');
    // this.agreemnet.updateValueAndValidity();
  }
  OnChangingRecurringValue2 ( event ) {
    // this.agreemnet.value.recurringTypeValueTwo = event.target.value ;
  }

  getProductServiceList () {
    if(!this.productServices.length) {
      this.toastr.warning("Please add product and services first");
    }
  }


  productServiceList ()
  {
    this._invoiceService.GetProductServiceList( null ).subscribe(
      result =>
      { this.List = result[ 'data' ];
        this.productServices = [
          ...this.List[ 0 ].map(
            ( item: Object ) => ( {
              group: 'Service',
              name: item[ 'name' ],
              id: item[ 'service_id' ],
            } )
          ),
          ...this.List[ 1 ].map(
            ( item: Object ) => ( {
              group: 'Product',
              name: item[ 'name' ],
              id: item[ 'item_id' ],
            } )
          )
        ]
      }, ( err: HttpErrorResponse ) =>
    {
      if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
      {
        this.SettingService.LogOut();
      }
    }
    )
  }

  getActivatedAddOnList ()
  {

    this.AddOnService.GetActivatedAddOnList().subscribe(
      result =>
      {
        this.enabled_modules = result[ 'data' ];
        for ( let index of this.enabled_modules )
        {
          if ( index[ 'module_id' ] == '606f1baf2281b' && index[ 'enabled' ] )
          {
            this.invoice_recurring_enabled = true;
          }
        }
      }
    )
  }

  //Procuct Inventory Management

  UpdateProductInventory ( value )
  {
    if ( value == 'create' )
    {
      for ( let i of this.InvoiceModel.items )
      {
        i.method = 'create'
      }
      this.ItemService.UpdateItemInventory( this.InvoiceModel ).subscribe(
        result =>
        {

        }
      )

    } else if ( value == 'update' )
    {
      for ( let i of this.InvoiceModel.items )
      {
        i.method = 'update'
        if ( i.qty > i.previous_quantity )
        {
          let updatedItemQuantity = i.previous_quantity - ( +i.qty );
          i.previous_quantity = updatedItemQuantity;
        } else
        {
          let updatedItemQuantity = i.previous_quantity - ( +i.qty );
          if ( isNaN( updatedItemQuantity ) )
          {
            i.method = 'create'
            updatedItemQuantity = 0;
          }
          i.previous_quantity = updatedItemQuantity;

        }
      }
      this.ItemService.UpdateItemInventory( this.InvoiceModel ).subscribe(
        result =>
        {

        }
      )


    }
  }

  getWorkOrderImages(work_order_id) {
    this.WorkOrderService.GetFilesList(work_order_id).subscribe(
      result => {
        for (let index of result['data']) {
          this.work_order_images.push(index);
        }
      }
    )
  }

  getWorkOrderDetails ()
  {
    this.loading = true;
    this.WorkOrderService.GetApprovedInvoiceData().subscribe(
      result =>
      {
        this.loading = false;
        if ( result )
        {
          this.InvoiceModel.customer_id = result[ 'customer_id' ];
          if ( result[ 'workorder_customers' ] )
          {
            this.CustomerModel.customer_id = result[ 'workorder_customers' ][ 0 ][ 'customer_id' ];
            this.CustomerModel.name = result[ 'workorder_customers' ][ 0 ][ 'name' ];

            this.CustomerModel.address = result[ 'workorder_customers' ][ 0 ][ 'address' ];
            this.CustomerModel.email = result[ 'workorder_customers' ][ 0 ][ 'email' ];
            this.CustomerModel.zip = result[ 'workorder_customers' ][ 0 ][ 'zip' ];
            this.CustomerModel.locality = result[ 'workorder_customers' ][ 0 ][ 'locality' ];
            this.CustomerModel.mobile = result[ 'workorder_customers' ][ 0 ][ 'mobile' ];
            this.CustomerModel.alternative_number = result[ 'workorder_customers' ][ 0 ][ 'alternative_number' ];
            this.alternative_number = result[ 'workorder_customers' ][ 0 ][ 'alternative_number' ];
          }
          this.InvoiceModel.invoice_recurring_details_id = result[ 'invoice_agreement_recurring_details_id' ] || null;
          if ( result[ 'workorder_workorderitems' ] != null || result[ 'workorder_workorderitems' ].length > 0 )
          {
            this.InvoiceModel.line_item = result[ 'workorder_workorderitems' ][ 0 ].item_id;
            this.NewInvoice.get( 'line_item' ).clearValidators();
            this.NewInvoice.get( 'line_item' ).updateValueAndValidity();


            for ( let index of result[ 'workorder_workorderitems' ] )
            {
              if ( index.group == 'Product' )
              {
                if ( index.workorderitems_items.tax_rate )
                {
                  this.InvoiceModel.items.push( {
                    item_id: index.item_id,
                    qty: index.quantity,
                    group: index.group,
                    unit_price: index.workorderitems_items.rate,
                    tax: index.workorderitems_items.tax_rate_data.rate,
                    previous_quantity: index.quantity,
                  } );
                  this.itemArr.push( {
                    item_id: index.workorderitems_items.name,
                    qty: index.quantity,
                    rate: ( ( +index.quantity ) * ( +index.workorderitems_items.rate + ( +index.workorderitems_items.rate * ( ( +index.workorderitems_items.tax_rate_data.rate ) / 100 ) ) ) ).toFixed( 2 ),
                    unit_price: index.workorderitems_items.rate,
                    previous_quantity: index.quantity,
                    group: index.group,
                    tax: index.workorderitems_items.tax_rate_data.rate + '%'

                  } );
                  this.amount = this.amount + ( +index.quantity * ( +index.workorderitems_items.rate + ( +index.workorderitems_items.rate * ( +index.workorderitems_items.tax_rate_data.rate / 100 ) ) ) );


                } else
                {
                  this.InvoiceModel.items.push( {
                    item_id: index.item_id,
                    qty: index.quantity,
                    group: index.group,
                    unit_price: index.workorderitems_items.rate,
                    previous_quantity: index.quantity,
                  } );
                  this.itemArr.push( {
                    item_id: index.workorderitems_items.name,
                    qty: index.quantity,
                    rate: index.quantity * ( +index.workorderitems_items.rate ),
                    unit_price: index.workorderitems_items.rate,
                    previous_quantity: index.quantity,
                    group: index.group,
                    tax: 'NA'

                  } );
                  this.amount = this.amount + ( index.quantity * ( +index.workorderitems_items.rate ) );
                }

              } else if ( index.group == 'Service' )
              {
                if ( index.workorderitems_service.tax_rate )
                {
                  this.InvoiceModel.items.push( {
                    item_id: index.item_id,
                    qty: index.quantity,
                    group: index.group,
                    unit_price: index.workorderitems_service.rate,
                    tax: index.workorderitems_service.tax_rate_data.rate,
                    previous_quantity: index.quantity,
                  } );
                  this.itemArr.push( {
                    item_id: index.workorderitems_service.name,
                    qty: index.quantity,
                    rate: ( ( +index.quantity ) * ( +index.workorderitems_service.rate + ( +index.workorderitems_service.rate * ( ( +index.workorderitems_service.tax_rate_data.rate ) / 100 ) ) ) ).toFixed( 2 ),
                    unit_price: index.workorderitems_service.rate,
                    previous_quantity: index.quantity,
                    group: index.group,
                    tax: index.workorderitems_service.tax_rate_data.rate + '%'

                  } );
                  this.amount = this.amount + ( ( +index.quantity ) * ( +index.workorderitems_service.rate + ( +index.workorderitems_service.rate * ( ( +index.workorderitems_service.tax_rate_data.rate ) / 100 ) ) ) );

                } else
                {
                  this.InvoiceModel.items.push( {
                    item_id: index.item_id,
                    qty: index.quantity,
                    group: index.group,
                    unit_price: index.workorderitems_service.rate,
                    previous_quantity: index.quantity,
                  } );
                  this.itemArr.push( {
                    item_id: index.workorderitems_service.name,
                    qty: index.quantity,
                    rate: index.quantity * ( +index.workorderitems_service.rate ),
                    unit_price: index.workorderitems_service.rate,
                    previous_quantity: index.quantity,
                    group: index.group,
                    tax: 'NA'

                  } );
                  this.amount = this.amount + ( index.quantity * ( +index.workorderitems_service.rate ) );

                }
              }

            }
          }

          this.InvoiceModel.discount = result[ 'discount' ];
          this.total_amount = this.amount;
          this.InvoiceModel.discount_type = result[ 'discount_type' ];
          this.NewInvoice.get( 'discount' ).setValue( this.InvoiceModel.discount );
          this.NewInvoice.get( 'discount_type' ).setValue( this.InvoiceModel.discount_type );
          if ( this.InvoiceModel.discount_type != null && this.InvoiceModel.discount_type != '' )
          {
            if ( this.InvoiceModel.discount_type == '%' )
            {

              this.isCheckedDiscountPercent = true;
            } else if ( this.InvoiceModel.discount_type == '$' )
            {

              this.isCheckedDiscountDollar = true;
            }
          }
          this.InvoiceModel.sales_tax = result[ 'sales_tax' ];
          this.InvoiceModel.advance = result[ 'advance' ];
          this.InvoiceModel.advance_type = result[ 'advance_type' ];
          this.NewInvoice.get( 'sales_tax' ).setValue( this.InvoiceModel.discount );
          this.NewInvoice.get( 'advance' ).setValue( this.InvoiceModel.advance );
          this.NewInvoice.get( 'advance_type' ).setValue( this.InvoiceModel.advance_type );
          this.NewInvoice.updateValueAndValidity();
          if ( this.InvoiceModel.advance_type != null && this.InvoiceModel.advance_type != '' )
          {
            if ( this.InvoiceModel.advance_type == '%' )
            {
              this.isCheckedPercent = true;
            } else if ( this.InvoiceModel.advance_type == '$' )
            {
              this.isCheckedDollar = true;
            }
          }
          this.InvoiceModel.amount = this.amount;
          this.InvoiceModel.due_date = new Date( result[ 'due_date' ] );
          this.InvoiceModel.private_note = result['private_note'];
          this.InvoiceModel.work_order_id = result[ 'work_order_id' ];
          this.getWorkOrderImages(result[ 'work_order_id' ]);
          this.InvoiceModel.note = result[ 'note' ];
          this.InvoiceModel.checklist_id = result[ 'checklist_id' ];
          this.InvoiceModel.invoice_number = this.transformedData;
          this.InvoiceModel.work_order_update = true;
          this.CalculateTotalFromWorkOrderDetails();

        }
      }

    );
  }

  CalculateTotalFromWorkOrderDetails ()
  {
    if ( this.InvoiceModel.discount_type != null &&
      this.InvoiceModel.discount_type != '' &&
      this.InvoiceModel.discount != null &&
      this.InvoiceModel.discount != '' )
    {
      if ( this.InvoiceModel.discount_type == '%' )
      {
        this.discount_amount = 0;
        this.discount_amount = ( this.amount * this.InvoiceModel.discount ) / 100;
        this.discount_amount = this.amount - this.discount_amount;
      } else if ( this.InvoiceModel.discount_type == '$' )
      {

        this.discount_amount = 0;
        this.discount_amount = this.amount - this.InvoiceModel.discount;
      }
    }
    if ( this.InvoiceModel.sales_tax != null || this.InvoiceModel.sales_tax != '' )
    {
      if ( this.discount_amount == 0 )
      {
        this.total_amount = this.amount * ( 1 + ( this.InvoiceModel.sales_tax / 100 ) );

        this.total_amount = ( Math.round( this.total_amount * 10000 ) / 10000 ).toFixed( 2 );
      } else
      {

        this.total_amount = this.discount_amount * ( 1 + ( this.InvoiceModel.sales_tax / 100 ) );
        this.total_amount = ( Math.round( this.total_amount * 10000 ) / 10000 ).toFixed( 2 );
      }
    }
    if ( ( this.InvoiceModel.advance != null && this.InvoiceModel.advance != '' ) &&
      ( this.InvoiceModel.advance_type != null && this.InvoiceModel.advance_type != '' ) )
    {
      if ( this.InvoiceModel.advance_type == "%" )
      {

        this.total_amount = this.total_amount * ( 1 - this.InvoiceModel.advance / 100 );
        this.total_amount = ( Math.round( this.total_amount * 10000 ) / 10000 ).toFixed( 2 )
      } else
      {

        this.total_amount = this.total_amount - this.InvoiceModel.advance;
        this.total_amount = ( Math.round( this.total_amount * 10000 ) / 10000 ).toFixed( 2 );

      }
    }
    if ( this.discount_amount == 0 && this.total_amount == 0 )
    {
      this.total_amount = this.amount;
    } else if ( this.discount_amount > 0 && this.total_amount == 0 )
    {
      this.total_amount = this.discount_amount;
    } else if ( this.total_amount > 0 )
    {
      this.total_amount = this.total_amount;
    }
  }

  // Getting item set in localstorage from product self page
  getProductFromProductPage ()
  {
    let data = JSON.parse( localStorage.getItem( 'product' ) );
    this.NewInvoice.get( 'line_item' ).setValue( data.id );
    this.NewInvoice.get( 'line_item' ).updateValueAndValidity();
    this.ViewProductOrSeviceData( data );
  }
  addProductservice(){
    this.modalRef = this.modalService.show(AddProductAndServiceComponent, {
      class: 'modal-lg'
    })
  }

  getProductServiceModalData(){
    const productDataSubscription = this._invoiceService.product_response_data.subscribe(
      (result) => {
        if(result){
          if(result['group']=='Product'){
            let quantity_for_invoice = localStorage.getItem('quantity_for_invoice');
            this.NewInvoice.get('line_item').setValue(result['item_id']);
            this.NewInvoice.get('quantity').setValue(result['quantity_for_invoice']);
            this.NewInvoice.get('unit_price').setValue(result['rate']);
            this.current_selected_item ={
              group : result['group'],
              id : result['item_id'],
              name: result['name']
            }
            this.current_selected_item.group = result['group'];
  
            this.NewInvoice.updateValueAndValidity();
            let item={
              'id': result['item_id'],
              'group': result['group'],
            }
            this.ViewProductOrSeviceData(item);
            setTimeout(()=>{
              this.AddItem();
            },3000)
          }
        }
      }
    );

    this.subscriptions.push(productDataSubscription);
    
  }

  getServiceModalData() {
    const serviceDataSubscription = this._invoiceService.service_response_data.subscribe(
      (result) => {
        if (result) {
          if(result['group']=='Service'){
            this.NewInvoice.get('line_item').setValue(result['service_id']);
            this.NewInvoice.get('quantity').setValue(1);
            this.NewInvoice.get('unit_price').setValue(result['rate']);
            this.current_selected_item = {
              group: result['group'],
              id: result['service_id'],
              name: result['name']
            }
            this.current_selected_item.group = result['group'];
  
            this.NewInvoice.updateValueAndValidity();
            let item={
              'id': result['service_id'],
              'group': result['group'],
            }
            this.ViewProductOrSeviceData(item);
            setTimeout(()=>{
              this.AddItem();
            },3000)
          }
        }
      }
    );

    this.subscriptions.push(serviceDataSubscription);
  }

  taxList: object[] = [];
  getLocationList(search){
    const data = {
      search: search.target.value
    }
    this.taxService.getLocationList(data).subscribe((response) => {
      this.taxList = response['data'];
      if (!this.taxList) {
        this.toastr.warning("Please add tax first");
      }
    })
  }
  
  setTaxRate(event){
    this.NewInvoice.get('sales_tax').setValue(event);
    this.NewInvoice.updateValueAndValidity();
    this.Calculate()
  }

  arrowDownBtn(index : number){
    const copy1 = [...this.itemArr];
    const moveTo1 = copy1.splice(index, 1)[0];
    copy1.splice(index +1, 0, moveTo1);
    this.itemArr = copy1;

    const copy2 = [...this.InvoiceModel.items];
    const moveTo2 = copy2.splice(index, 1)[0];
    copy2.splice(index +1, 0, moveTo2);
    this.InvoiceModel.items = copy2;
  }


  arrowUpBtn(index : number){
    const copy1 = [...this.itemArr];
    const moveTo1 = copy1.splice(index, 1)[0];
    copy1.splice(index - 1, 0, moveTo1);
    this.itemArr = copy1;
  
    const copy2 = [...this.InvoiceModel.items];
    const moveTo2 = copy2.splice(index, 1)[0];
    copy2.splice(index - 1, 0, moveTo2);
    this.InvoiceModel.items = copy2;
  }

  public isChecklistNull: boolean = false;
  getCheckListItem(){
    this.loading = true;
    this.AddChecklistService.GetCheckList().subscribe(
      (result)=>{
        this.loading = false;
        if(result['data'].length) {
          this.isChecklistNull = false;
        }else {
          this.isChecklistNull = true;
        }
        this.checklistItem = result["data"];
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    )
  }

  getCheckLists(){
    if(this.isChecklistNull) {
      this.toastr.warning("Please add checklist first");
    }
  }

  deleteDraft() {
    this.InvoiceModel.draft_reset = true;
    this._invoiceService.GetDraftData(this.InvoiceModel.draft_reset).subscribe(
      (result) => {
        if (result['success']) {
          this.loading = false;
          this.invoiceDraft.hide();
          this.InvoiceModel.is_draft_save = false;
          this._invoiceService.setIsDraftSave = this.InvoiceModel.is_draft_save;
        }
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    )
  }

  getDraftDetailsData() {
    this.InvoiceModel.draft_reset = false;
      this._invoiceService.GetDraftData(this.InvoiceModel.draft_reset).subscribe(
        (result) => {
          if (result['success']) { 
            this.InvoiceModel.is_draft_save = result["data"].is_draft_save;
            this._invoiceService.setIsDraftSave = this.InvoiceModel.is_draft_save;
          } else {
            this.loading = false;
          }
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
          }
        }
      )
  }

  getDraftData() {
    this.loading = true;
    this.InvoiceModel.draft_reset = false;
    this._invoiceService.GetDraftData(this.InvoiceModel.draft_reset).subscribe(
      (result) => {
        if (result['success']) {
          this.loading = false;
          this.invoiceDraft.hide();
          
          this.InvoiceModel.invoice_id = result["data"].invoice_id;
          this.getInvoiceImages(this.InvoiceModel.invoice_id)

          this.InvoiceModel.customer_id = result[ 'data' ].customer_id;
          if ( result[ 'data' ][ 'invoice_customers' ] )
          {
            this.CustomerModel.customer_id = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'customer_id' ];
            this.CustomerModel.name = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'name' ];

            this.CustomerModel.address = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'address' ];
            this.CustomerModel.email = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'email' ];
            this.CustomerModel.zip = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'zip' ];
            this.CustomerModel.locality = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'locality' ];
            this.CustomerModel.mobile = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'mobile' ];
            this.CustomerModel.alternative_number = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'alternative_number' ];
            this.alternative_number = result[ 'data' ][ 'invoice_customers' ][ 0 ][ 'alternative_number' ];
          }
          this.InvoiceModel.invoice_recurring_details_id = result[ 'data' ].invoice_agreement_recurring_details_id || null;
          if (result[ 'data' ][ 'invoice_invoiceitems' ].length > 0 )
          {
            this.InvoiceModel.line_item = result[ 'data' ][ 'invoice_invoiceitems' ][ 0 ].item_id;
            this.NewInvoice.get( 'line_item' ).clearValidators();
            this.NewInvoice.get( 'line_item' ).updateValueAndValidity();

            for ( let index of result[ 'data' ][ 'invoice_invoiceitems' ] )
            {
              if ( index.group == 'Product' )
              {
                if ( index.invoiceitems_items_with_trashed.tax_rate )
                {
                  this.InvoiceModel.items.push( {
                    item_id: index.invoiceitems_items_with_trashed.item_id,
                    qty: index.quantity,
                    group: index.group,
                    unit_price: index.rate,
                    tax: index.invoiceitems_items_with_trashed.tax_rate_data.rate,
                    previous_quantity: index.quantity
                  } );
                  this.itemArr.push( {
                    item_id: index.invoiceitems_items_with_trashed.name,
                    qty: index.quantity,
                    rate: ( index.quantity * ( +index.rate + ( +index.rate * ( index.invoiceitems_items_with_trashed.tax_rate_data.rate / 100 ) ) ) ).toFixed( 2 ),
                    unit_price: index.rate,
                    tax: index.invoiceitems_items_with_trashed.tax_rate_data.rate + '%',
                    previous_quantity: index.quantity,
                    group: index.group,

                  } );
                } else
                {
                  this.InvoiceModel.items.push( {
                    item_id: index.invoiceitems_items_with_trashed.item_id,
                    qty: index.quantity,
                    group: index.group,
                    unit_price: index.rate,
                    previous_quantity: index.quantity
                  } );
                  this.itemArr.push( {
                    item_id: index.invoiceitems_items_with_trashed.name,
                    qty: index.quantity,
                    rate: index.quantity * ( +index.rate ),
                    unit_price: index.rate,
                    previous_quantity: index.quantity,
                    group: index.group,
                    tax: 'NA'
                  } );
                }
              } else if ( index.group == 'Service' )
              {
                if ( index.invoiceitems_service_with_trashed.tax_rate )
                {
                  this.InvoiceModel.items.push( {
                    item_id: index.invoiceitems_service_with_trashed.service_id,
                    qty: index.quantity,
                    unit_price: index.rate,
                    tax: index.invoiceitems_service_with_trashed.tax_rate_data.rate,
                    group: index.group,
                  } );
                  this.itemArr.push( {
                    item_id: index.invoiceitems_service_with_trashed.name,
                    qty: index.quantity,
                    rate: ( index.quantity * ( +index.rate + ( +index.rate * ( index.invoiceitems_service_with_trashed.tax_rate_data.rate / 100 ) ) ) ).toFixed( 2 ),
                    unit_price: index.rate,
                    group: index.group,
                    tax: index.invoiceitems_service_with_trashed.tax_rate_data.rate + '%',


                  } );
                } else
                {
                  this.InvoiceModel.items.push( {
                    item_id: index.invoiceitems_service_with_trashed.service_id,
                    qty: index.quantity,
                    unit_price: index.rate,
                    group: index.group,
                  } );
                  this.itemArr.push( {
                    item_id: index.invoiceitems_service_with_trashed.name,
                    qty: index.quantity,
                    rate: index.quantity * ( +index.rate ),
                    unit_price: index.rate,
                    group: index.group,
                    tax: 'NA'
                  } );
                }

              }

            }
          }
          if ( result[ 'data' ][ 'invoice_invoiceexpenses' ] != null && result[ 'data' ][ 'invoice_invoiceexpenses' ].length > 0 )
          {
            this.InvoiceModel.expenses = result[ 'data' ][ 'invoice_invoiceexpenses' ][ 0 ].expenses_id;
            this.NewInvoice.get( 'expenses' ).clearValidators();
            this.NewInvoice.get( 'expenses' ).updateValueAndValidity();

            for ( let index of result[ 'data' ][ 'invoice_invoiceexpenses' ] )
            {
              this.InvoiceModel.expense.push(
                index[ 'expense_id' ]
              );
              this.expenseArr.push( {
                expense_type: index.invoiceexpenses_expenses.type,
                expense_id: index.invoiceexpenses_expenses.expenses_id,
                expense_amount: index.invoiceexpenses_expenses.total,
                expense_number: index.invoiceexpenses_expenses.expense_number,
                expense_merchant: index.invoiceexpenses_expenses.merchant
              } );
              this.invoice_expenseData.push( index.invoiceexpenses_expenses );
            }
          }
          this.InvoiceModel.discount = result[ 'data' ].discount;
          this.InvoiceModel.discount_type = result[ 'data' ].discount_type;
          if ( this.InvoiceModel.discount_type != null && this.InvoiceModel.discount_type != '' )
          {
            if ( this.InvoiceModel.discount_type == '%' )
            {

              this.isCheckedDiscountPercent = true;
            } else if ( this.InvoiceModel.discount_type == '$' )
            {

              this.isCheckedDiscountDollar = true;
            }
            if (this.InvoiceModel.discount == null) {
              this.NewInvoice.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);
              this.NewInvoice.get('discount').setValue('');
              this.NewInvoice.updateValueAndValidity();
            }
          }
          if(this.InvoiceModel.discount!=null && this.InvoiceModel.discount!=''){
            if (this.InvoiceModel.discount_type == null) {
              this.NewInvoice.get('discount_type').setValidators(Validators.required);
              this.NewInvoice.updateValueAndValidity();
            }
          }
          this.InvoiceModel.sales_tax = result[ 'data' ].sales_tax;
          this.InvoiceModel.advance = result[ 'data' ].advance;
          this.InvoiceModel.advance_type = result[ 'data' ].advance_type;
          this.InvoiceModel.gross_amount = result[ 'data' ].gross_amount;
          this.InvoiceModel.net_amount = result[ 'data' ].net_amount;
          this.InvoiceModel.advance_paid = result[ 'data' ].advance_paid;
          if ( this.InvoiceModel.advance_type != null && this.InvoiceModel.advance_type != '' )
          {

            if ( this.InvoiceModel.advance_type == '%' )
            {


              this.isCheckedPercent = true;
            } else if ( this.InvoiceModel.advance_type == '$' )
            {


              this.isCheckedDollar = true;
            }
            if (this.InvoiceModel.advance == null) {
              this.NewInvoice.get('advance').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);
              this.NewInvoice.get('advance').setValue('');
              this.NewInvoice.updateValueAndValidity();
            }
          }
          if(this.InvoiceModel.advance!=null && this.InvoiceModel.advance!=''){
            if (this.InvoiceModel.advance_type == null) {
              this.NewInvoice.get('advance_type').setValidators(Validators.required);
              this.NewInvoice.get('advance_type').updateValueAndValidity();
            }
          }
          if(result['data'].due_date) {
            this.InvoiceModel.due_date = new Date(result['data'].due_date);
          }
          this.InvoiceModel.amount = result[ 'data' ].amount;
          this.InvoiceModel.total = result[ 'data' ].total;
          this.amount = result[ 'data' ].amount;
          this.total_amount = result[ 'data' ].total;
          this.InvoiceModel.note = result[ 'data' ].note;
          this.InvoiceModel.private_note = result[ 'data' ].private_note;
          this.InvoiceModel.checklist_id = result[ 'data' ].checklist_id;
          this.InvoiceModel.invoice_number = result[ 'data' ].invoice_number;
          this.InvoiceModel.select_interval = result[ 'data' ].select_interval;
          if ( result[ 'data' ][ 'invoice_recurrings' ] != null && result[ 'data' ][ 'invoice_recurrings' ] != '' )
          {
            this.selected = true;

            this.InvoiceModel.day = result[ 'data' ][ 'invoice_recurrings' ].day;
            this.InvoiceModel.month = result[ 'data' ][ 'invoice_recurrings' ].month;
            this.InvoiceModel.quarter = result[ 'data' ][ 'invoice_recurrings' ].quarter;

            this.InvoiceModel.recurring_type = result[ 'data' ][ 'invoice_recurrings' ][ 'recurring_type' ];
          }
          this.recurring_check = result[ 'data' ].select_interval;
          this.recurring_value = this.InvoiceModel.recurring_type;
          if ( this.recurring_value == 'Weekly' )
          {
            setTimeout( () =>
            {
              this.NewInvoice.get( 'weekly_day' ).setValue( this.InvoiceModel.day );
              this.NewInvoice.get( 'weekly_day' ).updateValueAndValidity();

            }, 0 );
          } else if ( this.recurring_value == 'Biweekly' )
          {
            setTimeout( () =>
            {
              this.NewInvoice.get( 'biweekly_day' ).setValue( this.InvoiceModel.day );
              this.NewInvoice.get( 'biweekly_day' ).updateValueAndValidity();

            }, 0 );
          } 
        } else {
          this.loading = false;
        }
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    )
  }

  getInvoiceDraftImageList ()
  {
    this.loading = true;
    this._invoiceService.GetDraftImageList(this.InvoiceModel.invoice_id).subscribe(
      result =>
      {
        this.loading = false;
        for ( let index of result[ 'data' ] )
        {
          this.invoice_images.push( index );
          
        }
      }
    )
  }
}
