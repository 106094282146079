import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class Schedule {
  name: string = null;
  widget_service_id: string = null;
  customer_id: string = null;
  estimate_id: string = null;
  checklist_id: string = null;
  date: any = null;
  requestList: any = [];
  status: any = "";
  note: string = null;
  customer_note: string = null;
  assign_to: string = null;
  time: any = null;
  start_journey: any = null;
  end_journey: any = null;
  start_work: any = null;
  end_work: any = null;
  address: string = null;
  work_status: any = "";
  accepted: boolean = false;
  public estimate_number: any = null;
  public total: number = null;
  public email: string = null;
  public mobile: string = null;
}
