import { Injectable } from '@angular/core';

@Injectable()
export class Sidebar {
  public state: boolean = false;

  constructor() {}

  Open() {
    let body = document.querySelector('body');

    this.state = true;
    body.classList.add('overflow-hidden');
  }

  Close() {
    let body = document.querySelector('body');
    
    this.state = false;
    body.classList.remove('overflow-hidden');
  }

  Toggle() {
    this.state = !this.state;
  }
}
