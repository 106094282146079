import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-schedule-calendar-details',
  templateUrl: './schedule-calendar-details.component.html',
  styleUrls: ['./schedule-calendar-details.component.scss']
})
export class ScheduleCalendarDetailsComponent implements OnInit {
  @Input() public data;
  
  constructor(public modalRef: BsModalRef) {
   }

  ngOnInit() {
  }
  closeModal(){
    this.modalRef.hide()
  }
}
