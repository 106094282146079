import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import { AddChecklistService } from 'src/app/shared/services/checklist/add-checklist.service';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from "src/app/shared/services/setting/setting.service";
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-checklist-add',
  templateUrl: './checklist-add.component.html',
  styleUrls: ['./checklist-add.component.scss']
})
export class ChecklistAddComponent implements OnInit {
  public submitted: boolean = null;
  public addchecklistItem: object[] = []
  public isEdit: boolean = false;
  public setIndex: any = "";
  public getItem: any;
  public checklist_id: string = null;
  public getDetails: any[] = [];
  public editChecklistDetails: any[] = [];
  public _formdata = new FormData();
  public isCheckListItem: boolean = false;
  public loading: boolean = false;
  public isSelectType: boolean = false;
  public isChecklist: boolean = false;
  public add_remove_item: boolean = false;
  public addBtnDisable = false;

  public Newchecklist = this.fb.group({
    name: ["", Validators.required],
    checklist: [""],
    type: ["", Validators.required]
  });

  constructor(
    public fb: FormBuilder,
    public toastr: ToastrService,
    public AddCheckListService : AddChecklistService,
    public SettingService: SettingService,
    public activateRoute: ActivatedRoute,
    public router: Router
  ) {
    this.checklist_id = this.activateRoute.snapshot.paramMap.get("checklist_id");
  }

  ngOnInit() {
    if(this.checklist_id){
      this.updateChecklistInfo();
    }
  }

  get f() {
    return this.Newchecklist.controls;
  }


  CreateChecklist(){
    // this.loading = true;
    this.submitted = true;
    // this.addBtnDisable = true;
    if(this.Newchecklist.value.checklist && !this.Newchecklist.value.type){
      this.submitted = true;
      // this.addBtnDisable = true;
      this.isChecklist = false;
      this.isSelectType = true;
      this.isCheckListItem = false;
      return;
    }else if(!this.Newchecklist.value.checklist && this.Newchecklist.value.type){
      this.submitted = true;
      // this.addBtnDisable = true;
      this.isSelectType = false;
      this.isChecklist = true;
      this.isCheckListItem = false;
      return;
    }else if (this.Newchecklist.controls.name.invalid) {
      if(this.addchecklistItem.length<=0){
        this.isCheckListItem = true;
        return
      }
      return;
    }else if(this.addchecklistItem.length<=0){
      // this.isCheckListItem = true;
      this.add_remove_item = true;
      this.isSelectType = false;
      this.isChecklist = false;
      return
    }else{
      this.isCheckListItem = false;
    }

    if(this.Newchecklist.value.checklist && this.Newchecklist.value.type){
      this.add_remove_item = true;
      this.isSelectType = false;
      this.isChecklist = false;
      return;
    }else{
      this.add_remove_item = false;
    }

    const data = {
      "name": this.Newchecklist.value.name,
      "checklist": this.addchecklistItem
    }
    if(!this.checklist_id){
      this.AddCheckListService.AddCheckList(data).subscribe(
        (result) => {
          
          if (result["success"]) {
            // this.loading = false;
            this.showSuccess(result["msg"]);
            this.router.navigate(["/checklist"]);
          } else {
            // this.loading = false;
            this.showDanger(result["msg"]);
          }
        },
        (err: HttpErrorResponse) => {
          // this.loading = false;
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut()
          }
        }
      );
    }else if(this.checklist_id != null){
      let editData = {
        checklist_id: this.checklist_id,
        "name": this.Newchecklist.value.name,
        "checklist": this.addchecklistItem
      }
      this.AddCheckListService.UpdateChecklist(this.checklist_id, editData).subscribe(
        (result) => {
          if (result["success"]) {
            this.loading = false;
            this.showSuccess(result["msg"]);
            this.router.navigate(['/checkList-self/', this.checklist_id]);
          } else {
            this.loading = false;
            this.showDanger(result["msg"]);
          }
        },
        (err: HttpErrorResponse) => {
          this.loading = false;
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut()
          }
        }
      );
    }
  }

  public checklistData: object = null;
  
  updateChecklistInfo(){
    this.loading = true;
    this.AddCheckListService.GetChecklistId(this.checklist_id).subscribe(
      (result) => {
        if (result["success"]) {
          this.loading = false;
          this.checklistData = result['data']
          this.Newchecklist.get('name').setValue(this.checklistData['name']);
          this.Newchecklist.get('type').setValue(this.checklistData['type']);
          this.Newchecklist.updateValueAndValidity();
          this.addchecklistItem = this.checklistData['checklist']
        } else {
          this.loading = false;
          this.showDanger(result["msg"]);
        }
      },
      (err: HttpErrorResponse) => {
        this.loading = false;

        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    );
  }



  editlist(index: any){
    this.getItem = this.addchecklistItem[index];
    this.Newchecklist.get('checklist').setValue(this.getItem.item)
    this.Newchecklist.get('type').setValue(this.getItem.type)
    this.setIndex = index;
    this.isEdit = true;
    
  }

  checkListAddBtn(){
    this.addBtnDisable = true;
    this.isCheckListItem = false;
    this.isChecklist = false;
    this.isSelectType = false;
    if(this.Newchecklist.value.checklist && !this.Newchecklist.value.type){
      this.submitted = true;
      this.addBtnDisable = true;
      // this.isChecklist = false;
      // this.isSelectType = true;
      return;
    }else if(!this.Newchecklist.value.checklist && this.Newchecklist.value.type){
      this.submitted = true;
      this.addBtnDisable = true;
      // this.isSelectType = false;
      // this.isChecklist = true;
      return;
      
    }else if(!this.Newchecklist.value.checklist && !this.Newchecklist.value.type){
      // this.submitted = true;
      // this.isSelectType = true;
      // this.isChecklist = true;
      this.addBtnDisable = true;
      return;
      
    }else{
      this.submitted = false;

      this.isSelectType = false;
      this.isChecklist = false;
    }
    this.add_remove_item = false;
    if (!this.isEdit) {
      this.addchecklistItem.push({
        item: this.Newchecklist['value']['checklist'],
        type: Number(this.Newchecklist['value']['type'])
      })
      if(this.addchecklistItem.length > 0){
        this.isCheckListItem = false
      }else{
        this.isCheckListItem = true
      }
    } 
    else {
      this.addchecklistItem[this.setIndex]['item'] = this.Newchecklist.get('checklist').value;
      this.addchecklistItem[this.setIndex]['type'] = this.Newchecklist.get('type').value;     
      this.isEdit = false
    }
    this.Newchecklist.get('checklist').setValue('')
    this.Newchecklist.get('type').setValue('')
  }

  delete(index: any) {
    this.addchecklistItem.splice(index, 1);
  }

  onInputCheck(){
    if(!this.Newchecklist.value.checklist && !this.Newchecklist.value.type){
      this.add_remove_item = false;
    }else if(this.Newchecklist.value.checklist){
      this.isChecklist = false;
    }else{
      this.isChecklist = true;
    }
  }

  valuechange($event){
    if(this.Newchecklist.value.type){
      this.isSelectType = false;
    }
  }

  // Up & Down Arrow Function

  arrowDownBtn(index : number){
    const copy1 = [...this.addchecklistItem];
    const moveTo1 = copy1.splice(index, 1)[0];
    copy1.splice(index +1, 0, moveTo1);
    this.addchecklistItem = copy1;
  }


  arrowUpBtn(index : number){
    const copy1 = [...this.addchecklistItem];
    const moveTo1 = copy1.splice(index, 1)[0];
    copy1.splice(index - 1, 0, moveTo1);
    this.addchecklistItem = copy1;
  }

  showSuccess(msg: string) {
    this.toastr.success(msg);
  }

  showDanger(msg: string) {
    this.toastr.error(msg);
  }
}
