import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class TaxRateModel {
    tax_rate_id: string = null;
    tax_authority_id: string = null;
    tax_authority: string = null;
    rate: string = null;
    organization_id: string = null;
    name: string = null;
    created_by: string = null;
    state: String = null;
    city: String = null;
    county: String = null;
    location_code: String = null;
}