import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token/token.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EstimateService {
  public approveInvoiceState: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public estimateDraft: BehaviorSubject<object> = new BehaviorSubject({});
  public estimateDraftImages: BehaviorSubject<any> = new BehaviorSubject([]);
  public totalAmount: BehaviorSubject<any> = new BehaviorSubject(0);
  public grossAmount: BehaviorSubject<any> = new BehaviorSubject(0);
  public estimateNumber: BehaviorSubject<any> = new BehaviorSubject(null);
  public isDraftSave: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // public advanceValue: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public _http:HttpClient,
    public _TokenService:TokenService
  ) { }

  set setApproveInvoiceState(value: boolean) {
    this.approveInvoiceState.next(value);
  }

  set setEstimateDraft(value: object) {
    this.estimateDraft.next(value);
  }

  set setEstimateDraftImages(value: object) {
    this.estimateDraftImages.next(value);
  }

  set setTotalAmount(value: any) {
    this.totalAmount.next(value);
  }

  set setGrossAmount(value: any) {
    this.grossAmount.next(value);
  }

  set setEstimateNumber(value: any) {
    this.estimateNumber.next(value);
  }

  set setIsDraftSave(value: boolean) {
    this.isDraftSave.next(value);
  }

  // set setAdvanceValue(value: boolean) {
  //   this.advanceValue.next(value);
  // }

  GetCustomerList():Observable<[]> {
    let URL = `${environment.url}customer/list`;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  GetItemList() :Observable<[]> {
    let URL = `${environment.url}item/list`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  AddEstimate(data):Observable<[]> {
    if (data.is_draft_save == 1 && data.due_date) {
      data.due_date = new Date(data.due_date.getTime() - data.due_date.getTimezoneOffset() * 60000);
    }if (data.is_draft_save == 1 && !data.due_date) {
      data.due_date = null;
    }if (data.is_draft_save == 0) {
      data.due_date = new Date(data.due_date.getTime() - data.due_date.getTimezoneOffset() * 60000);
    }
    
    let URL = `${environment.url}estimate/create`;

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  Update(data,estimate_id):Observable<[]> {
    data.due_date = new Date(data.due_date.getTime() - data.due_date.getTimezoneOffset() * 60000);
    let URL =  environment.url+ 'estimate/edit/'+estimate_id;

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  UpdateEstimate(data,estimate_id):Observable<[]> {

    let URL =  environment.url+ 'estimate/edit/'+estimate_id;

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  GetEstimateDetails(estimate_id):Observable<[]> {
    let URL = environment.url+ 'estimate/view/'+estimate_id;

    return this._http.get(URL,{
    }).pipe(map((res: any) => res));
  }

  GetEstimateList():Observable<[]> {
    let URL = environment.url+ 'estimate/list';

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }
  GetEstimateListByCustomer(customer_id):Observable<[]> {
    let URL = environment.url+ 'estimate/list-by-customer/'+customer_id;

    return this._http.post(URL,customer_id,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  DeleteEstimate(estimate_id):Observable<[]> {
    let URL = environment.url+ 'estimate/delete/'+estimate_id;

    return this._http.post(URL,estimate_id,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  CreateWorkorder(data): Observable<[]> {
    data.start_date = new Date(data.start_date.getTime() - data.start_date.getTimezoneOffset() * 60000);
    data.end_date = new Date(data.end_date.getTime() - data.end_date.getTimezoneOffset() * 60000);
    let URL = environment.url + 'estimate/approved';

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  Approve(estimate_id):Observable<[]> {
    let URL = environment.url+ 'estimate/approve/'+estimate_id;

    return this._http.post(URL,estimate_id,{
    }).pipe(map((res: any) => res));
  }

  getUser(user_id):Observable<[]> {
    let URL = environment.url+ 'user_details/view/'+user_id;

    return this._http.get(URL,{
    }).pipe(map((res: any) => res));
  }

  GetProductServiceList(data):Observable<[]> {
    let URL = `${environment.url}poducts-services-list`;

    return this._http.post(URL, data,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  EstimateSearch(data):Observable<[]> {
    let URL = `${environment.url}estimate/search`;

    return this._http.post(URL,data, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  SaveFiles(_formdata):Observable<[]> {
    let URL = `${environment.url}estimate_image/create`;

    return this._http.post(URL, _formdata, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  GetFilesList(estimate_id):Observable<[]> {
    let URL = `${environment.url}estimate_image/list/${estimate_id}`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }
  DeleteFile(estimate_image_id):Observable<[]> {
    let URL = `${environment.url}estimate_image/delete/${estimate_image_id}`;

    return this._http.delete(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  Charge(data): Observable<[]> {
    let URL = `${environment.url}advance-payment`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  GetDraftData(draft_reset):Observable<[]> {
    // draft_reset = Number(draft_reset);
    let URL = environment.url+ 'estimate/draft-view/'+draft_reset;
    
    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  GetDraftImageList(draft_reset):Observable<[]> {
    // draft_reset = Number(draft_reset);
    let URL = environment.url+ 'estimate_image/draft-image-list/'+draft_reset;
    
    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }


  // GetDraftImageData(estimate_image_id, draft_reset):Observable<[]> {
  //   draft_reset = Number(draft_reset);
  //   let URL = environment.url+ 'estimate_image/draft-image-view/'+estimate_image_id+draft_reset ;
    
  //   return this._http.get(URL, {
  //     headers: this._TokenService.GetTokenByHeader(),
  //   }).pipe(map((res: any) => res)); 
  // }
}
