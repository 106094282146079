import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { EstimateService } from 'src/app/shared/services/estimate/estimate.service';
import { ItemService } from 'src/app/shared/services/item/item.service';
import { EstimateModel, CustomerModel } from 'src/app/shared';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { environment } from 'src/environments/environment';
import { ExpenseService } from 'src/app/shared/services/expense/expense.service';
import { ServiceModuleService } from 'src/app/shared/services/service/service-module.service';
import { WorkOrderService } from 'src/app/shared/services/work-order/work-order.service';
import { WorkOrderModel } from 'src/app/shared/models/work-order/work-order.model';
import { AddProductAndServiceComponent } from 'src/app/layout/modal/add-product-and-service/add-product-and-service.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { TaxService } from 'src/app/shared/services/tax/tax.service';
import { AddChecklistService } from 'src/app/shared/services/checklist/add-checklist.service';
// import { ServiceModuleService } from 'src/app/shared/services/service/service-module.service';
// import { WorkOrderService } from 'src/app/shared/services/work-order/work-order.service';
// import { WorkOrderModel } from 'src/app/shared/models/work-order/work-order.model';
// import * as moment from 'moment';
@Component({
  selector: 'app-work-order-new',
  templateUrl: './work-order-new.component.html',
  styleUrls: ['./work-order-new.component.scss']
})
export class WorkOrderNewComponent  implements OnInit {

  public loading: boolean = true;
  public discount: number = 20;
  public percent: string = '%';
  public dollar: string = '';
  public math = Math;
  public loading1: boolean = false;
  public accepted1: boolean = false;
  public step: any = 1;
  private _step: any = null;
  public add_remove_item: boolean = false;
  public addBtnDisable: boolean = false;
  public checklistItem: any[] = [];

  public submitted: boolean = false;
  public submitted2: boolean = false;
  public discount_amount: number = 0;
  public env = environment;

  public itemArr: any[] = [];
  public expenseArr: any[] = [];
  public itemIdArr: any[] = [];
  public userlist: any[] = [];
  public Customers: any[] = [];
  public List: any[] = [];
  public itemData: any = null;
  public work_order_id: string = null;
  public item_id: string = null;
  public rate: any = 0;
  public work_order_number: any = null;
  public amount: number = 0;
  public total_amount: any = 0;
  public item_count: number = 0;
  public model_item_count: number = 0;
  public transformedData: any = null;
  public additional_image_name: any;
  public additionalFileExtension: any;
  public multiple_image_extension_validation: boolean = true;
  public multiple_image_size_validation: boolean = true;
  public image_extension: boolean = true;
  public image_size: any;
  public fileSizeMessage: any;
  public multiple_image_size: any;
  public filesSizeMessage: any;
  public multiplefileExtensionMessage: any;
  public addBtnLoader = false;
  public modalRef: BsModalRef;
  public subscriptions: Subscription[] = [];
  public checklist_enabled: boolean = false;
  public enabled_modules: any[] = [];
  public alternative_number: any[] = [];
  imagList: Array<any> = [];
  expenseData: Array<any> = [];
  invoice_expenseData: Array<any> = [];
  work_order_images: Array<any> = [];
  images: Array<any> = [];
  public customerList: any[] = [];

  public WorkOrderModel: WorkOrderModel = new WorkOrderModel();
  public CustomerModel: CustomerModel = new CustomerModel();
  public _formdata = new FormData();

  public NewWorkOrder = this.fb.group({
    customer_id: ['', Validators.required], //Validators.required
    line_item: [''], //Validators.required
    assign_to: ['', Validators.required], //Validators.required
    expenses: [''],
    quantity: [1, [Validators.required, Validators.pattern('^[0-9]*$')]],
    unit_price: [0, [Validators.required, Validators.pattern('^[0-9]*$')]],
    discount: ['',Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')],
    discount_type: ['%'],
    advance_type: ['%'],
    sales_tax: ['',
      Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')
    ],
    due_date: ['',],
    // start_date: ['', Validators.required],
    // end_date: ['', Validators.required],
    new_date_range: ['', Validators.required],
    advance: ['',
    Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')
    ],
    note: [''],
    private_note: [''],
    recurringType: ['', [Validators.required]],
    recurringTypeValue: ['', [Validators.required]],
    recurringTypeValueTwo: ['', [Validators.required]],
    checklist_id: ['']
  });

  public files: File[] = [];
  public submitted3: boolean = false;
  public add_item: boolean = false;
  public isCheckedPercent: boolean = false;
  public isCheckedDollar: boolean = false;
  public isCheckedDiscountPercent: boolean = false;
  public isCheckedDiscountDollar: boolean = false;

  public weekTrigger: boolean = true;
  public monthTrigger: boolean = true;
  public isWeekChecked: boolean = false;
  public isMonthChecked: boolean = false;
  public recurring_check: boolean = false;
  recurring_value: null;
  public customer_id: string = null;

  public minDate_for_start_date:any = null;
  public minDate_for_end_date:any = null;
  public minDate: Date;
  // recurring_list (demo)
  type_of_recurrings: string[] = [
    'Weekly',
    'Biweekly',
    'Monthly',
    'Quarterly',
    'Yearly'
  ];
  // week_day_list (demo)
  week_days: string[] = [
    'Sunday',
    'Monday',
    'Tuesday ',
    'Wednesday ',
    'Thursday ',
    'Friday ',
    'Saturday ',
  ];
  
  // month_day_list (demo)
  days: number[] = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28];
// Quarterly
  quarterly: any[] = [
    '1st','2nd','3rd'
  ]
  // yearly_day_list (demo)
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  public productServices: any[] = [];
  public current_selected_item:any = null;

  constructor(
    public _router: Router,
    public _activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    public _invoiceService: InvoiceService,
    public ItemService: ItemService,
    public CustomerService: CustomerService,
    public toastr: ToastrService,
    public ExpenseServive: ExpenseService,
    public SettingService:SettingService,
    public serviceService:ServiceModuleService,
    public UserService: UserService,
    public WorkOrderService: WorkOrderService,
    public AddChecklistService: AddChecklistService,
    public modalService: BsModalService,
    public taxService: TaxService
  ) 
  {
    this.step = this._activatedRoute.snapshot.paramMap.get('step');
    if (this.step == '2') {
      this.step = (+this.step)
      this.Step(this.step);
    }

    this.customer_id = this._activatedRoute.snapshot.paramMap.get('customer_id');
    if (this.customer_id) {
      this.Customer(this.customer_id)
    }

    this.item_id = this._activatedRoute.snapshot.paramMap.get('id');
    if (this.item_id) {
      this.getProductFromProductPage();
    }

    this.work_order_id = this._activatedRoute.snapshot.paramMap.get('work_order_id');
    if (this.work_order_id != null) {
      this.work_order_number = this._activatedRoute.snapshot.paramMap.get('work_order_index');
    }

    // this.getExpenseList();
    this.minDate = new Date();
    this.getWorkOrderDetails(this.work_order_id);
    if (this.work_order_id != null) {
      this.getWorkOrderImages(this.work_order_id);
    }
    if (this.work_order_id == null) {
      this.GetWorkOrderList();
    }
    this.productServiceList();

    if ( JSON.parse( localStorage.getItem( 'activated_modules' ) ) )
    {
      this.enabled_modules = JSON.parse( localStorage.getItem( 'activated_modules' ) );
      for ( let index of this.enabled_modules )
      {
        if ( index[ 'module_id' ] == '50bf5d16f34b' && index[ 'enabled' ] == 1)
        {
          this.checklist_enabled = true;
        }
      }
    }
  }

  ngOnInit() {
    this.getCheckListItem();
    this.itemArr = [];
    window.scroll(0, 0);
    this._step = this._activatedRoute.snapshot.paramMap.get('step');
    this.Step(this._step);
    this.CustomerList();
    this.getUserList();

    // this.ItemList();
    // this.Unselect();
    if(this.work_order_id==null){
      this.minDate_for_start_date = new Date();
      this.minDate_for_start_date.setDate(this.minDate_for_start_date.getDate());
      this.minDate_for_end_date = new Date();
      this.minDate_for_end_date.setDate(this.minDate_for_end_date.getDate());
    }

    this.serviceService.setServiceSuccess = null;
    this.serviceService.setMoreServiceSuccess = null;
    this.add_item = false;
    this.itemArr = [];
  }
  
  ngAfterViewInit(){
    const serviceAndProductResponse = this.serviceService.serviceSuccess.subscribe((response) => {
      if(response){
        this.productServiceList()
        this.modalService.hide(1);
      let removeModal =  document.querySelector('.modal-open');
      removeModal.classList.remove('modal-open')
      }
    });
    const moreServiceAndProductResponse = this.serviceService.servicMoreSuccess.subscribe((response) => {
      if(response){
        this.productServiceList()
      }
    })
    this.subscriptions.push(serviceAndProductResponse, moreServiceAndProductResponse)
    this.getProductServiceModalData();
    this.getServiceModalData();
    this.itemArr = [];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  checkToggle(type: string) {
    if (type === "week") {
      this.isWeekChecked = !this.isWeekChecked;
      this.isMonthChecked = false;

      this.isWeekChecked ? this.f.week_day.enable() : this.f.week_day.disable();
      this.f.months.disable();
    }
    else if (type === "month") {
      this.isMonthChecked = !this.isMonthChecked;
      this.isWeekChecked = false;

      this.isMonthChecked ? this.f.months.enable() : this.f.months.disable();
      this.f.week_day.disable();
    }
  }

  recurring_toggle() {
    this.recurring_check = !this.recurring_check;
  }


  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    this.transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;
    return this.transformedData;
  }

  Unselect(event) {
    if (this.NewWorkOrder.value.advance_type != null) {

      this.NewWorkOrder.get('advance_type').reset();
      this.NewWorkOrder.get('advance_type').updateValueAndValidity();
    }
  }

  Customer(event) {
    if (event) {
      // this.loading = true;
      this.CustomerService.GetCustomerById(event)
        .subscribe((result) => {
          // this.loading = false;

          this.CustomerModel.customer_id = result['data'].customer_id;
          this.CustomerModel.name = result['data'].name;
          this.CustomerModel.mobile = result['data'].mobile;
          this.CustomerModel.alternative_number = result['data'].alternative_number;
          this.alternative_number = this.CustomerModel.alternative_number;
          this.CustomerModel.email = result['data'].email;
          this.CustomerModel.address = result['data']['default_address'].address;
          this.CustomerModel.locality = result['data']['default_address'].locality;
          this.CustomerModel.zip = result['data']['default_address'].zip;
          if (!this.NewWorkOrder.value.customer_id) {
            this.NewWorkOrder.get('customer_id').setValue(this.CustomerModel.customer_id);
            this.NewWorkOrder.get('customer_id').updateValueAndValidity();
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

  GetWorkOrderList() {
    this.WorkOrderService.GetWorkOrderList().subscribe(
      (result) => {
        this.work_order_number = result['data'].length + 1;
        this.digitFormatter(this.work_order_number);
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  public isCustomerNull: boolean = false;

  getCustomerList() {
    if(this.isCustomerNull) {
      this.toastr.warning("Please add customer first");
    }
  }

  CustomerList() {
    this._invoiceService.GetCustomerList().subscribe(
      result => {
        if(result['data'].length) {
          this.isCustomerNull = false;
        }else {
          this.isCustomerNull = true;
        }
        this.customerList = result['data'];
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  GetCustomerListOnSearch(event){
    let data={
      search: event.target.value
    }
    if(data.search!=''){
      this.CustomerService.GetCustomerListOnSearch(data).subscribe(
        result=>{
          this.Customers = result['data'];
          
        }
      )
    }else{
      this.Customers = null;
    }
    
    
  }

  ItemList() {

    this._invoiceService.GetItemList().subscribe(
      result => {
        // this.loading = false;
        this.List = result['data'];

      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  get f() {
    return this.NewWorkOrder.controls;
  }

  dollarActive() {
    this.dollar = '$';
    this.percent = '';
  }

  percentActive() {
    this.dollar = '';
    this.percent = '%';
  }


  Step(step: number) {
    if (step == 2) {
      window.scroll(0,0)
      this.submitted3 = false;
        if ((this.NewWorkOrder.controls.customer_id.invalid)
          || (this.NewWorkOrder.controls.unit_price.invalid)
          || (this.NewWorkOrder.controls.quantity.invalid)
          || (this.NewWorkOrder.controls.discount.invalid)
          || (this.NewWorkOrder.controls.discount_type.invalid)
          || (this.NewWorkOrder.controls.sales_tax.invalid)) {
          this.submitted = true;
          if(this.WorkOrderModel.items.length == 0){
            this.add_item = true;
          }
          return;
        }

        if(this.WorkOrderModel.items.length==0){
          this.add_item=true;
          return;
        }
        if ( this.WorkOrderModel.items.length > 0 ){
          this.add_item = false;
        }
      }
      let flag = 0;
      if(this.NewWorkOrder.value.line_item){
        if(this.WorkOrderModel.items.length>0)
        for(let value of  this.WorkOrderModel.items){
          if(value.item_id == this.NewWorkOrder.value.line_item){
            flag =1;
            break;

          }else{
           flag =0;
          }
        }
        if(flag==0)
        {
          this.add_remove_item = true;
          return;
  
  
        }else{
          this.add_remove_item = false;
  
        }
      }
    this.step = step;
  }



  Route() {
    if (this.step === 2) {
      this.step = 1;
    }
    else {
      this._router.navigate(['/work-order']);
    }
  }

  Calculate() {
    if (this.amount == 0) {
      this.WorkOrderModel.total = 0;
    }

    if (this.NewWorkOrder.value.discount_type != null
      && this.NewWorkOrder.value.discount_type != ''
      && this.NewWorkOrder.value.discount != null
      && this.NewWorkOrder.value.discount != '') {
      if (this.NewWorkOrder.value.discount_type == '%') {
        this.discount_amount = 0;
        this.discount_amount = (this.amount * this.NewWorkOrder.value.discount) / 100;
        this.discount_amount = this.amount - this.discount_amount;
      } else if (this.NewWorkOrder.value.discount_type == '$') {
        this.discount_amount = 0;
        this.discount_amount = this.amount - this.NewWorkOrder.value.discount;
      }
    }

    if (this.NewWorkOrder.value.sales_tax != null || this.NewWorkOrder.value.sales_tax != '') {
      if (this.discount_amount == 0) {
        this.total_amount = this.amount * (1 + (this.NewWorkOrder.value.sales_tax / 100));

        this.total_amount = (Math.round(this.total_amount * 10000) / 10000).toFixed(2);
      } else {
        this.total_amount = this.discount_amount * (1 + (this.NewWorkOrder.value.sales_tax / 100));
        this.total_amount = (Math.round(this.total_amount * 10000) / 10000).toFixed(2);


      }
    }

    if (this.work_order_id === null) {
      if ((this.NewWorkOrder.value.advance != null && this.NewWorkOrder.value.advance != '')
        && (this.NewWorkOrder.value.advance_type != null && this.NewWorkOrder.value.advance_type != '')) {
        if (this.NewWorkOrder.value.advance_type == "%") {
          this.total_amount = this.total_amount * (1 - this.NewWorkOrder.value.advance / 100);
          this.total_amount = (Math.round(this.total_amount * 10000) / 10000).toFixed(2)
        } else {
          this.total_amount = this.total_amount - this.NewWorkOrder.value.advance;
          this.total_amount = (Math.round(this.total_amount * 10000) / 10000).toFixed(2)
        }
      }
    }
    else if (this.work_order_id !== null) {
      if ((this.WorkOrderModel.advance != null && this.WorkOrderModel.advance != '')
        && (this.WorkOrderModel.advance_type != null && this.WorkOrderModel.advance_type != '')) {
        if (this.WorkOrderModel.advance_type == "%") {
          this.total_amount = this.total_amount * (1 - this.WorkOrderModel.advance / 100);
          this.total_amount = (Math.round(this.total_amount * 10000) / 10000).toFixed(2)
        } else {
          this.total_amount = this.total_amount - this.WorkOrderModel.advance;
          this.total_amount = (Math.round(this.total_amount * 10000) / 10000).toFixed(2)
        }
      }
    }

    if (this.discount_amount == 0 && this.total_amount == 0) {
      this.total_amount = this.amount;
    } else if (this.discount_amount > 0 && this.total_amount == 0) {
      this.total_amount = this.discount_amount;
    } else if (this.total_amount > 0) {
      this.total_amount = this.total_amount;
    }
  }
  //Change Unit Price

  ChangeUnitPrice(event) {

  }

  // Add item
  AddItem() {
    // this.submitted = false;
    this.add_item = false;
    this.add_remove_item = false;
    if ((this.NewWorkOrder.controls.unit_price.invalid) || (this.NewWorkOrder.controls.quantity.invalid)) {
      this.submitted3 = true;
      return;
    }
    this.rate = this.NewWorkOrder.value.unit_price;
    let addItemField: HTMLInputElement = document.querySelector('[name="bindValue"]');
    let addQuantityField: HTMLInputElement = document.querySelector('[name="quantity"]');
    if (this.NewWorkOrder.value.line_item !== '') {
      
      if(this.current_selected_item.group=='Product'){
        this.ItemService.GetItemById(this.NewWorkOrder.value.line_item)
        .subscribe((result) => {
          window.scroll(0,0)
          for (let i in this.itemArr) {
            if (this.itemArr[i].item_id == result['data'].name) {
              this.itemArr[i].qty = this.NewWorkOrder.value.quantity;
              this.itemArr[i].group = 'Product';
              this.itemArr[i].unit_price = this.rate;
              this.itemArr[i].rate = this.NewWorkOrder.value.quantity * (+this.rate)
              this.item_count = 1;
              break;
            }
          }

          if (this.item_count == 0) {
            this.itemArr.push({ item_id: result['data'].name, group:'Product', qty: this.NewWorkOrder.value.quantity, unit_price: this.rate, rate: this.NewWorkOrder.value.quantity * (+this.rate) });
          }
          this.item_count = 0;
          this.itemIdArr.push(result['data'].item_id);

          this.amount = 0;


          if (this.amount == 0) {
            for (let i of this.itemArr) {
              this.amount = this.amount + (+i.rate);

            }
          }

          if (this.work_order_id == null) {
            if (this.NewWorkOrder.value.unit_price > 0) {
              this.NewWorkOrder.controls.unit_price.setValue(0);
              this.NewWorkOrder.updateValueAndValidity();
            }
            if (this.NewWorkOrder.value.quantity > 0) {
              // this.amount = this.amount + ((+result['data'].rate) * this.NewWorkOrder.value.quantity);
            }
            else if (this.NewWorkOrder.value.quantity == '' || this.NewWorkOrder.value.quantity == null) {
              this.NewWorkOrder.controls.quantity.setValue(1);
              // this.amount = this.amount + ((+result['data'].rate) * this.NewWorkOrder.value.quantity);
            }

            this.Calculate();
            this.NewWorkOrder.controls.quantity.setValue(1);
            this.NewWorkOrder.updateValueAndValidity();
          }
          else {
            if (this.NewWorkOrder.value.unit_price > 0) {
              this.NewWorkOrder.controls.unit_price.setValue(0);
              this.NewWorkOrder.updateValueAndValidity();
            }

            if (this.NewWorkOrder.value.quantity > 0) {
              // this.amount = this.amount + ((+result['data'].rate) * this.NewWorkOrder.value.quantity);
            }
            else if (this.NewWorkOrder.value.quantity == '' || this.NewWorkOrder.value.quantity == null) {
              this.NewWorkOrder.controls.quantity.setValue(1);
              // this.amount = this.amount + ((+result['data'].rate) * this.NewWorkOrder.value.quantity);
            }


            this.Calculate();
            this.WorkOrderModel.amount = this.amount;
            this.NewWorkOrder.controls.quantity.setValue(1);
            this.NewWorkOrder.updateValueAndValidity();

          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
        );
      }else if(this.current_selected_item.group=='Service'){
        this.serviceService.GetServiceById(this.NewWorkOrder.value.line_item)
        .subscribe((result) => {
          for (let i in this.itemArr) {

            if (this.itemArr[i].item_id == result['data'].name) {
              this.itemArr[i].qty = this.NewWorkOrder.value.quantity;
              this.itemArr[i].group = 'Service';
              this.itemArr[i].unit_price = this.rate;
              this.itemArr[i].rate = this.NewWorkOrder.value.quantity * (+this.rate)
              this.item_count = 1;
              break;
            }
          }
          if (this.item_count == 0) {
            this.itemArr.push({ item_id: result['data'].name, group:'Service' ,qty: this.NewWorkOrder.value.quantity, unit_price: this.rate, rate: this.NewWorkOrder.value.quantity * (+this.rate) });
          }
          this.item_count = 0;
          this.itemIdArr.push(result['data'].item_id);
          this.amount = 0;
          if (this.amount == 0) {
            for (let i of this.itemArr) {
              this.amount = this.amount + (+i.rate);
            }
          }

          if (this.work_order_id == null) {
            if (this.NewWorkOrder.value.unit_price > 0) {
              this.NewWorkOrder.controls.unit_price.setValue(0);
              this.NewWorkOrder.updateValueAndValidity();
            }
            if (this.NewWorkOrder.value.quantity > 0) {
              // this.amount = this.amount + ((+result['data'].rate) * this.NewWorkOrder.value.quantity);
            }
            else if (this.NewWorkOrder.value.quantity == '' || this.NewWorkOrder.value.quantity == null) {
              this.NewWorkOrder.controls.quantity.setValue(1);
              // this.amount = this.amount + ((+result['data'].rate) * this.NewWorkOrder.value.quantity);
            }

            this.Calculate();
            this.NewWorkOrder.controls.quantity.setValue(1);
            this.NewWorkOrder.updateValueAndValidity();
          }
          else {
            if (this.NewWorkOrder.value.unit_price > 0) {
              this.NewWorkOrder.controls.unit_price.setValue(0);
              this.NewWorkOrder.updateValueAndValidity();
            }

            if (this.NewWorkOrder.value.quantity > 0) {
              // this.amount = this.amount + ((+result['data'].rate) * this.NewWorkOrder.value.quantity);
            }
            else if (this.NewWorkOrder.value.quantity == '' || this.NewWorkOrder.value.quantity == null) {
              this.NewWorkOrder.controls.quantity.setValue(1);
              // this.amount = this.amount + ((+result['data'].rate) * this.NewWorkOrder.value.quantity);
            }


            this.Calculate();
            this.WorkOrderModel.amount = this.amount;
            this.NewWorkOrder.controls.quantity.setValue(1);
            this.NewWorkOrder.updateValueAndValidity();

          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
        );
      }
      

      for (let i in this.WorkOrderModel.items) {

        if (this.WorkOrderModel.items[i].item_id == this.NewWorkOrder.value.line_item) {
          // if (this.NewWorkOrder.value.quantity == '' || this.NewWorkOrder.value.quantity == null) {
          //   this.NewWorkOrder.value.quantity = 1;
          // }
          this.WorkOrderModel.items[i].qty = this.NewWorkOrder.value.quantity;
          this.WorkOrderModel.items[i].group = this.current_selected_item.group;
          this.WorkOrderModel.items[i].unit_price = this.rate
          this.model_item_count = 1;
          break;
        }
      }

      if (this.model_item_count == 0) {
        this.WorkOrderModel.items.push({
          item_id: this.NewWorkOrder.value.line_item,
          group: this.current_selected_item.group,
          qty: addQuantityField.value || '1',
          unit_price: this.rate
        });
      }

      this.model_item_count = 0;
    }
    else {
      return;
    }
    
    this.NewWorkOrder.get('line_item').setValue(null);
    this.NewWorkOrder.get('line_item').updateValueAndValidity();
    this.NewWorkOrder.value.line_item = '';

  }

  DeleteItem(index: number) {
    
    let spliced_item = this.itemArr.splice(index, 1);


    let id = this.itemIdArr.splice(index, 1);
    let invoice_amount = this.WorkOrderModel.items.splice(index, 1);

    if (this.WorkOrderModel.items.length == 0) {
      this.NewWorkOrder.get('line_item').setValue('');

      this.NewWorkOrder.get('line_item').setValidators([Validators.required]);
      this.NewWorkOrder.get('line_item').updateValueAndValidity();
    }

    if(spliced_item[0].group=='Product'){
      for (let i of invoice_amount) {
        this.ItemService.GetItemById(i.item_id).subscribe(
          result => {
            if (this.work_order_id == null) {
              this.amount = this.amount - (+i.unit_price * i.qty);
              // this.DiscountCalculate();
              // if (this.NewWorkOrder.value.sales_tax != null || this.NewWorkOrder.value.sales_tax != '') {
              //   this.SalesTax(null, this.NewWorkOrder.value.sales_tax);
              // }
  
              this.Calculate();
            } else {
              this.amount = this.amount - (+i.unit_price * i.qty);
              this.WorkOrderModel.amount = this.amount;
              // this.DiscountCalculate();
  
              this.Calculate();
            }
          }, (err:HttpErrorResponse) => {
            if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
              this.SettingService.LogOut();
            }
          }
        )
      }
    }else if(spliced_item[0].group=='Service'){
      for (let i of invoice_amount) {
        this.serviceService.GetServiceById(i.item_id).subscribe(
          result => {
            if (this.work_order_id == null) {
              this.amount = this.amount - (+i.unit_price * i.qty);
              // this.DiscountCalculate();
              // if (this.NewWorkOrder.value.sales_tax != null || this.NewWorkOrder.value.sales_tax != '') {
              //   this.SalesTax(null, this.NewWorkOrder.value.sales_tax);
              // }
  
              this.Calculate();
            } else {
              this.amount = this.amount - (+i.unit_price * i.qty);
              this.WorkOrderModel.amount = this.amount;
              // this.DiscountCalculate();
  
              this.Calculate();
            }
            
          }, (err:HttpErrorResponse) => {
            if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
              this.SettingService.LogOut();
            }
          }
        )

      }
    }
    
  }

  DiscountCalculate() {
    this.Calculate();
  }

  AdvanceCalculate() {
    this.Calculate();
  }


  ViewProductOrSeviceData(evt) {
    if(!this.NewWorkOrder.value.line_item || !evt){
      this.add_remove_item = false
      this.NewWorkOrder.get('unit_price').setValue(0);
      this.NewWorkOrder.get('quantity').setValue(1);
      this.NewWorkOrder.updateValueAndValidity();
      this.addBtnDisable = true;
    }

    if(evt){
      this.addBtnLoader = true;
      this.addBtnDisable = true;
      this.current_selected_item = evt;
  
      let value = evt['id'];
      if(evt['group']=='Product'){
        this._invoiceService.ViewListData(value).subscribe(
          (result: any) => {
            this.itemData = result['data'];
            this.rate = result['data']['rate'];
            this.addBtnLoader = false;
            this.addBtnDisable = false;
          }, (err:HttpErrorResponse) => {
            this.addBtnLoader = false;
            this.addBtnDisable = false;
            if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
              this.SettingService.LogOut();
            }
          }
        )
      }else if(evt['group']=='Service'){
        this.serviceService.GetServiceById(value).subscribe(
          (result: any) => {
            this.itemData = result['data'];
            this.rate = result['data']['rate'];
            this.addBtnLoader = false;
            this.addBtnDisable = false;
          }, (err:HttpErrorResponse) => {
            this.addBtnLoader = false;
            this.addBtnDisable = false;
            if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
              this.SettingService.LogOut();
            }
          }
        )    
      }
    }

  }

  getWorkOrderDetails(work_order_id) {
    this.loading = true;

    if (work_order_id != null) {
      this.WorkOrderService.GetWorkOrderDetail(work_order_id).subscribe(
        result => {
          this.loading = false;
          this.WorkOrderModel.start_date = new Date(result['data'].start_date);
          this.WorkOrderModel.end_date = new Date(result['data'].end_date);


          this.minDate_for_start_date = new Date();
          this.minDate_for_start_date.setDate(this.minDate_for_start_date.getDate());

          this.minDate_for_end_date = new Date();
          this.minDate_for_end_date.setDate(this.WorkOrderModel.start_date.getDate());

          
          this.WorkOrderModel.customer_id = result['data'].customer_id;
          this.WorkOrderModel.work_order_id = result['data'].work_order_id;
          if(result['data']['workorder_customers']){
            this.CustomerModel.customer_id = result['data']['workorder_customers'][0]['customer_id'];
            this.CustomerModel.name = result['data']['workorder_customers'][0]['name'];
            
            this.CustomerModel.address = result['data']['workorder_customers'][0]['address'];
            this.CustomerModel.email = result['data']['workorder_customers'][0]['email'];
            this.CustomerModel.zip = result['data']['workorder_customers'][0]['zip'];
            this.CustomerModel.locality = result['data']['workorder_customers'][0]['locality'];
            this.CustomerModel.mobile = result['data']['workorder_customers'][0]['mobile'];
            this.CustomerModel.alternative_number = result['data']['workorder_customers'][0]['alternative_number'];
            this.alternative_number = result['data']['workorder_customers'][0]['alternative_number'];
          }          
          this.WorkOrderModel.assign_to = result['data'].assign_to;
          if (result['data']['workorder_workorderitems'] != null || result['data']['workorder_workorderitems'].length > 0) {
            this.WorkOrderModel.line_item = result['data']['workorder_workorderitems'][0].item_id;
            this.NewWorkOrder.get('line_item').clearValidators();
            this.NewWorkOrder.get('line_item').updateValueAndValidity();

            for (let index of result['data']['workorder_workorderitems']) {
              if(index.workorderitems_items_with_trashed!=null && index.workorderitems_items_with_trashed!=''){
                if(index.group=='Product'){
                  this.WorkOrderModel.items.push({
                    item_id: index.workorderitems_items_with_trashed.item_id,
                    qty: index.quantity,
                    group: index.group,
                    unit_price: index.rate,
                  });
                  this.itemArr.push({
                    item_id: index.workorderitems_items_with_trashed.name,
                    qty: index.quantity,
                    rate: index.quantity * (+index.rate),
                    unit_price: index.rate,
                    group: index.group,
    
                  });
                }else if(index.group=='Service'){
                  this.WorkOrderModel.items.push({
                    item_id: index.workorderitems_items_with_trashed.service_id,
                    qty: index.quantity,
                    unit_price: index.rate,
                    group: index.group,
                  });
                  this.itemArr.push({
                    item_id: index.workorderitems_items_with_trashed.name,
                    qty: index.quantity,
                    rate: index.quantity * (+index.rate),
                    unit_price: index.rate,
                    group: index.group,
    
                  });
                }
              }else if(index.workorderitems_service_with_trashed!=null && index.workorderitems_service_with_trashed!=''){
                if(index.group=='Product'){
                  this.WorkOrderModel.items.push({
                    item_id: index.workorderitems_service_with_trashed.item_id,
                    qty: index.quantity,
                    group: index.group,
                    unit_price: index.rate,
                  });
                  this.itemArr.push({
                    item_id: index.workorderitems_service_with_trashed.name,
                    qty: index.quantity,
                    rate: index.quantity * (+index.rate),
                    unit_price: index.rate,
                    group: index.group,
    
                  });
                }else if(index.group=='Service'){
                  this.WorkOrderModel.items.push({
                    item_id: index.workorderitems_service_with_trashed.service_id,
                    qty: index.quantity,
                    unit_price: index.rate,
                    group: index.group,
                  });
                  this.itemArr.push({
                    item_id: index.workorderitems_service_with_trashed.name,
                    qty: index.quantity,
                    rate: index.quantity * (+index.rate),
                    unit_price: index.rate,
                    group: index.group,
    
                  });
                }
              }
              
              
            }
          }
          // if (result['data']['invoice_invoiceexpenses'] != null && result['data']['invoice_invoiceexpenses'].length > 0) {
          //   this.WorkOrderModel.expenses = result['data']['invoice_invoiceexpenses'][0].expenses_id;
          //   this.NewWorkOrder.get('expenses').clearValidators();
          //   this.NewWorkOrder.get('expenses').updateValueAndValidity();

          //   for (let index of result['data']['invoice_invoiceexpenses']) {
          //     this.WorkOrderModel.expense.push(
          //       index['expense_id']
          //     );
          //     this.expenseArr.push({
          //       expense_type: index.invoiceexpenses_expenses.type,
          //       expense_id: index.invoiceexpenses_expenses.expenses_id,
          //       expense_amount: index.invoiceexpenses_expenses.total,
          //       expense_number: index.invoiceexpenses_expenses.expense_number,
          //       expense_merchant: index.invoiceexpenses_expenses.merchant
          //     });
          //     this.invoice_expenseData.push(index.invoiceexpenses_expenses);
          //   }
          // }
          this.WorkOrderModel.discount = result['data'].discount;
          this.WorkOrderModel.discount_type = result['data'].discount_type;
          if(this.WorkOrderModel.discount_type!=null && this.WorkOrderModel.discount_type!=''){
            if(this.WorkOrderModel.discount_type=='%'){
    
              this.isCheckedDiscountPercent=true;
            }else if(this.WorkOrderModel.discount_type=='$'){
    
              this.isCheckedDiscountDollar=true;
            }
          }
          this.WorkOrderModel.sales_tax = result['data'].sales_tax;
          this.WorkOrderModel.advance = result['data'].advance;
          this.WorkOrderModel.advance_type = result['data'].advance_type;
          if(this.WorkOrderModel.advance_type!=null && this.WorkOrderModel.advance_type!=''){            
            if(this.WorkOrderModel.advance_type=='%'){    
              this.isCheckedPercent=true;
            }else if(this.WorkOrderModel.advance_type=='$'){    
              this.isCheckedDollar=true;
            }
          }
          this.WorkOrderModel.due_date = result['data'].due_date;
          
          const startDate = new Date(result['data'].start_date);

          const endDate = new Date(result['data'].end_date);

          const dateArry = [
            startDate,
            endDate
          ]
          this.WorkOrderModel.new_date_range = dateArry;
          this.WorkOrderModel.amount = result['data'].amount;
          this.WorkOrderModel.total = result['data'].total;
          this.amount = result['data'].amount;
          this.total_amount = result['data'].total;
          this.WorkOrderModel.note = result['data'].note;
          this.WorkOrderModel.private_note = result['data'].private_note;
          this.WorkOrderModel.checklist_id = result['data'].checklist_id;
          this.WorkOrderModel.work_order_number = result['data'].work_order_number;
          
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
    else {
      this.loading = false;
    }
  }

  Discount(event) {
    if (event.target.value == null || event.target.value == '') {
      this.NewWorkOrder.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);
      this.NewWorkOrder.get('discount').setValue('');
      this.NewWorkOrder.updateValueAndValidity();
      this.discount_amount = 0;
    }
    else {
      this.NewWorkOrder.get('discount_type').setValidators(Validators.required);
      this.NewWorkOrder.get('discount_type').updateValueAndValidity();
      if ((this.NewWorkOrder.value.discount != null
        || this.NewWorkOrder.value.discount != '')
        && (this.NewWorkOrder.value.discount_type != null
          || this.NewWorkOrder.value.discount_type != '')) {
        // this.DiscountCalculate();
        this.Calculate();

      }
    }

    if (this.NewWorkOrder.value.discount == null || this.NewWorkOrder.value.discount == '') {
      if (this.NewWorkOrder.value.discount_type == null || this.NewWorkOrder.value.discount_type == '') {
        this.NewWorkOrder.get('discount').clearValidators();
        this.NewWorkOrder.get('discount_type').clearValidators();
        this.NewWorkOrder.get('discount').updateValueAndValidity();
        this.NewWorkOrder.get('discount_type').updateValueAndValidity();
      }
    }

    if (this.NewWorkOrder.value.discount_type != null || this.NewWorkOrder.value.discount_type != '') {
      // this.discount_amount = this.amount;
      // this.discount_amount = (this.amount * this.NewWorkOrder.value.discount) / 100;
      // this.discount_amount = this.amount - this.discount_amount;
      // this.DiscountCalculate();
      // if (this.NewWorkOrder.value.sales_tax != null || this.NewWorkOrder.value.sales_tax != '') {
      //   this.SalesTax(null, this.NewWorkOrder.value.sales_tax)
      // }

      this.Calculate();

    }
  }

  Advance(evt: Event) {
    if (evt.target['value'] == null || evt.target['value'] == '') {
      this.NewWorkOrder.get('advance').setValidators(Validators.required);
      this.NewWorkOrder.get('advance').setValue('');
      this.NewWorkOrder.updateValueAndValidity();
    }
    else {

      this.NewWorkOrder.get('advance_type').setValidators(Validators.required);
      this.NewWorkOrder.get('advance_type').updateValueAndValidity();
      if ((this.NewWorkOrder.value.advance != null
        || this.NewWorkOrder.value.advance != '')
        && (this.NewWorkOrder.value.advance_type != null
          || this.NewWorkOrder.value.advance_type != '')) {
        this.Calculate();
      }
    }

    if (this.NewWorkOrder.value.advance == null || this.NewWorkOrder.value.advance == '') {
      if (this.NewWorkOrder.value.advance_type == null || this.NewWorkOrder.value.advance_type == '') {
        this.NewWorkOrder.get('advance').clearValidators();
        this.NewWorkOrder.get('advance_type').clearValidators();
        this.NewWorkOrder.get('advance').updateValueAndValidity();
        this.NewWorkOrder.get('advance_type').updateValueAndValidity();
      }
    }
  }

  DiscountInPercentage(event) {
    this.percentActive();
    if ((event.target.value != null || event.target.value != '') && event.target.name == 'discount_type') {
      if (this.NewWorkOrder.value.discount == null) {
        this.NewWorkOrder.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);
        this.NewWorkOrder.get('discount').setValue('');
        this.NewWorkOrder.updateValueAndValidity();
      }

      if (this.NewWorkOrder.value.discount != null && this.NewWorkOrder.value.discount != '') {
        this.Calculate();
      }
    }
  }

  DiscountInDollar(event) {
    this.dollarActive();
    if ((event.target.value != null || event.target.value != '') && event.target.name == 'discount_type') {
      if (this.NewWorkOrder.value.discount == null) {
        this.NewWorkOrder.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);
        this.NewWorkOrder.get('discount').setValue('');
        this.NewWorkOrder.updateValueAndValidity();
      }
      if (this.NewWorkOrder.value.discount != null && this.NewWorkOrder.value.discount != '') {
        this.Calculate();
      }
    }
  }

  unCheckPercent(evt: Event) {
    this.isCheckedPercent = !this.isCheckedPercent;
    this.isCheckedDollar = false;
    if (this.isCheckedPercent == false && (this.NewWorkOrder.value.advance == null || this.NewWorkOrder.value.advance == '')) {
      this.NewWorkOrder.get('advance_type').setValue('');
      this.NewWorkOrder.get('advance_type').clearValidators();
      this.NewWorkOrder.get('advance').clearValidators();

      this.NewWorkOrder.get('advance_type').updateValueAndValidity();
      this.NewWorkOrder.get('advance').updateValueAndValidity();
    } else if(this.isCheckedPercent == false && (this.NewWorkOrder.value.advance != null || this.NewWorkOrder.value.advance != '')){
      this.NewWorkOrder.get('advance_type').setValue('');
      this.NewWorkOrder.get('advance_type').updateValueAndValidity();

      this.NewWorkOrder.get('advance_type').setValidators(Validators.required);
      this.NewWorkOrder.get('advance').setValidators(Validators.required);

      this.NewWorkOrder.get('advance_type').updateValueAndValidity();
      this.NewWorkOrder.get('advance').updateValueAndValidity();
    }else{
      this.NewWorkOrder.get('advance_type').setValidators(Validators.required);
      this.NewWorkOrder.get('advance').setValidators(Validators.required);

      this.NewWorkOrder.get('advance_type').updateValueAndValidity();
      this.NewWorkOrder.get('advance').updateValueAndValidity();
    }

    this.Calculate();

  }

  AdvanceInPercentage(event) {
    this.percentActive();
    if ((event.target.value != null || event.target.value != '')
      && event.target.name == 'advance_type') {
      if (this.NewWorkOrder.value.advance == null) {
        this.NewWorkOrder.get('advance').setValidators(Validators.required);
        this.NewWorkOrder.get('advance').setValue('');
        this.NewWorkOrder.updateValueAndValidity();
      }
      this.Calculate();
    }
  }

  unCheckDollar(evt: Event) {
    this.isCheckedDollar = !this.isCheckedDollar;
    this.isCheckedPercent = false;
    if (this.isCheckedDollar == false && (this.NewWorkOrder.value.advance == null || this.NewWorkOrder.value.advance == '')) {
      this.NewWorkOrder.get('advance_type').setValue('');
      this.NewWorkOrder.get('advance_type').clearValidators();
      this.NewWorkOrder.get('advance').clearValidators();

      this.NewWorkOrder.get('advance_type').updateValueAndValidity();
      this.NewWorkOrder.get('advance').updateValueAndValidity();
    } else if(this.isCheckedDollar == false && (this.NewWorkOrder.value.advance != null || this.NewWorkOrder.value.advance != '')){
      this.NewWorkOrder.get('advance_type').setValue('');
      this.NewWorkOrder.get('advance_type').updateValueAndValidity();

      this.NewWorkOrder.get('advance_type').setValidators(Validators.required);
      this.NewWorkOrder.get('advance').setValidators(Validators.required);

      this.NewWorkOrder.get('advance_type').updateValueAndValidity();
      this.NewWorkOrder.get('advance').updateValueAndValidity();
    }else{
      this.NewWorkOrder.get('advance_type').setValidators(Validators.required);
      this.NewWorkOrder.get('advance').setValidators(Validators.required);

      this.NewWorkOrder.get('advance_type').updateValueAndValidity();
      this.NewWorkOrder.get('advance').updateValueAndValidity();
    }

    this.Calculate();

  }

  AdvanceInDollar(event) {
    this.dollarActive();
    if ((event.target.value != null || event.target.value != '')
      && event.target.name == 'advance_type') {
      if (this.NewWorkOrder.value.advance == null) {
        this.NewWorkOrder.get('advance').setValidators(Validators.required);
        this.NewWorkOrder.get('advance').setValue('');
        this.NewWorkOrder.updateValueAndValidity();
      }
      this.Calculate();
    }
  }

  ValueReset(event) {
    if (event.target.name == 'discount') {
      if (this.NewWorkOrder.value.discount_type == null) {
        this.NewWorkOrder.get('discount_type').setValue('');
      }
    }

    if (event.target.name == 'advance') {
      if (this.NewWorkOrder.value.advance_type == null) {
        this.NewWorkOrder.get('advance_type').setValue('');
      }
    }
  }

  UncheckDiscoutPercent(evt: Event) {
    this.isCheckedDiscountPercent = !this.isCheckedDiscountPercent;
    this.isCheckedDiscountDollar = false;
    if (this.isCheckedDiscountPercent == false && (this.NewWorkOrder.value.discount == null || this.NewWorkOrder.value.discount == '')) {
      this.NewWorkOrder.get('discount_type').setValue('');
      this.NewWorkOrder.get('discount_type').clearValidators();
      this.NewWorkOrder.get('discount').clearValidators();

      this.NewWorkOrder.get('discount_type').updateValueAndValidity();
      this.NewWorkOrder.get('discount').updateValueAndValidity();
    } else if(this.isCheckedDiscountPercent == false && (this.NewWorkOrder.value.discount != null || this.NewWorkOrder.value.discount != '')){
      this.NewWorkOrder.get('discount_type').setValue('');
      this.NewWorkOrder.get('discount_type').updateValueAndValidity();

      this.NewWorkOrder.get('discount_type').setValidators(Validators.required);
      this.NewWorkOrder.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);

      this.NewWorkOrder.get('discount_type').updateValueAndValidity();
      this.NewWorkOrder.get('discount').updateValueAndValidity();
    }else{
      this.NewWorkOrder.get('discount_type').setValidators(Validators.required);
      this.NewWorkOrder.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);

      this.NewWorkOrder.get('discount_type').updateValueAndValidity();
      this.NewWorkOrder.get('discount').updateValueAndValidity();
    }

    this.Calculate();

  }

  UncheckDiscoutDollar(evt: Event) {
    this.isCheckedDiscountDollar = !this.isCheckedDiscountDollar;
    this.isCheckedDiscountPercent = false;
    if (this.isCheckedDiscountDollar == false && (this.NewWorkOrder.value.discount == null || this.NewWorkOrder.value.discount == '')) {
      this.NewWorkOrder.get('discount_type').setValue('');
      this.NewWorkOrder.get('discount_type').clearValidators();
      this.NewWorkOrder.get('discount').clearValidators();

      this.NewWorkOrder.get('discount_type').updateValueAndValidity();
      this.NewWorkOrder.get('discount').updateValueAndValidity();
    } else if(this.isCheckedDiscountDollar == false && (this.NewWorkOrder.value.discount != null || this.NewWorkOrder.value.discount != '')){
      this.NewWorkOrder.get('discount_type').setValue('');
      this.NewWorkOrder.get('discount_type').updateValueAndValidity();

      this.NewWorkOrder.get('discount_type').setValidators(Validators.required);
      this.NewWorkOrder.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);

      this.NewWorkOrder.get('discount_type').updateValueAndValidity();
      this.NewWorkOrder.get('discount').updateValueAndValidity();
    }else{
      this.NewWorkOrder.get('discount_type').setValidators(Validators.required);
      this.NewWorkOrder.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);

      this.NewWorkOrder.get('discount_type').updateValueAndValidity();
      this.NewWorkOrder.get('discount').updateValueAndValidity();
    }

    this.Calculate();

  }

  SalesTax(event, tax) {
    this.Calculate();
  }

  AddWorkOrder() {
    this.submitted2 = true;
    
    if ((this.NewWorkOrder.controls.due_date.invalid)
      || (this.NewWorkOrder.controls.advance.invalid)
      || (this.NewWorkOrder.controls.advance_type.invalid)
      || (this.NewWorkOrder.controls.note.invalid)
      || (this.NewWorkOrder.controls.private_note.invalid)
      || (this.NewWorkOrder.controls.assign_to.invalid)) {
      this.submitted = true;
      return;
    }

    if (this.work_order_id == null) {
      this.WorkOrderModel.customer_id = this.NewWorkOrder.value.customer_id;
      this.WorkOrderModel.items = this.WorkOrderModel.items;
      this.WorkOrderModel.discount = this.NewWorkOrder.value.discount;
      this.WorkOrderModel.discount_type = this.NewWorkOrder.value.discount_type;
      this.WorkOrderModel.sales_tax = this.NewWorkOrder.value.sales_tax;
      this.WorkOrderModel.start_date = this.NewWorkOrder.get('new_date_range').value[0];
      this.WorkOrderModel.end_date = this.NewWorkOrder.get('new_date_range').value[1];
      this.WorkOrderModel.due_date = this.NewWorkOrder.get('new_date_range').value[1];
      this.WorkOrderModel.advance = this.NewWorkOrder.value.advance;
      this.WorkOrderModel.advance_type = this.NewWorkOrder.value.advance_type;
      
      this.WorkOrderModel.note = this.NewWorkOrder.value.note;
      this.WorkOrderModel.private_note = this.NewWorkOrder.value.private_note;
      this.WorkOrderModel.checklist_id = this.NewWorkOrder.value.checklist_id;
      this.WorkOrderModel.amount = this.amount;
      this.WorkOrderModel.total = this.total_amount;
      this.WorkOrderModel.work_order_number = this.transformedData;

      if (this.multiple_image_size_validation && this.multiple_image_extension_validation && this.image_extension) {
        this.loading = true;

        this.WorkOrderService.AddWorkOrder(this.WorkOrderModel)
          .subscribe((result) => {
            if (result['success']) {
              this.itemArr= [];
              this.loading = false
              let data = result['data'];
              if (this.imagList.length > 0) {
                this.UploadImages(data.work_order_id,'Successfully Work Order Added!');
              } else {
                this.toastr.success('Successfully Work Order Added!')
                this._router.navigate(['/work-order-self/', data.work_order_id, data.work_order_number]);
              }
            }else{
              this.toastr.error('Successfully Work Order Not Added!')
            }
          }, (err:HttpErrorResponse) => {
            if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
              this.SettingService.LogOut();
            }
          }
        );
      }
      else {
        return false;
      }
    }
    else {
      // this.NewWorkOrder.controls.amount.setValue(this.WorkOrderModel.amount);
      // this.NewWorkOrder.updateValueAndValidity(); 
      this.WorkOrderModel.total = this.total_amount;
      // this.WorkOrderModel.expenses = this.NewWorkOrder.value.expenses;
      this.WorkOrderModel.due_date = this.NewWorkOrder.get('new_date_range').value[1];
      this.WorkOrderModel.start_date = this.NewWorkOrder.get('new_date_range').value[0];
      this.WorkOrderModel.end_date = this.NewWorkOrder.get('new_date_range').value[1];

      if (this.multiple_image_size_validation && this.multiple_image_extension_validation && this.image_extension) {
        this.loading = true;
        // return;
        
        this.WorkOrderService.EditWorkOrder(this.WorkOrderModel)
          .subscribe((result) => {
            if (result['success']) {

              let data = result['data'];
              if (this.imagList.length > 0) {
                this.UploadImages(this.work_order_id,'Successfully Work Order Updated');
                this.loading = true;

              } else {
                this.loading1 = false;
                this.accepted1 = false;
                this.toastr.success('Successfully Work Order Updated!')
                this._router.navigate([`/work-order-self/${this.work_order_id}/${this.work_order_number}`]);
              }
            }else{
              this.toastr.success('Successfully Work Order Updated!')
            }
          }, (err:HttpErrorResponse) => {
            if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
              this.SettingService.LogOut();
            }
          }
        )
      }
      else {
        return false;
      }
    }
  }

  // Selecting multiple files

  FilesSelected(event) {

    this.files.push(...event.addedFiles);

    if (this.files && this.files.length > 0) {
      for (var i = 0; i < this.files.length; i++) {
        this.imagList[i] = this.files[i];

        this.additional_image_name = this.imagList[i].name;

        this.additionalFileExtension = this.additional_image_name.split('.').pop();

        this.multiple_image_size = (this.imagList[i].size / 1000);

        if (this.additionalFileExtension == 'jpeg'
          || this.additionalFileExtension == 'JPEG'
          || this.additionalFileExtension == 'png'
          || this.additionalFileExtension == 'PNG'
          || this.additionalFileExtension == 'jpg'
          || this.additionalFileExtension == 'JPG') {
          if (this.multiple_image_size <= 4096) {
            this.filesSizeMessage = "";
            this.multiplefileExtensionMessage = "";
            this.multiple_image_extension_validation = true;
            this.multiple_image_size_validation = true;
          }
          else {
            this.additional_image_name = this.imagList[i].name;
            this.filesSizeMessage = "Image can be maximum upto 4MB";
            this.multiple_image_size_validation = false;
            break;
          }
        } else {
          this.additional_image_name = this.imagList[i].name;
          this.multiplefileExtensionMessage = "Only .jpg or .jpeg or .png images are allowed"
          this.multiple_image_extension_validation = false;
          break;
        }
      }


    }
  }

  // Uploading multipl files
  onSelect(event) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);

    if (this.files && this.files.length > 0) {
      for (var i = 0; i < this.files.length; i++) {
        let image_name = this.files[i].name;

        this.additionalFileExtension = image_name.split('.').pop();

        this.multiple_image_size = (this.files[i].size / 1000);
        if (this.additionalFileExtension == 'jpeg'
          || this.additionalFileExtension == 'JPEG'
          || this.additionalFileExtension == 'png'
          || this.additionalFileExtension == 'PNG'
          || this.additionalFileExtension == 'jpg'
          || this.additionalFileExtension == 'JPG') {
          if (this.multiple_image_size <= 4096) {
            this.multiple_image_extension_validation = true;
            this.multiple_image_size_validation = true;
            this.image_extension = true;

          }
          else {
            this.multiple_image_size_validation = false;
            this.additional_image_name = this.files[i].name;
            this.filesSizeMessage = "Image can be maximum upto 4MB";
            this.multiplefileExtensionMessage = "";
            this.multiple_image_extension_validation = true;
            this.image_extension = true;
            break;
          }
        }
        else {
          this.image_extension = false;

          this.additional_image_name = this.files[i].name;
          this.multiplefileExtensionMessage = "Only .jpg or .jpeg or .png images are allowed";
          this.filesSizeMessage = "";
          this.multiple_image_size_validation = true;


          break;
        }
      }

    } else {

      this.multiple_image_size_validation = true;
      this.multiple_image_extension_validation = true;
      this.image_extension = true;
      this.filesSizeMessage = "";
      this.multiplefileExtensionMessage = "";
    }


    // if (event.type !== "image/jpeg" && event.type !== "image/png") {
    //   this.multiple_image_extension_validation == true;
    // }

    // if ((event.size / 1000) >= 4096) {
    //   this.multiple_image_size_validation = true;
    // }
  }

  // Image Validation function

  FilesValidation() {
  }

  UploadImages(work_order_id,msg) {
    this.loading = true;
    for (var i = 0; i < this.files.length; i++) {
      this._formdata.append("logo[]", <string><unknown>this.files[i]);
    }

    this._formdata.append("work_order_id", work_order_id);

    this.WorkOrderService.SaveFiles(this._formdata).subscribe(
      result => {
        if (result['success']) {
          this.loading = false;
          this.toastr.success(msg)
          this._router.navigate([`/work-order-self/${work_order_id}/${this.work_order_number}`]);
        }
      }
    )
  }

  //Retrieving the images

  getWorkOrderImages(work_order_id) {
    this.WorkOrderService.GetFilesList(work_order_id).subscribe(
      result => {
        for (let index of result['data']) {
          this.work_order_images.push(index);
        }
      }
    )
  }

  //Delete the file

  onDeleteConfirm(work_order_image_id, index: number) {
    this.WorkOrderService.DeleteFile(work_order_image_id).subscribe(
      result => {
        if (result['success']) {
          this.toastr.success('File Deleted');
          this.work_order_images.splice(index, 1);

          // this.getWorkOrderImages(this.work_order_id);

          // this.work_order_images_id=[];
          // for (let index of result['data']) {
          //   this.work_order_images_id.push(index.invoice_image_id);
          // }


        } else {
          this.toastr.error('File Not Deleted');
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  //Recurring Invoice 
  OnChangeRecurringtype(event){
    this.recurring_value = event.target.value; 
  }
  OnChangingRecurringValue(event, type) {
    this.NewWorkOrder.value.recurringType = type ;
    this.NewWorkOrder.value.recurringTypeValue = event.target.value ;    
  }
  OnChangingRecurringValue2(event) {
    this.NewWorkOrder.value.recurringTypeValueTwo = event.target.value ;
  }

  getProductServiceList () {
    if(!this.productServices.length) {
      this.toastr.warning("Please add product and services first");
    }
  }

  productServiceList(){
    this._invoiceService.GetProductServiceList(null).subscribe(
      result=>{
        this.List = result['data'];

        // for(let i of list){
        //   i.group = 'Services';
        //   this.productServices.push(i);
        // }
        // for(let i of this.List){
        //   i.group = 'PRODUCTS';
        //   this.productServices.push(i);
        // }
        this.productServices = [
          ...this.List[0].map(
            (item: Object) => ({
              group: 'Service',
              name: item['name'],
              id: item['service_id'],
            })
          ),
          ...this.List[1].map(
            (item: Object) => ({
              group: 'Product',
              name: item['name'],
              id: item['item_id'],
            })
          )
        ]
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  getUserList() {
    this.loading = true;
    this.UserService.UserList()
      .subscribe((result) => {
        if (result['success']) {
          this.loading = false;
          this.userlist = result['data'];
        }
        else {
          this.loading = false;
          
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  getProductFromProductPage() {
    
    let data = JSON.parse(localStorage.getItem('product'));

    this.NewWorkOrder.get('line_item').setValue(data.id);
    this.NewWorkOrder.get('line_item').updateValueAndValidity();
    this.ViewProductOrSeviceData(data);
  }

  showSuccess(msg){
    this.toastr.success(msg);
  }

  showError(msg){
    this.toastr.error(msg);
  }
  
  SetEndDateOptions(event){
    if(event!=null){
      this.minDate_for_end_date = new Date();
      this.minDate_for_end_date.setDate(event.getDate());
    }
  }

  addProductservice(){
    this.modalRef = this.modalService.show(AddProductAndServiceComponent, {
      class: 'modal-lg'
    })
  }

  taxList: object[] = [];
  getLocationList(search){
    const data = {
      search: search.target.value
    }
    this.taxService.getLocationList(data).subscribe((response) => {
      this.taxList = response['data'];
      if (!this.taxList) {
        this.toastr.warning("Please add tax first");
      }
    })
  }
  
  setTaxRate(event){
    this.NewWorkOrder.get('sales_tax').setValue(event);
    this.NewWorkOrder.updateValueAndValidity();
    this.Calculate()
  }

  getProductServiceModalData(){
    const productDataSubscription = this._invoiceService.product_response_data.subscribe(
      (result) => {
        if(result){
          this._invoiceService.setProductData = null;
          if(result['group']=='Product'){
            let quantity_for_invoice = localStorage.getItem('quantity_for_invoice');
            this.NewWorkOrder.get('line_item').setValue(result['item_id']);
            this.NewWorkOrder.get('quantity').setValue(result['quantity_for_invoice']);
            this.NewWorkOrder.get('unit_price').setValue(result['rate']);
            this.current_selected_item ={
              group : result['group'],
              id : result['item_id'],
              name: result['name']
            }
            this.current_selected_item.group = result['group'];
  
            this.NewWorkOrder.updateValueAndValidity();
            let item={
              'id': result['item_id'],
              'group': result['group'],
            }
            this.ViewProductOrSeviceData(item);
            setTimeout(()=>{
              this.AddItem();
            },3000)
          }
        }
      }
    );

    this.subscriptions.push(productDataSubscription);
    
  }

  getServiceModalData() {
    const serviceDataSubscription = this._invoiceService.service_response_data.subscribe(
      (result) => {
        if (result) {
          this._invoiceService.setServiceData = null;
          if(result['group']=='Service'){
            this.NewWorkOrder.get('line_item').setValue(result['service_id']);
            this.NewWorkOrder.get('quantity').setValue(1);
            this.NewWorkOrder.get('unit_price').setValue(result['rate']);
            this.current_selected_item = {
              group: result['group'],
              id: result['service_id'],
              name: result['name']
            }
            this.current_selected_item.group = result['group'];
  
            this.NewWorkOrder.updateValueAndValidity();
            let item={
              'id': result['service_id'],
              'group': result['group'],
            }
            this.ViewProductOrSeviceData(item);
            setTimeout(()=>{
              this.AddItem();
            },3000)
          }
        }
      }
    );

    this.subscriptions.push(serviceDataSubscription);
  }
  // Up & Down Arrow Function

  arrowDownBtn(index : number){
    const copy1 = [...this.itemArr];
    const moveTo1 = copy1.splice(index, 1)[0];
    copy1.splice(index +1, 0, moveTo1);
    this.itemArr = copy1;

    const copy2 = [...this.WorkOrderModel.items];
    const moveTo2 = copy2.splice(index, 1)[0];
    copy2.splice(index +1, 0, moveTo2);
    this.WorkOrderModel.items = copy2;
  }


  arrowUpBtn(index : number){
    const copy1 = [...this.itemArr];
    const moveTo1 = copy1.splice(index, 1)[0];
    copy1.splice(index - 1, 0, moveTo1);
    this.itemArr = copy1;
  
    const copy2 = [...this.WorkOrderModel.items];
    const moveTo2 = copy2.splice(index, 1)[0];
    copy2.splice(index - 1, 0, moveTo2);
    this.WorkOrderModel.items = copy2;
  }

  public isChecklistNull: boolean = false;

  getCheckListItem(){
    this.AddChecklistService.GetCheckList().subscribe(
      (result)=>{
        if(result['data'].length) {
          this.isChecklistNull = false;
        }else {
          this.isChecklistNull = true;
        }
        this.checklistItem = result["data"];
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    )
  }

  getCheckLists(){
    if(this.isChecklistNull) {
      this.toastr.warning("Please add checklist first");
    }
  }
}
