import { Component, OnInit } from '@angular/core';
import { Sidebar } from 'src/app/shared';

@Component({
  selector: 'recurring-invoice',
  templateUrl: './recurring-invoice.component.html',
  styleUrls: ['./recurring-invoice.component.scss']
})
export class RecurringInvoiceComponent implements OnInit {

  public recurring_value: boolean = false;
  public recurring_check: boolean = false;

  type_of_recurrings: string[] = [
    'Week',
    'Biweekly',
    'Every Month',
    'Quarterly',
    'Yearly'
  ];
  week_day: string[] = [
    'Sunday',
    'Monday',
    'Tuesday ',
    'Wednesday ',
    'Thursday ',
    'Friday ',
    'Saturday ',
  ];
  constructor(
    public _sidebar: Sidebar,
  ) { }

  ngOnInit() {
  }

  recurring_toggle() {
    this.recurring_check = !this.recurring_check;
  }

}
