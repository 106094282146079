import { Component, OnInit } from '@angular/core';
import { Sidebar, PaymentSettings } from 'src/app/shared';
import { FormBuilder, Validators } from '@angular/forms';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { timer } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: "app-payment-method",
  templateUrl: "./payment-method.component.html",
  styleUrls: ["./payment-method.component.scss"],
})
export class PaymentMethodComponent implements OnInit {
  
  public submitted:boolean=false;
  public submitted2:boolean=false;
  public submitted3:boolean=false;
  public submitted4:boolean=false;
  public organization_id:string = null;
  public payment_id:string = null;
  public loading: boolean = false;
  public accepted: boolean = false;
  public loading2: boolean = false;
  public accepted2: boolean = false;
  public loading3: boolean = false;
  public accepted3: boolean = false;
  constructor(
    public _sidebar: Sidebar,
    public fb: FormBuilder,
    public SettingService: SettingService,
    public toastr: ToastrService,
    public _Auth: AuthService
  ) {
    (err:HttpErrorResponse) => {
      if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
        this.SettingService.LogOut();
      }
   }
    let UserData = this._Auth.User();
    this.organization_id = UserData['user'].organization_id;
    this.getPaymentSetting(this.organization_id);
  }

  public Payment = this.fb.group({
    key: ['', Validators.required],
    secret: ['', Validators.required]
  })

  public SquarePayment = this.fb.group({
    application_id: ['', Validators.required],
    location_id: ['', Validators.required],
    access_token: ['', Validators.required],
  })

  public AuthorizePayment = this.fb.group({
    public_client_id: ['', Validators.required],
    transaction_key: ['', Validators.required],
    login_id: ['', Validators.required]

  })

  public BankDetailsForm = this.fb.group({
    bank_details: ['', Validators.required],
    checked: [0]
  })
  get f() {
    return this.Payment.controls;
  }

  get f2() {
    return this.SquarePayment.controls;
  }

  get f3() {
    return this.AuthorizePayment.controls;
  }

  get f4() {
    return this.BankDetailsForm.controls;
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  public PaymentModel: PaymentSettings = new PaymentSettings()

  getPaymentSetting(organization_id) {
    if (organization_id != null) {
      this.loading = true;
      this.SettingService.GetPaymentDetails(organization_id)
        .subscribe((result) => {
          this.loading = false;
          if(result['data']){
            this.PaymentModel.payment_setting_id = result['data']['payment_settings_id'];
            this.payment_id = result['data']['payment_settings_id'];
            this.PaymentModel.key = result['data']['key'];
            this.PaymentModel.secret = result['data']['secret'];
            this.PaymentModel.application_id = result['data']['application_id'];
            this.PaymentModel.location_id = result['data']['location_id'];
            this.PaymentModel.access_token = result['data']['access_token'];
            this.PaymentModel.public_client_id = result['data']['public_client_id'];
            this.PaymentModel.login_id = result['data']['login_id'];
            this.PaymentModel.transaction_key = result['data']['transaction_key'];
            this.PaymentModel.others = result['data']['others'];
            
          }
          if(result['bank_details']){
            this.PaymentModel.bank_details = result['bank_details']['bank_details'];
            this.PaymentModel.checked = result['bank_details']['checked'];
            this.PaymentModel.bank_detail_id = result['bank_details']['bank_detail_id'];
          }
          
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
    else {
      this.loading = false;
    }
  }

  AddPayment(){
    this.loading = true;
    this.accepted = false;
    this.submitted=true;
    if(this.Payment.invalid){
      this.loading = false;
      this.accepted = false;
      return;
    }
    if (this.payment_id == null) {
      this.SettingService.Payment(this.Payment.value).subscribe(
        result=>{
          if(result['success']){
            this.loading = false;
            this.accepted = false;
            this.showSuccess(result['msg'])
          }else{
            this.loading = false;
            this.accepted = false;
            this.showDanger(result['msg']);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    } else {
      this.SettingService.PaymentEdit(this.PaymentModel).subscribe(
        result=>{
          if(result['success']){
            this.loading = false;
            this.accepted = false;
            this.showSuccess(result['msg'])
          }else{
            this.loading = false;
            this.accepted = false;
            this.showDanger(result['msg']);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }
  

  AddSquarePayment(){
    this.loading2 = true;
    this.accepted2 = false;
    this.submitted2=true;
    if(this.SquarePayment.invalid){
      this.loading2 = false;
      this.accepted2 = false;
      return;
    }
    if (this.payment_id == null) {
      this.SettingService.Payment(this.SquarePayment.value).subscribe(
        result=>{
          if(result['success']){
            this.loading2 = false;
            this.accepted2 = false;
            this.showSuccess(result['msg'])
          }else{
            this.loading2 = false;
            this.accepted2 = false;
            this.showDanger(result['msg']);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    } else {
      this.SettingService.PaymentEdit(this.PaymentModel).subscribe(
        result=>{
          if(result['success']){
            this.loading2 = false;
            this.accepted2 = false;
            this.showSuccess(result['msg'])
          }else{
            this.loading2 = false;
            this.accepted2 = false;
            this.showDanger(result['msg']);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

  AddAuthorizePayment(){
    this.loading3 = true;
    this.accepted3 = false;
    this.submitted3=true;
    if(this.AuthorizePayment.invalid){
      this.loading3 = false;
      this.accepted3 = false;
      return;
    }
    if(this.payment_id == null) {
      this.SettingService.Payment(this.AuthorizePayment.value).subscribe(
        result=>{
          if(result['success']){
            this.loading3 = false;
            this.accepted3 = false;
            this.showSuccess(result['msg'])
          }else{
            this.loading3 = false;
            this.accepted3 = false;
            this.showDanger(result['msg']);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    } else {
      this.SettingService.PaymentEdit(this.PaymentModel).subscribe(
        result=>{
          if(result['success']){
            this.loading3 = false;
            this.accepted3 = false;
            this.showSuccess(result['msg'])
          }else{
            this.loading3 = false;
            this.accepted3 = false;
            this.showDanger(result['msg']);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

  showSuccess(msg: string) {
    this.toastr.success(msg);
  }

  showDanger(msg: string) {
    this.toastr.error(msg);
  }

  AddBankDetails(){
    this.submitted4 = true;
    if(this.BankDetailsForm.invalid){
      return;
    }
    if(this.PaymentModel.bank_detail_id == null){
      this.SettingService.AddBankDetails(this.BankDetailsForm.value).subscribe(response => {
        if(response['success']){
          this.toastr.success(response['msg'])
        }else{
          this.toastr.success(response['msg'])
        }
      })
    }else {
      this.SettingService.UpdateBankDetails(this.PaymentModel).subscribe(response => {
         if(response['success']){
          this.toastr.success(response['msg'])
        }else{
          this.toastr.success(response['msg'])
        }     
      })
    }
  }
}
