import { Component, OnInit } from '@angular/core';
import { Sidebar, UserModel } from 'src/app/shared';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { timer } from 'rxjs'
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  
  public submitted:boolean=false;
  public user_id:string = null;
  public loading: boolean = false;
  public accepted: boolean = false;

  public UserModel:UserModel=new UserModel();
  public User:FormGroup;

  constructor(
    public _sidebar: Sidebar,
    public fb: FormBuilder,
    public UserService: UserService,
    public toastr: ToastrService,
    public router: Router,
    public _activatedRoute: ActivatedRoute,
    public SettingService: SettingService
  ) {
    this.user_id = this._activatedRoute.snapshot.paramMap.get('user_id');
    if (this.user_id == null) {
      this.User = this.fb.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.pattern(/^[^*-._'%=+!`#~$*?^{}&@]+([\*.]?\w+)*[^*-_'%=+!`#~$*?^{}&@]*@\w+([\.]?\w+)*(\.\w{2,10})+$/)]],
        password: ['', [Validators.required, Validators.minLength(8),]],
        confirm_password: ['', [Validators.required, Validators.minLength(8),]],
        status: ['', Validators.required],
      }, {
        validator: this.mustMatch('password', 'confirm_password')
      });

    } else {
      this.User = this.fb.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.pattern(/^[^*-._'%=+!`#~$*?^{}&@]+([\*.]?\w+)*[^*-_'%=+!`#~$*?^{}&@]*@\w+([\.]?\w+)*(\.\w{2,10})+$/)]],
        status: ['', Validators.required],
      });
    }
    this.getUserDetails(this.user_id);
  }


  ngOnInit() {
    window.scroll(0, 0);
  }

  get f() {
    return this.User.controls;
  }

  mustMatch(password: string, confirm_password: string) {
    return (User: FormGroup) => {
      const control = User.controls[password];
      const matchingControl = User.controls[confirm_password];

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  getUserDetails(user_id) {
    if (user_id != null) {
      this.loading = true;

      this.UserService.getUser(user_id)
        .subscribe((result) => {
          this.loading = false;

          this.UserModel.user_id = result['data']['user_id'];
          this.UserModel.name = result['data']['name'];
          this.UserModel.email = result['data']['email'];
          this.UserModel.status = result['data']['status'];
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }


  AddUser(){
    this.loading = true;
    this.accepted = false;
    this.submitted=true;
    if(this.User.invalid){
      this.loading = false;
      this.accepted = false;
      return;
    }
    if (this.user_id == null) {
      this.UserService.AddUser(this.User.value).subscribe(
        result=>{
          if(result['success']){
            this.loading = false;
            this.accepted = false;
            
            this.showSuccess(result['msg']);
            this.router.navigate(['/business-profile']);
  
          }else{
            this.loading = false;
            this.accepted = false;
            this.showDanger(result['error'].email[0]);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    } else {
      this.UserService.UpdateUser(this.UserModel).subscribe(
        result=>{
          if(result['success']){
            this.loading = false;
            this.accepted = false;
            this.showSuccess(result['msg']);
            this.router.navigate(['/business-profile']);
  
          }else{
            this.loading = false;
            this.accepted = false;
            this.showDanger(result['error'].email[0]);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }


  }

  showSuccess(msg: string) {
    this.toastr.success(msg);
  }

  showDanger(msg: string) {
    this.toastr.error(msg);
  }
}
