import { Component, OnInit } from '@angular/core';
import { Sidebar } from 'src/app/shared';
import { ActivatedRoute } from '@angular/router';
import { ServiceAgreementService } from 'src/app/shared/services/service-agreement/service-agreement.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'service-agreements-templates-one',
  templateUrl: './service-agreements-templates-one.component.html',
  styleUrls: ['./service-agreements-templates-one.component.scss']
})

export class ServiceAgreementsTemplatesOneComponent implements OnInit {

  public payment_status: any = null;
  public date: Date = null;
  public invoice_id: string = null;
  public template_name: any = null;
  public content: any = null;
  public  userData: any = null;

  constructor(
    public _sidebar: Sidebar,
    public activateRoute: ActivatedRoute,
    public serviceAgreementService: ServiceAgreementService,
    public SettingService: SettingService,
    public auth: AuthService
    ) {
      this.template_name = this.activateRoute.snapshot.paramMap.get('template_name');
      this.getTemplateView();

  }
  ngOnInit() {
    window.scroll(0, 0);
  }

  getTemplateView() {
    let data = {
      template_name: this.template_name,
      user_id: this.auth.User().user.user_id,
      organization_id: this.auth.User().user.organization_id
    }
    this.serviceAgreementService.viewTemplate(data).subscribe(
      result => {
        this.content = result['data'].content;
      },(err:HttpErrorResponse)=>{
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }


}
