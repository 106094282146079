import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EstimateModel } from '../../models/estimate/estimate.model';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderService {
  private estimate_data: BehaviorSubject<Object> = new BehaviorSubject(null);
  private invoice_data: BehaviorSubject<Object> = new BehaviorSubject(null);

  constructor(
    public _http:HttpClient,
    public _TokenService:TokenService
  ) { }

  AddWorkOrder(data):Observable<[]> {
    let URL = `${environment.url}workorder/create`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  EditWorkOrder(data):Observable<[]> {
    let URL = `${environment.url}workorder/edit/`+data.work_order_id;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetWorkOrderList():Observable<[]> {
    let URL = `${environment.url}workorder/list`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetWorkOrderListByCustomer(customer_id):Observable<[]> {
    let URL = `${environment.url}workorder/view-by-customer/${customer_id}`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetWorkOrderDetail(work_order_id):Observable<[]> {
    let URL = `${environment.url}workorder/view/`+work_order_id;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  DeleteWorkOrder(work_order_id):Observable<[]> {
    let URL = `${environment.url}workorder/delete/`+work_order_id;

    return this._http.delete(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  SaveFiles(_formdata):Observable<[]> {
    let URL = `${environment.url}workorder_image/create`;

    return this._http.post(URL, _formdata, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  GetFilesList(work_order_id):Observable<[]> {
    let URL = `${environment.url}workorder_image/list/${work_order_id}`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }
  DeleteFile(work_order_image_id):Observable<[]> {
    let URL = `${environment.url}workorder_image/delete/${work_order_image_id}`;

    return this._http.delete(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  WorkOrderSearch(data):Observable<[]> {
    let URL = `${environment.url}workorder/search`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }
  ApproveInvoice(invoice) {
    this.invoice_data.next(invoice);
  }

  GetApprovedInvoiceData(){
    return this.invoice_data.asObservable();

  }
  

}
