import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AddChecklistService } from 'src/app/shared/services/checklist/add-checklist.service';


@Component({
  selector: 'app-checklist-self',
  templateUrl: './checklist-self.component.html',
  styleUrls: ['./checklist-self.component.scss']
})
export class ChecklistSelfComponent implements OnInit {
  public loading: boolean = false;
  public template: any = null;
  public modalRef: BsModalRef;
  public checklist_id: string = null;
  public checklistDetails: any[] = [];
  

  constructor(
    public _modalService: BsModalService,
    public activateRoute: ActivatedRoute,
    public toastr: ToastrService,
    public router: Router,
    public SettingService: SettingService,
    public AddCheckListService : AddChecklistService
  ) { 
    this.checklist_id = this.activateRoute.snapshot.paramMap.get('checklist_id');
    this.getChecklistDetails(this.checklist_id);
  }

  ngOnInit() {
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  getChecklistDetails(checklist_id){
    this.loading = true; 
    this.AddCheckListService.GetChecklistId(checklist_id).subscribe(
      (result) => {
        this.loading = false;
        if (result['success']) {
          this.loading = false; 
          this.checklistDetails = result["data"];
        }
      }, (err:HttpErrorResponse) => {
        this.loading = false; 
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  public isCheckListdlt: boolean = false;
  DeleteChecklist(checklist_id) {
    this.loading = true; 
    if (checklist_id != null) {
      this.isCheckListdlt = true;
      this.AddCheckListService.DeleteChecklist(checklist_id)
        .subscribe((result) => {
          this.isCheckListdlt = false;

          if (result['success']) {
            this.loading = false; 
            this.modalRef.hide();
            this.toastr.success(result['msg']);
            this.router.navigate(['/checklist']);
          }else {
            this.loading = false; 
            this.toastr.info(result['msg']);
          }
        }, (err:HttpErrorResponse) => {
          this.loading = false; 
          this.isCheckListdlt = false;

          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

}
