import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Sidebar } from 'src/app/shared';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';


@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss']
})
export class TimesheetComponent implements OnInit {
  public contents: any[] = [];
  private _url: string = "";
  public timeSheetData: object[] = [];
  Userdata: object = null;


  constructor(
    private http: HttpClient,
    public _sidebar: Sidebar,
    public customerService: CustomerService
  ) { }

  ngOnInit() {
    this.callStorage();
    this.timeSheet(this.Userdata['user'].user_id)
  }

  callStorage() {
    this.Userdata = JSON.parse(localStorage.getItem("user_data"));
  }

  timeSheet(assign_to) {
    const data = {
      assign_to
    }
    this.customerService.timesheetDetails().subscribe((response) => {
      this.timeSheetData = response['data']['data'];
    })
  }
}

