import { Component, OnInit } from '@angular/core';
import { Sidebar } from 'src/app/shared';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Router } from '@angular/router';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AddOnService } from 'src/app/shared/services/add-on/add-on.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public enabled_modules: any[] = [];
  public price_book_enabled: boolean = false;
  public service_agreement_enabled: boolean = false;
  public scheduler_enabled: boolean = false;
  public checklist_enabled: boolean = false;
  public env = environment;
  public orgId: string = null;
  public base_url: string = null;
  public user_data: any;
  constructor(
    public _sidebar: Sidebar,
    public _Auth:AuthService,
    public router:Router,
    public SettingService:SettingService,
    public AddOnService: AddOnService) { 
      if(JSON.parse(localStorage.getItem('activated_modules'))){
        this.enabled_modules = JSON.parse(localStorage.getItem('activated_modules'));
        
        for(let index of this.enabled_modules){
          if(index['module_id']=='606f1bbbad186' && index['enabled']){
            this.price_book_enabled = true;
          }
          if(index['module_id']=='5ece4797eaf5e' && index['enabled']){
            this.service_agreement_enabled = true;
          }
          if(index['module_id']=='544e74cc30175' && index['enabled']){
            this.scheduler_enabled = true;
          }
          if(index['module_id']=='50bf5d16f34b' && index['enabled'] == 1){
            this.checklist_enabled = true;
          }
        }
      }
      // this.getActivatedAddOnList();
      localStorage.removeItem("pricebook");
      const userDetails = JSON.parse(localStorage.getItem('user_data'));
      this.orgId = userDetails.user.organization_id;
      this.base_url = (this.env.url).slice(0,-4);
      this.user_data = this._Auth.User().user.super_admin;
    }

  ngOnInit() {
    this.getWidgetItemtList();
    window.scroll(0, 0);
  }

  LogOut(){
    localStorage.removeItem('user_data');
    localStorage.removeItem('payment_data');
    localStorage.removeItem('recurring_type');
    localStorage.removeItem('activated_modules');
    localStorage.removeItem('product');
    localStorage.removeItem('widget_service_id');
    localStorage.removeItem('account_details');
    localStorage.removeItem('estimate_checklist_id');
    this.router.navigate(['/']);
  }

  getActivatedAddOnList(){
    this.AddOnService.GetActivatedAddOnList().subscribe(
      result=>{
        this.enabled_modules = result['data'];        
        for(let index of this.enabled_modules){          
          if(index['module_id']=='606f1bbbad186' && index['enabled']==1){
            this.price_book_enabled = true;            
          }
        }
      }
    )
  }

  getWidgetItemtList(){
    this.SettingService.GetItemWidgetList().subscribe()
  }
}
