import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token/token.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddChecklistService {

  constructor(
    private _http : HttpClient,
    private _TokenService : TokenService
  ) { }

  AddCheckList(_formdata:any){
    let URL = `${environment.url}checklist`;

    return this._http.post(URL, _formdata, {
      headers: this._TokenService.GetTokenByHeader()
    })
    .pipe(map((res: any) => {
      return res
    }));
  }

  GetCheckList(){
    let URL = `${environment.url}checklist`;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => {
      return res
    }));
  }

  UpdateChecklist (checklist_id, data:any){
    let URL =  `${environment.url}checklist/${checklist_id}`;

    return this._http.put(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => {
      return res
    }));
  }

  GetChecklistId(checklist_id){
    let URL = environment.url+ 'checklist/'+checklist_id;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => {
      return res
    }));
  }

  DeleteChecklist(checklist_id){
    let URL = `${environment.url}checklist/${checklist_id}`;

    return this._http.delete(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => {
      return res
    }));
  }

  changeStatus(_formdata:any){
    let URL = `${environment.url}checklist-status`;

    return this._http.post(URL, _formdata, {
      headers: this._TokenService.GetTokenByHeader()
    })
    .pipe(map((res: any) => {
      return res
    }));
  }

  getStatus(ids) {
    let URL = environment.url+ 'checklist-status/'+ids['section_id']+'&'+ids['checklist_id'];

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => {
      return res
    }));
  }

  GetChecklistBySectionId(section_id){
    let URL = environment.url+ 'checklist/checklist-item/'+section_id;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => {
      return res
    }));
  }
}
