import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceModuleService {
  public serviceSuccess: BehaviorSubject<string | number | boolean> = new BehaviorSubject(null);
  public servicMoreSuccess: BehaviorSubject<string | number | boolean> = new BehaviorSubject(null);

  constructor(
    public _http:HttpClient,
    public _TokenService:TokenService


  ) { }

  set setServiceSuccess(success: any){
    this.serviceSuccess.next(success)
  }
  set setMoreServiceSuccess(success: any){
    this.servicMoreSuccess.next(success)
  }



  AddService(data):Observable<[]> {
    let URL = `${environment.url}service`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  UpdateService(data):Observable<[]> {
    let URL = environment.url+'service/'+ data.service_id;

    return this._http.put(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetServiceList():Observable<[]> {
    let URL = `${environment.url}service`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetServiceById(service_id):Observable<[]> {
    let URL = environment.url+'service/'+service_id;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  DeleteService(service_id):Observable<[]> {
    let URL = environment.url+'service/'+service_id;

    return this._http.delete(URL,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  ServiceSearch(data):Observable<[]> {
    let URL = environment.url+'service/search';

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  exportCSV(data):Observable<[]> {
    data.start_date = new Date(data.start_date.getTime() - data.start_date.getTimezoneOffset() * 60000);
    data.end_date = new Date(data.end_date.getTime() - data.end_date.getTimezoneOffset() * 60000);
    
    let URL = environment.url+'quickbook/services';
    
    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),
      // responseType:ResponseContentType.Blob
    })
      .pipe(map((res: any) => res));
  }

  showServiceInWidget(data):Observable<[]> {
    let URL = environment.url+'service/add-widget';

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }
}
