import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  private _rules:any;
  private _values: any;

  constructor(rules, values) { 
    this._rules = rules;
    this._values = values;
  }

  public validate() {
    let results = {
      success: true,
      messages: {},
    };

    for (let field_name in this._rules) { 
      let conditions = this._rules[field_name];

      for(let index in conditions) {
        if(conditions[index] == "required") {
          if(this._values[field_name] == null || this._values[field_name] == "") {
            results['success'] = false;
            results['messages'][field_name] = this._field_name(field_name) + " is required.";
            break;
          }
        }
        else if(conditions[index] == "email" && !this._validate_email(this._values[field_name])) {
          results['success'] = false;
          results['messages'][field_name] = this._field_name(field_name) + " is invalid.";
          break;
        }
      }
    }

    return results;
  }

  private _field_name(value) {
    let _values = value.split("_");
    let name = this._title_case_word(_values[0]);

    for(let i = 1; i < _values.length; i++) {
      name += " " + this._title_case_word(_values[i]);
    }
    
    return name;
  }

  private _title_case_word(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  private  _validate_email(mail: string = null) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(mail != null && mail.match(mailformat)) {
      return true;
    }
    else {
      return false
    }
  }
}
