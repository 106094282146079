import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemService } from 'src/app/shared/services/item/item.service';
import { ExpenseService } from 'src/app/shared/services/expense/expense.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ExpenseModel } from 'src/app/shared';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-expenses-self',
  templateUrl: './expenses-self.component.html',
  styleUrls: ['./expenses-self.component.scss']
})
export class ExpensesSelfComponent implements OnInit {

  public expense_id: string = null;
  public modalRef: BsModalRef;

  constructor(
    public activateRoute: ActivatedRoute,
    public ExpenseService: ExpenseService,
    public _modalService: BsModalService,
    public toastr: ToastrService,
    public router: Router,
    public SettingService: SettingService
  ) {
    this.expense_id = this.activateRoute.snapshot.paramMap.get('expense_id');
    this.getExpenseDetails(this.expense_id);
  }

  public ExpenseDetails:ExpenseModel=new ExpenseModel();

  ngOnInit() {
    window.scroll(0, 0);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  getExpenseDetails(expense_id) {
    this.ExpenseService.GetExpenseById(expense_id).subscribe(
      result => {
        if (result['success']) {
          this.ExpenseDetails.date = result['data'].date;
          this.ExpenseDetails.ist_date = result['data'].ist_date;
          this.ExpenseDetails.type = result['data'].type;
          this.ExpenseDetails.tip = result['data'].tip;
          this.ExpenseDetails.total = result['data'].total;
          this.ExpenseDetails.merchant = result['data'].merchant;
          this.ExpenseDetails.note = result['data'].note;
          this.ExpenseDetails.expense_number = result['data'].expense_number;
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  DeleteExpense(expenses_id) {
    if (this.expense_id != null) {
      this.ExpenseService.DeleteExpense(this.expense_id)
      .subscribe((result) => {
        if (result['success']) {
          this.modalRef.hide();
          this.toastr.success('Deleted Successfully.');
          this.router.navigate(["/expenses"]);
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
      )
    }
  }
}
