import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Schedule, Sidebar } from "src/app/shared";
import { ScheduleService } from "src/app/shared/services/schedule/schedule.service";
import { SettingService } from "src/app/shared/services/setting/setting.service";
import { UserService } from "src/app/shared/services/user/user.service";
import { EstimateService } from "src/app/shared/services/estimate/estimate.service";

@Component({
  selector: "app-schedule-detail",
  templateUrl: "./schedule-detail.component.html",
  styleUrls: ["./schedule-detail.component.scss"],
})
export class ScheduleDetailComponent implements OnInit {
  public items: any[] = [];
  public workStatus:any = null;
  public widget_service_id: string = null;
  public estimate_id: string = null;
  public submitted = false;
  public userlist: any[] = [];
  public acceptBtnDisable = false;
  public estimate_number: number = null;
  private transformedData: any = null;
  public net_amount: any = 0;
  public accepted: boolean = false;
  bsModalRef: BsModalRef;
  public loading: boolean = true;
  public scheduleDetailsForm = this.fb.group({
    customerName: ["", Validators.required],
    customerEmail: [""],
    customerNumber: [""],
    date: ["", Validators.required],
    // requestList: ["", Validators.required],
    status: ["",],
    address: [""],
    notes: [""],
    customer_notes: [""],
    assign_to: ["", Validators.required],
    time: [
      "",
      [
        Validators.required,
        Validators.pattern("^([1-9]|0[1-9]|1[0-2]):[0-5][0-9] ([AaPp][Mm])$"),
      ],
    ],
  });
  constructor(
    public _sidebar: Sidebar,
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    public scheduleService: ScheduleService,
    public UserService: UserService,
    public SettingService: SettingService,
    public toastr: ToastrService,
    public router: Router,
    public bsModalService: BsModalService,
    public EstimateService: EstimateService
  ) {
    this.widget_service_id =  this.activatedRoute.snapshot.params.widget_service_id;
    this.scheduleDetails(this.widget_service_id);
    this.getUserList();
    
  }
  get f() {
    return this.scheduleDetailsForm.controls;
  }
  ngOnInit() {
    window.scroll(0, 0);
    this.getEstimateList();
    
  }

  public scheduleModel: Schedule = new Schedule();
  public scheduleCustomerInfo: object = null;
  public scheduleData: any;
  productList;
  serviceList;

  scheduleDetails(widgetServiceId) {
    this.loading = true;
    this.scheduleService.scheduleDetails(widgetServiceId).subscribe(
      (result) => {
        this.scheduleData = result['data'];
        this.scheduleCustomerInfo = result['data']['widgetservice_customer'];
        this.scheduleModel.name = result["data"].widgetservice_customer.name;
        this.scheduleModel.email = result["data"].widgetservice_customer.email;
        this.scheduleModel.mobile = result["data"].widgetservice_customer.mobile;
        this.scheduleModel.widget_service_id = result["data"].widget_service_id;
        this.scheduleModel.date = result["data"].date;
        // this.scheduleModel.status = result["data"].status;
        this.workStatus = result["data"].status;
        this.scheduleModel.note = result["data"].note;
        this.scheduleModel.customer_note = result["data"].widgetservice_customer.customer_note;
        this.scheduleModel.assign_to = result["data"].assign_to;
        this.scheduleModel.estimate_id = result["data"].estimate_id;
        this.scheduleModel.time = result["data"].time;
        this.scheduleModel.address = result["data"].widgetservice_customer_address.address;
        this.scheduleModel.accepted = result["data"].accepted;
        this.scheduleModel.total = result["data"].total;
        // this.scheduleModel.requestList = result["data"].widgetservice_widgetserviceitems;
        this.productList = result["data"].widgetservice_widgetserviceitems;
        this.loading = false;
        if(this.scheduleModel.accepted == true && this.scheduleModel.estimate_id != null){
          this.acceptBtnDisable = true;
        } else if(this.scheduleModel.accepted == true) {
          this.acceptBtnDisable = true;
        }
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.loading = false;
          this.SettingService.LogOut();
        }
      }
    );
  }
  getUserList() {
    this.loading = true;
    this.UserService.UserList().subscribe(
      (result) => {
        if (result["success"]) {
          this.userlist = result["data"];
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    );
  }

  scheduleUpdate() {
    this.submitted = true;
    if (this.scheduleDetailsForm.invalid) {
      return;
    }
    this.loading = true;
    this.scheduleModel.date = new Date(this.scheduleModel.date);
    // this.scheduleModel.estimate_id = this.estimate_id;
    this.scheduleService
      .updateScheduleDetails(this.scheduleModel)
      .subscribe((result) => {
        this.loading = false;
        if (result["success"]) {
          this.loading = false;
          this.toastr.success(result["msg"]);
          // this.router.navigate(["/schedule"]);
        } else {
          this.loading = false;
          this.toastr.error(result["msg"]);
        }
      });
  }

  scheduleDetailsUpdate() {
    this.submitted = true;
    if (this.scheduleDetailsForm.invalid) {
      return;
    }
    this.loading = true;
    this.scheduleModel.date = new Date(this.scheduleModel.date);
    // this.scheduleModel.estimate_id = this.estimate_id;
    this.scheduleService
      .updateScheduleDetails(this.scheduleModel)
      .subscribe((result) => {
        this.loading = false;
        if (result["success"]) {
          this.loading = false;
          this.toastr.success(result["msg"]);
          this.router.navigate(["/schedule"]);
        } else {
          this.loading = false;
          this.toastr.error(result["msg"]);
        }
      });
  }

  createEstimate(){
    this.loading = true;
    this.productList = this.productList.map((item, index) => {
      let data: Object= null;
      if(item['group'] == 'Service'){
        // this.productList.push(value)
        data={
          group: item['group'],
          item_id: item['item_id'],
          qty: 1,
          tax: Number(item['widgetserviceitems_services']['tax_rate']),
          unit_price: Number(item['widgetserviceitems_services']['rate'])
        }
      }else if(item['group'] == 'Product'){
        data={
          group: item['group'],
          item_id: item['item_id'],
          qty: 1,
          tax: Number(item['widgetserviceitems_items']['tax_rate']),
          unit_price: Number(item['widgetserviceitems_items']['rate'])
        }
      }
      return data;
      
    });
    let estimateData = {
      customer_id: this.scheduleCustomerInfo['customer_id'],
      due_date: new Date(this.scheduleDetailsForm.get('date').value),
      note: this.scheduleDetailsForm.get('customer_notes').value,
      items: this.productList,
      work_order_update: false,
      estimate_number: this.transformedData,
      gross_amount: this.scheduleModel.total,
      total: (this.scheduleModel.total).toFixed(2)
    }
    this.EstimateService.AddEstimate(estimateData).subscribe((result) => {
      if(result["success"]){
        this.loading = false;
        this.showCreateSuccess(result['msg']);
        if(result["data"].estimate_id){
          this.scheduleModel.estimate_id = result["data"].estimate_id;
        }
        this.scheduleDetailsUpdate();
      } else {
        this.loading = false;
        this.showCreateDanger(result['msg']);
      }
    })
  }

  acceptRequest(){
    this.submitted = true;
    if (this.scheduleDetailsForm.invalid) {
      return;
    }
    this.loading = true;
    const data = {
      widget_service_id: this.widget_service_id,
      // assign_to: this.scheduleDetailsForm.value.assign_to,
      assign_to: this.scheduleDetailsForm.get('assign_to').value,
      date: this.scheduleDetailsForm.value.date,
      time: this.scheduleDetailsForm.value.time
    }
    
    this.scheduleService.AcceptSchedule(data).subscribe((result) => {
        if (result["success"]){
          this.loading = false;
          this.toastr.success(result["msg"]);
          
            this.scheduleModel.accepted = true;
          
          this.createEstimate();
        }else {
          this.loading = false;
          this.toastr.error(result['error']['assign_to'][0]);
        }
    
     });
  }

  scheduleDetailsUpdateAndAccept(){
    // this.acceptBtnDisable = true;
    this.acceptRequest();
    // setTimeout(()=>{
    // if(this.estimate_id != null){
      // this.scheduleDetailsUpdate();
    // }
    // },3000)
    
  }

  getEstimateList() {
    this.loading = true;
    this.EstimateService.GetEstimateList().subscribe(
      result => {
        this.loading = false;

        this.estimate_number = result['data'].length;
        this.estimate_number = this.estimate_number + 1;
        let starting: any = `${this.estimate_number}`;
        let transformFormat = '00000'
        this.transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;
        // this.digitFormatter(this.estimate_number);
      }, (err:HttpErrorResponse) => {
        this.loading = false;
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  showCreateSuccess(msg: string) {
    this.toastr.success(msg);
  }

  showCreateDanger(msg: string) {
    this.toastr.error(msg);
  }
}
