import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token/token.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  constructor(
    public _http:HttpClient,
    public _TokenService:TokenService
  ) { }

  AddExpense(data):Observable<[]> {
    let URL = `${environment.url}expense/create`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  UpdateExpense (data):Observable<[]> {
    data.date= new Date(data.date);
    
    data.date = new Date(data.date.getTime() - data.date.getTimezoneOffset() * 60000);

    let URL =  environment.url+'expense/edit/'+data.expenses_id;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }
  GetExpenseList():Observable<[]> {
    let URL = `${environment.url}expense/list`;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  GetExpenseType():Observable<[]> {
    let URL = `${environment.url}expense/list/expense-type`;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  GetExpenseById(customer_id):Observable<[]> {
    let URL = environment.url+'expense/view/'+customer_id;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  DeleteExpense(expenses_id):Observable<[]> {
    let URL = environment.url+'expense/delete/'+expenses_id;

    return this._http.post(URL,expenses_id,{  
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  
  }

  ExpenseSearch(data):Observable<[]> {
    let URL = environment.url+'expense/search';

    return this._http.post(URL,data,{  
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  
  }


}
