import { Component, OnInit } from '@angular/core';
import { Sidebar } from 'src/app/shared';
import { timer } from 'rxjs';
import { UserService } from 'src/app/shared/services/user/user.service';
import { FormBuilder } from '@angular/forms';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { ToastrService } from 'ngx-toastr';

declare var google: any;
@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.scss']
})
export class SalesReportComponent implements OnInit {
  public loading: boolean = false;
  public User_Agents: any[] = [];
  public _data: any = null;
  public user_name: string = null;
  public Sales: any[] = [];
  public max_sales: number = null;
  public min_sales: number = null;

  constructor(
    public _sidebar: Sidebar,
    public UserService: UserService,
    public _InvoiceService: InvoiceService,
    public fb:FormBuilder,
    public toastr: ToastrService
  ) {
    this.getUser();
  }

  Report = this.fb.group({
    user:[null]
  })

  ngOnInit() {
    this.Loading(1);
    window.scroll(0, 0);
  }

  drawStuff() {
    let agentOption: object = null;
    let dataAgent: any = null;

    if(this.Report.value.user != null){
      dataAgent = new google.visualization.arrayToDataTable([
        ['Last month', 'Number of Sales'],
        ["1st Week", this._data.first_week_count],
        ["2nd Week", this._data.second_week_count],
        ["3rd Week", this._data.third_week_count],
        ["4th Week", this._data.fourth_week_count],
        ['5th Week', this._data.fifth_week_count]
      ]);
    }
    else{
      dataAgent = new google.visualization.arrayToDataTable([
        ['Last month', 'Number of Sales'],
        ["1st Week", 0],
        ["2nd Week", 0],
        ["3rd Week", 0],
        ["4th Week", 0],
        ['5th Week', 0]
      ]);
    }

    if(this.Report.value.user){
      agentOption = {
        height: 500,
        legend: { position: 'none' },
        title: `Sales by ${ this.user_name } - Sales per week`,
        vAxis: {
          viewWindow: {
            max: this.max_sales,
            min: this.min_sales
          }
        },
        bar: { groupWidth: "90%" }
      };
    }
    else{
      agentOption = {
        height: 500,
        legend: { position: 'none' },
        title: 'No Sale to Show - Please select a agent to see the sale',
        vAxis: {
          viewWindow: {
            max: 200,
            min: 0
          }
        },
        bar: { groupWidth: "90%" }
      };
    }

    var chart = new google.visualization.ColumnChart(document.getElementById('chart'));
    chart.draw(dataAgent, agentOption);
  };

  Loading(evt: any) {
    let _timer = timer(2000);
    let value = evt;

    if(this.Report.value.user!=null){
      for(let i of this.User_Agents){
        if(this.Report.value.user==i.user_id){
          this.user_name = i.name;
          break;
        }
      }
    }
    if(this._data==null){
      this.loading = true;
      _timer.subscribe(() => {
        this.loading = false;
        google.charts.load('current', { 'packages': ['corechart'] });
        google.charts.setOnLoadCallback(() => this.drawStuff());
      })
    }

    if(this.Report.value.user!=null){
      this._InvoiceService.SalesReport(this.Report.value.user).subscribe(
        result => {
          this._data = result['data'];
          if(this._data.first_week_count==0 && this._data.second_week_count==0 && this._data.third_week_count==0 && this._data.fourth_week_count==0 && this._data.fifth_week_count==0){
            this.toastr.warning('No records found for '+this.user_name);
            this.max_sales = 1000;
            this.min_sales = 0;
  
            this._data.first_week_count = 0;
            this._data.second_week_count = 0;
            this._data.third_week_count = 0;
            this._data.fourth_week_count = 0;
            this._data.fifth_week_count = 0;
            this.loading = true;
            _timer.subscribe(() => {
              this.loading = false;
              google.charts.load('current', { 'packages': ['corechart'] });
              google.charts.setOnLoadCallback(() => this.drawStuff());
            })
          }else{
              this.Sales = [
              result['data'].first_week_count,
              result['data'].second_week_count,
              result['data'].third_week_count,
              result['data'].fourth_week_count,
              result['data'].fifth_week_count
            ];
            this.max_sales = this.Sales.reduce((acc, curr) => { return acc > curr ? acc : curr });
            this.min_sales = this.Sales.reduce((acc, curr) => { return acc < curr ? acc : curr });
            this.loading = true;
            _timer.subscribe(() => {
              this.loading = false;
              google.charts.load('current', { 'packages': ['corechart'] });
              google.charts.setOnLoadCallback(() => this.drawStuff());
            })
          }
        }
      )
    }
    if (evt.target !== undefined) {
      value = +evt.target.value;
      
    }
  }

  getUser(){
    this.UserService.UserList().subscribe(
      result=>{
        this.User_Agents = result['data'];
        
      }
    )
  }

}
