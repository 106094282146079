import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Sidebar } from 'src/app/shared';
import { ScheduleService } from 'src/app/shared/services/schedule/schedule.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  public loading = false;
  public scheduleDetails: any = [];
  public subsciption: Subscription;

  public dummyData: any[] = [
    {
      count: 1,
      status: 'new',
      name: null,
    },
    {
      count: 2,
      status: 'in-progress',
      name: 'Tejas'
    },
    {
      count: 3,
      status: 'completed',
      name: null,
    },
    {
      count: 4,
      status: 'new',
      name: null,
    },
  ]

  constructor(public _sidebar: Sidebar, 
    public scheduleService: ScheduleService,
    public SettingService: SettingService) {
    this.scheduleList();
   }

  ngOnInit() {
    window.scroll(0, 0);
    this.scheduleService.setAcceptStatus = false;
  }
  ngAfterViewInit(){
    this.subsciption = this.scheduleService.acceptStatus.subscribe((response) => {
      if(response) {
        this.scheduleList();
      }
    })
  }

  ngOnDestroy(){
    this.subsciption.unsubscribe()
  }

  scheduleList(){
    this.loading = true;
    this.scheduleService.scheduleList().subscribe((response) => {
      this.scheduleDetails = response['data']   
      this.loading = false;   
    }, (err: HttpErrorResponse) => {
        this.loading = false;

        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      })
  }

  FilterScheduleList(status){
    this.loading = true;

    let data={
      status: status
    }
    if(status=='1'){
      this.scheduleList();
    }else{
      this.scheduleService.stautsFilteredList(data).subscribe(
        result => {
          if (result['success']) {
            this.scheduleDetails = result['data'];
          } else {
            this.scheduleDetails = [];

          }
          this.loading = false;

        }, (err: HttpErrorResponse) => {
          this.loading = false;

          if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
            this.SettingService.LogOut();
          }
        })
    }
  
  }
}
