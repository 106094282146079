import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-order-pay',
  templateUrl: './work-order-pay.component.html',
  styleUrls: ['./work-order-pay.component.scss']
})
export class WorkOrderPayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
