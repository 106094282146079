import { Injectable } from '@angular/core';
import { LoginModel } from '../../models/auth/auth.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { TokenService } from '../token/token.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  public _data:any;
  public reset_email:string = null;

  constructor(
    private http: HttpClient,
    
  ) { }

   Login(data: LoginModel): Observable<Comment[]> {
    let _data = {
      email: data.email,
      password: data.password,
    }

    let URL = environment.url + 'login';
    localStorage.removeItem('payment_data');

    return this.http.post(URL, _data, {
      headers: this._httpOptions.headers,
    })
    .pipe(map((result: any) => result));
  }

  User() {
    this._data = localStorage.getItem("user_data");
    return JSON.parse(this._data);
  }

  UserDetails() {
    this._data = localStorage.getItem("account_details");
    return JSON.parse(this._data);
  }

  SignUp(data): Observable<Comment[]> {

    let URL = environment.url + 'register';

    return this.http.post(URL, data, {
    })
    .pipe(map((result: any) => result));
  }

  PasswordEmailVerify(data): Observable<Comment[]> {
    let URL = environment.url + 'email-verify';

    return this.http.post(URL, data, {
      headers: this._httpOptions.headers,
    })
    .pipe(map((result: any) => result));
  }

  saveResetEmail(email){
    this.reset_email = email;  
  }

  getResetEmail(){
    return this.reset_email;
  }

  ResetPassword(data): Observable<Comment[]> {
    let URL = environment.url + 'password-updation';

    return this.http.post(URL, data, {
      headers: this._httpOptions.headers,
    })
    .pipe(map((result: any) => result));
  }

  otpSend(data): Observable<Comment[]> {
    let URL = environment.url + 'reset-password';

    return this.http.post(URL, data, {
      headers: this._httpOptions.headers,
    })
    .pipe(map((result: any) => result));
  }

  otpVerify(data): Observable<Comment[]> {
    let URL = environment.url + 'verify-otp';

    return this.http.post(URL, data, {
      headers: this._httpOptions.headers,
    })
    .pipe(map((result: any) => result));
  }
}
