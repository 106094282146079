import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class PaymentModel{
    public invoice_id:string = null;
    public invoice_number:string = null;
    public customer_id :string = null;
    public customer_name:string = null;
    public StripeToken:string = null;
    public amount_to_be_paid:any = null;
    public address_line_1:string = null;
    public address_line_2:string = null;
    public state:string = null;
    public city:string = null;
    public zip:any = null;
    public mobile:any = null;
    public postal:any = null;
    public country:string = null;
    public brand:any = null;
    public last4:any = null;
    public card_id:any = null;
    public card_number:any = null;
    public cvv:any = null;
    public exp_month:any = null;
    public exp_year:any = null;
    public email:string = null;
    public funding:any = null;
    public secret_key:any = null;
    public nonce:any = null;
    public squareAccessToken:any = null;
    public squareApplicationId:any = null;
    public squareLocationId:any = null;
    public authorizePublicClientid:any = null;
    public authorizeappLoginId:any = null;
    public authorizeTransactionId:any = null;
    public estimate_id:string = null;
    public advance: any = null;
}

