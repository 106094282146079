import { Injectable } from '@angular/core';
import { BaseModel } from '../base/base.model';

@Injectable({
  providedIn: 'root'
})

export class AccountModel extends BaseModel{
    public organization_id:string = null;
    public email:string = null;
    public business_name:string = null;
    public password:string = null;
    public logo:string = null;
    public address_line_1:string = null;
    public address_line_2:string = null;
    public template:string = null;
    public service_agreement_template:string = null;
    public widget_code:string = null;
    public zip: string = null;
}

