import { Component, OnInit } from '@angular/core';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { Router, ActivatedRoute } from '@angular/router';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { InvoiceModel, CustomerModel, PaymentModel, PaymentSettings } from 'src/app/shared';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs'
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BroadcastChannel } from 'broadcast-channel';

declare type Message = {
  message: string;
};
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  public loading: boolean = false;
  public accepted: boolean = false;

  public invoice_id: string = null;
  public organization_id: string = null;
  public publishabe_key: any = null;
  public secret_key: any = null;
  public status: number = null;

  public BC: BroadcastChannel<Message> = new BroadcastChannel('payment');

  elements: Elements;
  card: StripeElement;
  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  constructor(
    private stripeService: StripeService,
    public activatedRoute: ActivatedRoute,
    public InvoiceService: InvoiceService,
    public CustomerService: CustomerService,
    public toastr: ToastrService,
    public _Auth: AuthService,
    public SettingService: SettingService
  ) {
    (err:HttpErrorResponse) => {
      if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
        this.SettingService.LogOut();
      }
    }
    this.invoice_id = this.activatedRoute.snapshot.paramMap.get('invoice_id');
    if (this.invoice_id != null) {
      this.getInvoiceDetails(this.invoice_id);
    }
    // let UserData=this._Auth.User();
    // this.organization_id=UserData.user.organization_id;

    let _data = localStorage.getItem("payment_data");
    let parse_data = JSON.parse(_data);
    // this.publishabe_key=parse_data.data.key;

  }

  public InvoiceModel: InvoiceModel = new InvoiceModel();
  public CustomerModel: CustomerModel = new CustomerModel();
  public PaymentModel: PaymentModel = new PaymentModel();
  public PaymentData: PaymentSettings = new PaymentSettings();

  ngOnInit() {
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        let style = {
          base: {
            iconColor: '#666EE8',
            color: '#31325F',
            lineHeight: '50px',
            fontWeight: 300,
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSize: '18px',
            '::placeholder': {
              color: '#CFD7E0'
            }
          },
          invalid: {
            '::placeholder': {
              color: '#d00',
            },
          },
          placeholder: 'Zip',
        }

        if (!this.card) {
          this.card = this.elements.create('cardNumber', {
            style,
          });
          this.card.mount('#stripe-cardNumber');

          this.card = this.elements.create('cardExpiry', {
            style,
          });
          this.card.mount('#stripe-cardExpiry');

          this.card = this.elements.create('cardCvc', {
            style,
          });
          this.card.mount('#stripe-cardCvc');

          this.card = this.elements.create('postalCode', {
            style,
            placeholder: 'Zip',
          });
          this.card.mount('#stripe-cardZip');
        }
    });
  }
  getPaymentData(organization_id) {
    this.SettingService.GetPaymentDetails(organization_id)
      .subscribe((result) => {
        this.PaymentData = result['data'];
        this.secret_key = result['data']['secret'];
    }, (err:HttpErrorResponse) => {
      if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
        this.SettingService.LogOut();
      }
    }
    )
  }

  getInvoiceDetails(invoice_id) {
    this.InvoiceService.InvoiceView(invoice_id).subscribe(
      result => {
        this.InvoiceModel.customer_id = result['data'].customer_id;
        this.InvoiceModel.invoice_number = result['data'].invoice_number;
        this.InvoiceModel.total = result['data'].total;
        // this.InvoiceModel.total = Math.round(this.InvoiceModel.total);

        this.status = result['data'].status;
        this.organization_id = result['data']['organization_id'];

        if (this.status == 2) {
          this.loading = false;
          this.accepted = true;
        }

        this.getCustomerDetails(this.InvoiceModel.customer_id);
        this.getPaymentData(this.organization_id);
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  getCustomerDetails(customer_id) {
    this.CustomerService.GetCustomerById(customer_id).subscribe(
      result => {
        this.CustomerModel.customer_id = result['data'].customer_id;
        this.CustomerModel.name = result['data'].name;
        this.CustomerModel.mobile = result['data'].mobile;
        this.CustomerModel.email = result['data'].email;
        this.CustomerModel.address = result['data'].address;
        this.CustomerModel.locality = result['data'].locality;
        this.CustomerModel.zip = result['data'].zip;
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  pay() {
    this.loading = true;
    this.accepted = false;

    this.stripeService.createToken(this.card, { name })
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          this.PaymentModel.funding = result.token.card.funding;
          this.PaymentModel.amount_to_be_paid = this.InvoiceModel.total;
          this.PaymentModel.invoice_id = this.invoice_id;
          this.PaymentModel.invoice_number = this.InvoiceModel.invoice_number;
          this.PaymentModel.brand = result.token.card.brand;
          this.PaymentModel.last4 = result.token.card.last4;
          this.PaymentModel.card_id = result.token.card.id;
          this.PaymentModel.exp_month = result.token.card.exp_month;
          this.PaymentModel.exp_year = result.token.card.exp_year;
          this.PaymentModel.zip = result.token.card.address_zip;
          this.PaymentModel.customer_name = this.CustomerModel.name;
          this.PaymentModel.email = this.CustomerModel.email;
          this.PaymentModel.address_line_1 = this.CustomerModel.address;
          this.PaymentModel.address_line_2 = this.CustomerModel.address;
          this.PaymentModel.state = this.CustomerModel.locality;
          this.PaymentModel.country = result.token.card.country;
          this.PaymentModel.StripeToken = result.token.id;
          this.PaymentModel.secret_key = this.secret_key;

          this.InvoiceService.Charge(this.PaymentModel).subscribe(
            result => {
              if (result['sucess']) {
                this.loading = false;
                this.accepted = true;

                this.showSuccess(result['message']);
                this.BC.postMessage({ message: 'Paid' });
              } else {
                this.loading = false;
                this.accepted = false;

                this.showDanger(result['message']);
              }
            }
          )
        } else if (result.error) {
          // Error creating the token
          this.showDanger(result.error.message);

          this.loading = false;
          this.accepted = false;
        }
      }, (err:HttpErrorResponse) => {
        this.loading=false;
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    );
  }

  Loading() {
    let interval = timer(5000);
    this.loading = true;
    this.accepted = false;

    interval.subscribe(() => {
      this.loading = false;
      this.accepted = true;
    });
  }

  showSuccess(msg: string) {
    this.toastr.success(msg);
  }
  showDanger(msg: string) {
    this.toastr.error(msg);
  }

}
