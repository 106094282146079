import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ResetPasswordComponent } from 'src/app/layout/modal/reset-password/reset-password.component';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-forgor-password',
  templateUrl: './forgor-password.component.html',
  styleUrls: ['./forgor-password.component.scss']
})
export class ForgorPasswordComponent implements OnInit {
  @ViewChild('otpModal', {static: true}) otpModal : ModalDirective;

  public EmailVerify =this.fb.group({
    email: ['', 
      [
        Validators.required,
        Validators.pattern(/^[^*-._'%=+!`#~$*?^{}&@();:"]+([\.]?\w+)*[^*-._'%=+!`#~$*?^{}&@();:"]*@\w+([\.]?\w+)*(\.\w{2,3})+$/)
      ]
    ],
  });
  public submitted: boolean = false;
  public submitted2: boolean = false;
  public template: any = null;

  public otpVerification =this.fb.group({
    otp_text1: ['', Validators.required],
    otp_text2: ['', Validators.required],
    otp_text3: ['', Validators.required],
    otp_text4: ['', Validators.required]
  });

  constructor(
    public fb: FormBuilder,
    public toastr: ToastrService,
    public _Auth:AuthService,
    private _modalService: BsModalService,
    public router: Router
  ) { }

  public loading: boolean = false;
  public accepted: boolean = false;
  public modalRef: BsModalRef;

  ngOnInit() {
    window.scroll(0, 0)
  }

  get f() {
    return this.EmailVerify.controls;
  }

  get otpField(){
    return this.otpVerification.controls;
  }

  otpSend(){
    let data = {
      email: this.EmailVerify.value.email
    }
    this._Auth.otpSend(data).subscribe(result => {
      if(result['success']){
        this.toastr.success(result['msg']);
      }else{
        this.toastr.error(result['msg']);
      }
    })
  }

  modalOtp() {
    // this.loading = true;
    if ((this.EmailVerify.controls.email.invalid)) {
      this.submitted = true;
      // this.loading = false;
      // this.accepted = false;
      return;
    }
    this._Auth.PasswordEmailVerify(this.EmailVerify.value).subscribe(
      result=>{
        if(result['success']){
          // this.loading = false;
          // this.accepted = false;
          this.toastr.success(result['msg']);
          this._Auth.saveResetEmail(this.EmailVerify.value.email);
          this.otpSend();
          this.otpModal.show();
          this.submitted2 = false;
        }else if(result['success']==false){
          // this.loading = false;
          // this.accepted = false;
          this.toastr.error(result['msg']);

        }
      }
    )
    this.otpVerification.get('otp_text1').setValue('');
    this.otpVerification.get('otp_text2').setValue('');
    this.otpVerification.get('otp_text3').setValue('');
    this.otpVerification.get('otp_text4').setValue('');
  }

  move(event:any, preVal:any, curVal:any, nextVal:any){
    let length = curVal.value.length;
    let maxlength = curVal.getAttribute('maxlength');
    if(length == maxlength){
      if(nextVal != ''){
        nextVal.focus();
      }
    }
    if(event.key == 'Backspace'){
      if(preVal != ''){
        preVal.focus();
      }
    }
  }



  modalOpen() {
    // this.loading = true;
    // this.accepted = false;
    let otpVal = this.otpVerification.value.otp_text1.concat(this.otpVerification.value.otp_text2,this.otpVerification.value.otp_text3,this.otpVerification.value.otp_text4);
    
    let otpData = {
      email: this.EmailVerify.value.email,
      otp: otpVal.trim()
    }

    this.submitted2 = true;

    if (this.otpVerification.invalid){
        this.submitted2= true;
    } else if (this.otpVerification.value.otp_text1 == '' && this.otpVerification.value.otp_text2 == '' 
      && this.otpVerification.value.otp_text3 == '' && this.otpVerification.value.otp_text4 == ''){
        this.submitted2 = true;
    } else {
      this._Auth.otpVerify(otpData).subscribe(result => {
        if(result['success']){
          this.toastr.success(result['msg']);
          this.otpModal.hide();
          this.modalRef = this._modalService.show(ResetPasswordComponent, { ignoreBackdropClick: true, });
        }else{
          this.toastr.error(result['msg']);
        }
      })
    }

    // if ((this.EmailVerify.controls.email.invalid)) {
    //   this.submitted = true;
    //   this.loading = false;
    //   this.accepted = false;
    //   return;
    // }
    // this._Auth.PasswordEmailVerify(this.EmailVerify.value).subscribe(
    //   result=>{
    //     if(result['success']){
    //       this.loading = false;
    //       this.accepted = false;
    //       this.toastr.success(result['msg']);
    //       this._Auth.saveResetEmail(this.EmailVerify.value.email);
    //       this.modalRef = this._modal.show(ResetPasswordComponent, { ignoreBackdropClick: true, });

    //     }else if(result['success']==false){
    //       this.loading = false;
    //       this.accepted = false;
    //       this.toastr.error(result['msg']);

    //     }
    //   }
    // )

    // this.modalRef.content.onClose.subscribe(result => {
    //   if (result) {
    //     location.reload();
    //   }
    // })
  }

  EmailVerifing(){
   
  }

}

