import { Component, OnInit } from '@angular/core';
import { Sidebar, ReportModel } from 'src/app/shared';
import { timer } from 'rxjs';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

declare var google: any;

@Component({
  selector: 'app-customer-report',
  templateUrl: './customer-report.component.html',
  styleUrls: ['./customer-report.component.scss']
})
export class CustomerReportComponent implements OnInit {
  public loading: boolean = false;
  public ReportsModel: ReportModel = new ReportModel();
  public customer_count: any[] = [];
  public max_customer: number = null;
  public min_customer: number = null;

  constructor(public _sidebar: Sidebar,
    public SettingService: SettingService,
    public CustomerService: CustomerService,
    public toastr: ToastrService) {
  }

  ngOnInit() {
    this.getCustomerReport();
    window.scroll(0, 0);
  }

  getCustomerReport() {
    this.loading = true;
    this.CustomerService.Report()
      .subscribe((result) => {
        this.loading = false;
        if (result['data'].first_week_count == 0 
        && result['data'].second_week_count == 0 
        && result['data'].third_week_count == 0 
        && result['data'].fourth_week_count == 0 
        && result['data'].fifth_week_count == 0) {
          this.toastr.warning('There are no records found for customer');
          this.max_customer = 1000;
          this.min_customer = 0;

          this.ReportsModel.first_week = 0;
          this.ReportsModel.second_week = 0;
          this.ReportsModel.third_week = 0;
          this.ReportsModel.fourth_week = 0;
          this.ReportsModel.fifth_week = 0;
        } 
        else {
          this.ReportsModel.first_week = result['data'].first_week.length;
          this.ReportsModel.second_week = result['data'].second_week.length;
          this.ReportsModel.third_week = result['data'].third_week.length;
          this.ReportsModel.fourth_week = result['data'].fourth_week.length;
          this.ReportsModel.fifth_week = result['data'].fifth_week.length;

          this.customer_count = [
            result['data'].first_week_count,
            result['data'].second_week_count,
            result['data'].third_week_count,
            result['data'].fourth_week_count,
            result['data'].fifth_week_count
          ];
          this.max_customer = this.customer_count.reduce((acc, curr) => { return acc > curr ? acc : curr });
          this.min_customer = this.customer_count.reduce((acc, curr) => { return acc < curr ? acc : curr });
        }
        google.charts.load('current', { 'packages': ['corechart'] });
        google.charts.setOnLoadCallback(() => this.drawStuff());
      },
        (err: HttpErrorResponse) => {
          if (err.error.message == 'Unauthenticated.'
            || err.status == 401
            || err.statusText == "Unauthorized") {
            this.SettingService.LogOut();
          }
        }
      )
  }

  drawStuff() {
    var data = new google.visualization.arrayToDataTable([
      ['Last month', ' No. of customer(s)'],
      ["1st Week", this.ReportsModel.first_week],
      ["2nd Week", this.ReportsModel.second_week],
      ["3rd Week", this.ReportsModel.third_week],
      ["4th Week", this.ReportsModel.fourth_week],
      ['5th Week', this.ReportsModel.fifth_week]
    ]);

    var options = {
      height: 500,
      legend: { position: 'none' },
      title: 'Customers - New customers added per week',
      axes: {},
      vAxis: {
        viewWindow: {
          max: this.max_customer,
          min: this.min_customer
        }
      },
      bar: { groupWidth: "90%" }
    };

    this.loading = false;
    var chart = new google.visualization.ColumnChart(document.getElementById('chart'));
    // Convert the Classic options to Material options.
    chart.draw(data, options);
  };
}
