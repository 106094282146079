import { Component, OnInit } from '@angular/core';
import { Sidebar } from 'src/app/shared';
import { timer } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { ToastrService } from 'ngx-toastr';

declare var google: any;

@Component({
  selector: 'app-payment-report',
  templateUrl: './payment-report.component.html',
  styleUrls: ['./payment-report.component.scss']
})
export class PaymentReportComponent implements OnInit {
  public loading: boolean = false;
  public _data: any = null;
  public max_transaction: number = null;
  public min_transaction: number = null;
  public transactions: any[] = [];


  constructor(
    public _sidebar: Sidebar,
    public fb: FormBuilder,
    public _InvoiceService: InvoiceService,
    public toastr: ToastrService
  ) { }

  public Report = this.fb.group({
    payment_type:[1]
  });

  ngOnInit() {
    this.Loading(1);
    window.scroll(0, 0);
  }

  drawStuff(val: any) {
    var dataCash = new google.visualization.arrayToDataTable([
      ['Last month', ' No. of transactions via cash'],
      ["1st Week", this._data.first_week_count],
      ["2nd Week", this._data.second_week_count],
      ["3rd Week", this._data.third_week_count],
      ["4th Week", this._data.fourth_week_count],
      ['5th Week', this._data.fifth_week_count]
    ]);

    var dataCard = new google.visualization.arrayToDataTable([
      ['Last month', 'No. of transactions via card'],
      ["1st Week", this._data.first_week_count],
      ["2nd Week", this._data.second_week_count],
      ["3rd Week", this._data.third_week_count],
      ["4th Week", this._data.fourth_week_count],
      ['5th Week', this._data.fifth_week_count]
    ]);

    var cashOptions = {
      height: 500,
      legend: { position: 'none' },
      title: 'Payment generated via Cash - Transactions per week',
      vAxis: {
        viewWindow: {
          max: this.max_transaction,
          min: this.min_transaction
        }
      },
      bar: { groupWidth: "90%" }
    };

    var cardOptions = {
      height: 500,
      legend: { position: 'none' },
      title: 'Payment generated via Card - Transactions per week',
      colors: ['#6758f3'],
      vAxis: {
        viewWindow: {
          max: this.max_transaction,
          min: this.min_transaction
        }
      },
      bar: { groupWidth: "90%" }
    };

    var chart = new google.visualization.ColumnChart(document.getElementById('chart'));

    // Convert the Classic options to Material options.
    switch (val) {
      case 1:
        chart.draw(dataCash, cashOptions);
        break;
      case 2:
        chart.draw(dataCard, cardOptions);
        break;
      default:
        chart.draw(dataCash, cashOptions);
    }
  };

  Loading(evt: any) {
    this.loading = true;
    let _timer = timer(2000);
    let value = evt;

    if (evt.target !== undefined) {
      value = +evt.target.value;
    }

    if(this.Report.value.payment_type==1){
      this._InvoiceService.GetCashReport().subscribe(
        result=>{
          this._data =  result['data'];
          if(this._data.first_week_count==0 && this._data.second_week_count==0 && this._data.third_week_count==0 && this._data.fourth_week_count==0 && this._data.fifth_week_count==0){
            this.toastr.warning('There are no records found for cash')
            this.max_transaction = 1000;
            this.min_transaction = 0;

            this._data.first_week_count = 0;
            this._data.second_week_count = 0;
            this._data.third_week_count = 0;
            this._data.fourth_week_count = 0;
            this._data.fifth_week_count = 0;
            _timer.subscribe(() => {
              this.loading = false;
              google.charts.load('current', { 'packages': ['corechart'] });
              google.charts.setOnLoadCallback(() => this.drawStuff(value));
            })
          }else{
            this.transactions = [
              this._data.first_week_count,
              this._data.second_week_count,
              this._data.third_week_count,
              this._data.fourth_week_count,
              this._data.fifth_week_count
            ];
            this.max_transaction = this.transactions.reduce((acc, curr) => { return acc > curr ? acc : curr})
            this.min_transaction = this.transactions.reduce((acc, curr) => { return acc < curr ? acc : curr})
            _timer.subscribe(() => {
              this.loading = false;
              google.charts.load('current', { 'packages': ['corechart'] });
              google.charts.setOnLoadCallback(() => this.drawStuff(value));
            })
          }
        }
      )
      
    }else if(this.Report.value.payment_type==2){
      this._InvoiceService.GetCardReport().subscribe(
        result=>{
          this._data =  result['data'];
          if(this._data.first_week_count==0 && this._data.second_week_count==0 && this._data.third_week_count==0 && this._data.fourth_week_count==0 && this._data.fifth_week_count==0){
            this.toastr.warning('There are no records found for card');
            this.max_transaction = 1000;
            this.min_transaction = 0;

            this._data.first_week_count = 0;
            this._data.second_week_count = 0;
            this._data.third_week_count = 0;
            this._data.fourth_week_count = 0;
            this._data.fifth_week_count = 0;
            _timer.subscribe(() => {
              this.loading = false;
              google.charts.load('current', { 'packages': ['corechart'] });
              google.charts.setOnLoadCallback(() => this.drawStuff(value));
            })
          }else{
            this.transactions = [
              this._data.first_week_count,
              this._data.second_week_count,
              this._data.third_week_count,
              this._data.fourth_week_count,
              this._data.fifth_week_count
            ];
            this.max_transaction = this.transactions.reduce((acc, curr) => { return acc > curr ? acc : curr})
            this.min_transaction = this.transactions.reduce((acc, curr) => { return acc < curr ? acc : curr})
            _timer.subscribe(() => {
              this.loading = false;
              google.charts.load('current', { 'packages': ['corechart'] });
              google.charts.setOnLoadCallback(() => this.drawStuff(value));
            })
          }

        }
      )
    }

    
    
  }

}
