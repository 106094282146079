import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PagesModule } from "./pages/pages.module";
import { HttpClientModule } from "@angular/common/http";
import { NgxStripeModule } from "ngx-stripe";
import { SidebarComponent } from "./layout";
import { Sidebar } from "./shared";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { ScheduleCalendarDetailsComponent } from './layout/modal/schedule-calendar-details/schedule-calendar-details.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AddProductAndServiceComponent } from './layout/modal/add-product-and-service/add-product-and-service.component';
import { TabsModule } from "ngx-bootstrap/tabs";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgOtpInputModule } from "ng-otp-input";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
var STRIPE_KEY;
let _data = localStorage.getItem("payment_data");
let parse_data = JSON.parse(_data);
STRIPE_KEY = parse_data;

FullCalendarModule.registerPlugins( [
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
] );
@NgModule({
  declarations: [AppComponent, SidebarComponent, ScheduleCalendarDetailsComponent, AddProductAndServiceComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxStripeModule.forRoot(STRIPE_KEY),
    AngularEditorModule,
    CKEditorModule,
    FullCalendarModule,
    InfiniteScrollModule,
    TabsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgOtpInputModule,
    GooglePlaceModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [ScheduleCalendarDetailsComponent,AddProductAndServiceComponent]
})
export class AppModule {
  // let _data = localStorage.getItem("payment_data");
  // let parse_data = JSON.parse(_data);
  // STRIPE_KEY=parse_data.data.key;
  // return JSON.parse(_data);
  // }
}
