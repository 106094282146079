import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, NgModule } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Schedule } from "src/app/shared";
import { ScheduleService } from "src/app/shared/services/schedule/schedule.service";
import { SettingService } from "src/app/shared/services/setting/setting.service";
import { UserService } from "src/app/shared/services/user/user.service";

@Component({
  selector: "app-call-customer",
  templateUrl: "./call-customer.component.html",
  styleUrls: ["./call-customer.component.scss"],
})
export class CallCustomerComponent implements OnInit {
  widget_service_id: any;
  public userlist: any = [];
  public submitted: boolean = false;
  public callCustomer = this.fb.group({
    appointmentDate: ["",Validators.required],
    time: ['', [Validators.required, Validators.pattern('^([1-9]|0[1-9]|1[0-2]):[0-5][0-9] ([AaPp][Mm])$')]],
    assign_to: ["", Validators.required],
  });
  constructor(public modalRef: BsModalRef, 
    public fb: FormBuilder,
    public scheduleService: ScheduleService,
    public SettingService: SettingService,
    public toastr: ToastrService,
    public router: Router,
    public UserService: UserService,
    ) {}

  ngOnInit() {
    this.scheduleDetails(this.widget_service_id);
    this.getUserList();
  }
  public scheduleModel: Schedule = new Schedule();

  scheduleDetails(widgetServiceId) {
    // this.loading = true;
    this.scheduleService.scheduleDetails(widgetServiceId).subscribe((result) => {
      this.scheduleModel.widget_service_id = result['data'].widget_service_id;
      this.scheduleModel.date = result['data'].date;
      this.scheduleModel.time = result['data'].time;
      this.scheduleModel.assign_to = result['data'].assign_to;
      // this.loading = false;
    }, (err: HttpErrorResponse) => {
      if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
        // this.loading = false;
        this.SettingService.LogOut();
      }
    }
    )
  }
  callToCustomer() {
  }

  get f() {
    return this.callCustomer.controls;
  }

  scheduleDetailsUpdate() {
    this.submitted  = true;
    if(this.callCustomer.invalid){
      return;
    }
    this.scheduleModel.date = new Date(this.scheduleModel.date);
    this.scheduleModel.time  = this.callCustomer.value.time;
    this.scheduleModel.accepted = true;
    this.scheduleService.updateScheduleDetails(this.scheduleModel).subscribe((result) => {
      this.scheduleService.setAcceptStatus = result['success'];

      // this.loading = false;
      if (result['success']) {
        // this.loading = false;
        this.toastr.success(result['msg']);
        this.modalRef.hide();
        this.router.navigate(['/schedule']);
      } else {
        // this.loading = false;
        this.toastr.error(result['msg']);
      }
    })
  }

  getUserList() {
    // this.loading = true;
    this.UserService.UserList()
      .subscribe((result) => {
        if (result['success']) {
          // this.loading = false;
          this.userlist = result['data'];
        }
        else {
          // this.loading = false;
        }
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      }
      )
  }
}
