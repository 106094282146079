import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { EstimateService } from 'src/app/shared/services/estimate/estimate.service';
import { ItemService } from 'src/app/shared/services/item/item.service';
import { EstimateModel, CustomerModel, TaxRateModel } from 'src/app/shared';
import { ToastrService } from 'ngx-toastr';
import { NgxMaterialTimepickerHoursFace } from 'ngx-material-timepicker/src/app/material-timepicker/components/timepicker-hours-face/ngx-material-timepicker-hours-face';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { ServiceModuleService } from 'src/app/shared/services/service/service-module.service';
import { WorkOrderService } from 'src/app/shared/services/work-order/work-order.service';
import { AddProductAndServiceComponent } from 'src/app/layout/modal/add-product-and-service/add-product-and-service.component';
import { BsModalRef, BsModalService, ModalOptions, ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { TaxService } from 'src/app/shared/services/tax/tax.service';
import {  environment } from 'src/environments/environment';
import { AddChecklistService } from 'src/app/shared/services/checklist/add-checklist.service';
import { SaveProgressWorkComponent } from 'src/app/layout/modal/save-progress-work/save-progress-work/save-progress-work.component';
@Component({
  selector: 'app-estimate-new',
  templateUrl: './estimate-new.component.html',
  styleUrls: ['./estimate-new.component.scss']
})
export class EstimateNewComponent implements OnInit {
  public loading: boolean = false;
  public discount: number = 20;
  public percent: string = '%';
  public dollar: string = '';
  public estimate_id: string = null;
  public item_id: string = '';
  public submitted: boolean = null;
  public submitted2: boolean = null;
  public submitted3: boolean = false;
  public addBtnDisable = false;
  public keyDownDisable: boolean = false;
  public keyUpDisable: boolean = false;
  public setIndex: any;
  public env = environment;
  public checklistItem: any[] = []

  public rate: any = 0;
  public current_selected_item: any = null;
  public estimate_number: number = null;
  public estimate_index: string = null;
  public step: any = 1;
  public add_remove_item: boolean = false;

  public additional_image_name: any;
  public multiple_image_extension_validation: boolean = true;
  public multiple_image_size_validation: boolean = true;
  public image_extension: boolean = true;
  public multiplefileExtensionMessage: any;
  public filesSizeMessage: any;
  public multiple_image_size: any;
  public additionalFileExtension: any;
  public files: File[] = [];
  imagList: Array<any> = [];

  public amount: number = 0;
  public discount_amount: number = 0;
  public salesTax_amount: number = 0;
  public total_amount: any = 0;
  public advance_amount: number = 0;

  private _step: any = null;
  private transformedData: any = null;

  public itemArr: any[] = [];
  public itemIdArr: any[] = [];
  public Customers: any[] = [];
  public List: any[] = [];
  public CustomerModel: CustomerModel = new CustomerModel();
  public math: Math;
  public add_item: boolean =false;
  public loading1: boolean = false;
  public accepted1: boolean = false;
  public isCheckedDiscountPercent: boolean = false;
  public isCheckedDiscountDollar: boolean = false;
  public addBtnLoader = false;
  public modalRef: BsModalRef;
  public EstimateModel: EstimateModel = new EstimateModel();
  public _formdata = new FormData();
  public checklist_enabled: boolean = false;
  public enabled_modules: any[] = [];
  public alternative_number: any[] = [];
  public NewEstimate = this.fb.group({
    customer_id: ['', Validators.required],
    line_item: [''],
    quantity: [1, [Validators.required, Validators.pattern('^[0-9]*$')]],
    unit_price: [0, [Validators.required, Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)]],
    discount: ['',
      Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')
    ],
    discount_type: [''],
    sales_tax: ['',
      Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')
    ],
    due_date: ['', Validators.required],
    advance: ['',
      Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')
    ],
    advance_type: [''],
    note: [''],
    private_note: [''],
    checklist_id: ['']
  });
  public customerList: any[] = [];
  public item_count: number = 0;
  public model_item_count: number = 0;
  public isCheckedPercent: boolean = false;
  public isCheckedDollar: boolean = false;
  public current_tax:any =null;
  public services: any[] = [];
  public productServices: any[] = [];
  public customer_id: string = null;
  public minDate: Date;
  public subscriptions: Subscription[] = [];
  estimate_images: Array<any> = [];
  work_order_images: Array<any> = [];
  public discount_value: boolean = false;
  public advance_value: boolean = false;
  public data: any[] = [];
  public is_draft_save: boolean =false;
  public subsciption: Subscription;

  @ViewChild('estimateDraft', { static: true }) estimateDraft: ModalDirective;

  constructor(
    public _router: Router,
    public _activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    public EstimateService: EstimateService,
    public ItemService: ItemService,
    public toatr: ToastrService,
    public CustomerService : CustomerService,
    public SettingService : SettingService,
    public serviceService : ServiceModuleService,
    public WorkOrderService : WorkOrderService,
    public modalService: BsModalService,
    public _invoiceService: InvoiceService,
    public AddChecklistService: AddChecklistService,
    public taxService: TaxService
  ) {
    this.EstimateModel.items = [];
    this.step=this._activatedRoute.snapshot.paramMap.get('step');
    if(this.step=='2'){
      this.step=(+this.step)
      this.Step(this.step); 
    }
    this.customer_id = this._activatedRoute.snapshot.paramMap.get('customer_id');
    if (this.customer_id) {
      this.Customer(this.customer_id)
    }
    this.item_id = this._activatedRoute.snapshot.paramMap.get('id');
    if (this.item_id) {
      this.getProductFromProductPage();
    }
    this.getCustomerList();
    // this.getItemList();
    this.estimate_id = this._activatedRoute.snapshot.paramMap.get('estimate_id');
    if (!this.estimate_id) {
      this.getWorkOrderDetails();
    }
    this.getEstimateDetails(this.estimate_id);

    this.getEstimateList();
    if (this.estimate_id != null) {
      this.estimate_index = this._activatedRoute.snapshot.paramMap.get('estimate_index');
    }
    this.productServiceList();
    this.minDate = new Date();

    if ( JSON.parse( localStorage.getItem( 'activated_modules' ) ) )
    {
      this.enabled_modules = JSON.parse( localStorage.getItem( 'activated_modules' ) );
      for ( let index of this.enabled_modules )
      {
        if ( index[ 'module_id' ] == '50bf5d16f34b' && index[ 'enabled' ] == 1)
        {
          this.checklist_enabled = true;
        }
      }
    }
    this.getDraftDetailsData();
  }

  ngOnInit() {
    // this._step = this._activatedRoute.snapshot.paramMap.get('step');
    // this.Step(this._step);
    this.getCheckListItem();
    window.scroll(0, 0);
    if ( this.estimate_id != null )
    {
      this.getEstimateImages( this.estimate_id );

    }
    this.serviceService.setServiceSuccess = null;
    this.serviceService.setMoreServiceSuccess = null;
    this.add_item = false;
    this.AddEstimateDraft();
  }

  ngAfterViewInit(){
    this.subsciption = this.EstimateService.isDraftSave.subscribe((response) => {
      if(response) {
        this.is_draft_save = response;
        if(this.is_draft_save && !this.estimate_id) {
          this.estimateDraft.show();
        }
      }
    })


    const serviceAndProductResponse = this.serviceService.serviceSuccess.subscribe((response) => {
      if(response){
        this.productServiceList()
        this.modalService.hide(1);
      let removeModal =  document.querySelector('.modal-open');
      removeModal.classList.remove('modal-open')
      }
    });
    const moreServiceAndProductResponse = this.serviceService.servicMoreSuccess.subscribe((response) => {
      if(response){
        this.productServiceList()
      }
    })
    this.subscriptions.push(serviceAndProductResponse, moreServiceAndProductResponse);
    this.getProductServiceModalData();
    this.getServiceModalData();
  }

  ngOnDestroy(): void {
    this.itemArr = [];
    this.EstimateModel.items = [];
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subsciption.unsubscribe();
  }

  discountErrorMessage(){
    
    if (this.NewEstimate.value.discount_type == '$'){
      if (this.NewEstimate.value.discount >= Number(this.amount)){
        this.discount_value = true;
      }else{
        this.discount_value = false;
      }
    }else if (this.NewEstimate.value.discount_type == '%'){
      if (this.amount < 0){
        this.discount_value = true;
      }else{
        this.discount_value = false;
      }
    }
  }

  advanceErrorMessage(){

    if (this.NewEstimate.value.advance_type == '$'){
      if (this.NewEstimate.value.advance >= Number(this.amount)){
        this.advance_value = true;
      }else{
        this.advance_value = false;
      }
    }else if (this.NewEstimate.value.advance_type == '%'){
      if (this.NewEstimate.value.advance>=100){
        this.advance_value = true;
      }else{
        this.advance_value = false;
      }
    }
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    this.transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;
    this.EstimateService.setEstimateNumber = this.transformedData;
    return this.transformedData;
  }

  Customer(event){
    if (event) {
      // this.loading = true;
      this.CustomerService.GetCustomerById(event)
        .subscribe((result) => {
          // this.loading = false;

          this.CustomerModel.customer_id = result['data'].customer_id;
          this.CustomerModel.name = result['data'].name;
          this.CustomerModel.mobile = result['data'].mobile;
          this.CustomerModel.alternative_number = result['data'].alternative_number;
          this.alternative_number = this.CustomerModel.alternative_number;
          this.CustomerModel.email = result['data'].email;
          this.CustomerModel.address = result['data']['default_address'].address;
          this.CustomerModel.locality = result['data']['default_address'].locality;
          this.CustomerModel.zip = result['data']['default_address'].zip;
          if (!this.NewEstimate.value.customer_id) {
            this.NewEstimate.get('customer_id').setValue(this.CustomerModel.customer_id);
            this.NewEstimate.get('customer_id').updateValueAndValidity();
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

  Calculate() {
    //the total value to be send in api
    this.total_amount = 0;

    //amount variable is the added unit price of all the items added in line items
    if (this.amount == 0) {
      this.EstimateModel.total = 0;
    }

    //Discount calulation on amount variable to get the the total amount
    if (this.NewEstimate.value.discount_type != null && this.NewEstimate.value.discount_type != '' && this.NewEstimate.value.discount != null && this.NewEstimate.value.discount != '') {
      if (this.NewEstimate.value.discount_type == '%') {
        if(this.NewEstimate.value.discount>=100){
          this.discount_value = true;
          return;

        }else{
          this.discount_value = false;

        }
        this.discount_amount = 0;
        this.discount_amount = (this.amount * this.NewEstimate.value.discount) / 100;
        this.discount_amount = this.amount - this.discount_amount;
        this.EstimateService.setGrossAmount = this.discount_amount;
        this.total_amount = this.discount_amount;
        this.EstimateService.setTotalAmount = this.total_amount;
      } else if (this.NewEstimate.value.discount_type == '$') {
        if (this.NewEstimate.value.discount >= this.amount) {
          this.discount_value = true;
          return;

        } else {
          this.discount_value = false;
        }
        this.discount_amount = 0;
        this.discount_amount = this.amount - this.NewEstimate.value.discount;
        this.EstimateService.setGrossAmount = this.discount_amount;
        this.total_amount = this.discount_amount;
        this.EstimateService.setTotalAmount = this.total_amount;
      }
    }


    //Sales Tax calcualtion on amont variable and total amount variable based on the if else condition
    if (this.NewEstimate.value.sales_tax != null && this.NewEstimate.value.sales_tax != '') {
      
      //if discount amount is 0, that means discount is not given, so the calculation will be in the amount variable(which is the addition of the unit price of all the added line items)
      //else the calculation will be taken place in the total amount(which is the discounted amount after providing discount)
      if (this.discount_amount == 0) {
        this.total_amount = this.amount * (1 + (this.NewEstimate.value.sales_tax / 100));
        this.total_amount=((this.total_amount * 10000)/10000).toFixed(2);
        this.salesTax_amount = this.total_amount;
        this.EstimateService.setGrossAmount = this.salesTax_amount;
        this.EstimateService.setTotalAmount = this.total_amount;
      } else {
        this.total_amount = this.discount_amount * (1 + (this.NewEstimate.value.sales_tax / 100));
        this.total_amount=((this.total_amount * 10000)/10000).toFixed(2);
        this.salesTax_amount = this.total_amount;
        this.EstimateService.setGrossAmount = this.salesTax_amount;
        this.EstimateService.setTotalAmount = this.total_amount;

      }

    }
    //if total amount is 0, that means discount and sales tax are not given, so the total amount will be the amount variable value
    if(this.total_amount==0){
      this.total_amount = this.amount;
      this.EstimateService.setTotalAmount = this.total_amount;
    }
    //advance calculation while creating the estimate
    if (this.estimate_id === null) {
      if ((this.NewEstimate.value.advance != null && this.NewEstimate.value.advance != '')
        && (this.NewEstimate.value.advance_type != null && this.NewEstimate.value.advance_type != '')) {

        if (this.NewEstimate.value.advance_type == "%") {
          if(this.NewEstimate.value.advance>=100){
            this.advance_value = true;
  
          }else{
            this.advance_value = false;
  
          }

          this.total_amount = this.total_amount * (1 - this.NewEstimate.value.advance / 100);
          this.total_amount = (Math.round(this.total_amount * 10000) / 10000).toFixed(2);
          this.EstimateService.setTotalAmount = this.total_amount;
        } else {
          if (this.NewEstimate.value.advance > Number(this.total_amount)){
            this.advance_value = true;
  
          } else {
            this.advance_value = false;
  
          }

          this.total_amount = this.total_amount - this.NewEstimate.value.advance;
          this.total_amount = (Math.round(this.total_amount * 10000) / 10000).toFixed(2);
          this.EstimateService.setTotalAmount = this.total_amount;

        }
      }

      
    }

    //advance calculation while editing the estimate
    else if (this.estimate_id !== null) {
      if ((this.EstimateModel.advance != null && this.EstimateModel.advance != '')
        && (this.EstimateModel.advance_type != null && this.EstimateModel.advance_type != '')) {
        if (this.EstimateModel.advance_type == "%") {
          if(this.NewEstimate.value.advance>=100){
            this.advance_value = true;
  
          }else{
            this.advance_value = false;
  
          }
          this.total_amount = this.total_amount * (1 - this.EstimateModel.advance / 100);
          this.total_amount = (Math.round(this.total_amount * 10000) / 10000).toFixed(2);
          this.EstimateService.setTotalAmount = this.total_amount;
        } else {
          if (this.NewEstimate.value.advance > Number(this.total_amount)){
            this.advance_value = true;
  
          } else {
            this.advance_value = false;
  
          }
          this.total_amount = this.total_amount - this.EstimateModel.advance;
          this.total_amount = (Math.round(this.total_amount * 10000) / 10000).toFixed(2);
          this.EstimateService.setTotalAmount = this.total_amount;
        }
      }
    }

    if (this.discount_amount == 0 && this.total_amount == 0) {
      this.total_amount = this.amount;
      this.EstimateService.setTotalAmount = this.total_amount;
    } else if (this.discount_amount > 0 && this.total_amount == 0) {
      this.total_amount = this.discount_amount;
      this.EstimateService.setTotalAmount = this.total_amount;
    } else if (this.total_amount > 0) {
      this.total_amount = this.total_amount;
      this.EstimateService.setTotalAmount = this.total_amount;
    }
                    
    this.total_amount = parseFloat(this.total_amount).toFixed(2);
    this.EstimateService.setTotalAmount = this.total_amount;
  }

  getEstimateList() {
    this.EstimateService.GetEstimateList().subscribe(
      result => {

        this.estimate_number = result['data'].length;
        this.estimate_number = this.estimate_number + 1;
        this.digitFormatter(this.estimate_number);
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  public isCustomerNull: boolean = false;
  getCustomerList() {
    this.EstimateService.GetCustomerList().subscribe(
      result => {
        if(result['data'].length) {
          this.isCustomerNull = false;
        }else {
          this.isCustomerNull = true;
        }
        this.customerList = result['data'];
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  CustomerList() {
    if(this.isCustomerNull) {
      this.toatr.warning("Please add customer first");
    }
  }

  GetCustomerListOnSearch(event){
    let data={
      search: event.target.value
    }
    if(data.search!=''){
      this.CustomerService.GetCustomerListOnSearch(data).subscribe(
        result=>{
          this.Customers = result['data'];
          
        }
      )
    }else{
      this.Customers = null;
    } 
  }

  OnChangeItem(event,flag) {
    if(!this.NewEstimate.value.line_item && !event){
      this.add_remove_item = false
      this.NewEstimate.get('unit_price').setValue(0);
      this.NewEstimate.get('quantity').setValue(1);
      this.NewEstimate.updateValueAndValidity();
      this.addBtnDisable = true;
    }

    if(event){
      this.addBtnLoader = true;
      this.addBtnDisable = true;
      if(flag==null){
        this.current_selected_item = event;
      }
      let value = this.current_selected_item['id'];
  
      if (this.current_selected_item['group']=='Product'){
        this.ItemService.GetItemById(value).subscribe(
          result => {
            this.rate = result['data']['rate'];          
            if(result['data'].tax_rate){
              
              this.current_tax = result['data'].item_taxrate.rate
  
            }else{
              this.current_tax = null;
            }
            this.addBtnLoader = false;
            this.addBtnDisable = false;
            
          }, (err:HttpErrorResponse) => {
            this.addBtnLoader = false;
            this.addBtnDisable = false;
            if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
              this.SettingService.LogOut();
            }
          }
        )
      }
      else if (this.current_selected_item['group']=='Service'){
        this.serviceService.GetServiceById(value).subscribe(
          (result: any) => {
            this.rate = result['data']['rate'];
            if(result['data'].tax_rate){
              this.current_tax = result['data'].service_taxrate.rate
            }else{
              this.current_tax = null;
            }
            this.addBtnLoader = false;
            this.addBtnDisable = false;
          }, (err:HttpErrorResponse) => {
            this.addBtnLoader = false;
            this.addBtnDisable = false;
            if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
              this.SettingService.LogOut();
            }
          }
        )
      }
    }
  }

  // getItemList() {
  //   this.EstimateService.GetItemList().subscribe(
  //     result => {
  //       this.List = result['data'];
  //       console.log(this.List);

  //       this.productServices = [
  //         ...this.services.map(
  //           (item: Object) => ({
  //             group: 'Service',
  //             name: item['name'],
  //             id: item['service_id'],
  //           })
  //         ),
  //         ...this.List.map(
  //           (item: Object) => ({
  //             group: 'Product',
  //             name: item['name'],
  //             id: item['item_id'],
  //           })
  //         )
  //       ]
  //       console.log(this.productServices);
        

  //     }, (err:HttpErrorResponse) => {
  //       if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
  //         this.SettingService.LogOut();
  //       }
  //     }
  //   )
  // }

  // Add item
  AddItem(){
    this.add_item = false;
    this.add_remove_item = false;
    if ( ( this.NewEstimate.controls.unit_price.invalid ) || ( this.NewEstimate.controls.quantity.invalid ) )
    {
      this.submitted3 = true;
      return;
    }

    this.rate = this.NewEstimate.value.unit_price;

    let addItemField: HTMLInputElement = document.querySelector('[name="line_item"]');
    let addQuantityField: HTMLInputElement = document.querySelector('[name="quantity"]');

    if ( this.NewEstimate.value.line_item !== '' )
    {

      if ( this.current_selected_item.group == 'Product' )
      {
        this.ItemService.GetItemById( this.NewEstimate.value.line_item )
          .subscribe( ( result ) =>
          {
            for ( let i in this.itemArr )
            {

              if ( this.itemArr[ i ].item_id == result[ 'data' ].name )
              {
                this.itemArr[ i ].qty = this.NewEstimate.value.quantity;
                this.itemArr[ i ].group = 'Product';
                this.itemArr[ i ].unit_price = this.rate;
                if ( result[ 'data' ].tax_rate )
                {
                  this.itemArr[ i ].tax = result[ 'data' ].item_taxrate.rate + '%';
                  this.itemArr[ i ].rate = ( this.NewEstimate.value.quantity * ( +this.rate + ( +this.rate * ( result[ 'data' ].item_taxrate.rate / 100 ) ) ) ).toFixed( 2 );
                } else
                {
                  this.itemArr[ i ].tax = 'NA';
                  this.itemArr[ i ].rate = this.NewEstimate.value.quantity * ( +this.rate );

                }
                this.item_count = 1;
                break;
              }

            }

            if ( result[ 'data' ].tax_rate )
            {
              if ( this.item_count == 0 )
              {
                this.itemArr.push( {
                  item_id: result[ 'data' ].name,
                  group: 'Product',
                  qty: this.NewEstimate.value.quantity,
                  unit_price: this.rate,
                  rate: ( this.NewEstimate.value.quantity * ( +this.rate + ( +this.rate * ( result[ 'data' ].item_taxrate.rate / 100 ) ) ) ).toFixed( 2 ),
                  tax: result[ 'data' ].item_taxrate.rate + '%'
                } );
              }
            } else
            {
              if ( this.item_count == 0 )
              {
                this.itemArr.push( {
                  item_id: result[ 'data' ].name,
                  group: 'Product',
                  qty: this.NewEstimate.value.quantity,
                  unit_price: this.rate,
                  rate: this.NewEstimate.value.quantity * ( +this.rate ),
                  tax: 'NA'
                } );
              }
            }

            this.item_count = 0;
            this.itemIdArr.push( result[ 'data' ].item_id );

            this.amount = 0;


            if ( this.amount == 0 )
            {
              for ( let i of this.itemArr )
              {
                this.amount = this.amount + ( +i.rate );
                this.EstimateService.setGrossAmount = this.amount;
              }
            }

            if ( this.estimate_id == null )
            {
              if ( this.NewEstimate.value.unit_price > 0 )
              {
                this.NewEstimate.controls.unit_price.setValue( 0 );
                this.NewEstimate.updateValueAndValidity();
              }
              if ( this.NewEstimate.value.quantity > 0 )
              {
                // this.amount = this.amount + ((+result['data'].rate) * this.NewEstimate.value.quantity);
              } else if ( this.NewEstimate.value.quantity == '' || this.NewEstimate.value.quantity == null )
              {
                this.NewEstimate.controls.quantity.setValue( 1 );
                // this.amount = this.amount + ((+result['data'].rate) * this.NewEstimate.value.quantity);
              }

              this.Calculate();
              this.NewEstimate.controls.quantity.setValue( 1 );
              this.NewEstimate.updateValueAndValidity();
            } else
            {
              if ( this.NewEstimate.value.unit_price > 0 )
              {
                this.NewEstimate.controls.unit_price.setValue( 0 );
                this.NewEstimate.updateValueAndValidity();
              }

              if ( this.NewEstimate.value.quantity > 0 )
              {
                // this.amount = this.amount + ((+result['data'].rate) * this.NewEstimate.value.quantity);
              } else if ( this.NewEstimate.value.quantity == '' || this.NewEstimate.value.quantity == null )
              {
                this.NewEstimate.controls.quantity.setValue( 1 );
                // this.amount = this.amount + ((+result['data'].rate) * this.NewEstimate.value.quantity);
              }


              this.Calculate();
              this.EstimateModel.amount = this.amount;
              this.NewEstimate.controls.quantity.setValue( 1 );
              this.NewEstimate.updateValueAndValidity();

            }
          }, ( err: HttpErrorResponse ) =>
          {
            if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
            {
              this.SettingService.LogOut();
            }
          } );
      } else if ( this.current_selected_item.group == 'Service' )
      {
        this.serviceService.GetServiceById( this.NewEstimate.value.line_item )
          .subscribe( ( result ) =>
          {
            for ( let i in this.itemArr )
            {

              if ( this.itemArr[ i ].item_id == result[ 'data' ].name )
              {
                this.itemArr[ i ].qty = this.NewEstimate.value.quantity;
                this.itemArr[ i ].group = 'Service';
                this.itemArr[ i ].unit_price = this.rate;
                if ( result[ 'data' ].tax_rate )
                {
                  this.itemArr[ i ].tax = result[ 'data' ].service_taxrate.rate + '%';
                  this.itemArr[ i ].rate = ( this.NewEstimate.value.quantity * ( +this.rate + ( +this.rate * ( result[ 'data' ].service_taxrate.rate / 100 ) ) ) ).toFixed( 2 );

                } else
                {
                  this.itemArr[ i ].rate = this.NewEstimate.value.quantity * ( +this.rate );
                  this.itemArr[ i ].tax = 'NA';

                }
                this.item_count = 1;
                break;
              }

            }

            if ( result[ 'data' ].tax_rate )
            {
              if ( this.item_count == 0 )
              {
                this.itemArr.push( {
                  item_id: result[ 'data' ].name,
                  group: 'Service',
                  qty: this.NewEstimate.value.quantity,
                  unit_price: this.rate,
                  rate: ( this.NewEstimate.value.quantity * ( +this.rate + ( +this.rate * ( result[ 'data' ].service_taxrate.rate / 100 ) ) ) ).toFixed( 2 ),
                  tax: result[ 'data' ].service_taxrate.rate + '%'
                } );
              }
            } else
            {
              if ( this.item_count == 0 )
              {
                this.itemArr.push( {
                  item_id: result[ 'data' ].name,
                  group: 'Service',
                  qty: this.NewEstimate.value.quantity,
                  unit_price: this.rate,
                  rate: this.NewEstimate.value.quantity * ( +this.rate ),
                  tax: 'NA'
                } );
              }
            }
            this.item_count = 0;
            this.itemIdArr.push( result[ 'data' ].item_id );

            this.amount = 0;


            if ( this.amount == 0 )
            {
              for ( let i of this.itemArr )
              {
                this.amount = this.amount + ( +i.rate );
                this.EstimateService.setGrossAmount = this.amount;

              }
            }

            if ( this.estimate_id == null )
            {
              if ( this.NewEstimate.value.unit_price > 0 )
              {
                this.NewEstimate.controls.unit_price.setValue( 0 );
                this.NewEstimate.updateValueAndValidity();
              }
              if ( this.NewEstimate.value.quantity > 0 )
              {
                // this.amount = this.amount + ((+result['data'].rate) * this.NewEstimate.value.quantity);
              } else if ( this.NewEstimate.value.quantity == '' || this.NewEstimate.value.quantity == null )
              {
                this.NewEstimate.controls.quantity.setValue( 1 );
                // this.amount = this.amount + ((+result['data'].rate) * this.NewEstimate.value.quantity);
              }

              this.Calculate();
              this.NewEstimate.controls.quantity.setValue( 1 );
              this.NewEstimate.updateValueAndValidity();
            } else
            {
              if ( this.NewEstimate.value.unit_price > 0 )
              {
                this.NewEstimate.controls.unit_price.setValue( 0 );
                this.NewEstimate.updateValueAndValidity();
              }

              if ( this.NewEstimate.value.quantity > 0 )
              {
                // this.amount = this.amount + ((+result['data'].rate) * this.NewEstimate.value.quantity);
              } else if ( this.NewEstimate.value.quantity == '' || this.NewEstimate.value.quantity == null )
              {
                this.NewEstimate.controls.quantity.setValue( 1 );
                // this.amount = this.amount + ((+result['data'].rate) * this.NewEstimate.value.quantity);
              }


              this.Calculate();
              this.EstimateModel.amount = this.amount;
              this.NewEstimate.controls.quantity.setValue( 1 );
              this.NewEstimate.updateValueAndValidity();

            }
          }, ( err: HttpErrorResponse ) =>
          {
            if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
            {
              this.SettingService.LogOut();
            }
          } );
      }

      for ( let i in this.EstimateModel.items )
      {

        if ( this.EstimateModel.items[ i ].item_id == this.NewEstimate.value.line_item )
        {
          this.EstimateModel.items[ i ].qty = this.NewEstimate.value.quantity;
          this.EstimateModel.items[ i ].group = this.current_selected_item.group;
          this.EstimateModel.items[ i ].unit_price = this.rate;
          this.EstimateModel.items[ i ].tax = this.current_tax;
          this.model_item_count = 1;
          break;
        }
      }

      if ( this.model_item_count == 0 )
      {
        this.EstimateModel.items.push( {
          item_id: this.NewEstimate.value.line_item,
          group: this.current_selected_item.group,
          qty: addQuantityField.value || '1',
          unit_price: this.rate,
          tax: this.current_tax
        } );
      }

      this.model_item_count = 0;
    } else
    {
      return;
    }
   
    this.NewEstimate.get('line_item').setValue(null);
    this.NewEstimate.get('line_item').updateValueAndValidity();

    setTimeout(()=>{
      this._invoiceService.service_response_data.next(null);
      this._invoiceService.product_response_data.next(null);

    },0)
  }

  DeleteItem(index: number) {
    let spliced_item = this.itemArr.splice(index, 1);
    
    let estimate_amount = this.EstimateModel.items.splice(index, 1);
    if (this.EstimateModel.items.length == 0) {
      this.NewEstimate.controls.line_item.setValue('');

      this.NewEstimate.get('line_item').setValidators([Validators.required]);
      this.NewEstimate.get('line_item').updateValueAndValidity();

    }
    
    if(spliced_item[0].group=='Product'){
      for (let i of estimate_amount) {
        this.ItemService.GetItemById(i.item_id).subscribe(
          result => {
            if(i.tax){
              this.amount = this.amount - ((+i.unit_price +(+i.unit_price*(+i.tax/100)))* i.qty);
              this.EstimateService.setGrossAmount = this.amount;
              this.EstimateModel.gross_amount = this.amount; 

            }else{
              this.amount = this.amount - (+i.unit_price * i.qty);
              this.EstimateService.setGrossAmount = this.amount;
              this.EstimateModel.gross_amount = this.amount; 
            }
           
            this.Calculate()
          }, (err:HttpErrorResponse) => {
            if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
              this.SettingService.LogOut();
            }
          }
        )
      }
    }else if(spliced_item[0].group=='Service'){
      
      for (let i of estimate_amount) {
        this.serviceService.GetServiceById(i.service_id).subscribe(
          result => {
            if(i.tax){
              this.amount = this.amount - ((+i.unit_price +(+i.unit_price*(+i.tax/100)))* i.qty);
               this.EstimateService.setGrossAmount = this.amount;
              this.EstimateModel.gross_amount = this.amount; 
            }else{

              this.amount = this.amount - (+i.unit_price * i.qty);
               this.EstimateService.setGrossAmount = this.amount;
              this.EstimateModel.gross_amount = this.amount; 
            }
           
            
            this.Calculate()
          }, (err:HttpErrorResponse) => {
            if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            }
          }
        )
      }
    }
    
    
    // else{
    //   this.Calculate()
    // }
    let item_amount = this.itemIdArr.splice(index, 1);
  }

  dollarActive() {
    this.dollar = '$';
    this.percent = '';
  }

  percentActive() {
    this.dollar = '';
    this.percent = '%';
  }

  AdvanceInDollar(event) {
    this.dollarActive();
    if ((event.target.value != null || event.target.value != '')
      && event.target.name == 'advance_type') {
      if (this.NewEstimate.value.advance == null) {
        this.NewEstimate.get('advance').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);
        this.NewEstimate.get('advance').setValue('');
        this.NewEstimate.updateValueAndValidity();
      }
      this.Calculate();
    }
  }

  AdvanceInPercentage(event) {
    this.percentActive();
    if ((event.target.value != null || event.target.value != '')
      && event.target.name == 'advance_type') {
      if (this.NewEstimate.value.advance == null) {
        this.NewEstimate.get('advance').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);
        this.NewEstimate.get('advance').setValue('');
        this.NewEstimate.updateValueAndValidity();
      }
      this.Calculate();
    }
  }

  AdvanceCalclate(event) {

    if (event.target['value'] == null || event.target['value'] == '') {
      this.advance_value = false;
      this.NewEstimate.get('advance').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);
      this.NewEstimate.get('advance').setValue('');
      this.NewEstimate.updateValueAndValidity();
    }
    else {

      this.NewEstimate.get('advance_type').setValidators(Validators.required);
      this.NewEstimate.get('advance_type').updateValueAndValidity();
      if ((this.NewEstimate.value.advance != null
        || this.NewEstimate.value.advance != '')
        && (this.NewEstimate.value.advance_type != null
          || this.NewEstimate.value.advance_type != '')) {
        this.Calculate();
      }
    }

    if (this.NewEstimate.value.advance == null || this.NewEstimate.value.advance == '') {
      if (this.NewEstimate.value.advance_type == null || this.NewEstimate.value.advance_type == '') {
        this.NewEstimate.get('advance').clearValidators();
        this.NewEstimate.get('advance_type').clearValidators();
        this.NewEstimate.get('advance').updateValueAndValidity();
        this.NewEstimate.get('advance_type').updateValueAndValidity();
      }
    }
  }
  
  Discount(event) {
    if (event.target.value == null || event.target.value == '') {
      this.discount_value = false;
      this.NewEstimate.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);
      this.NewEstimate.get('discount').setValue('');
      this.NewEstimate.updateValueAndValidity();
      this.discount_amount = 0;
    }
    else {
      this.NewEstimate.get('discount_type').setValidators(Validators.required);
      this.NewEstimate.get('discount_type').updateValueAndValidity();
      if (this.NewEstimate.value.discount != null
        && this.NewEstimate.value.discount != ''
        && this.NewEstimate.value.discount_type != null
        && this.NewEstimate.value.discount_type != '') {
        this.Calculate();
      }
    }

    if (this.NewEstimate.value.discount == null || this.NewEstimate.value.discount == '') {
      if (this.NewEstimate.value.discount_type == null || this.NewEstimate.value.discount_type == '') {
        this.NewEstimate.get('discount').clearValidators();
        this.NewEstimate.get('discount_type').clearValidators();
        this.NewEstimate.get('discount').updateValueAndValidity();
        this.NewEstimate.get('discount_type').updateValueAndValidity();
      }
    }

    if (this.NewEstimate.value.discount_type != null && this.NewEstimate.value.discount_type != '') {
      this.Calculate();

    }
  }

  DiscountInPercentage(event) {
    this.percentActive();
    if ((event.target.value != null || event.target.value != '') && event.target.name == 'discount_type') {
      if (this.NewEstimate.value.discount == null) {
        this.NewEstimate.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);
        this.NewEstimate.get('discount').setValue('');
        this.NewEstimate.updateValueAndValidity();

      }


      if (this.NewEstimate.value.discount != null && this.NewEstimate.value.discount != '') {
        this.Calculate();
      }
    }
  }

  DiscountInDollar(event) {
    this.dollarActive();
    if ((event.target.value != null || event.target.value != '') && event.target.name == 'discount_type') {
      if (this.NewEstimate.value.discount == null) {
        this.NewEstimate.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);
        this.NewEstimate.get('discount').setValue('');
        this.NewEstimate.updateValueAndValidity();

      }


      if (this.NewEstimate.value.discount != null && this.NewEstimate.value.discount != '') {

        this.Calculate();

      }
    }
  }

  ValueReset(event) {
    if (event.target.name == 'discount') {
      if (this.NewEstimate.value.discount_type == null) {
        this.NewEstimate.get('discount_type').setValue('');
      }
    }
  }

  DiscountCalculate() {
    this.Calculate();
  }


  SalesTax(event, tax) {
    this.Calculate()
  }

  Step(step: number) {
    if (step == 2) {

      this.submitted = true;

      if (this.discount_value == true) {
        return;
      }
      if ((this.NewEstimate.controls.customer_id.invalid)
        || (this.NewEstimate.controls.unit_price.invalid)
        || (this.NewEstimate.controls.sales_tax.invalid)
        || (this.NewEstimate.controls.discount.invalid)
        || (this.NewEstimate.controls.discount_type.invalid)) {
          this.submitted = true;
        if(this.EstimateModel.items.length == 0){
          this.add_item = true;
        }
        return false;
      }
      
      if(this.EstimateModel.items.length==0){
        this.add_item=true;
        return;
      }
      if ( this.EstimateModel.items.length > 0 ){
        this.add_item = false;
      }
      let flag =0;
      if(this.NewEstimate.value.line_item){
        if(this.EstimateModel.items.length>0)
        for(let value of  this.EstimateModel.items){
          if(value.item_id == this.NewEstimate.value.line_item){

            flag =1;
            break;
            
          }else{
           flag =0;
          }
        }
        if(flag==0)
        {
          this.add_remove_item = true;
          return;
  
  
        }else{
          this.add_remove_item = false;
  
        }
      }
    }
    this.step = step;
  }

  Route() {
    const initialState: ModalOptions = { 
      initialState: {
        type: "Estimate",
        path: "estimate-list",
        ignoreBackdropClick: true,
      }
    };
    if (!this.estimate_id && 
        (this.NewEstimate.value.customer_id 
        || this.NewEstimate.value.discount
        || this.NewEstimate.value.discount_type
        || this.NewEstimate.value.sales_tax
        || (this.EstimateModel.items.length>0))) 
    {
      this.modalRef = this.modalService.show(SaveProgressWorkComponent, initialState);
      this.EstimateService.setEstimateDraftImages = this.files;
    } else {
      if (this.step === 2 || this._step == 2) {
        this.step = 1;
        this._step = 1;
      }
      else {
        this._router.navigate(['/estimate']);
      }
    }
  }

  addCustomer() {
    const initialState: ModalOptions = {
      initialState: {
        type: "Estimate",
        path: "customer",
        ignoreBackdropClick: true
      }
    };
    if(!this.estimate_id) {
      this.modalRef = this.modalService.show(SaveProgressWorkComponent, initialState);
    } else {
      this._router.navigate( [ '/customer-new' ] );
    }
  }

  addChecklist() {
    const initialState: ModalOptions = {
      initialState: {
        type: "Estimate",
        path: "checklist",
        ignoreBackdropClick: true
      }
    };
    if(!this.estimate_id) {
      this.modalRef = this.modalService.show(SaveProgressWorkComponent, initialState);
    } else {
      this._router.navigate( [ '/checklist-add' ] );
    }
  }

  addTax() {
    const initialState: ModalOptions = {
      initialState: {
        type: "Estimate",
        path: "tax-setting",
        ignoreBackdropClick: true
      }
    };
    if(!this.estimate_id) {
      this.modalRef = this.modalService.show(SaveProgressWorkComponent, initialState);
    } else {
      this._router.navigate( [ '/tax-settings' ] );
    }
  }

  get f() {
    return this.NewEstimate.controls;
  }

  AddEstimateDraft() {
    this.EstimateModel.customer_id = this.NewEstimate.value.customer_id;
      this.EstimateModel.items = this.EstimateModel.items;
      this.EstimateModel.discount = this.NewEstimate.value.discount;
      this.EstimateModel.discount_type = this.NewEstimate.value.discount_type;
      this.EstimateModel.sales_tax = this.NewEstimate.value.sales_tax;
      this.EstimateModel.due_date = this.NewEstimate.value.due_date;
      this.EstimateModel.advance = this.NewEstimate.value.advance;
      this.EstimateModel.advance_type = this.NewEstimate.value.advance_type;
      this.EstimateModel.note = this.NewEstimate.value.note;
      this.EstimateModel.private_note = this.NewEstimate.value.private_note;
      this.EstimateModel.total = this.total_amount;
      this.EstimateModel.estimate_number = this.transformedData;
      this.EstimateModel.checklist_id = this.NewEstimate.value.checklist_id;
      if(this.discount_amount==0 && this.salesTax_amount==0){
        this.EstimateModel.gross_amount = this.amount;
        this.EstimateService.setGrossAmount = this.amount;  
      }else if(this.discount_amount>0 && this.salesTax_amount==0){
        this.EstimateModel.gross_amount = this.discount_amount;
        this.EstimateService.setGrossAmount = this.discount_amount; 
      }else if(this.salesTax_amount>0){
        this.EstimateModel.gross_amount = this.salesTax_amount;
        this.EstimateService.setGrossAmount = this.salesTax_amount;
      }
      this.EstimateService.setEstimateDraft = this.EstimateModel;
  }


  AddEstimate() {
    this.submitted2 = true;
    if(this.advance_value == true){
      return;
    }
    if(this.discount_amount==0 && this.salesTax_amount==0){
      this.EstimateModel.gross_amount = this.amount; 
    }else if(this.discount_amount>0 && this.salesTax_amount==0){
      this.EstimateModel.gross_amount = this.discount_amount; 
    }else if(this.salesTax_amount>0){
      this.EstimateModel.gross_amount = this.salesTax_amount;
    }
    if ((this.NewEstimate.controls.due_date.invalid)
      || (this.NewEstimate.controls.advance.invalid)
      || (this.NewEstimate.controls.advance_type.invalid)
      || (this.NewEstimate.controls.note.invalid)
      || (this.NewEstimate.controls.private_note.invalid))
       {
      this.submitted = true;
      return;
    }
    if (this.estimate_id == null) {
      this.EstimateModel.customer_id = this.NewEstimate.value.customer_id;
      this.EstimateModel.items = this.EstimateModel.items;
      this.EstimateModel.discount = this.NewEstimate.value.discount;
      this.EstimateModel.discount_type = this.NewEstimate.value.discount_type;
      this.EstimateModel.sales_tax = this.NewEstimate.value.sales_tax;
      this.EstimateModel.due_date = this.NewEstimate.value.due_date;
      this.EstimateModel.advance = this.NewEstimate.value.advance;
      this.EstimateModel.advance_type = this.NewEstimate.value.advance_type;
      this.EstimateModel.note = this.NewEstimate.value.note;
      this.EstimateModel.private_note = this.NewEstimate.value.private_note;
      this.EstimateModel.total = this.total_amount;
      this.EstimateModel.is_draft_save = false;
      this.EstimateModel.estimate_number = this.transformedData;
      this.EstimateModel.checklist_id = this.NewEstimate.value.checklist_id;
      localStorage.setItem("estimate_checklist_id", JSON.stringify(this.EstimateModel.checklist_id))
      // this.loading=true;
      // this.EstimateService.AddEstimate(this.EstimateModel).subscribe(
      //   result => {
      //     if (result['success']) {
      //       this.estimate_id = result['data']['estimate_id'];
      //       this.showCreateSuccess(result['msg']);
      //       this.loading = false;
      //       this.add_item = false;
      //       this._router.navigate(['/estimate-send/', this.estimate_id, this.estimate_number]);
      //     } else {
      //       this.loading=false
      //       this.showCreateDanger(result['msg']);
      //     }
      //   }, (err:HttpErrorResponse) => {
      //     if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
      //       this.SettingService.LogOut();
      //     }
      //   }
      // )
      if (this.multiple_image_size_validation && this.multiple_image_extension_validation && this.image_extension) {
        this.loading = true;

        this.EstimateService.AddEstimate(this.EstimateModel)
          .subscribe((result) => {
            if (result['success']) {
              this.EstimateModel.items = [];
              this.itemArr = [];

              this.loading = false;
              this.estimate_id = result['data']['estimate_id'];
              this.EstimateModel.is_draft_save = false;
              this.EstimateService.setIsDraftSave = this.EstimateModel.is_draft_save;
              if (this.imagList.length > 0) {
                this.UploadImages(this.estimate_id,'Successfully Estimate Added!');
              } else {
                this.showCreateSuccess(result['msg']);
                this._router.navigate(['/estimate-self/', this.estimate_id, this.estimate_number]);
              }
            }else{
              this.showCreateDanger(result['msg']);
            }
          }, (err:HttpErrorResponse) => {
            if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
              this.SettingService.LogOut();
            }
          }
        );
      }
      else {
        return false;
      }
    }
    else {
      this.EstimateModel.due_date = new Date(this.NewEstimate.value.due_date);
      this.EstimateModel.total = this.total_amount;
      // this.loading=true;
      
      // this.EstimateService.Update(this.EstimateModel, this.estimate_id).subscribe(
      //   result => {
      //     if (result['success']) {
      //       this.estimate_id = result['data']['estimate_id'];
      //       this.showCreateSuccess(result['msg']);
      //       this.loading=false;
      //       this.add_item = false;
      //       this._router.navigate(['/estimate-send/', this.estimate_id, this.estimate_index]);
      //     } else {
      //       this.showCreateDanger(result['msg']);
      //     }
      //   }, (err:HttpErrorResponse) => {
      //     if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
      //       this.SettingService.LogOut();
      //     }
      //   }
      // )

      if (this.multiple_image_size_validation && this.multiple_image_extension_validation && this.image_extension) {
        this.loading = true;
        this.EstimateService.Update(this.EstimateModel, this.estimate_id)
          .subscribe((result) => {
            if (result['success']) {
              this.EstimateModel.items = [];
              this.itemArr = [];
              this.estimate_id = result['data']['estimate_id'];
              if (this.imagList.length > 0) {
                this.UploadImages(this.estimate_id,'Successfully Estimate Updated');
                this.loading = true;

              } else {
                this.loading1 = false;
                this.accepted1 = false;
                this.showCreateSuccess(result['msg']);
                this._router.navigate(['/estimate-self/', this.estimate_id, this.estimate_number]);
              }
            }else{
              this.showCreateDanger(result['msg']);
            }
          }, (err:HttpErrorResponse) => {
            if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
              this.SettingService.LogOut();
            }
          }
        )
      }
      else {
        return false;
      }
    }
  }

  getEstimateDetails(estimate_id) {
    this.loading = true;

    if (estimate_id) {
      this.EstimateService.GetEstimateDetails(estimate_id)
        .subscribe((result) => {
          if (result['success']) {
            this.loading = false;
            this.EstimateModel.customer_id = result['data'].customer_id;
            this.EstimateModel.estimate_id = result['data'].estimate_id;
            this.EstimateModel.total = result['data']['net_amount'];
            if(result['data']['estimate_customers']){
              this.CustomerModel.customer_id = result['data']['estimate_customers'][0]['customer_id'];
              this.CustomerModel.name = result['data']['estimate_customers'][0]['name'];
              
              this.CustomerModel.address = result['data']['estimate_customers'][0]['address'];
              this.CustomerModel.email = result['data']['estimate_customers'][0]['email'];
              this.CustomerModel.zip = result['data']['estimate_customers'][0]['zip'];
              this.CustomerModel.locality = result['data']['estimate_customers'][0]['locality'];
              this.CustomerModel.mobile = result['data']['estimate_customers'][0]['mobile'];
              this.CustomerModel.alternative_number = result['data']['estimate_customers'][0]['alternative_number'];
              this.alternative_number = result['data']['estimate_customers'][0]['alternative_number'];
            }
            if (result['data']['estimate_estimateitems'] != null || result['data']['estimate_estimateitems'].length > 0) {
              this.EstimateModel.line_item = result['data']['estimate_estimateitems'][0].item_id;
              this.NewEstimate.get('line_item').clearValidators();
              this.NewEstimate.get('line_item').updateValueAndValidity();
              for (let index of result['data']['estimate_estimateitems']) {
                if(index.group=='Product'){
                  if(index.estimateitems_items_with_trashed.tax_rate){
                    this.EstimateModel.items.push({
                      item_id: index.estimateitems_items_with_trashed.item_id,
                      qty: index.quantity,
                      unit_price: index.rate,
                      group: index.group,
                      tax: index.estimateitems_items_with_trashed.tax_rate_data.rate,
                    });
                    this.itemArr.push({
                      item_id: index.estimateitems_items_with_trashed.name,
                      qty: index.quantity,
                      unit_price: index.rate,
                      group: index.group,
                      rate: (index.quantity * (+index.rate+(+index.rate*(index.estimateitems_items_with_trashed.tax_rate_data.rate/100)))).toFixed(2),
                      tax: index.estimateitems_items_with_trashed.tax_rate_data.rate+'%', 
    
                    });
                    this.amount = this.amount + (index.quantity * (+index.rate+(+index.rate*(index.estimateitems_items_with_trashed.tax_rate_data.rate/100))));

                  }else{
                    this.EstimateModel.items.push({
                      item_id: index.estimateitems_items_with_trashed.item_id,
                      qty: index.quantity,
                      unit_price: index.rate,
                      group: index.group,
                    });
                    this.itemArr.push({
                      item_id: index.estimateitems_items_with_trashed.name,
                      qty: index.quantity,
                      rate: index.quantity*(+index.rate),
                      unit_price: index.rate,
                      group: index.group,
                      tax: 'NA'
                    });
                    this.amount = this.amount + (+index.rate * index.quantity);

                  }
                  
                }else if(index.group=='Service'){
                  if(index.estimateitems_service_with_trashed.tax_rate){
                    this.EstimateModel.items.push({
                      item_id: index.estimateitems_service_with_trashed.service_id,
                      qty: index.quantity,
                      unit_price: index.rate,
                      tax: index.estimateitems_service_with_trashed.tax_rate_data.rate, 
                      group: index.group,
                    });
                    this.itemArr.push({
                      item_id: index.estimateitems_service_with_trashed.name,
                      qty: index.quantity,
                      rate: (index.quantity * (+index.rate+(+index.rate*(index.estimateitems_service_with_trashed.tax_rate_data.rate/100)))).toFixed(2),
                      unit_price: index.rate,
                      group: index.group,
                      tax: index.estimateitems_service_with_trashed.tax_rate_data.rate+'%', 
    
    
                    });
                    this.amount = this.amount + (index.quantity * (+index.rate+(+index.rate*(index.estimateitems_service_with_trashed.tax_rate_data.rate/100))));

                  }else{
                    this.EstimateModel.items.push({
                      item_id: index.estimateitems_service_with_trashed.service_id,
                      qty: index.quantity,
                      unit_price: index.rate,
                      group: index.group,
                      tax:0
                    });
                    this.itemArr.push({
                      item_id: index.estimateitems_service_with_trashed.name,
                      qty: index.quantity,
                      rate: index.quantity*(+index.rate),
                      unit_price: index.rate,
                      group: index.group,
                      tax: 'NA', 
                    });
                    this.amount = this.amount + (+index.rate * index.quantity);

                  }
                  
                }
                
              }
            }
            this.EstimateModel.discount = result['data'].discount;
            this.EstimateModel.discount_type = result['data'].discount_type;
            if(this.EstimateModel.discount_type!=null && this.EstimateModel.discount_type!=''){
              if(this.EstimateModel.discount_type=='%'){
      
                this.isCheckedDiscountPercent=true;
                
              }else if(this.EstimateModel.discount_type=='$'){
      
                this.isCheckedDiscountDollar=true;
              }
            }
            this.EstimateModel.sales_tax = result['data'].sales_tax;
            this.EstimateModel.total = result['data'].net_amount;
            // this.EstimateModel.total = result['data']['estimate_estimateitems'][0].estimateitems_items.rate;
            this.EstimateModel.advance = result['data'].advance;
            this.EstimateModel.advance_type = result['data'].advance_type;
            if(this.EstimateModel.advance_type!=null && this.EstimateModel.advance_type!=''){
              if(this.EstimateModel.advance_type=='%'){
      
                this.isCheckedPercent=true;
              }else if(this.EstimateModel.advance_type=='$'){
      
                this.isCheckedDollar=true;
              }
            }
            this.EstimateModel.due_date = result['data'].due_date;
            this.EstimateModel.note = result['data'].note;
            this.EstimateModel.private_note = result['data'].private_note;
            this.EstimateModel.checklist_id = result['data'].checklist_id;
            this.NewEstimate.get('advance_type').setValue(result['data'].advance_type);
            this.NewEstimate.get('advance_type').updateValueAndValidity();
            this.NewEstimate.get('advance').setValue(result['data'].advance);
            this.NewEstimate.get('advance').updateValueAndValidity();
            this.NewEstimate.get('discount').setValue(result['data'].discount);
            this.NewEstimate.get('discount').updateValueAndValidity();
            this.NewEstimate.get('discount_type').setValue(result['data'].discount_type);
            this.NewEstimate.get('discount_type').updateValueAndValidity();
            this.NewEstimate.get('sales_tax').setValue(result['data'].sales_tax);
            this.NewEstimate.get('sales_tax').updateValueAndValidity();
            // this.EstimateModel.total = result['data'].net_amount;
            // this.EstimateModel.gross_amount = result['data'].gross_amount;
            this.EstimateModel.estimate_number = result['data'].estimate_number;
            this.total_amount = result['data'].net_amount;
            this.Calculate()         
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
    else {
      this.loading = false;
    }
  }

  unCheckPercent(evt: Event) {
    this.isCheckedPercent = !this.isCheckedPercent;
    this.isCheckedDollar = false;
    if (this.isCheckedPercent == false && (this.NewEstimate.value.advance == null || this.NewEstimate.value.advance == '')) {
      this.NewEstimate.get('advance_type').setValue('');
      this.NewEstimate.get('advance_type').clearValidators();
      this.NewEstimate.get('advance').clearValidators();

      this.NewEstimate.get('advance_type').updateValueAndValidity();
      this.NewEstimate.get('advance').updateValueAndValidity();
    } else if(this.isCheckedPercent == false && (this.NewEstimate.value.advance != null || this.NewEstimate.value.advance != '')){
      this.NewEstimate.get('advance_type').setValue('');
      this.NewEstimate.get('advance_type').updateValueAndValidity();

      this.NewEstimate.get('advance_type').setValidators(Validators.required);
      this.NewEstimate.get('advance').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);

      this.NewEstimate.get('advance_type').updateValueAndValidity();
      this.NewEstimate.get('advance').updateValueAndValidity();
    }else{
      this.NewEstimate.get('advance_type').setValidators(Validators.required);
      this.NewEstimate.get('advance').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);

      this.NewEstimate.get('advance_type').updateValueAndValidity();
      this.NewEstimate.get('advance').updateValueAndValidity();
    }
    this.Calculate();
  }

  unCheckDollar(evt: Event) {
    this.isCheckedDollar = !this.isCheckedDollar;
    this.isCheckedPercent = false;
    if (this.isCheckedDollar == false && (this.NewEstimate.value.advance == null || this.NewEstimate.value.advance == '')) {
      this.NewEstimate.get('advance_type').setValue('');
      this.NewEstimate.get('advance_type').clearValidators();
      this.NewEstimate.get('advance').clearValidators();

      this.NewEstimate.get('advance_type').updateValueAndValidity();
      this.NewEstimate.get('advance').updateValueAndValidity();
    } else if(this.isCheckedDollar == false && (this.NewEstimate.value.advance != null || this.NewEstimate.value.advance != '')){
      this.NewEstimate.get('advance_type').setValue('');
      this.NewEstimate.get('advance_type').updateValueAndValidity();

      this.NewEstimate.get('advance_type').setValidators(Validators.required);
      this.NewEstimate.get('advance').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);

      this.NewEstimate.get('advance_type').updateValueAndValidity();
      this.NewEstimate.get('advance').updateValueAndValidity();
    }else{
      this.NewEstimate.get('advance_type').setValidators(Validators.required);
      this.NewEstimate.get('advance').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);

      this.NewEstimate.get('advance_type').updateValueAndValidity();
      this.NewEstimate.get('advance').updateValueAndValidity();
    }

    this.Calculate();

  }

  UncheckDiscoutPercent(evt: Event) {
    this.isCheckedDiscountPercent = !this.isCheckedDiscountPercent;
    this.isCheckedDiscountDollar = false;
    if (this.isCheckedDiscountPercent == false && (this.NewEstimate.value.discount == null || this.NewEstimate.value.discount == '')) {
      this.NewEstimate.get('discount_type').setValue('');
      this.NewEstimate.get('discount_type').clearValidators();
      this.NewEstimate.get('discount').clearValidators();

      this.NewEstimate.get('discount_type').updateValueAndValidity();
      this.NewEstimate.get('discount').updateValueAndValidity();
    } else if(this.isCheckedDiscountPercent == false && (this.NewEstimate.value.discount != null || this.NewEstimate.value.discount != '')){
      this.NewEstimate.get('discount_type').setValue('');
      this.NewEstimate.get('discount_type').updateValueAndValidity();

      this.NewEstimate.get('discount_type').setValidators(Validators.required);
      this.NewEstimate.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);

      this.NewEstimate.get('discount_type').updateValueAndValidity();
      this.NewEstimate.get('discount').updateValueAndValidity();
    }else{
      this.NewEstimate.get('discount_type').setValidators(Validators.required);
      this.NewEstimate.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);

      this.NewEstimate.get('discount_type').updateValueAndValidity();
      this.NewEstimate.get('discount').updateValueAndValidity();
    }

    this.Calculate();

  }

  UncheckDiscoutDollar(evt: Event) {
    this.isCheckedDiscountDollar = !this.isCheckedDiscountDollar;
    this.isCheckedDiscountPercent = false;
    if (this.isCheckedDiscountDollar == false && (this.NewEstimate.value.discount == null || this.NewEstimate.value.discount == '')) {
      this.NewEstimate.get('discount_type').setValue('');
      this.NewEstimate.get('discount_type').clearValidators();
      this.NewEstimate.get('discount').clearValidators();

      this.NewEstimate.get('discount_type').updateValueAndValidity();
      this.NewEstimate.get('discount').updateValueAndValidity();
    } else if(this.isCheckedDiscountDollar == false && (this.NewEstimate.value.discount != null || this.NewEstimate.value.discount != '')){
      this.NewEstimate.get('discount_type').setValue('');
      this.NewEstimate.get('discount_type').updateValueAndValidity();

      this.NewEstimate.get('discount_type').setValidators(Validators.required);
      this.NewEstimate.
      get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);

      this.NewEstimate.get('discount_type').updateValueAndValidity();
      this.NewEstimate.get('discount').updateValueAndValidity();
    }else{
      this.NewEstimate.get('discount_type').setValidators(Validators.required);
      this.NewEstimate.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);

      this.NewEstimate.get('discount_type').updateValueAndValidity();
      this.NewEstimate.get('discount').updateValueAndValidity();
    }

    this.Calculate();

  }

  getProductServiceList () {
    if(!this.productServices.length) {
      this.toatr.warning("Please add product and services first");
    }

  }

  productServiceList(){
    this.EstimateService.GetProductServiceList(null).subscribe(
      result=>{
        this.List = result['data'];

        this.productServices = [
          ...this.List[0].map(
            (item: Object) => ({
              group: 'Service',
              name: item['name'],
              id: item['service_id'],
            })
          ),
          ...this.List[1].map(
            (item: Object) => ({
              group: 'Product',
              name: item['name'],
              id: item['item_id'],
            })
          )
        ]
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  // Procuct Inventory Management

  // UpdateProductInventory ( value )
  // {
  //   if ( value == 'create' )
  //   {
  //     for ( let i of this.EstimateModel.items )
  //     {
  //       i.method = 'create'
  //     }
  //     this.ItemService.UpdateItemInventory( this.EstimateModel ).subscribe(
  //       result =>
  //       {

  //       }
  //     )

  //   } else if ( value == 'update' )
  //   {
  //     for ( let i of this.EstimateModel.items )
  //     {
  //       i.method = 'update'
  //       if ( i.qty > i.previous_quantity )
  //       {
  //         let updatedItemQuantity = i.previous_quantity - ( +i.qty );
  //         i.previous_quantity = updatedItemQuantity;
  //       } else
  //       {
  //         let updatedItemQuantity = i.previous_quantity - ( +i.qty );
  //         if ( isNaN( updatedItemQuantity ) )
  //         {
  //           i.method = 'create'
  //           updatedItemQuantity = 0;
  //         }
  //         i.previous_quantity = updatedItemQuantity;

  //       }
  //     }
  //     this.ItemService.UpdateItemInventory( this.EstimateModel ).subscribe(
  //       result =>
  //       {

  //       }
  //     )


  //   }
  // }

  getWorkOrderImages(work_order_id) {
    this.WorkOrderService.GetFilesList(work_order_id).subscribe(
      result => {
        for (let index of result['data']) {
          this.work_order_images.push(index);
        }
      }
    )
  }

  getWorkOrderDetails(){
    this.loading = true;
    this.WorkOrderService.GetApprovedInvoiceData().subscribe(
      result => {
        this.loading = false;
        if (result) {
          this.EstimateModel.customer_id = result['customer_id'];
            this.EstimateModel.estimate_id = result['estimate_id'];
            if(result['workorder_customers']){
              this.CustomerModel.customer_id = result['workorder_customers'][0]['customer_id'];
              this.CustomerModel.name = result['workorder_customers'][0]['name'];
              this.CustomerModel.address = result['workorder_customers'][0]['address'];
              this.CustomerModel.email = result['workorder_customers'][0]['email'];
              this.CustomerModel.zip = result['workorder_customers'][0]['zip'];
              this.CustomerModel.locality = result['workorder_customers'][0]['locality'];
              this.CustomerModel.mobile = result['workorder_customers'][0]['mobile'];
              this.CustomerModel.alternative_number = result['workorder_customers'][0]['alternative_number'];
              this.alternative_number = result['workorder_customers'][0]['alternative_number'];
            }
            if (result['workorder_workorderitems'] != null || result['workorder_workorderitems'].length > 0) {
              this.EstimateModel.line_item = result['workorder_workorderitems'][0].item_id;
              this.NewEstimate.get('line_item').clearValidators();
              this.NewEstimate.get('line_item').updateValueAndValidity();
              for (let index of result['workorder_workorderitems']) {
                if(index.group=='Product'){
                  if(index.workorderitems_items.tax_rate){
                    this.EstimateModel.items.push({
                      item_id: index.item_id,
                      qty: index.quantity,
                      group: index.group,
                      unit_price: index.workorderitems_items.rate,
                      tax: index.workorderitems_items.tax_rate_data.rate,
                      previous_quantity: index.quantity,
                    });
                    this.itemArr.push({
                      item_id: index.workorderitems_items.name,
                      qty: index.quantity,
                      rate: ((+index.quantity) * (+index.workorderitems_items.rate+(+index.workorderitems_items.rate*((+index.workorderitems_items.tax_rate_data.rate)/100)))).toFixed(2),
                      unit_price: index.workorderitems_items.rate,
                      previous_quantity: index.quantity,
                      group: index.group,
                      tax: index.workorderitems_items.tax_rate_data.rate+'%'
    
                    });
                    this.amount = this.amount + (+index.quantity * (+index.workorderitems_items.rate+(+index.workorderitems_items.rate*(+index.workorderitems_items.tax_rate_data.rate/100))));
                  }else{
                    this.EstimateModel.items.push({
                      item_id: index.item_id,
                      qty: index.quantity,
                      group: index.group,
                      unit_price: index.workorderitems_items.rate,
                      previous_quantity: index.quantity,
                    });
                    this.itemArr.push({
                      item_id: index.workorderitems_items.name,
                      qty: index.quantity,
                      rate: index.quantity * (+index.workorderitems_items.rate),
                      unit_price: index.workorderitems_items.rate,
                      previous_quantity: index.quantity,
                      group: index.group,
                      tax: 'NA'
    
                    });
                    this.amount = this.amount + (index.quantity * (+index.workorderitems_items.rate));
                  }

                }else if(index.group=='Service'){
                  if(index.workorderitems_service.tax_rate){
                    this.EstimateModel.items.push({
                      item_id: index.item_id,
                      qty: index.quantity,
                      group: index.group,
                      unit_price: index.workorderitems_service.rate,
                      tax: index.workorderitems_service.tax_rate_data.rate,
                      previous_quantity: index.quantity,
                    });
                    this.itemArr.push({
                      item_id: index.workorderitems_service.name,
                      qty: index.quantity,
                      rate: ((+index.quantity) * (+index.workorderitems_service.rate+(+index.workorderitems_service.rate*((+index.workorderitems_service.tax_rate_data.rate)/100)))).toFixed(2),
                      unit_price: index.workorderitems_service.rate,
                      previous_quantity: index.quantity,
                      group: index.group,
                      tax: index.workorderitems_service.tax_rate_data.rate+'%'
    
                    });
                 
                    this.amount = this.amount + ((+index.quantity) * (+index.workorderitems_service.rate+(+index.workorderitems_service.rate*((+index.workorderitems_service.tax_rate_data.rate)/100))));
  
                  }else{
                    this.EstimateModel.items.push({
                      item_id: index.item_id,
                      qty: index.quantity,
                      group: index.group,
                      unit_price: index.workorderitems_service.rate,
                      previous_quantity: index.quantity,
                    });
                    this.itemArr.push({
                      item_id: index.workorderitems_service.name,
                      qty: index.quantity,
                      rate: index.quantity * (+index.workorderitems_service.rate),
                      unit_price: index.workorderitems_service.rate,
                      previous_quantity: index.quantity,
                      group: index.group,
                      tax: 'NA'
    
                    });
                    this.amount = this.amount + (index.quantity * (+index.workorderitems_service.rate));
  
                  }
                }
              }
            }
            this.EstimateModel.discount_type = result['discount_type'];
            if(this.EstimateModel.discount_type!=null && this.EstimateModel.discount_type!=''){
              if(this.EstimateModel.discount_type=='%'){
      
                this.isCheckedDiscountPercent=true;
              }else if(this.EstimateModel.discount_type=='$'){
      
                this.isCheckedDiscountDollar=true;
              }
            }
            this.EstimateModel.discount = result['discount'];
            this.EstimateModel.sales_tax = result['sales_tax'];
            this.EstimateModel.total = result['net_amount'];
            // this.EstimateModel.total = result['estimate_estimateitems'][0].estimateitems_items.rate'];
            this.EstimateModel.advance_type = result['advance_type'];
            if(this.EstimateModel.advance_type!=null && this.EstimateModel.advance_type!=''){
              if(this.EstimateModel.advance_type=='%'){
      
                this.isCheckedPercent=true;
              }else if(this.EstimateModel.advance_type=='$'){
      
                this.isCheckedDollar=true;
              }
            }
            this.EstimateModel.advance = result['advance'];
            if(this.EstimateModel.advance && this.EstimateModel.advance_type){
              this.NewEstimate.get('advance').setValue(this.EstimateModel.advance);
              this.NewEstimate.get('advance_type').setValue(this.EstimateModel.advance_type);
              this.NewEstimate.updateValueAndValidity();
            }
            this.EstimateModel.due_date = new Date(result['due_date']);

            // this.EstimateModel.due_date = result['due_date'];
            this.EstimateModel.note = result['note'];
            this.EstimateModel.private_note = result['private_note'];
            this.EstimateModel.estimate_number = this.transformedData;
            this.EstimateModel.work_order_update = true;
            this.EstimateModel.work_order_id = result['work_order_id']
            this.EstimateModel.checklist_id = result['checklist_id']
            this.getWorkOrderImages(result[ 'work_order_id' ]);
            this.CalculateTotalFromWorkOrderDetails();
        }
      }
    );
  }

  CalculateTotalFromWorkOrderDetails() {
    if (this.EstimateModel.discount_type != null && this.EstimateModel.discount_type != '' && this.EstimateModel.discount != null && this.EstimateModel.discount != '') {
      if (this.EstimateModel.discount_type == '%') {
        this.discount_amount = 0;
        this.discount_amount = (this.amount * (+this.EstimateModel.discount)) / 100;
        this.discount_amount = this.amount - this.discount_amount;
      } else if (this.EstimateModel.discount_type == '$') {
        this.discount_amount = 0;
        this.discount_amount = this.amount - (+this.EstimateModel.discount);
      }
    }


    if (this.EstimateModel.sales_tax != null || this.EstimateModel.sales_tax != '') {
      if (this.discount_amount == 0) {
        this.total_amount = this.amount * (1 + (this.EstimateModel.sales_tax / 100));
        this.total_amount=((this.total_amount * 10000)/10000).toFixed(2);
        this.salesTax_amount = this.total_amount;
      } else {
        this.total_amount = this.discount_amount * (1 + (this.EstimateModel.sales_tax / 100));
        this.total_amount=((this.total_amount * 10000)/10000).toFixed(2);
        this.salesTax_amount = this.total_amount;

      }

    }
    if ((this.EstimateModel.advance != null && this.EstimateModel.advance != '')
      && (this.EstimateModel.advance_type != null && this.EstimateModel.advance_type != '')) {
      if (this.EstimateModel.advance_type == "%") {
        this.total_amount = this.total_amount * (1 - this.EstimateModel.advance / 100);
        this.total_amount = ((this.total_amount * 10000) / 10000).toFixed(2);
      } else {
        this.total_amount = this.total_amount - this.EstimateModel.advance;
        this.total_amount = ((this.total_amount * 10000) / 10000).toFixed(2);
      }
    }
    

    if (this.discount_amount == 0 && this.total_amount == 0) {
      this.total_amount = this.amount;
    } else if (this.discount_amount > 0 && this.total_amount == 0) {
      this.total_amount = this.discount_amount;

    } else if (this.total_amount > 0) {
      this.total_amount = this.total_amount;
    }
  }

  // Getting item set in localstorage from product self page
  getProductFromProductPage() {    
    let data = JSON.parse(localStorage.getItem('product'));
    this.NewEstimate.get('line_item').setValue(data.id);
    this.NewEstimate.get('line_item').updateValueAndValidity();
    this.OnChangeItem(data,null);
  }


  showCreateSuccess(msg: string) {
    this.toatr.success(msg);
  }

  showCreateDanger(msg: string) {
    this.toatr.error(msg);
  }
  addProductservice(){
    this.modalRef = this.modalService.show(AddProductAndServiceComponent, {
      class: 'modal-lg'
    })
  }

  getProductServiceModalData() {
    const productDataSubscription = this._invoiceService.product_response_data.subscribe(
      (result) => {
        if (result) {
          if (result['group'] == 'Product') {
            let quantity_for_invoice = localStorage.getItem('quantity_for_invoice');
            this.NewEstimate.get('line_item').setValue(result['item_id']);
            this.NewEstimate.get('quantity').setValue(result['quantity_for_invoice']);
            this.NewEstimate.get('unit_price').setValue(result['rate']);
            this.current_selected_item = {
              group: result['group'],
              id: result['item_id'],
              name: result['name']
            }
            this.current_selected_item.group = result['group'];
            this.NewEstimate.get('line_item').updateValueAndValidity();
            this.NewEstimate.get('quantity').updateValueAndValidity();
            this.NewEstimate.get('unit_price').updateValueAndValidity();
            this.OnChangeItem(null,'modal')
            let item={
              'id': result['item_id'],
              'group': result['group'],
            }
            this.OnChangeItem(item,null);
            setTimeout(()=>{
              this.AddItem();
            },3000)
          }
        }
      }
    );
    
    this.subscriptions.push(productDataSubscription);
  }

  getServiceModalData() {
   const serviceDataSubscription =  this._invoiceService.service_response_data.subscribe(
      (result) => {
        if (result) {
          if (result['group'] == 'Service') {
            this.NewEstimate.get('line_item').setValue(result['service_id']);
            this.NewEstimate.get('quantity').setValue(1);
            this.NewEstimate.get('unit_price').setValue(result['rate']);
            this.current_selected_item = {
              group: result['group'],
              id: result['service_id'],
              name: result['name']
            }
            this.current_selected_item.group = result['group'];

            this.NewEstimate.updateValueAndValidity();
            this.OnChangeItem(null, 'modal')
            let item={
              'id': result['service_id'],
              'group': result['group'],
            }
            this.OnChangeItem(item,null);
            setTimeout(()=>{
              this.AddItem();
            },3000)
          }
        }
      }
    );
    this.subscriptions.push(serviceDataSubscription);
  }

  taxList: object[] = [];
  getLocationList(search){
    const data = {
      search: search.target.value
    }
    this.taxService.getLocationList(data).subscribe((response) => {
      this.taxList = response['data'];
      if (!this.taxList) {
        this.toatr.warning("Please add tax first");
      }
    })
  }
  
  setTaxRate(event){
    this.NewEstimate.get('sales_tax').setValue(event);
    this.NewEstimate.updateValueAndValidity();
    this.Calculate()
  }

  // Up & Down Arrow Function

  arrowDownBtn(index : number){
    const copy1 = [...this.itemArr];
    const moveTo1 = copy1.splice(index, 1)[0];
    copy1.splice(index +1, 0, moveTo1);
    this.itemArr = copy1;

    const copy2 = [...this.EstimateModel.items];
    const moveTo2 = copy2.splice(index, 1)[0];
    copy2.splice(index +1, 0, moveTo2);
    this.EstimateModel.items = copy2;
  }


  arrowUpBtn(index : number){
    const copy1 = [...this.itemArr];
    const moveTo1 = copy1.splice(index, 1)[0];
    copy1.splice(index - 1, 0, moveTo1);
    this.itemArr = copy1;
  
    const copy2 = [...this.EstimateModel.items];
    const moveTo2 = copy2.splice(index, 1)[0];
    copy2.splice(index - 1, 0, moveTo2);
    this.EstimateModel.items = copy2;
  }



  //  Selecting multiple files

   FilesSelected ( event )
   {
 
     this.files.push( ...event.addedFiles );
     this.EstimateService.setEstimateDraftImages = this.files;
    
     if ( this.files && this.files.length > 0 )
     {
       for ( var i = 0; i < this.files.length; i++ )
       {
        this.imagList[ i ] = this.files[ i ];
        this.additional_image_name = this.imagList[ i ].name;
        this.additionalFileExtension = this.additional_image_name.split( '.' ).pop();

        this.multiple_image_size = ( this.imagList[ i ].size / 1000 );

         if ( this.additionalFileExtension == 'jpeg' ||
           this.additionalFileExtension == 'JPEG' ||
           this.additionalFileExtension == 'png' ||
           this.additionalFileExtension == 'PNG' ||
           this.additionalFileExtension == 'jpg' ||
           this.additionalFileExtension == 'JPG' )
         {
           if ( this.multiple_image_size <= 4096 )
           {
             this.filesSizeMessage = "";
             this.multiplefileExtensionMessage = "";
             this.multiple_image_extension_validation = true;
             this.multiple_image_size_validation = true;
           } else
           {
             this.additional_image_name = this.imagList[ i ].name;
             this.filesSizeMessage = "Image can be maximum upto 4MB";
             this.multiple_image_size_validation = false;
             break;
           }
         } else
         {
           this.additional_image_name = this.imagList[ i ].name;
           this.multiplefileExtensionMessage = "Only .jpg or .jpeg or .png images are allowed"
           this.multiple_image_extension_validation = false;
           break;
         }
       }
 
 
     }
   }

   
  // Uploading multipl files
  onSelect ( event )
  {
    this.files.push( ...event.addedFiles );
  }

  onRemove ( event )
  {
    this.files.splice( this.files.indexOf( event ), 1 );

    if ( this.files && this.files.length > 0 )
    {
      for ( var i = 0; i < this.files.length; i++ )
      {
        let image_name = this.files[ i ].name;

        this.additionalFileExtension = image_name.split( '.' ).pop();

        this.multiple_image_size = ( this.files[ i ].size / 1000 );
        if ( this.additionalFileExtension == 'jpeg' ||
          this.additionalFileExtension == 'JPEG' ||
          this.additionalFileExtension == 'png' ||
          this.additionalFileExtension == 'PNG' ||
          this.additionalFileExtension == 'jpg' ||
          this.additionalFileExtension == 'JPG' )
        {
          if ( this.multiple_image_size <= 4096 )
          {
            this.multiple_image_extension_validation = true;
            this.multiple_image_size_validation = true;
            this.image_extension = true;

          } else
          {
            this.multiple_image_size_validation = false;
            this.additional_image_name = this.files[ i ].name;
            this.filesSizeMessage = "Image can be maximum upto 4MB";
            this.multiplefileExtensionMessage = "";
            this.multiple_image_extension_validation = true;
            this.image_extension = true;
            break;
          }
        } else
        {
          this.image_extension = false;

          this.additional_image_name = this.files[ i ].name;
          this.multiplefileExtensionMessage = "Only .jpg or .jpeg or .png images are allowed";
          this.filesSizeMessage = "";
          this.multiple_image_size_validation = true;


          break;
        }
      }

    } else
    {

      this.multiple_image_size_validation = true;
      this.multiple_image_extension_validation = true;
      this.image_extension = true;
      this.filesSizeMessage = "";
      this.multiplefileExtensionMessage = "";
    }

  }

  // Image Validation function

  FilesValidation () { }

  UploadImages ( estimate_id, msg ){
    this.loading = true;
    for ( var i = 0; i < this.files.length; i++ ){
      this._formdata.append( "logo[]", <string><unknown>this.files[ i ] );
    }

    this._formdata.append( "estimate_id", estimate_id );
    // this._formdata.append( "is_draft_save", <string><unknown>this.EstimateModel.is_draft_save )
    this.EstimateService.SaveFiles( this._formdata ).subscribe(
      (result) =>
      {
        if ( result[ 'success' ] )
        {
          this.loading = false;
          this.toatr.success(msg)
          this._router.navigate( [ '/estimate-self/', this.estimate_id, this.estimate_number ] );
        }
      }
    )
  }

  //Retrieving the images

  getEstimateImages ( estimate_id )
  {
    this.EstimateService.GetFilesList( estimate_id ).subscribe(
      result =>
      {
        for ( let index of result[ 'data' ] )
        {
          this.estimate_images.push( index );
        }
      }
    )
  }

  //Delete the file

  onDeleteConfirm ( estimate_image_id, index: number )
  {
    this.EstimateService.DeleteFile( estimate_image_id ).subscribe(
      result =>
      {
        if ( result[ 'success' ] )
        {
          this.toatr.success( 'File Deleted' );
          this.estimate_images.splice( index, 1 );

        } else
        {
          this.toatr.error( 'File Not Deleted' );
        }
      }, ( err: HttpErrorResponse ) =>
    {
      if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
      {
        this.SettingService.LogOut();
      }
    }
    )
  }

  public isChecklistNull: boolean = false;

  getCheckListItem(){
    this.AddChecklistService.GetCheckList().subscribe(
      (result)=>{
        if(result['data'].length) {
          this.isChecklistNull = false;
        }else {
          this.isChecklistNull = true;
        }
        this.checklistItem = result["data"];
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    )
  }

  getCheckLists(){
    if(this.isChecklistNull) {
      this.toatr.warning("Please add checklist first");
    }
  }

  deleteDraft() {
    this.EstimateModel.draft_reset = true;
    this.EstimateService.GetDraftData(this.EstimateModel.draft_reset).subscribe(
      (result) => {
        
        if (result['success']) {
          this.loading = false;
          this.estimateDraft.hide();
          // this.deleteEstimateDraftImages();
          this.EstimateModel.is_draft_save = false;
          this.EstimateService.setIsDraftSave = this.EstimateModel.is_draft_save;
        }
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    )
  }

  getDraftDetailsData() {
    this.EstimateModel.draft_reset = false;
      this.EstimateService.GetDraftData(this.EstimateModel.draft_reset).subscribe(
        (result) => {
          
          if (result['success']) { 
            this.EstimateModel.is_draft_save = result["data"].is_draft_save;
            this.EstimateService.setIsDraftSave = this.EstimateModel.is_draft_save;
          } else {
            this.loading = false;
          }
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
          }
        }
      )
  }

  getDraftData() {
    this.loading = true;
    this.EstimateModel.draft_reset = false;
    this.EstimateService.GetDraftData(this.EstimateModel.draft_reset).subscribe(
      (result) => {
        
        if (result['success']) {
          this.loading = false;
          this.estimateDraft.hide();
          this.getEstimateDraftImageList();
          this.EstimateModel.customer_id = result['data'].customer_id;
          this.EstimateModel.estimate_id = result['data'].estimate_id;
          this.EstimateModel.total = result['data']['net_amount'];
          if(result['data']['estimate_customers'].length > 0){
            this.CustomerModel.customer_id = result['data']['estimate_customers'][0]['customer_id'];
            this.CustomerModel.name = result['data']['estimate_customers'][0]['name'];
            
            this.CustomerModel.address = result['data']['estimate_customers'][0]['address'];
            this.CustomerModel.email = result['data']['estimate_customers'][0]['email'];
            this.CustomerModel.zip = result['data']['estimate_customers'][0]['zip'];
            this.CustomerModel.locality = result['data']['estimate_customers'][0]['locality'];
            this.CustomerModel.mobile = result['data']['estimate_customers'][0]['mobile'];
            this.CustomerModel.alternative_number = result['data']['estimate_customers'][0]['alternative_number'];
            this.alternative_number = result['data']['estimate_customers'][0]['alternative_number'];
          }
          if (result['data']['estimate_estimateitems'].length > 0) {
            this.EstimateModel.line_item = result['data']['estimate_estimateitems'][0].item_id;
            this.NewEstimate.get('line_item').clearValidators();
            this.NewEstimate.get('line_item').updateValueAndValidity();
            for (let index of result['data']['estimate_estimateitems']) {
              if(index.group=='Product'){
                if(index.estimateitems_items_with_trashed.tax_rate){
                  this.EstimateModel.items.push({
                    item_id: index.estimateitems_items_with_trashed.item_id,
                    qty: index.quantity,
                    unit_price: index.rate,
                    group: index.group,
                    tax: index.estimateitems_items_with_trashed.tax_rate_data.rate,
                  });
                  this.itemArr.push({
                    item_id: index.estimateitems_items_with_trashed.name,
                    qty: index.quantity,
                    unit_price: index.rate,
                    group: index.group,
                    rate: (index.quantity * (+index.rate+(+index.rate*(index.estimateitems_items_with_trashed.tax_rate_data.rate/100)))).toFixed(2),
                    tax: index.estimateitems_items_with_trashed.tax_rate_data.rate+'%', 

                  });
                  this.amount = this.amount + (index.quantity * (+index.rate+(+index.rate*(index.estimateitems_items_with_trashed.tax_rate_data.rate/100))));

                }else{
                  this.EstimateModel.items.push({
                    item_id: index.estimateitems_items_with_trashed.item_id,
                    qty: index.quantity,
                    unit_price: index.rate,
                    group: index.group,
                  });
                  this.itemArr.push({
                    item_id: index.estimateitems_items_with_trashed.name,
                    qty: index.quantity,
                    rate: index.quantity*(+index.rate),
                    unit_price: index.rate,
                    group: index.group,
                    tax: 'NA'
                  });
                  this.amount = this.amount + (+index.rate * index.quantity);

                }
                
              }else if(index.group=='Service'){
                if(index.estimateitems_service_with_trashed.tax_rate){
                  this.EstimateModel.items.push({
                    item_id: index.estimateitems_service_with_trashed.service_id,
                    qty: index.quantity,
                    unit_price: index.rate,
                    tax: index.estimateitems_service_with_trashed.tax_rate_data.rate, 
                    group: index.group,
                  });
                  this.itemArr.push({
                    item_id: index.estimateitems_service_with_trashed.name,
                    qty: index.quantity,
                    rate: (index.quantity * (+index.rate+(+index.rate*(index.estimateitems_service_with_trashed.tax_rate_data.rate/100)))).toFixed(2),
                    unit_price: index.rate,
                    group: index.group,
                    tax: index.estimateitems_service_with_trashed.tax_rate_data.rate+'%', 


                  });
                  this.amount = this.amount + (index.quantity * (+index.rate+(+index.rate*(index.estimateitems_service_with_trashed.tax_rate_data.rate/100))));

                }else{
                  this.EstimateModel.items.push({
                    item_id: index.estimateitems_service_with_trashed.service_id,
                    qty: index.quantity,
                    unit_price: index.rate,
                    group: index.group,
                    tax:0
                  });
                  this.itemArr.push({
                    item_id: index.estimateitems_service_with_trashed.name,
                    qty: index.quantity,
                    rate: index.quantity*(+index.rate),
                    unit_price: index.rate,
                    group: index.group,
                    tax: 'NA', 
                  });
                  this.amount = this.amount + (+index.rate * index.quantity);

                }
                
              }
              
            }
          }
          this.EstimateModel.discount = result['data'].discount;
          this.EstimateModel.discount_type = result['data'].discount_type;
          if(this.EstimateModel.discount_type!=null && this.EstimateModel.discount_type!=''){
            if(this.EstimateModel.discount_type=='%'){
    
              this.isCheckedDiscountPercent=true;
            }else if(this.EstimateModel.discount_type=='$'){
    
              this.isCheckedDiscountDollar=true;
            }
            if (this.EstimateModel.discount == null) {
              this.NewEstimate.get('discount').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);
              this.NewEstimate.get('discount').setValue('');
              this.NewEstimate.updateValueAndValidity();
            }
          }
          if(this.EstimateModel.discount!=null && this.EstimateModel.discount!=''){
            if (this.EstimateModel.discount_type == null) {
              this.NewEstimate.get('discount_type').setValidators(Validators.required);
              this.NewEstimate.updateValueAndValidity();
            }
          }
          this.EstimateModel.sales_tax = result['data'].sales_tax;
          this.EstimateModel.total = result['data'].net_amount;
          // this.EstimateModel.total = result['data']['estimate_estimateitems'][0].estimateitems_items.rate;
          this.EstimateModel.advance = result['data'].advance;
          this.EstimateModel.advance_type = result['data'].advance_type;
          if(this.EstimateModel.advance_type!=null && this.EstimateModel.advance_type!=''){
            if(this.EstimateModel.advance_type=='%'){
    
              this.isCheckedPercent=true;
            }else if(this.EstimateModel.advance_type=='$'){
    
              this.isCheckedDollar=true;
            }
            if (this.EstimateModel.advance == null) {
              this.NewEstimate.get('advance').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);
              this.NewEstimate.get('advance').setValue('');
              this.NewEstimate.updateValueAndValidity();
            }
          }
          if(this.EstimateModel.advance!=null && this.EstimateModel.advance!=''){
            if (this.EstimateModel.advance_type == null) {
              this.NewEstimate.get('advance_type').setValidators(Validators.required);
              this.NewEstimate.get('advance_type').updateValueAndValidity();
            }
          }
          if(result['data'].due_date) {
            this.EstimateModel.due_date = new Date(result['data'].due_date);
          }
          this.EstimateModel.note = result['data'].note;
          this.EstimateModel.private_note = result['data'].private_note;
          this.EstimateModel.checklist_id = result['data'].checklist_id;
          this.NewEstimate.get('advance_type').setValue(this.EstimateModel.advance_type);
          this.NewEstimate.get('advance_type').updateValueAndValidity();
          this.NewEstimate.get('advance').setValue(this.EstimateModel.advance);
          this.NewEstimate.get('advance').updateValueAndValidity();
          this.NewEstimate.get('discount').setValue(this.EstimateModel.discount);
          this.NewEstimate.get('discount').updateValueAndValidity();
          this.NewEstimate.get('discount_type').setValue(this.EstimateModel.discount_type);
          this.NewEstimate.get('discount_type').updateValueAndValidity();
          this.NewEstimate.get('sales_tax').setValue(this.EstimateModel.sales_tax);
          this.NewEstimate.get('sales_tax').updateValueAndValidity();
          // this.EstimateModel.total = result['data'].net_amount;
          // this.EstimateModel.gross_amount = result['data'].gross_amount;
          this.EstimateModel.estimate_number = result['data'].estimate_number;
          this.total_amount = result['data'].net_amount;
          this.Calculate()         
        } else {
          this.loading = false;
        }
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    )
  }

  getEstimateDraftImageList ()
  {
    this.loading = true;
    this.EstimateModel.draft_reset = false;
    this.EstimateService.GetDraftImageList(this.EstimateModel.draft_reset).subscribe(
      result =>
      {
        this.loading = false;
        for ( let index of result[ 'data' ] )
        {
          this.estimate_images.push( index );
          
        }
      }
    )
  }
}