import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from "src/app/shared/services/setting/setting.service";
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { SocialProfileService } from 'src/app/shared/services/social-profile/social-profile.service';

@Component({
  selector: 'app-social-profile',
  templateUrl: './social-profile.component.html',
  styleUrls: ['./social-profile.component.scss']
})
export class SocialProfileComponent implements OnInit {

  public loading: boolean = false;
  public submitted: boolean = null;
  public social_profile_id: string = null;

  public Newsocialprofile = this.fb.group({
    google_review_url: ["", Validators.required],
    yelp_review_url: [""]
  });

  constructor(
    public fb: FormBuilder,
    public toastr: ToastrService,
    public SocialProfileService : SocialProfileService,
    public SettingService: SettingService,
    public activateRoute: ActivatedRoute,
    public router: Router
  ) { 
    this.social_profile_id = this.activateRoute.snapshot.paramMap.get("social_profile_id");
  }

  ngOnInit() {
    this.getSocialProfileInfo();
  }

  get f() {
    return this.Newsocialprofile.controls;
  }

  CreateSocialProfile(){
    this.submitted = true;
    if(this.Newsocialprofile.invalid){
      return;
    }
    if(!this.socialProfileData){
      const data = {
        google_review_url: this.Newsocialprofile.value.google_review_url,
        yelp_review_url: this.Newsocialprofile.value.yelp_review_url
      }
      this.SocialProfileService.AddSocialProfile(data).subscribe(
        (result) => {
          
          if (result["success"]) {
            this.showSuccess(result["msg"]);
            this.router.navigate(["/settings"]);
          } else {
            this.showDanger(result["msg"]);
          }
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut()
          }
        }
      );
    }else if(this.socialProfileData != null){
      this.social_profile_id = this.socialProfileData['social_profile_id']
      const editData = {
        social_profile_id: this.social_profile_id,
        google_review_url: this.Newsocialprofile.value.google_review_url,
        yelp_review_url: this.Newsocialprofile.value.yelp_review_url
      }
      
      this.SocialProfileService.UpdateSocialProfile(this.social_profile_id, editData).subscribe(
        (result) => {
          if (result["success"]) {
            this.loading = false;
            this.showSuccess(result["msg"]);
            this.router.navigate(["/settings"]);
          } else {
            this.loading = false;
            this.showDanger(result["msg"]);
          }
        },
        (err: HttpErrorResponse) => {
          this.loading = false;
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut()
          }
        }
      );
    }
  }

  public socialProfileData: object = null;
  
  getSocialProfileInfo(){
    this.loading = true;
    this.SocialProfileService.GetSocialProfile().subscribe(
      (result) => {
        if (result["success"]) {
          this.loading = false;
          this.socialProfileData = result['data']
          
          this.Newsocialprofile.get('google_review_url').setValue(this.socialProfileData['google_review_url']);
          this.Newsocialprofile.get('yelp_review_url').setValue(this.socialProfileData['yelp_review_url']);
          this.Newsocialprofile.updateValueAndValidity();
        } else {
          this.loading = false;
          this.showDanger(result["msg"]);
        }
      },
      (err: HttpErrorResponse) => {
        this.loading = false;

        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    );
  }

  showSuccess(msg: string) {
    this.toastr.success(msg);
  }

  showDanger(msg: string) {
    this.toastr.error(msg);
  }

}
