import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ItemModel {
    public item_id:string = null;
    public service_id:string = null;
    public service_number:string = null;
    public item_number:string = null;
    public name:string = null;
    public description :string = null;
    public quantity:number = null;
    public service_type:any = null;
    public rate:string = null;
    public video_url :string = null;
    public is_active :boolean = true;
    public sku :any = null;
    public cost :any = null;
    public tax_rate :any = null;
    public tax_exemption :any = null;
    public part_no :any = null;
    public model_no :any = null;
    public serial_no: any = null;
    public show_in_widget: any = null;

}

