import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  public isAdvancePay: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public invoiceDraft: BehaviorSubject<object> = new BehaviorSubject({});
  public invoiceDraftImages: BehaviorSubject<any> = new BehaviorSubject([]);
  public totalAmount: BehaviorSubject<any> = new BehaviorSubject(0);
  public invoiceNumber: BehaviorSubject<any> = new BehaviorSubject(null);
  public product_response_data: BehaviorSubject<object> = new BehaviorSubject(null);
  public service_response_data: BehaviorSubject<object> = new BehaviorSubject(null);
  public grossAmount: BehaviorSubject<any> = new BehaviorSubject(0);
  public is_new_invoice:string = null;
  public isDraftSave: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public amount: BehaviorSubject<any> = new BehaviorSubject(0);

  constructor(
    public _http: HttpClient,
    public _TokenService: TokenService
    ) { }
    
  set setIsAdvancePay(value: boolean) {
    this.isAdvancePay.next(value)
  }

  set setInvoiceDraft(value: object) {
    this.invoiceDraft.next(value);
  }

  set setInvoiceDraftImages(value: object) {
    this.invoiceDraftImages.next(value);
  }

  set setTotalAmount(value: any) {
    this.totalAmount.next(value);
  }

  set setInvoiceNumber(value: any) {
    this.invoiceNumber.next(value);
  }

  set setGrossAmount(value: any) {
    this.grossAmount.next(value);
  }
  
  set setIsDraftSave(value: boolean) {
    this.isDraftSave.next(value);
  }

  set setAmount(value: any) {
    this.amount.next(value);
  }
  
  setInvoiceType(data: string){
    this.is_new_invoice  = data;
  }

   getInvoiceType(){
    return this.is_new_invoice;
  }

  Create(data): Observable<[]> {
    if (data.is_draft_save == 1 && data.due_date) {
      data.due_date = new Date(data.due_date.getTime() - data.due_date.getTimezoneOffset() * 60000);
    }if (data.is_draft_save == 1 && !data.due_date) {
      data.due_date = null;
    }if (data.is_draft_save == 0) {
      data.due_date = new Date(data.due_date.getTime() - data.due_date.getTimezoneOffset() * 60000);
    }
    let URL = `${environment.url}invoice/create`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  Edit(data: any, invoice_id: string): Observable<[]> {
    let URL = `${environment.url}invoice/edit/${invoice_id}`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  EditInvoice(data: any, invoice_id: string): Observable<[]> {
    
    data.due_date = new Date(data.due_date.getTime() - data.due_date.getTimezoneOffset() * 60000);

    let URL = `${environment.url}invoice/edit/${invoice_id}`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  Delete(invoice_id: string): Observable<[]> {
    let URL = `${environment.url}invoice/delete/${invoice_id}`;

    return this._http.post(URL, invoice_id, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  Charge(data): Observable<[]> {
    let URL = `${environment.url}invoice/payment`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  SquareCharge(data): Observable<[]> {
    let URL = `${environment.url}invoice/square-payment`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  AuthorizeNetCharge(data): Observable<[]> {
    let URL = `${environment.url}invoice/authorize-payment`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  GetCustomerList(): Observable<[]> {
    let URL = `${environment.url}customer/list`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  GetItemList(): Observable<[]> {
    let URL = `${environment.url}item/list`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  ViewListData(item_id: any): Observable<[]> {
    let URL = `${environment.url}item/view/${item_id}`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }
  
  InvoiceView(invoice_id: any) :Observable<[]> {
    let URL = `${environment.url}invoice/view/${invoice_id}`;

    return this._http.get(URL, {
    }).pipe(map((res: any) => res));
  }

  InvoiceList(): Observable<[]> {
    let URL = `${environment.url}invoice/list`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  InvoicePaidList(): Observable<[]> {
    let URL = `${environment.url}invoice/paid-list`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  InvoiceUnpaidList(): Observable<[]> {
    let URL = `${environment.url}invoice/unpaid-list`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  InvoiceLatestList(): Observable<[]> {
    let URL = `${environment.url}invoice/latest-list`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  GetSearchedResult(data): Observable<[]> {
    let URL = `${environment.url}customer/home-search`;

    return this._http.post(URL, data,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  
  GetOrganizationDetails(organization_id: any) :Observable<[]> {
    let URL = `${environment.url}account/view/${organization_id}`;

    return this._http.get(URL, {
    }).pipe(map((res: any) => res));
  }

  SetTemplate(data) :Observable<[]> {
    
    let URL = `${environment.url}account/edit`;

    return this._http.post(URL, data,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  
  Report():Observable<[]> {
    let URL = `${environment.url}invoice/invoice-report`;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  SendIvoiceViaEmail(Email):Observable<[]> {
    let URL = `${environment.url}mail/send-invoice/${Email.invoice_id}`;

    return this._http.post(URL, Email, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  SendIvoiceViaSMS(Email):Observable<[]> {
    let URL = `${environment.url}message/send-message/${Email.invoice_id}`;

    return this._http.post(URL, Email, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  SaveFiles(_formdata):Observable<[]> {
    let URL = `${environment.url}invoice_image/create`;

    return this._http.post(URL, _formdata, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  GetFilesList(invoice_id):Observable<[]> {
    let URL = `${environment.url}invoice_image/list/${invoice_id}`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }
  DeleteFile(invoice_image_id):Observable<[]> {
    let URL = `${environment.url}invoice_image/delete/${invoice_image_id}`;

    return this._http.post(URL,invoice_image_id, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

 

  GetCashReport():Observable<[]> {
    let URL = `${environment.url}invoice/payment-report-by-cash`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  GetCardReport():Observable<[]> {
    let URL = `${environment.url}invoice/payment-report-by-card`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  SalesReport(user_id):Observable<[]> {
    let URL = environment.url+'invoice/sales-report/'+user_id;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetProductServiceList(data):Observable<[]> {
    let URL = `${environment.url}poducts-services-list`;

    return this._http.post(URL, null,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  Recurring(invoice_id):Observable<[]> {
    let URL = `${environment.url}invoice/invoice-duplicate/${invoice_id}`;

    return this._http.post(URL, invoice_id,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  set setProductData(data) {
    this.product_response_data.next(data);
  }

  set setServiceData(data) {
    this.service_response_data.next(data);
  }

  get getProductData(){

    return this.product_response_data.value;
  }

  get getServiceData() {
    return this.service_response_data.value;
  }

  exportCSV(data):Observable<[]> {
    data.start_date = new Date(data.start_date.getTime() - data.start_date.getTimezoneOffset() * 60000);
    data.end_date = new Date(data.end_date.getTime() - data.end_date.getTimezoneOffset() * 60000);

    let URL = `${environment.url}quickbook/invoices`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),
      // responseType:ResponseContentType.Blob
    })
      .pipe(map((res: any) => res));
  }

  AddSaveAsDraft(data): Observable<[]> {
    data.due_date = new Date(data.due_date.getTime() - data.due_date.getTimezoneOffset() * 60000);
    let URL = `${environment.url}invoice/create`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  UpdateSaveAsDraft(data: any, invoice_id: string): Observable<[]> {
    data.due_date = new Date(data.due_date.getTime() - data.due_date.getTimezoneOffset() * 60000);

    let URL = `${environment.url}invoice/edit/${invoice_id}`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  GetDraftData(draft_reset):Observable<[]> {
    // draft_reset = Number(draft_reset);
    let URL = environment.url+ 'invoice/draft-view/'+draft_reset;
    
    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }

  GetDraftImageList(draft_reset):Observable<[]> {
    // draft_reset = Number(draft_reset);
    let URL = environment.url+ 'invoice_image/draft-image-list/'+draft_reset;
    
    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res)); 
  }
}

