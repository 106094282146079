import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ReportModel {
   first_week:number=null;
   second_week:number=null;
   third_week:number=null;
   fourth_week:number=null;
   fifth_week:number=null;
   first_amount: number=null;
   second_amount: number=null;
   third_amount: number=null;
   fourth_amount: number=null;
   fifth_amount: number=null;

}   

