import { HttpErrorResponse } from "@angular/common/http";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
// import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as ClassicEditorBuild from "@ckeditor/ckeditor5-build-classic";
import { ToastrService } from "ngx-toastr";
import { throwError } from "rxjs";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { ServiceAgreementService } from "src/app/shared/services/service-agreement/service-agreement.service";
import { SettingService } from "src/app/shared/services/setting/setting.service";

@Component({
  selector: "app-service-agreement-template-content-editor",
  templateUrl: "./service-agreement-template-content-editor.component.html",
  styleUrls: ["./service-agreement-template-content-editor.component.scss"],
})
export class ServiceAgreementTemplateContentEditorComponent implements OnInit {
  public Editor = ClassicEditorBuild;
  public template_name: any = null;
  public  userData: any = null;
  public content: any = null;

  public editor = this.fb.group({
    template_name: [null],
    content: [null],

  })
  constructor(public fb: FormBuilder,
    public activateRoute: ActivatedRoute,
    public serviceagreementService: ServiceAgreementService,
    public toastr: ToastrService,
    public SettingService: SettingService,
    public Auth: AuthService,
    public router: Router) {
    this.template_name = this.activateRoute.snapshot.paramMap.get('template_name');
    this.userData = this.Auth.User().user;    
    this.getTemplateView()
  }

  ngOnInit() {}

  SaveContent(){
    let editor_edit_data={
      template_name: this.editor.value.template_name,
      content: this.editor.value.content,
      created_by: this.userData.user_id,
      organization_id: this.userData.organization_id,
    }
    
    this.serviceagreementService.EditTemplate(editor_edit_data).subscribe(
      result=>{
        if(result['success']){
          this.toastr.success(result['msg']);
          this.router.navigate(['/service-agreements-templates']);
        }else{
          this.toastr.error(result['msg']);
        }
      },(err:HttpErrorResponse)=>{
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  getTemplateView() {
    let data = {
      template_name: this.template_name,
      user_id: this.Auth.User().user.user_id,
      organization_id: this.Auth.User().user.organization_id
    }
    this.serviceagreementService.viewTemplate(data).subscribe(
      result => {
        this.content = result['data'].content;
      },(err:HttpErrorResponse)=>{
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }
}
