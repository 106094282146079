import { Component, OnInit, ViewChild } from '@angular/core';
import { Sidebar, AccountModel, InvoiceModel, PaymentModel, CustomerModel, EstimateModel, PaymentSettings } from 'src/app/shared';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { timer } from 'rxjs'
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { EstimateService } from 'src/app/shared/services/estimate/estimate.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SignatureComponent } from 'src/app/layout';
import { SignatureService } from 'src/app/shared/services/signature/signature.service';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BroadcastChannel } from 'broadcast-channel';
import { WorkOrderService } from 'src/app/shared/services/work-order/work-order.service';
import { WorkOrderModel } from 'src/app/shared/models/work-order/work-order.model';
import { HttpErrorResponse } from '@angular/common/http';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { Subscription } from 'rxjs';

declare type Message = {
  message: string;
};

@Component({
  selector: 'app-public-template-one',
  templateUrl: './public-template-one.component.html',
  styleUrls: ['./public-template-one.component.scss']
})
export class PublicTemplateOneComponent implements OnInit {
  // public _invoiceData: InvoiceModel[] = [];
  public _customerData: CustomerModel = new CustomerModel();
  public payment_status: any = null;
  public date: Date = null;
  public invoice_id: string = null;
  public item_total: number = 0;
  public discount: any = 0;
  public sales_tax: any = 0;
  public advance: any = 0;
  public amount: any = 0;
  public loading: boolean = false;
  public accepted: boolean = false;
  public estimate_id: string = null;
  public work_order_id: string = null;
  public _workOrderData: any = null;
  public section: string = null;
  public estimate_items: any[] = [];
  public work_order_items: any[] = [];
  public invoice_items: any[] = [];
  public alternative_number: any[] = [];
  public _invoiceData: any = null;
  public InvoiceDetails: InvoiceModel = new InvoiceModel();
  public organization_id:string=null;
  public EstimateDetails: EstimateModel = new EstimateModel();
  public WorkOrderModel: WorkOrderModel = new WorkOrderModel();
  public PaymentData: PaymentSettings = new PaymentSettings();
  public PaymentModel: PaymentModel = new PaymentModel();
  public BC: BroadcastChannel<Message> = null //new BroadcastChannel('payment');
  public BC2: BroadcastChannel<Message> = new BroadcastChannel('approved');
  public BC3: BroadcastChannel<Message> = new BroadcastChannel('advance-paid');
  public advancePaid = false;
  public emailString: string = null;


  public modalRef: BsModalRef;

  public user_name: string  = null;
  public user_email: string  = null;
  public secret_key: any = null;

  @ViewChild('advancePayment', { static: true }) advancePayment: ModalDirective;

  elements: Elements;
  card: StripeElement;
  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  constructor(
    public _sidebar: Sidebar,
    public _Auth: AuthService,
    public InvoiceService: InvoiceService,
    public activatedRoute: ActivatedRoute,
    public _invoiceService: InvoiceService,
    public CustomerService: CustomerService,
    public SettingSerrvice: SettingService,
    public EstimateService: EstimateService,
    public WorkOrderService: WorkOrderService,
    public _DomSanitizer: DomSanitizer,
    public SignatureService:SignatureService,
    private _modal: BsModalService,
    public toastr: ToastrService,
    public _router:Router,
    private stripeService: StripeService,
    ) {
      this.section = this.activatedRoute.snapshot.paramMap.get('section');

      if(this.section=='estimate'){
        
        this.estimate_id = this.activatedRoute.snapshot.paramMap.get('invoice_id');
  
      }else if(this.section=='invoice'){
  
        this.invoice_id = this.activatedRoute.snapshot.paramMap.get('invoice_id');
  
      }
      else if(this.section=='work_order'){
  
        this.work_order_id = this.activatedRoute.snapshot.paramMap.get('invoice_id');
  
      }
      
      if (this.invoice_id != null && this.invoice_id != '') {
        this.getInvoiceDetails(this.invoice_id);
      }else if(this.estimate_id!=null && this.estimate_id!=''){
  
        this.getEstimateDetails(this.estimate_id);
  
  
      }else if(this.work_order_id!=null && this.work_order_id!=''){
        this.getWorkOrderDetails(this.work_order_id);
      }

    this.SignatureService.sendData(this.invoice_id);


    this.channel();
    this.BC.onmessage = (e) => {
      if (e.message) {
        location.reload();
      }
    }
  }

  public AccountData: AccountModel = new AccountModel();

  ngOnInit() {
    window.scroll(0, 0);
    let _data = localStorage.getItem("payment_data");
    let parse_data = JSON.parse(_data);
    // this.EstimateService.setApproveInvoiceState = false;
    // this.InvoiceService.setIsAdvancePay = false;

  }

  // async getPaymentKey() {
  //   let _data = await localStorage.getItem("payment_data");
  //   let parse_data = JSON.parse(_data);
  // }

  public subsciption: Subscription;
  
  ngAfterViewInit() {
    this.stripeService.elements(this.elementsOptions)
    .subscribe(elements => {
        
        this.elements = elements;
        
        // Only mount the element the first time
        let style = {
          base: {
            iconColor: '#666EE8',
            color: '#31325F',
            lineHeight: '50px',
            fontWeight: 300,
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSize: '18px',
            '::placeholder': {
              color: '#CFD7E0'
            }
          },
          invalid: {
            '::placeholder': {
              color: '#d00',
            },
          },
          placeholder: 'Zip',
        }
  
        if (!this.card) {
          this.card = this.elements.create('cardNumber', {
            style,
          });
          this.card.mount('#stripe-cardNumber');
  
          this.card = this.elements.create('cardExpiry', {
            style,
          });
          this.card.mount('#stripe-cardExpiry');
  
          this.card = this.elements.create('cardCvc', {
            style,
          });
          this.card.mount('#stripe-cardCvc');
  
          this.card = this.elements.create('postalCode', {
            style,
            placeholder: 'Zip',
          });
          this.card.mount('#stripe-cardZip');
        }
    });

    // this.subsciption = this.EstimateService.approveInvoiceState.subscribe((response) => {
    //   if(response) {
    //     this.getEstimateDetails(this.estimate_id);
    //   }
    // })

    // this.subsciption = this.InvoiceService.isAdvancePay.subscribe((response) => {
    //   if(response) {
    //     this.getInvoiceDetails(this.invoice_id);
    //   }
    // })

  }

  closeModal() {
    this.advancePayment.hide();
  }

  channel() {
    this.BC = new BroadcastChannel('payment', {
      idb: {
        onclose: () => {
          this.BC.close();
          this.channel();
        }
      }
    });
  }
  
  getOrganizationDetails(organization_id) {
    this.InvoiceService.GetOrganizationDetails(organization_id).subscribe(
      result => {
        if (result['success']) {
          this.AccountData.organization_id = result['data']['account_id'];
          this.AccountData.business_name = result['data']['business_name'];
          this.AccountData.email = result['data']['email'];
          this.AccountData.address_line_1 = result['data']['address_line_1'];
          this.AccountData.address_line_2 = result['data']['address_line_2'];
          this.AccountData.logo = result['data']['logo'];
        }
      }
    )
  }

  getInvoiceDetails(invoice_id) {
    this._invoiceService.InvoiceView(invoice_id).subscribe(
      (result) => {

        this._invoiceData = result['data'];
        if (this._invoiceData['invoice_invoiceitems'] != null && this._invoiceData['invoice_invoiceitems'].length > 0) {
          this.invoice_items = this._invoiceData['invoice_invoiceitems'];
        }
        this.item_total = +result['data']['amount'];
        
        this.InvoiceDetails.amount = result['data'].total;
        // this.InvoiceDetails.amount = Math.round(this.InvoiceDetails.amount );
        this.InvoiceDetails.invoice_number = result['data'].invoice_number;
        this.InvoiceDetails.due_date = result['data'].due_date;
        this.InvoiceDetails.discount = result['data'].discount;
        this.InvoiceDetails.discount_type = result['data'].discount_type;
        this.InvoiceDetails.advance = result['data'].advance;
        this.InvoiceDetails.advance_type = result['data'].advance_type;
        this.InvoiceDetails.sales_tax = result['data'].sales_tax;
        this.InvoiceDetails.customer_id = result['data']['customer_id'];
        this.InvoiceDetails.created_at = result['data']['created_at'];
        this.InvoiceDetails.updated_at = result['data']['updated_at'];
        this.InvoiceDetails.amount = result['data']['amount'];
        this.InvoiceDetails.total = result['data']['total'];
        this.payment_status = result['data']['status'];
        this.organization_id=result['data']['organization_id'];
        this.InvoiceDetails.signature = result['data']['invoice_signature'].signature;
        this.InvoiceDetails.is_signed = result['data']['invoice_signature'].is_signed;
        this.InvoiceDetails.note = result['data']['note'];
        this.InvoiceDetails.net_amount = result['data']['net_amount'];
        this.InvoiceDetails.gross_amount = result['data']['gross_amount'];
        this.InvoiceDetails.advance_paid = result['data']['advance_paid'];
        if(this.InvoiceDetails.advance_paid == true) {
          this.advancePaid = true;
        }
        this.getOrganizationDetails(this.organization_id);
        this.getPaymentData(this.organization_id);
        

        if (this.InvoiceDetails.discount != null && this.InvoiceDetails.discount != '') {
          if (this.InvoiceDetails.discount_type == '%') {
            this.discount = (this.item_total * (+this.InvoiceDetails.discount)) / 100;

            this.amount = (+this.item_total) - this.discount;
          } else if (this.InvoiceDetails.discount_type == '$') {
            this.discount = (+this.InvoiceDetails.discount);
            this.amount = this.item_total - (+this.InvoiceDetails.discount);
          }
        }

        if (this.InvoiceDetails.sales_tax != null && this.InvoiceDetails.sales_tax != '') {
          if (this.discount == 0) {
            this.sales_tax = (this.item_total * (+this.InvoiceDetails.sales_tax)) / 100;
            this.amount = this.item_total + this.sales_tax;


          } else {
            this.sales_tax = (this.amount * (+this.InvoiceDetails.sales_tax)) / 100;
            this.amount = this.amount + this.sales_tax;
          }

        }

        if (this.InvoiceDetails.advance != null && this.InvoiceDetails.advance != '') {
          if (this.discount == 0 && this.sales_tax == 0) {

            if (this.InvoiceDetails.advance_type == '%') {
              this.advance = (this.item_total * (+this.InvoiceDetails.advance)) / 100;
            } else if (this.InvoiceDetails.advance_type == '$') {
              this.advance =  (+this.InvoiceDetails.advance);
              // this.advance = this.item_total - (+this.InvoiceDetails.advance);
            }
          } else if (this.discount == 0 && this.sales_tax != 0) {

            if (this.InvoiceDetails.advance_type == '%') {

              this.advance = (this.amount * (+this.InvoiceDetails.advance)) / 100;

            } else if (this.InvoiceDetails.advance_type == '$') {
              this.advance =  (+this.InvoiceDetails.advance);
              // this.advance = this.amount - (+this.InvoiceDetails.advance);
            }
          } else if (this.discount != 0 && this.sales_tax == 0) {

            if (this.InvoiceDetails.advance_type == '%') {
              this.advance = (this.amount * (+this.InvoiceDetails.advance)) / 100;
            } else if (this.InvoiceDetails.advance_type == '$') {
              this.advance =  (+this.InvoiceDetails.advance);
              // this.advance = this.amount - (+this.InvoiceDetails.advance);
            }
          } else {
            if (this.InvoiceDetails.advance_type == '%') {

              this.advance = (this.amount * (+this.InvoiceDetails.advance)) / 100;
            } else if (this.InvoiceDetails.advance_type == '$') {
              this.advance =  (+this.InvoiceDetails.advance);
              // this.advance = this.amount - (+this.InvoiceDetails.advance);
            }
          }
        }
        this.discount = parseFloat(this.discount).toFixed(2);
        this.advance = parseFloat(this.advance).toFixed(2);
        this.sales_tax = parseFloat(this.sales_tax).toFixed(2);
        // this.item_total = parseFloat(this.item_total).toFixed(2);

          this.getCustomerDetails(result['data'].invoice_customers[0]);
      }
    )
  }

  modalOpen() {

    this.modalRef=this._modal.show(SignatureComponent);
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        location.reload();
      }
    })
  }

  getEstimateDetails(estimate_id) {

    this.EstimateService.GetEstimateDetails(estimate_id).subscribe(
      (result) => {
        
        this.EstimateDetails.amount = result['data'].total;
        this.EstimateDetails.estimate_id = result['data'].estimate_id;
        this.EstimateDetails.items = result['data'].estimate_estimateitems;
        this.EstimateDetails.estimate_number = result['data'].estimate_number;
        this.EstimateDetails.due_date = result['data'].due_date;
        this.EstimateDetails.discount = result['data'].discount;
        this.EstimateDetails.discount_type = result['data'].discount_type;
        this.EstimateDetails.advance = result['data'].advance;
        this.EstimateDetails.advance_type = result['data'].advance_type;
        this.EstimateDetails.sales_tax = result['data'].sales_tax;
        this.EstimateDetails.customer_id = result['data']['customer_id'];
        this.EstimateDetails.created_at = result['data']['created_at'];
        this.EstimateDetails.updated_at = result['data']['updated_at'];
        this.EstimateDetails.is_approved = result['data']['is_approved'];
        this.EstimateDetails.gross_amount  = result['data']['gross_amount'];
        this.EstimateDetails.net_amount  = result['data']['net_amount'];
        this.EstimateDetails.note  = result['data']['note'];
        this.EstimateDetails.advance_paid  = result['data']['advance_paid'];
        this.payment_status = result['data']['status'];
        this.organization_id = result['data']['organization_id'];
        if (this.EstimateDetails.items != null && this.EstimateDetails.items.length > 0) {
          this.estimate_items = this.EstimateDetails.items;
          for (let i of this.estimate_items) {
            if(i.group=='Product'){
              if(i.estimateitems_items_with_trashed.tax_rate){
                this.item_total = this.item_total+ i.quantity * (+i.rate+(+i.rate*(i.estimateitems_items_with_trashed.tax_rate_data.rate/100)));
              }else{
                this.item_total = this.item_total + (+i.rate * i.quantity);
              }
            }else if(i.group=='Service'){
              if(i.estimateitems_service_with_trashed.tax_rate){
                this.item_total = this.item_total+ i.quantity * (+i.rate+(+i.rate*(i.estimateitems_service_with_trashed.tax_rate_data.rate/100)));
              }else{
                this.item_total = this.item_total + (+i.rate * i.quantity);
              }
            }

          }
        }
        
        this.getOrganizationDetails(this.organization_id);
        this.getPaymentData(this.organization_id);
        this.getUser(result['data'].user_id)

        if (this.EstimateDetails.discount != null && this.EstimateDetails.discount != '') {
          if (this.EstimateDetails.discount_type == '%') {
            this.discount = (this.item_total * (+this.EstimateDetails.discount)) / 100;
            this.amount = (+this.item_total) - this.discount;
          } else if (this.EstimateDetails.discount_type == '$') {
            this.discount = (+this.EstimateDetails.discount);
            this.amount = this.item_total - (+this.EstimateDetails.discount);
          }
        }

        if (this.EstimateDetails.sales_tax != null && this.EstimateDetails.sales_tax != '') {
          if (this.discount == 0) {
            this.sales_tax = (this.item_total * (+this.EstimateDetails.sales_tax)) / 100;
            this.amount = this.item_total + this.sales_tax;


          } else {
            this.sales_tax = (this.amount * (+this.EstimateDetails.sales_tax)) / 100;
            this.amount = this.amount + this.sales_tax;
          }

        }

        if (this.EstimateDetails.advance != null && this.EstimateDetails.advance != '') {
          if (this.discount == 0 && this.sales_tax == 0) {

            if (this.EstimateDetails.advance_type == '%') {
              this.advance = (this.item_total * (+this.EstimateDetails.advance)) / 100;
            } else if (this.EstimateDetails.advance_type == '$') {
              this.advance =  (+this.EstimateDetails.advance);
              // this.advance = this.item_total - (+this.EstimateDetails.advance);
            }
          } else if (this.discount == 0 && this.sales_tax != 0) {

            if (this.EstimateDetails.advance_type == '%') {

              this.advance = (this.amount * (+this.EstimateDetails.advance)) / 100;

            } else if (this.EstimateDetails.advance_type == '$') {
              this.advance =  (+this.EstimateDetails.advance);
              // this.advance = this.amount - (+this.EstimateDetails.advance);
            }
          } else if (this.discount != 0 && this.sales_tax == 0) {

            if (this.EstimateDetails.advance_type == '%') {
              this.advance = (this.amount * (+this.EstimateDetails.advance)) / 100;
            } else if (this.EstimateDetails.advance_type == '$') {
              this.advance =  (+this.EstimateDetails.advance);
              // this.advance = this.amount - (+this.EstimateDetails.advance);
            }
          } else {
            if (this.EstimateDetails.advance_type == '%') {

              this.advance = (this.amount * (+this.EstimateDetails.advance)) / 100;
            } else if (this.EstimateDetails.advance_type == '$') {
              this.advance =  (+this.EstimateDetails.advance);
              // this.advance = this.amount - (+this.EstimateDetails.advance);
            }
          }
        }
        if(result['data'].estimate_customers.length > 0){
          this.getCustomerDetails(result['data'].estimate_customers[0]);
        }
        this.discount = parseFloat(this.discount).toFixed(2);
        this.advance = parseFloat(this.advance).toFixed(2);
        this.sales_tax = parseFloat(this.sales_tax).toFixed(2);
      }
    )
  }

  getWorkOrderDetails(work_order_id){
    if (work_order_id != null) {
      this.loading = true;


      this.WorkOrderService.GetWorkOrderDetail(work_order_id)
        .subscribe((result) => {
          this.WorkOrderModel.amount = result['data'].total;
        // this.WorkOrderModel.amount = Math.round(this.WorkOrderModel.amount );
          this.WorkOrderModel.work_order_id = result['data'].work_order_id;
          this.WorkOrderModel.items = result['data'].workorder_workorderitems;
          this.WorkOrderModel.work_order_number = result['data'].work_order_number;
          this.WorkOrderModel.due_date = result['data'].due_date;
          this.WorkOrderModel.discount = result['data'].discount;
          this.WorkOrderModel.discount_type = result['data'].discount_type;
          this.WorkOrderModel.advance = result['data'].advance;
          this.WorkOrderModel.advance_type = result['data'].advance_type;
          this.WorkOrderModel.sales_tax = result['data'].sales_tax;
          this.WorkOrderModel.customer_id = result['data']['customer_id'];
          this.WorkOrderModel.created_at = result['data']['created_at'];
          this.WorkOrderModel.updated_at = result['data']['updated_at'];
          this.payment_status = result['data']['status'];
          this.organization_id = result['data']['organization_id'];
          if (this.WorkOrderModel.items != null && this.WorkOrderModel.items.length > 0) {
            this.work_order_items = this.WorkOrderModel.items;
            for (let i of this.work_order_items) {
              this.item_total = this.item_total + (+i.rate * i.quantity);
            }
          }
          
          this.getOrganizationDetails(this.organization_id);
          this.getPaymentData(this.organization_id);
          this.getUser(result['data'].user_id)

          if (this.WorkOrderModel.discount != null && this.WorkOrderModel.discount != '') {
            if (this.WorkOrderModel.discount_type == '%') {
              this.discount = (this.item_total * (+this.WorkOrderModel.discount)) / 100;
              this.amount = (+this.item_total) - this.discount;
            } else if (this.WorkOrderModel.discount_type == '$') {
              this.discount = (+this.WorkOrderModel.discount);
              this.amount = this.item_total - (+this.WorkOrderModel.discount);
            }
          }

          if (this.WorkOrderModel.sales_tax != null && this.WorkOrderModel.sales_tax != '') {
            if (this.discount == 0) {
              this.sales_tax = (this.item_total * (+this.WorkOrderModel.sales_tax)) / 100;
              this.amount = this.item_total + this.sales_tax;


            } else {
              this.sales_tax = (this.amount * (+this.WorkOrderModel.sales_tax)) / 100;
              this.amount = this.amount + this.sales_tax;
            }

          }

          if (this.WorkOrderModel.advance != null && this.WorkOrderModel.advance != '') {
            if (this.discount == 0 && this.sales_tax == 0) {

              if (this.WorkOrderModel.advance_type == '%') {
                this.advance = (this.item_total * (+this.WorkOrderModel.advance)) / 100;
              } else if (this.WorkOrderModel.advance_type == '$') {
                this.advance =  (+this.WorkOrderModel.advance);
                // this.advance = this.item_total - (+this.WorkOrderModel.advance);
              }
            } else if (this.discount == 0 && this.sales_tax != 0) {

              if (this.WorkOrderModel.advance_type == '%') {

                this.advance = (this.amount * (+this.WorkOrderModel.advance)) / 100;

              } else if (this.WorkOrderModel.advance_type == '$') {
                this.advance =  (+this.WorkOrderModel.advance);
                // this.advance = this.amount - (+this.WorkOrderModel.advance);
              }
            } else if (this.discount != 0 && this.sales_tax == 0) {

              if (this.WorkOrderModel.advance_type == '%') {
                this.advance = (this.amount * (+this.WorkOrderModel.advance)) / 100;
              } else if (this.WorkOrderModel.advance_type == '$') {
                this.advance =  (+this.WorkOrderModel.advance);
                // this.advance = this.amount - (+this.WorkOrderModel.advance);
              }
            } else {
              if (this.WorkOrderModel.advance_type == '%') {

                this.advance = (this.amount * (+this.WorkOrderModel.advance)) / 100;
              } else if (this.WorkOrderModel.advance_type == '$') {
                this.advance =  (+this.WorkOrderModel.advance);
                // this.advance = this.amount - (+this.WorkOrderModel.advance);
              }
            }
          }
          
          this.getCustomerDetails(result['data'].workorder_customers[0]);
        }
      )
    }

  }

  getUrl(){
    
    return this._DomSanitizer.bypassSecurityTrustResourceUrl(this.InvoiceDetails.signature);
  }

  getCustomerDetails(customer_details) {
    this._customerData.name = customer_details['name'];
    this._customerData.address = customer_details['address'];
    if(customer_details['locality']){
      this._customerData.locality = customer_details['locality'];
    }else{
      this._customerData.locality = '';
    }
    if(customer_details['zip']){
      this._customerData.zip = customer_details['zip'];
    }else{  
      this._customerData.zip = '';
    }
    this._customerData.alternative_number = customer_details['alternative_number'];
    this.alternative_number = customer_details['alternative_number'];
    this._customerData.mobile = customer_details['mobile'];
    this._customerData.email = customer_details['email'];        
     
  }

  getUser(user_id){
    this.EstimateService.getUser(user_id).subscribe(
      result=>{
        if(result['success']){
          this.user_name=result['data'].name;
          this.user_email=result['data'].email;
        }
      }
    )
  }
  
 
  
  getPaymentData(organization_id){
    this.SettingSerrvice.GetPaymentDetails(organization_id).subscribe(
      result => {
        
        if(result['success']){
          if (result['data']) {
            this.PaymentData = result['data'];
            this.secret_key = result['data']['secret'];
            localStorage.setItem("payment_data", JSON.stringify(result['data'].key));

          }
          if (result['bank_details']) {
            this.PaymentData.bank_details = result['bank_details']['bank_details'];
            this.PaymentData.checked = result['bank_details']['checked'];
            this.PaymentData.bank_detail_id = result['bank_details']['bank_detail_id'];
          }
        }
        
      }
    )
  }

  ApproveEstimate() {
    this.EstimateService.Approve(this.estimate_id).subscribe(
      result=>{
        // if(result['success']) {
        //   this.EstimateService.setApproveInvoiceState = true;
        // }else {
        //   this.EstimateService.setApproveInvoiceState = false;
        // }
        if(result['success']){
          this.EstimateDetails.is_approved = result['data'].is_approved;
          this.BC2.postMessage({message:'approved'});
          this.toastr.success(result['msg']);
          location.reload();
        }else{
          this.toastr.error(result['msg']);

        }
        
      }
    )
  }


  payEstimateAdvance(){ 
    if(this.EstimateDetails.advance) {
      this.advancePayment.show();
    } else {
      this.ApproveEstimate();
    }
  }

  pay() {
    this.loading = true;
    this.accepted = false;

    this.stripeService.createToken(this.card, { name })
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          // this.PaymentModel.funding = result.token.card.funding;
          if (this.estimate_id) {
            this.PaymentModel.amount_to_be_paid = this.EstimateDetails.advance;
          } else if (this.invoice_id) {
            this.PaymentModel.amount_to_be_paid = this.InvoiceDetails.advance;
          }
          this.PaymentModel.estimate_id = this.estimate_id;
          this.PaymentModel.invoice_id = this.invoice_id;
          // this.PaymentModel.brand = result.token.card.brand;
          // this.PaymentModel.last4 = result.token.card.last4;
          // this.PaymentModel.card_id = result.token.card.id;
          // this.PaymentModel.exp_month = result.token.card.exp_month;
          // this.PaymentModel.exp_year = result.token.card.exp_year;
          // this.PaymentModel.zip = result.token.card.address_zip;
          // this.PaymentModel.customer_name = this.CustomerModel.name;
          // this.PaymentModel.email = this.CustomerModel.email;
          // this.PaymentModel.address_line_1 = this.CustomerModel.address;
          // this.PaymentModel.address_line_2 = this.CustomerModel.address;
          // this.PaymentModel.state = this.CustomerModel.locality;
          // this.PaymentModel.country = result.token.card.country;
          this.PaymentModel.StripeToken = result.token.id;
          // this.PaymentModel.secret_key = this.secret_key;

          let data = {
            estimate_id: this.PaymentModel.estimate_id,
            amount_to_be_paid: this.PaymentModel.amount_to_be_paid,
            StripeToken: this.PaymentModel.StripeToken,
            invoice_id: this.PaymentModel.invoice_id
          }

          this.EstimateService.Charge(data).subscribe(
            result => {
              if (result['success']) {
                this.loading = false;
                this.accepted = true;
                // this.advance = 0;
                // setTimeout(() => {
                //   this.getEstimateDetails(this.estimate_id);
                // }, 0);
                location.reload();
                this.InvoiceService.setIsAdvancePay = true;
                this.showSuccess(result['message']);
                this.BC3.postMessage({ message: 'advance-paid' });
                this.advancePayment.hide();
              } else {
                this.loading = false;
                this.accepted = false;
                this.InvoiceService.setIsAdvancePay = false;
                this.showDanger(result['message']);
              }
            }
          )
        } else if (result.error) {
          // Error creating the token
          this.showDanger(result.error.message);

          this.loading = false;
          this.accepted = false;
        }
      }, (err:HttpErrorResponse) => {
        this.loading=false;
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingSerrvice.LogOut();
        }
      }
    );
  }

  getPaymentSetting(organization_id) {
    if (organization_id != null) {
      this.loading = true;
      this.SettingSerrvice.GetPaymentDetails(organization_id)
        .subscribe((result) => {
          this.loading = false;
          if (result['bank_details']) {
            this.PaymentData.bank_details = result['bank_details']['bank_details'];
            this.PaymentData.checked = result['bank_details']['checked'];
            this.PaymentData.bank_detail_id = result['bank_details']['bank_detail_id'];
            
          } else {
            this.PaymentData = null;
          }
        }, (err: HttpErrorResponse) => {
          if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
            this.SettingSerrvice.LogOut();
          }
        }
        )
    }
    else {
      this.loading = false;
    }
  }


  Loading() {
    let interval = timer(5000);
    this.loading = true;
    this.accepted = false;

    interval.subscribe(() => {
      this.loading = false;
      this.accepted = true;
    });
  }

  advancePay() {
    this.advancePayment.show();
  }

  showSuccess(msg: string) {
    this.toastr.success(msg);
  }
  showDanger(msg: string) {
    this.toastr.error(msg);
  }
}
