import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class PaymentSettings {
    public payment_setting_id:string = null;
    public key:string = null;
    public secret :string = null;
    public application_id :string = null;
    public location_id :string = null;
    public access_token :string = null;
    public public_client_id :string = null;
    public login_id :string = null;
    public transaction_key :string = null;
    public others:string = null;
    public bank_details :string = null;
    public checked :boolean = false;
    public bank_detail_id :string = null;
}