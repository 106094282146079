import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { InvoiceAllList, CustomerModel, EstimateModel } from 'src/app/shared';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EstimateService } from 'src/app/shared/services/estimate/estimate.service';
import { WorkOrderModel } from 'src/app/shared/models/work-order/work-order.model';
import { environment } from 'src/environments/environment';
import { Form, FormBuilder, Validators } from '@angular/forms';
import { CustomerAddress } from 'src/app/shared/models/customer/customer-address.model';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';

@Component({
  selector: 'app-customer-self',
  templateUrl: './customer-self.component.html',
  styleUrls: ['./customer-self.component.scss']
})
export class CustomerSelfComponent implements OnInit {
  public loading: boolean = false;
  public Items: number[] = [0, 1, 2, 3, 4, 5, 6];
  public customer_id: string = null;
  public customer_name: string = null;
  public modalRef: BsModalRef;
  public formatted_mobile_number = null;
  public CustomerDetails: CustomerModel = new CustomerModel();
  public CustomerAddressModel: CustomerAddress = new CustomerAddress();
  public invoiceAllData: InvoiceAllList[] = [];
  public workOrderAllData: WorkOrderModel[] = [];
  public EstimateModel: EstimateModel[] = [];
  public loadingInvoice: boolean = false;
  public loadingEstimates: boolean = false;
  public loadingWorkOrder: boolean = false;
  public enabled_modules: any[] = [];
  public service_agreement_enabled: boolean = false;
  public work_order_enabled: boolean = false;
  public env = environment.frontend_url;
  public CustomerAddresses: any[] = [];
  public btnLoader = false;
  public newAddress = false;
  public alternative_number: any[] = [];
  public createAddressForm = this.fb.group({
    address_type: ["", [Validators.required]],
    address: ["", [Validators.required]],
    state: ["", Validators.required],
    zip: ["", [Validators.required, Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/),Validators.minLength(4), Validators.maxLength(15)]],
    // zip: ["", [Validators.required]],

    customer_id: [`${this.customer_id}`, Validators.required],
  })
  constructor(
    public activateRoute: ActivatedRoute,
    public CustomerService: CustomerService,
    public _modalService: BsModalService,
    public toastr: ToastrService,
    public router: Router,
    public SettingService:SettingService,
    public EstimateService:EstimateService,
    public fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,

  ) {
    this.customer_id = this.activateRoute.snapshot.paramMap.get('customer_id');
    this.getCustomerDetails(this.customer_id);
    this.getCustomerInvoiceList(this.customer_id);
    this.getCustomerEstimate(this.customer_id);
    this.GetWorkOrderListByCustomer(this.customer_id);
    if (JSON.parse(localStorage.getItem('activated_modules'))) {
      this.enabled_modules = JSON.parse(localStorage.getItem('activated_modules'));
      for (let index of this.enabled_modules) {

        if (index['module_id'] == '5ece4797eaf5e' && index['enabled']) {
          this.service_agreement_enabled = true;
        } else if (index['module_id'] == '606f1bc66d62b' && index['enabled']) {
          this.work_order_enabled = true;
        }
      }
    }
    this.getStateList();
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  currentCustomerAddressId: any = null;
  public config = {
    backdrop : true,
    keyboard : false,
    ignoreBackdropClick: true
  };
  openModal(template: TemplateRef<any>, customerAddrressId) {
    this.modalRef = this._modalService.show(template, this.config);
    this.currentCustomerAddressId = customerAddrressId;
    this.btnLoader = false;
    if(this.currentCustomerAddressId){
      this.getAddressDetails(this.currentCustomerAddressId);
    }else if(this.currentCustomerAddressId == null){
      this.CustomerAddressModel.id = null;
    }
  }
  getAddressDetails(addressId){
    if(addressId){
      this.CustomerService.getAddressDetails(addressId).subscribe(
        result=>{
          if(result['success']){
            this.CustomerAddressModel.customer_address_id = result['data'].customer_address_id;
            this.CustomerAddressModel.address_type = result['data'].address_type;
            this.CustomerAddressModel.address = result['data'].address;
            this.CustomerAddressModel.state = result['data'].state;
            this.CustomerAddressModel.zip = result['data'].zip;
            this.CustomerAddressModel.customer_id = result['data'].customer_id;
            this.CustomerAddressModel.id = result['data'].id;
          }
        }
      )

      }
  }
  
  get f() {
    return this.createAddressForm.controls;
  }
  
  getCustomerDetails(customer_id) {
    if (this.customer_id != null) {
      this.CustomerService.GetCustomerById(this.customer_id).subscribe(
        result => {
          this.CustomerDetails = result['data'];
          this.CustomerAddresses = result['data']['customer_addresses'];
          this.customer_name = result['data'].name;
          this.alternative_number = result["data"].alternative_number;
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    } 
  }

  DeleteCustomer(customer_id) {
    if (customer_id != null) {
      this.CustomerService.DeleteCustomer(customer_id)
        .subscribe((res) => {
          if (res['success']) {
            this.modalRef.hide();
            this.toastr.success('Deleted Successfully.');
            this.router.navigate(['/customer']);
          }else{
            this.toastr.info(res['msg']);
            this.modalRef.hide();
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }
  states: any[] = [];
  getStateList() {
    this.CustomerService.GetStates().subscribe(
      (result) => {
        this.states = result["data"];
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    );
  }
  getCustomerInvoiceList(customer_id) {
    if (customer_id != null) {
      this.loading = true;
      this.CustomerService.GetInvoiceList(customer_id).subscribe(
        result => {
          if (result['success']) {
            let invoices: any[] = [];
            invoices = result['data'];
            this.loading = false;
            
            for (let index of invoices) {
              let _invoiceAllData = new InvoiceAllList();
              _invoiceAllData.rate = index['amount'];
              _invoiceAllData.customer_id = index['customer_id'];
              _invoiceAllData.invoice_id = index['invoice_id'];
              _invoiceAllData.item_id = index['line_item'];
              _invoiceAllData.invoice_number = index['invoice_number'];
              _invoiceAllData.total = index['total'];

              if (index['invoice_customers'] !== null && index['invoice_customers'].length > 0) {
                _invoiceAllData.customer_name = index['invoice_customers'][0]['name'];
              }
              else {
                _invoiceAllData.customer_name = 'Not Available';
              }

              if (index['status'] !== null) {
                switch (index['status']) {
                  case 2:
                    _invoiceAllData.status = 'Paid';
                    break;
                  case 3:
                    _invoiceAllData.status = 'Unpaid';
                    break;
                  default:
                    _invoiceAllData.status = 'N/A';
                    break;
                }
              }
              else {
                _invoiceAllData.status = 'N/A';
              }
              this.invoiceAllData.push(_invoiceAllData);
            }

          } 
          else {
            this.loading = false;
            this.invoiceAllData = null;
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      );
    }
    else {
      this.loading = false;
    }
  }
  GetWorkOrderListByCustomer(customer_id) {
    if (customer_id != null) {
      this.loading = true;
      this.CustomerService.GetWorkOrderListByCustomer(customer_id).subscribe(
        result => {
          if (result['success']) {
            let invoices: any[] = [];
            invoices = result['data'];
            this.loading = false;
            
            for (let index of invoices) {
              let _workOrderAllData = new WorkOrderModel();
              _workOrderAllData.rate = index['amount'];
              _workOrderAllData.customer_id = index['customer_id'];
              _workOrderAllData.work_order_id = index['work_order_id'];
              _workOrderAllData.invoice_id = index['invoice_id'];
              _workOrderAllData.work_order_number = index['work_order_number'];
              _workOrderAllData.total = index['total'];
              this.workOrderAllData.push(_workOrderAllData);      
            }

          } 
          else {
            this.loading = false;
            this.workOrderAllData = [];
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      );
    }
    else {
      this.loading = false;
    }
  }
  getCustomerEstimate(customer_id) {
    if (customer_id != null) {
      this.loading = true;
      this.EstimateService.GetEstimateListByCustomer(customer_id).subscribe(
        result => {
          
          if (result['success']) {
            let estimates: any[] = [];
            estimates = result['data'];
            this.loading = false;
            let data = [];
            for (let index of estimates) {
              let _estimateAllData = new EstimateModel();
              _estimateAllData.customer_id = index['customer_id'];
              _estimateAllData.estimate_id = index['estimate_id'];
              _estimateAllData.items = index['line_item'];
              _estimateAllData.estimate_number = index['estimate_number'];
              _estimateAllData.total = index['gross_amount'];

              if (index['estimate_customers'] !== null && index['estimate_customers'].length > 0) {
                _estimateAllData.name = index['estimate_customers'][0]['name'];
              }
              else {
                _estimateAllData.name = 'Not Available';
              }

              data.push(_estimateAllData);

            }
            this.EstimateModel = data;

          } 
          else {
            this.loading = false;
            this.EstimateModel = null;
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      );
    }
    else {
      this.loading = false;
    }
  }

  public submitted = false;
  public isAddressAdded = false;
  createAddress(){
    this.btnLoader = true;
    this.createAddressForm.get('address_type').setValidators(Validators.required);
    this.createAddressForm.get('address').setValidators(Validators.required);
    this.createAddressForm.get('state').setValidators(Validators.required);
    this.createAddressForm.get('zip').setValidators([Validators.required, Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/),Validators.minLength(4), Validators.maxLength(10)]);

    this.createAddressForm.get('address_type').updateValueAndValidity();
    this.createAddressForm.get('address').updateValueAndValidity();
    this.createAddressForm.get('state').updateValueAndValidity();
    this.createAddressForm.get('zip').updateValueAndValidity();

    this.submitted = true;
    this.isAddressAdded = true;
    if (this.createAddressForm.invalid) {
      this.isAddressAdded = false;
      return;
    }
    this.CustomerAddressModel.customer_id = this.customer_id;

    if(this.currentCustomerAddressId == null){
        this.CustomerService.createCustomerAddress(this.CustomerAddressModel).subscribe(response => {
          if(response['success']){
            this.toastr.success('Address successfully Created!');
            this.Cancel();
            this.getCustomerDetails(this.customer_id);
            this.btnLoader = false;
          }else{
            this.toastr.error('Something Went Wrong!')
            this.btnLoader = false;
            this.submitted = false;
            this.isAddressAdded = false;
          }
      }) 
    }else if(this.currentCustomerAddressId){
      this.CustomerAddressModel.customer_address_id = this.currentCustomerAddressId;
      this.CustomerService.editCustomerAddress(this.CustomerAddressModel).subscribe((response)=> {
        if(response['success']){
          this.toastr.success('Address successfully Updated!');
          this.Cancel();
          this.getCustomerDetails(this.customer_id);
          this.newAddress = true;
          this.btnLoader = false;
        }else{
          this.toastr.error('Something Went Wrong!')
          this.btnLoader = false;
          this.submitted = false;
          this.isAddressAdded = false;
        }
      })
    }
  }
  deleteAddress( ){
    this.Cancel()
    this.btnLoader = true;
    this.CustomerService.deleteAddress(this.currentCustomerAddressId).subscribe((response) => {
      if(response['success']){
        this.toastr.success('Address successfully Deleted');
        this.modalRef.hide();
        this.Cancel();
        this.currentCustomerAddressId = null
        this.getCustomerDetails(this.customer_id);
        this.btnLoader = false;
        this.CustomerAddressModel = new CustomerAddress()
      }else{
        this.toastr.error('Something Went Wrong!');
        this.btnLoader = false;
        this.Cancel();
        this.currentCustomerAddressId = null

      }
    })
  }

  setDefaultAddress(customer_id, customer_address_id ){
    this.loading = true;
    const data = {
      customer_id: customer_id,
      customer_address_id: customer_address_id
    }
    this.CustomerService.setDefaultAddress(data).subscribe((response) => {
      if(response['success']){
        this.toastr.success('Successfully added to default address');
        this.loading = false;
        this.getCustomerDetails(customer_id);
      }else{
        this.loading = false;
        this.toastr.error('Something went Wrong!');
      }      
    })
  }

  Cancel(){
    this.modalRef.hide();
    this.submitted = false;
    this.isAddressAdded = false
    this.createAddressForm.reset();
    this.createAddressForm.get('address_type').clearValidators();
    this.createAddressForm.get('address').clearValidators();
    this.createAddressForm.get('state').clearValidators();
    this.createAddressForm.get('zip').clearValidators();
    this.createAddressForm.get('customer_id').clearValidators();
    this.changeDetectorRef.detectChanges();
  }

  public customerState: string = '';
  public customerAddress: any;
  @ViewChild("placesRef", {static: true}) placesRef : GooglePlaceDirective; 

  getAddressFromGoogle(address: any){
    this.customerAddress = address;
    this.createAddressForm.get('address').setValue(address.formatted_address);
    address.address_components.forEach(element => {
      if(element['types'][0] === "postal_code") {
        this.createAddressForm.get('zip').setValue(element['long_name']);
      }else if(element['types'][0] === "administrative_area_level_1"){
        this.customerState = element['long_name'];
        this.createAddressForm.get('state').setValue(element['long_name']);
      }
    });
  }
}
