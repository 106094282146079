import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { TokenService } from '../token/token.service';
import { AccountModel } from '../../models/account/account.model';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class SettingService {
  public paymentData: BehaviorSubject<object> = new BehaviorSubject({});

  constructor(
    private http: HttpClient,
    public TokenService: TokenService,
    public router: Router
  ) { }

  set setPaymentData(value: object) {
    this.paymentData.next(value);
  }

  AddAccount(data:AccountModel,selected_file,account_id):Observable<[]> {
    let addURL = environment.url+'account/create';
    let editURL = environment.url+'account/edit';
    let _FormData = new FormData();
    if(account_id==null){
      _FormData.append("business_name", data.business_name);
      _FormData.append("email", data.email);                                                         
      _FormData.append("address_line_1", data.address_line_1);
      _FormData.append("password", data.password);
      _FormData.append("address_line_2", data.address_line_2);
      _FormData.append("logo", data.logo);
  
  
      return this.http.post(addURL, _FormData, {
        headers: this.TokenService.GetTokenByHeader(),
  
      })
        .pipe(map((res: any) => res));
    }else{
      _FormData.append("business_name", data.business_name);
      _FormData.append("email", data.email);                                                         
      _FormData.append("address_line_1", data.address_line_1);
      _FormData.append("address_line_2", data.address_line_2);
      _FormData.append("logo", data.logo);
  
  
      return this.http.post(editURL, _FormData, {
        headers: this.TokenService.GetTokenByHeader(),
  
      })
        .pipe(map((res: any) => res));
    }
  
  }

  GetAccountDetails(org_id: any):Observable<[]> {
    let URL = environment.url+'account/view/'+org_id;

    return this.http.get(URL, {
      // headers: this.TokenService.GetTokenByHeader(),
    })
      .pipe(map((res: any) => res));
  }

  Payment(data):Observable<[]>{
    let URL = `${environment.url}payment_setting/create`;

    return this.http.post(URL, data, {
      headers: this.TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  PaymentEdit(data):Observable<[]>{
    
    let URL = environment.url+'payment_setting/edit';

    return this.http.post(URL, data, {
      headers: this.TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetPaymentDetails(organization_id):Observable<[]> {
    let URL = environment.url+'payment_setting/view/'+organization_id;

    return this.http.get(URL, {

    })
      .pipe(map((res: any) => res));
  }

  AddBankDetails(data):Observable<[]> {
    let URL = environment.url+'bank-details';
    return this.http.post(URL, data, {
      headers: this.TokenService.GetTokenByHeader()
    })
      .pipe(map((res: any) => res));
  }

  UpdateBankDetails(data):Observable<[]> {
    let URL = environment.url+'bank-details';
    return this.http.put(URL, data, {
      headers: this.TokenService.GetTokenByHeader()
    })
      .pipe(map((res: any) => res));
  }
  LogOut(){
    localStorage.removeItem('user_data');
    localStorage.removeItem('payment_data');
    localStorage.removeItem('activated_modules');
    this.router.navigate(['/']);
  }

  //Tax Rate API's
  AddTaxRate(data):Observable<[]> {
    let URL = environment.url+'tax-rate';

    return this.http.post(URL, data,{
      headers: this.TokenService.GetTokenByHeader(),
    })
      .pipe(map((res: any) => res));
  }
  GetTaxRates():Observable<[]> {
    let URL = environment.url+'tax-rate';

    return this.http.get(URL,{
      headers: this.TokenService.GetTokenByHeader(),
    })
      .pipe(map((res: any) => res));
  }
  GetTaxRateDetails(id){
    let URL = environment.url+'tax-rate/'+id;

    return this.http.get(URL,{
      headers: this.TokenService.GetTokenByHeader(),
    })
      .pipe(map((res: any) => res));
  }

  DeleteTaxRate(id){
    let URL = environment.url+'tax-rate/'+id;

    return this.http.delete(URL,{
      headers: this.TokenService.GetTokenByHeader(),
    })
      .pipe(map((res: any) => res));
  }

  UpdateTaxRate(data){
    let URL = environment.url+'tax-rate/'+data.tax_rate_id;

    return this.http.put(URL,data,{
      headers: this.TokenService.GetTokenByHeader(),
    })
      .pipe(map((res: any) => res));
  }

  //Tax Authority API's

  AddTaxAuthority(data):Observable<[]> {
    let URL = environment.url+'tax-authority';

    return this.http.post(URL, data,{
      headers: this.TokenService.GetTokenByHeader(),
    })
      .pipe(map((res: any) => res));
  }
  GetTaxAuthorities():Observable<[]> {
    let URL = environment.url+'tax-authority';

    return this.http.get(URL,{
      headers: this.TokenService.GetTokenByHeader(),
    })
      .pipe(map((res: any) => res));
  }
  GetTaxAuthorityDetails(id){
    let URL = environment.url+'tax-authority/'+id;

    return this.http.get(URL,{
      headers: this.TokenService.GetTokenByHeader(),
    })
      .pipe(map((res: any) => res));
  }

  DeleteTaxAuthority(id){
    let URL = environment.url+'tax-authority/'+id;

    return this.http.delete(URL,{
      headers: this.TokenService.GetTokenByHeader(),
    })
      .pipe(map((res: any) => res));
  }

  UpdateTaxAuthority(data){
    let URL = environment.url+'tax-authority/'+data.tax_authority_id;

    return this.http.put(URL,data,{
      headers: this.TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => res));
  }

  //Tax Exemption API's

  AddTaxExemption(data):Observable<[]> {
    let URL = environment.url+'tax-exemption';

    return this.http.post(URL, data,{
      headers: this.TokenService.GetTokenByHeader(),
    })
      .pipe(map((res: any) => res));
  }
  GetTaxExemptionList():Observable<[]> {
    let URL = environment.url+'tax-exemption';

    return this.http.get(URL,{
      headers: this.TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => res));
  }
  GetTaxExemptionDetails(id){
    let URL = environment.url+'tax-exemption/'+id;

    return this.http.get(URL,{
      headers: this.TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => res));
  }

  DeleteTaxExemption(id){
    let URL = environment.url+'tax-exemption/'+id;

    return this.http.delete(URL,{
      headers: this.TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => res));
  }

  UpdateTaxExemption(data){
    let URL = environment.url+'tax-exemption/'+data.tax_exemption_id;
    return this.http.put(URL,data,{
      headers: this.TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => res));
  }
  setDefaultTax(taxRateId){
    const URL = environment.url + 'tax-rate/default-tax/'+taxRateId;
    return this.http.post(URL, taxRateId, {
      headers: this.TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => res));
  }

  GetItemWidgetList(): Observable<[]>{
    let URL = environment.url + 'item/widget-item-list';
    return this.http.get(URL, {
      headers: this.TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => res));
  }

  validateCsv(file): Observable<[]>{
    let formData = new FormData();
    formData.append('documentType',file); 
    const URL = environment.url  + 'tax-rate/csv-tax-rate-validate';
    return this.http.post(URL,formData, {
      headers: this.TokenService.GetTokenByHeader()
    })
    .pipe(map((result:any) => result))
  }
  csvTaxUpload(file): Observable<[]>{
    let formData = new FormData();
    formData.append('documentType',file); 
    const URL = environment.url  + 'tax-rate/csv-tax-rate-save';
    return this.http.post(URL,formData, {
      headers: this.TokenService.GetTokenByHeader()
    })
    .pipe(map((result:any) => result))
  }
}
