import { Injectable } from '@angular/core';
import { BaseModel } from '../base/base.model';

@Injectable({
  providedIn: 'root'
})

export class ExpenseModel extends BaseModel{
    public expenses_id:string = null;
    public expense_number:any = null;
    public date:string = null;
    public ist_date:string = null;
    public type :string = null;
    public note:string = null;
    public merchant:string = null;
    public tip  :string = null;
    public total:string = null;

}

