import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ServiceAgreementModel {
    public service_agreement_id: string = null;
    public customer_id: string = null;
    public items: any[] = [];
    public rate: string = null;
    public line_item: string = null;
    public created_at: any = null;
    public updated_at: any = null;
    public quantity: any = null;
    public amount: any = null;
    public total: any = null;
    public service_agreement_number: any = null;
    public customer_name: string = null;
    public customer_phone: any = null;
    public customer_email: string = null;
    public is_signed: any;
    public signature: any;
    public select_interval: any;
    public recurring_type: any;
    public month: any;
    public day: any;
    public quarter: any;
    public is_approved: boolean = false;
    public service_agreement_recurring_details_id: string = null;
    public is_signed_receiver: any;
    public receiver_signature: any = null;
    public create_invoice: any = null;
    public start_date: any =null;
    public end_date: any = null;
    public new_date_range: any = null;
    public markup_price: any = null;
    public private_note: any = null;
    public alternative_number: any[] = [];
}