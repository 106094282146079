import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EstimateService } from 'src/app/shared/services/estimate/estimate.service';
import { ItemService } from 'src/app/shared/services/item/item.service';
import { EstimateModel } from 'src/app/shared';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PhonePipe } from 'src/app/shared/pipe/phone.pipe';

@Component({
  selector: 'app-estimate-send',
  templateUrl: './estimate-send.component.html',
  styleUrls: ['./estimate-send.component.scss'],
  providers: [ PhonePipe ]
})
export class EstimateSendComponent implements OnInit {
  public loading: boolean = false;

  public loading1: boolean = false;
  public accepted1: boolean = false;

  public estimate_id: string = null;
  public estimate_number: string = null;
  public EstimateDetails: string = null;
  public ItemDetails: string = null;
  public modalRef: BsModalRef;
  public estimate_items: any[] = [];
  public item_total: number = 0;
  public discount: any = 0;
  public sales_tax: any = 0;
  public advance: any = 0;
  public amount: number = 0;
  public customer_name: string = null;
  public customer_email: string = null;
  public mobile: any = null;
  public note: any = null;
  public loading2: boolean = false;
  public accepted2: boolean = false;
  public organization_id: string = null;
  public organization_name: string = null;
  public template: any = null;
  public userData:any = null;
  public env = environment; 
  public _estimateData: any = null;
  public checklist_enabled: boolean = false;
  public enabled_modules: any[] = [];

  public EstimateModel: EstimateModel = new EstimateModel();

  constructor(
    public activatedRoute: ActivatedRoute,
    public EstimateService: EstimateService,
    public ItemService: ItemService,
    public _modalService: BsModalService,
    public router: Router,
    public toastr: ToastrService,
    public Auth:AuthService,
    public SettingService:SettingService,
    private sanitizer:DomSanitizer,
    private phoneTransform: PhonePipe
  ) {
    this.userData = this.Auth.User();
    this.organization_id = this.userData.user.organization_id;
    this.getOrganizationDetails(this.organization_id);
    this.estimate_id = this.activatedRoute.snapshot.paramMap.get('estimate_id');
    this.estimate_number = this.activatedRoute.snapshot.paramMap.get('estimate_index');
    this.getEstimateDetails(this.estimate_id);  
    if ( JSON.parse( localStorage.getItem( 'activated_modules' ) ) )
    {
      this.enabled_modules = JSON.parse( localStorage.getItem( 'activated_modules' ) );
      for ( let index of this.enabled_modules )
      {
        if ( index[ 'module_id' ] == '50bf5d16f34b' && index[ 'enabled' ] ==1)
        {
          this.checklist_enabled = true;
        }
      }
    }  
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    let transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;

    return transformedData;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  public estimateData:object = null;
  getEstimateDetails(estimate_id: string) {
    if (estimate_id != null) {
      this.loading = true;
      this.EstimateService.GetEstimateDetails(estimate_id)
        .subscribe((result) => {
          this.loading = false;
          this.estimateData = result['data']
          this.EstimateModel.customer_id = result['data'].customer_id;
          this.EstimateModel.estimate_id = result['data'].estimate_id;
          this.EstimateModel.is_approved = result['data'].is_approved;
          this.EstimateModel.invoice_id = result['data'].invoice_id;
          this.note = result['data'].note;
          this.EstimateModel.private_note = result['data'].private_note;

          if (result['data']['estimate_customers'] != null && result['data']['estimate_customers'].length > 0) {
            this.customer_name = result['data']['estimate_customers'][0].name;
            this.customer_email = result['data']['estimate_customers'][0].email;
            this.mobile = result['data']['estimate_customers'][0].mobile;
          }
          if (result['data']['estimate_estimateitems'] != null && result['data']['estimate_estimateitems'].length > 0) {
            // for(let index of result['data']['estimate_estimateitems']){
            //   this.item_name.push(index.name);
            //   this.rates.push(index.rate);
            // }
            this.estimate_items = result['data']['estimate_estimateitems'];
            for (let i of this.estimate_items) {
              if(i.group=='Product'){
                if(i.estimateitems_items_with_trashed.tax_rate){
                  this.item_total = this.item_total+ i.quantity * (+i.rate+(+i.rate*(i.estimateitems_items_with_trashed.tax_rate_data.rate/100)));
                }else{
                  this.item_total = this.item_total + (+i.rate * i.quantity);
                }
              }else if(i.group=='Service'){
                if(i.estimateitems_service_with_trashed.tax_rate){
                  this.item_total = this.item_total+ i.quantity * (+i.rate+(+i.rate*(i.estimateitems_service_with_trashed.tax_rate_data.rate/100)));
                }else{
                  this.item_total = this.item_total + (+i.rate * i.quantity);
                }
              }
  
            }
          }
          this.EstimateModel.rate = result['data'].estimate_estimateitems[0].rate;
          this.EstimateModel.discount_type = result['data'].discount_type;
          this.EstimateModel.discount = result['data'].discount;
          this.EstimateModel.sales_tax = result['data'].sales_tax;
          this.EstimateModel.advance_type = result['data'].advance_type;
          this.EstimateModel.advance = result['data'].advance;
          this.EstimateModel.due_date = result['data'].due_date;
          this.EstimateModel.note = result['data'].note;
          this.EstimateModel.total = result['data'].gross_amount;
          this.EstimateModel.estimate_number = result['data'].estimate_number;
          this.EstimateModel.created_at = result['data'].created_at;
          this.EstimateModel.updated_at = result['data'].updated_at;
          this.getItem(result['data']['line_item']);
          
          if (this.EstimateModel.discount != null && this.EstimateModel.discount != '') {
    
            if (this.EstimateModel.discount_type == '%') {
    
              this.discount = (this.item_total * (+this.EstimateModel.discount)) / 100;
              this.amount = (+this.item_total) - this.discount;
    
            } else if (this.EstimateModel.discount_type == '$') {
              this.discount = (+this.EstimateModel.discount);
              this.amount = this.item_total - (+this.EstimateModel.discount);
            }
          }
    
          if (this.EstimateModel.sales_tax != null && this.EstimateModel.sales_tax != '') {
            if (this.discount == 0) {
              this.sales_tax = (this.item_total * (+this.EstimateModel.sales_tax)) / 100;
              this.amount = this.item_total + this.sales_tax;
    
    
            } else {
              this.sales_tax = (this.amount * (+this.EstimateModel.sales_tax)) / 100;
              this.amount = this.amount + this.sales_tax;
            }
    
          }
          if (this.EstimateModel.advance != null && this.EstimateModel.advance != '') {
            
            if (this.discount == 0 && this.sales_tax == 0) {    
    
              if (this.EstimateModel.advance_type == '%') {
    
                this.advance = (this.item_total * (+this.EstimateModel.advance)) / 100;
    
              } else if (this.EstimateModel.advance_type == '$') {
    
                this.advance =  (+this.EstimateModel.advance);
                // this.advance = this.item_total - (+this.EstimateModel.advance);
              }
            } else if (this.discount == 0 && this.sales_tax != 0) {
    
    
              if (this.EstimateModel.advance_type == '%') {
    
                this.advance = (this.amount * (+this.EstimateModel.advance)) / 100;
    
              } else if (this.EstimateModel.advance_type == '$') {
                this.advance =  (+this.EstimateModel.advance);
                // this.advance = this.amount - (+this.EstimateModel.advance);
              }
            } else if (this.discount != 0 && this.sales_tax == 0) {
    
              if (this.EstimateModel.advance_type == '%') {
                this.advance = (this.amount * (+this.EstimateModel.advance)) / 100;
              } else if (this.EstimateModel.advance_type == '$') {
                this.advance =  (+this.EstimateModel.advance);
                // this.advance = this.amount - (+this.EstimateModel.advance);
              }
            } else {
    
              if (this.EstimateModel.advance_type == '%') {
                
                this.advance = (this.amount * (+this.EstimateModel.advance)) / 100;
              } else if (this.EstimateModel.advance_type == '$') {
                this.advance =  (+this.EstimateModel.advance);
                // this.advance = this.amount - (+this.EstimateModel.advance);
    
              }
            }
            this.discount = parseFloat(this.discount).toFixed(2);
            this.advance = parseFloat(this.advance).toFixed(2);
            this.sales_tax = parseFloat(this.sales_tax).toFixed(2);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
        
      )
     
    }
  }

  getItem(item_id: any) {
    if (item_id != null) {
      this.ItemService.GetItemById(item_id).subscribe(
        result => {

          this.ItemDetails = result['data'];
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

  DeleteEstimate(estimate_id: any) {
    if (estimate_id != null) {
      this.EstimateService.DeleteEstimate(estimate_id)
        .subscribe((result) => {
          if (result['success']) {
            this.modalRef.hide();
            this.toastr.success('Deleted Successfully.');
            this.router.navigate(['/estimate']);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

  getOrganizationDetails(organization_id: string) {
    this.SettingService.GetAccountDetails(organization_id).subscribe(
      result => {
        this.template = result['data']['template'];
        this.organization_name=result['data']['business_name'];
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  sanitize(){
    return this.sanitizer.bypassSecurityTrustUrl('sms:'+this.mobile+';?&body=Dear '+this.customer_name+', %0a%0aThank you for your business. %0a%0aYour estimate '+this.EstimateModel.estimate_number+' can be viewed, printed, or downloaded as a PDF file from the link below. You can also choose to pay it online. %0a%0aClick to view Estimate %0a'+this.env.frontend_url+'template-'+this.template+'/estimate/'+this.estimate_id+'  %0a%0aWe look forward to doing more business with you. %0a%0aRegards, %0a'+this.userData.user.name+' %0a'+this.organization_name);
    // return this.sanitizer.bypassSecurityTrustUrl('sms:'+this.mobile+';?&body=Dear '+this._invoiceData.customer_name+', Thank you for your business. Your invoice '+this._invoiceData.invoice_number+' can be viewed, printed, or downloaded as a PDF file from the link below. You can also choose to pay it online. Click to view Invoice http://111.93.175.214/invoiceapppwa/display/template-'+this.template+'/'+this.invoice_id+'  We look forward to doing more business with you. Regards, '+this.userData.user.name+' '+this.organization_name);
  }

  getDataUrl(template: string,id: string){
    return this.env.frontend_url+"template-"+template+'/estimate/'+id;
  }

  copy(evt: Event){
    let url: string = evt.target['dataset'].url;

    document.addEventListener('copy', function(evt) {
      evt.clipboardData.setData('text/plain', url);
      evt.preventDefault();
    }, true);

    let result = document.execCommand('copy');

    if (result) {
      this.toastr.success('Successfully Copied');
    }
    else {
      this.toastr.error('Not Copied');
    }
  }

  ApproveEstimate() {
    this.EstimateService.Approve(this.estimate_id).subscribe(
      result => {
        if (result['data']) {
          this.toastr.success(result['msg']);
          this._estimateData = result['data'];
          this.EstimateModel.invoice_id = result['data'].invoice_id;
          this.EstimateModel.is_approved = this._estimateData.is_approved;
        } else {
          this.toastr.success(result['msg']);
        }
        
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      })
  }

}
