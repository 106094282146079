import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import {  SettingService } from 'src/app/shared/services/setting/setting.service';
import {  HttpErrorResponse } from '@angular/common/http';
import { EstimateService } from 'src/app/shared/services/estimate/estimate.service';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { InvoiceModel, EstimateModel } from 'src/app/shared';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {   ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common'

@Component({
  selector: 'app-save-progress-work',
  templateUrl: './save-progress-work.component.html',
  styleUrls: ['./save-progress-work.component.scss']
})
export class SaveProgressWorkComponent implements OnInit {
  public type: string= '';
  public path: string= '';
  public InvoiceModel: InvoiceModel = new InvoiceModel();
  public EstimateModel: EstimateModel = new EstimateModel();
  public invoice_draft_id: string = null;
  public loading: boolean = true;
  public estimate_draft_id: string = null;
  public estimateModelData: any;
  public invoiceModelData: any;
  public imagList: any = [];
  public _formdata = new FormData();
  public estimate_id: string = null;
  public total_amount: any = 0;
  public estimate_number: any = null;
  public invoice_number: any = null;
  public gross_amount: any = 0;
  public amount: any = 0;

  constructor(
    public _modalService: BsModalService,
    public modalRef: BsModalRef,
    public SettingService: SettingService,
    public EstimateService: EstimateService,
    public InvoiceService: InvoiceService,
    public fb: FormBuilder,
    public _router: Router,
    public _activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    private location: Location

  ) { }

  ngOnInit() {
  }
  public subsciption: Subscription;

  ngAfterViewInit() {

    if (this.type == "Estimate") {
      this.subsciption = this.EstimateService.totalAmount.subscribe((result) => {
        if(result) {
          this.total_amount = result;
        }
      })

      this.subsciption = this.EstimateService.grossAmount.subscribe((result) => {
        if(result) {
          this.gross_amount = result;
        }
      })

      this.subsciption = this.EstimateService.estimateNumber.subscribe((result) => {
        if(result) {
          this.estimate_number = result;
        }
      })

      this.subsciption = this.EstimateService.estimateDraft.subscribe((response) => {
        if(response){
          this.estimateModelData = response;
        }
      })

      this.subsciption = this.EstimateService.estimateDraftImages.subscribe((response) => {
        if(response) {
          this.imagList = response;
        }
      })
    } else if (this.type == "Invoice") {
      this.subsciption = this.InvoiceService.totalAmount.subscribe((result) => {
        if(result) {
          this.total_amount = result;
        }
      })

      this.subsciption = this.InvoiceService.grossAmount.subscribe((result) => {
        if(result) {
          this.gross_amount = result;
        }
      })

      this.subsciption = this.InvoiceService.invoiceNumber.subscribe((result) => {
        if(result) {
          this.invoice_number = result;
        }
      })

      this.subsciption = this.InvoiceService.invoiceDraft.subscribe((response) => {
        if(response){
          this.invoiceModelData = response;
        }
      })

      this.subsciption = this.InvoiceService.invoiceDraftImages.subscribe((response) => {
        if(response) {
          this.imagList = response;
        }
      })

      this.subsciption = this.InvoiceService.amount.subscribe((result) => {
        console.log("amount: ", result);
        
        if(result) {
          this.amount = result;
        }
      })
    }
  }

  ngOnDestroy() {
    this.subsciption.unsubscribe()
  }

  // cancel() {

  //   this.EstimateService.setEstimateDraft = null;

  //   this.modalRef.hide();
  //   if(this.type == "Estimate") {
  //     if(this.path == "estimate-list") {
  //       this._router.navigate(['/estimate']);
  //     } else if(this.path == "customer") {
  //       this._router.navigate(['/customer-new']);
  //     } else if(this.path == "checklist") {
  //       this._router.navigate(['/checklist-add']);
  //     } else if(this.path == "tax-setting") {
  //       this._router.navigate(['/tax-settings']);
  //     }
  //   } else if(this.type == "Invoice") {
  //     if(this.path == "invoice-list") {
  //       this._router.navigate( [ '/invoices' ] );
  //     } else if(this.path == "customer") {
  //       this._router.navigate(['/customer-new']);
  //     } else if(this.path == "checklist") {
  //       this._router.navigate(['/checklist-add']);
  //     } else if(this.path == "tax-setting") {
  //       this._router.navigate(['/tax-settings']);
  //     } else if(this.path == "expense") {
  //       this._router.navigate(['/expense-new']);
  //     }
  //   }
  // }

  back(): void {
    this.modalRef.hide();
    this.location.back();
  }

  saveAsDraft(evt) {
    if(this.type == "Estimate") {
        this.loading = true;
        this.estimateModelData.total = this.total_amount;
        this.estimateModelData.gross_amount = this.gross_amount;
        this.estimateModelData.estimate_number = this.estimate_number;
        this.estimateModelData.is_draft_save = true;
        this.EstimateService.AddEstimate(this.estimateModelData).subscribe((result) => {
          if(result['success']) {
            this.loading = false;
            this.modalRef.hide();
            this.estimateModelData.estimate_id = result['data']['estimate_id'];
            if (this.imagList.length > 0) {
              this.UploadEstimateImages(this.estimateModelData.estimate_id,'Successfully Estimate Added!');
            }
            if(this.path == "estimate-list") {
              this._router.navigate(['/estimate']);
            } else if(this.path == "customer") {
              this._router.navigate(['/customer-new']);
            } else if(this.path == "checklist") {
              this._router.navigate(['/checklist-add']);
            } else if(this.path == "tax-setting") {
              this._router.navigate(['/tax-settings']);
            }
            this.showCreateSuccess(result['msg']);
          } else {
            this.loading = false;
            this.showCreateDanger(result["msg"]);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
        );
    }else if(this.type == "Invoice") {
        this.loading = true;
        this.invoiceModelData.total = this.total_amount;
        this.invoiceModelData.gross_amount = this.gross_amount;
        this.invoiceModelData.amount = this.amount;
        this.invoiceModelData.invoice_number = this.invoice_number;
        this.invoiceModelData.is_draft_save = true;
        this.InvoiceService.Create(this.invoiceModelData).subscribe((result) => {
          if(result['success']) {
            this.loading = false;
            this.modalRef.hide();
            this.invoiceModelData.invoice_id = result['data']['invoice_id'];
            if ( this.imagList.length > 0 ){
                this.UploadInvoiceImages( this.invoiceModelData.invoice_id );
            }
            if(this.path == "invoice-list") {
              this._router.navigate( [ '/invoices' ] );
            } else if(this.path == "customer") {
              this._router.navigate(['/customer-new']);
            } else if(this.path == "checklist") {
              this._router.navigate(['/checklist-add']);
            } else if(this.path == "tax-setting") {
              this._router.navigate(['/tax-settings']);
            } else if(this.path == "expense") {
              this._router.navigate(['/expense-new']);
            }
            this.showCreateSuccess(result['msg']);
          } else {
            this.loading = false;
            this.showCreateDanger(result["msg"]);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
        );
    }
  }

  UploadEstimateImages ( estimate_id, msg ){
    this.loading = true;
    for ( var i = 0; i < this.imagList.length; i++ ){
      this._formdata.append( "logo[]", <string><unknown>this.imagList[ i ] );
    }

    this._formdata.append( "estimate_id", this.estimateModelData.estimate_id );
    // this.estimateModelData.is_draft_save = Number(this.estimateModelData.is_draft_save);
    // this._formdata.append( "is_draft_save", this.estimateModelData.is_draft_save );

    this.EstimateService.SaveFiles( this._formdata ).subscribe(
      (result) =>
      {
        if ( result[ 'success' ] )
        {
          this.loading = false;
          this.showCreateSuccess(result['msg']);
        }
      }
    )
  }

  UploadInvoiceImages ( invoice_id ){
    this.loading = true;
    for ( var i = 0; i < this.imagList.length; i++ ){
      this._formdata.append( "logo[]", <string><unknown>this.imagList[ i ] );
    }
    this._formdata.append( "invoice_id", this.invoiceModelData.invoice_id );
    // this.invoiceModelData.is_draft_save = Number(this.invoiceModelData.is_draft_save);
    // this._formdata.append( "is_draft_save", this.invoiceModelData.is_draft_save )

    this.InvoiceService.SaveFiles( this._formdata ).subscribe(
      (result) =>
      {
        if ( result[ 'success' ] )
        {
          this.loading = false;
          this.showCreateSuccess(result['msg']);
        }
      }
    )
  }

  showCreateSuccess(msg: string) {
    this.toastr.success(msg);
  }

  showCreateDanger(msg: string) {
    this.toastr.error(msg);
  }
}
