import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public isOn: boolean = true;
  public loading: boolean = false;
  public accepted: boolean = false;
  public SignUp =this.fb.group({
    name: ['', Validators.required],
    email: ['',[Validators.required,
       Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')
      ]
    ],
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
      ]
    ],
    confirm_password: [''],
    }, 
    {
      validator: this.mustMatch('password', 'confirm_password'),
    });
  public submitted: boolean = false;

  constructor(
    public fb: FormBuilder,
    public _Auth:AuthService,
    public toastr: ToastrService,
    public router: Router
  ) { 
    
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  mustMatch(password: string, confirm_password: string) {
    return (SignUp: FormGroup) => {
      const control = SignUp.controls[password];
      const matchingControl = SignUp.controls[confirm_password];

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  get f() {
    return this.SignUp.controls;
  }

  SignupUser(){
    this.loading = true;
    this.accepted = false;
    if ((this.SignUp.controls.name.invalid) || (this.SignUp.controls.email.invalid) || (this.SignUp.controls.password.invalid) || (this.SignUp.controls.confirm_password.invalid)) {
      this.submitted = true;
      this.loading = false;
      this.accepted = false;
      return;
    }
    this._Auth.SignUp(this.SignUp.value).subscribe(
      result=>{
        if(result['success']){
          this.loading = false;
          this.accepted = false;
          this.toastr.success("Your data has been successfully registered!");
          this.router.navigate(['/']);
        }else{
          this.loading = false;
          this.accepted = false;
          this.toastr.error("Your data are already registered! ");

        }
      }
    )
  }
}
