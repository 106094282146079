import { Component, OnInit, Input } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { timer } from "rxjs";
import { ScheduleService } from "src/app/shared/services/schedule/schedule.service";
import { CallCustomerComponent } from "../../modal/call-customer/call-customer.component";

@Component({
  selector: "app-schedule-status",
  templateUrl: "./schedule-status.component.html",
  styleUrls: ["./schedule-status.component.scss"],
})
export class ScheduleStatusComponent implements OnInit {
  @Input() data: any = null;

  public schedule_accepted: boolean = false;
  public loading: boolean = false;
  public accepted: boolean = false;
  modalRef: BsModalRef;

  constructor(
    private _modal: BsModalService,
    public ScheduleService: ScheduleService,
    public toastr: ToastrService
  ) {
  }

  ngOnInit() {
    if (this.data.status == 3 || this.data.status == 4) {
      this.schedule_accepted = true;
    } else {
      this.schedule_accepted = false;
    }
  }

  openModal(widget_service_id) {
    const initialState = {
      widget_service_id: widget_service_id
    };
    this.modalRef = this._modal.show(CallCustomerComponent, { initialState });
  }
  AcceptService(widget_service_id) {
    this.openModal(widget_service_id);
    // const data = {
    //   widget_service_id
    // }
    // this.loading = true;

    // this.ScheduleService.AcceptSchedule(data).subscribe(
    //   (result) => {
    //     console.log(result);
        
    //     if (result["success"]) {
    //         this.schedule_accepted = true;
    //         this.loading = false;
    //         this.accepted = true;
    //     } else {
    //       this.toastr.warning('First assign to someone')
          
    //       this.loading = false;
    //       this.accepted = false;
    //     }
    //   });
  }
}
