import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EstimateService } from 'src/app/shared/services/estimate/estimate.service';
import { ItemService } from 'src/app/shared/services/item/item.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WorkOrderModel } from 'src/app/shared/models/work-order/work-order.model';
import { WorkOrderService } from 'src/app/shared/services/work-order/work-order.service';


@Component({
  selector: 'app-work-order-send',
  templateUrl: './work-order-send.component.html',
  styleUrls: ['./work-order-send.component.scss']
})
export class WorkOrderSendComponent implements OnInit {

  public loading: boolean = false;

  public loading1: boolean = false;
  public accepted1: boolean = false;

  public work_order_id: string = null;
  public work_order_number: string = null;
  public EstimateDetails: string = null;
  public ItemDetails: string = null;
  public modalRef: BsModalRef;
  public work_order_items: any[] = [];
  public item_total: number = 0;
  public discount: number = 0;
  public sales_tax: number = 0;
  public advance: number = 0;
  public amount: number = 0;
  public customer_name: string = null;
  public customer_email: string = null;
  public mobile: any = null;
  public loading2: boolean = false;
  public accepted2: boolean = false;
  public organization_id: string = null;
  public organization_name: string = null;
  public template: any = null;
  public userData:any = null;
  public env = environment; 
  public checklistId: string = null;
  public checklist_enabled: boolean = false;
  public enabled_modules: any[] = [];


  public WorkOrderModel: WorkOrderModel = new WorkOrderModel();

  constructor(
    public activatedRoute: ActivatedRoute,
    public EstimateService: EstimateService,
    public ItemService: ItemService,
    public _modalService: BsModalService,
    public router: Router,
    public toastr: ToastrService,
    public Auth:AuthService,
    public SettingService:SettingService,
    public WorkOrderService:WorkOrderService,
    private sanitizer:DomSanitizer,
  ) {
    this.userData = this.Auth.User();
    this.organization_id = this.userData.user.organization_id;
    this.getOrganizationDetails(this.organization_id);
    this.work_order_id = this.activatedRoute.snapshot.paramMap.get('work_order_id');
    this.work_order_number = this.activatedRoute.snapshot.paramMap.get('work_order_index');
    this.getWorkOderDetails(this.work_order_id);
    if ( JSON.parse( localStorage.getItem( 'activated_modules' ) ) )
    {
      this.enabled_modules = JSON.parse( localStorage.getItem( 'activated_modules' ) );
      for ( let index of this.enabled_modules )
      {
        if ( index[ 'module_id' ] == '50bf5d16f34b' && index[ 'enabled' ] == 1)
        {
          this.checklist_enabled = true;
        }
      }
    } 
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    let transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;

    return transformedData;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  getWorkOderDetails(work_order_id) {
    if (work_order_id != null) {
      this.loading = true;
      this.WorkOrderService.GetWorkOrderDetail(work_order_id)
        .subscribe((result) => {
          this.loading = false;
          this.checklistId = result['data']['checklist_id']
          this.WorkOrderModel.customer_id = result['data'].customer_id;
          if(result['data'].workorder_workorderitems[0]['workorderitems_items_with_trashed']!=null){
            this.WorkOrderModel.name = result['data'].workorder_workorderitems[0]['workorderitems_items_with_trashed'].name;
            this.WorkOrderModel.line_item = result['data'].workorder_workorderitems[0]['workorderitems_items_with_trashed'].name;

          }else if(result['data'].workorder_workorderitems[0]['workorderitems_service_with_trashed']!=null){
            this.WorkOrderModel.name = result['data'].workorder_workorderitems[0]['workorderitems_service_with_trashed'].name;
            this.WorkOrderModel.line_item = result['data'].workorder_workorderitems[0]['workorderitems_service_with_trashed'].name;

          }
          
          if (result['data']['workorder_customers'] != null && result['data']['workorder_customers'].length > 0) {
            this.customer_name = result ['data']['workorder_customers'][0].name;
            this.customer_email = result['data']['workorder_customers'][0].email;
            this.mobile = result['data']['workorder_customers'][0].mobile;
          }
          if (result['data']['workorder_workorderitems'] != null && result['data']['workorder_workorderitems'].length > 0) {
            // for(let index of result['data']['workorder_workorderitems']){
            //   this.item_name.push(index.name);
            //   this.rates.push(index.rate);
            // }
            this.work_order_items = result['data']['workorder_workorderitems'];
            for (let i of this.work_order_items) {
              this.item_total = this.item_total + (+i.rate * i.quantity);  
            }
          }
          if(result['data'].workorder_workorderitems[0]['workorderitems_items_with_trashed']!=null){
            this.WorkOrderModel.rate = result['data'].workorder_workorderitems[0]['workorderitems_items_with_trashed'].rate;

          }else if(result['data'].workorder_workorderitems[0]['workorderitems_service_with_trashed']!=null){
            this.WorkOrderModel.rate = result['data'].workorder_workorderitems[0]['workorderitems_service_with_trashed'].rate;
            
          }
          this.WorkOrderModel.discount_type = result['data'].discount_type;
          this.WorkOrderModel.assign_to = result['data'].workorder_users.name;
          this.WorkOrderModel.assign_to_email = result['data'].workorder_users.email;
          this.WorkOrderModel.discount = result['data'].discount;
          this.WorkOrderModel.sales_tax = result['data'].sales_tax;
          this.WorkOrderModel.advance_type = result['data'].advance_type;
          this.WorkOrderModel.advance = result['data'].advance;
          this.WorkOrderModel.due_date = result['data'].due_date;
          this.WorkOrderModel.start_date = result['data'].start_date;
          this.WorkOrderModel.end_date = result['data'].end_date;
          this.WorkOrderModel.note = result['data'].note;
          this.WorkOrderModel.private_note = result['data'].private_note;
          this.WorkOrderModel.total = result['data'].total;
          this.WorkOrderModel.work_order_number = result['data'].work_order_number;
          this.WorkOrderModel.created_at = result['data'].created_at;
          this.WorkOrderModel.updated_at = result['data'].updated_at;
          this.getItem(result['data']['line_item']);

          if (this.WorkOrderModel.discount != null && this.WorkOrderModel.discount != '') {
    
            if (this.WorkOrderModel.discount_type == '%') {
    
              this.discount = (this.item_total * (+this.WorkOrderModel.discount)) / 100;
              this.amount = (+this.item_total) - this.discount;
    
            } else if (this.WorkOrderModel.discount_type == '$') {
              this.discount = (+this.WorkOrderModel.discount);
              this.amount = this.item_total - (+this.WorkOrderModel.discount);
            }
          }
    
          if (this.WorkOrderModel.sales_tax != null && this.WorkOrderModel.sales_tax != '') {
            if (this.discount == 0) {
              this.sales_tax = (this.item_total * (+this.WorkOrderModel.sales_tax)) / 100;
              this.amount = this.item_total + this.sales_tax;
    
    
            } else {
              this.sales_tax = (this.amount * (+this.WorkOrderModel.sales_tax)) / 100;
              this.amount = this.amount + this.sales_tax;
            }
    
          }
          if (this.WorkOrderModel.advance != null && this.WorkOrderModel.advance != '') {
            
            if (this.discount == 0 && this.sales_tax == 0) {    
    
              if (this.WorkOrderModel.advance_type == '%') {
    
                this.advance = (this.item_total * (+this.WorkOrderModel.advance)) / 100;
    
              } else if (this.WorkOrderModel.advance_type == '$') {
    
                this.advance =  (+this.WorkOrderModel.advance);
                // this.advance = this.item_total - (+this.WorkOrderModel.advance);
              }
            } else if (this.discount == 0 && this.sales_tax != 0) {
    
    
              if (this.WorkOrderModel.advance_type == '%') {
    
                this.advance = (this.amount * (+this.WorkOrderModel.advance)) / 100;
    
              } else if (this.WorkOrderModel.advance_type == '$') {
                this.advance =  (+this.WorkOrderModel.advance);
                // this.advance = this.amount - (+this.WorkOrderModel.advance);
              }
            } else if (this.discount != 0 && this.sales_tax == 0) {
    
              if (this.WorkOrderModel.advance_type == '%') {
                this.advance = (this.amount * (+this.WorkOrderModel.advance)) / 100;
              } else if (this.WorkOrderModel.advance_type == '$') {
                this.advance =  (+this.WorkOrderModel.advance);
                // this.advance = this.amount - (+this.WorkOrderModel.advance);
              }
            } else {
    
              if (this.WorkOrderModel.advance_type == '%') {
                
                this.advance = (this.amount * (+this.WorkOrderModel.advance)) / 100;
              } else if (this.WorkOrderModel.advance_type == '$') {
                this.advance =  (+this.WorkOrderModel.advance);
                // this.advance = this.amount - (+this.WorkOrderModel.advance);
    
              }
            }
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
        
      )
     
    }
  }

  getItem(item_id) {
    if (item_id != null) {
      this.ItemService.GetItemById(item_id).subscribe(
        result => {

          this.ItemDetails = result['data'];
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

  DeleteWorkOrder(work_order_id) {
    if (work_order_id != null) {
      this.WorkOrderService.DeleteWorkOrder(work_order_id)
        .subscribe((result) => {
          if (result['success']) {
            this.modalRef.hide();
            this.toastr.success('Deleted Successfully.');
            this.router.navigate(['/work-order']);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

  getOrganizationDetails(organization_id) {
    this.SettingService.GetAccountDetails(organization_id).subscribe(
      result => {
        this.template = result['data']['template'];
        this.organization_name=result['data']['business_name'];
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  sanitize(){
    return this.sanitizer.bypassSecurityTrustUrl('sms:'+this.mobile+';?&body=Dear '+this.customer_name+', %0a%0aThank you for your business. %0a%0aYour work order '+this.WorkOrderModel.work_order_number+' can be viewed, printed, or downloaded as a PDF file from the link below. You can also choose to pay it online. %0a%0aClick to view Work Order %0a'+this.env.frontend_url+'template-'+this.template+'/work_order/'+this.work_order_id+'  %0a%0aWe look forward to doing more business with you. %0a%0aRegards, %0a'+this.userData.user.name+' %0a'+this.organization_name);
    // return this.sanitizer.bypassSecurityTrustUrl('sms:'+this.mobile+';?&body=Dear '+this._invoiceData.customer_name+', Thank you for your business. Your invoice '+this._invoiceData.invoice_number+' can be viewed, printed, or downloaded as a PDF file from the link below. You can also choose to pay it online. Click to view Invoice http://111.93.175.214/invoiceapppwa/display/template-'+this.template+'/'+this.invoice_id+'  We look forward to doing more business with you. Regards, '+this.userData.user.name+' '+this.organization_name);
  }

  getDataUrl(template,id){
    return this.env.frontend_url+"template-"+template+'/work_order/'+id;
  }

  copy(evt: Event){
    let url: string = evt.target['dataset'].url;

    document.addEventListener('copy', function(evt) {
      evt.clipboardData.setData('text/plain', url);
      evt.preventDefault();
    }, true);

    let result = document.execCommand('copy');

    if (result) {
      this.toastr.success('Successfully Copied');
    }
    else {
      this.toastr.error('Not Copied');
    }

    // this.clipboard.writeText(url).then(() => {
    //   this.toastr.success('Successfully Copied');
    // }, () => {
    //   this.toastr.error('Not Copied');
    // });
  }


}
