import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgorPasswordComponent } from './pages/forgor-password/forgor-password.component';
import { HomeComponent } from './pages/home/home.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { InvoiceSelfComponent } from './pages/invoice-self/invoice-self.component';
import { NewInvoiceComponent } from './pages/new-invoice/new-invoice.component';
import { InvoicePayComponent } from './pages/invoice-pay/invoice-pay.component';
import { InvoiceSendComponent } from './pages/invoice-send/invoice-send.component';
import { ExpensesComponent } from './pages/expenses/expenses.component';
import { ExpenseNewComponent } from './pages/expense-new/expense-new.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { CustomerSelfComponent } from './pages/customer-self/customer-self.component';
import { CustomerNewComponent } from './pages/customer-new/customer-new.component';
import { ItemsComponent } from './pages/items/items.component';
import { ItemNewComponent } from './pages/item-new/item-new.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { InvoiceTemplateComponent } from './pages/invoice-template/invoice-template.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { EstimateComponent } from './pages/estimate/estimate.component';
import { EstimateSelfComponent } from './pages/estimate-self/estimate-self.component';
import { EstimateNewComponent } from './pages/estimate-new/estimate-new.component';
import { EstimatePayComponent } from './pages/estimate-pay/estimate-pay.component';
import { EstimateSendComponent } from './pages/estimate-send/estimate-send.component';
import { ItemSelfComponent } from './pages/item-self/item-self.component';
import { ExpensesSelfComponent } from './pages/expenses-self/expenses-self.component';
import { BusinessProfileComponent } from './pages/business-profile/business-profile.component';
import { AddUserComponent } from './pages/add-user/add-user.component';
import { AccountDetailsComponent } from './pages/account-details/account-details.component';
import { PaymentMethodComponent } from './pages/payment-method/payment-method.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { ScheduleDetailComponent } from './pages/schedule-detail/schedule-detail.component';
import { MyScheduleComponent } from './pages/my-schedule/my-schedule.component';
import { MyScheduleDetailComponent } from './pages/my-schedule-detail/my-schedule-detail.component';
import { WidgetCodeComponent } from './pages/widget-code/widget-code.component';
import { PlanSettingsComponent } from './pages/plan-settings/plan-settings.component';
import { InvoiceReportComponent } from './pages/invoice-report/invoice-report.component';
import { PaymentReportComponent } from './pages/payment-report/payment-report.component';
import { SalesReportComponent } from './pages/sales-report/sales-report.component';
import { CustomerReportComponent } from './pages/customer-report/customer-report.component';
import { AuthGuardService } from './shared/services/auth-guard/auth-guard.service';
import { InvoiceTemplateOneComponent } from './pages/invoice-template-one/invoice-template-one.component';
import { InvoiceTemplateTwoComponent } from './pages/invoice-template-two/invoice-template-two.component';
import { InvoiceTemplateThreeComponent } from './pages/invoice-template-three/invoice-template-three.component';
import { PublicTemplateOneComponent } from './pages/public-template-one/public-template-one.component';
import { PublicTemplateTwoComponent } from './pages/public-template-two/public-template-two.component';
import { PublicTemplateThreeComponent } from './pages/public-template-three/public-template-three.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { MarkupInvoicesComponent } from './pages/markup-invoices/markup-invoices.component';
import { SquarePaymentComponent } from './pages/square-payment/square-payment.component';
import { AuthorizeNetComponent } from './pages/authorize-net/authorize-net.component';
import { WorkOrderComponent } from './pages/work-order/work-order.component';
import { WorkOrderNewComponent } from './pages/work-order-new/work-order-new.component';
import { WorkOrderPayComponent } from './pages/work-order-pay/work-order-pay.component';
import { UpgradeAddOnsComponent } from './pages/upgrade-add-ons/upgrade-add-ons.component';
import { WorkOrderSelfComponent } from './pages/work-order-self/work-order-self.component';
import { WorkOrderSendComponent } from './pages/work-order-send/work-order-send.component';
import { AddOnDetailsComponent } from './pages/add-on-details/add-on-details.component';
import { MyAddOnsComponent } from './pages/my-add-ons/my-add-ons.component';
import { RecurringInvoiceComponent } from './pages/recurring-invoice/recurring-invoice.component';
import { ServiceAgreementsTemplatesComponent } from './pages/service-agreements-templates/service-agreements-templates.component';
import { ServiceAgreementsTemplatesOneComponent } from './pages/service-agreements-templates-one/service-agreements-templates-one.component';
import { ServiceAgreementsTemplatesTwoComponent } from './pages/service-agreements-templates-two/service-agreements-templates-two.component';
import { ServiceAgreementsTemplatesThreeComponent } from './pages/service-agreements-templates-three/service-agreements-templates-three.component';
import { CreateAgreementComponent } from './pages/create-agreement/create-agreement.component';
import { ListAgreementComponent } from './pages/list-agreement/list-agreement.component';
import { ServiceDetailComponent } from './pages/service-detail/service-detail.component';
import { ServiceNewComponent } from './pages/service-new/service-new.component';
import { ServiceAgreementSelfComponent } from './pages/service-agreement-self/service-agreement-self.component';
import { ServicesComponent } from './pages/services/services.component';
import { PriceBookComponent } from './pages/price-book/price-book.component';
import { LoggedInAuthGuard } from './shared/services/auth-guard/logged-in-auth.guard';
import { PublicServiceTemplateOneComponent } from './pages/public-service-template-one/public-service-template-one.component';
import { PublicServiceTemplateTwoComponent } from './pages/public-service-template-two/public-service-template-two.component';
import { PublicServiceTemplateThreeComponent } from './pages/public-service-template-three/public-service-template-three.component';
import { TaxSettingsComponent } from './pages/tax-settings/tax-settings.component';
import { CustomerTimelineComponent } from './pages/customer-timeline/customer-timeline.component';
import { ServiceAgreementTemplateContentEditorComponent } from './pages/service-agreement-template-content-editor/service-agreement-template-content-editor.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PublicWidgetViewComponent } from './pages/public-widget-view/public-widget-view.component';
import { ScheduleCalendarComponent } from './pages/schedule-calendar/schedule-calendar.component';
import { TimesheetComponent } from './pages/timesheet/timesheet.component';
import { ChecklistComponent } from './pages/checklist/checklist.component';
import { QuickbooksComponent } from './pages/quickbooks/quickbooks.component';
import { SocialProfileComponent } from './pages/social-profile/social-profile.component';
import { ChecklistTodoComponent } from './pages/checklist-todo/checklist-todo.component';
import { ChecklistAddComponent } from './pages/checklist-add/checklist-add.component';
import { ChecklistSelfComponent } from './pages/checklist-self/checklist-self.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [LoggedInAuthGuard]
  },
  // Commented for disabling register
  // {
  //   path: 'register',
  //   component: RegisterComponent,
  // },
  {
    path: 'forgot-password',
    component: ForgorPasswordComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'invoices',
    component: InvoicesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'invoices/:customer_id',
    component: InvoicesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'markup-invoices',
    component: MarkupInvoicesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'invoice-self/:invoice_id/:invoice_index',
    component: InvoiceSelfComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'template-1/:section/:invoice_id',
    component: PublicTemplateOneComponent,
  },
  {
    path: 'template-2/:section/:invoice_id',
    component: PublicTemplateTwoComponent,
  },
  {
    path: 'template-3/:section/:invoice_id',
    component: PublicTemplateThreeComponent,
  },
  {
    path: 'service-template-1/:section/:service_agreement_id',
    component: PublicServiceTemplateOneComponent,
  },
  {
    path: 'service-template-2/:section/:service_agreement_id',
    component: PublicServiceTemplateTwoComponent,
  },
  {
    path: 'service-template-3/:section/:service_agreement_id',
    component: PublicServiceTemplateThreeComponent,
  },
  {
    path: 'new-invoice/:step',
    component: NewInvoiceComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'edit-invoice/:step/:invoice_id/:invoice_index',
    component: NewInvoiceComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'invoice-pay/:invoice_id/:invoice_index',
    component: InvoicePayComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'invoice-send/:invoice_id/:invoice_index',
    component: InvoiceSendComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'expenses',
    component: ExpensesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'expense-new',
    component: ExpenseNewComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: 'expense-edit/:expense_id',
    component: ExpenseNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'expenses-self/:expense_id',
    component: ExpensesSelfComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'customer',
    component: CustomerComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'customer-self/:customer_id',
    component: CustomerSelfComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'customer-new',
    component: CustomerNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'customer-timeline/:customer_id',
    component: CustomerTimelineComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'edit-customer/:customer_id',
    component: CustomerNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'products',
    component: ItemsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'product-self/:item_id',
    component: ItemSelfComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'product-new',
    component: ItemNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'item-edit/:item_id',
    component: ItemNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'invoice-template',
    component: InvoiceTemplateComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'service-agreements-templates',
    component: ServiceAgreementsTemplatesComponent,
    canActivate: [AuthGuardService],
  },
   {
    path: 'service-agreements-1/:template_name',
    component: ServiceAgreementsTemplatesOneComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'service-agreements-2/:template_name',
    component: ServiceAgreementsTemplatesTwoComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'service-agreements-3/:template_name',
    component: ServiceAgreementsTemplatesThreeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'service-agreements-editior/:template_name',
    component: ServiceAgreementTemplateContentEditorComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'list-agreement',
    component: ListAgreementComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'create-agreement',
    component: CreateAgreementComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'edit-agreement/:agreement_id',
    component: CreateAgreementComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'service-agreement-self/:agreement_id',
    component: ServiceAgreementSelfComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'checkList-self/:checklist_id',
    component: ChecklistSelfComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'estimate',
    component: EstimateComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'estimate/:customer_id',
    component: EstimateComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'estimate-self/:estimate_id/:estimate_index',
    component: EstimateSelfComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'checklist',
    component: ChecklistComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'checklist/:section_id/:checklist_id/:section',
    component: ChecklistTodoComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'checklist-add',
    component: ChecklistAddComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'checklist-edit/:checklist_id',
    component: ChecklistAddComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'checklist-edit/:checklist_id',
    component: ChecklistAddComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'checklist-edit/:checklist_id',
    component: ChecklistAddComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'estimate-new/:step',
    component: EstimateNewComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: 'estimate-edit/:step/:estimate_id/:estimate_index',
    component: EstimateNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'estimate-pay/:estimate_id/:estimate_index',
    component: EstimatePayComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'estimate-send/:estimate_id/:estimate_index',
    component: EstimateSendComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'add-service/:step',
    component: PriceBookComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'services',
    component: ServicesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'service-new',
    component: ServiceNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'service-edit/:service_id',
    component: ServiceNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'service-detail/:service_id',
    component: ServiceDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'business-profile',
    component: BusinessProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'payment-method',
    component: PaymentMethodComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'add-user',
    component: AddUserComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'edit_user/:user_id',
    component: AddUserComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'account-details',
    component: AccountDetailsComponent,
    // canActivate: [AuthGuardService],
  },
  // Disabling schedule routes
  {
    path: 'schedule',
    component: ScheduleComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'schedule-detail/:widget_service_id',
    component: ScheduleDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-schedule',
    component: MyScheduleComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-schedule-detail/:widget_service_id',
    component: MyScheduleDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'schedule-calendar',
    component: ScheduleCalendarComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'upgrade-add-ons',
    component: UpgradeAddOnsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'add-on-details/:module_id',
    component: AddOnDetailsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-add-ons',
    component: MyAddOnsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'recurring-invoice',
    component: RecurringInvoiceComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'widget-code',
    component: WidgetCodeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'plan-settings',
    component: PlanSettingsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'invoice-report',
    component: InvoiceReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'payment-report',
    component: PaymentReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'sales-report',
    component: SalesReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'customer-report',
    component: CustomerReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'template-1',
    component: InvoiceTemplateOneComponent,
  },
  {
    path: 'template-2',
    component: InvoiceTemplateTwoComponent,
  },
  {
    path: 'template-3',
    component: InvoiceTemplateThreeComponent,
  },
  {
    path: 'pay-invoice/:invoice_id',
    component: PaymentComponent,
  },
  {
    path: 'payment-invoice/:invoice_id',
    component: SquarePaymentComponent,
  },
  {
    path: 'payment/:invoice_id',
    component: AuthorizeNetComponent,
  },
  {
    path: 'work-order',
    component: WorkOrderComponent,
  },
  {
    path: 'work-order/:customer_id',
    component: WorkOrderComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'work-order-new/:step',
    component: WorkOrderNewComponent,
  },
  {
    path: 'work-order-pay/:estimate_id/:estimate_index',
    component: WorkOrderPayComponent,
  },
  {
    path: 'work-order-self/:work_order_id/:work_order_index',
    component: WorkOrderSelfComponent,
  },
  {
    path: 'work-order-send/:work_order_id/:work_order_index',
    component: WorkOrderSendComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'work-order-edit/:step/:work_order_id/:work_order_index',
    component: WorkOrderNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'tax-settings',
    component: TaxSettingsComponent,
    canActivate: [AuthGuardService],
  },
  // Routing from Customer section
  {
    path: 'new-invoice/:step/:customer_id',
    component: NewInvoiceComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'estimate-new/:step/:customer_id',
    component: EstimateNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'work-order-new/:step/:customer_id',
    component: WorkOrderNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'create-agreement/:customer_id',
    component: CreateAgreementComponent,
    canActivate: [AuthGuardService],
  },

  // Routing from item section
  {
    path: 'new-invoice/:step/:id/:group',
    component: NewInvoiceComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'estimate-new/:step/:id/:group',
    component: EstimateNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'work-order-new/:step/:id/:group',
    component: WorkOrderNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'quickbooks',
    component: QuickbooksComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: 'book-a-visit/:organization_id',
    component: PublicWidgetViewComponent,
  },
  {
    path: 'create-agreement/:id/:group',
    component: CreateAgreementComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'timesheet', 
    component: TimesheetComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'social-profile', 
    component: SocialProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'social-profile-edit/:social_profile_id',
    component: SocialProfileComponent,
    canActivate: [AuthGuardService],
  },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/page-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
