import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class InvoiceModel {
  public invoice_id: string = null;
  public customer_id: string = null;
  public items: any[] = [];
  public expense: any[] = [];
  public rate: string = null;
  public line_item: string = null;
  public expenses: string = null;
  public discount: any = null;
  public discount_type: string = null;
  public sales_tax: any = null;
  public due_date: any = null;
  public created_at: any = null;
  public updated_at: any = null;
  public advance: any = null;
  public advance_type: string = null;
  public quantity: any = null;
  public note: string = null;
  public private_note: any =null;
  public amount: any = null;
  public total: any = null;
  public invoice_number: any = null;
  public customer_name: string = null;
  public customer_phone: any = null;
  public customer_email: string = null;
  public is_signed: any;
  public signature: any;
  public assigned_to: any =null;
  public recurring_type: any =null;
  public invoice_recurring_details_id: string = null;
  public select_interval: boolean =false;
  public day: any =null;
  public month: any =null;
  public quarter: any =null;
  public work_order_update: boolean =false;
  public work_order_id: string =null;
  public checklist_id: string = null;
  public alternative_number: any[] = [];
  public advance_paid: boolean =false;
  public net_amount: any = 0;
  public gross_amount: any = 0;
  public is_draft_save: boolean =false;
  public draft_reset: boolean =false;
}