import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { InvoiceSignatureModel } from "src/app/shared";
import { SignatureService } from "src/app/shared/services/signature/signature.service";
import { InvoiceService } from "src/app/shared/services/invoice/invoice.service";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { ServiceAgreementService } from "src/app/shared/services/service-agreement/service-agreement.service";
import { NgxSignatureOptions, NgxSignaturePadComponent } from "@eve-sama/ngx-signature-pad";
import { Platform } from "@angular/cdk/platform";

@Component({
  selector: "app-signature",
  templateUrl: "./signature.component.html",
  styleUrls: ["./signature.component.scss"],
  styles: [
    `
      :host >>> canvas {
        width: 100%;
        border: 1px dashed #4f5d6b;
        border-radius: 4px;
      }
    `,
  ],
})
export class SignatureComponent implements OnInit {
  @ViewChild('SignaturePad', {static: false}) signaturePad: NgxSignaturePadComponent;
  @Input() invoice_id: any;
  @Input() service_agreement_id: any;
  @Input() provider: any;

  public signaturePadOptions: NgxSignatureOptions = {
    width: 500,
    height: 300,
  };

  public signaturePadOptionsMobile: NgxSignatureOptions = {
    width: 0,
    height: 0,
  };

  public SignatureModel: InvoiceSignatureModel = new InvoiceSignatureModel();
  // public BC: BroadcastChannel = new BroadcastChannel('Signature');

  public svg: ArrayBuffer | string = null;
  public organization_id: string = null;
  public template: number = null;
  public is_signed: boolean = false;
  public signature: any = null;
  public is_signed_receiver: boolean = false;
  public receiver_signature: any = null;
  public onClose: Subject<boolean>;
  public width: number = null;

  constructor(
    public modalRef: BsModalRef,
    public SignatureService: SignatureService,
    public _invoiceService: InvoiceService,
    public router: Router,
    public AgreementService: ServiceAgreementService,
    public platform: Platform,
  ) {
    this.invoice_id = this.SignatureService.getSignatureData();
    this.service_agreement_id =
      this.SignatureService.getServiceAgreementSignatureData();
    this.provider =
      this.SignatureService.getServiceAgreementSignatureProvider();
    if (this.invoice_id != null) {
      this.getInvoiceDetails(this.invoice_id);
    } else if (this.service_agreement_id != null) {
      this.getServiceAgreementDetailsthis(this.service_agreement_id);
    }
  }

  ngOnInit() {
    this.onClose = new Subject();
    this.width = window.outerWidth;

    if ((this.platform.ANDROID || this.platform.IOS) && this.width < 768) {
      this.calcMiniscreenSize();
    }
  }

  ngAfterViewInit() {
  }

  drawComplete() {
    this.svg = this.signaturePad.toDataURL();
  }

  drawStart() {
  }

  private calcMiniscreenSize(): void {
    const width = document.querySelector('body').clientWidth;
    const height = (width * 2) / 3;
    this.signaturePadOptionsMobile.width = width;
    this.signaturePadOptionsMobile.height = height;
  }
  

  getInvoiceDetails(invoice_id) {
    this._invoiceService.InvoiceView(invoice_id).subscribe((result) => {
      this.organization_id = result["data"]["organization_id"];
      if (this.organization_id != null) {
        this.getOrganizationDetails(this.organization_id);
      }
    });
  }

  getServiceAgreementDetailsthis(service_agreement_id) {
    this.AgreementService.GetServiceAgreementById(
      service_agreement_id
    ).subscribe((result) => {
      this.is_signed = result["data"].service_agreement_signature.is_signed;
      this.signature = result["data"].service_agreement_signature.signature;
      this.is_signed_receiver =
        result["data"].service_agreement_signature.is_signed_receiver;
      this.receiver_signature =
        result["data"].service_agreement_signature.receiver_signature;
      this.organization_id = result["data"]["organization_id"];
      if (this.organization_id != null) {
        this.getOrganizationDetails(this.organization_id);
      }
    });
  }

  getOrganizationDetails(organization_id) {
    this._invoiceService
      .GetOrganizationDetails(organization_id)
      .subscribe((result) => {
        if (result["success"]) {
          this.template = result["data"].service_agreement_template;
        }
      });
  }

  Save() {
    if (this.svg != null && this.svg != "") {
      if (this.invoice_id) {
        this.SignatureModel.invoice_id = this.invoice_id;
        this.SignatureModel.signature = this.svg;
        this.SignatureModel.is_signed = 1;
        this.SignatureService.SendSignatureURL(this.SignatureModel.signature);

        this.SignatureService.SaveSignature(this.SignatureModel).subscribe(
          (result) => {
            this.onClose.next(true);
            this.modalRef.hide();

            if (result["success"]) {
              if (this.template == 1 || this.template == null) {
                this.router.navigate([
                  "template-1/",
                  "invoice",
                  this.invoice_id,
                ]);
              } else if (this.template == 2) {
                this.router.navigate([
                  "template-2/",
                  "invoice",
                  this.invoice_id,
                ]);
              } else {
                this.router.navigate([
                  "template-3/",
                  "invoice",
                  this.invoice_id,
                ]);
              }
            }
          }
        );
      } else if (this.service_agreement_id) {

        this.SignatureModel.service_agreement_id = this.service_agreement_id;
        if (this.provider == "Service_Provider") {
          this.SignatureModel.is_signed = 1;
          this.SignatureModel.signature = this.svg;
          this.SignatureModel.is_signed_receiver = this.is_signed_receiver;
          this.SignatureModel.receiver_signature = this.receiver_signature;
          this.SignatureService.SendSignatureURL(this.SignatureModel.signature);

          this.SignatureService.SaveServiceAgreementSignature(
            this.SignatureModel
          ).subscribe((result) => {
            this.onClose.next(true);
            this.modalRef.hide();

            if (result["success"]) {
              if (this.template == 1 || this.template == null) {
                this.router.navigate([
                  "service-template-1/",
                  "service-agreement",
                  this.service_agreement_id,
                ]);
              } else if (this.template == 2) {
                this.router.navigate([
                  "service-template-2/",
                  "service-agreement",
                  this.service_agreement_id,
                ]);
              } else {
                this.router.navigate([
                  "service-template-3/",
                  "service-agreement",
                  this.service_agreement_id,
                ]);
              }
            }
          });
        } else if (this.provider == "Service_Receiver") {
          this.SignatureModel.is_signed = this.is_signed;
          this.SignatureModel.signature = this.signature;
          this.SignatureModel.is_signed_receiver = 1;
          this.SignatureModel.receiver_signature = this.svg;
          this.SignatureService.SendSignatureURL(this.SignatureModel.signature);

          this.SignatureService.SaveServiceAgreementSignature(
            this.SignatureModel
          ).subscribe((result) => {
            this.onClose.next(true);
            this.modalRef.hide();

            if (result["success"]) {
              if (this.template == 1 || this.template == null) {
                this.router.navigate([
                  "service-template-1/",
                  "service-agreement",
                  this.service_agreement_id,
                ]);
              } else if (this.template == 2) {
                this.router.navigate([
                  "service-template-2/",
                  "service-agreement",
                  this.service_agreement_id,
                ]);
              } else {
                this.router.navigate([
                  "service-template-3/",
                  "service-agreement",
                  this.service_agreement_id,
                ]);
              }
            }
          });
        }
      }
    }
  }
}
