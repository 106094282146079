import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EstimateService } from 'src/app/shared/services/estimate/estimate.service';
import { EstimateModel, PaymentModel, PaymentSettings, } from 'src/app/shared';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BroadcastChannel } from 'broadcast-channel';
import { FormBuilder, Validators } from "@angular/forms";
import { UserService } from 'src/app/shared/services/user/user.service';
import { AdvancePaymentComponent } from 'src/app/layout/modal/advance-payment/advance-payment/advance-payment.component';
import { Subscription } from 'rxjs';

declare type Message = {
  message: string;
};

@Component({
  selector: 'app-estimate-self',
  templateUrl: './estimate-self.component.html',
  styleUrls: ['./estimate-self.component.scss']
})
export class EstimateSelfComponent implements OnInit {
  public loading: boolean = false;
  public estimate_id: string = null;
  public estimate_index: any = null;
  public email_sent: any = null;
  public EstimateModel: EstimateModel = new EstimateModel;
  public modalRef: BsModalRef;
  public _estimateData: any = null;
  public item_total: number = 0;
  public discount: any = 0;
  public sales_tax: any = 0;
  public advance: any = 0;
  public amount: number = 0;
  public estimate_items: any[] = [];
  // public amount: number = 0;
  public customer_name: string = null;
  public customer_email: string = null;
  public mobile: any = null;
  public loading1: boolean = false;
  public accepted1: boolean = false;
  public loading2: boolean = false;
  public accepted2: boolean = false;
  public organization_id: string = null;
  public organization_name: string = null;
  public template: any = null;
  public userData:any = null;
  public BC2: BroadcastChannel<Message> = null;
  public env = environment; 
  public checklist_enabled: boolean = false;
  public enabled_modules: any[] = [];
  public submitted: boolean = false;
  public userlist: any[] = [];
  public alternative_number: any[] = [];
  public isEndDate: boolean = false;
  public minStartDate: any = null;
  public minEndDate: any = null;
  public checklist_id: string = null;
  
  public createNewWorkOrder = this.fb.group({
    assign_to: ["", Validators.required],
    start_date: ["", Validators.required],
    end_date: [""]
  });
  public PaymentData: PaymentSettings = new PaymentSettings();
  public secret_key: any = null;

  constructor(
    public activateRoute: ActivatedRoute,
    public EstimateService: EstimateService,
    public EstimateDetails: EstimateService,
    public _modalService: BsModalService,
    public toastr: ToastrService,
    public router: Router,
    public Auth:AuthService,
    public SettingService:SettingService,
    private sanitizer:DomSanitizer,
    public fb: FormBuilder,
    public UserService: UserService
  ) {
    this.userData = this.Auth.User();
    (err:HttpErrorResponse) => {
      if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
        this.SettingService.LogOut();
      }
    }
    this.organization_id = this.userData.user.organization_id;
    this.getOrganizationDetails(this.organization_id);
    this.estimate_id = this.activateRoute.snapshot.paramMap.get('estimate_id');
    this.estimate_index = this.activateRoute.snapshot.paramMap.get('estimate_index');
    this.getEstimateDetails(this.estimate_id);
    if ( JSON.parse( localStorage.getItem( 'activated_modules' ) ) )
    {
      this.enabled_modules = JSON.parse( localStorage.getItem( 'activated_modules' ) );
      for ( let index of this.enabled_modules )
      {
        if ( index[ 'module_id' ] == '50bf5d16f34b' && index[ 'enabled' ] == 1)
        {
          this.checklist_enabled = true;
        }
      }
    }
  }

  ngOnInit() {
    this.channel2();
    this.BC2.onmessage = (e) => {
      if (e.message) {
        location.reload();
      }
    }
    window.scroll(0, 0);
    this.getUserList();
    this.minStartDate = new Date();
    this.minStartDate.setDate(this.minStartDate.getDate());
    this.minEndDate = new Date();
    this.minEndDate.setDate(this.minEndDate.getDate());
  }

  public subsciption: Subscription;
  ngAfterViewInit() {
    this.subsciption = this.EstimateService.approveInvoiceState.subscribe((response) => {
      if(response) {
        this.getEstimateDetails(this.estimate_id);
      }
    })
  }

  ngOnDestroy(){
    this.subsciption.unsubscribe()
  }

  getUserList() {
    this.loading = true;
    this.UserService.UserList()
      .subscribe((result) => {
        if (result['success']) {
          this.loading = false;
          this.userlist = result['data'];
        }
        else {
          this.loading = false;
          
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  channel2() {
    this.BC2 = new BroadcastChannel('approved', {
      idb: {
        onclose: () => {
          this.BC2.close();
          this.channel2();
        }
      }
    });
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    let transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;

    return transformedData;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  getEstimateDetails(estimate_id) {
    if (estimate_id != null) {
      this.loading = true;

      this.EstimateService.GetEstimateDetails(estimate_id)
        .subscribe((result) => {
          if (result['success']) {
            
            this.loading = false;
            this._estimateData = result['data'];
            this.EstimateModel.estimate_id = this._estimateData.estimate_id;
            this.EstimateModel.is_approved = this._estimateData.is_approved;
            this.EstimateModel.work_order_id = this._estimateData.work_order_id;
            this.EstimateModel.assign_to = this._estimateData.assign_to;
            this.EstimateModel.due_date = this._estimateData.due_date;
            this.EstimateModel.start_date = this._estimateData.start_date;
            this.EstimateModel.end_date = this._estimateData.end_date;
            this.EstimateModel.organization_id = this._estimateData.organization_id;
            this.EstimateModel.advance_paid = this._estimateData.advance_paid;
            this.EstimateModel.net_amount = this._estimateData.net_amount;
            this.EstimateModel.gross_amount = this._estimateData.gross_amount;
            this.minStartDate = new Date();
            this.minStartDate.setDate(this.minStartDate.getDate());
            this.minEndDate = new Date();
            if(this.EstimateModel.start_date){

              this.minEndDate.setDate(this.EstimateModel.start_date.getDate());
            }
            
            this.EstimateModel.note = this._estimateData.note
            this.EstimateModel.private_note = this._estimateData.private_note
            if (this._estimateData['estimate_customers'] != null && this._estimateData['estimate_customers'].length > 0) {
          
              this.customer_name = this._estimateData['estimate_customers'][0].name;
              this.customer_email = this._estimateData['estimate_customers'][0].email;
              this.mobile = this._estimateData['estimate_customers'][0].mobile;
              this.alternative_number = this._estimateData['estimate_customers'][0].alternative_number;
            }

            if (result['data']['estimate_estimateitems'] != null && result['data']['estimate_estimateitems'].length > 0) {
              // for(let index of result['data']['estimate_estimateitems']){
              //   this.item_name.push(index.name);
              //   this.rates.push(index.rate);
              // }

              this.estimate_items = result['data']['estimate_estimateitems'];
              for (let i of this.estimate_items) {
                if(i.group=='Product'){
                  if(i.estimateitems_items_with_trashed.tax_rate){
                    this.item_total = this.item_total+ i.quantity * (+i.rate+(+i.rate*(i.estimateitems_items_with_trashed.tax_rate_data.rate/100)));
                  }else{
                    this.item_total = this.item_total + (+i.rate * i.quantity);
                  }
                }else if(i.group=='Service'){
                  if(i.estimateitems_service_with_trashed.tax_rate){
                    this.item_total = this.item_total+ i.quantity * (+i.rate+(+i.rate*(i.estimateitems_service_with_trashed.tax_rate_data.rate/100)));
                  }else{
                    this.item_total = this.item_total + (+i.rate * i.quantity);
                  }
                }
              }
            }

            this.EstimateModel.line_item = this._estimateData.estimate_estimateitems.item_id;
            this.EstimateModel.discount = this._estimateData.discount;
            this.EstimateModel.estimate_number = this._estimateData.estimate_number;
            this.EstimateModel.discount_type = this._estimateData.discount_type;
            this.EstimateModel.advance = this._estimateData.advance;
            this.EstimateModel.advance_type = this._estimateData.advance_type;
            this.EstimateModel.sales_tax = this._estimateData.sales_tax;
            this.EstimateModel.rate = this._estimateData.estimate_estimateitems.rate;            
            this.EstimateModel.total = this._estimateData.gross_amount;
            this.EstimateModel.created_at = this._estimateData.created_at;
            this.EstimateModel.updated_at = this._estimateData.updated_at;            
          }
          if (this.EstimateModel.discount != null && this.EstimateModel.discount != '') {
            if (this.EstimateModel.discount_type == '%') {
              this.discount = (this.item_total * (+this.EstimateModel.discount)) / 100;
              this.amount = (+this.item_total) - this.discount;
            } else if (this.EstimateModel.discount_type == '$') {
              this.discount = (+this.EstimateModel.discount);
              this.amount = this.item_total - (+this.EstimateModel.discount);
            }
          }
  
          if (this.EstimateModel.sales_tax != null && this.EstimateModel.sales_tax != '') {
            if (this.discount == 0) {
              this.sales_tax = (this.item_total * (+this.EstimateModel.sales_tax)) / 100;
              this.amount = this.item_total + this.sales_tax;
  
  
            } else {
              this.sales_tax = (this.amount * (+this.EstimateModel.sales_tax)) / 100;
              this.amount = this.amount + this.sales_tax;
            }
  
          }
          if (this.EstimateModel.advance != null && this.EstimateModel.advance != '') {
            
            if (this.discount == 0 && this.sales_tax == 0) {

  
              if (this.EstimateModel.advance_type == '%') {

                this.advance = (this.item_total * (+this.EstimateModel.advance)) / 100;

              } else if (this.EstimateModel.advance_type == '$') {

                this.advance =  (+this.EstimateModel.advance);
                // this.advance = this.item_total - (+this.EstimateModel.advance);
              }
            } else if (this.discount == 0 && this.sales_tax != 0) {

  
              if (this.EstimateModel.advance_type == '%') {
  
                this.advance = (this.amount * (+this.EstimateModel.advance)) / 100;
  
              } else if (this.EstimateModel.advance_type == '$') {
                this.advance =  (+this.EstimateModel.advance);
                // this.advance = this.amount - (+this.EstimateModel.advance);
              }
            } else if (this.discount != 0 && this.sales_tax == 0) {
  
              if (this.EstimateModel.advance_type == '%') {
                this.advance = (this.amount * (+this.EstimateModel.advance)) / 100;
              } else if (this.EstimateModel.advance_type == '$') {
                this.advance =  (+this.EstimateModel.advance);
                // this.advance = this.amount - (+this.EstimateModel.advance);
              }
            } else {

              if (this.EstimateModel.advance_type == '%') {
                
                this.advance = (this.amount * (+this.EstimateModel.advance)) / 100;
              } else if (this.EstimateModel.advance_type == '$') {
                this.advance =  (+this.EstimateModel.advance);
                // this.advance = this.amount - (+this.EstimateModel.advance);
  
              }
            }
            this.discount = parseFloat(this.discount).toFixed(2);
            this.advance = parseFloat(this.advance).toFixed(2);
            this.sales_tax = parseFloat(this.sales_tax).toFixed(2);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }

      )
    }
  }

  DeleteEstimate(estimate_id) {
    if (estimate_id != null) {
      this.EstimateService.DeleteEstimate(estimate_id)
        .subscribe((result) => {
          if (result['success']) {
            this.modalRef.hide();
            this.toastr.success('Deleted Successfully.');
            this.router.navigate(['/estimate']);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

  getOrganizationDetails(organization_id) {
    this.SettingService.GetAccountDetails(organization_id).subscribe(
      result => {
        this.template = result['data']['template'];
        this.organization_name=result['data']['business_name'];
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  sanitize(){
    return this.sanitizer.bypassSecurityTrustUrl('sms:'+this.mobile+';?&body=Dear '+this.customer_name+', %0a%0aThank you for your business. %0a%0aYour estimate '+this.EstimateModel.estimate_number+' can be viewed, printed, or downloaded as a PDF file from the link below. You can also choose to pay it online. %0a%0aClick to view Estimate %0a'+this.env.frontend_url+'template-'+this.template+'/estimate/'+this.estimate_id+'  %0a%0aWe look forward to doing more business with you. %0a%0aRegards, %0a'+this.userData.user.name+' %0a'+this.organization_name);
    // return this.sanitizer.bypassSecurityTrustUrl('sms:'+this.mobile+';?&body=Dear '+this._invoiceData.customer_name+', Thank you for your business. Your invoice '+this._invoiceData.invoice_number+' can be viewed, printed, or downloaded as a PDF file from the link below. You can also choose to pay it online. Click to view Invoice http://111.93.175.214/invoiceapppwa/display/template-'+this.template+'/'+this.invoice_id+'  We look forward to doing more business with you. Regards, '+this.userData.user.name+' '+this.organization_name);
  }

  getDataUrl(template,id){
    return this.env.frontend_url+"template-"+template+'/estimate/'+id;
  }

  copy(evt: Event){
    let url: string = evt.target['dataset'].url;

    document.addEventListener('copy', function(evt) {
      evt.clipboardData.setData('text/plain', url);
      evt.preventDefault();
    }, true);

    let result = document.execCommand('copy');

    if (result) {
      this.toastr.success('Successfully Copied');
    }
    else {
      this.toastr.error('Not Copied');
    }
    this.getPaymentData(this.organization_id);
    // this.clipboard.writeText(url).then(() => {
    //   this.toastr.success('Successfully Copied');
    // }, () => {
    //   this.toastr.error('Not Copied');
    // });
  }

  ApproveEstimate(){
    if(this.EstimateModel.advance) {
      const initialState: ModalOptions = {
        initialState: {
          estimate_id: this.EstimateModel.estimate_id,
          organization_id: this.EstimateModel.organization_id,
          ignoreBackdropClick: true,
          advance: this.advance
        }
      };
      this.modalRef = this._modalService.show(AdvancePaymentComponent,  initialState);
    } else {
      this.EstimateService.Approve(this.estimate_id).subscribe(
      result=>{
        if(result['data']){
          this.toastr.success(result['msg']);
          this._estimateData=result['data'];
          this.EstimateModel.is_approved = this._estimateData.is_approved;
        }else{
          this.toastr.success(result['msg']);
        }
      
  
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      })
    }

  }

  get f() {
    return this.createNewWorkOrder.controls;
  }

  openCreateWorkorder(template: TemplateRef<any>) {
    this.submitted = false;
    this.modalRef = this._modalService.show(template);
    this.createNewWorkOrder.get('start_date').setValue('');
    this.createNewWorkOrder.get('assign_to').setValue('');
    this.createNewWorkOrder.get('assign_to').updateValueAndValidity();
    this.EstimateModel.assign_to = null;
  }

  createWorkOrder(){
    this.submitted  = true;
    if(this.createNewWorkOrder.invalid){
      return;
    }
    this.EstimateModel.end_date = this.createNewWorkOrder.get('start_date').value[1];
    this.EstimateModel.start_date = this.createNewWorkOrder.get('start_date').value[0];
    this.EstimateModel.assign_to = this.createNewWorkOrder.get('assign_to').value;
    let get_estimate_checklist_id = JSON.parse(localStorage.getItem('estimate_checklist_id'))
    this.EstimateModel.checklist_id = get_estimate_checklist_id;
    // this.EstimateModel.start_date = new Date(this.EstimateModel.start_date);
    // this.EstimateModel.end_date = new Date(this.EstimateModel.end_date);
    this.EstimateService.CreateWorkorder(this.EstimateModel).subscribe(
      (result) => {
        this.submitted = false;
        if (result['work order']) {
          this.EstimateModel.work_order_id = result['work order']['work_order_id']
          this.modalRef.hide();
        }
        if (result["data"]) {
          this.toastr.success(result['msg']);
          this._estimateData = result['data'];
          if (this._estimateData.work_order_id){
            this.EstimateModel.work_order_id = this._estimateData.work_order_id;
          }else if (this._estimateData.assign_to){
            this.EstimateModel.assign_to = this._estimateData.assign_to;
          }
        } else {
          this.toastr.success(result['msg']);
        }


      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      })
  }

  SetEndDateOptions(event){
    if(event!=null){
      this.minEndDate = new Date();
      this.minEndDate.setDate(event.getDate() + 1);
    }
  }

  // CreateWorkorder(){
  //   this.EstimateService.CreateWorkorder(this.estimate_id).subscribe(
  //     (result) => {
  //       console.log("workData", result);
  //       if (result['data']) {
          
  //         this.toastr.success(result['msg']);
  //         this._estimateData = result['data'];
  //         if (this._estimateData.work_order_id){
  //           this.EstimateModel.work_order_id = this._estimateData.work_order_id;
  //         }
  //       } else {
  //         this.toastr.success(result['msg']);
  //       }


  //     }, (err: HttpErrorResponse) => {
  //       if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
  //         this.SettingService.LogOut();
  //       }
  //     })
  // }

  getPaymentData(organization_id){
    this.SettingService.GetPaymentDetails(organization_id).subscribe(
      result => {
        if(result['success']){
          if (result['data']) {
            this.PaymentData = result['data'];
            this.secret_key = result['data']['secret'];
            localStorage.setItem("payment_data", JSON.stringify(result['data'].key));
            // this.EstimateService.paymentKey = result['data'].key;
          }
          if (result['bank_details']) {
            this.PaymentData.bank_details = result['bank_details']['bank_details'];
            this.PaymentData.checked = result['bank_details']['checked'];
            this.PaymentData.bank_detail_id = result['bank_details']['bank_detail_id'];
          }
        }
        
      }
    )
  }
}
