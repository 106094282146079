import { Injectable } from '@angular/core';
import { ValidationService } from '../../services/validation/validation.service';

@Injectable({
    providedIn: 'root'
  })
  export class BaseModel {
    public validate() {
      let _rules = this.rules();
  
      if(Object.keys(_rules).length = 0) {
        console.error("Rules not set. Please set the rules to validate your model.");
      }
      else {
        let _ValidationService = new ValidationService(this.rules(), this);
        return _ValidationService.validate();
      }
    }
  
    public rules() {
      return {};
    }
  }