import { Injectable } from '@angular/core';
import { InvoiceSignatureModel } from '../../models/signature/invoice_signature.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {

  public SignatureModel:InvoiceSignatureModel;
  public invoice_id: string = null;
  public service_agreement_id: string = null;
  public provider:string = null;
  public url: any = null;

  constructor(
    private http: HttpClient,
  ) { }

  getSignatureData(){
    return this.invoice_id;
  }
  sendData(invoice_id){
    this.invoice_id=invoice_id;
  }

  setServiceAgreementData(service_agreement_id){
    this.service_agreement_id=service_agreement_id;
  }

  setServiceAgreementProvider(provider){
    this.provider=provider;
  }

  SendSignatureURL(data){
    this.url=data;
  }

  getServiceAgreementSignatureData(){
    return this.service_agreement_id;
  }

  getServiceAgreementSignatureProvider(){
    return this.provider;
  }

  getSignature(){
    return this.url;
  }

  SaveSignature(data):Observable<[]>{
    let URL = `${environment.url}invoice_signature/edit/${data.invoice_id}`;

    return this.http.post(URL, data, {
    })
      .pipe(map((res: any) => res));
  }

  SaveServiceAgreementSignature(data):Observable<[]>{
    let URL = `${environment.url}serviceagreement/signature-edit/${data.service_agreement_id}`;

    return this.http.post(URL, data, {
    })
      .pipe(map((res: any) => res));
  }
}
