import { Component, OnInit } from '@angular/core';
import { Sidebar, AccountModel } from 'src/app/shared';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { timer } from 'rxjs'
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-invoice-template',
  templateUrl: './invoice-template.component.html',
  styleUrls: ['./invoice-template.component.scss']
})
export class InvoiceTemplateComponent implements OnInit {

  public loading: any[] = [];
  public accepted: any[] = [];
  public templates: any[] = [1, 2, 3];
  public organization_id:string=null;
  public template:any=null;

  constructor(
    public _sidebar: Sidebar,
    public _Auth: AuthService,
    public SettingService:SettingService,
    public InvoiceService:InvoiceService,
    public toastr: ToastrService
    ) {
      let userData = this._Auth.User();
      this.organization_id = userData.user.organization_id;
      this.getOrganizationDetails(this.organization_id);
  }

  ngOnInit() {
    // this.loading[0] = false;
    // this.accepted[0] = true;

    window.scroll(0, 0);
  }

  public AccountData:AccountModel=new AccountModel();

  getOrganizationDetails(org_id){
    this.SettingService.GetAccountDetails(org_id).subscribe(
      result=>{
        this.AccountData.organization_id=result['data']['organization_id'];
        this.AccountData.business_name=result['data']['business_name'];
        this.AccountData.email=result['data']['email'];
        this.AccountData.address_line_1=result['data']['address_line_1'];
        this.AccountData.address_line_2=result['data']['address_line_2'];
        this.template=result['data']['template'];
        if(this.template==null || this.template==1){
          this.loading[0] = false;
          this.accepted[0] = true;
        }else if(this.template==2){
          this.loading[1] = false;
          this.accepted[1] = true;
        }else{
          this.loading[2] = false;
          this.accepted[2] = true;
        }
        // this.AccountData.logo=result['data']['logo'];

        
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }
  Loading(i,template_id) {
    if(this.AccountData.email==null || this.AccountData.email==''){
      this.toastr.error('Please provide the Account Details and then try to set template again!');
      return;
    }
    this.AccountData.template=template_id;

    this.InvoiceService.SetTemplate(this.AccountData).subscribe(
      result=>{

      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )

    let interval = timer(2000);
    this.loading = [];
    this.accepted = [];
    this.loading[i] = true;

    interval.subscribe(() => {
      this.loading[i] = false;
      this.accepted[i] = true;
    });
  }
}
