import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class AddOnService {

  constructor(
    public _http:HttpClient,
    public _TokenService:TokenService
  ) { }

  GetActivatedAddOnList():Observable<[]> {
    let URL = `${environment.url}module`;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  EnableOrDiableAddOn(data):Observable<[]> {
    let URL = `${environment.url}module/`+data['module_id'];

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  GetAllModulesList():Observable<[]> {
    let URL = `${environment.url}module/all-modules`;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }
  
  GetModuleDetail(module_id):Observable<[]> {
    let URL = `${environment.url}module/view/`+module_id;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  AddPriceBookData(file):Observable<[]> {
    let URL = `${environment.url}item/csv-save`
    let formData = new FormData();
    formData.append('documentType',file); 
    return this._http.post(URL,formData,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  ValidateCSV(file):Observable<[]> {
    let URL = `${environment.url}item/csv-validate`
    let formData = new FormData();
    formData.append('documentType',file); 
    return this._http.post(URL,formData,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }
  
}
