import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BroadcastChannel } from 'broadcast-channel';
import { ToastrService } from 'ngx-toastr';
import { CustomerModel, InvoiceModel, PaymentModel, PaymentSettings } from 'src/app/shared';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';

declare type Message = {
  message: string;
};

@Component({
  selector: 'app-authorize-net',
  templateUrl: './authorize-net.component.html',
  styleUrls: ['./authorize-net.component.scss']
})

export class AuthorizeNetComponent implements OnInit {

  public invoice_id: string = null;
  public status: number = null;
  public loading: boolean = false;
  public accepted: boolean = false;
  public loading2: boolean = false;
  public InvoiceModel: InvoiceModel = new InvoiceModel();
  public CustomerModel: CustomerModel = new CustomerModel();
  public PaymentModel: PaymentModel = new PaymentModel();
  public PaymentData: PaymentSettings = new PaymentSettings();
  public organization_id: string = null;

  public submitted: boolean = false;
  public Payment: FormGroup = this.fb.group({
    card_number:['',[Validators.required, Validators.maxLength(16), Validators.minLength(16)]],
    exp_month:['',Validators.required],
    exp_year:['',Validators.required],
    cvv:['',[Validators.required, Validators.maxLength(3),Validators.minLength(3)]],
    postal:['',Validators.required],
  });

  public BC: BroadcastChannel<Message> = new BroadcastChannel('payment');


  constructor(
    public activatedRoute: ActivatedRoute,
    public SettingService: SettingService,
    public InvoiceService: InvoiceService,
    public CustomerService: CustomerService,
    public toastr: ToastrService,
    public fb:FormBuilder
  ) { 
    this.invoice_id = this.activatedRoute.snapshot.paramMap.get('invoice_id');
    if (this.invoice_id != null) {
      this.loading2 = true;
      this.getInvoiceDetails(this.invoice_id);
    }
  }

  ngOnInit() {}

  get AuthorizeFormData() {
    return this.Payment.controls;
  }

  getInvoiceDetails(invoice_id) {
    this.loading2 = true;
    this.InvoiceService.InvoiceView(invoice_id).subscribe(
      result => {
        this.InvoiceModel.customer_id = result['data'].customer_id;
        this.InvoiceModel.invoice_number = result['data'].invoice_number;
        this.InvoiceModel.total = result['data'].total;
        // this.InvoiceModel.total = Math.round(this.InvoiceModel.total);
        this.organization_id = result['data']['organization_id'];

        this.status = result['data'].status;
        if (this.status == 2) {
          this.loading = false;
        }
        this.getPaymentData(this.organization_id);
        this.getCustomerDetails(this.InvoiceModel.customer_id);
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      }
    )
  }

  getPaymentData(organization_id) {
    this.SettingService.GetPaymentDetails(organization_id)
      .subscribe((result) => { 
        this.PaymentData = result['data'];
        this.PaymentModel.authorizePublicClientid = result['data']['public_client_id'];
        this.PaymentModel.authorizeTransactionId = result['data']['transaction_key'];
        this.PaymentModel.authorizeappLoginId = result['data']['login_id'];
    }, (err:HttpErrorResponse) => {
      if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
        this.SettingService.LogOut();
      }
    }
    )
  }

  getCustomerDetails(customer_id) {
    this.CustomerService.GetCustomerById(customer_id).subscribe(
      result => {
        this.loading2 = false;
        this.CustomerModel.customer_id = result['data'].customer_id;
        this.CustomerModel.name = result['data'].name;
        this.CustomerModel.mobile = result['data'].mobile;
        this.CustomerModel.email = result['data'].email;
        this.CustomerModel.address = result['data'].address;
        this.CustomerModel.locality = result['data'].locality;
        this.CustomerModel.zip = result['data'].zip;
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      }
    )
  }

  SetPaymentData() {
    this.loading = true;
    this.submitted = true;
    if (this.Payment.invalid) {
      this.submitted = true;
      this.loading = false;
      return false;
    }

    this.PaymentModel.card_number = this.Payment.value.card_number;
    this.PaymentModel.exp_month = this.Payment.value.exp_month;
    this.PaymentModel.exp_year = this.Payment.value.exp_year;
    this.PaymentModel.cvv = this.Payment.value.cvv;
    this.PaymentModel.zip = this.CustomerModel.zip;
    this.PaymentModel.postal = this.Payment.value.postal;
    this.PaymentModel.amount_to_be_paid = this.InvoiceModel.total;
    this.PaymentModel.invoice_id = this.invoice_id;
    this.PaymentModel.invoice_number = this.InvoiceModel.invoice_number;
    this.PaymentModel.customer_name = this.CustomerModel.name;
    this.PaymentModel.email = this.CustomerModel.email;
    this.PaymentModel.address_line_1 = this.CustomerModel.address;
    this.PaymentModel.address_line_2 = this.CustomerModel.address;
    this.PaymentModel.state = this.CustomerModel.locality;
    this.PaymentModel.mobile = this.CustomerModel.mobile;

    this.InvoiceService.AuthorizeNetCharge(this.PaymentModel).subscribe(
      (result) => {
        console.clear();
        if (result['sucess']) {
          this.submitted = true;
          this.loading = false;
          this.accepted = true;
          this.BC.postMessage({ message: 'Paid' });
          this.showSuccess(result['Description']);
        } else {
          this.submitted = true;
          this.loading = false;
          this.showDanger(result['Description']);
        }
      },
      (error: HttpErrorResponse) => {
        this.submitted = true;
        this.loading = false;
        this.showDanger('Something went wrong');
      }
    )
  }


  showSuccess(msg: string) {
    this.toastr.success(msg);
  }
  showDanger(msg: string) {
    this.toastr.error(msg);
  }

}
