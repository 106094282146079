import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EstimateService } from 'src/app/shared/services/estimate/estimate.service';
import { ServiceAgreementModel } from 'src/app/shared';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BroadcastChannel } from 'broadcast-channel';
import { ServiceAgreementService } from 'src/app/shared/services/service-agreement/service-agreement.service';
import { FormBuilder } from '@angular/forms';

declare type Message = {
  message: string;
};

@Component({
  selector: 'app-service-agreement-self',
  templateUrl: './service-agreement-self.component.html',
  styleUrls: ['./service-agreement-self.component.scss']
})
export class ServiceAgreementSelfComponent implements OnInit{

  public loading: boolean = false;
  public service_agreement_id: string = null;
  public estimate_index: any = null;
  public email_sent: any = null;
  public ServiceAgreementModel: ServiceAgreementModel = new ServiceAgreementModel;
  public modalRef: BsModalRef;
  public service_agreement_data: any = null;
  public service_agreement_work_order: any = null;
  public item_total: number = 0;
  public discount: number = 0;
  public sales_tax: number = 0;
  public advance: number = 0;
  public amount: number = 0;
  public service_agreement_items: any[] = [];
  public alternative_number: any[] = [];
  public customer_name: string = null;
  public customer_email: string = null;
  public mobile: any = null;
  public loading1: boolean = false;
  public accepted1: boolean = false;
  public loading2: boolean = false;
  public accepted2: boolean = false;
  public organization_id: string = null;
  public organization_name: string = null;
  public template: any = null;
  public userData:any = null;
  public BC2: BroadcastChannel<Message> = null;
  public env = environment; 

  public ApproveForm = this.fb.group({
    service_agreement_id: [''],
    create_invoice: [false],
    is_approved: [false],
  })

  //Temporary variable for just design purpose
  public recurring_type = null;

  constructor(
    public activateRoute: ActivatedRoute,
    public EstimateService: EstimateService,
    public AgreementService: ServiceAgreementService,
    public _modalService: BsModalService,
    public toastr: ToastrService,
    public router: Router,
    public Auth:AuthService,
    public SettingService:SettingService,
    private sanitizer:DomSanitizer,
    public fb: FormBuilder
  ) {
    this.userData = this.Auth.User();
    (err:HttpErrorResponse) => {
      if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
        this.SettingService.LogOut();
      }
    }
    this.organization_id = this.userData.user.organization_id;
    this.getOrganizationDetails(this.organization_id);
    this.service_agreement_id = this.activateRoute.snapshot.paramMap.get('agreement_id');
    this.getServiceAgreementDetails(this.service_agreement_id);
  }

  ngOnInit() {
    this.channel2();
    this.BC2.onmessage = (e) => {
      if (e.message) {
      }
    }
    this.recurring_type = localStorage.getItem('recurring_type');
    
    window.scroll(0, 0);
  }

  channel2() {
    this.BC2 = new BroadcastChannel('approved', {
      idb: {
        onclose: () => {
          this.BC2.close();
          this.channel2();
        }
      }
    });
  }



  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    let transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;

    return transformedData;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  getServiceAgreementDetails(service_agreement_id) {
    if (service_agreement_id != null) {
      this.loading = true;

      this.AgreementService.GetServiceAgreementById(service_agreement_id)
        .subscribe((result) => {
          if (result['success']) {
            this.loading = false;
            this.service_agreement_data = result['data'];
            this.ServiceAgreementModel.customer_id = this.service_agreement_data.customer_id;
            this.ServiceAgreementModel.start_date = this.service_agreement_data.start_date;
            this.ServiceAgreementModel.end_date = this.service_agreement_data.end_date;
            this.ServiceAgreementModel.total = this.service_agreement_data.total;
            this.ServiceAgreementModel.private_note = this.service_agreement_data.private_note;
            this.ServiceAgreementModel.create_invoice = this.service_agreement_data.create_invoice;
            this.ServiceAgreementModel.service_agreement_id = this.service_agreement_data.service_agreement_id;
            this.ApproveForm.get('service_agreement_id').setValue(this.service_agreement_id);
            this.ApproveForm.get('service_agreement_id').updateValueAndValidity();
            this.ServiceAgreementModel.is_approved = this.service_agreement_data.is_approved;
            this.ServiceAgreementModel.service_agreement_recurring_details_id = this.service_agreement_data.service_agreement_recurring_details_id;
            this.ServiceAgreementModel.select_interval = result['data'].select_interval;
            if (this.service_agreement_data['serviceagreement_customers'] != null && this.service_agreement_data['serviceagreement_customers'].length > 0) {
          
              this.customer_name = this.service_agreement_data['serviceagreement_customers'][0].name;
              this.customer_email = this.service_agreement_data['serviceagreement_customers'][0].email;
              this.mobile = this.service_agreement_data['serviceagreement_customers'][0].mobile;
              this.alternative_number = this.service_agreement_data['serviceagreement_customers'][0].alternative_number;
            }
            if (result['data']['service_agreement_recurrings'] != null && result['data']['service_agreement_recurrings'] != '') {

              this.ServiceAgreementModel.day = result['data']['service_agreement_recurrings'].day;
              this.ServiceAgreementModel.month = result['data']['service_agreement_recurrings'].month;
              this.ServiceAgreementModel.quarter = result['data']['service_agreement_recurrings'].quarter;
              this.ServiceAgreementModel.recurring_type = result['data']['service_agreement_recurrings']['recurring_type'];
            }
            if (result['data']['serviceagreement_serviceagreementsitems'] != null && result['data']['serviceagreement_serviceagreementsitems'].length > 0) {
              this.service_agreement_items = result['data']['serviceagreement_serviceagreementsitems'];
              this.ServiceAgreementModel.items = result['data']['serviceagreement_serviceagreementsitems'];
              // for (let i of this.service_agreement_items) {
              //   this.item_total = this.item_total + (+i.estimateitems_items.rate * i.quantity);
    
              // }
            }
             
            
            this.ServiceAgreementModel.service_agreement_number = this.service_agreement_data.service_agreement_number;
            this.ServiceAgreementModel.created_at = this.service_agreement_data.created_at;
            this.ServiceAgreementModel.updated_at = this.service_agreement_data.updated_at;
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }

      )
    }
  }

  DeleteAgreement(service_agreement_id) {
    if (service_agreement_id != null) {
      this.AgreementService.DeleteAgreement(service_agreement_id)
        .subscribe((result) => {
          if (result['success']) {
            this.modalRef.hide();
            this.toastr.success('Deleted Successfully.');
            this.router.navigate(['/list-agreement']);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

  getOrganizationDetails(organization_id) {
    this.SettingService.GetAccountDetails(organization_id).subscribe(
      result => {
        this.template = result['data']['service_agreement_template'];
        this.organization_name=result['data']['business_name'];
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  sanitize(){
    return this.sanitizer.bypassSecurityTrustUrl('sms:'+this.mobile+';?&body=Dear '+this.customer_name+', %0a%0aThank you for your business. %0a%0aYour service agreement '+this.ServiceAgreementModel.service_agreement_number+' can be viewed, printed, or downloaded as a PDF file from the link below. You can also choose to pay it online. %0a%0aClick to view service agreement %0a'+this.env.frontend_url+'service-template-'+this.template+'/service-agreement/'+this.service_agreement_id+'  %0a%0aWe look forward to doing more business with you. %0a%0aRegards, %0a'+this.userData.user.name+' %0a'+this.organization_name);
  }

  getDataUrl(template,id){
    return this.env.frontend_url+"service-template-"+template+'/service-agreement/'+id;
  }

  copy(evt: Event){
    let url: string = evt.target['dataset'].url;

    document.addEventListener('copy', function(evt) {
      evt.clipboardData.setData('text/plain', url);
      evt.preventDefault();
    }, true);

    let result = document.execCommand('copy');

    if (result) {
      this.toastr.success('Successfully Copied');
    }
    else {
      this.toastr.error('Not Copied');
    }
  }

  ApproveServiceArgreement(service_agreement_id){
    this.ApproveForm.get('is_approved').setValue(true);
    this.ApproveForm.get('is_approved').updateValueAndValidity();
    this.AgreementService.ApproveServiceAgreement(this.ApproveForm.value).subscribe(
    result=>{      
      if(result['success']){
        this.ServiceAgreementModel.is_approved = true;   
        this.toastr.success(result['msg']); 
      }else{
        this.toastr.error(result['msg']); 
      }
    }, (err:HttpErrorResponse) => {
      if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
        this.SettingService.LogOut();
      }
    })
  }

  CreateInvoice(){
    if(this.ServiceAgreementModel.is_approved){
      this.ApproveForm.get('create_invoice').setValue(true);
      this.ApproveForm.get('create_invoice').updateValueAndValidity();
      this.ApproveServiceArgreement(this.service_agreement_id);
    }else{
        this.toastr.warning('You need to approve the service agreement by clicking on Approve button!'); 

    }
  }
}
