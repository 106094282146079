import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ItemModel } from 'src/app/shared';
import { ServiceModuleService } from 'src/app/shared/services/service/service-module.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss']
})
export class ServiceDetailComponent implements OnInit {

  public loading: boolean = false;
  public service_id: string = null;
  public modalRef: BsModalRef;
  public ItemModal: ItemModel = new ItemModel();
  public enabled_modules: any[] = [];
  public service_agreement_enabled: boolean = false;
  public work_order_enabled: boolean = false;


  constructor(
    public activateRoute: ActivatedRoute,
    public serviceService: ServiceModuleService,
    public SettingService: SettingService,
    public _modalService: BsModalService,
    public toastr: ToastrService,
    public router: Router,
    ) { 
    this.service_id = this.activateRoute.snapshot.paramMap.get('service_id');
    this.getServiceDetails(this.service_id);
    if (JSON.parse(localStorage.getItem('activated_modules'))) {
      this.enabled_modules = JSON.parse(localStorage.getItem('activated_modules'));
      for (let index of this.enabled_modules) {

        if (index['module_id'] == '5ece4797eaf5e' && index['enabled']) {
          this.service_agreement_enabled = true;
        } else if (index['module_id'] == '606f1bc66d62b' && index['enabled']) {
          this.work_order_enabled = true;
        }
      }
    }
  }

  ngOnInit() {
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  deleteItem(){
    if (this.service_id != null) {
      this.serviceService.DeleteService(this.service_id).subscribe(
        result => {
          if (result['success']) {
            this.modalRef.hide();

            this.showSuccess(result['msg']);
            this.router.navigate(['/services']);
          } else {
            this.showDanger(result['msg']);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }

  }

  getServiceDetails(service_id){
    this.serviceService.GetServiceById(service_id).subscribe(
      (result)=>{
        this.ItemModal.service_id = result['data'].service_id;
          this.ItemModal.service_number = result['data'].service_number;
          this.ItemModal.name = result['data'].name;
          this.ItemModal.description = result['data'].description;
          this.ItemModal.service_type = result['data'].service_type;
          this.ItemModal.rate = result['data'].rate;
          this.ItemModal.video_url = result['data'].video_url;
          this.ItemModal.is_active = result['data'].is_active;
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        } 
    )
    
  }

  NavigateToInvoiceWithProduct() {
    let data = {
      group: "Service",
      id: this.service_id,
    }
    localStorage.setItem('product', JSON.stringify(data))
    this.router.navigate([`/new-invoice/1/${this.service_id}/Service`])
  }

  NavigateToEstimateWithProduct() {
    let data = {
      group: "Service",
      id: this.service_id,
    }
    localStorage.setItem('product', JSON.stringify(data))
    this.router.navigate([`/estimate-new/1/${this.service_id}/Service`])
  }

  NavigateToWorkOrderWithProduct() {
    let data = {
      group: "Service",
      id: this.service_id,
    }
    localStorage.setItem('product', JSON.stringify(data))
    this.router.navigate([`/work-order-new/1/${this.service_id}/Service`])
  }

  NavigateToAgreementWithProduct() {
    let data = {
      group: "Service",
      id: this.service_id,
    }
    localStorage.setItem('product', JSON.stringify(data))
    this.router.navigate([`/create-agreement/${this.service_id}/Service`])
  }

  showSuccess(msg: string) {
    this.toastr.success(msg);
  }

  showDanger(msg: string) {
    this.toastr.error(msg);
  }

}
