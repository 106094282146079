import { Component, OnInit, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SignatureComponent } from "src/app/layout";
import {
  AccountModel,
  CustomerModel,
  InvoiceModel,
  ItemModel,
  ServiceAgreementModel,
  Sidebar,
} from "src/app/shared";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { InvoiceService } from "src/app/shared/services/invoice/invoice.service";
import { ServiceAgreementService } from "src/app/shared/services/service-agreement/service-agreement.service";
import { SignatureService } from "src/app/shared/services/signature/signature.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-public-service-template-three",
  templateUrl: "./public-service-template-three.component.html",
  styleUrls: ["./public-service-template-three.component.scss"],
})
export class PublicServiceTemplateThreeComponent implements OnInit {
  public _customerData: CustomerModel = new CustomerModel();
  public ServiceAgreementModal: ServiceAgreementModel = new ServiceAgreementModel();
  public ItemData: ItemModel[] = [];

  public AccountData: AccountModel = new AccountModel();
  public date: Date = null;
  public modalRef: BsModalRef;
  public serviveProviderData: any = null;
  public section: string = null;
  public service_agreement_id: string = null;
  public service_agreement_data: any = null;
  public service_agreement_items: any = null;
  public organization_id: any = null;
  public user_name: any = null;
  public user_email: any = null;
  public user_full_address: any = null;
  public customer_name: any = null;
  public customer_email: any = null;
  public customer_full_address: any = null;
  public customer_address: any = null;
  public customer_locality: any = null;
  public customer_zip: any = null;
  public agreement_created_date: any = null;
  public agreement_start_date: any = null;
  public agreement_end_date: any = null;
  public template_name: any = null;
  public content: any = null;
  public env = environment;
  public env_url: any = null;
  public loading: boolean = true;
  constructor(
    public _modal: BsModalService,
    public auth: AuthService,
    public _sidebar: Sidebar,
    public activatedRoute: ActivatedRoute,
    public SignatureService: SignatureService,
    public _DomSanitizer: DomSanitizer,
    public AgreementService: ServiceAgreementService,
    public InvoiceService: InvoiceService,
    public serviceAgreementService: ServiceAgreementService
  ) {
    this.env_url = this._DomSanitizer.sanitize(SecurityContext.URL,this.env.url);

    this.section = this.activatedRoute.snapshot.paramMap.get("section");

    if (this.section == "service-agreement") {
      this.service_agreement_id = this.activatedRoute.snapshot.paramMap.get(
        "service_agreement_id"
      );
    }
    if (this.service_agreement_id != null && this.service_agreement_id != "") {
      this.getServiceAgreementDetails(this.service_agreement_id);
      this.SignatureService.setServiceAgreementData(this.service_agreement_id);
    }
    this.serviceProviderDetails();
  }

  ngOnInit() {}
  modalOpen(provider) {
    this.SignatureService.setServiceAgreementProvider(provider);
    this.modalRef = this._modal.show(SignatureComponent);
    this.modalRef.content.onClose.subscribe((result) => {
      if (result) {
        location.reload();
      }
    });
  }
  serviceProviderDetails() {
    if (this.auth.User()) {
      this.serviveProviderData = this.auth.User().user;
    } else {
      this.serviveProviderData = null;
    }
  }

  getUrl() {
    return this._DomSanitizer.bypassSecurityTrustResourceUrl(
      this.ServiceAgreementModal.signature
    );
  }
  getUrl2() {
    return this._DomSanitizer.bypassSecurityTrustResourceUrl(
      this.ServiceAgreementModal.receiver_signature
    );
  }

  getServiceAgreementDetails(service_agreement_id) {
    this.AgreementService.GetServiceAgreement(
      service_agreement_id
    ).subscribe((result) => {
      this.service_agreement_data = result["data"];
      this.getUserDetails(this.service_agreement_data.user_id);
      if (
        this.service_agreement_data["serviceagreement_customers"] != null &&
        this.service_agreement_data["serviceagreement_customers"].length > 0
      ) {
        this.customer_name = this.service_agreement_data["serviceagreement_customers"][0].name;
        this.customer_email = this.service_agreement_data["serviceagreement_customers"][0].email;
        this.customer_full_address = this.service_agreement_data['serviceagreement_customers'][0].address + ', ' + this.service_agreement_data['serviceagreement_customers'][0].locality + ', ' + this.service_agreement_data['serviceagreement_customers'][0].zip;
        if (this.service_agreement_data['serviceagreement_customers'][0].address){
          this.customer_address = this.service_agreement_data['serviceagreement_customers'][0].address;
        }else{
          this.customer_address = 'NA';
        }
        if (this.service_agreement_data['serviceagreement_customers'][0].locality){
          this.customer_locality = this.service_agreement_data['serviceagreement_customers'][0].locality;
        }else{
          this.customer_locality = 'NA';
        }
        if (this.service_agreement_data['serviceagreement_customers'][0].zip){
          this.customer_zip = this.service_agreement_data['serviceagreement_customers'][0].zip;
        }else{
          this.customer_zip = 'NA';
        }
      }

      if (
        this.service_agreement_data["serviceagreement_serviceagreementsitems" ] != null &&
        this.service_agreement_data["serviceagreement_serviceagreementsitems"].length > 0
      ) {
        this.service_agreement_items = this.service_agreement_data["serviceagreement_serviceagreementsitems"];
        
        let _data = [];
        for (let i of this.service_agreement_data["serviceagreement_serviceagreementsitems"]){
          let itemData = new ItemModel();
            itemData.rate = i.rate;
            itemData.quantity = i.quantity;

          if (i.serviceagreementitem_service){
            itemData.name = i.serviceagreementitem_service.name;
            if(i.serviceagreementitem_service.service_type){
              itemData.service_type = i.serviceagreementitem_service.service_type;
            } else {
              itemData.service_type = 'NA'
            }
            if (i.serviceagreementitem_service.model_no) {
              itemData.model_no = i.serviceagreementitem_service.model_no;
            } else {
              itemData.model_no = 'NA';
            }

            if (i.serviceagreementitem_service.serial_no) {
              itemData.serial_no = i.serviceagreementitem_service.serial_no;
            } else {
              itemData.serial_no = 'NA';
            }

            if (i.serviceagreementitem_service.part_no) {
              itemData.part_no = i.serviceagreementitem_service.part_no;
            } else {
              itemData.part_no = 'NA';
            }
            if (i.serviceagreementitem_service.part_no) {
              itemData.part_no = i.serviceagreementitem_service.part_no;
            } else {
              itemData.part_no = 'NA';
            }
            if (i.serviceagreementitem_service.tax_rate_data) {
              itemData.tax_rate = i.serviceagreementitem_service.tax_rate_data.rate;
              itemData.cost = (+i.quantity) * (+i.rate + (+i.rate * (i.serviceagreementitem_service.tax_rate_data.rate / 100)))
            } else {
              itemData.tax_rate = 'NA';
              itemData.cost = (+i.rate) * (+i.quantity)
            }
          }
          else if (i.serviceagreementitem_item) {
            itemData.name = i.serviceagreementitem_item.name;
            itemData.service_type ='NA';
            if (i.serviceagreementitem_item.model_no){
              itemData.model_no = i.serviceagreementitem_item.model_no;
            }else{
              itemData.model_no = 'NA';
            }

            if (i.serviceagreementitem_item.serial_no){
              itemData.serial_no = i.serviceagreementitem_item.serial_no;
            }else{
              itemData.serial_no = 'NA';
            }

            if (i.serviceagreementitem_item.part_no) {
              itemData.part_no = i.serviceagreementitem_item.part_no;
            } else {
              itemData.part_no = 'NA';
            }
            if (i.serviceagreementitem_item.part_no) {
              itemData.part_no = i.serviceagreementitem_item.part_no;
            } else {
              itemData.part_no = 'NA';
            }
            if (i.serviceagreementitem_item.tax_rate_data){
              itemData.tax_rate = i.serviceagreementitem_item.tax_rate_data.rate;
              itemData.cost = (+i.quantity) * (+i.rate + (+i.rate * (i.serviceagreementitem_item.tax_rate_data.rate / 100)))
            }else{
              itemData.tax_rate = 'NA';
              itemData.cost = (+i.rate) * (+i.quantity)
            }
          }
          _data.push(itemData)
        }
        this.ItemData = _data;
        
      }
      // this.ServiceAgreementModal.amount = Math.round(this.ServiceAgreementModal.amount );
      this.ServiceAgreementModal.service_agreement_number =
        result["data"].service_agreement_number;
      this.ServiceAgreementModal.customer_id = result["data"]["customer_id"];
      this.ServiceAgreementModal.created_at = result["data"]["created_at"];
      this.ServiceAgreementModal.updated_at = result["data"]["updated_at"];
      this.organization_id = result["data"]["organization_id"];
      this.ServiceAgreementModal.signature =
        result["data"]["service_agreement_signature"].signature;
      this.ServiceAgreementModal.is_signed =
        result["data"]["service_agreement_signature"].is_signed;
      this.ServiceAgreementModal.is_signed_receiver =
        result["data"]["service_agreement_signature"].is_signed_receiver;
      this.ServiceAgreementModal.receiver_signature =
        result["data"]["service_agreement_signature"].receiver_signature;
      this.agreement_created_date = this.service_agreement_data.updated_at;
      this.agreement_start_date = result['data']['start_date'];
      this.agreement_end_date = result['data']['end_date'];
      this.getOrganizationDetails(this.organization_id);
    });
  }

  getOrganizationDetails(organization_id) {
    this.InvoiceService.GetOrganizationDetails(organization_id).subscribe(
      (result) => {
        if (result["success"]) {
          this.AccountData.organization_id = result["data"]["account_id"];
          this.AccountData.business_name = result["data"]["business_name"];
          this.AccountData.email = result["data"]["email"];
          if(result['data']['address_line_1']){
            this.AccountData.address_line_1 = result['data']['address_line_1'];
          }else{
            this.AccountData.address_line_1 = 'NA';
          }
          if(result['data']['address_line_2']){
            this.AccountData.address_line_2 = result['data']['address_line_2'];
          }else{
            this.AccountData.address_line_2 = 'NA';
          }
          this.user_full_address = this.AccountData.address_line_1+', '+this.AccountData.address_line_2
          this.AccountData.logo = result["data"]["logo"];
          this.template_name = result['data'].service_agreement_template;
          this.getTemplateView();
        }
      }
    );
  }

  getUserDetails(user_id) {
    this.AgreementService.getUser(user_id).subscribe((result) => {
      this.user_name = result["data"].name;
      this.user_email = result["data"].email;

    });
  }

  getTemplateView(){
    let data={
      template_name: this.template_name,
      user_id: this.service_agreement_data.user_id,
      organization_id: this.organization_id,
    }
    this.serviceAgreementService.viewTemplate(data).subscribe(
      result=>{
        this.loading = false;
        this.content = result['data'].content;
      }
    )
  }
}
