import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { InvoiceService } from "src/app/shared/services/invoice/invoice.service";
import { Validators, FormBuilder } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { InvoiceAllList } from "src/app/shared";
import { SettingService } from "src/app/shared/services/setting/setting.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-invoice-pay",
  templateUrl: "./invoice-pay.component.html",
  styleUrls: ["./invoice-pay.component.scss"],
})
export class InvoicePayComponent implements OnInit {
  public step: number = 1;
  public invoice_id: string = null;
  public _invoiceData: InvoiceAllList = new InvoiceAllList();
  public invoice_number: any = null;
  public payment_type: string = null;
  public loading: boolean = false;
  public saveBtn: boolean = false;

  public submitted: boolean = false;
  public Invoice = this.fb.group({
    payment_type: ["", Validators.required],
    status: ["", Validators.required],
  });

  constructor(
    public _router: Router,
    public fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public _invoiceService: InvoiceService,
    public toastr: ToastrService,
    public SettingService: SettingService
  ) {
    this.invoice_id = this.activatedRoute.snapshot.paramMap.get("invoice_id");
    this.invoice_number =
      this.activatedRoute.snapshot.paramMap.get("invoice_index");
    this.invoiceData(this.invoice_id);
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = "00000";
    let transformedData =
      transformFormat.substring(0, transformFormat.length - starting.length) +
      starting;

    return transformedData;
  }

  get f() {
    return this.Invoice.controls;
  }

  invoiceData(invoiceID: string) {
    this.loading = true;

    this._invoiceService.InvoiceView(invoiceID).subscribe(
      (result) => {
        this.loading = false;

        this._invoiceData.payment_type = result["data"].payment_type;
        this._invoiceData.status = result["data"].status;
        this._invoiceData.due_date = result["data"].due_date;
        this._invoiceData.amount = result["data"].amount;
        this._invoiceData.total = result["data"].total;
        this._invoiceData.sales_tax = result["data"].sales_tax;
        this._invoiceData.created_at = result["data"].created_at;
        this._invoiceData.updated_at = result["data"].updated_at;
        this._invoiceData.note = result["data"].note;
        this._invoiceData.customer_id =
          result["data"].invoice_customers[0].customer_id;
        if (
          result["data"]["invoice_invoiceitems"] != null ||
          result["data"]["invoice_invoiceitems"].length > 0
        ) {
          this._invoiceData.line_item =
            result["data"]["invoice_invoiceitems"][0].item_id;
          for (let index of result["data"]["invoice_invoiceitems"]) {
            this._invoiceData.items.push({
              item_id: index.item_id,
              qty: index.quantity,
              group: index.group,
              unit_price: index.rate,
            });
          }
        }
        this._invoiceData.discount = result["data"].discount;
        this._invoiceData.discount_type = result["data"].discount_type;
        this._invoiceData.advance = result["data"].advance;
        this._invoiceData.advance_type = result["data"].advance_type;
        this._invoiceData.invoice_number = result["data"].invoice_number;
        this.payment_type = result["data"].payment_type;
        this._invoiceData.select_interval = result["data"].select_interval;
        this._invoiceData.invoice_recurring_details_id =
          result["data"].invoice_agreement_recurring_details_id || null;
        if (
          result["data"]["invoice_recurrings"] != null &&
          result["data"]["invoice_recurrings"] != ""
        ) {
          this._invoiceData.day = result["data"]["invoice_recurrings"].day;
          this._invoiceData.month = result["data"]["invoice_recurrings"].month;
          this._invoiceData.quarter =
            result["data"]["invoice_recurrings"].quarter;

          this._invoiceData.recurring_type =
            result["data"]["invoice_recurrings"]["recurring_type"];
        }
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    );
  }

  createInvoice() {
    this.submitted = true;
    if (
      this.Invoice.controls.payment_type.invalid ||
      this.Invoice.controls.status.invalid
    ) {
      return;
    }
    this.saveBtn = true;
    this._invoiceData.payment_type = this.Invoice.value.payment_type;
    this._invoiceData.status = this.Invoice.value.status;
    this._invoiceData.is_new_invoice = this._invoiceService.getInvoiceType();
    this._invoiceService.Edit(this._invoiceData, this.invoice_id).subscribe(
      (result) => {
        if (result["success"]) {
          if (this.payment_type == null) {
            this.showCreateSuccess();
          } else {
            this.showUpdateSuccess();
          }
          this._router.navigate([
            `/invoice-self/${this.invoice_id}/${this.invoice_number}`,
          ]);
        } else {
          if (this.payment_type == null) {
            this.showCreateDanger();
          } else {
            this.showUpdateDanger();
          }
        }
        this.saveBtn = false;
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
          this.saveBtn = false;
        }
      }
    );
  }

  showCreateSuccess() {
    this.toastr.success("Invoice created successfully");
  }
  showUpdateSuccess() {
    this.toastr.success("Invoice updated successfully");
  }

  showCreateDanger() {
    this.toastr.error("Invoice could not be created successfully");
  }

  showUpdateDanger() {
    this.toastr.error("Invoice could not be updated successfully");
  }
}
