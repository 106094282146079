import { Component, OnInit } from '@angular/core';
import { Sidebar, EstimateModel } from 'src/app/shared';
import { EstimateService } from 'src/app/shared/services/estimate/estimate.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { WorkOrderService } from 'src/app/shared/services/work-order/work-order.service';
import { WorkOrderModel } from 'src/app/shared/models/work-order/work-order.model';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-work-order',
  templateUrl: './work-order.component.html',
  styleUrls: ['./work-order.component.scss']
})
export class WorkOrderComponent implements OnInit {

  public loading: boolean = false;
  public customer_id: string = null;
  public work_order: any[] = [];
  public WorkOrderModel: WorkOrderModel[] = [];
  public amount: number = 0;
  public Search = this.fb.group({
    work_order_number: ['',Validators.required],
  })

  constructor(
    public _sidebar: Sidebar,
    public EstimateService: EstimateService,
    public SettingService:SettingService,
    public WorkOrderService:WorkOrderService,
    public fb: FormBuilder,
    public activatedRoute:ActivatedRoute,
    public toastr: ToastrService,
  ) {
    this.getWorkOrderList();
    this.customer_id = this.activatedRoute.snapshot.paramMap.get('customer_id');
    if (this.customer_id != null) {
      this.getWorkOrderListByCustomer(this.customer_id);
    }
    else {
      this.getWorkOrderList();
    }
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    let transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;

    return transformedData;
  }

  getWorkOrderList() {
    this.loading = true;

    this.WorkOrderService.GetWorkOrderList().subscribe(
      result => {
        if (result['success']) {
          this.loading = false;

          this.work_order = result['data'];          
          let data = [];
          for (let index of this.work_order) {
            let WorkOrderData = new WorkOrderModel()
            if (index.workorder_customers == null || index.workorder_customers == '') {
              WorkOrderData.name = 'NA';
            } 
            else {
              WorkOrderData.name = index.workorder_customers[0].name;
            }
            if (index.workorder_workorderitems == null || index.workorder_workorderitems == '') {
              WorkOrderData.rate = 'NA';
            } 
            else {
              if(index.workorder_workorderitems!=null && index.workorder_workorderitems!=''){
                for (let i of index.workorder_workorderitems) {
                  this.amount = this.amount + (+i.rate);
                  WorkOrderData.rate = index.workorder_workorderitems[0].rate;
                }
                WorkOrderData.rate = this.amount;
                this.amount = 0; 
              }
              
              else if(index.workorder_workorderitems!=null && index.workorder_workorderitems!=''){
                for (let i of index.workorder_workorderitems) {
                  this.amount = this.amount + (+i.rate);
                  WorkOrderData.rate = index.workorder_workorderitems[0].rate;
                }
                WorkOrderData.rate = this.amount;
                this.amount = 0;
              }
            }
            WorkOrderData.work_order_id = index.work_order_id;
            WorkOrderData.due_date = index.due_date;
            WorkOrderData.total = index.total;
            WorkOrderData.work_order_number = index.work_order_number;
            WorkOrderData.created_at = index.created_at;
            data.push(WorkOrderData);
          }
          this.WorkOrderModel = data;
        }
        else {
          this.WorkOrderModel = null;
          this.loading = false;
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  Clear(){
    this.Search.get('work_order_number').setValue('');
    this.Search.updateValueAndValidity();
    this.getWorkOrderList();
      
  }
  ClearInput(){
    if(this.Search.value.work_order_number == '' || this.Search.value.work_order_number == null){
      this.getWorkOrderList();
    }else{
      this.SearchWrokorder();
    }
   
  }

  SearchWrokorder(){
    if(this.Search.invalid){
      this.toastr.error('Provide work order number');
      return;
    }
    this.WorkOrderService.WorkOrderSearch(this.Search.value).subscribe(
      result=>{
        if (result['success']) {
          this.loading = false;

          this.work_order = result['data'];
          let data = [];
          for (let index of this.work_order) {
            let WorkOrderData = new WorkOrderModel()
            if (index.workorder_customers == null || index.workorder_customers == '') {
              WorkOrderData.name = 'NA';
            } 
            else {
              WorkOrderData.name = index.workorder_customers[0].name;
            }
            if (index.workorder_workorderitems == null || index.workorder_workorderitems == '') {
              WorkOrderData.rate = 'NA';
            } 
            else {
              if(index.workorder_workorderitems!=null && index.workorder_workorderitems!=''){
                for (let i of index.workorder_workorderitems) {
                  this.amount = this.amount + (+i.rate);
                  WorkOrderData.rate = index.workorder_workorderitems[0].rate;
                }
                WorkOrderData.rate = this.amount;
                this.amount = 0; 
              }
              
              else if(index.workorder_workorderitems!=null && index.workorder_workorderitems!=''){
                for (let i of index.workorder_workorderitems) {
                  this.amount = this.amount + (+i.rate);
                  WorkOrderData.rate = index.workorder_workorderitems[0].rate;
                }
                WorkOrderData.rate = this.amount;
                this.amount = 0;
              }
            }
            WorkOrderData.work_order_id = index.work_order_id;
            WorkOrderData.due_date = index.due_date;
            WorkOrderData.total = index.total;
            WorkOrderData.work_order_number = index.work_order_number;
            data.push(WorkOrderData);
          }
          this.WorkOrderModel = data;
        }else{
          // this.toastr.warning(result['msg']+' Provide valid work order number');
          this.loading = false;

          this.WorkOrderModel = null;
        }              
      }
    )
  }

  getWorkOrderListByCustomer(customer_id){
    this.loading = true;

    this.WorkOrderService.GetWorkOrderListByCustomer(customer_id).subscribe(
      result => {
        if (result['success']) {
          this.loading = false;

          this.work_order = result['data'];
          let data = [];
          for (let index of this.work_order) {
            let WorkOrderData = new WorkOrderModel()
            if (index.workorder_customers == null || index.workorder_customers == '') {
              WorkOrderData.name = 'NA';
            } 
            else {
              WorkOrderData.name = index.workorder_customers[0].name;
            }
            if (index.workorder_workorderitems == null || index.workorder_workorderitems == '') {
              WorkOrderData.rate = 'NA';
            } 
            else {
              if(index.workorder_workorderitems!=null && index.workorder_workorderitems!=''){
                for (let i of index.workorder_workorderitems) {
                  this.amount = this.amount + (+i.rate);
                  WorkOrderData.rate = index.workorder_workorderitems[0].rate;
                }
                WorkOrderData.rate = this.amount;
                this.amount = 0; 
              }
              
              else if(index.workorder_workorderitems!=null && index.workorder_workorderitems!=''){
                for (let i of index.workorder_workorderitems) {
                  this.amount = this.amount + (+i.rate);
                  WorkOrderData.rate = index.workorder_workorderitems[0].rate;
                }
                WorkOrderData.rate = this.amount;
                this.amount = 0;
              }
            }
            WorkOrderData.work_order_id = index.work_order_id;
            WorkOrderData.due_date = index.due_date;
            WorkOrderData.total = index.total;
            WorkOrderData.work_order_number = index.work_order_number;
            data.push(WorkOrderData);
          }
          this.WorkOrderModel = data;
        }
        else {
          this.loading = false;
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }



}
