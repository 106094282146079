import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UserModel {
  public user_id: string = null;
  public name: string = null;
  public email: string = null;
  public password: any[] = [];
  public confirm_password: string = null;
  public status: number = null;
}

