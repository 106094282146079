import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ExpenseService } from "src/app/shared/services/expense/expense.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ExpenseModel } from "src/app/shared";
import { ToastrService } from "ngx-toastr";
import { SettingService } from "src/app/shared/services/setting/setting.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-expense-new",
  templateUrl: "./expense-new.component.html",
  styleUrls: ["./expense-new.component.scss"],
})
export class ExpenseNewComponent implements OnInit {
  public submitted: boolean = null;
  public addBtn: boolean = null;
  public transformedData: any = null;
  public length: any = null;

  public expense_id: string = null;
  public expense_type: any[] = [];
  public minDate: Date;

  public NewExpense = this.fb.group({
    expenses_id: [""],
    expense_number: [""],
    date: ["", Validators.required],
    type: ["", Validators.required],
    merchant: ["", Validators.required],
    note: ["", Validators.required],
    tip: ["", Validators.pattern("^[0-9._%+-]+\\%$")],
    total: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
  });

  constructor(
    public fb: FormBuilder,
    public activateRoute: ActivatedRoute,
    public ExpenseService: ExpenseService,
    public router: Router,
    public toastr: ToastrService,
    public SettingService: SettingService
  ) {
    this.getExpenseList();
    this.getExpenseTypeList();
    this.expense_id = this.activateRoute.snapshot.paramMap.get("expense_id");
    if (this.expense_id != null) {
      this.getExpenseDetails(this.expense_id);
    }
    this.minDate = new Date();
  }

  public Expensemodel: ExpenseModel = new ExpenseModel();

  ngOnInit() {
    window.scroll(0, 0);
  }

  get f() {
    return this.NewExpense.controls;
  }

  getExpenseList() {
    this.ExpenseService.GetExpenseList().subscribe(
      (result) => {
        if (result["success"]) {
          this.length = result["data"].length;
          this.digitFormatter(this.length + 1);
        } else {
          this.length = 0;
          this.digitFormatter(this.length + 1);
        }
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    );
  }

  getExpenseTypeList() {
    this.ExpenseService.GetExpenseType().subscribe(
      (result) => {
        for (let i in result["data"]) {
          this.expense_type.push(result["data"][i]);
        }
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    );
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = "00000";
    this.transformedData =
      transformFormat.substring(0, transformFormat.length - starting.length) +
      starting;
    return this.transformedData;
  }

  getExpenseDetails(expense_id) {
    this.ExpenseService.GetExpenseById(expense_id).subscribe(
      (result) => {
        if (result["success"]) {
          this.Expensemodel.date = result["data"]["date"];
          // this.Expensemodel.date = result['data']['ist_date'];

          this.Expensemodel.type = result["data"]["type"];
          this.Expensemodel.merchant = result["data"]["merchant"];
          this.Expensemodel.note = result["data"]["note"];
          this.Expensemodel.type = result["data"]["type"];
          this.Expensemodel.tip = result["data"]["tip"];
          this.Expensemodel.total = result["data"]["total"];
          this.Expensemodel.expenses_id = result["data"]["expenses_id"];
          this.Expensemodel.expense_number = result["data"]["expense_number"];
        }
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    );
  }

  CreateExpense() {
    this.submitted = true;
    if (this.NewExpense.invalid) {
      return;
    }
    this.addBtn = true;
    if (this.expense_id == null) {
      this.NewExpense.get("expense_number").setValue(this.transformedData);
      this.NewExpense.updateValueAndValidity();
      this.ExpenseService.AddExpense(this.NewExpense.value).subscribe(
        (result) => {
          if (result["success"]) {
            this.showSuccess(result["msg"]);
            this.router.navigate(["/expenses"]);
          } else {
            this.showDanger(result["msg"]);
          }
          this.addBtn = false;
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
            this.addBtn = false;
          }
        }
      );
    } else {
      if (this.expense_id != null) {
        this.NewExpense.get("expenses_id").setValue(
          this.Expensemodel.expenses_id
        );
        this.NewExpense.updateValueAndValidity();
        this.NewExpense.value.date = new Date(this.NewExpense.value.date);

        this.NewExpense.get("expense_number").setValue(
          this.Expensemodel.expense_number
        );
        this.NewExpense.updateValueAndValidity();
      }

      this.ExpenseService.UpdateExpense(this.NewExpense.value).subscribe(
        (result) => {
          if (result["success"]) {
            this.showSuccess(result["msg"]);
            this.router.navigate(["/expenses"]);
          } else {
            this.showDanger(result["msg"]);
          }
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
          }
        }
      );
    }
  }

  showSuccess(msg: string) {
    this.toastr.success(msg);
  }

  showDanger(msg: string) {
    this.toastr.error(msg);
  }
}
