import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ServiceAgreementModel, Sidebar } from 'src/app/shared';
import { ServiceAgreementService } from 'src/app/shared/services/service-agreement/service-agreement.service';

@Component({
  selector: 'app-list-agreement',
  templateUrl: './list-agreement.component.html',
  styleUrls: ['./list-agreement.component.scss']
})
export class ListAgreementComponent implements OnInit {
  public AgreementList: any[] = null;
  public loading: boolean =true;
  
  public customerDetails: any[] = [
    {name:'Line Rolland', serviceId: 12255, serviceName: 'AC', date: '12-01-2021' },
    {name:'Dianna Smiley', serviceId: 85420, serviceName: 'Laptop', date: '16-01-2021' },
    {name:'Rachel Leonard', serviceId: 12255, serviceName: 'TV', date: '18-01-2021' },
    {name:'Anne Mcfadden', serviceId: 12255, serviceName: 'Bike', date: '16-02-2021' },
    {name:'Aaron Nunez', serviceId: 12255, serviceName: 'Printer', date: '15-02-2021' },
  ];
  public Search = this.fb.group({
    service_agreement_number: ['',Validators.required],
  })
  constructor(
    public _sidebar: Sidebar,
    public fb: FormBuilder,
    public AgreementService: ServiceAgreementService,
    public ServiceAgreementModel: ServiceAgreementModel,
    public toastr: ToastrService
  ) { }

  ngOnInit() {
    this.getAgreementList();
  }

  getAgreementList(){
    this.AgreementService.GetServiceAgreementList().subscribe(
      result=>{
        if(result['data']){
          this.loading = false;
          this.AgreementList = result['data'];
        }else{
          this.AgreementList = null;

        }
      }
    )
  }

  Clear(){
    this.Search.get('service_agreement_number').setValue('');
    this.Search.updateValueAndValidity();
    this.getAgreementList();
      
  }
  ClearInput(){
    if(this.Search.value.service_agreement_number == '' || this.Search.value.service_agreement_number == null){
      this.getAgreementList();
    }else{
      this.SearchAgreement();
    }
   
  }

  SearchAgreement(){
    
    if(this.Search.invalid){
      this.toastr.error('Please provide service agreement number');
      return;
    }
    this.AgreementService.ServiceAgreementSearch(this.Search.value).subscribe(
      result=>{
        
        if(result['success']){
          this.AgreementList = result['data'];                  

        }else{
          // this.toastr.warning(result['msg']+' Provide valid Service Agreement Number')
          this.loading = false;

          this.AgreementList = null;
        }
      }
    )
  }
}
