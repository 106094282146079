import { Component, OnInit } from '@angular/core';
import { Sidebar, ReportModel } from 'src/app/shared';
import { timer } from 'rxjs';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

declare var google: any;

@Component({
  selector: 'app-invoice-report',
  templateUrl: './invoice-report.component.html',
  styleUrls: ['./invoice-report.component.scss']
})
export class InvoiceReportComponent implements OnInit {
  public loading: boolean = false;
  public ReportsModel: ReportModel = new ReportModel();
  public amount: any[] = [];
  public max_amount: number = null;
  public min_amount: number = null;

  constructor(
    public _sidebar: Sidebar,
    public InvoiceService: InvoiceService,
    public SettingService: SettingService,
    public toastr: ToastrService) {
  }

  ngOnInit() {
    // this.Loading();
    this.getInvoiceReport();
    window.scroll(0, 0);
  }

  getInvoiceReport() {
    this.loading = true;
    this.InvoiceService.Report()
      .subscribe((result) => {
        this.loading = false;

        if (result['data'].first_week_count == 0 && result['data'].second_week_count == 0 && result['data'].third_week_count == 0 && result['data'].fourth_week_count == 0 && result['data'].fifth_week_count == 0) {
          this.toastr.warning('No records found!');
          this.max_amount = 1000;
          this.min_amount = 0;

          this.ReportsModel.first_week = 0;
          this.ReportsModel.second_week = 0;
          this.ReportsModel.third_week = 0;
          this.ReportsModel.fourth_week = 0;
          this.ReportsModel.fifth_week = 0;
          this.ReportsModel.first_amount = 0;
          this.ReportsModel.second_amount = 0;
          this.ReportsModel.third_amount = 0;
          this.ReportsModel.fourth_amount = 0;
          this.ReportsModel.first_amount = 0;
        }else{

          this.ReportsModel.first_week = result['data'].first_week.length;
          this.ReportsModel.second_week = result['data'].second_week.length;
          this.ReportsModel.third_week = result['data'].third_week.length;
          this.ReportsModel.fourth_week = result['data'].fourth_week.length;
          this.ReportsModel.fifth_week = result['data'].fifth_week.length;
          this.ReportsModel.first_amount = result['data'].first_week_amount;
          this.ReportsModel.second_amount = result['data'].second_week_amount;
          this.ReportsModel.third_amount = result['data'].third_week_amount;
          this.ReportsModel.fourth_amount = result['data'].fourth_week_amount;
          this.ReportsModel.first_amount = result['data'].fifth_week_amount;

          this.amount = [
            this.ReportsModel.first_amount,
            this.ReportsModel.second_amount,
            this.ReportsModel.third_amount,
            this.ReportsModel.fourth_amount,
            this.ReportsModel.fifth_amount
          ];

          this.max_amount = this.amount.reduce((acc, curr) => { return acc > curr ? acc : curr })
          this.min_amount = this.amount.reduce((acc, curr) => { return acc < curr ? acc : curr })
        }

        google.charts.load('current', { 'packages': ['corechart'] });
        google.charts.setOnLoadCallback(() => this.drawStuff());
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      }
      )
  }


  drawStuff() {
    var data = new google.visualization.arrayToDataTable([
      ['Last month', 'No. Of Invoice', 'Amount ($)'],
      ["1st Week", this.ReportsModel.first_week, this.ReportsModel.first_amount],
      ["2nd Week", this.ReportsModel.second_week, this.ReportsModel.second_amount],
      ["3rd Week", this.ReportsModel.third_week, this.ReportsModel.third_amount],
      ["4th Week", this.ReportsModel.fourth_week, this.ReportsModel.fourth_amount,],
      ['5th Week', this.ReportsModel.first_week, this.ReportsModel.fifth_amount,]
    ]);

    var options = {
      height: 500,
      legend: { position: 'none' },
      title: 'Invoice generated - Amount per week (Last Month)',
      vAxis: {
        viewWindowMode: 'explicit',
        viewWindow: {
          // max: this.max_amount,
          min: this.min_amount,
        },
        gridlines: {
          count: 20
        }
      },
      bar: { groupWidth: "90%" }
    };

    this.loading = false;
    var chart = new google.visualization.ColumnChart(document.getElementById('chart'));
    // Convert the Classic options to Material options.
    chart.draw(data, options);
  };

  // Loading() {
  //   let _timer = timer(2000);

  //   this.loading = true;
  //   _timer.subscribe(() => {
  //     this.loading = false;
  //     google.charts.load('current', { 'packages': ['bar'] });
  //     google.charts.setOnLoadCallback(() => this.drawStuff());
  //   })
  // }

}
