import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Sidebar } from 'src/app/shared';
import { ServiceModuleService } from 'src/app/shared/services/service/service-module.service';
import { ItemService } from 'src/app/shared/services/item/item.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { ItemModel } from "src/app/shared/models/item/item.modal";
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  public zip_url: string = null;
  public loading: boolean = false;
  public services: any[] = [
    // {name:'Service 1', id: 12255, amount: 145, type: 'monthly' },
    // {name:'Service 2', id: 12587, amount: 15, type: 'weekly' },
    // {name:'Service 3', id: 9887, amount: 95, type: 'monthly' },
  ]
public Search = this.fb.group({
    service_number: ['',Validators.required],
  })

  public ItemModal: ItemModel = new ItemModel();

  public show_in_widget = this.fb.group({
    show_in_widget: [false]
  }) 
  public submitted: boolean = false;
  modelRef: BsModalRef;
  constructor(
    public _sidebar: Sidebar,
    public serviceService: ServiceModuleService,
    public fb: FormBuilder,
    public toastr: ToastrService,
    public ItemService: ItemService,
    public SettingService:SettingService,
    public modelService: BsModalService,
  ) { 
    this.getServiceList();
  }

  ngOnInit() {
  }
  getServiceList(){
   
    this.serviceService.GetServiceList().subscribe(
      (result)=>{
        if(result['success']){
          this.services = result['data'];      
          for(let [index,services] of Object.entries(this.services)) {
            this.ItemModal.show_in_widget = result["data"][index].show_in_widget;
            this.ItemModal.service_id = result["data"][index].service_id;
          }
              
        }else{
          this.services  =null;
        }
      }
    )
  }
  Clear(){
    this.Search.get('service_number').setValue('');
    this.Search.updateValueAndValidity();
    this.getServiceList();
      
  }
  ClearInput(){
    if(this.Search.value.service_number == '' || this.Search.value.service_number == null){
      this.getServiceList();
    }else{
      this.SearchService();
    }
   
  }

  SearchService(){
    
    if(this.Search.invalid){
      this.toastr.error('Please provide service number');
      return;
    }
    this.serviceService.ServiceSearch(this.Search.value).subscribe(
      result=>{
        
        if(result['success']){
          this.services = result['data'];                  

        }else{
          // this.toastr.warning(result['msg']+' Provide valid Service Number')
          this.services = null;
        }
      }
    )
  }

  public downloadCSVform = this.fb.group({
    start_date: ["", Validators.required],
    end_date: [""]
  });

  get csvForm() {
    return this.downloadCSVform.controls;
  }

  exportCSV(template: TemplateRef<any>) {
    this.submitted = false;
    this.modelRef = this.modelService.show(template);
    this.downloadCSVform.get('start_date').setValue('');
  }

  exportServiceCSV() {
    this.submitted  = true;
    if(this.downloadCSVform.invalid){
      return;
    }
    let data = {
      end_date: this.downloadCSVform.get('start_date').value[1],
      start_date: this.downloadCSVform.get('start_date').value[0]
    }
    // this.loading = true;
    this.serviceService.exportCSV(data)
      .subscribe((result) => {
        if (result['success']) {
          this.zip_url = "http://128.199.25.224/invoice-app-server/public/service/service.zip";
          const link = document.createElement('a');
          link.href =  this.zip_url ;
          link.download = this.zip_url ;
          link.click();
          this.modelRef.hide();
        }
      }
      , (err:HttpErrorResponse) => {
        this.loading = false;
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  showServiceInWidget(evt,service) {
    let data = {
      service_id: service.service_id,
      show_in_widget: evt.target.checked
    }
    
    this.serviceService.showServiceInWidget(data).subscribe((result) => {
      if (result['success']){
        this.showSuccess(result["msg"]);
      } else {
        this.showDanger(result["msg"]);
      }
    }),
    (err: HttpErrorResponse) => {
      if (
        err.error.message == "Unauthenticated." ||
        err.status == 401 ||
        err.statusText == "Unauthorized"
      ) {
        this.SettingService.LogOut();
      }
    }
  }

  showSuccess(msg: string) {
    this.toastr.success(msg);
  }

  showDanger(msg: string) {
    this.toastr.error(msg);
  }
}
