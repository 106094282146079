import { Component, OnInit } from '@angular/core';
import { Sidebar } from 'src/app/shared';
import { ExpenseService } from 'src/app/shared/services/expense/expense.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent implements OnInit {
  public loading: boolean = false;
  public expenses: any[] = null;
  public Search = this.fb.group({
    expense_number: ['',Validators.required,],
  })
  constructor(
    public _sidebar: Sidebar,
    public ExpenseServive: ExpenseService,
    public SettingService:SettingService,
    public fb: FormBuilder,
    public toastr: ToastrService
  ) {
    this.getExpenseList();
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  getExpenseList() {
    this.loading = true;
    this.ExpenseServive.GetExpenseList()
      .subscribe((result) => {
        if (result['success']) {
          this.loading = false;
          this.expenses = result['data'];
        }
        else {
          this.expenses = null;
          this.loading = false;
        }
      }, (err:HttpErrorResponse) => {
        this.loading = false;
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  Clear(){
    this.Search.get('expense_number').setValue('');
    this.Search.updateValueAndValidity();
    this.getExpenseList();
      
  }
  ClearInput(){
    if(this.Search.value.expense_number == '' || this.Search.value.expense_number == null){
      this.getExpenseList();
    }else{
      this.SearchExpense();
    }
   
  }

  SearchExpense(){
    
    if(this.Search.invalid){
      this.toastr.error('Please provide service number');
      return;
    }
    this.ExpenseServive.ExpenseSearch(this.Search.value).subscribe(
      result=>{
        if(result['success']){
          this.expenses = result['data'];                  
        }else{
          // this.toastr.warning(result['msg']+' Provide valid Service Number')
          this.loading = false;
          this.expenses = null;
        }
      }
    )
  }
}
