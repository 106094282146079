import { Component, OnInit, TemplateRef } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Sidebar } from "src/app/shared";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { identifierModuleUrl } from "@angular/compiler";
import { AddOnService } from "src/app/shared/services/add-on/add-on.service";

@Component({
  selector: "app-price-book",
  templateUrl: "./price-book.component.html",
  styleUrls: ["./price-book.component.scss"],
})
export class PriceBookComponent implements OnInit {
  priceBookType = null;
  public step: any = 1;
  public modalRef: BsModalRef;
  public env = environment;
  // upload file
  public file: File = null;
  public _formdata = new FormData();
  private _step: any = null;
  private number_of_pricebook_data: any = null;
  public serviceList: any[] = [];
  public itemList: any[] = [];
  public lines: any[] = [];
  public uploadConfirmBtn = false;
  public addService = this.fb.group({
    service_type: ["", Validators.required],
  });

  constructor(
    public fb: FormBuilder,
    public _sidebar: Sidebar,
    public _activatedRoute: ActivatedRoute,
    public _router: Router,
    public _modalService: BsModalService,
    public toastr: ToastrService,
    public _addOnService: AddOnService
  ) {
    this.priceBookType = localStorage.getItem("pricebook");

    this.step = this._activatedRoute.snapshot.paramMap.get("step");
    if (this.step == "2") {
      this.step = +this.step;
      this.Step(this.step);
    }
  }

  ngOnInit() {
    this._step = this._activatedRoute.snapshot.paramMap.get("step");
    this.Step(+this._step);
  }
  openModal(template: TemplateRef<any>) {
    if (this.number_of_pricebook_data > 1000) {
      if (this.priceBookType == 1) {
        this.toastr.warning("You can Upload upto 1000 Services ");
      } else {
        this.toastr.warning("You can Upload upto 1000 Products");
      }
      return;
    }
    this.modalRef = this._modalService.show(template);
  }

  FilesSelected(event) {
    if (event.rejectedFiles.length > 0) {
      this.toastr.error("The file must be CSV type");
      return;
    }
    this.file = event.addedFiles[0];
    let reader: FileReader = new FileReader();
    reader.readAsText(this.file);

    reader.onload = (e) => {
      let csv: any = reader.result;
      let allTextLines = csv.split(/\r?\n/);
      let headers = allTextLines[0].split(",");
      if (headers[3] == "service_type") {
        this.priceBookType = 1;
      } else if (headers[3] == "quantity") {
        this.priceBookType = 2;
      }
    };
    this._addOnService.ValidateCSV(this.file).subscribe((result) => {
      this.lines = result["msg"];
    });
  }
  onRemove() {
    this.file = null;
  }

  onTypeSelect(event) {
    this.priceBookType = event.target.value;
    // localStorage.setItem("pricebook", this.priceBookType);
  }
  Step(step: number) {}
  Route() {
    if (this.step === 2) {
      this.step = 1;
      this._router.navigate([`/add-service/${this.step}`]);
    } else {
      this._router.navigate(["/settings"]);
    }
  }

  UploadData(priceBookType, step) {
    if (this.file == null) {
      if (priceBookType == 1) {
        this.toastr.error("Please select a valid Service CSV file");
      } else if (priceBookType == 2) {
        this.toastr.error("Please select a valid Product CSV file");
      }
      return;
    }

    if (priceBookType == 1) {
      let reader: FileReader = new FileReader();
      reader.readAsText(this.file);

      reader.onload = (e) => {
        let csv: any = reader.result;
        let allTextLines = csv.split(/\r?\n/);

        let headers = allTextLines[0].split(",");

        if (headers[3] == "quantity" && this.priceBookType == 1) {
          this.toastr.warning(
            "Pricebook Data and Price Book Type Mismatch! Please select Products option from select Type"
          );
          return;
        }
        if (headers.length > 8) {
          this.toastr.error(
            "Invalid Format. Please download the service sample format for overview."
          );
          return;
        }

        if (headers[0] != "name") {
          this.toastr.error(
            "Invalid Format. The first column of first row should contain name text."
          );
          return;
        } else if (headers[1] != ["description"]) {
          this.toastr.error(
            "Invalid Format. The second column of first row should contain description text."
          );
          return;
        } else if (headers[2] != "rate") {
          this.toastr.error(
            "Invalid Format. The third column of first row should contain rate text."
          );
          return;
        } else if (headers[3] != "service_type") {
          this.toastr.error(
            "Invalid Format. The fourth column of first row should contain service_type text."
          );
          return;
        } else if (headers[4] != "sku") {
          this.toastr.error(
            "Invalid Format. The fifth column of first row should contain sku text."
          );
          return;
        } else if (headers[5] != "cost") {
          this.toastr.error(
            "Invalid Format. The sixth column of first row should contain cost text."
          );
          return;
        } else if (headers[6] != "serial_no") {
          this.toastr.error(
            "Invalid Format. The seventh column of first row should contain serial_no text."
          );
          return;
        } else if (headers[7] != "video_url") {
          this.toastr.error(
            "Invalid Format. The eighth column of first row should contain video_url text."
          );
          return;
        } else {
          let lines = [];
          for (let i = 1; i < allTextLines.length; i++) {
            // split content based on comma
            let data = allTextLines[i].split(",");
            if (data.length === headers.length) {
              let tarr = [];
              for (let j = 0; j < headers.length; j++) {
                tarr.push(data[j]);
              }

              // log each row to see output
              lines.push(tarr);              
            }
          }

          let data_list = [];
          for (let i = 1; i < this.lines.length; i++) {
            data_list.push({
              name: this.lines[i][0],
              description: this.lines[i][1],
              rate: this.lines[i][2],
              service_type: this.lines[i][3],
              sku: this.lines[i][4],
              cost: this.lines[i][5],
              serial_no: this.lines[i][6],
              video_url: this.lines[i][7],
              row: +i + 1,
            });
          }
          for (let index in data_list) {
            if (isNaN(Number(data_list[index].rate))) {
              this.toastr.error(
                "Row Number " +
                  data_list[index].row +
                  " contains string value in rate colunm! It must be number."
              );
              return;
            }
            if (
              data_list[index].service_type != "Monthly" &&
              data_list[index].service_type != "Quaterly" &&
              data_list[index].service_type != "Yearly"
            ) {
              this.toastr.error(
                "Row Number " +
                  data_list[index].row +
                  " contains wrong value in service_type colunm! It must be either Monthly or Yearly or Quaterly."
              );
              return;
            }
            if (data_list[index].cost != "") {
              if (isNaN(Number(data_list[index].cost))) {
                this.toastr.error(
                  "Row Number " +
                    data_list[index].row +
                    " contains string value in cost colunm! It must be number."
                );
                return;
              }
            }
            var regex =
              /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

            if (data_list[index].video_url != "") {
              if (!regex.test(data_list[index].video_url)) {
                this.toastr.error(
                  "Row Number " +
                    data_list[index].row +
                    " does not contain valid URL! It must be URL."
                );
                return;
              }
            }
          }
          this.serviceList = data_list;
          this.number_of_pricebook_data = this.serviceList.length;

          this.step = step;
          this._router.navigate([`/add-service/${this.step}`]);
        }
      };
    } else if (priceBookType == 2) {
      let reader: FileReader = new FileReader();
      reader.readAsText(this.file);

      reader.onload = (e) => {
        let csv: any = reader.result;
        let allTextLines = csv.split(/\r|\n|\r/);
        let headers = allTextLines[0].split(",");

        if (headers[3] == "service_type" && this.priceBookType == 2) {
          this.toastr.warning(
            "Pricebook Data and Price Book Type Mismatch! Please select Services option from select Type"
          );
          return;
        }

        if (headers.length > 10) {
          this.toastr.error(
            "Invalid Format. Please download the product sample format for overview."
          );
          return;
        }
        if (headers[0] != "name") {
          this.toastr.error(
            "Invalid Format. The first column of first row should contain name text."
          );
          return;
        } else if (headers[1] != ["description"]) {
          this.toastr.error(
            "Invalid Format. The second column of first row should contain description text."
          );
          return;
        } else if (headers[2] != "rate") {
          this.toastr.error(
            "Invalid Format. The third column of first row should contain rate text."
          );
          return;
        } else if (headers[3] != "quantity") {
          this.toastr.error(
            "Invalid Format. The fourth column of first row should contain quantity text."
          );
          return;
        } else if (headers[4] != "sku") {
          this.toastr.error(
            "Invalid Format. The fifth column of first row should contain sku text."
          );
          return;
        } else if (headers[5] != "cost") {
          this.toastr.error(
            "Invalid Format. The sixth column of first row should contain cost text."
          );
          return;
        } else if (headers[6] != "serial_no") {
          this.toastr.error(
            "Invalid Format. The seventh column of first row should contain serial_no text."
          );
          return;
        } else if (headers[7] != "model_no") {
          this.toastr.error(
            "Invalid Format. The eighth column of first row should contain model_no text."
          );
          return;
        } else if (headers[8] != "part_no") {
          this.toastr.error(
            "Invalid Format. The ninth column of first row should contain part_no text."
          );
          return;
        } else if (headers[9] != "video_url") {
          this.toastr.error(
            "Invalid Format. The tenth column of first row should contain video_url text."
          );
          return;
        } else {
          let lines = [];
          for (let i = 1; i < allTextLines.length; i++) {
            // split content based on comma
            let data = allTextLines[i].split(",");
            if (data.length === headers.length) {
              let tarr = [];
              for (let j = 0; j < headers.length; j++) {
                tarr.push(data[j]);
              }

              // log each row to see output
              lines.push(tarr);
            }
          }

          let data_list = [];
          for (let i = 1; i < this.lines.length; i++) {
            data_list.push({
              name: this.lines[i][0],
              description: this.lines[i][1],
              rate: this.lines[i][2],
              quantity: this.lines[i][3],
              sku: this.lines[i][4],
              cost: this.lines[i][5],
              serial_no: this.lines[i][6],
              model_no: this.lines[i][7],
              part_no: this.lines[i][8],
              video_url: this.lines[i][9],
              row: +i + 1,
            });
          }
          for (let index in data_list) {
            if (isNaN(Number(data_list[index].rate))) {
              this.toastr.error(
                "Row Number " +
                  data_list[index].row +
                  " contains string value in rate colunm! It must be number"
              );

              return;
            }
            if (isNaN(Number(data_list[index].quantity))) {
              this.toastr.error(
                "Row Number " +
                  data_list[index].row +
                  " contains string value in quantity colunm! It must be number"
              );
              return;
            }
            if (data_list[index].cost != "") {
              if (isNaN(Number(data_list[index].cost))) {
                this.toastr.error(
                  "Row Number " +
                    data_list[index].row +
                    " contains string value in cost colunm! It must be number."
                );
                return;
              }
            }
            // var pattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/);
            var regex =
              /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
            if (data_list[index].video_url != "") {
              if (!regex.test(data_list[index].video_url)) {
                this.toastr.error(
                  "Row Number " +
                    data_list[index].row +
                    " does not contain valid URL! It must be URL."
                );
                return;
              }
            }
          }
          this.itemList = data_list;
          for (let i of this.itemList) {
          }

          this.number_of_pricebook_data = this.itemList.length;

          this.step = step;
          this._router.navigate([`/add-service/${this.step}`]);
        }
      };
    }
  }

  AddPriceBookData() {
    this.uploadConfirmBtn = true;
    this.modalRef.hide();
    this._addOnService.AddPriceBookData(this.file).subscribe((result) => {
      if (result["success"]) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        if (this.priceBookType == 1) {
          this.toastr.success("CSV data uploaded successfully for Service");
        } else {
          this.toastr.success("CSV data uploaded successfully for Product");
        }
        this.uploadConfirmBtn = false;
      } else {
        this.toastr.success("CSV data could not uploaded successfully");
        this.uploadConfirmBtn = false;
      }
      this.uploadConfirmBtn = false;
    });
  }
}
