import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ItemService } from "src/app/shared/services/item/item.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ItemModel } from "src/app/shared/models/item/item.modal";
import { ToastrService } from "ngx-toastr";
import { SettingService } from "src/app/shared/services/setting/setting.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ServiceModuleService } from "src/app/shared/services/service/service-module.service";

@Component({
  selector: "app-service-new",
  templateUrl: "./service-new.component.html",
  styleUrls: ["./service-new.component.scss"],
})
export class ServiceNewComponent implements OnInit {
  public loading: boolean = false;
  public saveToServiceBtn: boolean = false;
  public addMoreBtn: boolean = false;
  public submitted: boolean = null;
  public service_id: string = null;
  public transformedData: any = null;
  public tax_rate_list: any[] = [];
  public tax_exemption_list: any[] = [];

  public ItemModal: ItemModel = new ItemModel();
  public serviceTypes: string[] = ["Monthly", "Quarterly", "Yearly"];
  constructor(
    public fb: FormBuilder,
    public ItemService: ItemService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public SettingService: SettingService,
    public serviceService: ServiceModuleService
  ) {
    this.service_id = this.activatedRoute.snapshot.paramMap.get("service_id");
    this.getServiceDetails(this.service_id);
    this.getTaxRateList();
    this.getTaxExemptionList();
  }

  ngOnInit() {
    window.scroll(0, 0);
    if (this.service_id == null) {
      this.GetServiceList();
    }
  }

  public NewService = this.fb.group({
    name: ["", Validators.required],
    service_number: ["", Validators.required],
    description: ["", Validators.required],
    service_type: [""],
    rate: [
      "",
      [Validators.required, Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")],
    ],
    video_url: [
      "",
      [
        Validators.pattern(
          /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
        ),
      ],
    ],
    is_active: [true],
    show_in_widget: [false],
    sku: [""],
    cost: ["", Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")],
    taxable: [0],
    tax_rate: [""],
    tax_exemption: [""],
    serial_no: [""],
  });

  get f() {
    return this.NewService.controls;
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = "00000";
    this.transformedData =
      transformFormat.substring(0, transformFormat.length - starting.length) +
      starting;

    this.NewService.get("service_number").setValue(this.transformedData);
    this.NewService.updateValueAndValidity();

    return this.transformedData;
  }

  GetServiceList() {
    this.serviceService.GetServiceList().subscribe(
      (result) => {
        var service_number = result["data"].length + 1;
        this.digitFormatter(service_number);
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    );
  }

  getServiceDetails(service_id) {
    if (service_id != null) {
      this.loading = true;
      this.serviceService.GetServiceById(service_id).subscribe(
        (result) => {
          this.loading = false;

          this.ItemModal.service_id = result["data"].service_id;
          this.ItemModal.service_number = result["data"].service_number;
          this.NewService.get("service_number").setValue(
            result["data"].service_number
          );
          this.NewService.updateValueAndValidity();
          this.ItemModal.name = result["data"].name;
          this.ItemModal.description = result["data"].description;
          this.ItemModal.service_type = result["data"].service_type;
          this.ItemModal.rate = result["data"].rate;
          this.ItemModal.video_url = result["data"].video_url;
          this.ItemModal.is_active = result["data"].is_active;
          this.ItemModal.sku = result["data"].sku;
          this.ItemModal.cost = result["data"].cost;
          this.ItemModal.serial_no = result["data"].serial_no;
          this.ItemModal.tax_rate = result["data"].tax_rate;
          this.ItemModal.tax_exemption = result["data"].tax_exemption;
          this.ItemModal.is_active = result["data"].is_active;
          this.ItemModal.show_in_widget = result["data"].show_in_widget;
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
          }
        }
      );
    } else {
      this.loading = false;
    }
  }

  SaveItem() {
    this.submitted = true;

    if (
      this.NewService.value.is_active === "" ||
      this.NewService.value.is_active === null
    ) {
      this.NewService.value.is_active = false;
    }
    if (
      this.NewService.value.show_in_widget === "" ||
      this.NewService.value.show_in_widget === null
    ) {
      this.NewService.value.show_in_widget = false;
    }

    if (this.NewService.invalid) {
      return;
    }
    this.saveToServiceBtn = true;
    if (this.service_id == null) {
      // this.NewService.value.rate = this.NewService.value.rate.substring(1);
      this.serviceService.AddService(this.NewService.value).subscribe(
        (result) => {
          if (result["success"]) {
            this.showSuccess(result["msg"]);
            this.router.navigate(["/services"]);
          } else {
            this.showDanger(result["msg"]);
          }
          this.saveToServiceBtn = false;
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
            this.saveToServiceBtn = false;
          }
        }
      );
    } else {
      // this.ItemModal.rate= this.ItemModal.rate.substring(1);
      this.serviceService.UpdateService(this.ItemModal).subscribe(
        (result) => {
          if (result["success"]) {
            this.showSuccess(result["msg"]);
            this.router.navigate(["/services"]);
          } else {
            this.showDanger(result["msg"]);
          }
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
          }
        }
      );
    }
  }

  SaveItemAndMore() {
    this.submitted = true;

    // if (
    //   this.NewService.value.is_active === "" ||
    //   this.NewService.value.is_active === null
    // ) {
    //   this.NewService.value.is_active = false;
    // }
    if (
      this.NewService.value.show_in_widget === "" ||
      this.NewService.value.show_in_widget === null
    ) {
      this.NewService.value.show_in_widget = false;
    }

    if (this.NewService.invalid) {
      return;
    }
    this.addMoreBtn = true;
    if (this.service_id == null) {
      // this.NewService.value.rate = this.NewService.value.rate.substring(1);
      this.serviceService.AddService(this.NewService.value).subscribe(
        (result) => {
          if (result["success"]) {
            this.showSuccess(result["msg"]);
            this.submitted = false;

            this.NewService.reset();
            this.NewService.get('is_active').setValue(true);
            this.NewService.get('is_active').updateValueAndValidity();
            this.submitted = false;
            this.GetServiceList();
          } else {
            this.showDanger(result["msg"]);
          }
          this.addMoreBtn = false;
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
            this.addMoreBtn = false;
          }
        }
      );
    } else {
      // this.ItemModal.rate= this.ItemModal.rate.substring(1);
      this.serviceService.UpdateService(this.ItemModal).subscribe(
        (result) => {
          if (result["success"]) {
            this.submitted = false;

            this.showSuccess(result["msg"]);
            this.NewService.reset();
            this.submitted = false;
            this.GetServiceList();
          } else {
            this.showDanger(result["msg"]);
          }
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
          }
        }
      );
    }
  }

  getTaxRateList() {
    this.SettingService.GetTaxRates().subscribe((result) => {
      this.tax_rate_list = result["data"];
    });
  }

  getTaxExemptionList() {
    this.SettingService.GetTaxExemptionList().subscribe((result) => {
      this.tax_exemption_list = result["data"];
    });
  }
  showSuccess(msg: string) {
    this.toastr.success(msg);
  }

  showDanger(msg: string) {
    this.toastr.error(msg);
  }
}
