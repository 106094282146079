import { Injectable } from '@angular/core';
import { TokenService } from '../token/token.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(
    public _http:HttpClient,
    public _TokenService:TokenService
  ) { }

  AddItem(data):Observable<[]> {
    let URL = `${environment.url}item/create`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  UpdateItem(data):Observable<[]> {
    let URL = environment.url+'item/edit/'+ data.item_id;

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetItemList():Observable<[]> {
    let URL = `${environment.url}item/list`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetItemById(item_id):Observable<[]> {
    let URL = environment.url+'item/view/'+item_id;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetPaidCount():Observable<[]> {
    let URL = `${environment.url}invoice/paid-count`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetUnPaidCount():Observable<[]> {
    let URL = `${environment.url}invoice/unpaid-count`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }
  GetPaidAmount():Observable<[]> {
    let URL = `${environment.url}invoice/paid-amount`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }
  GetUnPaidAmount():Observable<[]> {
    let URL = `${environment.url}invoice/unpaid-amount`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }
  GetPaidList():Observable<[]> {
    let URL = `${environment.url}invoice/list`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  Delete(item_id):Observable<[]> {
    let URL = environment.url+'item/delete/'+item_id;

    return this._http.post(URL,item_id,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  ItemSearch(data):Observable<[]> {
    let URL = environment.url+'item/search';

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  UpdateItemInventory(data):Observable<[]> {
    let URL = environment.url+'item/inventory-management';

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  exportCSV(data):Observable<[]> {
    data.start_date = new Date(data.start_date.getTime() - data.start_date.getTimezoneOffset() * 60000);
    data.end_date = new Date(data.end_date.getTime() - data.end_date.getTimezoneOffset() * 60000);
    
    let URL = `${environment.url}quickbook/item`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),
      // responseType:ResponseContentType.Blob
    })
      .pipe(map((res: any) => res));
  }

  showItemInWidget(data):Observable<[]> {
    let URL = environment.url+'item/add-widget';

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }
}

