import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddChecklistService } from 'src/app/shared/services/checklist/add-checklist.service';
import { SettingService } from "src/app/shared/services/setting/setting.service";



@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit {

  modalRef?: BsModalRef;
  public userForm: FormGroup;
  public setIndex: any = "";
  index: number;
  public getItem: any;
  public currentRoute: string = null;
  public getItems: any
  public isEdit: boolean = false;
  public demoObject: object[] = []
  public checklistItem: any[] = []
  public loading: boolean = true;


  constructor(private modalService: BsModalService,
    public formBuilder: FormBuilder,
    public AddCheckListService : AddChecklistService,
    public SettingService: SettingService,
    private activatedRoute: ActivatedRoute) {
    this.userForm = this.formBuilder.group({
      firstItem: ['', [Validators.minLength(5)]]
    })
    this.currentRoute = this.activatedRoute.snapshot['_routerState']['url'];
    this.getCheckList();
  }

  ngOnInit() {}

  get userformData() {
    return this.userForm.controls;
  }

  getCheckList(){
    this.loading = true; 
    this.AddCheckListService.GetCheckList().subscribe(
      (result)=>{
      this.loading = false;        
        this.checklistItem = result["data"];
      },
      (err: HttpErrorResponse) => {
      this.loading = false;        
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    )
  }
}
