import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EstimateService } from 'src/app/shared/services/estimate/estimate.service';
import { Validators, FormBuilder } from '@angular/forms';
import { ItemService } from 'src/app/shared/services/item/item.service';
import { EstimateModel } from 'src/app/shared';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-estimate-pay',
  templateUrl: './estimate-pay.component.html',
  styleUrls: ['./estimate-pay.component.scss']
})
export class EstimatePayComponent implements OnInit {
  public estimate_id: string = null;
  public submitted: boolean = false;
  public EstimateDetail: string = null;
  public estimate_number: string = null;
  public item_id: string = null;
  public rate: string = null;
  public amount: number = 0;
  public payment_type: string = null;

  public EstimateModel: EstimateModel = new EstimateModel();

  public NewEstimate = this.fb.group({
    payment_type: ['', Validators.required],
  });

  constructor(
    public _router: Router,
    public activatedRoute: ActivatedRoute,
    public EstimateService: EstimateService,
    public fb: FormBuilder,
    public ItemService: ItemService,
    public toastr: ToastrService
  ) {
    this.estimate_id = this.activatedRoute.snapshot.paramMap.get('estimate_id');
    this.estimate_number = this.activatedRoute.snapshot.paramMap.get('estimate_index');
    this.getEstimateDetails(this.estimate_id);
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    let transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;

    return transformedData;
  }

  get f() {
    return this.NewEstimate.controls;
  }
  getEstimateDetails(estimate_id) {
    if (estimate_id != null) {
      this.EstimateService.GetEstimateDetails(estimate_id).subscribe(
        result => {

          if (result['success']) {
            this.EstimateModel.payment_type = result['data'].payment_type;
            this.payment_type = result['data'].payment_type;
            this.EstimateModel.due_date = result['data'].due_date;
            for (let index of result['data']['estimate_items']) {
              this.amount = this.amount + (+index.rate);
            }
            this.getItem();
          }
        }
      )
    }
  }

  getItem() {
    if (this.item_id != null) {
      this.ItemService.GetItemById(this.item_id).subscribe(
        result => {
          this.rate = result['data']['rate'];
        }
      )
    }
  }

  UpdateEstimate() {
    this.submitted = true;
    if (this.NewEstimate.invalid) {
      return;
    }
    this.EstimateService.UpdateEstimate(this.NewEstimate.value, this.estimate_id).subscribe(
      result => {
        if (result['success']) {
          if (this.payment_type == null) {
            this.showCreateSuccess();

          } else {
            this.showUpdateSuccess();

          }
          this._router.navigate(['/estimate-send/', this.estimate_id, this.estimate_number]);

        } else {
          if (this.payment_type == null) {
            this.showCreateDanger()
          } else {
            this.showUpdateDanger();

          }

        }

      }
    )
  }

  showCreateSuccess() {
    this.toastr.success('Estimate created successfully');
  }

  showCreateDanger() {
    this.toastr.error('Estimate could not be created successfully');
  }

  showUpdateSuccess() {
    this.toastr.success('Estimate Update successfully');
  }

  showUpdateDanger() {
    this.toastr.error('Estimate could not be upadate successfully');
  }
}
