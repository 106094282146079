import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { CalendarOptions, EventClickArg } from "@fullcalendar/core";
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { ScheduleCalendarDetailsComponent } from "src/app/layout/modal/schedule-calendar-details/schedule-calendar-details.component";
import { CustomerModel, Sidebar } from "src/app/shared";
import { CustomerService } from "src/app/shared/services/customer/customer.service";
import { EstimateService } from "src/app/shared/services/estimate/estimate.service";
import { ItemService } from "src/app/shared/services/item/item.service";
import { ScheduleService } from "src/app/shared/services/schedule/schedule.service";
import { ServiceModuleService } from "src/app/shared/services/service/service-module.service";
import { SettingService } from "src/app/shared/services/setting/setting.service";
import { UserService } from "src/app/shared/services/user/user.service";
import * as moment from 'moment';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Subscription } from 'rxjs';
import { EventDragStartArg, EventResizeStartArg } from "@fullcalendar/interaction";
import { Schedule } from "src/app/shared";
import { AuthService } from 'src/app/shared/services/auth/auth.service';
@Component({
  selector: "app-schedule-calendar",
  templateUrl: "./schedule-calendar.component.html",
  styleUrls: ["./schedule-calendar.component.scss"],
  styles: [
    `
    /* :host >>> .nav-tabs > .invoices__tab-pane:nth-child(1) > .nav-link {
      color: #d00 !important;
    }

    :host >>> .nav-tabs > .invoices__tab-pane:nth-child(2) > .nav-link {
      color: #00b118 !important;
    } */

    :host >>> .nav-tabs .nav-link {
      border: 0;
      border-bottom: 2px solid #ddd;
      border-radius: 0;
      color: #79848c;
      font-weight: 600;
      background-color: transparent !important;
    }

    :host >>> .nav-tabs .nav-link.active {
      color: #000 !important;
      border-bottom: 2px solid #e49037 !important;
    }
    `
  ],
})
export class ScheduleCalendarComponent implements OnInit {
  @ViewChild('addSchedulerModal', {static: false}) addSchedulerModal : ModalDirective;
  @ViewChild('eventCalendar', {static: false}) eventCalendar : FullCalendarComponent;

  public scheduleCalendarDetails: any[] = [];
  public CustomerModel: CustomerModel = new CustomerModel();
  public loading = false;
  public Customers: any[] = [];
  public current_tax:any =null;
  public rate: any = 0;
  public productServices: any[] = [];
  public itemArr: any[] = [];
  public itemDataArr: any[] = [];
  public List: any[] = [];
  public current_selected_item: any = null;
  public customerDetails: object = null;
  public customerViewDetails: object[] = [];
  public states: any[] = [];
  public add_remove_item: boolean = false;
  public addBtnDisable: boolean = false;
  public addBtnLoader: boolean = false;
  public customerId: boolean = true;
  public alternative_number: any[] = [];
  public customerList: any[] = [];
  public subsciption: Subscription;
  public scheduleModel: Schedule = new Schedule();
  public startTime: any;
  public endTime: any;
  public startTimeRange: any;
  public endTimeRange: any;
  modalRef: BsModalRef;
  calendarVisible = true;
  public addSchedule: FormGroup = this.fb.group({
    customer_id: [''],
    line_item: [''],
    assign_to: [null, Validators.required],
    time: ['', Validators.required]
  });

  // existingCustomer
  public selectExistingCustomer: FormGroup = this.fb.group({
    existingCustomer: ['']
  })
  public user_data: any = 0;
  public addCustomerForm: FormGroup = this.fb.group({
    name: ["", Validators.required],
    email: [
      "",
      [
        Validators.required,
        Validators.pattern(/^[^*-._'%=+!`#~$*?^{}&@();:"]+([\.]?\w+)*[^*-._'%=+!`#~$*?^{}&@();:"]*@\w+([\.]?\w+)*(\.\w{2,10})+$/),
      ],
    ],
    mobile: ["", [Validators.required]],
    address_type: ["", Validators.required],
    address: ["", Validators.required],
    locality: [null],
    zip: ["", [Validators.required, Validators.maxLength(15), Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]],
  });

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: "prev,next,today",
      center: "title",
      right: "dayGridMonth,timeGridWeek,timeGridDay",
    },
    initialView: "dayGridMonth",
    events: [],
     eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short',
    },
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    scrollTime: '00:00:00',
    initialDate: new Date(),
    eventClick: this.openModal.bind(this),
    dateClick: this.handleDateClick.bind(this),
    select: this.openTime.bind(this, 'time'),
    // eventDragStart: this.dragStart.bind(this),
    eventResize: this.dragStart.bind(this),
    timeZone: 'local'
  };

  constructor(
    public _sidebar: Sidebar,
    public scheduleService: ScheduleService,
    public modalService: BsModalService,
    public router: Router,
    public fb: FormBuilder,
    public SettingService : SettingService,
    public ItemService: ItemService,
    public serviceService : ServiceModuleService,
    public UserService: UserService,
    public CustomerService : CustomerService,
    public EstimateService: EstimateService,
    public AuthService: AuthService,
    public toastr: ToastrService
  ) {
    this.getUserList();
    this.productServiceList();
    this.getStateList();
    this.scheduleDetails();
    this.CustomerList();
    this.user_data = this.AuthService.User().user;
  }

  ngOnInit() {
    window.scroll(0,0);
    this.scheduleService.setAcceptStatus = false;
  }

  get f() {
    return this.addCustomerForm.controls;
  }
  get scheduleForm() {
    return this.addSchedule.controls;
  }

  scheduleAllData:any[] = [] ;
  scheduleDetails() {
    this.loading = true;
    if(this.isNewScheduleAdded){
      this.scheduleCalendarDetails = [];
      this.isNewScheduleAdded = false;
    }
    this.scheduleService.scheduleCalendardetails().subscribe((response) => {
      this.scheduleAllData = response["data"];
      for(let i in this.scheduleAllData){
        this.scheduleCalendarDetails.push(this.scheduleAllData[i].calendar_data);
      }
      this.calendarOptions.events = this.scheduleCalendarDetails;
      this.loading = false;
    });
  }
  public getTime:any;

  openTime (type, calEvent: any) {
    if(calEvent.view.type == 'timeGridWeek' || calEvent.view.type == 'timeGridDay') {
      this.getTime = moment(calEvent.start).format('h:mm a');
      this.addSchedule.get('time').setValue(this.getTime);
    }
  }

  openModal(clickInfo: EventClickArg) {
    // return
    let initialState;
    for (const i of this.scheduleAllData) {
      if (i['widget_service_id'] == clickInfo.event._def.publicId) {
        initialState = {
          data: i
        };
        break;
      }
    }
    this.modalRef = this.modalService.show(ScheduleCalendarDetailsComponent, {
      initialState,
      class: 'modal-dialog-centered',
    });
  }

  scheduleDate: any = null;
  openDate:any = null;
  handleDateClick(arg) {
    this.openDate = moment(arg.dateStr).format('MM-DD-YYYY');
    let newDate = new Date();
    let formateDate = moment(newDate).format('YYYY-MM-DD')
    if(arg.dateStr >= formateDate){
      this.addSchedulerModal.show();
      this.itemDataArr = [];
      this.customerViewDetails = [];
      this.addCustomerForm.get('name').setValue('');
      this.addCustomerForm.get('email').setValue('');
      this.addCustomerForm.get('mobile').setValue('');
      this.addCustomerForm.get('address_type').setValue('');
      this.addCustomerForm.get('address').setValue('');
      this.addCustomerForm.get('locality').setValue('');
      this.addCustomerForm.get('zip').setValue('');
      this.selectExistingCustomer.get('existingCustomer').setValue('');
      this.addSchedule.get('line_item').setValue('');
      if(arg.view.type == "dayGridMonth") {
        this.addSchedule.get('time').setValue('');
      }
      this.addSchedule.get('assign_to').setValue('');
      this.addSchedule.get('assign_to').updateValueAndValidity();
      this.scheduleSubmitted = false;
      this.submitted = false;
      this.isCustomerAdded = false;
    }
    this.scheduleDate = arg.dateStr;
    if(arg.view.type == "timeGridWeek" || arg.view.type == "timeGridDay") {
      this.scheduleDate = (arg.dateStr).slice(0,-15);
    }
    this.addSchedule.get('assign_to').setValidators([Validators.required]);
    this.addSchedule.get('time').setValidators([Validators.required]);
    this.addSchedule.updateValueAndValidity();
    this.isCustomerResponse = false;
    this.add_item= false
    this.add_remove_item= false
  }

  closeModal(){
    this.modalRef.hide()
  }

  getStateList() {
    this.CustomerService.GetStates().subscribe(
      (result) => {
        this.states = result["data"];
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    );
  }
  // Product and service
  OnChangeItem(event) {

    if(!this.addSchedule.value.line_item || !event){
      this.add_remove_item = false
      this.addSchedule.updateValueAndValidity();
      this.addBtnDisable = true;
    }


    if(event){
      this.addBtnLoader = true;
      this.addBtnDisable = true;
      this.current_selected_item = event;

      let value = event['id'];
      if(event['group']=='Product'){
        this.ItemService.GetItemById(value).subscribe(
          result => {
            this.rate = result['data']['rate'];
            if(result['data'].tax_rate){
              this.current_tax = result['data'].item_taxrate.rate
            }else{
              this.current_tax = null;
            }
            this.addBtnLoader = false;
            this.addBtnDisable = false;

          }, (err:HttpErrorResponse) => {
            this.addBtnLoader = false;
            this.addBtnDisable = false;
            if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
              this.SettingService.LogOut();
            }
          }
        )
      }
      else if(event['group']=='Service'){
        this.serviceService.GetServiceById(value).subscribe(
          (result: any) => {
            this.rate = result['data']['rate'];
            if(result['data'].tax_rate){
              this.current_tax = result['data'].service_taxrate.rate
            }else{
              this.current_tax = null;
            }
            this.addBtnLoader = false;
            this.addBtnDisable = false;
          }, (err:HttpErrorResponse) => {
            this.addBtnLoader = false;
            this.addBtnDisable = false;
            if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
              this.SettingService.LogOut();
            }
          }
        )
      }
    }

  }

  getProductServiceList () {
    if(!this.productServices.length) {
      this.toastr.warning("Please add product and services first");
    }
  }

   //Get Product and service list
   productServiceList(){
    this.EstimateService.GetProductServiceList(null).subscribe(
      result=>{
        this.List = result['data'];
        this.productServices = [
          ...this.List[0].map(
            (item: Object) => ({
              group: 'Service',
              name: item['name'],
              id: item['service_id'],
            })
          ),
          ...this.List[1].map(
            (item: Object) => ({
              group: 'Product',
              name: item['name'],
              id: item['item_id'],
            })
          )
        ]
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  submitted: boolean = false;
  newCustomerAdded: boolean = false;
  isCustomerResponse: boolean = false;
  addCustomer(){
    this.submitted = true;
    this.isCustomerResponse = true;
    if(this.addCustomerForm.invalid){
      this.isCustomerResponse = false;
      return
    }
    const data = {
      name: this.addCustomerForm.get('name').value,
      mobile: this.addCustomerForm.value.mobile.e164Number,
      email: this.addCustomerForm.get('email').value,
      address: this.addCustomerForm.get('address').value,
      locality: this.addCustomerForm.get('locality').value,
      zip: this.addCustomerForm.get('zip').value,
      address_type: this.addCustomerForm.get('address_type').value,
    }
    this.CustomerService.AddCandidate(data).subscribe((result) => {
      if(result['success']){
        this.customerDetails = result['data'];
        this.newCustomerAdded = true;
        this.toastr.success(result['msg']);
        // this.saveSchedule();
        this.isCustomerResponse = true;
        this.isCustomerAdded = false;
      }else{
        this.toastr.error(result['msg']);
        this.isCustomerResponse = false;
      }
    })
  }

  // Select existing customer
  Customer(event){
      if (event) {
        this.CustomerService.GetCustomerById(event)
          .subscribe((result) => {
            this.customerDetails = result['data'];
            this.customerViewDetails = result['data'];
            this.alternative_number = result['data'].alternative_number;
            if(this.customerDetails){
              this.isCustomerAdded = false
            }
          }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

  public custom_phone_vaidation: string = "";
  CheckValidation(event) {
    this.custom_phone_vaidation = event.target.validationMessage;
  }

  // Get Org user list
  public userlist: any[] = [];
  getUserList() {
    this.loading = true;
    this.UserService.UserList().subscribe(
      (result) => {
        if (result["success"]) {
          this.userlist = result["data"];
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    );
  }

  // On search cutomer list
  GetCustomerListOnSearch(event){
    let data = {
      search: event.target.value
    }
    if(data.search!=''){
      this.CustomerService.GetCustomerListOnSearch(data).subscribe(
        result=>{
          this.Customers = result['data'];
        }
      )
    }else{
      this.Customers = null;
    }
  }

  add_item: boolean = false;
  AddItem(){
    this.add_item = false;
    this.add_remove_item = false;

    if(this.current_selected_item['group'] == 'Product'){
      this.ItemService.GetItemById(this.addSchedule.get('line_item').value).subscribe((result) => {
        this.itemDataArr.push(result['data']);
      })

    }
    if(this.current_selected_item['group'] == 'Service'){
      this.serviceService.GetServiceById(this.addSchedule.get('line_item').value).subscribe((result) => {
        this.itemDataArr.push(result['data']);
      })
    }


    this.addSchedule.get('line_item').setValue(null);
    this.addSchedule.get('line_item').updateValueAndValidity();
  }

  DeleteItem(index){
  let _index = this.itemDataArr.findIndex((x) => {
    });
    // if(_index > -1){
    //   this.itemDataArr.splice(_index,1);
    // }
    this.itemDataArr.splice(index,1);
  }

  isCustomerAdded: boolean = false;
  public scheduleSubmitted: boolean = false;
  public isNewScheduleAdded: boolean = false;

  saveSchedule(){
    this.scheduleSubmitted = true;
    if(!this.customerDetails && this.itemDataArr.length == 0){
      this.isCustomerAdded = true;
      this.add_item = true;
      return
    }

    if(!this.customerDetails){
      this.isCustomerAdded = true;
      return
    }


    // If Product or service is selected from dropdown but not added
    if(this.addSchedule.get('line_item').value && this.itemDataArr.length === 0){
      this.add_item = true;
    }
    if(this.itemDataArr.length == 0){
      this.add_item = true;
      return;
    }

    if ( this.itemDataArr.length > 0 ) {
      this.add_item = false;
    }
    let flag =0;
      if(this.addSchedule.value.line_item){
        if(this.itemDataArr.length>0)

        for(let value of  this.itemDataArr){
          if((value.item_id || value.service_id) == this.addSchedule.value.line_item){

            flag =1;
            break;

          }else{
           flag =0;
          }
        }
        if(flag==0){
          this.add_remove_item = true;
          return;

        }else{
          this.add_remove_item = false;
        }
      }
    // If form form is invalid
    if(this.addSchedule.invalid){
      return
    }
    this.isNewScheduleAdded = true

    // Creating item array for form
    for(let [index, obj] of Object.entries(this.itemDataArr)){
      if(obj.service_id){
        this.itemArr.push(obj.service_id)
      }
      if(obj.item_id){
        this.itemArr.push(obj.item_id)
      }
    }

    // Form data
    const data = {
      note: '',
      customer_id: this.customerDetails['customer_id'],
      // name: this.customerDetails['name'],
      // email: this.customerDetails['email'],
      // address: this.customerDetails['address'],
      // mobile: this.customerDetails['mobile'],
      date: this.scheduleDate,
      time: this.addSchedule.get('time').value,
      assign_to: this.addSchedule.get('assign_to').value,
      accepted: 1,
      status: 2,
      items: this.itemArr,
    }

    this.scheduleService.scheduleServiceWidget(data).subscribe((response) => {
      this.isNewScheduleAdded = false
      if(response['success']){
        this.scheduleMessage();
        this.scheduleCalendarDetails = [];
        this.calendarOptions.events = this.scheduleCalendarDetails;
        setTimeout(() => {
          this.scheduleDetails();
        },0);
        this.addSchedulerModal.hide();
        this.cancelSchedule();
        this.selectExistingCustomer.get('existingCustomer').setValue('');
        this.selectExistingCustomer.get('existingCustomer').updateValueAndValidity();
      }else{
      }
    })
  }

  scheduleMessage(){
    let msgData = {
      customername: this.customerDetails['name'],
      mobile: this.customerDetails['mobile']
    }
    this.scheduleService.scheduleMessage(msgData).subscribe((result) => {
      if(result['success']){
        this.toastr.success(result['msg']);
      }else{
        this.toastr.error(result['msg']);
      }
    })
  }

  cancelSchedule(){
    this.addSchedulerModal.hide();
    this.addSchedule.reset();

    this.addSchedule.get('assign_to').clearValidators();
    this.addSchedule.get('time').clearValidators();
    this.scheduleSubmitted = false;

    this.addCustomerForm.reset();
    this.addCustomerForm.clearValidators();
    this.submitted = false;

    // Select user
    this.isCustomerAdded = false;
    this.newCustomerAdded = false;

    // Customer
    this.customerDetails = null;
    this.Customers = null;
    this.customerViewDetails = [];

    // Item
    this.itemDataArr = [];
    this.add_item= false
    this.add_remove_item= false
  }

  public customerState: string = '';
  @ViewChild("placesRef", {static: true}) placesRef : GooglePlaceDirective;

  getAddress(address: any){
    this.addCustomerForm.get('address').setValue(address.formatted_address);
    address.address_components.forEach(element => {
      if(element['types'][0] === "postal_code") {
        this.addCustomerForm.get('zip').setValue(element['long_name']);
      }else if(element['types'][0] === "administrative_area_level_1"){
        this.customerState = element['long_name'];
        this.addCustomerForm.get('locality').setValue(element['long_name']);
      }
    });
  }

  getCustomerList() {
    if(!this.customerList.length) {
      this.toastr.warning("Please add customer first");
    }
  }

  CustomerList() {
    this.CustomerService.CustomerList().subscribe(
      result => {
        this.customerList = result['data'];
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  public getEndTime: any;

  dragStart(event: any) {
    if(this.user_data.super_admin == 1 || this.user_data.super_admin == 2) {
      // this.getEndTime = moment(event.el.fcSeg.end).format('h:mm a');
      this.getEndTime = event.el.fcSeg.end;
      let initialState;
      for (const i of this.scheduleAllData) {
        if (i['widget_service_id'] == event.event._def.publicId) {
          initialState = {
            data: i
          };
          break;
        }
      }
      this.scheduleModel.end_work = this.getEndTime;
      this.scheduleModel.widget_service_id = initialState.data.widget_service_id;
      this.scheduleModel.date = new Date (initialState.data.date);
      this.scheduleModel.accepted = true;

      this.scheduleService
        .updateScheduleDetails(this.scheduleModel)
        .subscribe((response) => {
          this.loading = false;
          if (response["success"]) {
            this.toastr.success(response["msg"]);
            this.scheduleModel.accepted = true;
          } else {
            this.toastr.error(response["msg"]);
          }
        });
    } else {
      this.toastr.warning("Only admin and super admin can set the time range");
    }
  }
}

