import { Component, OnInit, Input } from '@angular/core';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentModel, PaymentSettings, EstimateModel, InvoiceModel, InvoiceAllList } from 'src/app/shared';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs'
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EstimateService } from 'src/app/shared/services/estimate/estimate.service';
import { Subscription } from 'rxjs';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';

declare type Message = {
  message: string;
};
@Component({
  selector: 'app-advance-payment',
  templateUrl: './advance-payment.component.html',
  styleUrls: ['./advance-payment.component.scss']
})
export class AdvancePaymentComponent implements OnInit {
  public estimate_id: string= '';
  public organization_id: string = null;
  public publishabe_key: any = null;
  public secret_key: any = null;
  public status: number = null;
  public loading: boolean = false;
  public accepted: boolean = false;
  public EstimateModel: EstimateModel = new EstimateModel;
  public _estimateData: any = null;
  public PaymentData: PaymentSettings = new PaymentSettings();
  public PaymentModel: PaymentModel = new PaymentModel();
  public subsciption: Subscription;
  public invoice_id: string = '';
  public InvoiceModel: InvoiceModel = new InvoiceModel();
  public InvoiceDetails: InvoiceAllList = new InvoiceAllList();
  public advance: any = 0;

  elements: Elements;
  card: StripeElement;
  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  constructor(
    private stripeService: StripeService,
    public activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public _Auth: AuthService,
    public SettingService: SettingService,
    public modalRef: BsModalRef,
    public EstimateService: EstimateService,
    public InvoiceService: InvoiceService,

  ) { 
    let _data = localStorage.getItem("payment_data");
    
  }

  ngOnInit() {
    this.EstimateService.setApproveInvoiceState = false;
    this.InvoiceService.setIsAdvancePay = false;
  }

  ngAfterViewInit() {
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        let style = {
          base: {
            iconColor: '#666EE8',
            color: '#31325F',
            lineHeight: '50px',
            fontWeight: 300,
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSize: '18px',
            '::placeholder': {
              color: '#CFD7E0'
            }
          },
          invalid: {
            '::placeholder': {
              color: '#d00',
            },
          },
          placeholder: 'Zip',
        }

        if (!this.card) {
          this.card = this.elements.create('cardNumber', {
            style,
          });
          this.card.mount('#stripe-cardNumber');

          this.card = this.elements.create('cardExpiry', {
            style,
          });
          this.card.mount('#stripe-cardExpiry');

          this.card = this.elements.create('cardCvc', {
            style,
          });
          this.card.mount('#stripe-cardCvc');

          this.card = this.elements.create('postalCode', {
            style,
            placeholder: 'Zip',
          });
          this.card.mount('#stripe-cardZip');
        }
    });
    
    if(this.estimate_id != null) {
      this.GetEstimateDetails(this.estimate_id);
    } 
    if (this.invoice_id != null) {
      this.getInvoiceDetails(this.invoice_id);
    }
  }

  getPaymentData(organization_id) {
    this.SettingService.GetPaymentDetails(organization_id)
      .subscribe((result) => {
        this.PaymentData = result['data'];
        this.SettingService.setPaymentData = this.PaymentData;
        this.secret_key = result['data']['secret'];
        localStorage.setItem("payment_data", JSON.stringify(result['data'].key));
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      }
      )
  }
  
  getInvoiceDetails(invoice_id) {
    this.InvoiceService.InvoiceView(invoice_id).subscribe(
      (result) => {
        this.InvoiceDetails.advance = result['data'].advance;
        this.InvoiceDetails.advance_type = result['data'].advance_type;
        this.InvoiceDetails.invoice_id = result['data'].invoice_id;
        this.InvoiceDetails.organization_id = result['data'].organization_id;

        // if (this.status == 2) {
        //   this.loading = false;
        //   this.accepted = true;
        // }

        // this.getCustomerDetails(this.InvoiceModel.customer_id);
        this.getPaymentData(this.organization_id);
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  GetEstimateDetails(estimate_id) {
    this.EstimateService.GetEstimateDetails(estimate_id)
        .subscribe((result) => {
            this._estimateData = result['data'];
            this.EstimateModel.estimate_id = this._estimateData.estimate_id;
            this.EstimateModel.advance = this._estimateData.advance;
            this.EstimateModel.advance_type = this._estimateData.advance_type;           
            this.EstimateModel.total = this._estimateData.gross_amount;   
            this.EstimateModel.is_approved = this._estimateData.is_approved;        
            this.getPaymentData(this.organization_id);

        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }

      )
  }

  approveInvoice() {
    this.EstimateService.Approve(this.estimate_id).subscribe(
      (result) => { 
        if(result['success']) {
          this.EstimateService.setApproveInvoiceState = true;
        }else {
          this.EstimateService.setApproveInvoiceState = false;
        }

        if(result['data']){
          this.toastr.success(result['msg']);
          this._estimateData=result['data'];
          this.EstimateModel.is_approved = this._estimateData.is_approved;
        }else{
          this.toastr.error(result['msg']);
        }

      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      })
  }

  pay() {
    this.loading = true;
    this.accepted = false;

    this.stripeService.createToken(this.card, { name })
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          // this.PaymentModel.funding = result.token.card.funding;
          if (this.estimate_id) {
            this.PaymentModel.amount_to_be_paid = this.EstimateModel.advance;
          } else if (this.invoice_id) {
            this.PaymentModel.amount_to_be_paid = this.InvoiceDetails.advance;
          }
          this.PaymentModel.estimate_id = this.estimate_id;
          this.PaymentModel.invoice_id = this.invoice_id;
          // this.PaymentModel.brand = result.token.card.brand;
          // this.PaymentModel.last4 = result.token.card.last4;
          // this.PaymentModel.card_id = result.token.card.id;
          // this.PaymentModel.exp_month = result.token.card.exp_month;
          // this.PaymentModel.exp_year = result.token.card.exp_year;
          // this.PaymentModel.zip = result.token.card.address_zip;
          // this.PaymentModel.customer_name = this.CustomerModel.name;
          // this.PaymentModel.email = this.CustomerModel.email;
          // this.PaymentModel.address_line_1 = this.CustomerModel.address;
          // this.PaymentModel.address_line_2 = this.CustomerModel.address;
          // this.PaymentModel.state = this.CustomerModel.locality;
          // this.PaymentModel.country = result.token.card.country;
          this.PaymentModel.StripeToken = result.token.id;
          // this.PaymentModel.secret_key = this.secret_key;

          let data = {
            estimate_id: this.PaymentModel.estimate_id,
            amount_to_be_paid: this.PaymentModel.amount_to_be_paid,
            StripeToken: this.PaymentModel.StripeToken,
            invoice_id: this.PaymentModel.invoice_id
          }

          this.EstimateService.Charge(data).subscribe(
            result => {
              if (result['success']) {
                this.loading = false;
                this.accepted = true;
                this.InvoiceService.setIsAdvancePay = true;
                this.showSuccess(result['message']);
                this.modalRef.hide();
                if(this.EstimateModel.estimate_id) {
                  this.approveInvoice();
                }
              } else {
                this.loading = false;
                this.accepted = false;
                this.InvoiceService.setIsAdvancePay = false;
                this.showDanger(result['message']);
              }
            }
          )
        } else if (result.error) {
          // Error creating the token
          this.showDanger(result.error.message);

          this.loading = false;
          this.accepted = false;
        }
      }, (err:HttpErrorResponse) => {
        this.loading=false;
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    );
  }
  Loading() {
    let interval = timer(5000);
    this.loading = true;
    this.accepted = false;

    interval.subscribe(() => {
      this.loading = false;
      this.accepted = true;
    });
  }
  showSuccess(msg: string) {
    this.toastr.success(msg);
  }
  showDanger(msg: string) {
    this.toastr.error(msg);
  }

}

