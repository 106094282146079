import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Schedule, Sidebar } from "src/app/shared";
import { ScheduleService } from "src/app/shared/services/schedule/schedule.service";
import { SettingService } from "src/app/shared/services/setting/setting.service";

@Component({
  selector: "app-my-schedule-detail",
  templateUrl: "./my-schedule-detail.component.html",
  styleUrls: ["./my-schedule-detail.component.scss"],
})
export class MyScheduleDetailComponent implements OnInit {
  public loading: boolean = true;
  public submitted: boolean = false;
  public widget_service_id: string = null;
  public addBtnLoader = false;
  public checklist_enabled: boolean = false;
  public enabled_modules: any[] = [];
  public scheduleDetailsForm = this.fb.group({
    customerName: [""],
    customerEmail: [""],
    customerNumber: [""],
    date: [""],
    // requestList: [""],
    status: [""],
    note: [""],
    customer_notes: [""],
    time: [""],
    address: [""],
  });
  constructor(
    public _sidebar: Sidebar,
    public fb: FormBuilder,
    public scheduleService: ScheduleService,
    public toastr: ToastrService,
    public activatedRoute: ActivatedRoute,
    public SettingService: SettingService,
    public router: Router
  ) {
    this.widget_service_id = this.activatedRoute.snapshot.params.widget_service_id;
    this.scheduleDetails(this.widget_service_id);
    if ( JSON.parse( localStorage.getItem( 'activated_modules' ) ) )
    {
      this.enabled_modules = JSON.parse( localStorage.getItem( 'activated_modules' ) );
      for ( let index of this.enabled_modules )
      {
        if ( index[ 'module_id' ] == '50bf5d16f34b' && index[ 'enabled' ] == 1)
        {
          this.checklist_enabled = true;
        }
      }
    }
    localStorage.setItem("widget_service_id", JSON.stringify(this.widget_service_id))
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  distance(request) {
    this.scheduleService.distance(request).subscribe((response) => {
      // console.log(response)
    })
  }
  public scheduleModel: Schedule = new Schedule();
  get f() {
    return this.scheduleDetailsForm.controls;
  }
  productList;
  scheduleDetails(widgetServiceId) {
    this.scheduleService
      .scheduleDetails(widgetServiceId)
      .subscribe((response: any) => {
        // console.log("scheduleDetails:", response);
        this.loading = false;

        if(response["success"]){

          this.scheduleModel.widget_service_id = response["data"].widget_service_id;
          this.scheduleModel.estimate_id = response["data"].estimate_id;
          this.scheduleModel.checklist_id = response["data"].checklist_id;
          this.scheduleModel.name = response["data"].widgetservice_customer.name;
          this.scheduleModel.email = response["data"].widgetservice_customer.email;
          this.scheduleModel.mobile = response["data"].widgetservice_customer.mobile;
          this.scheduleModel.date = response["data"]["date"];
          this.scheduleModel.requestList = response["data"].widgetservice_widgetserviceitems;
          this.productList = response["data"].widgetservice_widgetserviceitems;
          this.scheduleModel.status = response["data"].status;
          this.scheduleModel.work_status = response["data"].work_status;
          this.scheduleModel.note = response["data"].note;        
          this.scheduleModel.customer_note = response["data"].widgetservice_customer.customer_note;
          // let item = response["data"]['widgetservice_customer_address']['address'];
          if(response["data"]['widgetservice_customer_address'] && response["data"]['widgetservice_customer_address']['address']) {
            this.scheduleModel.address = response["data"]['widgetservice_customer_address']['address'];
          }

          this.scheduleModel.time = response["data"].time;
          this.scheduleModel.start_journey = response["data"].start_journey;
          this.scheduleModel.end_journey = response["data"].end_journey;
          this.scheduleModel.start_work = response["data"].start_work;
          this.scheduleModel.end_work = response["data"].end_work;
          this.scheduleModel.accepted = response["data"].accepted;
          if(response["data"].work_status == 4) {
            this.isScheduleCompleted = false;
          }
        }
      },
      (err: HttpErrorResponse) => {
        // this.loading = false;
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut()
        }
      }
      );
  }

  scheduleDetailsUpdate() {
    if (this.scheduleModel.status == "new") {
      this.scheduleModel.status = 2;
    } else if (this.scheduleModel.status == "in-progress") {
      this.scheduleModel.status = 3;
    } else if (this.scheduleModel.status == "completed") {
      this.scheduleModel.status = 4;
    }
    
    let data = {
      widget_service_id: this.scheduleModel.widget_service_id,
      estimate_id: this.scheduleModel.estimate_id,
      checklist_id: this.scheduleModel.checklist_id,
      name: this.scheduleModel.name,
      requestList: this.scheduleModel.requestList,
      note: this.scheduleModel.note,        
      customer_note: this.scheduleModel.customer_note,
      address: this.scheduleModel.address,
      time: this.scheduleModel.time,
      accepted: this.scheduleModel.accepted,
      date: new Date(this.scheduleModel.date),  
    }
    // console.log("save change button:", data);
    
    this.scheduleService
      .updateScheduleDetails(data)
      .subscribe((response) => {
        this.loading = false;
        if (response["success"]) {
          this.toastr.success(response["msg"]);
          this.router.navigate(["/my-schedule"]);
        } else {
          this.toastr.error(response["msg"]);
        }
      });
  }

  getPosition(): Promise<any>{
    return new Promise(
      (resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // console.log("position:", position);
            
            if(position) {
              resolve(position);
            }else {
              reject('Permission denied.')
            }
          }
        );
      }
    )
  }

  sendPosition(position) {
    let request = {
      origins: [position.coords.latitude, position.coords.longitude].toString() ,
      destinations: this.scheduleModel.address,
      widget_service_id: this.scheduleModel.widget_service_id,
      mode: 'DRIVING',
    };
    // console.log("request:", request);
    
    this.scheduleService.distance(request).subscribe((result) => {
      // console.log("distance matrix result", result);
      
      if(result['rows'][0]['elements'][0]['duration']){
        let leftTime = result['rows'][0]['elements'][0]['duration']['text'];
        this.toastr.success("Time left" + " " + leftTime);
      }else{
        this.toastr.error("Route is not found");
      }
    })
  }

  public isScheduleCompleted: boolean = true;
  UpdateWorkStatus(state_of_work){
    this.addBtnLoader = true;
    if (state_of_work == "new") {
      this.scheduleModel.status = 2;
    } else if (state_of_work == "in-progress") {
      this.scheduleModel.status = 3;

      this.getPosition().then((position) => {
        this.sendPosition(position);
      })

    } else if (state_of_work == "completed") {
      this.scheduleModel.status = 4;
      this.scheduleModel.work_status = 4;
    }else if (state_of_work == 2) {
      this.scheduleModel.work_status = 2;
      this.scheduleModel.status = 3;
    }else if (state_of_work == 3) {
      this.scheduleModel.work_status = 3;
      this.scheduleModel.status = 3;
    }
    var date = new Date();

    if(state_of_work== 'in-progress'){
      this.scheduleModel.start_journey=date;
    }else if(state_of_work== 2){
      this.scheduleModel.end_journey=date;
    }else if(state_of_work== 3){
      this.scheduleModel.start_work=date;
    }else if(state_of_work== "completed"){
      this.scheduleModel.end_work=date;
    }
    this.scheduleModel.date = new Date(this.scheduleModel.date);
    // console.log("work status button:", this.scheduleModel);
    
    this.scheduleService
      .updateScheduleDetails(this.scheduleModel)
      .subscribe((response) => {
        this.loading = false;
        if (response["success"]) {
          this.addBtnLoader = false;
          this.toastr.success(response["msg"]);
          this.scheduleDetails(this.widget_service_id);
          this.scheduleModel.accepted = true;
          if(state_of_work== "completed"){
            this.isScheduleCompleted = false;
          }
        } else {
          this.addBtnLoader = false;
          this.toastr.error(response["msg"]);
        }
      }); 
  }

  reviewRequestMessage(){
    this.scheduleService.reviewRequest(this.scheduleModel.widget_service_id).subscribe((result) => {
      this.loading = false;
      if (result["success"]){
        this.toastr.success(result["msg"]);
      }else{
        this.toastr.error(result["msg"]);
      }
    })
    
  }
}
