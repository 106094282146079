import { Injectable } from '@angular/core';
import { BaseModel } from '../base/base.model';

@Injectable({
  providedIn: 'root'
})

export class EstimateModel extends BaseModel {
  public estimate_id: string = null;
  public organization_id: string = null;
  public assigned_to: string = null;
  public name: string = null;
  public rate: any = null;
  public due_date: any = null;
  public line_item: string = null;
  public items: any[] = [];
  public items_rate: any = null;
  public discount: string = null;
  public sales_tax: any = null;
  public created_at: any = null;
  public updated_at: any = null;
  public total: number = null;
  public customer_id: string = null;
  public discount_type: string = null;
  public advance_type: string = null;
  public advance: any = null;
  public note: string = null;
  public private_note: string = null;
  public payment_type: string = null;
  public estimate_number: any = null;
  public amount: any = null;
  public invoice_id: string = null;
  public is_approved: any = null;
  public is_approved_to_workorder: any = null;
  public gross_amount: any = 0;
  public work_order_update: boolean =false;
  public work_order_id: string =null;
  public mobile: number | string = null;
  public checklist_id: string = null;
  public assign_to: string = null;
  public start_date: any =null;
  public end_date: any =null;
  public alternative_number: any[] = [];
  public advance_paid: boolean =false;
  public net_amount: any = 0;
  public is_draft_save: boolean =false;
  public draft_reset: boolean =false;
}

