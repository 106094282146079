import { Injectable } from '@angular/core';
import { BaseModel } from '../base/base.model';

@Injectable({
  providedIn: 'root'
})

export class EmailModel extends BaseModel {
  public invoice_id: string = null;
  public invoice_number: string = null;
  public template: string = null;
  
}

