import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class Search {
  public type: string = null;
  public item_id: string = null;
  public item_name: string = null;
  public rate: string = null;
  public customer_id: string = null;
  public customer_name: string = null;
  public address: string = null;
  public email: string = null;
}