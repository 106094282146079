import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EstimateService } from 'src/app/shared/services/estimate/estimate.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BroadcastChannel } from 'broadcast-channel';
import { WorkOrderService } from 'src/app/shared/services/work-order/work-order.service';
import { WorkOrderModel } from 'src/app/shared/models/work-order/work-order.model';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';

declare type Message = {
  message: string;
};

@Component({
  selector: 'app-work-order-self',
  templateUrl: './work-order-self.component.html',
  styleUrls: ['./work-order-self.component.scss']
})
export class WorkOrderSelfComponent implements OnInit {

  public loading: boolean = false;
  public work_order_id: string = null;
  public work_order_index: any = null;
  public email_sent: any = null;
  public WorkOrderModel: WorkOrderModel = new WorkOrderModel;
  public modalRef: BsModalRef;
  public _workOrderData: any = null;
  public item_total: number = 0;
  public discount: number = 0;
  public sales_tax: number = 0;
  public advance: number = 0;
  public amount: number = 0;
  public work_order_items: any[] = [];
  // public amount: number = 0;
  public customer_name: string = null;
  public customer_email: string = null;
  public mobile: any = null;
  public loading1: boolean = false;
  public accepted1: boolean = false;
  public loading2: boolean = false;
  public accepted2: boolean = false;
  public organization_id: string = null;
  public organization_name: string = null;
  public template: any = null;
  public userData:any = null;
  public BC2: BroadcastChannel<Message> = null;
  public env = environment; 
  public checklistId: string = '';
  public checklist_enabled: boolean = false;
  public enabled_modules: any[] = [];
  public alternative_number: any[] = [];

  constructor(
    public activateRoute: ActivatedRoute,
    public EstimateService: EstimateService,
    public _modalService: BsModalService,
    public toastr: ToastrService,
    public router: Router,
    public Auth:AuthService,
    public SettingService:SettingService,
    public WorkOrderService:WorkOrderService,
    public InvoiceService:InvoiceService,
    private sanitizer:DomSanitizer,
  ) {
    this.userData = this.Auth.User();
    (err:HttpErrorResponse) => {
      if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
        this.SettingService.LogOut();
      }
    }
    this.organization_id = this.userData.user.organization_id;
    this.getOrganizationDetails(this.organization_id);
    this.work_order_id = this.activateRoute.snapshot.paramMap.get('work_order_id');
    
    this.work_order_index = this.activateRoute.snapshot.paramMap.get('work_order_index');
    this.getWorkOrderDetails(this.work_order_id);
    if ( JSON.parse( localStorage.getItem( 'activated_modules' ) ) )
      {
        this.enabled_modules = JSON.parse( localStorage.getItem( 'activated_modules' ) );
        for ( let index of this.enabled_modules )
        {
          if ( index[ 'module_id' ] == '50bf5d16f34b' && index[ 'enabled' ] == 1)
          {
            this.checklist_enabled = true;
          }
        }
      }  
  }

  ngOnInit() {
    this.channel2();
    this.BC2.onmessage = (e) => {
      if (e.message) {
        // location.reload();
      }
    }
    window.scroll(0, 0);
  }

  channel2() {
    this.BC2 = new BroadcastChannel('approved', {
      idb: {
        onclose: () => {
          this.BC2.close();
          this.channel2();
        }
      }
    });
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    let transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;

    return transformedData;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  getWorkOrderDetails(work_order_id) {

    if (work_order_id != null) {
      this.loading = true;


      this.WorkOrderService.GetWorkOrderDetail(work_order_id)
        .subscribe((result) => {
          if (result['success']) {
            this.loading = false;
            this.checklistId = result['data']['checklist_id']
            this._workOrderData = result['data'];
            this.WorkOrderModel.work_order_id = this._workOrderData.work_order_id;
            this.WorkOrderModel.is_approved_invoice = this._workOrderData.is_approved_invoice;
            this.WorkOrderModel.is_approved_estimate = this._workOrderData.is_approved_estimate;
            this.WorkOrderModel.invoice_id = this._workOrderData.invoice_id;
            this.WorkOrderModel.estimate_id = this._workOrderData.estimate_id;
            this.WorkOrderModel.due_date = this._workOrderData.due_date;
            this.WorkOrderModel.start_date = this._workOrderData.start_date;
            this.WorkOrderModel.end_date = this._workOrderData.end_date;
            this.WorkOrderModel.note = this._workOrderData.note;
            this.WorkOrderModel.private_note = this._workOrderData.private_note;
            if (this._workOrderData['workorder_customers'] != null && this._workOrderData['workorder_customers'].length > 0) {
          
              this.customer_name = this._workOrderData['workorder_customers'][0].name;
              this.customer_email = this._workOrderData['workorder_customers'][0].email;
              this.mobile = this._workOrderData['workorder_customers'][0].mobile;
              this.alternative_number = this._workOrderData['workorder_customers'][0].alternative_number;
            }
            if (result['data']['workorder_workorderitems'] != null && result['data']['workorder_workorderitems'].length > 0) {
              // for(let index of result['data']['workorder_workorderitems']){
              //   this.item_name.push(index.name);
              //   this.rates.push(index.rate);
              // }
              this.work_order_items = result['data']['workorder_workorderitems'];
              for (let i of this.work_order_items) {
                this.item_total = this.item_total + (+i.rate * i.quantity);
    
              }
            }
            this.WorkOrderModel.discount = this._workOrderData.discount;
            this.WorkOrderModel.work_order_number = this._workOrderData.work_order_number;
            this.WorkOrderModel.discount_type = this._workOrderData.discount_type;
            this.WorkOrderModel.advance = this._workOrderData.advance;
            this.WorkOrderModel.advance_type = this._workOrderData.advance_type;
            this.WorkOrderModel.sales_tax = this._workOrderData.sales_tax;
            this.WorkOrderModel.assign_to = this._workOrderData.workorder_users.name;
            this.WorkOrderModel.assign_to_email = this._workOrderData.workorder_users.email;
            if(this._workOrderData.workorder_workorderitems[0]['workorderitems_items_with_trashed']!=null && 
              this._workOrderData.workorder_workorderitems[0]['workorderitems_items_with_trashed']!=''){

                this.WorkOrderModel.rate = this._workOrderData.workorder_workorderitems[0]['workorderitems_items_with_trashed'].rate;
                this.WorkOrderModel.line_item = this._workOrderData.workorder_workorderitems[0]['workorderitems_items_with_trashed'].name;

              }else if(this._workOrderData.workorder_workorderitems[0]['workorderitems_service_with_trashed']!=null && 
              this._workOrderData.workorder_workorderitems[0]['workorderitems_service_with_trashed']!=''){
                this.WorkOrderModel.line_item = this._workOrderData.workorder_workorderitems[0]['workorderitems_service_with_trashed'].name;
                this.WorkOrderModel.rate = this._workOrderData.workorder_workorderitems[0]['workorderitems_service_with_trashed'].rate;

              }
            this.WorkOrderModel.total = this._workOrderData.gross_amount;
            this.WorkOrderModel.created_at = this._workOrderData.created_at;
            this.WorkOrderModel.updated_at = this._workOrderData.updated_at;
          }
          if (this.WorkOrderModel.discount != null && this.WorkOrderModel.discount != '') {
            if (this.WorkOrderModel.discount_type == '%') {
              this.discount = (this.item_total * (+this.WorkOrderModel.discount)) / 100;
              this.amount = (+this.item_total) - this.discount;
            } else if (this.WorkOrderModel.discount_type == '$') {
              this.discount = (+this.WorkOrderModel.discount);
              this.amount = this.item_total - (+this.WorkOrderModel.discount);
            }
          }
  
          if (this.WorkOrderModel.sales_tax != null && this.WorkOrderModel.sales_tax != '') {
            if (this.discount == 0) {
              this.sales_tax = (this.item_total * (+this.WorkOrderModel.sales_tax)) / 100;
              this.amount = this.item_total + this.sales_tax;
  
  
            } else {
              this.sales_tax = (this.amount * (+this.WorkOrderModel.sales_tax)) / 100;
              this.amount = this.amount + this.sales_tax;
            }
  
          }
          if (this.WorkOrderModel.advance != null && this.WorkOrderModel.advance != '') {
            
            if (this.discount == 0 && this.sales_tax == 0) {

  
              if (this.WorkOrderModel.advance_type == '%') {

                this.advance = (this.item_total * (+this.WorkOrderModel.advance)) / 100;

              } else if (this.WorkOrderModel.advance_type == '$') {

                this.advance =  (+this.WorkOrderModel.advance);
                // this.advance = this.item_total - (+this.WorkOrderModel.advance);
              }
            } else if (this.discount == 0 && this.sales_tax != 0) {

  
              if (this.WorkOrderModel.advance_type == '%') {
  
                this.advance = (this.amount * (+this.WorkOrderModel.advance)) / 100;
  
              } else if (this.WorkOrderModel.advance_type == '$') {
                this.advance =  (+this.WorkOrderModel.advance);
                // this.advance = this.amount - (+this.WorkOrderModel.advance);
              }
            } else if (this.discount != 0 && this.sales_tax == 0) {
  
              if (this.WorkOrderModel.advance_type == '%') {
                this.advance = (this.amount * (+this.WorkOrderModel.advance)) / 100;
              } else if (this.WorkOrderModel.advance_type == '$') {
                this.advance =  (+this.WorkOrderModel.advance);
                // this.advance = this.amount - (+this.WorkOrderModel.advance);
              }
            } else {

              if (this.WorkOrderModel.advance_type == '%') {
                
                this.advance = (this.amount * (+this.WorkOrderModel.advance)) / 100;
              } else if (this.WorkOrderModel.advance_type == '$') {
                this.advance =  (+this.WorkOrderModel.advance);
                // this.advance = this.amount - (+this.WorkOrderModel.advance);
  
              }
            }
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }

      )
    }
  }

  DeleteWorkOrder(work_order_id) {
    if (work_order_id != null) {
      this.WorkOrderService.DeleteWorkOrder(work_order_id)
        .subscribe((result) => {
          if (result['success']) {
            this.modalRef.hide();
            this.toastr.success('Deleted Successfully.');
            this.router.navigate(['/work-order']);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

  getOrganizationDetails(organization_id) {
    this.SettingService.GetAccountDetails(organization_id).subscribe(
      result => {
        this.template = result['data']['template'];
        this.organization_name=result['data']['business_name'];
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  sanitize(){
    return this.sanitizer.bypassSecurityTrustUrl('sms:'+this.mobile+';?&body=Dear '+this.customer_name+', %0a%0aThank you for your business. %0a%0aYour work order '+this.WorkOrderModel.work_order_number+' can be viewed, printed, or downloaded as a PDF file from the link below. You can also choose to pay it online. %0a%0aClick to view Work Oder %0a'+this.env.frontend_url+'template-'+this.template+'/work_order/'+this.work_order_id+'  %0a%0aWe look forward to doing more business with you. %0a%0aRegards, %0a'+this.userData.user.name+' %0a'+this.organization_name);
    // return this.sanitizer.bypassSecurityTrustUrl('sms:'+this.mobile+';?&body=Dear '+this._invoiceData.customer_name+', Thank you for your business. Your invoice '+this._invoiceData.invoice_number+' can be viewed, printed, or downloaded as a PDF file from the link below. You can also choose to pay it online. Click to view Invoice http://111.93.175.214/invoiceapppwa/display/template-'+this.template+'/'+this.invoice_id+'  We look forward to doing more business with you. Regards, '+this.userData.user.name+' '+this.organization_name);
  }

  getDataUrl(template,id){
    return this.env.frontend_url+"template-"+template+'/work_order/'+id;
  }

  copy(evt: Event){
    let url: string = evt.target['dataset'].url;

    document.addEventListener('copy', function(evt) {
      evt.clipboardData.setData('text/plain', url);
      evt.preventDefault();
    }, true);

    let result = document.execCommand('copy');

    if (result) {
      this.toastr.success('Successfully Copied');
    }
    else {
      this.toastr.error('Not Copied');
    }
    // this.clipboard.writeText(url).then(() => {
    //   this.toastr.success('Successfully Copied');
    // }, () => {
    //   this.toastr.error('Not Copied');
    // });
  }

  ApproveEstimate(){
    this.WorkOrderService.ApproveInvoice(this._workOrderData);
    this.router.navigate(['/estimate-new/1'])
  }

  ApproveInvoice(){
    this.WorkOrderService.ApproveInvoice(this._workOrderData);
    this.router.navigate(['/new-invoice/1'])
  }

  
}
