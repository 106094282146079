import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceAgreementService {

  constructor(
    public _http:HttpClient,
    public _TokenService:TokenService
  ) { }

  AddServiceAgreement(data):Observable<[]> {
  
    // data.end_date = new Date(data.end_date.getTime() - data.end_date.getTimezoneOffset() * 60000);
    let URL = `${environment.url}serviceagreement/create`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  UpdateServiceAgreement(data):Observable<[]> {
    data.end_date = new Date(data.end_date.getTime() - data.end_date.getTimezoneOffset() * 60000);
    let URL = environment.url+'serviceagreement/edit/'+ data.service_agreement_id;

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  ApproveServiceAgreement(data):Observable<[]> {
    let URL = environment.url+'serviceagreement/approve-invoice/'+ data.service_agreement_id;

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetServiceAgreementList():Observable<[]> {
    let URL = `${environment.url}serviceagreement/list`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetServiceAgreementById(service_agreement_id):Observable<[]> {
    let URL = `${environment.url}serviceagreement/view/${service_agreement_id}`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  DeleteAgreement(service_agreement_id):Observable<[]> {
    let URL = `${environment.url}serviceagreement/delete/${service_agreement_id}`;

    return this._http.delete(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  SetTemplate(data) :Observable<[]> {
    
    let URL = `${environment.url}account/edit`;

    return this._http.post(URL, data,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  ServiceAgreementSearch(data):Observable<[]> {
    let URL = environment.url+'serviceagreement/search';

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  getUser(user_id):Observable<[]> {
    let URL = environment.url+ 'user_details/view/'+user_id;

    return this._http.get(URL,{
    }).pipe(map((res: any) => res));
  }
  
  createDefaultTemplate(data):Observable<[]> {
    let URL = environment.url+'serviceagreement-template/create/default';

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  viewTemplate(data):Observable<[]> {
    let URL = environment.url+'serviceagreement-template/view/template/'+data.template_name;
    return this._http.post(URL,data,{
    })
      .pipe(map((res: any) => res));
  }

  EditTemplate(data):Observable<[]> {
    let URL = environment.url+'serviceagreement-template/edit/template';

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetServiceAgreement(service_agreement_id):Observable<[]> {
    let URL = `${environment.url}serviceagreement/view-service-agreement/${service_agreement_id}`;

    return this._http.get(URL, {
    })
      .pipe(map((res: any) => res));
  }
}
