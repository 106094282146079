import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Sidebar } from 'src/app/shared';
import { AddOnService } from 'src/app/shared/services/add-on/add-on.service';
@Component({
  selector: 'my-add-ons',
  templateUrl: './my-add-ons.component.html',
  styleUrls: ['./my-add-ons.component.scss']
})
export class MyAddOnsComponent implements OnInit {
  add_ons_types: any[] = null;

  public AddOns = this.fb.group({
    module_id:[''],
    enabled:[],
  })
  constructor(
    public _sidebar: Sidebar,
    public AddOnService: AddOnService,
    public fb:FormBuilder,
    public toastr: ToastrService
  ) { 

    this.getActivatedAddOns();

  }

  ngOnInit() {
  }

  getActivatedAddOns(){
    this.AddOnService.GetActivatedAddOnList().subscribe(
      result=>{
        this.add_ons_types = result['data'];
        localStorage.setItem('activated_modules', JSON.stringify(this.add_ons_types))

      }
    )
  }

  EnableAddOn(event,module_id){
    this.AddOns.get('module_id').setValue(module_id);
    this.AddOns.get('enabled').setValue(event.target.checked);
    this.AddOns.updateValueAndValidity();
    this.AddOnService.EnableOrDiableAddOn(this.AddOns.value).subscribe(
      result=>{
        if(result['success']){
          localStorage.setItem('activated_modules', JSON.stringify(result['modules']))
          this.toastr.success(result['msg']);
        }else{
          this.toastr.error(result['msg']);
        }
        
      }
    )
    
  }


}
