import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class InvoicePaidList {
  public invoice_id: string = null;
  public customer_id: string = null;
  public item_id: string = null;
  public customer_name: string = null;
  public item_name: string = null;
  public rate: string = null;
  public amount: string = null;
  public status: any = null
  public total: any = null;
  public invoice_number: any = null;
  public markup: any = 0;
  public quantity: any = 0;
  public item_number: any = 0;
  public expense_number: any = 0;
}