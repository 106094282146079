import { Component, OnInit } from '@angular/core';
import { Sidebar } from 'src/app/shared';
import { timer } from 'rxjs';

@Component({
  selector: 'app-plan-settings',
  templateUrl: './plan-settings.component.html',
  styleUrls: ['./plan-settings.component.scss']
})
export class PlanSettingsComponent implements OnInit {
  public loading: boolean = false;

  constructor(public _sidebar: Sidebar) { }

  ngOnInit() {
    this.Loading();
    window.scroll(0, 0);
  }

  Loading() {
    let _timer = timer(5000);

    this.loading = true;
    _timer.subscribe(() => {
      this.loading = false;
    })
  }

}
