import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ItemModel } from 'src/app/shared';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { ItemService } from 'src/app/shared/services/item/item.service';
import { ServiceModuleService } from 'src/app/shared/services/service/service-module.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';

@Component({
  selector: 'app-add-product-and-service',
  templateUrl: './add-product-and-service.component.html',
  styles: [
    `
    /* :host >>> .nav-tabs > .invoices__tab-pane:nth-child(1) > .nav-link {
      color: #d00 !important;
    }

    :host >>> .nav-tabs > .invoices__tab-pane:nth-child(2) > .nav-link {
      color: #00b118 !important;
    } */

    :host >>> .nav-tabs .nav-link {
      border: 0;
      border-bottom: 2px solid #ddd;
      border-radius: 0;
      color: #79848c;
      font-weight: 600;
      background-color: transparent !important;
    }

    :host >>> .nav-tabs .nav-link.active {
      color: #000 !important;
      border-bottom: 2px solid #e49037 !important;
    }
    `
  ],
  styleUrls: ['./add-product-and-service.component.scss']
})
export class AddProductAndServiceComponent implements OnInit {

  

  public loading: boolean = false;
  public saveToServiceBtn: boolean = false;
  public addMoreBtn: boolean = false;
  public submitted: boolean = null;
  public service_id: string = null;
  public transformedData: any = null;
  public tax_rate_list: any[] = [];
  public tax_exemption_list: any[] = [];
  public saveToProductLoader: boolean = false;
  public quantity_exceed: boolean = false;

  public ItemModal: ItemModel = new ItemModel();
  public serviceTypes: string[] = ["Monthly", "Quarterly", "Yearly"];

  public newProduct: FormGroup = this.fb.group({
    name: ["", Validators.required],
    item_number: ["", Validators.required],
    description: ["", Validators.required],
    quantity: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
    quantity_for_invoice: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
    rate: [
      "",
      [Validators.required, Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")],
    ],
    video_url: [
      "",
      [
        Validators.pattern(
          /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
        ),
      ],
    ],
    is_active: [true],
    show_in_widget: [false],
    sku: [""],
    cost: ["", Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)],
    taxable: [0],
    tax_rate: [""],
    tax_exemption: [""],
    part_no: [""],
    model_no: [""],
    serial_no: [""],
  });;
  constructor(
    public fb: FormBuilder,
    public ItemService: ItemService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public SettingService: SettingService,
    public serviceService: ServiceModuleService,
    public InvoiceService: InvoiceService,
    public bsModalRef: BsModalRef
  ) {
    this.service_id = this.activatedRoute.snapshot.paramMap.get("service_id");
    this.getTaxRateList();
    this.getTaxExemptionList();
    this.getItemList();
    this.GetServiceList()
  }

  ngOnInit() {
    window.scroll(0, 0);

  }

  public NewService = this.fb.group({
    name: ["", Validators.required],
    service_number: ["", Validators.required],
    description: ["", Validators.required],
    service_type: [""],
    rate: [
      "",
      [Validators.required, Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")],
    ],
    video_url: [
      "",
      [
        Validators.pattern(
          /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
        ),
      ],
    ],
    is_active: [true],
    show_in_widget: [false],
    sku: [""],
    cost: ["", Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")],
    taxable: [0],
    tax_rate: [""],
    tax_exemption: [""],
    serial_no: [""],
  });

  get f() {
    return this.NewService.controls;
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = "00000";
    this.transformedData =
      transformFormat.substring(0, transformFormat.length - starting.length) +
      starting;

    this.NewService.get("service_number").setValue(this.transformedData);
    this.NewService.updateValueAndValidity();

    return this.transformedData;
  }
  
  GetServiceList() {
    this.serviceService.GetServiceList().subscribe(
      (result) => {
        var service_number = result["data"].length + 1;
        this.digitFormatter(service_number);
      });
  }

  SaveItem() {
    this.submitted = true;

    if (
      this.NewService.value.is_active === "" ||
      this.NewService.value.is_active === null
    ) {
      this.NewService.value.is_active = false;
    }
    if (
      this.NewService.value.show_in_widget === "" ||
      this.NewService.value.show_in_widget === null
    ) {
      this.NewService.value.show_in_widget = false;
    }

    if (this.NewService.invalid) {
      return;
    }
    this.saveToServiceBtn = true;
    if (this.service_id == null) {
      this.serviceService.AddService(this.NewService.value).subscribe(
        (result) => {
          if (result["success"]) {
            this.showSuccess(result["msg"]);
            this.serviceService.setServiceSuccess = result["success"];
            result['data'] = {
              service_id: result["data"].service_id,
              name: result["data"].name,
              rate: result["data"].rate,
              quantity: 1,
              tax_rate: result["data"].tax_rate,
              // quantity_for_invoice: this.newProduct.value.quantity_for_invoice,
              group: 'Service'
            }
            this.InvoiceService.setServiceData = result['data'];
          } else {
            this.showDanger(result["msg"]);
          }
          this.saveToServiceBtn = false;
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
          }
          this.saveToServiceBtn = false;

        }
      );
    }
  }

  SaveItemAndMore() {
    this.submitted = true;
    if (
      this.NewService.value.show_in_widget === "" ||
      this.NewService.value.show_in_widget === null
    ) {
      this.NewService.value.show_in_widget = false;
    }

    if (this.NewService.invalid) {
      return;
    }
    this.addMoreBtn = true;
    if (this.service_id == null) {
      this.serviceService.AddService(this.NewService.value).subscribe(
        (result) => {
          if (result["success"]) {
            this.showSuccess(result["msg"]);
            this.submitted = false;
            result['data'] = {
              service_id: result["data"].service_id,
              name: result["data"].name,
              rate: result["data"].rate,
              quantity: 1,
              tax_rate: result["data"].tax_rate,
              // quantity_for_invoice: this.newProduct.value.quantity_for_invoice,
              group: 'Service'
            }
            this.InvoiceService.setServiceData = result['data'];
            this.NewService.reset();
            this.NewService.get('is_active').setValue(true);
            this.NewService.get('is_active').updateValueAndValidity();
            this.submitted = false;
            this.GetServiceList();
            this.serviceService.setMoreServiceSuccess = result["success"];
          } else {
            this.showDanger(result["msg"]);
          }
          this.addMoreBtn = false;
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
          }
          this.addMoreBtn = false;

        }
      );
    } 
  }

  // Product


  get pf() {
    return this.newProduct.controls;
  }
  productSubmitted: boolean = false;
  saveProduct() {
    this.productSubmitted = true;
    if (
      this.newProduct.value.is_active === "" ||
      this.newProduct.value.is_active === null
    ) {
      this.newProduct.value.is_active = false;
    }

    if (
      this.newProduct.value.show_in_widget === "" ||
      this.newProduct.value.show_in_widget === null
    ) {
      this.newProduct.value.show_in_widget = false;
    }

    if (this.newProduct.invalid || this.quantity_exceed) {
      return;
    }
   
    this.saveToProductLoader = true;
      this.ItemService.AddItem(this.newProduct.value).subscribe(
        (result) => {
          if (result["success"]) {
            this.showSuccess(result["msg"]);
            this.serviceService.setServiceSuccess = result["success"];
            result['data']={
              item_id: result["data"].item_id,
              name: result["data"].name,
              rate: result["data"].rate,
              quantity: result["data"].quantity,
              tax_rate: result["data"].tax_rate,
              quantity_for_invoice: this.newProduct.value.quantity_for_invoice,
              group: 'Product'
            }
            this.InvoiceService.setProductData = result['data'];

          } else {
            this.showDanger(result["msg"]);
          }
          this.saveToProductLoader = false;
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
            this.saveToProductLoader = false;
          }
        }
      );
  }

  SaveProductAndMore() {

    this.productSubmitted = true;
    if (
      this.newProduct.value.show_in_widget === "" ||
      this.newProduct.value.show_in_widget === null
    ) {
      this.newProduct.value.show_in_widget = false;
    }

    if (this.newProduct.invalid || this.quantity_exceed) {
      return;
    }
    this.addMoreBtn = true;
      this.ItemService.AddItem(this.newProduct.value).subscribe(
        (result) => {
          if (result["success"]) {
            this.showSuccess(result["msg"]);
            this.productSubmitted = false;
            result['data'] = {
              item_id: result["data"].item_id,
              name: result["data"].name,
              rate: result["data"].rate,
              quantity: result["data"].quantity,
              tax_rate: result["data"].tax_rate,
              quantity_for_invoice: this.newProduct.value.quantity_for_invoice,
              group: 'Product'
            }
            this.InvoiceService.setProductData = result['data'];
            this.newProduct.reset();
            this.newProduct.get('is_active').setValue(true);
            this.newProduct.get('is_active').updateValueAndValidity();
            this.productSubmitted = false;
            this.serviceService.setMoreServiceSuccess = result["success"];
           

          } else {
            this.showDanger(result["msg"]);
          }
          this.addMoreBtn = false;
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
            this.addMoreBtn = false;
          }
        }
      );
  }

  // Common
  
  getTaxRateList() {
    this.SettingService.GetTaxRates().subscribe((result) => {
      this.tax_rate_list = result["data"];
    });
  }

  getTaxExemptionList() {
    this.SettingService.GetTaxExemptionList().subscribe((result) => {
      this.tax_exemption_list = result["data"];
    });
  }
  showSuccess(msg: string) {
    this.toastr.success(msg);
  }

  showDanger(msg: string) {
    this.toastr.error(msg);
  }
  digitProductFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = "00000";
    this.transformedData =
      transformFormat.substring(0, transformFormat.length - starting.length) +
      starting;

    this.newProduct.get("item_number").setValue(this.transformedData);
    this.newProduct.updateValueAndValidity();
    return this.transformedData;
  }
  getItemList() {
    this.loading = true;
    this.ItemService.GetItemList().subscribe(
      (result) => {
        this.loading = false;
        var item_number = result["data"].length + 1;
        this.digitProductFormatter(item_number);
    });
  }
  hideModal(){
    this.bsModalRef.hide()
  }

  SetQuantityForInvoice(value){
   
    if (+value > +this.newProduct.value.quantity){
      
      this.toastr.warning(this.newProduct.value.quantity + ' is present in our stock number of quantity!');
      this.quantity_exceed = true;
    }else{
      this.quantity_exceed = false;
      localStorage.setItem('quantity_for_invoice',value);

    }
  }
}
