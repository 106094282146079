import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Sidebar } from 'src/app/shared';
import { AddChecklistService } from 'src/app/shared/services/checklist/add-checklist.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
@Component({
  selector: 'app-checklist-todo',
  templateUrl: './checklist-todo.component.html',
  styleUrls: ['./checklist-todo.component.scss']
})
export class ChecklistTodoComponent implements OnInit {
  public loading: boolean = false;
  public checkListData: object[] = [];
  public routeParams: { [ x: string ]: any; };
  public previousUrl: string = '';
  public widget_service_id: string = null;
  constructor(
    private _activatedRoute: ActivatedRoute, 
    private _checkListService: AddChecklistService, 
    private _toastr: ToastrService,
    public _sidebar: Sidebar,
    public SettingService:SettingService
  ) {
    this.routeParams = this._activatedRoute.snapshot.params;
    if(this.routeParams['section'] === 'estimate') {
      this.previousUrl = `/estimate-self/${this.routeParams['section_id']}/1`
    }else if(this.routeParams['section'] === 'invoice'){
      this.previousUrl = `/invoice-self/${this.routeParams['section_id']}/1`
    }else if(this.routeParams['section'] === 'my-schedule'){
      let get_widget_service_id = JSON.parse(localStorage.getItem('widget_service_id'))
      this.previousUrl = `/my-schedule-detail/${get_widget_service_id}`
    }else{
      this.previousUrl = `/work-order-self/${this.routeParams['section_id']}/1`
    }
  }

  ngOnInit() {
    // this.getCheckList(this.routeParams['checklist_id']);
    this.GetChecklistBySectionId(this.routeParams['section_id']);
  }

  // getCheckList(checklist_id) {
  //   this.loading = true;

  //   this._checkListService.GetChecklistId(checklist_id).subscribe(
  //     (response) => {
  //       console.log(response);
        
  //       this.loading = false;
        
  //       if(response['success']) {
  //         this.checkListData = response['data']['checklist'];
  //         this.getCheckListStatus();
  //         this.checkListData = this.checkListData.map((item) => ({
  //           ...item,
  //           completed: false,
  //           comment: ''
  //         }))
  //       }
  //     },
  //     (err: HttpErrorResponse) => {
  //       this.loading = false;
  //     }
  //   )
  // }
  
  GetChecklistBySectionId(section_id) {
    this.loading = true;

    this._checkListService.GetChecklistBySectionId(section_id).subscribe(
      (response) => {
        this.loading = false;
        
        if(response['success']) {
          this.checkListData = response['data'];
          this.getCheckListStatus();
          this.checkListData = this.checkListData.map((item) => ({
            ...item,
            completed: false,
            comment: ''
          }))
        }
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
      }
    )
  }

  getCheckListStatus() {
    this.loading = true;
    this._checkListService.getStatus(this.routeParams).subscribe(
      (response) => {
        this.loading = false;

        if(response['data'].length) {
          this.checkListData.map((main_item, index) => {
            for(let item of response['data']) {
              if(main_item['checklist_item_id'] === item['checklist_item_id']) {
                main_item['completed'] = true;
                main_item['comment'] = item['comment'];
                break;
              }else {
                main_item['completed'] = false;
                main_item['comment'] = '';
              }
            }
          })
        }
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
      }
    )
  }

  public markCmploading: boolean[] = [];
  changeStatus(textArea: string, textInput: string, item_id, index) {
    this.markCmploading[index] = true;
    const formdata = {
      section_id: this.routeParams['section_id'],
      checklist_id: this.routeParams['checklist_id'],
      checklist_item_id: item_id,
      complete_status: 1,
      comment: '',
    }

    if(textArea) {
      formdata.comment = textArea
    }
    if(textInput) {
      formdata.comment = textInput
    }

    this._checkListService.changeStatus(formdata).subscribe(
      (response) => {
        this.markCmploading[index] = false;
        if(response['success']) {
          this._toastr.success(response['msg']);
          this.checkListData[index]['completed'] = true;
        }else {
          this._toastr.info(response['msg']);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      }
    )
  }
}
