import { Component, OnInit, SecurityContext } from "@angular/core";
import {
  Sidebar,
  AccountModel,
  InvoiceModel,
  CustomerModel,
} from "src/app/shared";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { InvoiceService } from "src/app/shared/services/invoice/invoice.service";
import { environment } from "src/environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { CustomerService } from "src/app/shared/services/customer/customer.service";

@Component({
  selector: "app-invoice-template-two",
  templateUrl: "./invoice-template-two.component.html",
  styleUrls: ["./invoice-template-two.component.scss"],
})
export class InvoiceTemplateTwoComponent implements OnInit {
  public organization_id: string = null;
  public invoice_id: string = null;
  public env = environment;
  public env_url: any = null;
  public payment_status: any = null;
  public date: Date = null;
  public AccountData: AccountModel = new AccountModel();
  public _invoiceData: InvoiceModel = new InvoiceModel();
  public _customerData: CustomerModel = new CustomerModel();

  constructor(
    public _sidebar: Sidebar,
    public _Auth: AuthService,
    public InvoiceService: InvoiceService,
    public _DomSanitizer: DomSanitizer,
    public activatedRoute: ActivatedRoute,
    public _invoiceService: InvoiceService,
    public CustomerService: CustomerService
  ) {
    let UserData = this._Auth.User();
    this.organization_id = UserData["user"].organization_id;
    this.getOrganizationDetails(this.organization_id);
    this.env_url = this._DomSanitizer.sanitize(
      SecurityContext.URL,
      this.env.url
    );
    this.invoice_id = this.activatedRoute.snapshot.paramMap.get("invoice_id");

    if (this.invoice_id) this.getInvoiceDetails(this.invoice_id);
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  getOrganizationDetails(organization_id) {
    this.InvoiceService.GetOrganizationDetails(organization_id).subscribe(
      (result) => {
        if (result["success"]) {
          this.AccountData.organization_id = result["data"]["account_id"];
          this.AccountData.business_name = result["data"]["business_name"];
          this.AccountData.email = result["data"]["email"];
          this.AccountData.address_line_1 = result["data"]["address_line_1"];
          this.AccountData.address_line_2 = result["data"]["address_line_2"];
          this.AccountData.logo = result["data"]["logo"];
        }
      }
    );
  }

  getInvoiceDetails(invoice_id) {
    this._invoiceService.InvoiceView(invoice_id).subscribe((result) => {
      this.payment_status = result["data"]["status"];
      this._invoiceData.customer_id = result["data"]["customer_id"];
      this._invoiceData.total = result["data"].total;
      if (
        result["data"]["updated_at"] != null ||
        result["data"]["updated_at"] != ""
      ) {
        this.date = result["data"]["updated_at"];
      } else {
        this.date = result["data"]["created_at"];
      }
      this.getCustomerDetails(this._invoiceData.customer_id);
    });
  }

  getCustomerDetails(customer_id) {
    this.CustomerService.GetCustomerById(customer_id).subscribe((result) => {
      this._customerData.name = result["data"]["name"];
      this._customerData.address = result["data"]["address"];
      this._customerData.locality = result["data"]["locality"];
      this._customerData.zip = result["data"]["zip"];
      this._customerData.mobile = result["data"]["mobile"];
      this._customerData.email = result["data"]["email"];
    });
  }
}
