import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    public _http:HttpClient,
    public _TokenService:TokenService
  ) { }

  AddUser(data):Observable<[]> {
    let URL = `${environment.url}user/create`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  UserList():Observable<[]> {
    let URL = `${environment.url}user/list`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  getUser(user_id):Observable<[]> {
    let URL = environment.url+'user/view/'+user_id;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  UpdateUser(data):Observable<[]> {
    let URL = environment.url+'user/edit/'+data.user_id;

    return this._http.post(URL,data, {
      headers: this._TokenService.GetTokenByHeader(),

    })
    .pipe(map((res: any) => res));
  }

  ActiveUsers():Observable<[]> {
    let URL = environment.url+'user/active-list-count';

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  AllActiveUsers():Observable<[]> {
    let URL = environment.url+'user/all-list-count';

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

}
