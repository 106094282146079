import { Component, OnInit } from '@angular/core';
import { Sidebar, InvoiceAllList } from 'src/app/shared';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { InvoicePaidList } from 'src/app/shared/models/invoice/invoice-list-paid.model';
import { InvoiceUnpaidList } from 'src/app/shared/models/invoice/invoice-list-unpaid.model';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingService } from 'src/app/shared/services/setting/setting.service';

@Component({
  selector: 'app-markup-invoices',
  templateUrl: './markup-invoices.component.html',
  styleUrls: ['./markup-invoices.component.scss'],
  styles: [
    `
    /* :host >>> .nav-tabs > .invoices__tab-pane:nth-child(1) > .nav-link {
      color: #d00 !important;
    }

    :host >>> .nav-tabs > .invoices__tab-pane:nth-child(2) > .nav-link {
      color: #00b118 !important;
    } */

    :host >>> .nav-tabs .nav-link {
      border: 0;
      border-bottom: 2px solid #ddd;
      border-radius: 0;
      color: #79848c;
      font-weight: 600;
      background-color: transparent !important;
    }

    :host >>> .nav-tabs .nav-link.active {
      color: #000 !important;
      border-bottom: 2px solid #e49037 !important;
    }
    `
  ],
})
export class MarkupInvoicesComponent implements OnInit {

  public invoiceAllData: InvoiceAllList[] = [];
  public invoicePaidData: InvoicePaidList[] = [];
  public invoiceUnpaidData: InvoiceUnpaidList[] = [];
  public customer_id: string = null;

  public loading: boolean = false;

  constructor(
    public _sidebar: Sidebar,
    public _invoiceService: InvoiceService,
    public activatedRoute: ActivatedRoute,
    public CustomerService: CustomerService,
    public SettingService:SettingService
  ) {
  }

  ngOnInit() {
    if (this.customer_id == null) {
      this.GetInvoiceList();
      this.GetPaidInvoiceList();
      this.GetUnpaidInvoiceList();
    }

    window.scroll(0, 0);
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    let transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;
    return transformedData;
  }

  GetInvoiceList() {
    this.loading = true;

    this._invoiceService.InvoiceList()
      .subscribe((result) => {
        let invoices: any[] = [];
        invoices = result['data'];
        this.loading = false;

        for (let index of invoices) {
          let _invoiceAllData = new InvoiceAllList();

          _invoiceAllData.customer_id = index['customer_id'];
          _invoiceAllData.invoice_id = index['invoice_id'];
          _invoiceAllData.item_id = index['line_item'];
          _invoiceAllData.amount = index['amount'];
          _invoiceAllData.total = index['total'];
          _invoiceAllData.invoice_number = index['invoice_number'];
          _invoiceAllData.markup =  index['total_markup_price'];
         


          if (index['invoice_customers'] !== null && index['invoice_customers'].length > 0) {
            _invoiceAllData.customer_name = index['invoice_customers'][0]['name'];
          }
          else {
            _invoiceAllData.customer_name = 'Not Available';
          }

          if (index['status'] !== null) {
            switch (index['status']) {
              case 2:
                _invoiceAllData.status = 'Paid';
                break;
              case 3:
                _invoiceAllData.status = 'Unpaid';
                break;
              default:
                _invoiceAllData.status = 'N/A';
                break;
            }
          }
          else {
            _invoiceAllData.status = 'N/A';
          }
          _invoiceAllData.expense_number = index['expense_total'];


          if (index['invoice_invoiceitems'] !== null && index['invoice_invoiceitems'].length > 0) {
            _invoiceAllData.item_number = index['invoice_invoiceitems'].length;

            for(let i of index['invoice_invoiceitems']){
              _invoiceAllData.quantity = _invoiceAllData.quantity+(i.quantity);
            }

            this.invoiceAllData.push(_invoiceAllData);  
          }
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    
    );
  }

  GetPaidInvoiceList() {
    this._invoiceService.InvoicePaidList()
      .subscribe((result) => {
        let paidInvoice = result['data'];

        for (let index of paidInvoice) {
          let _invoicePaidData = new InvoicePaidList();

          _invoicePaidData.customer_id = index['customer_id'];
          _invoicePaidData.invoice_id = index['invoice_id'];
          _invoicePaidData.item_id = index['line_item'];
          _invoicePaidData.amount = index['amount'];
          _invoicePaidData.total = index['total'];
          _invoicePaidData.invoice_number = index['invoice_number'];
          _invoicePaidData.markup =  index['total_markup_price'];

          if(index.status==2){
            _invoicePaidData.status = 'Paid';
 
           }
            _invoicePaidData.expense_number = index['expense_total'];

           if (index['invoice_invoiceitems'] !== null && index['invoice_invoiceitems'].length > 0) {
            _invoicePaidData.item_number = index['invoice_invoiceitems'].length;

            for(let i of index['invoice_invoiceitems']){
              _invoicePaidData.quantity = _invoicePaidData.quantity+(i.quantity);
            }
          }

          if (index['invoice_customers'] !== null && index['invoice_customers'].length > 0) {
            _invoicePaidData.customer_name = index['invoice_customers'][0]['name'];
          }
          else {
            _invoicePaidData.customer_name = 'Not Available';
          }
          // if (index['invoice_items'] !== null && index['invoice_items'].length > 0) {
          //   _invoicePaidData.item_name = index['invoice_items'][0]['name'];
          //   _invoicePaidData.status = 'Paid';
          // }
          this.invoicePaidData.push(_invoicePaidData);
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  GetUnpaidInvoiceList() {
    this._invoiceService.InvoiceUnpaidList()
      .subscribe((result) => {
        let unpaidInvoice = result['data'];

        for (let index of unpaidInvoice) {
          let _invoiceUnpaidData = new InvoiceUnpaidList();

          _invoiceUnpaidData.customer_id = index['customer_id'];
          _invoiceUnpaidData.invoice_id = index['invoice_id'];
          _invoiceUnpaidData.item_id = index['line_item'];
          _invoiceUnpaidData.amount = index['amount'];
          _invoiceUnpaidData.total = index['total'];
          _invoiceUnpaidData.invoice_number = index['invoice_number'];
          _invoiceUnpaidData.markup = index['total_markup_price'];
          if(index.status==3){
           _invoiceUnpaidData.status = 'Unpaid';

          }
          _invoiceUnpaidData.expense_number = index['expense_total'];


          if (index['invoice_invoiceitems'] !== null && index['invoice_invoiceitems'].length > 0) {
            _invoiceUnpaidData.item_number = index['invoice_invoiceitems'].length;

            for(let i of index['invoice_invoiceitems']){
              _invoiceUnpaidData.quantity = _invoiceUnpaidData.quantity+(i.quantity);
            }
          }

          if (index['invoice_customers'] !== null && index['invoice_customers'].length > 0) {
            _invoiceUnpaidData.customer_name = index['invoice_customers'][0]['name'];
          }
          else {
            _invoiceUnpaidData.customer_name = 'Not Available';
          }
          // if (index['invoice_items'] !== null && index['invoice_items'].length > 0) {
          //   _invoiceUnpaidData.item_name = index['invoice_items'][0]['name'];
          // }
          this.invoiceUnpaidData.push(_invoiceUnpaidData);
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }
}

