import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AddOnService } from 'src/app/shared/services/add-on/add-on.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'add-on-details',
  templateUrl: './add-on-details.component.html',
  styleUrls: ['./add-on-details.component.scss']
})
export class AddOnDetailsComponent implements OnInit {

  public user_name: string = null;
  public user_email: string = null;
  public module_id: string = null;
  public module_detail: string = null;

  constructor(public _Auth:AuthService,
    public AddOnService : AddOnService,
    public _activatedRoute : ActivatedRoute) {
    let UserData=this._Auth.User();
    this.user_name = UserData.user.name;
    this.user_email = UserData.user.email;
    this.module_id = this._activatedRoute.snapshot.paramMap.get('module_id');
    this.getModuleDetail();
   }


  ngOnInit() {
  }

  getModuleDetail(){
    this.AddOnService.GetModuleDetail(this.module_id).subscribe(
      result=>{
        this.module_detail = result['data'];
        
      }
    )
  }

}
