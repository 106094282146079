import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    public _http:HttpClient,
    public _TokenService:TokenService
  ) { }

  AddCandidate(data):Observable<[]> {
    let URL = `${environment.url}customer/create`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  UpdateCandidate(data):Observable<[]> {
    let URL = environment.url+'customer/edit/'+data.customer_id;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }
  GetCustomerList():Observable<[]> {
    let URL = `${environment.url}customer/name-list-alphabetically`;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  CustomerList():Observable<[]> {
    let URL = `${environment.url}customer/name-list`;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  GetCustomerById(customer_id):Observable<[]> {
    let URL = environment.url+'customer/view/'+customer_id;

    return this._http.get(URL,{
    }).pipe(map((res: any) => res));
  }

  GetStates():Observable<[]> {
    let URL = `${environment.url}address/state-list`;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  DeleteCustomer(customer_id):Observable<[]> {
    let URL = environment.url+'customer/delete/'+customer_id;

    return this._http.post(URL,customer_id,{  
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  
  }

  GetInvoiceList(customer_id):Observable<[]> {
    let URL = environment.url+'invoice/view-by-customer/'+customer_id;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }
  GetPaidInvoiceList(customer_id):Observable<[]> {
    let URL = environment.url+'invoice/paid-list-by-customer/'+customer_id;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }
  GetUnpaidInvoiceList(customer_id):Observable<[]> {
    let URL = environment.url+'invoice/unpaid-list-by-customer/'+customer_id;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  GetSearchedCustomer(data):Observable<[]> {
    let URL = environment.url+'customer/name-search/';

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  SearchedCustomer(data):Observable<[]>{
    let url = environment.url+'customer/name-search';

    return this._http.post(url,data,{  
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  
  }

  Report():Observable<[]> {
    let URL = `${environment.url}customer/customer-report`;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  InvoiceSearch(data):Observable<[]> {
    let URL = `${environment.url}invoice/search`;

    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetCustomerListOnSearch(data):Observable<[]> {
    let URL = environment.url+'customer/list-by-organization';

    return this._http.post(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }

  
  // WorkOrder
    GetWorkOrderListByCustomer(customer_id):Observable<[]> {
    let URL = environment.url+'workorder/view-by-customer/'+customer_id;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    }).pipe(map((res: any) => res));
  }
  customerAddressList():Observable<[]>{
    const URL = environment.url + 'customer-address/';
    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader()
    })
    .pipe(map((result: any) => result))
  }
  createCustomerAddress(data):Observable <[]>{
    const URL = environment.url + 'customer-address'
    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader()
    })
    .pipe(map((result: any) => result))
  }
  editCustomerAddress(data):Observable <[]>{
    
    const URL = environment.url + 'customer-address/' + data.customer_address_id
    return this._http.put(URL, data, {
      headers: this._TokenService.GetTokenByHeader()
    })
    .pipe(map((result: any) => result))
  }
  getAddressDetails(customer_address_id):Observable <[]>{
    
    const URL = environment.url + 'customer-address/' + customer_address_id
    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader()
    })
    .pipe(map((result: any) => result))
  }
  deleteAddress(customer_address_id):Observable <[]>{
    const URL = environment.url + 'customer-address/' + customer_address_id;
    return this._http.delete(URL, {
      headers: this._TokenService.GetTokenByHeader()
    })
    .pipe(map((result: any) => result))
  }
  setDefaultAddress(data):Observable <[]> {
    const URL = environment.url + 'customer-address/default-address/' + data.customer_id + '/' + data.customer_address_id;
    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader()
    })
    .pipe(map((result: any) => result))
  }


  validateCustomerCsv(file): Observable<[]>{
    let formData = new FormData();
    formData.append('documentType',file); 
    const URL = environment.url  + 'customer/csv-customer-validate';
    return this._http.post(URL,formData, {
      headers: this._TokenService.GetTokenByHeader()
    })
    .pipe(map((result:any) => result))
  }
  uploadValidCustomer(file): Observable<[]>{
    let formData = new FormData();
    formData.append('documentType',file); 
    const URL = environment.url  + 'customer/csv-customer-save';
    return this._http.post(URL,formData, {
      headers: this._TokenService.GetTokenByHeader()
    })
    .pipe(map((result:any) => result))
  }

  customerTimeline(data): Observable<[]>{
    const customerId = {
      customer_id: data.customer_id
    }
    const URL = environment.url + `customer-timeline?page=${data.page}`;
    return this._http.post(URL,customerId, {
      headers: this._TokenService.GetTokenByHeader()
    })
    .pipe(map((result:any) => result))
  }

  // Timesheet 

  timesheetDetails():Observable<[]>{
    const URL = environment.url + 'widget-service/timesheet';
    return this._http.post(URL, null, {
      headers: this._TokenService.GetTokenByHeader()
    })
    .pipe(map((result:any) => result)) 
  }

  exportCSV(data):Observable<[]> {
    data.start_date = new Date(data.start_date.getTime() - data.start_date.getTimezoneOffset() * 60000);
    data.end_date = new Date(data.end_date.getTime() - data.end_date.getTimezoneOffset() * 60000);
    
    let URL = environment.url+'quickbook/customer';
    
    return this._http.post(URL, data, {
      headers: this._TokenService.GetTokenByHeader(),
      // responseType:ResponseContentType.Blob
    })
      .pipe(map((res: any) => res));
  }
}