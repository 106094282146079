import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class TaxService {

  constructor(
    private http: HttpClient,
    public TokenService: TokenService,
  ) { }

  AddTaxRate(data):Observable<[]> {
    let URL = environment.url+'tax-rate/';

    return this.http.post(URL, data,{
      headers: this.TokenService.GetTokenByHeader(),
    })
      .pipe(map((res: any) => res));
  }
  getCityList(id):Observable <[]>{
    const URL= environment.url + 'city-list/'+id;
    return this.http.get(URL, {
      headers: this.TokenService.GetTokenByHeader()
    })
    .pipe(map((result: any) => result))
  }

  getCityListByStateName(name):Observable <[]>{
    const URL= environment.url + 'cities-by-state_name/'+name;
    return this.http.get(URL, {
      headers: this.TokenService.GetTokenByHeader()
    })
    .pipe(map((result: any) => result))
  }
  getLocationList(data):Observable <[]>{
    const URL= environment.url + 'tax-rate/search-location';
    return this.http.post(URL,data, {
      headers: this.TokenService.GetTokenByHeader()
    })
    .pipe(map((result: any) => result))
  }

}
