import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { InvoiceAllList, InvoiceModel, EmailModel, PaymentSettings } from 'src/app/shared';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { timer } from 'rxjs'
import {DomSanitizer} from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-invoice-send',
  templateUrl: './invoice-send.component.html',
  styleUrls: ['./invoice-send.component.scss']
})
export class InvoiceSendComponent implements OnInit {
  public invoice_id: any = null;
  public invoice_number: any = null;
  public item_total: number = 0;
  public discount: any = 0;
  public sales_tax: any = 0;
  public advance: any = 0;
  public amount: number = 0;
  public loading: boolean = false;
  public loading1: boolean = false;
  public loading2: boolean = false;
  public accepted1: boolean = false;
  public accepted2: boolean = false;
  public email_sent: any = null;
  public total_rate: any = null;
  // public item_name: any[] = [];
  // public rates: any[] = [];
  public invoice_items: any[] = [];
  public _invoiceData: InvoiceModel = new InvoiceModel();
  public Email: EmailModel = new EmailModel();
  public modalRef: BsModalRef;
  public transformedData: any;
  public organization_id: string = null;
  public organization_name: string = null;
  public template: any = null;
  public customer_name: string = null;
  public customer_email: string = null;
  public mobile: string = null;
  public userData:any = null;
  public env = environment; 
  public clipboard: Clipboard;
  public checklist_enabled: boolean = false;
  public enabled_modules: any[] = [];
  public InvoiceDetails: InvoiceAllList = new InvoiceAllList();
  public PaymentModel: PaymentSettings = new PaymentSettings()


  constructor(
    public _router: Router,
    private activatedRoute: ActivatedRoute,
    public _invoiceService: InvoiceService,
    public _modalService: BsModalService,
    public toastr: ToastrService,
    public Auth: AuthService,
    public SettingService: SettingService,
    private sanitizer:DomSanitizer
  ) {
      this.userData = this.Auth.User();
      this.organization_id = this.userData.user.organization_id;
      this.getOrganizationDetails(this.organization_id);
      this.invoice_id = this.activatedRoute.snapshot.paramMap.get('invoice_id');
      this.invoice_number = this.activatedRoute.snapshot.paramMap.get('invoice_index');      
      this.invoiceData(this.invoice_id);
      if ( JSON.parse( localStorage.getItem( 'activated_modules' ) ) )
      {
        this.enabled_modules = JSON.parse( localStorage.getItem( 'activated_modules' ) );
        for ( let index of this.enabled_modules )
        {
          if ( index[ 'module_id' ] == '50bf5d16f34b' && index[ 'enabled' ] == 1)
          {
            this.checklist_enabled = true;
          }
        }
      }  
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  getOrganizationDetails(organization_id) {
    this.SettingService.GetAccountDetails(organization_id).subscribe(
      result => {
        this.template = result['data']['template'];
        this.organization_name=result['data']['business_name']
        this.Email.invoice_id = this.invoice_id;
        this.Email.invoice_number = this.invoice_number;
        this.Email.template = this.template;
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    this.transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;

    return this.transformedData;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  public checklistId: string = null;
  invoiceData(invoiceID: string) {
    this.loading = true;

    this._invoiceService.InvoiceView(invoiceID)
      .subscribe((result) => {
        this.checklistId = result['data']['checklist_id']
        this.loading = false;
        let data = result['data'];
        this.getPaymentSetting(result['data'].organization_id);
        this._invoiceData.due_date = data.due_date;
        this._invoiceData.note = result['data'].note
        this._invoiceData.private_note = result['data'].private_note

        if (data['invoice_customers'] != null && data['invoice_customers'].length > 0) {
          this._invoiceData.customer_name = data['invoice_customers'][0].name;
          this._invoiceData.customer_email = data['invoice_customers'][0].email;
          this._invoiceData.customer_email = data['invoice_customers'][0].email;
          this._invoiceData.customer_phone = data['invoice_customers'][0].mobile;
          this.customer_name = this._invoiceData.customer_name;
          this.customer_email = this._invoiceData.customer_email;
          this.mobile = this._invoiceData.customer_phone;
        }

        if (data['invoice_invoiceitems'] != null 
        && data['invoice_invoiceitems'].length > 0) {
          this.invoice_items = data['invoice_invoiceitems'];
          this.item_total = result['data'].amount;
        }

        this._invoiceData.amount = data.total;
        this._invoiceData.discount = data.discount;
        this._invoiceData.discount_type = data.discount_type;
        this._invoiceData.sales_tax = data.sales_tax;
        this._invoiceData.invoice_number = data.invoice_number;
        this._invoiceData.advance = data.advance;
        this._invoiceData.advance_type = data.advance_type;
        this.InvoiceDetails.discount = result['data'].discount;
        this.InvoiceDetails.discount_type = result['data'].discount_type;
        this.InvoiceDetails.advance = result['data'].advance;
        this.InvoiceDetails.advance_type = result['data'].advance_type;
        this.InvoiceDetails.sales_tax = result['data'].sales_tax;
        this.InvoiceDetails.created_at = result['data'].created_at;
        this.InvoiceDetails.updated_at = result['data'].updated_at;

        if (this.InvoiceDetails.discount != null && this.InvoiceDetails.discount != '') {
          if (this.InvoiceDetails.discount_type == '%') {
            this.discount = (this.item_total * (+this.InvoiceDetails.discount)) / 100;
            this.amount = (+this.item_total) - this.discount;
          } else if (this.InvoiceDetails.discount_type == '$') {
            this.discount = (+this.InvoiceDetails.discount);
            this.amount = this.item_total - (+this.InvoiceDetails.discount);
          }
        }

        if (this.InvoiceDetails.sales_tax != null && this.InvoiceDetails.sales_tax != '') {
          if (this.discount == 0) {
            this.sales_tax = (this.item_total * (+this.InvoiceDetails.sales_tax)) / 100;
            this.amount = this.item_total + this.sales_tax;


          } else {
            this.sales_tax = (this.amount * (+this.InvoiceDetails.sales_tax)) / 100;
            this.amount = this.amount + this.sales_tax;
          }

        }

        if (this.InvoiceDetails.advance != null && this.InvoiceDetails.advance != '') {
          if (this.discount == 0 && this.sales_tax == 0) {

            if (this.InvoiceDetails.advance_type == '%') {
              this.advance = (this.item_total * (+this.InvoiceDetails.advance)) / 100;
            } else if (this.InvoiceDetails.advance_type == '$') {
              this.advance =  (+this.InvoiceDetails.advance);
              // this.advance = this.item_total - (+this.InvoiceDetails.advance);
            }
          } else if (this.discount == 0 && this.sales_tax != 0) {

            if (this.InvoiceDetails.advance_type == '%') {

              this.advance = (this.amount * (+this.InvoiceDetails.advance)) / 100;

            } else if (this.InvoiceDetails.advance_type == '$') {
              this.advance =  (+this.InvoiceDetails.advance);
              // this.advance = this.amount - (+this.InvoiceDetails.advance);
            }
          } else if (this.discount != 0 && this.sales_tax == 0) {

            if (this.InvoiceDetails.advance_type == '%') {
              this.advance = (this.amount * (+this.InvoiceDetails.advance)) / 100;
            } else if (this.InvoiceDetails.advance_type == '$') {
              this.advance =  (+this.InvoiceDetails.advance);
              // this.advance = this.amount - (+this.InvoiceDetails.advance);
            }
          } else {
            if (this.InvoiceDetails.advance_type == '%') {

              this.advance = (this.amount * (+this.InvoiceDetails.advance)) / 100;
            } else if (this.InvoiceDetails.advance_type == '$') {
              this.advance =  (+this.InvoiceDetails.advance);
              // this.advance = this.amount - (+this.InvoiceDetails.advance);

            }
          }
          this.discount = parseFloat(this.discount).toFixed(2);
          this.advance = parseFloat(this.advance).toFixed(2);
          this.sales_tax = parseFloat(this.sales_tax).toFixed(2);
        }
        this.discount = parseFloat(this.discount).toFixed(2);
        this.advance = parseFloat(this.advance).toFixed(2);
        this.sales_tax = parseFloat(this.sales_tax).toFixed(2);
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  deleteInvoice(invoice_id: string) {
    this._invoiceService.Delete(invoice_id).subscribe(
      (result) => {
        if (result['success']) {
          this.modalRef.hide();
          this.toastr.success('Deleted Successfully.');
          this._router.navigate([`/invoices`]);
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  SendMail() {
    this.loading1 = true;
    this.accepted1 = false;
    this._invoiceService.SendIvoiceViaEmail(this.Email).subscribe(
      result => {
        if (result['success']) {
          this.loading1 = false;
          this.accepted1 = true;
          this.showSuccess(result['msg']);
        } else {
          this.loading1 = false;
          this.accepted1 = true;
          this.showError(result['msg']);
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  SendSMS() {
    this.loading2 = true;
    this.accepted2 = false;
    this._invoiceService.SendIvoiceViaSMS(this.Email).subscribe(
      result => {
        if (result['success']) {
          this.loading2 = false;
          this.accepted2 = true;
          this.showSuccess(result['msg']);
        } else {
          this.loading2 = false;
          this.accepted2 = true;
          this.showError(result['msg']);
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  getSMSLink(){
    // return 'sms:9883840126;?&body=http://111.93.175.214/invoiceapppwa/display/template-'+this.template+'/'+this.invoice_id;
    return 'sms:9883840126;?&body= hi I m rimpal'  ;
  }

  sanitize(){
    return this.sanitizer.bypassSecurityTrustUrl('sms:'+this.mobile+';?&body=Dear '+this._invoiceData.customer_name+', %0a%0aThank you for your business. %0a%0aYour invoice '+this._invoiceData.invoice_number+' can be viewed, printed, or downloaded as a PDF file from the link below. You can also choose to pay it online. %0a%0aClick to view Invoice %0a'+this.env.frontend_url+'template-'+this.template+'/invoice/'+this.invoice_id+'  %0a%0aWe look forward to doing more business with you. %0a%0aRegards, %0a'+this.userData.user.name+' %0a'+this.organization_name);
    // return this.sanitizer.bypassSecurityTrustUrl('sms:'+this.mobile+';?&body=Dear '+this._invoiceData.customer_name+', Thank you for your business. Your invoice '+this._invoiceData.invoice_number+' can be viewed, printed, or downloaded as a PDF file from the link below. You can also choose to pay it online. Click to view Invoice http://111.93.175.214/invoiceapppwa/display/template-'+this.template+'/'+this.invoice_id+'  We look forward to doing more business with you. Regards, '+this.userData.user.name+' '+this.organization_name);
  }
  getDataUrl(template,id){
    return this.env.frontend_url+"template-"+template+'/invoice/'+id;
  }

  copy(evt: Event){
    let url: string = evt.target['dataset'].url;

    document.addEventListener('copy', function(evt) {
      evt.clipboardData.setData('text/plain', url);
      evt.preventDefault();
    }, true);

    let result = document.execCommand('copy');

    if (result) {
      this.toastr.success('Successfully Copied');
    }
    else {
      this.toastr.error('Not Copied');
    }

    // this.clipboard.writeText(url).then(() => {
    //   this.toastr.success('Successfully Copied');
    // }, () => {
    //   this.toastr.error('Not Copied');
    // });
  }

  getPaymentSetting(organization_id) {
    if (organization_id != null) {
      this.loading = true;
      this.SettingService.GetPaymentDetails(organization_id)
        .subscribe((result) => {
          this.loading = false;
          if(result['bank_details']){
            this.PaymentModel.bank_details = result['bank_details']['bank_details'];
            this.PaymentModel.checked = result['bank_details']['checked'];
            this.PaymentModel.bank_detail_id = result['bank_details']['bank_detail_id'];
          }else{
            this.PaymentModel = null;
          }          
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
    else {
      this.loading = false;
    }
  }

  showSuccess(msg: string) {
    this.toastr.success(msg);
  }
  showError(msg: string) {
    this.toastr.error(msg);
  }
}
