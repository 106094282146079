import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-quickbooks',
  templateUrl: './quickbooks.component.html',
  styleUrls: ['./quickbooks.component.scss']
})
export class QuickbooksComponent implements OnInit {
  public loading: boolean = false;
  public Payment = this.fb.group({
    key: ['', [Validators.required]],
    secret: ['', [Validators.required]]
  })
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
  }

}
