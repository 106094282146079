import { Component, OnInit } from '@angular/core';
import { Sidebar, AccountModel } from 'src/app/shared';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Router } from '@angular/router';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: "app-account-details",
  templateUrl: "./account-details.component.html",
  styleUrls: ["./account-details.component.scss"],
})
export class AccountDetailsComponent implements OnInit {
  public submitted: boolean = false;
  public validate = false;
  public success: boolean = true;
  public organization_id: any = null;
  public logo_image_name: any;
  public fileExtension: any;
  public loading: boolean = false;
  public accepted: boolean = false;
  public fileExtensionMessage: any;
  public fileExtensionError: boolean = false;
  public fileSizeMessage: any;
  public image_size: any;
  public selected_file: File;

  // public Account: FormGroup;
  public AccountData: AccountModel = new AccountModel();
 
    public Account = this.fb.group({
    business_name: ['', Validators.required],
    email: ['', [Validators.required, Validators.pattern(/^[^*-._'%=+!`#~$*?^{}&@]+([\*.]?\w+)*[^*-_'%=+!`#~$*?^{}&@]*@\w+([\.]?\w+)*(\.\w{2,10})+$/)]],
    address_line_1: [''],
    address_line_2: [''],
    logo: [''],
    password: ['', [Validators.required, Validators.minLength(8)]],
  });

  constructor(
    public _sidebar: Sidebar,
    public fb: FormBuilder,
    public _Auth: AuthService,
    public router: Router,
    public SettingService: SettingService,
    public toastr: ToastrService
  ) {
    
    let userData = this._Auth.User();
    this.organization_id = userData.user.organization_id;

    this.getAccountDetails(this.organization_id);
    (err:HttpErrorResponse) => {
      if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
        this.SettingService.LogOut();
      }
   }

    if (this.organization_id == null) {
      this.Account = this.fb.group({
        business_name: ['', Validators.required],
        email: ['', [Validators.required, Validators.pattern(/^[^*-._'%=+!`#~$*?^{}&@]+([\*.]?\w+)*[^*-_'%=+!`#~$*?^{}&@]*@\w+([\.]?\w+)*(\.\w{2,10})+$/)]],
        address_line_1: [''],
        address_line_2: [''],
        logo: [''],
        password: ['', [Validators.required, Validators.minLength(8)]],
      })
    }
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  get f() {
    return this.Account.controls;
  }

  getAccountDetails(org_id: any) {
    this.loading = true;

    this.SettingService.GetAccountDetails(org_id)
      .subscribe((result) => {
        this.loading = false;
        if (result['success']) {
          this.organization_id = result['data']['organization_id'];
          this.AccountData.organization_id = result['data']['organization_id'];
          this.AccountData.business_name = result['data']['business_name'];
          this.AccountData.email = result['data']['email'];
          this.AccountData.address_line_1 = result['data']['address_line_1'];
          this.AccountData.address_line_2 = result['data']['address_line_2'];
          this.AccountData.logo = result['data']['logo'];

          if (this.organization_id != null) {

            this.Account = this.fb.group({
              business_name: ['', Validators.required],
              email: ['', [Validators.required, Validators.pattern(/^[^*-._'%=+!`#~$*?^{}&@]+([\*.]?\w+)*[^*-_'%=+!`#~$*?^{}&@]*@\w+([\.]?\w+)*(\.\w{2,10})+$/)]],
              address_line_1: [''],
              address_line_2: [''],
              logo: [''],
            })
          }
        }
        else {
          this.loading = false;
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  onFileSelected(evt: Event) {
    // const reader = new FileReader();
    let target: any = evt.target;
    let fakeFileInput: HTMLInputElement = target.previousElementSibling;

    if (target['files'] && target['files'].length) {
      const _files = event.target['files'];
      let fileName: any = _files[0].name;

      this.image_size = _files[0].size / 1000;

      this.logo_image_name = fileName;

      this.fileExtension = this.logo_image_name.split('.').pop();
      if (this.image_size <= 4096) {
        this.fileSizeMessage = "";
        this.fileExtensionMessage = "";
        this.fileExtensionError = false;
      } else {
        this.fileSizeMessage = "Image can be maximum upto 4MB";
        this.fileExtensionError = true;
      }

      if (this.fileExtension == 'jpeg'
        || this.fileExtension == 'JPEG'
        || this.fileExtension == 'png'
        || this.fileExtension == 'PNG'
        || this.fileExtension == 'jpg'
        || this.fileExtension == 'JPG') {
        this.fileExtensionMessage = "";
        this.fileExtensionError = false;
      } else {
        this.fileExtensionMessage = "Only .jpg or .jpeg or .png images are allowed"
        this.fileExtensionError = true;
      }

      this.Account.get('logo').setValue(_files[0]);
      fakeFileInput.value = fileName;
    }
  }

  AddAccount() {
    this.loading = true;
    this.accepted = false;

    this.submitted = true;
    
    if (this.Account.invalid) {
      this.loading = false;
      this.accepted = true;
      return;
    }
    this.SettingService.AddAccount(this.Account.value, this.selected_file, this.organization_id).subscribe(
      result => {
        if (result['success']) {
          localStorage.setItem("account_details", JSON.stringify(result));
          this.loading = false;
          this.accepted = true;
          this.showSuccess(result['msg']);
          this.router.navigate(['/settings']);
        } else {
          this.loading = false;
          this.accepted = true;
          this.showDanger(result['msg']);

        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  showSuccess(msg: string) {
    this.toastr.success(msg);
  }

  showDanger(msg: string) {
    this.toastr.error(msg);
  }
}
