import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Sidebar } from 'src/app/shared';
import { ScheduleService } from 'src/app/shared/services/schedule/schedule.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';

@Component({
  selector: 'app-my-schedule',
  templateUrl: './my-schedule.component.html',
  styleUrls: ['./my-schedule.component.scss']
})
export class MyScheduleComponent implements OnInit {
  public my_schedule_list: any = [];
  public loading: boolean = true;
  constructor(
    public _sidebar: Sidebar,
    public ScheduleService: ScheduleService,
    public SettingService: SettingService
  ) {
    this.getMyScheduleList();
   }

  ngOnInit() {
    window.scroll(0, 0);
  }

  getMyScheduleList(){
    this.ScheduleService.myScheduleList().subscribe(
      result=>{
        if(result['success']){
          this.my_schedule_list = result['data'];        
        }
        this.loading = false;
      }, (err: HttpErrorResponse) => {
          this.loading = false;

        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      }

    )
  }

}
