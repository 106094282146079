import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class WorkOrderModel {
  public work_order_id: string = null;
  public name: string = null;
  public customer_id: string = null;
  public items: any[] = [];
  public rate: any = null;
  public line_item: string = null;
  public discount: any = null;
  public discount_type: string = null;
  public sales_tax: any = null;
  public due_date: any = null;
  public created_at: any = null;
  public updated_at: any = null;
  public advance: any = null;
  public advance_type: string = null;
  public quantity: any = null;
  public note: string = null;
  public private_note: string = null;
  public amount: any = null;
  public total: any = null;
  public work_order_number: any = null;
  public customer_name: string = null;
  public customer_phone: any = null;
  public customer_email: string = null;
  public is_signed: any;
  public assign_to: any =null;
  public assign_to_email: any =null;
  public is_approved_estimate: any =null;
  public is_approved_invoice: any =null;
  public invoice_id: any =null;
  public estimate_id: any =null;
  public start_date: any =null;
  public end_date: any =null;
  public new_date_range: any =null;
  public checklist_id: string = null;
  public alternative_number: any[] = [];
}