import { Component, OnInit } from '@angular/core';
import { Sidebar, InvoiceAllList, Search } from 'src/app/shared';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { HomeService } from 'src/app/shared/services/home/home.service';
import { ItemService } from 'src/app/shared/services/item/item.service';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { FormBuilder, Validators } from '@angular/forms';
import { timer } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { AddOnService } from 'src/app/shared/services/add-on/add-on.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public invoices: any[] = [0, 1, 2, 3, 4, 5];
  public enabled_modules: any[] = [];
  public paid_amount: any = null;
  public service_agreement_enabled: boolean = false;
  public work_order_enabled: boolean = false;
  public scheduler_enabled: boolean = false;
  public checklist_enabled: boolean = false;
  public loading1: boolean = false;
  public accepted1: boolean = false;
  public loading2: boolean = false;
  public accepted2: boolean = false;
  public paid_loader: boolean = true;
  public un_paid_loader: boolean = true;

  public paid_number: any = null;
  public unpaid_amount: any = 0;
  public unpaid_number: any = null;
  public invoice_list: any = null;
  public submitted: boolean = false;

  public invoiceAllData: InvoiceAllList[] = [];
  public searchData: Search[] = [];
  public response: boolean = true;
  public paidAmountLoader: boolean = false;
  public unpaidInvoiceLoader: boolean = false;
  public user_data: any;

  public Search = this.fb.group({
    search: ['', Validators.required]
  })

  constructor(
    public _sidebar: Sidebar,
    public _Auth: AuthService,
    public HomeService: HomeService,
    public ItemService: ItemService,
    public SettingService: SettingService,
    public _invoiceService: InvoiceService,
    public fb: FormBuilder,
    public AddOnService: AddOnService
  ) {
    this.getPaidCount();
    this.getUnPaidCount();
    this.getPaidAmount();
    this.getUnPaidAmount();
    this.getLatestList();
    this.getActivatedAddOnList();
    if(JSON.parse(localStorage.getItem('activated_modules'))){
      this.enabled_modules = JSON.parse(localStorage.getItem('activated_modules'));
      for(let index of this.enabled_modules){
        
        if(index['module_id']=='5ece4797eaf5e' && index['enabled']){
          this.service_agreement_enabled = true;
        }else if(index['module_id']=='606f1bc66d62b' && index['enabled']){
          this.work_order_enabled = true;
        }
      }
    }else{
      this.getActivatedAddOnList();
    }
    this.user_data = this._Auth.User().user.super_admin;
    
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  clearField() {
    this.Search.get('search').setValue('');
    this.searchData = [];
  }

  getPaidCount() {
    this.ItemService.GetPaidCount().subscribe(
      result => {
        if (result['success']) {
          this.paid_number = result['data'];
          this.paid_loader = false;
        }}, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
      }
    )
  }

  getUnPaidCount() {
    this.ItemService.GetUnPaidCount().subscribe(
      result => {
        if (result['success']) {
          this.unpaid_number = result['data'];
          this.un_paid_loader = false;

        }
      }
    )
  }

  getPaidAmount() {
    this.paidAmountLoader = true;
    this.ItemService.GetPaidAmount().subscribe(
      result => {
        if (result['success']) {
          this.paid_amount = result['data'];
        }
        this.paidAmountLoader = false;
      }
    )
  }

  getUnPaidAmount() {
    this.unpaidInvoiceLoader = true;
    this.ItemService.GetUnPaidAmount().subscribe(
      result => {
        if (result['success']) {
          this.unpaid_amount = result['data'];          
        }
        this.unpaidInvoiceLoader = false;
      }
    )
  }

  getLatestList() {
    this._invoiceService.InvoiceLatestList()
      .subscribe((result) => {
        let invoices: any[] = [];
        invoices = result['data'];

        for (let index of invoices) {

          let _invoiceAllData = new InvoiceAllList();

          _invoiceAllData.customer_id = index['customer_id'];
          _invoiceAllData.invoice_id = index['invoice_id'];
          _invoiceAllData.item_id = index['line_item'];
          _invoiceAllData.amount = index['amount'];
          _invoiceAllData.total = index['total'];
          _invoiceAllData.invoice_number = index['invoice_number'];

          if (index['invoice_customers'] !== null && index['invoice_customers'].length > 0) {
            _invoiceAllData.customer_name = index['invoice_customers'][0]['name'];
          }
          else {
            _invoiceAllData.customer_name = 'Not Available';
          }

          if (index['status'] !== null) {
            switch (index['status']) {
              case 2:
                _invoiceAllData.status = 'Paid';
                break;
              case 3:
                _invoiceAllData.status = 'Unpaid';
                break;
              default:
                _invoiceAllData.status = 'N/A';
                break;
            }
          }
          else {
            _invoiceAllData.status = 'N/A';
          }
          if (index['invoice_items'] !== null && index['invoice_items'].length > 0) {
            _invoiceAllData.item_name = index['invoice_items'][0]['name'];
            _invoiceAllData.rate = index['invoice_items'][0]['rate'];
          }
          this.invoiceAllData.push(_invoiceAllData);
        }
      });
  }

  get f() {
    return this.Search.controls;
  }

  Searching() {
    
    this._invoiceService.GetSearchedResult(this.Search.value).subscribe(
      result => {
        let data = result['data'];
        let _data = [];
        if (data.length == 0) {
          this.response = false;
        }
        else {
          this.response = true;
        }

        for (let index of data) {
          let _searchResult = new Search();

          if (index['Type'] == 'Customer') {
            _searchResult.type = index['Type'];
            _searchResult.customer_id = index['customer_id'];
            _searchResult.customer_name = index['name'];
            _searchResult.address = index['address'];
            _searchResult.email = index['email'];
          }
          else if (index['Type'] == 'Item') {
            _searchResult.type = index['Type'];
            _searchResult.item_id = index['item_id'];
            _searchResult.item_name = index['name'];
            _searchResult.rate = index['rate'];
          }
          _data.push(_searchResult);
        }
        this.searchData = _data;
      }
    ) 
  }

  ClearInput() {
    if (this.Search.value.search) {
      this.Searching();  
    }else{
      
    }
    

  }

  getActivatedAddOnList(){
    this.AddOnService.GetActivatedAddOnList().subscribe(
      result=>{
        this.enabled_modules = result['data'];
        localStorage.setItem('activated_modules', JSON.stringify(result['data']))
        for(let index of this.enabled_modules){
          
          if(index['module_id']=='5ece4797eaf5e' && index['enabled']){
            this.service_agreement_enabled = true;
          }else if(index['module_id']=='606f1bc66d62b' && index['enabled']){
            this.work_order_enabled = true;
          }else if(index['module_id']=='544e74cc30175' && index['enabled']){
            this.scheduler_enabled = true;
          }else if(index['module_id']=='50bf5d16f34b' && index['enabled'] == 1){
            this.checklist_enabled = true;
          }
        }
      }
    )
  }

  Clear(){
    this.Search.reset();
  }

  invoice(){
    this.loading2 = true;
    this.accepted2 = false;
  }
}
