import { Injectable } from "@angular/core";
import { BaseModel } from "../base/base.model";

@Injectable({
  providedIn: 'root'
})
export class CustomerAddress extends BaseModel {
  public address_type: string = null;
  public address: string = null;
  public state: string = null;
  public zip: string = null;
  public customer_id: string = null;
  public customer_address_id: string = null;
  public id: string | number = null;
}
