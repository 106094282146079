import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  styles: [
    `
    :host >>> canvas {
      width: 100%;
      border: 1px dashed #4f5d6b;
      border-radius: 4px;
    }
    `
  ]
})
export class ResetPasswordComponent implements OnInit {

  public isOn1: boolean = true;
  public isOn2: boolean = true;
  public loading: boolean = false;
  public accepted: boolean = false;
  public submitted: boolean = false;

  public ResetPassword =this.fb.group({
    email: [''],
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
      ]
    ],
    confirm_password: [''],
    }, 
    {
      validator: this.mustMatch('password', 'confirm_password'),
    }
  );

  constructor(
    public modalRef: BsModalRef,
    public fb: FormBuilder,
    public toastr: ToastrService,
    public _Auth:AuthService,
    public router: Router
  ) { 
    let email= this._Auth.getResetEmail();
    this.ResetPassword.get('email').setValue(email);
    this.ResetPassword.get('email').updateValueAndValidity();
  }

  ngOnInit() {
  }

  get f() {
    return this.ResetPassword.controls;
  }

  mustMatch(password: string, confirm_password: string) {
    return (ResetPassword: FormGroup) => {
      const control = ResetPassword.controls[password];
      const matchingControl = ResetPassword.controls[confirm_password];

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  Reset(){

    this.loading = true;
    this.accepted = false;
    if ((this.ResetPassword.controls.password.invalid) || (this.ResetPassword.controls.confirm_password.invalid)) {
      this.submitted = true;
      this.loading = false;
      this.accepted = false;
      return;
    }
    this._Auth.ResetPassword(this.ResetPassword.value).subscribe(
      result=>{
        if(result['success']){
          this.loading = false;
          this.accepted = false;
          this.toastr.success(result['msg']);
          this.modalRef.hide();
          this.router.navigate(['/']);
          
        }else if(result['success']==false){
          this.loading = false;
          this.accepted = false;
          this.toastr.error(result['msg']);

        }
      }
    )
  }


}
