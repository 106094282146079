import { Component, OnInit, TemplateRef } from '@angular/core';
import { ItemService } from 'src/app/shared/services/item/item.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ItemModel } from 'src/app/shared';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-item-self',
  templateUrl: './item-self.component.html',
  styleUrls: ['./item-self.component.scss']
})
export class ItemSelfComponent implements OnInit {
  public loading: boolean = false;
  public item_id: string = null;
  public modalRef: BsModalRef;
  public ItemDetails: ItemModel = new ItemModel();
  public enabled_modules: any[] = [];
  public service_agreement_enabled: boolean = false;
  public work_order_enabled: boolean = false;

  constructor(
    public activateRoute: ActivatedRoute,
    public ItemService: ItemService,
    public _modalService: BsModalService,
    public toastr: ToastrService,
    public router: Router,
    public SettingService:SettingService
  ) {
    this.item_id = this.activateRoute.snapshot.paramMap.get('item_id');
    this.getItemDetails(this.item_id);
    if (JSON.parse(localStorage.getItem('activated_modules'))) {
      this.enabled_modules = JSON.parse(localStorage.getItem('activated_modules'));
      for (let index of this.enabled_modules) {

        if (index['module_id'] == '5ece4797eaf5e' && index['enabled']) {
          this.service_agreement_enabled = true;
        } else if (index['module_id'] == '606f1bc66d62b' && index['enabled']) {
          this.work_order_enabled = true;
        }
      }
    }
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  getItemDetails(item_id) {
    if (this.item_id != null) {
      this.loading = true;
      this.ItemService.GetItemById(this.item_id)
        .subscribe((result) => {
          this.loading = false;

          this.ItemDetails.name = result['data'].name;
          this.ItemDetails.item_number = result['data'].item_number;
          this.ItemDetails.description = (result['data'].description);
          this.ItemDetails.quantity = result['data'].quantity;
          this.ItemDetails.video_url = result['data'].video_url;
          this.ItemDetails.rate = result['data'].rate;
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
    else {
      this.loading = false;
    }
  }

  deleteItem() {
    if (this.item_id != null) {
      this.ItemService.Delete(this.item_id).subscribe(
        result => {
          if (result['success']) {
            this.modalRef.hide();

            this.showSuccess(result['msg']);
            this.router.navigate(['/products']);
          } else {
            this.showDanger(result['msg']);
          }
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

  NavigateToInvoiceWithProduct(){
    let data = {
      group: "Product",
      id: this.item_id,
    }    
    localStorage.setItem('product',JSON.stringify(data))
    this.router.navigate([`/new-invoice/1/${this.item_id}/Product`])
  }

  NavigateToEstimateWithProduct(){
    let data = {
      group: "Product",
      id: this.item_id,
    }
    localStorage.setItem('product', JSON.stringify(data))
    this.router.navigate([`/estimate-new/1/${this.item_id}/Product`])
  }
  NavigateToWorkOrderWithProduct() {
    let data = {
      group: "Product",
      id: this.item_id,
    }
    localStorage.setItem('product', JSON.stringify(data))
    this.router.navigate([`/work-order-new/1/${this.item_id}/Product`])
  }

  NavigateToAgreementWithProduct() {
    let data = {
      group: "Product",
      id: this.item_id,
    }
    localStorage.setItem('product', JSON.stringify(data))
    this.router.navigate([`/create-agreement/${this.item_id}/Product`])
  }

  showSuccess(msg: string) {
    this.toastr.success(msg);
  }

  showDanger(msg: string) {
    this.toastr.error(msg);
  }


}
