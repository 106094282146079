import {
  Component,
  OnInit,
  TemplateRef
} from '@angular/core';
import {
  Sidebar,
  InvoiceAllList
} from 'src/app/shared';
import {
  InvoiceService
} from 'src/app/shared/services/invoice/invoice.service';
import {
  InvoicePaidList
} from 'src/app/shared/models/invoice/invoice-list-paid.model';
import {
  InvoiceUnpaidList
} from 'src/app/shared/models/invoice/invoice-list-unpaid.model';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  CustomerService
} from 'src/app/shared/services/customer/customer.service';
import {
  SettingService
} from 'src/app/shared/services/setting/setting.service';
import {
  HttpErrorResponse
} from '@angular/common/http';
import {
  FormBuilder,
  Validators
} from '@angular/forms';
import {
  ToastrService
} from 'ngx-toastr';
import {
  WorkOrderService
} from 'src/app/shared/services/work-order/work-order.service';
import { ItemService } from 'src/app/shared/services/item/item.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  styles: [
    `
    /* :host >>> .nav-tabs > .invoices__tab-pane:nth-child(1) > .nav-link {
      color: #d00 !important;
    }

    :host >>> .nav-tabs > .invoices__tab-pane:nth-child(2) > .nav-link {
      color: #00b118 !important;
    } */

    :host >>> .nav-tabs .nav-link {
      border: 0;
      border-bottom: 2px solid #ddd;
      border-radius: 0;
      color: #79848c;
      font-weight: 600;
      background-color: transparent !important;
    }

    :host >>> .nav-tabs .nav-link.active {
      color: #000 !important;
      border-bottom: 2px solid #e49037 !important;
    }
    `
  ],
})
export class InvoicesComponent implements OnInit {


  public invoiceAllData: InvoiceAllList[] = [];
  public invoicePaidData: InvoicePaidList[] = [];
  public invoiceUnpaidData: InvoiceUnpaidList[] = [];
  public customer_id: string = null;
  public current_tab: any = 'ALL';
  public zip_url: string = null;
  public loading: boolean = false;
  public Search = this.fb.group({
    invoice_number: ['', Validators.required],
  })
  public modalRef: BsModalRef;
  public submitted: boolean = false;
  public minStartDate: any = null;

  constructor(
    public _sidebar: Sidebar,
    public _invoiceService: InvoiceService,
    public activatedRoute: ActivatedRoute,
    public CustomerService: CustomerService,
    public SettingService: SettingService,
    public WorkOrderService: WorkOrderService,
    public toastr: ToastrService,
    public fb: FormBuilder,
    public router: Router,
    public ItemService: ItemService,
    public _modalService: BsModalService
  ) {
    this.customer_id = this.activatedRoute.snapshot.paramMap.get('customer_id');
    if (this.customer_id != null) {
      this.getCustomerInvoiceList(this.customer_id);
      this.getPaidCustomerInvoiceList(this.customer_id);
      this.getUnPaidCustomerInvoiceList(this.customer_id);
    }

  }

  ngOnInit() {
    if (this.customer_id == null) {
      this.GetInvoiceList();
      this.GetPaidInvoiceList();
      this.GetUnpaidInvoiceList();
    }

    window.scroll(0, 0);
    // this.minStartDate = new Date();
    // this.minStartDate.setDate(this.minStartDate.getDate());
  }

  getCustomerInvoiceList(customer_id) {
    if (customer_id != null) {
      this.CustomerService.GetInvoiceList(customer_id).subscribe(
        result => {
          if (result['success']) {
            let invoices: any[] = [];
            invoices = result['data'];

            for (let index of invoices) {

              let _invoiceAllData = new InvoiceAllList();
              _invoiceAllData.rate = index['amount'];
              _invoiceAllData.customer_id = index['customer_id'];
              _invoiceAllData.invoice_id = index['invoice_id'];
              _invoiceAllData.item_id = index['line_item'];
              _invoiceAllData.invoice_number = index['invoice_number'];
              _invoiceAllData.total = index['total'];

              if (index['invoice_customers'] !== null && index['invoice_customers'].length > 0) {
                _invoiceAllData.customer_name = index['invoice_customers'][0]['name'];
              } else {
                _invoiceAllData.customer_name = 'Not Available';
              }

              if (index['status'] !== null) {
                switch (index['status']) {
                  case 2:
                    _invoiceAllData.status = 'Paid';
                    break;
                  case 3:
                    _invoiceAllData.status = 'Unpaid';
                    break;
                  default:
                    _invoiceAllData.status = 'N/A';
                    break;
                }
              } else {
                _invoiceAllData.status = 'N/A';
              }

              // _invoiceAllData.item_name = index['invoice_items'][0]['name'];
              // _invoiceAllData.rate = index['invoice_items'][0]['rate'];

              this.invoiceAllData.push(_invoiceAllData);

            }
          } else {
            this.loading = false;
            this.invoiceAllData = null;
          }
        }, (err: HttpErrorResponse) => {
          if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
            this.SettingService.LogOut();
          }
        }
      );
    }
  }

  getUnPaidCustomerInvoiceList(customer_id) {
    this.CustomerService.GetUnpaidInvoiceList(customer_id)
      .subscribe((result) => {
        let unpaidInvoice = result['data'];

        for (let index of unpaidInvoice) {
          let _invoiceUnpaidData = new InvoiceUnpaidList();

          _invoiceUnpaidData.customer_id = index['customer_id'];
          _invoiceUnpaidData.invoice_id = index['invoice_id'];
          _invoiceUnpaidData.item_id = index['line_item'];
          _invoiceUnpaidData.amount = index['amount'];
          _invoiceUnpaidData.total = index['total'];
          _invoiceUnpaidData.invoice_number = index['invoice_number'];


          if (index['invoice_customers'] !== null && index['invoice_customers'].length > 0) {
            _invoiceUnpaidData.customer_name = index['invoice_customers'][0]['name'];
          } else {
            _invoiceUnpaidData.customer_name = 'Not Available';
          }
          if (index['invoice_items'] !== null && index['invoice_items'].length > 0) {
            _invoiceUnpaidData.item_name = index['invoice_items'][0]['name'];
            _invoiceUnpaidData.status = 'Unpaid';
          }
          this.invoiceUnpaidData.push(_invoiceUnpaidData);
        }
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      })
  }

  getPaidCustomerInvoiceList(customer_id) {
    this.CustomerService.GetPaidInvoiceList(customer_id)
      .subscribe((result) => {
        let paidInvoice = result['data'];

        for (let index of paidInvoice) {
          let _invoicePaidData = new InvoicePaidList();

          _invoicePaidData.customer_id = index['customer_id'];
          _invoicePaidData.invoice_id = index['invoice_id'];
          _invoicePaidData.item_id = index['line_item'];
          _invoicePaidData.amount = index['amount'];
          _invoicePaidData.total = index['total'];
          _invoicePaidData.invoice_number = index['invoice_number'];
          if (index.status == 2) {
            _invoicePaidData.status = 'Paid';

          }



          if (index['invoice_customers'] !== null && index['invoice_customers'].length > 0) {
            _invoicePaidData.customer_name = index['invoice_customers'][0]['name'];
          } else {
            _invoicePaidData.customer_name = 'Not Available';
          }
          if (index['invoice_items'] !== null && index['invoice_items'].length > 0) {
            _invoicePaidData.item_name = index['invoice_items'][0]['name'];
          }
          this.invoicePaidData.push(_invoicePaidData);
        }
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      })
  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    let transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;
    return transformedData;
  }

  GetInvoiceList() {
    this.loading = true;
    this.invoiceAllData = [];
    this._invoiceService.InvoiceList()
      .subscribe((result) => {
        let invoices: any[] = [];
        if(result['success']){
          invoices = result['data'];
          this.loading = false;
          for (let index of invoices) {
            let data:any = null
            let _invoiceAllData = new InvoiceAllList();

            _invoiceAllData.customer_id = index['customer_id'];
            _invoiceAllData.invoice_id = index['invoice_id'];
            _invoiceAllData.item_id = index['line_item'];
            _invoiceAllData.amount = index['amount'];
            _invoiceAllData.total = index['total'];
            _invoiceAllData.invoice_number = index['invoice_number'];
            if (index['invoice_customers'] !== null && index['invoice_customers'].length > 0) {
              _invoiceAllData.customer_name = index['invoice_customers'][0]['name'];
            } else {
              _invoiceAllData.customer_name = 'Not Available';
            }

            if (index['status'] !== null) {
              switch (index['status']) {
                case 2:
                  _invoiceAllData.status = 'Paid';
                  break;
                case 3:
                  _invoiceAllData.status = 'Unpaid';
                  break;
                default:
                  _invoiceAllData.status = 'N/A';
                  break;
              }
            } else {
              _invoiceAllData.status = 'N/A';
            }
            // if (index['invoice_invoiceitems'] !== null && index['invoice_invoiceitems'].length > 0) {
            //   _invoiceAllData.item_name = index['invoice_items'][0]['name'];
            //   _invoiceAllData.rate = index['invoice_items'][0]['rate'];
            // }
            
            this.invoiceAllData.push(_invoiceAllData);

          }
        }else{
          this.loading = false;
        }
        
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      });
  }

  GetPaidInvoiceList() {
    this.invoicePaidData = [];
    this._invoiceService.InvoicePaidList()
      .subscribe((result) => {
        if(result['success']){
          let paidInvoice = result['data'];

          for (let index of paidInvoice) {
            let _invoicePaidData = new InvoicePaidList();

            _invoicePaidData.customer_id = index['customer_id'];
            _invoicePaidData.invoice_id = index['invoice_id'];
            _invoicePaidData.item_id = index['line_item'];
            _invoicePaidData.amount = index['amount'];
            _invoicePaidData.total = index['total'];
            _invoicePaidData.invoice_number = index['invoice_number'];
            if (index.status == 2) {
              _invoicePaidData.status = 'Paid';
            } else {

            }
            if (index['invoice_customers'] !== null && index['invoice_customers'].length > 0) {
              _invoicePaidData.customer_name = index['invoice_customers'][0]['name'];
            }else{
              _invoicePaidData.customer_name = 'Not Available';
            }
            // if (index['invoice_items'] !== null && index['invoice_items'].length > 0) {
            //   _invoicePaidData.item_name = index['invoice_items'][0]['name'];
            //   _invoicePaidData.status = 'Paid';
            // }          
            let data = _invoicePaidData;
            this.invoicePaidData.push(data);

          }        
        }
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      })
  }

  GetUnpaidInvoiceList() {
    this.invoiceUnpaidData = [];
    this._invoiceService.InvoiceUnpaidList()
      .subscribe((result) => {
        if(result['success']){
          let unpaidInvoice = result['data'];

        for (let index of unpaidInvoice) {
          let _invoiceUnpaidData = new InvoiceUnpaidList();

          _invoiceUnpaidData.customer_id = index['customer_id'];
          _invoiceUnpaidData.invoice_id = index['invoice_id'];
          _invoiceUnpaidData.item_id = index['line_item'];
          _invoiceUnpaidData.amount = index['amount'];
          _invoiceUnpaidData.total = index['total'];
          _invoiceUnpaidData.invoice_number = index['invoice_number'];
          if (index.status == 3) {
            _invoiceUnpaidData.status = 'Unpaid';

          }



          if (index['invoice_customers'] !== null && index['invoice_customers'].length > 0) {
            _invoiceUnpaidData.customer_name = index['invoice_customers'][0]['name'];
          } else {
            _invoiceUnpaidData.customer_name = 'Not Available';
          }
          // if (index['invoice_items'] !== null && index['invoice_items'].length > 0) {
          //   _invoiceUnpaidData.item_name = index['invoice_items'][0]['name'];
          // }
          this.invoiceUnpaidData.push(_invoiceUnpaidData);
        }
        }
      }, (err: HttpErrorResponse) => {
        this.loading = false;

        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      })
  }

  Clear() {
    this.Search.get('invoice_number').setValue('');
    this.Search.updateValueAndValidity();
    this.GetInvoiceList();
    this.GetPaidInvoiceList();
    this.GetUnpaidInvoiceList();

  }
  ClearInput() {
    if (!this.Search.value.invoice_number) {
        this.GetInvoiceList();
        this.GetPaidInvoiceList();
        this.GetUnpaidInvoiceList();
    } else {
      this.SearchInvoice();
    }

  }

  SearchInvoice() {

    if (this.Search.invalid) {
      this.toastr.error('Please provide invoice number');
      return;
    }
    this.CustomerService.InvoiceSearch(this.Search.value).subscribe(
      result => {
        if (result['success']) {
          let invoices: any[] = [];
          this.invoiceAllData = [];

          invoices = result['data'];
          this.loading = false;
          for (let index of invoices) {
            let _invoiceAllData = new InvoiceAllList();

            _invoiceAllData.customer_id = index['customer_id'];
            _invoiceAllData.invoice_id = index['invoice_id'];
            _invoiceAllData.item_id = index['line_item'];
            _invoiceAllData.amount = index['amount'];
            _invoiceAllData.total = index['total'];
            _invoiceAllData.invoice_number = index['invoice_number'];



            if (index['invoice_customers'] !== null && index['invoice_customers'].length > 0) {
              _invoiceAllData.customer_name = index['invoice_customers'][0]['name'];
            } else {
              _invoiceAllData.customer_name = 'Not Available';
            }

            if (index['status'] !== null) {
              switch (index['status']) {
                case 2:
                  _invoiceAllData.status = 'Paid';
                  break;
                case 3:
                  _invoiceAllData.status = 'Unpaid';
                  break;
                default:
                  _invoiceAllData.status = 'N/A';
                  break;
              }
            } else {
              _invoiceAllData.status = 'N/A';
            }
            // if (index['invoice_invoiceitems'] !== null && index['invoice_invoiceitems'].length > 0) {
            //   _invoiceAllData.item_name = index['invoice_items'][0]['name'];
            //   _invoiceAllData.rate = index['invoice_items'][0]['rate'];
            // }

            this.invoiceAllData.push(_invoiceAllData);
          }

          this.invoicePaidData = [];
          let paidInvoice = result['data'];

          for (let index of paidInvoice) {
            if (index.status == 2) {
              let _invoicePaidData = new InvoicePaidList();

              _invoicePaidData.customer_id = index['customer_id'];
              _invoicePaidData.invoice_id = index['invoice_id'];
              _invoicePaidData.item_id = index['line_item'];
              _invoicePaidData.amount = index['amount'];
              _invoicePaidData.total = index['total'];
              _invoicePaidData.invoice_number = index['invoice_number'];
              if (index.status == 2) {
                _invoicePaidData.status = 'Paid';

              }
              if (index['invoice_customers'] !== null && index['invoice_customers'].length > 0) {
                _invoicePaidData.customer_name = index['invoice_customers'][0]['name'];
              } else {
                _invoicePaidData.customer_name = 'Not Available';
              }
              // if (index['invoice_items'] !== null && index['invoice_items'].length > 0) {
              //   _invoicePaidData.item_name = index['invoice_items'][0]['name'];
              //   _invoicePaidData.status = 'Paid';
              // }
              this.invoicePaidData.push(_invoicePaidData);

            }


            this.invoiceUnpaidData = [];

            let unpaidInvoice = result['data'];

            for (let index of unpaidInvoice) {
              if (index.status == 3) {
                let _invoiceUnpaidData = new InvoiceUnpaidList();

                _invoiceUnpaidData.customer_id = index['customer_id'];
                _invoiceUnpaidData.invoice_id = index['invoice_id'];
                _invoiceUnpaidData.item_id = index['line_item'];
                _invoiceUnpaidData.amount = index['amount'];
                _invoiceUnpaidData.total = index['total'];
                _invoiceUnpaidData.invoice_number = index['invoice_number'];
                if (index.status == 3) {
                  _invoiceUnpaidData.status = 'Unpaid';

                }



                if (index['invoice_customers'] !== null && index['invoice_customers'].length > 0) {
                  _invoiceUnpaidData.customer_name = index['invoice_customers'][0]['name'];
                } else {
                  _invoiceUnpaidData.customer_name = 'Not Available';
                }
                // if (index['invoice_items'] !== null && index['invoice_items'].length > 0) {
                //   _invoiceUnpaidData.item_name = index['invoice_items'][0]['name'];
                // }
                this.invoiceUnpaidData.push(_invoiceUnpaidData);
              } else {
                this.invoiceUnpaidData = [];
              }

            }
          }

        } else {
          // this.toastr.warning(result['msg']);
          this.loading = false;
          this.invoiceAllData = [];
          this.invoicePaidData = [];
          this.invoiceUnpaidData = [];
        }
      })
  }

  CurrentTab(current_tab) {
    this.current_tab = current_tab;
  }

  NavigateToAddInvoice() {
    this.WorkOrderService.ApproveInvoice(null);
    this.router.navigate(['/new-invoice/1']);
  }

  public downloadCSVform = this.fb.group({
    start_date: ["", Validators.required],
    end_date: [""]
  });

  get csvForm() {
    return this.downloadCSVform.controls;
  }

  exportCSV(template: TemplateRef<any>) {
    this.submitted = false;
    this.modalRef = this._modalService.show(template);
    this.downloadCSVform.get('start_date').setValue('');
  }

  exportInvoiceCSV(){
    this.submitted  = true;
    if(this.downloadCSVform.invalid){
      return;
    }
    let data = {
      end_date: this.downloadCSVform.get('start_date').value[1],
      start_date: this.downloadCSVform.get('start_date').value[0]
    }
    // this.loading = true;
    this._invoiceService.exportCSV(data)
      .subscribe((result) => {
        this.submitted = false;
        if (result['success']) {
          this.zip_url = "http://128.199.25.224/invoice-app-server/public/invoices/invoices.zip";
          const link = document.createElement('a');
          link.href =  this.zip_url ;
          link.download = this.zip_url ;
          link.click();
          this.modalRef.hide();
        }
      }
      , (err:HttpErrorResponse) => {
        this.loading = false;
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }
}
