import { Component, OnInit } from '@angular/core';
import { Sidebar } from 'src/app/shared';
import { UserService } from 'src/app/shared/services/user/user.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: "app-business-profile",
  templateUrl: "./business-profile.component.html",
  styleUrls: ["./business-profile.component.scss"],
})
export class BusinessProfileComponent implements OnInit {
  public loading: boolean = false;
  public userlist: any[] = [];
  public active_users: any = 0;
  public allactive_users: any = 0;
  public user_data: any = 0;
  constructor(
    public _sidebar: Sidebar,
    public UserService: UserService,
    public AuthService: AuthService,
    public SettingService:SettingService) {
    this.user_data = this.AuthService.User().user;
    this.getUserList();
    this.getActiveUsers();
    this.getAllActiveUsers();
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  getUserList() {
    this.loading = true;
    this.UserService.UserList()
      .subscribe((result) => {
        if (result['success']) {
          this.loading = false;
          this.userlist = result['data'];
        }
        else {
          this.loading = false;
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  getActiveUsers() {
    this.UserService.ActiveUsers().subscribe(
      result => {
        this.active_users = result['data'];
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }
  getAllActiveUsers() {
    this.UserService.AllActiveUsers().subscribe(
      result => {
        this.allactive_users = result['data'];
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }
}
