import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token/token.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    public _http:HttpClient,
    public _TokenService:TokenService
  ) {

   }

   GetPaidCount():Observable<[]> {
    let URL = `${environment.url}invoice/paid-count`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }

  GetUnPaidCount():Observable<[]> {
    let URL = `${environment.url}invoice/unpaid-count`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }
  GetPaidAmount():Observable<[]> {
    let URL = `${environment.url}invoice/paid-amount`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }
  GetUnPaidAmount():Observable<[]> {
    let URL = `${environment.url}invoice/unpaid-amount`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }
  GetPaidList():Observable<[]> {
    let URL = `${environment.url}invoice/list`;

    return this._http.get(URL, {
      headers: this._TokenService.GetTokenByHeader(),

    })
      .pipe(map((res: any) => res));
  }
}
