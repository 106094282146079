import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { CustomerService } from "src/app/shared/services/customer/customer.service";
import { CustomerModel } from "src/app/shared";
import { ToastrService } from "ngx-toastr";
import { SettingService } from "src/app/shared/services/setting/setting.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-customer-new",
  templateUrl: "./customer-new.component.html",
  styleUrls: ["./customer-new.component.scss"],
})
export class CustomerNewComponent implements OnInit {
  public loading: boolean = false;
  public step: number = 1;
  public submitted: boolean = null;
  public submitted2: boolean = null;
  public customer_name: string = null;
  public customer_mobile: number = null;
  public customer_email: string = null;
  public customer_id: string = null;
  public states: any[] = [];
  public alternative_number: any[] = [];
  public CustomerList: any = null;
  public custom_phone_vaidation: string = "";
  public addCustomerLoader = false;

  public NewCustomer = this.fb.group({
    name: ["", Validators.required],
    email: [
      "",
      [
        Validators.required,
        Validators.pattern(/^[^*-._'%=+!`#~$*?^{}&@]+([\*.]?\w+)*[^*-_'%=+!`#~$*?^{}&@]*@\w+([\.]?\w+)*(\.\w{2,10})+$/),
      ],
    ],
    mobile: ["", [Validators.required]],
    address_type: ["", [Validators.required]],
    address: ["", Validators.required],
    locality: ["", Validators.required],
    zip: ["", [Validators.required, Validators.maxLength(15), Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]],
  });

  constructor(
    public _router: Router,
    public fb: FormBuilder,
    public CustomerService: CustomerService,
    public activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public SettingService: SettingService
  ) {
    this.getStateList();
    this.customer_id = this.activatedRoute.snapshot.paramMap.get("customer_id");
    this.getCustomers();

    this.getCustomerDetails(this.customer_id);
  }
  public CustomerModel: CustomerModel = new CustomerModel();
  ngOnInit() {
    window.scroll(0, 0);
    if(this.customer_id){
      this.getCustomerById();
    }
    this.updateCustomerInfo();
  }
  getCustomers() {
    this.loading = true;

    this.CustomerService.CustomerList().subscribe(
      (result) => {
        this.CustomerList = result["data"];
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    );
  }

  getStateList() {
    this.CustomerService.GetStates().subscribe(
      (result) => {
        this.states = result["data"];
      },
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    );
  }
  getCustomerDetails(customer_id) {
    if (this.customer_id != null) {
      this.loading = true;
      this.CustomerService.GetCustomerById(this.customer_id).subscribe(
        (result) => {
          this.loading = false;

          this.CustomerModel.customer_id = result["data"].customer_id;
          this.CustomerModel.name = result["data"].name;
          this.CustomerModel.mobile = result["data"].mobile;
          this.CustomerModel.alternative_number = result["data"].alternative_number;
          this.alternative_number = result["data"].alternative_number;
          this.CustomerModel.email = result["data"].email;
          this.CustomerModel.address_type = result["data"]["default_address"].address_type;
          this.CustomerModel.address = result["data"]["default_address"].address;
          this.CustomerModel.locality = result["data"]["default_address"].state;
          this.CustomerModel.zip = result["data"]["default_address"].zip;
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
          }
        }
      );
    } else {
      this.loading = false;
    }
  }

  get f() {
    return this.NewCustomer.controls;
  }
  OnCustomerName(event) {
    this.customer_name = event.target.value;
  }

  OnCustomermobile(event) {
    this.customer_mobile = event.target.value;
  }

  OnCustomerEmail(event) {
    this.customer_email = event.target.value;
  }

  Step(step: number) {
    if (step == 2) {
      // this.Focus();

      if (
        this.NewCustomer.controls.name.invalid ||
        this.NewCustomer.controls.mobile.invalid ||
        this.NewCustomer.controls.email.invalid ||
        this.custom_phone_vaidation != ""
      ) {
        this.submitted = true;
        return;
      }
      if (this.customer_id != null) {
        if (
          this.NewCustomer.value.mobile.e164Number != "" ||
          this.NewCustomer.value.mobile.e164Number != null
        )
          this.NewCustomer.controls.mobile.setValue(
            this.NewCustomer.value.mobile.e164Number
          );
        this.NewCustomer.updateValueAndValidity();
        this.CustomerModel.mobile = this.NewCustomer.value.mobile;
      }

      //   this.NewCustomer.value.mobile=this.NewCustomer.value.mobile.e164Number;
      //   this.NewCustomer.updateValueAndValidity();

      //   for(let i of this.CustomerList){
      //     if(i.email==this.NewCustomer.value.email){
      //       if(this.customer_id==null || this.customer_id!=i.customer_id){
      //       this.showEmailerror();
      //       return;
      //     }
      //   }
      // }
    }

    this.step = step;
  }

  Route() {
    if (this.step === 2) {
      this.step = 1;
    } else {
      this._router.navigate(["/customer"]);
    }
  }

  Select(event) {}
  
  Create() {
    this.submitted2 = true;
    if (this.NewCustomer.invalid) {
      return;
    }
    if (
      this.NewCustomer.value.mobile.e164Number != "" ||
      this.NewCustomer.value.mobile.e164Number != null
      ) {
        this.NewCustomer.controls.mobile.setValue(
          this.NewCustomer.value.mobile.e164Number
          );
          this.NewCustomer.updateValueAndValidity();
        }
        
        if (
      this.NewCustomer.controls.address.invalid ||
      this.NewCustomer.controls.locality.invalid ||
      this.NewCustomer.controls.zip.invalid
    ) {
      this.submitted2 = true;
      return;
    }
    this.addCustomerLoader = true;
    if (this.customer_id == null) {
      this.CustomerService.AddCandidate(this.NewCustomer.value).subscribe(
        (result) => {
          if (result["success"]) {
            this.showSuccess(result["msg"]);
            this._router.navigate([`/customer-self/${result['data'].customer_id}`]);
          } else {
            this.showDanger(result["msg"]);
          }
          this.addCustomerLoader = false;
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
            this.addCustomerLoader = false;
          }
        }
      );
    } else {
      this.CustomerService.UpdateCandidate(this.CustomerModel).subscribe(
        (result) => {
          if (result["success"]) {
            this.showSuccess(result["msg"]);
            this._router.navigate([`/customer-self/${this.customer_id}`]);
          } else {
            this.showDanger(result["msg"]);
          }
        },
        (err: HttpErrorResponse) => {
          if (
            err.error.message == "Unauthenticated." ||
            err.status == 401 ||
            err.statusText == "Unauthorized"
          ) {
            this.SettingService.LogOut();
          }
        }
      );
    }
  }

  updateCustomerInfo(){
    if(this.customer_id){
      this.NewCustomer.get('address_type').clearValidators()
      this.NewCustomer.get('address').clearValidators()
      this.NewCustomer.get('locality').clearValidators()
      this.NewCustomer.get('zip').clearValidators()
    }
  }

  getCustomerById(){
    this.loading = true;
    this.CustomerService.GetCustomerById(this.customer_id).subscribe((result) => {
      if (result["success"]){
        this.NewCustomer.get('name').setValue(result["data"]['name']);
        this.NewCustomer.get('address_type').setValue(result["data"]['default_address']['address_type']);
        this.NewCustomer.get('email').setValue(result["data"]['email']);
        this.NewCustomer.get('mobile').setValue(result["data"]['mobile']);
        this.NewCustomer.get('address').setValue(result["data"]['address']);
        this.NewCustomer.get('locality').setValue(result["data"]['locality']);
        this.NewCustomer.get('zip').setValue(result["data"]['zip']);
        this.NewCustomer.updateValueAndValidity();
      }else {
        this.showDanger(result["msg"]);
      }
      (err: HttpErrorResponse) => {
        if (
          err.error.message == "Unauthenticated." ||
          err.status == 401 ||
          err.statusText == "Unauthorized"
        ) {
          this.SettingService.LogOut();
        }
      }
    })
  }

  CheckValidation(event) {
    this.custom_phone_vaidation = event.target.validationMessage;
  }

  showSuccess(msg: string) {
    this.toastr.success(msg);
  }

  showDanger(msg: string) {
    this.toastr.error(msg);
  }
  showEmailerror() {
    this.toastr.error("Email Already Exists!");
  }
 
  public customerState: string = '';
  public customerAddress: string = ''; 

  getAddressFromGoogle(address: any){
    this.NewCustomer.get('address').setValue(address.formatted_address);
    address.address_components.forEach(element => {
      if(element['types'][0] === "postal_code") {
        this.NewCustomer.get('zip').setValue(element['long_name']);
      }else if(element['types'][0] === "administrative_area_level_1"){
        this.customerState = element['long_name'];
        this.NewCustomer.get('locality').setValue(element['long_name']);
      }
    });
  }
}
