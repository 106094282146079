import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { InvoiceUnpaidList } from 'src/app/shared/models/invoice/invoice-list-unpaid.model';
import { InvoiceAllList, InvoiceModel, PaymentSettings } from 'src/app/shared';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AdvancePaymentComponent } from 'src/app/layout/modal/advance-payment/advance-payment/advance-payment.component';
import { Subscription } from 'rxjs';
import { BroadcastChannel } from 'broadcast-channel';

declare type Message = {
  message: string;
};
@Component({
  selector: 'app-invoice-self',
  templateUrl: './invoice-self.component.html',
  styleUrls: ['./invoice-self.component.scss']
})
export class InvoiceSelfComponent implements OnInit {
  public invoice_id: any = null;
  public invoice_index: any = null;
  public _invoiceData: any = null;
  public email_sent: any = null;
  public item_total: number = 0;
  public discount: any = 0;
  public sales_tax: any = 0;
  public advance: any = 0;
  public amount: number = 0;
  public modalRef: BsModalRef;
  public InvoiceDetails: InvoiceAllList = new InvoiceAllList();
  public PaymentModel: PaymentSettings = new PaymentSettings()
  public _InvoiceModel: InvoiceModel = new InvoiceModel();
  public env = environment; 
  public advancePaid = false;

  // public item_name: any[] = [];
  // public rates: any[] = [];
  public invoice_items: any[] = [];
  

  public loading: boolean = false;
  public customer_name: string = null;
  public customer_email: string = null;
  public mobile: any = null;
  public organization_id: string = null;
  public organization_name: string = null;
  public template: any = null;
  public userData:any = null;
  public loading1: boolean = false;
  public loading2: boolean = false;
  public accepted1: boolean = false;
  public accepted2: boolean = false;
  public clipboard: Clipboard;
  public checklist_enabled: boolean = false;
  public enabled_modules: any[] = [];
  public alternative_number: any[] = [];
  public BC3: BroadcastChannel<Message> = null;

  constructor(
    public _router: Router,
    private activatedRoute: ActivatedRoute,
    public _invoiceService: InvoiceService,
    public _modalService: BsModalService,
    public toastr: ToastrService,
    private sanitizer:DomSanitizer,
    public Auth:AuthService,
    public SettingService:SettingService

  ) {
    this.userData = this.Auth.User();
    this.organization_id = this.userData.user.organization_id;
    this.getOrganizationDetails(this.organization_id);
    this.invoice_id = this.activatedRoute.snapshot.paramMap.get('invoice_id');
    this.invoice_index = this.activatedRoute.snapshot.paramMap.get('invoice_index');
    this.invoiceData(this.invoice_id);
    if ( JSON.parse( localStorage.getItem( 'activated_modules' ) ) )
    {
      this.enabled_modules = JSON.parse( localStorage.getItem( 'activated_modules' ) );
      for ( let index of this.enabled_modules )
      {
        if ( index[ 'module_id' ] == '50bf5d16f34b' && index[ 'enabled' ] == 1)
        {
          this.checklist_enabled = true;
        }
      }
    }  
  }

  ngOnInit() {
    this.channel2();
    this.BC3.onmessage = (e) => {
      if (e.message) {
        location.reload();
      }
    }
    window.scroll(0, 0);
  }

  public subsciption: Subscription;
  ngAfterViewInit() {
    this.subsciption = this._invoiceService.isAdvancePay.subscribe((response) => {
      if(response) {
        this.invoiceData(this.invoice_id);
      }
    })
  }

  ngOnDestroy(){
    this.subsciption.unsubscribe()
  }

  channel2() {
    this.BC3 = new BroadcastChannel('advance-paid', {
      idb: {
        onclose: () => {
          this.BC3.close();
          this.channel2();
        }
      }
    });
  }

  getOrganizationDetails(organization_id) {
    this.SettingService.GetAccountDetails(organization_id).subscribe(
      result => {
        this.template = result['data']['template'];
        this.organization_name=result['data']['business_name'];
      }
      , (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }
  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    let transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;

    return transformedData;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  public checklistId: string = '';
  invoiceData(invoiceID: string) {
    this.loading = true;

    this._invoiceService.InvoiceView(invoiceID).subscribe(
      (result) => {
        this.checklistId = result['data']['checklist_id']
        this._invoiceData = result['data'];
        this.loading = false;
        this.getPaymentSetting(result['data'].organization_id);
        if (this._invoiceData['invoice_customers'] != null && this._invoiceData['invoice_customers'].length > 0) {
          this._InvoiceModel.customer_name = this._invoiceData['invoice_customers'][0].name;
          this._InvoiceModel.customer_email = this._invoiceData['invoice_customers'][0].email;
          this._InvoiceModel.customer_phone = this._invoiceData['invoice_customers'][0].mobile;
          this._InvoiceModel.alternative_number = this._invoiceData['invoice_customers'][0].alternative_number;

          this.customer_name = this._invoiceData['invoice_customers'][0].name;
          this.customer_email = this._invoiceData['invoice_customers'][0].email;
          this.mobile = this._invoiceData['invoice_customers'][0].mobile;
          this.alternative_number = this._invoiceData['invoice_customers'][0].alternative_number;
        }

        if (this._invoiceData['invoice_invoiceitems'] != null && this._invoiceData['invoice_invoiceitems'].length > 0) {
          // for(let index of this._invoiceData['invoice_items']){
          //   this.item_name.push(index.name);
          //   this.rates.push(index.rate);
          // }
          this.invoice_items = this._invoiceData['invoice_invoiceitems'];
          this.item_total = result['data'].amount;
        }
       
        this.InvoiceDetails.amount = result['data'].total;
        this.InvoiceDetails.invoice_number = result['data'].invoice_number;
        this.email_sent=result['data'].email_sent_date;
        this.InvoiceDetails.discount = result['data'].discount;
        this.InvoiceDetails.discount_type = result['data'].discount_type;
        this.InvoiceDetails.advance = result['data'].advance;
        this.InvoiceDetails.advance_type = result['data'].advance_type;
        this.InvoiceDetails.sales_tax = result['data'].sales_tax;
        this.InvoiceDetails.created_at = result['data'].created_at;
        this.InvoiceDetails.updated_at = result['data'].updated_at;
        this.InvoiceDetails.note = result['data'].note;
        this.InvoiceDetails.private_note = result['data'].private_note;
        this.InvoiceDetails.advance_paid = result['data'].advance_paid;
        this.InvoiceDetails.net_amount = result['data'].net_amount;
        this.InvoiceDetails.gross_amount = result['data'].gross_amount;
        this.InvoiceDetails.invoice_id = result['data'].invoice_id;
        this.InvoiceDetails.organization_id = result['data'].organization_id;
        if(this.InvoiceDetails.advance_paid == true) {
          this.advancePaid = true;
        }
        if (this.InvoiceDetails.discount != null && this.InvoiceDetails.discount != '') {
          if (this.InvoiceDetails.discount_type == '%') {
            this.discount = (this.item_total * (+this.InvoiceDetails.discount)) / 100;
            this.amount = (+this.item_total) - this.discount;
          } else if (this.InvoiceDetails.discount_type == '$') {
            this.discount = (+this.InvoiceDetails.discount);
            this.amount = this.item_total - (+this.InvoiceDetails.discount);
          }
        }

        if (this.InvoiceDetails.sales_tax != null && this.InvoiceDetails.sales_tax != '') {
          if (this.discount == 0) {
            this.sales_tax = (this.item_total * (+this.InvoiceDetails.sales_tax)) / 100;
            this.amount = this.item_total + this.sales_tax;
          } else {
            this.sales_tax = (this.amount * (+this.InvoiceDetails.sales_tax)) / 100;
            this.amount = this.amount + this.sales_tax;
          }

        }

        if (this.InvoiceDetails.advance != null && this.InvoiceDetails.advance != '') {
          if (this.discount == 0 && this.sales_tax == 0) {

            if (this.InvoiceDetails.advance_type == '%') {
              this.advance = (this.item_total * (+this.InvoiceDetails.advance)) / 100;
            } else if (this.InvoiceDetails.advance_type == '$') {
              this.advance =  (+this.InvoiceDetails.advance);
              // this.advance = this.item_total - (+this.InvoiceDetails.advance);
            }
          } else if (this.discount == 0 && this.sales_tax != 0) {

            if (this.InvoiceDetails.advance_type == '%') {

              this.advance = (this.amount * (+this.InvoiceDetails.advance)) / 100;

            } else if (this.InvoiceDetails.advance_type == '$') {
              this.advance =  (+this.InvoiceDetails.advance);
              // this.advance = this.amount - (+this.InvoiceDetails.advance);
            }
          } else if (this.discount != 0 && this.sales_tax == 0) {

            if (this.InvoiceDetails.advance_type == '%') {
              this.advance = (this.amount * (+this.InvoiceDetails.advance)) / 100;
            } else if (this.InvoiceDetails.advance_type == '$') {
              this.advance =  (+this.InvoiceDetails.advance);
              // this.advance = this.amount - (+this.InvoiceDetails.advance);
            }
          } else {
            if (this.InvoiceDetails.advance_type == '%') {

              this.advance = (this.amount * (+this.InvoiceDetails.advance)) / 100;
            } else if (this.InvoiceDetails.advance_type == '$') {
              this.advance =  (+this.InvoiceDetails.advance);
              // this.advance = this.amount - (+this.InvoiceDetails.advance);

            }
          }
        }
        this.discount = parseFloat(this.discount).toFixed(2);
        this.advance = parseFloat(this.advance).toFixed(2);
        this.sales_tax = parseFloat(this.sales_tax).toFixed(2);
      }
    )
  }

  deleteInvoice(invoice_id: string) {
    this._invoiceService.Delete(invoice_id).subscribe(
      (result) => {
        if (result['success']) {
          this.modalRef.hide();
          this.toastr.success('Deleted Successfully.');
          this._router.navigate([`/invoices`]);
        }
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingService.LogOut();
        }
      }
    )
  }

  sanitize(){
    return this.sanitizer.bypassSecurityTrustUrl('sms:'+this.mobile+';?&body=Dear '+this._InvoiceModel.customer_name+', %0a%0aThank you for your business. %0a%0aYour invoice '+this.InvoiceDetails.invoice_number+' can be viewed, printed, or downloaded as a PDF file from the link below. You can also choose to pay it online. %0a%0aClick to view Invoice %0a'+this.env.frontend_url+'template-'+this.template+'/invoice/'+this.invoice_id+'  %0a%0aWe look forward to doing more business with you. %0a%0aRegards, %0a'+this.userData.user.name+' %0a'+this.organization_name);
  }
  getDataUrl(template,id){
    return this.env.frontend_url+"template-"+template+'/invoice/'+id;
  }

  copy(evt: Event){
    let url: string = evt.target['dataset'].url;

    document.addEventListener('copy', function(evt) {
      evt.clipboardData.setData('text/plain', url);
      evt.preventDefault();
    }, true);

    let result = document.execCommand('copy');

    if (result) {
      this.toastr.success('Successfully Copied');
    }
    else {
      this.toastr.error('Not Copied');
    }

    // this.clipboard.writeText(url).then(() => {
    //   this.toastr.success('Successfully Copied');
    // }, () => {
    //   this.toastr.error('Not Copied');
    // });
  }

  Recurring(invoice_id){
    this._invoiceService.Recurring(invoice_id).subscribe(
      result=>{

      }
    )

  }

  getPaymentSetting(organization_id) {
    if (organization_id != null) {
      this.loading = true;
      this.SettingService.GetPaymentDetails(organization_id)
        .subscribe((result) => {
          this.loading = false;
          if(result['bank_details']){
            this.PaymentModel.bank_details = result['bank_details']['bank_details'];
            this.PaymentModel.checked = result['bank_details']['checked'];
            this.PaymentModel.bank_detail_id = result['bank_details']['bank_detail_id'];
          }else{
            this.PaymentModel = null;
          }          
        }, (err:HttpErrorResponse) => {
          if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
            this.SettingService.LogOut();
          }
        }
      )
    }
    else {
      this.loading = false;
    }
  }

  payAdvance() {
    const initialState: ModalOptions = {
      initialState: {
        invoice_id: this.InvoiceDetails.invoice_id,
        organization_id: this.InvoiceDetails.organization_id,
        ignoreBackdropClick: true,
        advance: this.advance
      }
    };
    this.modalRef = this._modalService.show(AdvancePaymentComponent,  initialState);
  }
}
