import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  public acceptStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(public http: HttpClient, public TokenService: TokenService) { 
  }

  set setAcceptStatus(value: boolean) {
    this.acceptStatus.next(value)
  }

  scheduleList(){
    const URL = environment.url + 'widget-service';
    return this.http.get(URL, {
      headers: this.TokenService.GetTokenByHeader()
    })
    .pipe(map((res: any) => res));
  }
  scheduleDetails(widget_service_id):Observable<[]>{
    const URL = environment.url+'widget-service/'+widget_service_id;
    return this.http.get(URL, {
      headers: this.TokenService.GetTokenByHeader()
    })
    .pipe(map((res: any) => res));
  }
  updateScheduleDetails(data):Observable<[]>{
    data.date = new Date(data.date.getTime() - data.date.getTimezoneOffset() * 60000);

    const URL = environment.url+'widget-service/'+data.widget_service_id;
  return this.http.put(URL, data, {
    headers: this.TokenService.GetTokenByHeader()
  })
  .pipe(map((res: any) => res));
  }
  stautsFilteredList(data): Observable<[]> {
    const URL = environment.url + 'widget-service/list-by-status';
    return this.http.post(URL, data, {
      headers: this.TokenService.GetTokenByHeader()
    })
      .pipe(map((res: any) => res));
  }

  AcceptSchedule(data): Observable<[]> {
    const URL = environment.url + 'widget-service/accept/' + data.widget_service_id;
    return this.http.post(URL, data, {
      headers: this.TokenService.GetTokenByHeader()
    })
      .pipe(map((res: any) => res));
  }

  //My Schedule Apis

  myScheduleList(): Observable<[]> {
    const URL = environment.url + 'widget-service/list-by-user';
    return this.http.post(URL, null,{
      headers: this.TokenService.GetTokenByHeader()
    })
      .pipe(map((res: any) => res));
  }
  scheduleCalendardetails():Observable <[]>{
    const URL = environment.url + 'widget-service/calendar-list';
    return this.http.get(URL, {
      headers: this.TokenService.GetTokenByHeader()
    })
    .pipe(map((result:any) => result))
  }

  scheduleServiceWidget(data): Observable<[]>{
    const URL = environment.url + 'widget-service/modal-save';
    return this.http.post(URL, data, {
      headers: this.TokenService.GetTokenByHeader()
    })
    .pipe(map((result:any) => result))
  }
  
  distance(data): Observable<[]> {
    const URL = environment.url + 'distance-matrix';
    return this.http.post(URL, data, {
      headers: this.TokenService.GetTokenByHeader()
    })
      .pipe(map((res: any) => res));
  }

  reviewRequest(widget_service_id): Observable<[]> {
    const URL = `${environment.url}review-message/${widget_service_id}`;
    return this.http.post(URL, widget_service_id, {
      headers: this.TokenService.GetTokenByHeader()
    })
      .pipe(map((res: any) => res));
  }

  scheduleMessage(data): Observable<[]> {
    const URL = `${environment.url}send-sms`;
    return this.http.post(URL, data, {
      headers: this.TokenService.GetTokenByHeader()
    })
      .pipe(map((res: any) => res));
  }
}
