import { Component, OnInit, SecurityContext } from '@angular/core';
import { Sidebar} from 'src/app/shared';
import { ActivatedRoute } from '@angular/router';
import { ServiceAgreementService } from 'src/app/shared/services/service-agreement/service-agreement.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'service-agreements-templates-two',
  templateUrl: './service-agreements-templates-two.component.html',
  styleUrls: ['./service-agreements-templates-two.component.scss']
})

export class ServiceAgreementsTemplatesTwoComponent implements OnInit { 

  public template_name: any = null;
  public content: any = null;

  constructor(
    public _sidebar: Sidebar,
    public activateRoute: ActivatedRoute,
    public serviceAgreementService: ServiceAgreementService,
    public auth: AuthService) { 
     this.template_name = this.activateRoute.snapshot.paramMap.get('template_name');
     this.getTemplateView();
     
  }
  ngOnInit() {
    window.scroll(0, 0);
  }

  getTemplateView(){
    let data = {
      template_name: this.template_name,
      user_id: this.auth.User().user.user_id,
      organization_id: this.auth.User().user.organization_id
    }
    this.serviceAgreementService.viewTemplate(data).subscribe(
      result=>{
        this.content = result['data'].content;
        
      }
    )
  }
  
}