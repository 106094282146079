import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  BsModalRef,
  BsModalService,
  ModalDirective
} from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import {
  Sidebar,TaxAuthority,TaxExemption,TaxRateModel
} from 'src/app/shared';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { TaxService } from 'src/app/shared/services/tax/tax.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tax-settings',
  templateUrl: './tax-settings.component.html',
  styleUrls: ['./tax-settings.component.scss']
})
export class TaxSettingsComponent implements OnInit {
  public loading = false;
  public taxRate: boolean = true;
  public taxExemption: boolean = false;
  public taxAuthorities: boolean = false;
  modelRef: BsModalRef;
  public submitted_taxRate: boolean = false;
  public submitted_taxExemption: boolean = false;
  public submitted_taxAuthorities: boolean = false;
  public tax_rate_list:any[] = [];
  public tax_exemption_list:any[] = [];
  public tax_authority_list:any[] = [];
  public valid_tax_rate_csv_data:any[] = [];
  public invalid_tax_rate_csv_data:any[] = [];
  public TaxRateModel: TaxRateModel = new TaxRateModel();
  public TaxAuthorityModel: TaxAuthority = new TaxAuthority();
  public TaxExemptionModel: TaxExemption = new TaxExemption();
  public current_deleting_tax:string = null;
  public current_deleting_tax_id:string = null;
  public saveBtnLoader: boolean = false;
  env = environment.frontend_url;
  @ViewChild('uploadTaxConfirm', {static: false}) uploadTaxConfirm : ModalDirective;
  @ViewChild('uploadTaxGroup', {static: false}) uploadTaxGroup : TemplateRef<any>;

  public citiesList: any[] = [];


  //Formgroup for tax rates
  public TaxRates = this.fb.group({
    name: ['',Validators.required],
    tax_authority_id: [''],
    rate: ['', [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
    tax_authority: [''],
    state: ['',Validators.required],
    city: [''],
    location_code: [''],
    county: [''],
  })

  get tax_rate_fields(){
    return this.TaxRates.controls;
  }

  //Formgroup for tax rates
  public TaxExemption = this.fb.group({
    reason: ['',Validators.required],
    description: ['',Validators.required],
    type: ['',Validators.required],
  })

  get tax_exemption_fields(){
    return this.TaxExemption.controls;
  }

  //Formgroup for tax rates
  public TaxAuthority = this.fb.group({
    name: ['',Validators.required],
    description:['', Validators.required]
  })

  get tax_authority_fields(){
    return this.TaxAuthority.controls;
  }
  constructor(public _sidebar: Sidebar, 
    private modelService: BsModalService,
    public fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    public TaxService: TaxService,
    public SettingService: SettingService,
    public toastr: ToastrService,
    public customerService: CustomerService
    ) {
      this.getTaxRateList();
      this.getTaxAuthoprityList();
      this.getTaxExemptionList();
      this.stateList();
    }

  ngOnInit() {}
  openModal(template: TemplateRef < any >, id, tax_type ) {
    this.modelRef = this.modelService.show(template)
    if(tax_type=='Tax Rates'){
      this.getTaxRateDetails(id);
    } else if(tax_type=='Tax Authority'){
      this.getTaxAuthorityDetails(id);
    }
    else if(tax_type=='Tax Exemption'){
      this.getTaxExemptionDetails(id);
    }
    if(id==null){
      this.TaxRateModel = new TaxRateModel();
      this.TaxAuthorityModel = new TaxAuthority();
      this.TaxExemptionModel = new TaxExemption();
    }
    
  }

  DeleteModal(template: TemplateRef < any >, id, tax_type ) {
    this.modelRef = this.modelService.show(template)
    if(tax_type=='Tax Rates'){
      this.current_deleting_tax_id = id;
    }else if(tax_type=='Tax Authority'){
      this.current_deleting_tax_id = id;
    }
    else if(tax_type=='Tax Exemption'){
      this.current_deleting_tax_id = id;
    }
    this.current_deleting_tax = tax_type;

    
  }
  viewTaxRate(){
    this.taxRate = true;
    this.taxExemption = false
    this.taxAuthorities = false
  }
  viewTaxExemption(){
    this.taxExemption = true
    this.taxRate = false;
    this.taxAuthorities = false
  }
  viewtaxAuthorities(){
    this.taxExemption = false
    this.taxRate = false;
    this.taxAuthorities = true
  }

  Cancel(){
    this.submitted_taxAuthorities = false;
    this.submitted_taxExemption = false;
    this.submitted_taxRate = false;
    this.file = null;
    this.modelRef.hide();
    this.TaxRates.reset();    
    this.TaxRates.get('rate').clearValidators();    
    this.TaxRates.get('name').clearValidators();    
    // this.TaxRates.get('tax_authority_id').clearValidators();    
    // this.TaxRates.get('tax_authority').clearValidators();    
    this.TaxAuthority.reset();
    this.TaxAuthority.get('name').clearValidators();    
    this.TaxAuthority.get('description').clearValidators();    
    this.TaxExemption.reset();
    this.TaxExemption.get('reason').clearValidators();    
    this.TaxExemption.get('description').clearValidators(); 
    this.TaxExemption.get('type').clearValidators(); 
    this.changeDetectorRef.detectChanges();
  }


  DeleteTax(){
    this.saveBtnLoader = true;
    if(this.current_deleting_tax_id!=null && this.current_deleting_tax=="Tax Rates"){
      this.SettingService.DeleteTaxRate(this.current_deleting_tax_id).subscribe(
        result=>{
          if(result['success']){
            this.toastr.success(result['msg']);
            this.getTaxRateList();
            this.modelRef.hide();

          }else{
            this.toastr.error(result['msg']);
          }
          this.saveBtnLoader = false;
        }
      )
    }
    if(this.current_deleting_tax_id!=null && this.current_deleting_tax=="Tax Authority"){
      this.SettingService.DeleteTaxAuthority(this.current_deleting_tax_id).subscribe(
        result=>{
          if(result['success']){
            this.toastr.success(result['msg']);
            this.getTaxAuthoprityList();
            this.modelRef.hide();

          }else{
            this.toastr.error(result['msg']);
          }
          this.saveBtnLoader = false;
        }
      )
    }

    if(this.current_deleting_tax_id!=null && this.current_deleting_tax=="Tax Exemption"){
      this.SettingService.DeleteTaxExemption(this.current_deleting_tax_id).subscribe(
        result=>{
          if(result['success']){
            this.toastr.success(result['msg']);
            this.getTaxExemptionList();
            this.modelRef.hide();

          }else{
            this.toastr.error(result['msg']);
          }
          this.saveBtnLoader = false;
        }
      )
    }
  }

    //Tax Rate Functions
    getTaxRateList(){
      this.loading = true;
      this.SettingService.GetTaxRates().subscribe(
        result=>{
          this.tax_rate_list = result['data'];
          this.loading = false;
        }
      )
    }
  
    getTaxRateDetails(id){
      this.SettingService.GetTaxRateDetails(id).subscribe(
        result=>{
          this.TaxRateModel.name = result['data'].name;
          this.TaxRateModel.created_by = result['data'].created_by;
          this.TaxRateModel.organization_id = result['data'].organization_id;
          this.TaxRateModel.rate = result['data'].rate;
          this.TaxRateModel.state = result['data'].state;
          this.TaxRateModel.city = result['data'].city;
          this.TaxRates.get('city').setValue(this.TaxRateModel.city);
          this.TaxRates.get('city').updateValueAndValidity();
          this.TaxRateModel.county = result['data'].county;
          this.TaxRateModel.location_code = result['data'].location_code;
          this.TaxRateModel.tax_authority = result['data'].tax_authority;
          this.TaxRateModel.tax_authority_id = result['data'].tax_authority_id;
          this.TaxRateModel.tax_rate_id = result['data'].tax_rate_id;      
          this.getCityByStateName(this.TaxRateModel.state);   
        }
      )
    }
  

  AddTaxRate(){
    this.TaxRates.get('rate').setValidators([Validators.required,Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]);
    this.TaxRates.get('name').setValidators(Validators.required);
    this.TaxRates.get('state').setValidators(Validators.required);
    this.TaxRates.get('city');
    this.TaxRates.get('rate').updateValueAndValidity();
    this.TaxRates.get('name').updateValueAndValidity();
    this.TaxRates.get('state').updateValueAndValidity();
    this.TaxRates.get('city').updateValueAndValidity();
    this.submitted_taxRate = true;
    if(this.TaxRates.invalid){
      return;
    }
    this.saveBtnLoader = true;
    if(this.TaxRateModel.tax_rate_id==null){
      this.SettingService.AddTaxRate(this.TaxRates.value).subscribe(
        result=>{
          if(result['success']){
            this.toastr.success(result['msg']);
            this.getTaxRateList();
            this.Cancel();
            this.modelRef.hide();
          }else{
            this.Cancel();
            this.toastr.error(result['error']['rate'][0]);
          }
          this.saveBtnLoader = false;

        }
      )
    }else if(this.TaxRateModel.tax_rate_id!=null){
      this.SettingService.UpdateTaxRate(this.TaxRateModel).subscribe(
        result=>{
          if(result['success']){
            this.toastr.success(result['msg']);
            this.getTaxRateList();
            this.modelRef.hide();
            this.Cancel();
          }else{
            this.toastr.error(result['msg']);
          }
          this.saveBtnLoader = false;

        }
      )
    }
  }

  //Tax Authority Functions
  AddTaxAuthority(){
    this.TaxAuthority.get('name').setValidators(Validators.required);    
    this.TaxAuthority.get('description').setValidators(Validators.required);  
    this.TaxAuthority.get('name').updateValueAndValidity();    
    this.TaxAuthority.get('description').updateValueAndValidity();  
    this.submitted_taxAuthorities = true;
    
    if(this.TaxAuthority.invalid){
      return;
    }
    this.saveBtnLoader = true;

    if(this.TaxAuthorityModel.tax_authority_id==null){
      this.SettingService.AddTaxAuthority(this.TaxAuthority.value).subscribe(
        result=>{
          if(result['success']){
            this.toastr.success(result['msg']);
            this.getTaxAuthoprityList();
            this.Cancel();
            this.modelRef.hide();
          }else{
            this.Cancel();
            this.toastr.error(result['msg']);
          }
          this.saveBtnLoader = false;

        }
      )
    }else if(this.TaxAuthorityModel.tax_authority_id!=null){
      this.SettingService.UpdateTaxAuthority(this.TaxAuthorityModel).subscribe(
        result=>{
          if(result['success']){
            this.toastr.success(result['msg']);
            this.getTaxAuthoprityList();
            this.modelRef.hide();
            this.Cancel();
          }else{
            this.Cancel();
            this.toastr.error(result['msg']);
          }
          this.saveBtnLoader = false;

        }
      )
    }
  }

  getTaxAuthoprityList(){
    this.loading = true;
    this.SettingService.GetTaxAuthorities().subscribe(
      result=>{
        this.tax_authority_list = result['data'];
        this.loading = false;
      }
    )
  }


  getTaxAuthorityDetails(id){
    this.SettingService.GetTaxAuthorityDetails(id).subscribe(
      result=>{
        this.TaxAuthorityModel.name = result['data'].name;
        this.TaxAuthorityModel.created_by = result['data'].created_by;
        this.TaxAuthorityModel.organization_id = result['data'].organization_id;
        this.TaxAuthorityModel.rate = result['data'].rate;
        this.TaxAuthorityModel.tax_authority_id = result['data'].tax_authority_id;
        this.TaxAuthorityModel.description = result['data'].description;        
      }
    )
  }

  //Tax Authority Functions
  AddTaxExemption(){
     
    this.TaxExemption.get('reason').setValidators(Validators.required);    
    this.TaxExemption.get('description').setValidators(Validators.required); 
    this.TaxExemption.get('type').setValidators(Validators.required);
    this.TaxExemption.get('reason').updateValueAndValidity();    
    this.TaxExemption.get('description').updateValueAndValidity(); 
    this.TaxExemption.get('type').updateValueAndValidity();
    this.submitted_taxExemption = true;
    if(this.TaxExemption.invalid){
      return;
    }
    this.saveBtnLoader = true;
    if(this.TaxExemptionModel.tax_exemption_id==null){
      this.SettingService.AddTaxExemption(this.TaxExemption.value).subscribe(
        result=>{
          if(result['success']){
            this.toastr.success(result['msg']);
            this.getTaxExemptionList();
            this.modelRef.hide();
            this.Cancel();
          }else{
            this.Cancel();
            this.toastr.error(result['msg']);
          }
          this.saveBtnLoader = false;
        }
      )
    }else if(this.TaxExemptionModel.tax_exemption_id!=null){
      this.SettingService.UpdateTaxExemption(this.TaxExemptionModel).subscribe(
        result=>{
          if(result['success']){
            this.toastr.success(result['msg']);
            this.getTaxExemptionList();
            this.Cancel();
            this.modelRef.hide();
          }else{
            this.Cancel();
            this.toastr.error(result['msg']);
          }
          this.saveBtnLoader = false;
        }
      )
    }
  }

  getTaxExemptionList(){
    this.loading = true;
    this.SettingService.GetTaxExemptionList().subscribe(
      result=>{
        this.tax_exemption_list = result['data'];
        this.loading = false;
      }
    )
  }


  getTaxExemptionDetails(id){
    this.SettingService.GetTaxExemptionDetails(id).subscribe(
      result=>{
        this.TaxExemptionModel.reason = result['data'].reason;
        this.TaxExemptionModel.created_by = result['data'].created_by;
        this.TaxExemptionModel.organization_id = result['data'].organization_id;
        this.TaxExemptionModel.type = result['data'].type;
        this.TaxExemptionModel.tax_exemption_id = result['data'].tax_exemption_id;
        this.TaxExemptionModel.description = result['data'].description;        
      }
    )
  }

  SetAuthorityDetails(){
    this.SettingService.GetTaxAuthorityDetails(this.TaxRates.value.tax_authority_id).subscribe(
      result=>{
        this.TaxRates.get('tax_authority').setValue(result['data'].name);
        this.TaxRates.get('tax_authority').updateValueAndValidity();
        this.TaxRateModel.tax_authority = this.TaxRates.value.tax_authority;

      }
    )
  }
  setDefaultTax(taxRateId){
    this.SettingService.setDefaultTax(taxRateId).subscribe((result) => {
      this.getTaxRateList()
    })
  }
  statesList: any[] = [];
  stateList(){
    this.customerService.GetStates().subscribe((response) => {
      this.statesList = response['data'];
      this.citiesList = [];

    })
  }
  public file: File = null;
  // Upload tax
  FilesSelected(event) {
    if (event.rejectedFiles.length > 0) {
      this.toastr.error("The file must be CSV type");
      return;
    }
    this.file = event.addedFiles[0];
    let reader: FileReader = new FileReader();
    reader.readAsText(this.file);

    reader.onload = (e) => {
      let csv: any = reader.result;
      let allTextLines = csv.split(/\r?\n/);      
    };
  }
  onRemove() {
    this.file = null;
  }
  uploadBtnLoader: boolean = false;
   validateCsv(){
     this.uploadBtnLoader = true;
     this.SettingService.validateCsv(this.file).subscribe((response) => {
       if(response['success']){
         this.toastr.success('CSV Data Validated')
         this.modelRef.hide();
         this.uploadTaxConfirm.show();
         this.valid_tax_rate_csv_data = response['valid'];
         this.invalid_tax_rate_csv_data = response['invalid'];
         this.uploadBtnLoader = false;
      }else{
        this.file = null;
        this.uploadBtnLoader = false;
        this.toastr.error(response['msg'])
      }
    })
  }
  csvUpload(){
    this.SettingService.csvTaxUpload(this.file).subscribe((response) => {
      if(response['success']){
        this.toastr.success(response['msg']);
        this.uploadTaxConfirm.hide();
        this.file = null;
        this.getTaxRateList();
      }else{
        this.file = null;
        this.toastr.warning('Something went wrong!','Warning');
      }
    })
  }
  getCityId(event){
    this.citiesList = [];    
    this.TaxService.getCityList(event.target.value).subscribe((response) => {
      let citiesList = response['data'];
      for(let i in citiesList){
        this.citiesList.push(citiesList[i])
      }      
    })
  }

  getCityByStateName(state_name){
    this.citiesList = [];
    
    this.TaxService.getCityListByStateName(state_name).subscribe((response) => {
      let citiesList = response['data'];
      
      for(let i in citiesList){
        this.citiesList.push(citiesList[i])
      }
    })
  }
}
