import { Component, OnInit } from '@angular/core';
import { Sidebar, AccountModel } from 'src/app/shared';
import { timer } from 'rxjs'
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-widget-code',
  templateUrl: './widget-code.component.html',
  styleUrls: ['./widget-code.component.scss']
})
export class WidgetCodeComponent implements OnInit {

  public copied: boolean = false;
  public organization_id: any = null;

  constructor(
    public _sidebar: Sidebar,
    public _Auth: AuthService,
    public SettingsService: SettingService
  ) {
    let userData = this._Auth.User();
    this.organization_id = userData.user.organization_id;

    this.getAccountDetails(this.organization_id);
  }
  public AccountModel: AccountModel = new AccountModel();

  ngOnInit() {
    window.scroll(0, 0);
  }

  getAccountDetails(org_id) {
    this.SettingsService.GetAccountDetails(org_id).subscribe(
      result => {
        this.AccountModel.widget_code = result['data']['widget_code']
      }, (err:HttpErrorResponse) => {
        if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
          this.SettingsService.LogOut();
        }
      }
    )
  }

  copying() {
    let interval = timer(1000);
    let widgetCode: HTMLTextAreaElement = document.querySelector('[name="widget-code"]');

    if (widgetCode.value !== '') {
      widgetCode.select();
      widgetCode.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.copied = true
    }

    interval.subscribe(() => {
      this.copied = false;
    });
  }
}
