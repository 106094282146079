import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, NgModule } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BroadcastChannel } from 'broadcast-channel';
import { ToastrService } from 'ngx-toastr';
import { CustomerModel, InvoiceModel, PaymentModel, PaymentSettings } from 'src/app/shared';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { InvoiceService } from 'src/app/shared/services/invoice/invoice.service';
import { SettingService } from 'src/app/shared/services/setting/setting.service';

declare var SqPaymentForm: any;
declare var self: any;
declare type Message = {
  message: string;
};

@Component({
  selector: 'app-square-payment',
  templateUrl: './square-payment.component.html',
  styleUrls: ['./square-payment.component.scss']
})
export class SquarePaymentComponent implements OnInit {

  public invoice_id: string = null;
  public _nonce: any;
  public status: number = null;
  public loading: boolean = false;
  public accepted: boolean = false;
  public loading2: boolean = false;
  public InvoiceModel: InvoiceModel = new InvoiceModel();
  public CustomerModel: CustomerModel = new CustomerModel();
  public PaymentModel: PaymentModel = new PaymentModel();
  public PaymentData: PaymentSettings = new PaymentSettings();
  public organization_id: string = null;

  public BC: BroadcastChannel<Message> = new BroadcastChannel('payment');


  constructor(
    public activatedRoute: ActivatedRoute,
    public SettingService: SettingService,
    public InvoiceService: InvoiceService,
    public CustomerService: CustomerService,
    public toastr: ToastrService
  ) {
    (err: HttpErrorResponse) => {
      if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
        this.SettingService.LogOut();
      }
    }
    this.invoice_id = this.activatedRoute.snapshot.paramMap.get('invoice_id');
    if (this.invoice_id != null) {
      this.loading2 = true;
      this.getInvoiceDetails(this.invoice_id);
    }
  }

  paymentForm; //this is our payment form object

  ngOnInit() {
    this.paymentForm = new SqPaymentForm({
      // Initialize the payment form elements

      //TODO: Replace with your sandbox application ID
      applicationId: "sandbox-sq0idb-NC2G5_ORHTbD2t_KyH8qGQ",
      autoBuild: false,
      inputClass: 'sq-input',
      // // Customize the CSS for SqPaymentForm iframe elements
      inputStyles: [{
        fontSize: '18px',
        lineHeight: '10px',
        padding: '14px',
        placeholderColor: '#CFD7E0',
        backgroundColor: 'transparent',
      }],
      // Initialize the credit card placeholders
      cardNumber: {
        elementId: 'sq-card-number',
        placeholder: 'Card Number'
      },
      cvv: {
        elementId: 'sq-cvv',
        placeholder: 'CVV'
      },
      expirationDate: {
        elementId: 'sq-expiration-date',
        placeholder: 'MM/YY'
      },
      postalCode: {
        elementId: 'sq-postal-code',
        placeholder: 'Postal'
      },
      // SqPaymentForm callback functions
      callbacks: {
        /*
        * callback function: cardNonceResponseReceived
        * Triggered when: SqPaymentForm completes a card nonce request
        */


        cardNonceResponseReceived: function (errors, nonce, cardData) {

          if (errors) {
            // Log errors from nonce generation to the browser developer console.
            errors.forEach(function (error) {
            });
            alert('Encountered errors, check browser developer console for more details');
            return;
          }
          window['_nonce'] = nonce;

          self.SetPaymentData(window['_nonce']);
        }
      }

    });
    this.paymentForm.build();

  }

  onGetCardNonce(event) {
    self = this;
    // Don't submit the form until SqPaymentForm returns with a nonce
    event.preventDefault();
    // Request a nonce from the SqPaymentForm object
    this.paymentForm.requestCardNonce();
    // console.log('data', window['_nonce']);
    // this.test();
  }

  getInvoiceDetails(invoice_id) {
    this.loading2 = true;
    this.InvoiceService.InvoiceView(invoice_id).subscribe(
      result => {
        this.InvoiceModel.customer_id = result['data'].customer_id;
        this.InvoiceModel.invoice_number = result['data'].invoice_number;
        this.InvoiceModel.total = result['data'].total;
        // this.InvoiceModel.total = Math.round(this.InvoiceModel.total);
        this.organization_id = result['data']['organization_id'];

        this.status = result['data'].status;
        if (this.status == 2) {
          this.loading = false;
        }
        this.getPaymentData(this.organization_id);
        this.getCustomerDetails(this.InvoiceModel.customer_id);
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      }
    )
  }

  getPaymentData(organization_id) {
    this.SettingService.GetPaymentDetails(organization_id)
      .subscribe((result) => {
        this.PaymentData = result['data'];
        this.PaymentModel.squareAccessToken = result['data']['access_token'];
        this.PaymentModel.squareApplicationId = result['data']['application_id'];
        this.PaymentModel.squareLocationId = result['data']['location_id'];
    }, (err:HttpErrorResponse) => {
      if(err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized"){
        this.SettingService.LogOut();
      }
    }
    )
  }

  getCustomerDetails(customer_id) {
    this.CustomerService.GetCustomerById(customer_id).subscribe(
      result => {
        this.loading2 = false;
        this.CustomerModel.customer_id = result['data'].customer_id;
        this.CustomerModel.name = result['data'].name;
        this.CustomerModel.mobile = result['data'].mobile;
        this.CustomerModel.email = result['data'].email;
        this.CustomerModel.address = result['data'].address;
        this.CustomerModel.locality = result['data'].locality;
        this.CustomerModel.zip = result['data'].zip;
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      }
    )
  }

  SetPaymentData(nonce) {
    this.loading = true;
    this.PaymentModel.nonce = nonce;
    this.PaymentModel.amount_to_be_paid = this.InvoiceModel.total;
    this.PaymentModel.invoice_id = this.invoice_id;
    this.PaymentModel.invoice_number = this.InvoiceModel.invoice_number;
    this.PaymentModel.customer_name = this.CustomerModel.name;
    this.PaymentModel.email = this.CustomerModel.email;
    this.PaymentModel.address_line_1 = this.CustomerModel.address;
    this.PaymentModel.address_line_2 = this.CustomerModel.address;
    this.PaymentModel.state = this.CustomerModel.locality;
    this.InvoiceService.SquareCharge(this.PaymentModel).subscribe(
      result => {
        if (result['sucess']) {
          this.loading = false;
          this.accepted = true;
          this.BC.postMessage({ message: 'Paid' });
          this.showSuccess(result['message']);
        } else {
          this.loading = false;
          this.showDanger(result['message']);
        }


      }
    )
  }

  showSuccess(msg: string) {
    this.toastr.success(msg);
  }
  showDanger(msg: string) {
    this.toastr.error(msg);
  }

}

