import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { RegisterComponent } from "./register/register.component";
import { LoginComponent } from "./login/login.component";
import { RouterModule } from "@angular/router";
import { ForgorPasswordComponent } from "./forgor-password/forgor-password.component";
import { HomeComponent } from "./home/home.component";
import {
  ScheduleStatusComponent,
  CallCustomerComponent,
  MyScheduleStatusComponent,
  LoaderComponent,
  SignatureComponent,
  AlternateNumberViewComponent,
} from "../layout";
import { Sidebar } from "../shared";

import { InvoicesComponent } from "./invoices/invoices.component";
import { InvoiceSelfComponent } from "./invoice-self/invoice-self.component";
import { NewInvoiceComponent } from "./new-invoice/new-invoice.component";

// Ngx-bootstrap
import { TabsModule } from "ngx-bootstrap/tabs";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ModalModule } from "ngx-bootstrap/modal";

import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { NgxDropzoneModule } from "ngx-dropzone";
import { NgxSignaturePadModule } from '@eve-sama/ngx-signature-pad';

import { InvoicePayComponent } from "./invoice-pay/invoice-pay.component";
import { InvoiceSendComponent } from "./invoice-send/invoice-send.component";
import { ExpensesComponent } from "./expenses/expenses.component";
import { ExpenseNewComponent } from "./expense-new/expense-new.component";
import { CustomerComponent } from "./customer/customer.component";
import { CustomerSelfComponent } from "./customer-self/customer-self.component";
import { CustomerNewComponent } from "./customer-new/customer-new.component";
import { ItemsComponent } from "./items/items.component";
import { ItemNewComponent } from "./item-new/item-new.component";
import { SettingsComponent } from "./settings/settings.component";
import { InvoiceTemplateComponent } from "./invoice-template/invoice-template.component";
import { ReportsComponent } from "./reports/reports.component";
import { EstimateComponent } from "./estimate/estimate.component";
import { EstimateSelfComponent } from "./estimate-self/estimate-self.component";
import { EstimateNewComponent } from "./estimate-new/estimate-new.component";
import { EstimatePayComponent } from "./estimate-pay/estimate-pay.component";
import { EstimateSendComponent } from "./estimate-send/estimate-send.component";
import { ItemSelfComponent } from "./item-self/item-self.component";
import { ExpensesSelfComponent } from "./expenses-self/expenses-self.component";
import { BusinessProfileComponent } from "./business-profile/business-profile.component";
import { AddUserComponent } from "./add-user/add-user.component";
import { AccountDetailsComponent } from "./account-details/account-details.component";
import { PaymentMethodComponent } from "./payment-method/payment-method.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ScheduleComponent } from "./schedule/schedule.component";
import { ScheduleDetailComponent } from "./schedule-detail/schedule-detail.component";
import { MyScheduleComponent } from "./my-schedule/my-schedule.component";
import { MyScheduleDetailComponent } from "./my-schedule-detail/my-schedule-detail.component";
import { WidgetCodeComponent } from "./widget-code/widget-code.component";
import { PlanSettingsComponent } from "./plan-settings/plan-settings.component";
import { InvoiceReportComponent } from "./invoice-report/invoice-report.component";
import { PaymentReportComponent } from "./payment-report/payment-report.component";
import { SalesReportComponent } from "./sales-report/sales-report.component";
import { CustomerReportComponent } from "./customer-report/customer-report.component";
import { InvoiceTemplateOneComponent } from "./invoice-template-one/invoice-template-one.component";
import { InvoiceTemplateTwoComponent } from "./invoice-template-two/invoice-template-two.component";
import { InvoiceTemplateThreeComponent } from "./invoice-template-three/invoice-template-three.component";
import { PublicTemplateOneComponent } from "./public-template-one/public-template-one.component";
import { PublicTemplateTwoComponent } from "./public-template-two/public-template-two.component";
import { PublicTemplateThreeComponent } from "./public-template-three/public-template-three.component";
import { PaymentComponent } from "./payment/payment.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { MarkupInvoicesComponent } from "./markup-invoices/markup-invoices.component";
import { ResetPasswordComponent } from "../layout/modal/reset-password/reset-password.component";
import { SquarePaymentComponent } from "./square-payment/square-payment.component";
import { AuthorizeNetComponent } from "./authorize-net/authorize-net.component";
import { WorkOrderComponent } from "./work-order/work-order.component";
import { WorkOrderNewComponent } from "./work-order-new/work-order-new.component";
import { WorkOrderPayComponent } from "./work-order-pay/work-order-pay.component";
import { WorkOrderSelfComponent } from "./work-order-self/work-order-self.component";
import { UpgradeAddOnsComponent } from "./upgrade-add-ons/upgrade-add-ons.component";
import { WorkOrderSendComponent } from "./work-order-send/work-order-send.component";
import { AddOnDetailsComponent } from "./add-on-details/add-on-details.component";
import { MyAddOnsComponent } from "./my-add-ons/my-add-ons.component";
import { RecurringInvoiceComponent } from "./recurring-invoice/recurring-invoice.component";
import { ServiceAgreementsTemplatesComponent } from "./service-agreements-templates/service-agreements-templates.component";
import { ServiceAgreementsTemplatesOneComponent } from "./service-agreements-templates-one/service-agreements-templates-one.component";
import { ServiceAgreementsTemplatesTwoComponent } from "./service-agreements-templates-two/service-agreements-templates-two.component";
import { ServiceAgreementsTemplatesThreeComponent } from "./service-agreements-templates-three/service-agreements-templates-three.component";
import { CreateAgreementComponent } from "./create-agreement/create-agreement.component";
import { ListAgreementComponent } from "./list-agreement/list-agreement.component";
import { ServiceDetailComponent } from "./service-detail/service-detail.component";
import { ServiceNewComponent } from "./service-new/service-new.component";
import { ServiceAgreementSelfComponent } from "./service-agreement-self/service-agreement-self.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { ServicesComponent } from "./services/services.component";
import { PriceBookComponent } from "./price-book/price-book.component";
import { PublicServiceTemplateOneComponent } from "./public-service-template-one/public-service-template-one.component";
import { PublicServiceTemplateTwoComponent } from "./public-service-template-two/public-service-template-two.component";
import { PublicServiceTemplateThreeComponent } from "./public-service-template-three/public-service-template-three.component";
import { TaxSettingsComponent } from "./tax-settings/tax-settings.component";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CustomerTimelineComponent } from "./customer-timeline/customer-timeline.component";
import { ServiceAgreementTemplateContentEditorComponent } from "./service-agreement-template-content-editor/service-agreement-template-content-editor.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PublicWidgetViewComponent } from './public-widget-view/public-widget-view.component';
import { ScheduleCalendarComponent } from './schedule-calendar/schedule-calendar.component';
import { FullCalendarModule } from "@fullcalendar/angular";
import { SafeUrlPipe } from "../shared/pipe/safe-url.pipe";
import { PhonePipe } from "../shared/pipe/phone.pipe";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { QuickbooksComponent } from './quickbooks/quickbooks.component';
import { SocialProfileComponent } from './social-profile/social-profile.component';
import { ChecklistTodoComponent } from './checklist-todo/checklist-todo.component';
import { ChecklistAddComponent } from './checklist-add/checklist-add.component';
import { ChecklistSelfComponent } from './checklist-self/checklist-self.component';
import { AdvancePaymentComponent } from "../layout/modal/advance-payment/advance-payment/advance-payment.component";
import { SaveProgressWorkComponent } from "../layout/modal/save-progress-work/save-progress-work/save-progress-work.component";
@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgorPasswordComponent,
    HomeComponent,
    InvoicesComponent,
    InvoiceSelfComponent,
    NewInvoiceComponent,
    InvoicePayComponent,
    InvoiceSendComponent,
    ExpensesComponent,
    ExpenseNewComponent,
    CustomerComponent,
    CustomerSelfComponent,
    CustomerNewComponent,
    ItemsComponent,
    ItemNewComponent,
    SettingsComponent,
    InvoiceTemplateComponent,
    ReportsComponent,
    EstimateComponent,
    EstimateSelfComponent,
    EstimateNewComponent,
    EstimatePayComponent,
    EstimateSendComponent,
    ItemSelfComponent,
    ExpensesSelfComponent,
    BusinessProfileComponent,
    AddUserComponent,
    AccountDetailsComponent,
    PaymentMethodComponent,
    ScheduleComponent,
    ScheduleStatusComponent,
    MyScheduleStatusComponent,
    CallCustomerComponent,
    SignatureComponent,
    ResetPasswordComponent,
    ScheduleDetailComponent,
    MyScheduleComponent,
    MyScheduleDetailComponent,
    WidgetCodeComponent,
    PlanSettingsComponent,
    LoaderComponent,
    InvoiceReportComponent,
    PaymentReportComponent,
    SalesReportComponent,
    CustomerReportComponent,
    InvoiceTemplateOneComponent,
    InvoiceTemplateTwoComponent,
    InvoiceTemplateThreeComponent,
    PublicTemplateOneComponent,
    PublicTemplateTwoComponent,
    PublicTemplateThreeComponent,
    PaymentComponent,
    MarkupInvoicesComponent,
    SquarePaymentComponent,
    AuthorizeNetComponent,
    WorkOrderComponent,
    WorkOrderNewComponent,
    WorkOrderPayComponent,
    WorkOrderSelfComponent,
    UpgradeAddOnsComponent,
    WorkOrderSendComponent,
    AddOnDetailsComponent,
    MyAddOnsComponent,
    RecurringInvoiceComponent,
    ServiceAgreementsTemplatesComponent,
    ServiceAgreementsTemplatesOneComponent,
    ServiceAgreementsTemplatesTwoComponent,
    ServiceAgreementsTemplatesThreeComponent,
    CreateAgreementComponent,
    ListAgreementComponent,
    ServiceDetailComponent,
    ServiceNewComponent,
    ServiceAgreementSelfComponent,
    ServicesComponent,
    PriceBookComponent,
    PublicServiceTemplateOneComponent,
    PublicServiceTemplateTwoComponent,
    PublicServiceTemplateThreeComponent,
    TaxSettingsComponent,
    CustomerTimelineComponent,
    ServiceAgreementTemplateContentEditorComponent,
    PageNotFoundComponent,
    PublicWidgetViewComponent,
    ScheduleCalendarComponent,
    SafeUrlPipe,
    PhonePipe,
    TimesheetComponent,
    ChecklistComponent,
    QuickbooksComponent,
    SocialProfileComponent,
    ChecklistTodoComponent,
    ChecklistAddComponent,
    ChecklistSelfComponent,
    AlternateNumberViewComponent,
    AdvancePaymentComponent,
    SaveProgressWorkComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    NgxMaterialTimepickerModule,
    NgxIntlTelInputModule,
    NgxDropzoneModule,
    NgxSignaturePadModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    AngularEditorModule,
    CKEditorModule,
    FullCalendarModule,
    InfiniteScrollModule,
    GooglePlaceModule
  ],
  entryComponents: [
    CallCustomerComponent,
    SignatureComponent,
    ResetPasswordComponent,
    AdvancePaymentComponent,
    SaveProgressWorkComponent
  ],
  providers: [Sidebar],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class PagesModule {}
