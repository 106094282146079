import { Component, OnInit } from '@angular/core';
import { Sidebar } from 'src/app/shared';
import { AddOnService } from 'src/app/shared/services/add-on/add-on.service';

@Component({
  selector: 'upgrade-add-ons',
  templateUrl: './upgrade-add-ons.component.html',
  styleUrls: ['./upgrade-add-ons.component.scss']
})
export class UpgradeAddOnsComponent implements OnInit {

  public modules: any[] = [];
  constructor( 
    public _sidebar: Sidebar,
    public AddOnService : AddOnService
  )
  {
    this.getAllModules();
   }

  ngOnInit() {
  }

  getAllModules(){
    this.AddOnService.GetAllModulesList().subscribe(
      result=>{
        this.modules = result['data'];
      }
    )
  }

}
