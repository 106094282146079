import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(
     public _Auth :AuthService,
    public route: Router
  ) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this._Auth.User() != null) {
      if (!this._Auth.UserDetails()) {
        this.route.navigate(['/account-details']);
        return false;
      }
      return true;
    }
    else {   
      // this._Auth.logout();
      this.route.navigate(['/']);
      return false;
    }
  }
}
