import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alternate-number-view',
  templateUrl: './alternate-number-view.component.html',
  styleUrls: ['./alternate-number-view.component.scss']
})
export class AlternateNumberViewComponent implements OnInit {
  @Input() alternateNumber: string[] = [];

  constructor() {
   }

  ngOnInit() {
  }

}
