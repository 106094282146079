import {
  HttpErrorResponse
} from '@angular/common/http';
import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  ToastrService
} from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AddProductAndServiceComponent } from 'src/app/layout/modal/add-product-and-service/add-product-and-service.component';
import {
  CustomerModel,
  InvoiceModel,
  Sidebar,
  ServiceAgreementModel
} from 'src/app/shared';
import {
  CustomerService
} from 'src/app/shared/services/customer/customer.service';
import {
  EstimateService
} from 'src/app/shared/services/estimate/estimate.service';
import {
  InvoiceService
} from 'src/app/shared/services/invoice/invoice.service';
import {
  ItemService
} from 'src/app/shared/services/item/item.service';
import {
  ServiceAgreementService
} from 'src/app/shared/services/service-agreement/service-agreement.service';
import {
  ServiceModuleService
} from 'src/app/shared/services/service/service-module.service';
import {
  SettingService
} from 'src/app/shared/services/setting/setting.service';

@Component({
  selector: 'app-create-agreement',
  templateUrl: './create-agreement.component.html',
  styleUrls: ['./create-agreement.component.scss']
})
export class CreateAgreementComponent implements OnInit {
  public services: any[] = [];
  public alternative_number: any[] = [];
  public weekTrigger: boolean = true;
  public monthTrigger: boolean = true;
  public isWeekChecked: boolean = false;
  public isMonthChecked: boolean = false;
  public recurring_check: boolean = false;
  public submitted: boolean = false;
  public recurring_value: null;
  public add_item: boolean = false;
  public transformedData: any = null;
  public service_agreement_number: any = null;
  public service_agreement_id: string = null;
  public loading: boolean = false;
  public selected: boolean = false;
  public add_remove_item: boolean = false;
  public addBtnDisable: boolean = false;
  public minDate: Date;
  public customerList: any[] = [];

  // recurring_list (demo)
  type_of_recurrings: string[] = [
    'Weekly',
    'Biweekly',
    'Monthly',
    'Quarterly',
    'Yearly'
  ];
  // week_day_list (demo)
  week_days: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  // month_day_list (demo)
  days: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];
  // Quarterly
  quarterly: any[] = [
    '1st', '2nd', '3rd', '4th'
  ]
  // yearly_day_list (demo)
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  public agreemnet = this.formBuilder.group({
    customer_id: [null, Validators.required],
    line_item: [null],
    quantity: [1, Validators.required],
    unit_price: [0, [Validators.required, Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)]],
    new_date_range: [null, Validators.required],
    select_interval: [false],
    recurring_type: [''],
    month: [''],
    quarter: [''],
    day: [''],
    private_note: [''],
  });

  public customers: any[] = [];
  public CustomerModel: CustomerModel = new CustomerModel();
  public InvoiceModel: InvoiceModel = new InvoiceModel();
  public productServices: any[] = [];
  public List: any[] = [];
  public item_id: string = '';
  public rate: any = 0;
  public itemArr: any[] = [];
  public itemIdArr: any[] = [];
  public submitted3: boolean = false;
  public item_count: number = 0;
  public model_item_count: number = 0;
  public amount: number = 0;
  public ServiceAgreementModel: ServiceAgreementModel = new ServiceAgreementModel();
  public current_selected_item: any = null;
  public minDate_for_start_date:any = null;
  public minDate_for_end_date:any = null;
  public current_tax: any = null;
  public customer_id: string = null;
  public addBtnLoader = false;
  public subscriptions: Subscription[] = [];
  modalRef: BsModalRef;

  constructor(
    public _sidebar: Sidebar,
    public formBuilder: FormBuilder,
    public CustomerService: CustomerService,
    public SettingService: SettingService,
    public _invoiceService: InvoiceService,
    public EstimateService: EstimateService,
    public ItemService: ItemService,
    public serviceService: ServiceModuleService,
    public AgreementService: ServiceAgreementService,
    public toastr: ToastrService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public modalService: BsModalService
  ) {
    this.getAgreementList();
    this.minDate = new Date();
  }

  ngOnInit() {
    this.CustomerList();
    this.productServiceList();
    this.customer_id = this.activatedRoute.snapshot.paramMap.get('customer_id');
    if (this.customer_id) {
      this.Customer(this.customer_id)
    }
    this.item_id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.item_id) {
      this.getProductFromProductPage();
    }
    // this.getItemList();
    this.service_agreement_id = this.activatedRoute.snapshot.paramMap.get('agreement_id');
    if (this.service_agreement_id != null) {
      this.getAgreementDetails(this.service_agreement_id);
    }    
    if(this.service_agreement_id==null){
      this.minDate_for_start_date = new Date();
      this.minDate_for_start_date.setDate(this.minDate_for_start_date.getDate());
      this.minDate_for_end_date = new Date();
      this.minDate_for_end_date.setDate(this.minDate_for_end_date.getDate());
    }

    // const current = new Date();
    this.serviceService.setServiceSuccess = null;
    this.serviceService.setMoreServiceSuccess = null;
    this.add_item = false;
  }
  ngAfterViewInit(){
    const serviceAndProductResponse = this.serviceService.serviceSuccess.subscribe((response) => {
      if(response){
        this.productServiceList()
        this.modalService.hide(1);
      let removeModal =  document.querySelector('.modal-open');
      removeModal.classList.remove('modal-open')
      }
    });
    const moreServiceAndProductResponse = this.serviceService.servicMoreSuccess.subscribe((response) => {
      if(response){
        this.productServiceList()
      }
    })
    this.subscriptions.push(serviceAndProductResponse, moreServiceAndProductResponse)
    this.getProductServiceModalData();
    this.getServiceModalData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  get f() {
    return this.agreemnet.controls;
  }

  OnChangeItem(event) {
    if(!this.agreemnet.value.line_item || !event){
      this.add_remove_item = false
      this.agreemnet.get('unit_price').setValue(0);
      this.agreemnet.get('quantity').setValue(1);
      this.agreemnet.updateValueAndValidity();
      this.addBtnDisable = true;
    }
    if(event){
      this.addBtnLoader = true;
      this.addBtnDisable = true;
      this.current_selected_item = event;

      let value = event['id'];
      if (event['group'] == 'Product') {
  
        this.ItemService.GetItemById(value).subscribe(
          result => {
            if(result['success']){
              this.rate = result['data']['rate'];
              if (result['data'].tax_rate) {
                this.current_tax = result['data'].item_taxrate.rate
  
              } else {
                this.current_tax = null;
              }
              this.addBtnLoader = false;
              this.addBtnDisable = false;
            }
            this.addBtnLoader = false;
            this.addBtnDisable = false;
          }, (err: HttpErrorResponse) => {
              this.addBtnLoader = false;
              this.addBtnDisable = false;
            if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
              this.SettingService.LogOut();
            }
          }
        )
      } else if (event['group'] == 'Service') {
        this.serviceService.GetServiceById(value).subscribe(
          (result: any) => {
            if(result['success']){
              this.rate = result['data']['rate'];
              
              if (result['data'].tax_rate) {
                this.current_tax = result['data'].service_taxrate.rate
  
              } else {
                this.current_tax = null;
              }
              this.addBtnLoader = false;
              this.addBtnDisable = false;
            }  
            this.addBtnLoader = false;
            this.addBtnDisable = false;
          }, (err: HttpErrorResponse) => {
            this.addBtnLoader = false;
            this.addBtnDisable = false;
            if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
              this.SettingService.LogOut();
            }
          }
        )
      }
    }
  }

  public isCustomerNull: boolean = false;

  CustomerList() {
    this._invoiceService.GetCustomerList()
      .subscribe(
        (result: any) => {
          this.loading = false;
          if(result['data'].length) {
            this.isCustomerNull = false;
          }else {
            this.isCustomerNull = true;
          }
          this.customerList = result['data'];
        },
        (err: HttpErrorResponse) => {
          if (err.error.message == 'Unauthenticated.' ||
            err.status == 401 ||
            err.statusText == "Unauthorized") {
            this.SettingService.LogOut();
          }
        }
      )
  }

  getCustomerList() {
    if(this.isCustomerNull) {
      this.toastr.warning("Please add customer first");
    }
  }

  GetCustomerListOnSearch(event){
    let data={
      search: event.target.value
    }
    if(data.search!=''){
      this.CustomerService.GetCustomerListOnSearch(data).subscribe(
        result=>{
          this.customers = result['data'];
          
        }
      )
    }else{
      this.customers = null;
    }
    
    
  }
  Customer(event: any) {
    if (event) {
      // this.loading = true;
      this.CustomerService.GetCustomerById(event)
        .subscribe((result) => {
          // this.loading = false;

          this.CustomerModel.customer_id = result['data'].customer_id;
          this.CustomerModel.name = result['data'].name;
          this.CustomerModel.mobile = result['data'].mobile;
          this.CustomerModel.alternative_number = result['data'].alternative_number;
          this.alternative_number = this.CustomerModel.alternative_number;
          this.CustomerModel.email = result['data'].email;
          this.CustomerModel.address = result['data']['default_address'].address;
          this.CustomerModel.locality = result['data']['default_address'].locality;
          this.CustomerModel.zip = result['data']['default_address'].zip;
          if(!this.agreemnet.value.customer_id){
            this.agreemnet.get('customer_id').setValue(this.CustomerModel.customer_id);
            this.agreemnet.get('customer_id').updateValueAndValidity();
          }
          
        }, (err: HttpErrorResponse) => {
          if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
            this.SettingService.LogOut();
          }
        })
    }
  }

  InvoiceType($event, type) {
    if (type == 1) {
      localStorage.setItem("recurring_type", '1');
    } else if (type == 2) {
      localStorage.setItem("recurring_type", '2');

    }
  }

  OnChangeRecurringtype(event) {
    this.selected = false;
    this.agreemnet.get('day').setValue('');
    this.agreemnet.updateValueAndValidity();
    this.recurring_value = event.target.value;
    this.itemArr = [];
    this.ServiceAgreementModel.items = [];
    this.agreemnet.get('quarter').setValue('');
    this.agreemnet.get('month').setValue('');
    this.agreemnet.get('day').setValue('');
    this.agreemnet.updateValueAndValidity();
    if (this.recurring_value == 'Weekly') {
      this.agreemnet.get('day').setValidators(Validators.required);
      this.agreemnet.get('quarter').setValidators(null);
      this.agreemnet.get('month').setValidators(null);
      this.agreemnet.get('day').updateValueAndValidity();
      this.agreemnet.get('month').updateValueAndValidity();
      this.agreemnet.get('quarter').updateValueAndValidity();

    } else if (this.recurring_value == 'Biweekly') {
      this.agreemnet.get('day').setValidators(Validators.required);
      this.agreemnet.get('quarter').setValidators(null);
      this.agreemnet.get('month').setValidators(null);
      this.agreemnet.get('day').updateValueAndValidity();
      this.agreemnet.get('month').updateValueAndValidity();
      this.agreemnet.get('quarter').updateValueAndValidity();

    } else if (this.recurring_value == "Monthly") {
      this.agreemnet.get('day').setValidators(Validators.required);
      this.agreemnet.get('quarter').setValidators(null);
      this.agreemnet.get('month').setValidators(null);
      this.agreemnet.get('day').updateValueAndValidity();
      this.agreemnet.get('month').updateValueAndValidity();
      this.agreemnet.get('quarter').updateValueAndValidity();
    } else if (this.recurring_value == "Quarterly") {
      this.agreemnet.get('quarter').setValidators(Validators.required);
      this.agreemnet.get('day').setValidators(Validators.required);
      this.agreemnet.get('month').setValidators(null);
      this.agreemnet.get('month').updateValueAndValidity();
      this.agreemnet.get('quarter').updateValueAndValidity();
      this.agreemnet.get('day').updateValueAndValidity();


    } else if (this.recurring_value == "Yearly") {
      this.agreemnet.get('month').setValidators(Validators.required);
      this.agreemnet.get('day').setValidators(Validators.required);
      this.agreemnet.get('quarter').setValidators(null);
      this.agreemnet.get('day').updateValueAndValidity();
      this.agreemnet.get('month').updateValueAndValidity();
      this.agreemnet.get('quarter').updateValueAndValidity();

    }
    this.productServiceList();

  }
  OnChangingRecurringValue(event, type) {
    // this.agreemnet.get('day').setValue('');
    // this.agreemnet.updateValueAndValidity();
  }
  OnChangingRecurringValue2(event) {
    // this.agreemnet.value.recurringTypeValueTwo = event.target.value ;
  }


  recurring_toggle() {
    this.recurring_check = !this.recurring_check;
    this.recurring_value = null;

    this.agreemnet.get('select_interval').setValue(this.recurring_check);
    this.agreemnet.get('recurring_type').setValue('');
    this.agreemnet.get('quarter').setValue('');
    this.agreemnet.get('month').setValue('');
    this.agreemnet.get('day').setValue('');
    this.agreemnet.get('select_interval').updateValueAndValidity();
    this.agreemnet.get('recurring_type').updateValueAndValidity();
    this.agreemnet.get('quarter').updateValueAndValidity();
    this.agreemnet.get('month').updateValueAndValidity();
    this.agreemnet.get('day').updateValueAndValidity();
    if (this.recurring_check) {
      this.agreemnet.get('select_interval').setValidators(Validators.required);
      this.agreemnet.get('recurring_type').setValidators(Validators.required);
      this.agreemnet.updateValueAndValidity();
    } else {
      this.agreemnet.get('select_interval').setValidators(null);
      this.agreemnet.get('recurring_type').setValidators(null);
      this.agreemnet.get('quarter').setValidators(null);
      this.agreemnet.get('month').setValidators(null);
      this.agreemnet.get('day').setValidators(null);
      this.agreemnet.get('select_interval').updateValueAndValidity();
      this.agreemnet.get('recurring_type').updateValueAndValidity();
      this.agreemnet.get('quarter').updateValueAndValidity();
      this.agreemnet.get('month').updateValueAndValidity();
      this.agreemnet.get('day').updateValueAndValidity();
    }
    this.productServiceList()


  }

  AddItem(){
    this.add_item = false;
    this.add_remove_item = false;
    if ( ( this.agreemnet.controls.unit_price.invalid ) || ( this.agreemnet.controls.quantity.invalid ) )
    {
      this.submitted3 = true;
      return;
    }

    this.rate = this.agreemnet.value.unit_price;

    let addItemField: HTMLInputElement = document.querySelector('[name="line_item"]');
    let addQuantityField: HTMLInputElement = document.querySelector('[name="quantity"]');

    if ( this.agreemnet.value.line_item !== '' )
    {

      if ( this.current_selected_item.group == 'Product' )
      {
        this.ItemService.GetItemById( this.agreemnet.value.line_item )
          .subscribe( ( result ) =>
          {
            for ( let i in this.itemArr )
            {

              if ( this.itemArr[ i ].item_id == result[ 'data' ].name )
              {
                this.itemArr[ i ].qty = this.agreemnet.value.quantity;
                this.itemArr[ i ].group = 'Product';
                this.itemArr[ i ].unit_price = this.rate;
                if ( result[ 'data' ].tax_rate )
                {
                  this.itemArr[ i ].tax = result[ 'data' ].item_taxrate.rate + '%';
                  this.itemArr[ i ].rate = ( this.agreemnet.value.quantity * ( +this.rate + ( +this.rate * ( result[ 'data' ].item_taxrate.rate / 100 ) ) ) ).toFixed( 2 );
                } else
                {
                  this.itemArr[ i ].tax = 'NA';
                  this.itemArr[ i ].rate = this.agreemnet.value.quantity * ( +this.rate );

                }
                this.item_count = 1;
                break;
              }

            }

            if ( result[ 'data' ].tax_rate )
            {
              if ( this.item_count == 0 )
              {
                this.itemArr.push( {
                  item_id: result[ 'data' ].name,
                  group: 'Product',
                  qty: this.agreemnet.value.quantity,
                  unit_price: this.rate,
                  rate: ( this.agreemnet.value.quantity * ( +this.rate + ( +this.rate * ( result[ 'data' ].item_taxrate.rate / 100 ) ) ) ).toFixed( 2 ),
                  tax: result[ 'data' ].item_taxrate.rate + '%'
                } );
              }
            } else
            {
              if ( this.item_count == 0 )
              {
                this.itemArr.push( {
                  item_id: result[ 'data' ].name,
                  group: 'Product',
                  qty: this.agreemnet.value.quantity,
                  unit_price: this.rate,
                  rate: this.agreemnet.value.quantity * ( +this.rate ),
                  tax: 'NA'
                } );
              }
            }

            this.item_count = 0;
            this.itemIdArr.push( result[ 'data' ].item_id );

            this.amount = 0;


            if ( this.amount == 0 )
            {
              for ( let i of this.itemArr )
              {
                this.amount = this.amount + ( +i.rate );
              }
            }

            if ( this.service_agreement_id == null )
            {
              if ( this.agreemnet.value.unit_price > 0 )
              {
                this.agreemnet.controls.unit_price.setValue( 0 );
                this.agreemnet.updateValueAndValidity();
              }
              if ( this.agreemnet.value.quantity > 0 )
              {
                // this.amount = this.amount + ((+result['data'].rate) * this.agreemnet.value.quantity);
              } else if ( this.agreemnet.value.quantity == '' || this.agreemnet.value.quantity == null )
              {
                this.agreemnet.controls.quantity.setValue( 1 );
                // this.amount = this.amount + ((+result['data'].rate) * this.agreemnet.value.quantity);
              }

              // this.Calculate();
              this.agreemnet.controls.quantity.setValue( 1 );
              this.agreemnet.updateValueAndValidity();
            } else
            {
              if ( this.agreemnet.value.unit_price > 0 )
              {
                this.agreemnet.controls.unit_price.setValue( 0 );
                this.agreemnet.updateValueAndValidity();
              }

              if ( this.agreemnet.value.quantity > 0 )
              {
                // this.amount = this.amount + ((+result['data'].rate) * this.agreemnet.value.quantity);
              } else if ( this.agreemnet.value.quantity == '' || this.agreemnet.value.quantity == null )
              {
                this.agreemnet.controls.quantity.setValue( 1 );
                // this.amount = this.amount + ((+result['data'].rate) * this.agreemnet.value.quantity);
              }


              // this.Calculate();
              this.ServiceAgreementModel.amount = this.amount;
              this.agreemnet.controls.quantity.setValue( 1 );
              this.agreemnet.updateValueAndValidity();

            }
          }, ( err: HttpErrorResponse ) =>
          {
            if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
            {
              this.SettingService.LogOut();
            }
          } );
      } else if ( this.current_selected_item.group == 'Service' )
      {
        this.serviceService.GetServiceById( this.agreemnet.value.line_item )
          .subscribe( ( result ) =>
          {
            for ( let i in this.itemArr )
            {

              if ( this.itemArr[ i ].item_id == result[ 'data' ].name )
              {
                this.itemArr[ i ].qty = this.agreemnet.value.quantity;
                this.itemArr[ i ].group = 'Service';
                this.itemArr[ i ].unit_price = this.rate;
                if ( result[ 'data' ].tax_rate )
                {
                  this.itemArr[ i ].tax = result[ 'data' ].service_taxrate.rate + '%';
                  this.itemArr[ i ].rate = ( this.agreemnet.value.quantity * ( +this.rate + ( +this.rate * ( result[ 'data' ].service_taxrate.rate / 100 ) ) ) ).toFixed( 2 );

                } else
                {
                  this.itemArr[ i ].rate = this.agreemnet.value.quantity * ( +this.rate );
                  this.itemArr[ i ].tax = 'NA';

                }
                this.item_count = 1;
                break;
              }

            }

            if ( result[ 'data' ].tax_rate )
            {
              if ( this.item_count == 0 )
              {
                this.itemArr.push( {
                  item_id: result[ 'data' ].name,
                  group: 'Service',
                  qty: this.agreemnet.value.quantity,
                  unit_price: this.rate,
                  rate: ( this.agreemnet.value.quantity * ( +this.rate + ( +this.rate * ( result[ 'data' ].service_taxrate.rate / 100 ) ) ) ).toFixed( 2 ),
                  tax: result[ 'data' ].service_taxrate.rate + '%'
                } );
              }
            } else
            {
              if ( this.item_count == 0 )
              {
                this.itemArr.push( {
                  item_id: result[ 'data' ].name,
                  group: 'Service',
                  qty: this.agreemnet.value.quantity,
                  unit_price: this.rate,
                  rate: this.agreemnet.value.quantity * ( +this.rate ),
                  tax: 'NA'
                } );
              }
            }
            this.item_count = 0;
            this.itemIdArr.push( result[ 'data' ].item_id );

            this.amount = 0;


            if ( this.amount == 0 )
            {
              for ( let i of this.itemArr )
              {
                this.amount = this.amount + ( +i.rate );
              }
            }

            if ( this.service_agreement_id == null )
            {
              if ( this.agreemnet.value.unit_price > 0 )
              {
                this.agreemnet.controls.unit_price.setValue( 0 );
                this.agreemnet.updateValueAndValidity();
              }
              if ( this.agreemnet.value.quantity > 0 )
              {
                // this.amount = this.amount + ((+result['data'].rate) * this.agreemnet.value.quantity);
              } else if ( this.agreemnet.value.quantity == '' || this.agreemnet.value.quantity == null )
              {
                this.agreemnet.controls.quantity.setValue( 1 );
                // this.amount = this.amount + ((+result['data'].rate) * this.agreemnet.value.quantity);
              }

              // this.Calculate();
              this.agreemnet.controls.quantity.setValue( 1 );
              this.agreemnet.updateValueAndValidity();
            } else
            {
              if ( this.agreemnet.value.unit_price > 0 )
              {
                this.agreemnet.controls.unit_price.setValue( 0 );
                this.agreemnet.updateValueAndValidity();
              }

              if ( this.agreemnet.value.quantity > 0 )
              {
                // this.amount = this.amount + ((+result['data'].rate) * this.agreemnet.value.quantity);
              } else if ( this.agreemnet.value.quantity == '' || this.agreemnet.value.quantity == null )
              {
                this.agreemnet.controls.quantity.setValue( 1 );
                // this.amount = this.amount + ((+result['data'].rate) * this.agreemnet.value.quantity);
              }


              // this.Calculate();
              this.ServiceAgreementModel.amount = this.amount;
              this.agreemnet.controls.quantity.setValue( 1 );
              this.agreemnet.updateValueAndValidity();

            }
          }, ( err: HttpErrorResponse ) =>
          {
            if ( err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized" )
            {
              this.SettingService.LogOut();
            }
          } );
      }

      for ( let i in this.ServiceAgreementModel.items )
      {

        if ( this.ServiceAgreementModel.items[ i ].item_id == this.agreemnet.value.line_item )
        {
          this.ServiceAgreementModel.items[ i ].qty = this.agreemnet.value.quantity;
          this.ServiceAgreementModel.items[ i ].group = this.current_selected_item.group;
          this.ServiceAgreementModel.items[ i ].unit_price = this.rate;
          this.ServiceAgreementModel.items[ i ].tax = this.current_tax;
          this.model_item_count = 1;
          break;
        }
      }

      if ( this.model_item_count == 0 )
      {
        this.ServiceAgreementModel.items.push( {
          item_id: this.agreemnet.value.line_item,
          group: this.current_selected_item.group,
          qty: addQuantityField.value || '1',
          unit_price: this.rate,
          tax: this.current_tax
        } );
      }

      this.model_item_count = 0;
    } else
    {
      return;
    }
    this.agreemnet.get('line_item').setValue(null);
    this.agreemnet.get('line_item').updateValueAndValidity();

    setTimeout(()=>{
      this._invoiceService.service_response_data.next(null);
      this._invoiceService.product_response_data.next(null);

    },0)
  }

  DeleteItem(index: number) {
    this.itemArr.splice(index, 1);
    let id = this.itemIdArr.splice(index, 1);
    let invoice_amount = this.ServiceAgreementModel.items.splice(index, 1);


    if (this.ServiceAgreementModel.items.length == 0) {
      this.agreemnet.get('line_item').setValue('');

      this.agreemnet.get('line_item').setValidators([Validators.required]);
      this.agreemnet.get('line_item').updateValueAndValidity();
    }

    for (let i of invoice_amount) {
      this.ItemService.GetItemById(i.item_id).subscribe(
        result => {
          if (this.service_agreement_id == null) {
            if (i.tax) {
              this.amount = this.amount - ((+i.unit_price + (+i.unit_price * (+i.tax / 100))) * i.qty);

            } else {
              this.amount = this.amount - (+i.unit_price * i.qty);
            }
            // this.amount = this.amount - (+i.unit_price * i.qty);
          } else {
            if (i.tax) {
              this.amount = this.amount - ((+i.unit_price + (+i.unit_price * (+i.tax / 100))) * i.qty);
              this.ServiceAgreementModel.amount = this.amount;


            } else {
              this.amount = this.amount - (+i.unit_price * i.qty);
              this.ServiceAgreementModel.amount = this.amount;

            }
          }
        }, (err: HttpErrorResponse) => {
          if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
            this.SettingService.LogOut();
          }
        }
      )
    }
  }

  getProductServiceList() {    
    if(!this.productServices.length) {
      this.toastr.warning("Please add product and services first");
    }
  }

  productServiceList() {    
    let data = {
      selected_interval : this.recurring_value
    }
    this.EstimateService.GetProductServiceList(data).subscribe(
      result => {
        this.List = result['data'];
        this.productServices = [
          ...this.List[0].map(
            (item: Object) => ({
              group: 'Service',
              name: item['name'],
              id: item['service_id'],
            })
          ),
          ...this.List[1].map(
            (item: Object) => ({
              group: 'Product',
              name: item['name'],
              id: item['item_id'],
            })
          )
        ]
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      }
    )
  }

  CreateAgreement() {  
    if (this.agreemnet.invalid) {
      this.submitted = true;
      if(this.ServiceAgreementModel.items.length == 0){
        this.add_item = true;
      }
      return;
    }
    if (this.ServiceAgreementModel.items.length == 0) {
      this.add_item = true;
      return;
    }
    if ( this.ServiceAgreementModel.items.length > 0 ) {
      this.add_item = false;
    }

    let flag =0;
      if(this.agreemnet.value.line_item){
        if(this.ServiceAgreementModel.items.length>0)
        for(let value of  this.ServiceAgreementModel.items){
          if(value.item_id == this.agreemnet.value.line_item){

            flag =1;
            break;
            
          }else{
           flag =0;
          }          
        }
        if(flag==0)
        {
          this.add_remove_item = true;
          return;
  
  
        }else{
          this.add_remove_item = false;
  
        }
      }



    this.ServiceAgreementModel.total = this.amount;
    if (this.service_agreement_id == null) {
      this.ServiceAgreementModel.customer_id = this.agreemnet.value.customer_id;
      this.ServiceAgreementModel.items = this.ServiceAgreementModel.items;
      this.ServiceAgreementModel.select_interval = this.agreemnet.value.select_interval;
      this.ServiceAgreementModel.recurring_type = this.agreemnet.value.recurring_type;
      this.ServiceAgreementModel.day = this.agreemnet.value.day;
      this.ServiceAgreementModel.month = this.agreemnet.value.month;
      this.ServiceAgreementModel.quarter = this.agreemnet.value.quarter;
      this.ServiceAgreementModel.private_note = this.agreemnet.value.private_note;
      this.ServiceAgreementModel.service_agreement_number = this.transformedData;


      this.ServiceAgreementModel.start_date = this.agreemnet.get('new_date_range').value[0];
      this.ServiceAgreementModel.end_date = this.agreemnet.get('new_date_range').value[1];
      this.loading = true;
      this.AgreementService.AddServiceAgreement(this.ServiceAgreementModel).subscribe(
        result => {
          if (result['success']) {
            // this.service_agreement_id = result['data']['service_agreement_id'];
            this.toastr.success(result['msg']);
            this.loading = false
            this.router.navigate(['/service-agreement-self/', result['data']['service_agreement_id']]);
          } else {
            this.loading = false
            this.toastr.error(result['msg']);
          }
        }, (err: HttpErrorResponse) => {
          if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
            this.SettingService.LogOut();
          }
        }
      )
    } else if (this.service_agreement_id != null) {
      this.ServiceAgreementModel.day = this.agreemnet.value.day;
      this.ServiceAgreementModel.start_date = this.agreemnet.get('new_date_range').value[0];
      this.ServiceAgreementModel.end_date = this.agreemnet.get('new_date_range').value[1];
      this.AgreementService.UpdateServiceAgreement(this.ServiceAgreementModel).subscribe(
        result => {if (result['success']) {
          // this.service_agreement_id = result['data']['service_agreement_id'];
          this.toastr.success(result['msg']);
          this.loading = false
          this.router.navigate(['/service-agreement-self/', result['data']['service_agreement_id']]);
        } else {
          this.loading = false
          this.toastr.error(result['msg']);
        }
        }, (err: HttpErrorResponse) => {
          if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
            this.SettingService.LogOut();
          }
        }
      )
    }


  }

  digitFormatter(index: number) {
    let starting: any = `${index}`;
    let transformFormat = '00000'
    this.transformedData = transformFormat.substring(0, transformFormat.length - starting.length) + starting;

    return this.transformedData;
  }

  getAgreementList() {
    this.AgreementService.GetServiceAgreementList().subscribe(
      result => {
        this.service_agreement_number = result['data'].length;
        this.service_agreement_number = this.service_agreement_number + 1;
        this.digitFormatter(this.service_agreement_number);
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      }
    )
  }

  getAgreementDetails(service_agreement_id) {
    this.loading = true;
    this.AgreementService.GetServiceAgreementById(service_agreement_id).subscribe(
      (result) => {
        if (result['success']) {
          this.loading = false;
          this.selected = true;
          this.ServiceAgreementModel.start_date = new Date(result['data'].start_date);
          this.ServiceAgreementModel.end_date = new Date(result['data'].end_date);

          this.amount = result['data'].total;
          this.ServiceAgreementModel.total = result['data'].amount;
          
          this.minDate_for_start_date = new Date();
          this.minDate_for_start_date.setDate(this.minDate_for_start_date.getDate());

            this.minDate_for_end_date = new Date();
            this.minDate_for_end_date.setDate(this.ServiceAgreementModel.start_date.getDate());
            // this.minDate_for_end_date = new Date();

            const startDate = new Date(result['data'].start_date)
            const endDate = new Date(result['data'].end_date)
            const dateArry= [
              startDate,
              endDate
            ]
          this.ServiceAgreementModel.new_date_range = dateArry;
          this.ServiceAgreementModel.customer_id = result['data'].customer_id;
          this.ServiceAgreementModel.service_agreement_id = result['data'].service_agreement_id;
          this.ServiceAgreementModel.private_note = result['data'].private_note;
         
          this.ServiceAgreementModel.service_agreement_recurring_details_id = result['data'].service_agreement_recurring_details_id;
          if (result['data']['serviceagreement_customers'] != null && result['data']['serviceagreement_customers'].length > 0) {
            this.CustomerModel.customer_id = result['data'].customer_id;
            this.CustomerModel.name = result['data']['serviceagreement_customers'][0].name;
            this.CustomerModel.email = result['data']['serviceagreement_customers'][0].email;
            this.CustomerModel.mobile = result['data']['serviceagreement_customers'][0].mobile;
            this.CustomerModel.alternative_number = result['data']['serviceagreement_customers'][0].alternative_number;
            this.alternative_number = result['data']['serviceagreement_customers'][0].alternative_number;
            this.CustomerModel.zip = result['data']['serviceagreement_customers'][0].zip;
            this.CustomerModel.locality = result['data']['serviceagreement_customers'][0].locality;
            this.CustomerModel.address = result['data']['serviceagreement_customers'][0].address;
          }
          if (result['data']['serviceagreement_serviceagreementsitems'] != null || result['data']['serviceagreement_serviceagreementsitems'].length > 0) {
            this.ServiceAgreementModel.line_item = result['data']['serviceagreement_serviceagreementsitems'][0].item_id;
            this.agreemnet.get('line_item').clearValidators();
            this.agreemnet.get('line_item').updateValueAndValidity();
            for (let index of result['data']['serviceagreement_serviceagreementsitems']) {
              if (index.group == 'Product') {
                if (index.serviceagreementitems_items_with_trashed.tax_rate) {
                  this.ServiceAgreementModel.items.push({
                    item_id: index.serviceagreementitems_items_with_trashed.item_id,
                    qty: index.quantity,
                    unit_price: index.rate,
                    group: index.group,
                    tax: index.serviceagreementitems_items_with_trashed.tax_rate_data.rate,
                  });
                  this.itemArr.push({
                    item_id: index.serviceagreementitems_items_with_trashed.name,
                    qty: index.quantity,
                    unit_price: index.rate,
                    group: index.group,
                    tax: index.serviceagreementitems_items_with_trashed.tax_rate_data.rate+'%',
                    rate: (index.quantity * (+index.rate + (+index.rate * (index.serviceagreementitems_items_with_trashed.tax_rate_data.rate / 100)))).toFixed(2),
                  });
                }else{
                  this.ServiceAgreementModel.items.push({
                    item_id: index.serviceagreementitems_items_with_trashed.item_id,
                    qty: index.quantity,
                    unit_price: index.rate,
                    group: index.group,
                    tax: 0
                  });
                  this.itemArr.push({
                    item_id: index.serviceagreementitems_items_with_trashed.name,
                    qty: index.quantity,
                    rate: index.quantity * (+index.rate),
                    unit_price: index.rate,
                    group: index.group,
                    tax: 'NA'
                  });
                }
               
              } else if (index.group == 'Service') {
                if (index.serviceagreementitems_service_with_trashed.tax_rate) {
                  this.ServiceAgreementModel.items.push({
                    item_id: index.serviceagreementitems_service_with_trashed.service_id,
                    qty: index.quantity,
                    unit_price: index.rate,
                    group: index.group,
                    tax: index.serviceagreementitems_service_with_trashed.tax_rate_data.rate,
                  });
                  this.itemArr.push({
                    item_id: index.serviceagreementitems_service_with_trashed.name,
                    qty: index.quantity,
                    unit_price: index.rate,
                    group: index.group,
                    tax: index.serviceagreementitems_service_with_trashed.tax_rate_data.rate + '%',
                    rate: (index.quantity * (+index.rate + (+index.rate * (index.serviceagreementitems_service_with_trashed.tax_rate_data.rate / 100)))).toFixed(2),

                  });
                }else{
                  this.ServiceAgreementModel.items.push({
                    item_id: index.serviceagreementitems_service_with_trashed.service_id,
                    qty: index.quantity,
                    unit_price: index.rate,
                    group: index.group,
                    tax: 0,
                  });
                  this.itemArr.push({
                    item_id: index.serviceagreementitems_service_with_trashed.name,
                    qty: index.quantity,
                    rate: index.quantity * (+index.rate),
                    unit_price: index.rate,
                    group: index.group,
                    tax: 'NA',
                  });
                }
              }

            }
          }

          if (result['data']['service_agreement_recurrings'] != null && result['data']['service_agreement_recurrings'] != '') {

            this.ServiceAgreementModel.day = result['data']['service_agreement_recurrings'].day;
            this.ServiceAgreementModel.month = result['data']['service_agreement_recurrings'].month;
            this.ServiceAgreementModel.quarter = result['data']['service_agreement_recurrings'].quarter;

            this.ServiceAgreementModel.recurring_type = result['data']['service_agreement_recurrings']['recurring_type'];
          }
          else{
            this.ServiceAgreementModel.day = null;
            this.ServiceAgreementModel.month = null;
            this.ServiceAgreementModel.quarter = null;

            this.ServiceAgreementModel.recurring_type = null;
          }
        }
        this.ServiceAgreementModel.service_agreement_number = result['data'].service_agreement_number;
        this.ServiceAgreementModel.select_interval = result['data'].select_interval;
        this.recurring_check = result['data'].select_interval;
        this.recurring_value = this.ServiceAgreementModel.recurring_type;

        setTimeout(()=>{
          this.agreemnet.get('day').setValue(this.ServiceAgreementModel.day);
          this.agreemnet.get('day').updateValueAndValidity();
        },0);
      }, (err: HttpErrorResponse) => {
        if (err.error.message == 'Unauthenticated.' || err.status == 401 || err.statusText == "Unauthorized") {
          this.SettingService.LogOut();
        }
      }
    )

  }

  SetEndDateOptions(event){
    if(event!=null){
      this.minDate_for_end_date = new Date();
      this.minDate_for_end_date.setDate(event.getDate());
    }
   
  }

  getProductFromProductPage() {
    let data = JSON.parse(localStorage.getItem('product'));
    this.agreemnet.get('line_item').setValue(data.id);
    this.agreemnet.get('line_item').updateValueAndValidity();
    this.OnChangeItem(data);
  }
  addProductservice(){
    this.modalRef = this.modalService.show(AddProductAndServiceComponent, {
      class: 'modal-lg'
    })
  }

  getProductServiceModalData(){
    const productDataSubscription = this._invoiceService.product_response_data.subscribe(
      (result) => {
        if(result){
          this._invoiceService.setProductData = null;
          if(result['group']=='Product'){
           
            let quantity_for_invoice = localStorage.getItem('quantity_for_invoice');
            this.agreemnet.get('line_item').setValue(result['item_id']);
            this.agreemnet.get('quantity').setValue(result['quantity_for_invoice']);
            this.agreemnet.get('unit_price').setValue(result['rate']);
            this.current_selected_item ={
              group : result['group'],
              id : result['item_id'],
              name: result['name']
            }
            this.current_selected_item.group = result['group'];
  
            this.agreemnet.updateValueAndValidity();
            let item={
              'id': result['item_id'],
              'group': result['group'],
            }
            this.OnChangeItem(item);
            setTimeout(()=>{
              this.AddItem();
            },3000)
            
          }
        }
      }
    );

    this.subscriptions.push(productDataSubscription);
    
  }

  getServiceModalData() {
    const serviceDataSubscription = this._invoiceService.service_response_data.subscribe(
      (result) => {
        if (result) {
          this._invoiceService.setServiceData = null;
          if(result['group']=='Service'){
            this.agreemnet.get('line_item').setValue(result['service_id']);
            this.agreemnet.get('quantity').setValue(1);
            this.agreemnet.get('unit_price').setValue(result['rate']);
            this.current_selected_item = {
              group: result['group'],
              id: result['service_id'],
              name: result['name']
            }
            this.current_selected_item.group = result['group'];
  
            this.agreemnet.updateValueAndValidity();
            let item={
              'id': result['service_id'],
              'group': result['group'],
            }
            this.OnChangeItem(item);
            setTimeout(()=>{
              this.AddItem();
            },3000)
          }
        }
      }
    );

    this.subscriptions.push(serviceDataSubscription);
  }
  
  arrowDownBtn(index : number){
    const copy1 = [...this.itemArr];
    const moveTo1 = copy1.splice(index, 1)[0];
    copy1.splice(index +1, 0, moveTo1);
    this.itemArr = copy1;

    const copy2 = [...this.ServiceAgreementModel.items];
    const moveTo2 = copy2.splice(index, 1)[0];
    copy2.splice(index +1, 0, moveTo2);
    this.ServiceAgreementModel.items = copy2;
  }


  arrowUpBtn(index : number){
    const copy1 = [...this.itemArr];
    const moveTo1 = copy1.splice(index, 1)[0];
    copy1.splice(index - 1, 0, moveTo1);
    this.itemArr = copy1;
  
    const copy2 = [...this.ServiceAgreementModel.items];
    const moveTo2 = copy2.splice(index, 1)[0];
    copy2.splice(index - 1, 0, moveTo2);
    this.ServiceAgreementModel.items = copy2;
  }
}
