import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token/token.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocialProfileService {

  constructor(
    private _http : HttpClient,
    private _TokenService : TokenService
  ) { }

  AddSocialProfile(_formdata:any){
    let URL = `${environment.url}socile-profile`;

    return this._http.post(URL, _formdata, {
      headers: this._TokenService.GetTokenByHeader()
    })
    .pipe(map((res: any) => {
      return res
    }));
  }

  GetSocialProfile(){
    let URL = `${environment.url}socile-profile`;

    return this._http.get(URL,{
      headers: this._TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => {
      return res
    }));
  }

  UpdateSocialProfile (social_profile_id, data:any){
    let URL =  `${environment.url}socile-profile/${social_profile_id}`;

    return this._http.put(URL,data,{
      headers: this._TokenService.GetTokenByHeader(),
    })
    .pipe(map((res: any) => {
      return res
    }));
  }
}
