import { Injectable } from '@angular/core';
import { BaseModel } from '../base/base.model';

@Injectable({
  providedIn: 'root'
})

export class LoginModel extends BaseModel{
    public email:string = null;
    public password:string = null;
    public name:string = null;
    public id:string = null;
    public image:string = null;
    public token:string = null;
    public idToken:boolean = false;

    public rules() {
        return {
            email: ["required", "email"],
            password: ["required"] ,
        };
    }
}

