import { Injectable } from '@angular/core';
import { BaseModel } from '../base/base.model';

@Injectable({
  providedIn: 'root'
})

export class CustomerModel extends BaseModel {
  public customer_id: string = null;
  public name: string = null;
  public mobile: string = null;
  public email: string = null;
  public address: string = null;
  public address_type: string = null;
  public locality: string = null;
  public state: string = null;
  public zip: string = null;
  public title: string = null;
  public type: string = null;
  public note: string = null;
  public merchant: string = null;
  public tip: string = null;
  public total: string = null;
  public alternative_number: any[] = [];
}

