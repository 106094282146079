import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CustomerModel } from "src/app/shared";
import { CustomerService } from "src/app/shared/services/customer/customer.service";
import { SettingService } from "src/app/shared/services/setting/setting.service";

@Component({
  selector: "app-customer-timeline",
  templateUrl: "./customer-timeline.component.html",
  styleUrls: ["./customer-timeline.component.scss"],
})
export class CustomerTimelineComponent implements OnInit {
  public customerData: CustomerModel = new CustomerModel();
  public customer_id: string = null;
  public timeLineData: any[] = [];
  public loading = false;
  public currentPageNo: number = 1;
  constructor(
    public CustomerService: CustomerService,
    public activateRoute: ActivatedRoute,
    public SettingService: SettingService
  ) {
    this.customer_id = this.activateRoute.snapshot.paramMap.get("customer_id");
    this.getCustomerDetails(this.customer_id);
  }

  ngOnInit() {
    this.customerTimeline(this.currentPageNo);
    window.scroll(0, 0);
  }
  getCustomerDetails(customer_id) {
    this.loading = true;
    if (this.customer_id != null) {
      this.CustomerService.GetCustomerById(this.customer_id).subscribe(
        (result) => {
          this.customerData = result["data"];
          this.loading = false;
        });
    }
  }

  timelineData: any[] = [];
  incommingData: any[] = [];
  timelineAllData: any[] = [];

  customerTimeline(pageNo: number) {
    this.currentPageNo = pageNo;
    const data = {
      page: this.currentPageNo,
      customer_id: this.customer_id,
    };
    this.CustomerService.customerTimeline(data).subscribe((response) => {
      if (response["success"]) {
        this.timelineAllData = response["data"];
        if (pageNo == 1) {
          this.timeLineData = response["data"]["data"];
        } else {
          this.incommingData = response["data"]["data"];
          for (let i in this.incommingData) {
            this.timeLineData.push(this.incommingData[i]);
          }
        }
      }else{
        this.notScroll = false;
      }
    });
  }
  public notScroll = true;
  public notEmptyData = true;

  onScroll(pageNo: number) {
    this.currentPageNo = pageNo;
    if(!this.notScroll){
      return
    }
    this.customerTimeline(this.currentPageNo);
  }

}
